import React, { useState } from 'react'
import { connect } from 'react-redux'
import { every, mapValues } from 'lodash'
import Chip from '@material-ui/core/Chip'
import ChipsFilterModal from '../../../components/_shared/modals/chipsFilter/ChipsFilterModal'
import actions from '../services/dashboard-actions'
import { STATUS_LABELS } from '../../../services/constants'
import { STATUS_FILTER_LABELS } from '../services/dashboard-constants'
import { getStatusFilterLabel } from '../services/related/filter-service'
import { gaEventAction } from '../../../services/googleAnalytics/ga-actions'

const StatusFilter = (props) => {
  const {
    statusFilter,
    changeSelectedChip,
    label,
    setStatusLabel,
    startPollingModification,
    stopPollingModification,
    isInitialState,
    gaEventSend,
  } = props
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(statusFilter)

  const handleClickOpen = () => {
    if (!isInitialState) {
      setSelected(statusFilter)
      setOpen(true)

      stopPollingModification()
    }
  }

  const handleClose = () => {
    setOpen(false)

    startPollingModification()
  }

  const changeSelected = (name) => {
    const newSelected = mapValues(selected, (item, key) => {
      return name === key ? !selected[key] : selected[key]
    })

    setSelected(newSelected)
  }

  const onApply = () => {
    const isAllSelected = every(selected)
    let filterLabel = ''
    if (isAllSelected) {
      filterLabel = 'All'
    } else {
      filterLabel = getStatusFilterLabel(selected)
    }
    setStatusLabel(filterLabel)
    changeSelectedChip('statusFilter', selected)
    gaEventSend('cycle_status_filter', { event_label: filterLabel })

    handleClose()
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        label={'Status: ' + label}
        variant="outlined"
      />
      <ChipsFilterModal
        open={open}
        constants={STATUS_LABELS}
        onSubmitLabel={STATUS_FILTER_LABELS.apply}
        filterName={STATUS_FILTER_LABELS.filterName}
        statusFilter={selected}
        onApply={onApply}
        onChange={changeSelected}
        onClose={handleClose}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  statusFilter: state.dashboardReducer.statusFilter,
  isInitialState: state.dashboardReducer.isInitialState,
  label: state.dashboardReducer.statusLabel,
})

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedChip: (name, key) =>
      dispatch(actions.changeFilter(name, key)),
    setStatusLabel: (label) => dispatch(actions.setStatusLabel(label)),
    startPollingModification: () =>
      dispatch(actions.startPollingModification()),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    gaEventSend: gaEventAction(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusFilter)
