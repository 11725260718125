import customTreeActions from '../../components/_shared/customTree/services/customTree-actions'
import authorizationActions from '../../containers/LogIn/services/authorization-actions'
import rootActions from './root-actions'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { HttpClient } from '../HttpClient'

export const getLastModification = (state) =>
  state.rootReducer.lastLocationModification
export const getFirstLoadingFlag = (state) =>
  state.rootReducer.firstLoadingFinished
export const getOrganizationId = (state) => state.rootReducer.organization.id

function* loadUserPermissions() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/user-profiles/current')
    })

    if (data.message) {
      yield put(rootActions.loadUserPermissionsFailed(data.message))
    } else {
      yield put(rootActions.loadUserPermissionsSuccess(data))
    }
  } catch (e) {
    yield put(rootActions.loadUserPermissionsFailed(e.message))
  }
}

function* loadFeatureToggling() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/feature/')
    })

    if (data.message) {
      yield put(rootActions.loadFeatureTogglingFailed(data.message))
    } else {
      yield put(rootActions.loadFeatureTogglingSuccess(data))
    }
  } catch (e) {
    yield put(rootActions.loadFeatureTogglingFailed(e.message))
  }
}

function* loadBuildVersion() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/public/app/properties')
    })

    if (data.message) {
      yield put(rootActions.loadBuildVersionFailed(data.message))
    } else {
      yield put(rootActions.loadBuildVersionSuccess(data))
    }
  } catch (e) {
    yield put(rootActions.loadBuildVersionFailed(e.message))
  }
}

function* getDefaultDeviceMode() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/organizations/my')
    })

    if (data.message) {
      yield put(rootActions.getDefaultAccountModeFailed(data.message))
    } else {
      yield put(rootActions.getDefaultAccountModeSucceeded(data))
    }
  } catch (e) {
    yield put(rootActions.getDefaultAccountModeFailed(e.message))
  }
}

function* loadLocations() {
  try {
    const firstLoadingFinished = yield select(getFirstLoadingFlag)

    const data = yield call(() => {
      return HttpClient.get('/api/secured/locations/')
    })

    if (data.message) {
      yield put(customTreeActions.loadLocationsFailed(data.message))
    } else {
      if (!firstLoadingFinished) {
        yield put(rootActions.loadUserPermissions(data))
      }

      yield put(customTreeActions.loadInitialLocationTreeSuccess(data))
    }
  } catch (e) {
    yield put(customTreeActions.loadLocationsFailed(e.message))
  }
}

function* getLocationModificationId() {
  try {
    const lastModification = yield select(getLastModification)
    const firstLoadingFinished = yield select(getFirstLoadingFlag)

    const data = yield call(() => {
      return HttpClient.get(
        '/api/secured/organizations/last-modification/LOCATION'
      )
    })

    if (data.message) {
      yield put(rootActions.getLocationModificationIdFailed(data.message))
    } else {
      if (
        data['modified'] !== lastModification ||
        (!lastModification && !firstLoadingFinished)
      ) {
        yield put(rootActions.loadLocations(data))
      }

      yield put(rootActions.getLocationModificationIdSuccess(data['modified']))
    }
  } catch (e) {
    yield put(rootActions.getLocationModificationIdFailed(e.message))
  }
}

function* getBlueprintLink() {
  const orgId = yield select(getOrganizationId)

  try {
    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/organizations/blueprint-ready/${orgId}`
      )
    })

    if (data.message) {
      yield put(rootActions.getBlueprintLinkFailed(data.message))
    } else {
      yield put(rootActions.getBlueprintLinkSucceeded(data))
    }
  } catch (e) {
    yield put(rootActions.getBlueprintLinkFailed(e.message))
  }
}

function* getDefaultDeviceModeSaga() {
  yield takeLatest(
    rootActions.actionTypes.LOAD_FEATURE_TOGGLING_SUCCEEDED,
    getDefaultDeviceMode
  )
}

function* loadFeatureTogglingSaga() {
  yield takeLatest(
    rootActions.actionTypes.LOAD_USER_PERMISSIONS_SUCCEEDED,
    loadFeatureToggling
  )
}

function* getBlueprintLinkSaga() {
  yield takeLatest(rootActions.actionTypes.GET_BLUEPRINT_LINK, getBlueprintLink)
}

function* loginSucceededSaga() {
  yield all([
    takeLatest(
      rootActions.actionTypes.LOAD_USER_PERMISSIONS,
      loadUserPermissions
    ),
    takeLatest(
      rootActions.actionTypes.LOAD_USER_PERMISSIONS_SUCCEEDED,
      loadBuildVersion
    ),
    takeLatest(
      authorizationActions.actionTypes.LOGIN_TO_APP_SUCCEEDED,
      loadUserPermissions
    ),
    takeLatest(
      authorizationActions.actionTypes.LOGIN_FROM_FACTORY_SUCCEEDED,
      loadUserPermissions
    ),
  ])
}

function* loadLocationsSaga() {
  yield all([takeLatest(rootActions.actionTypes.LOAD_LOCATIONS, loadLocations)])
}

function* getLocationModificationIdSaga() {
  yield takeLatest(
    rootActions.actionTypes.GET_LOCATION_MODIFICATION_ID,
    getLocationModificationId
  )
}

function* loadConfigurationSaga() {
  yield takeLatest(
    rootActions.actionTypes.LOAD_CONFIGURATIONS,
    getLocationModificationId
  )
}

export default function* rootSaga() {
  yield fork(loginSucceededSaga)
  yield fork(loadFeatureTogglingSaga)
  yield fork(getDefaultDeviceModeSaga)
  yield fork(loadLocationsSaga)
  yield fork(getLocationModificationIdSaga)
  yield fork(loadConfigurationSaga)
  yield fork(getBlueprintLinkSaga)
}
