import { cloneDeep } from 'lodash'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { HttpClient } from '../../../../services/HttpClient'
import customTreeActions from '../../../../components/_shared/customTree/services/customTree-actions'
import {
  DEVICE_TYPE,
  PREF,
  TREE_STATE_ALL,
} from '../../../../services/constants'
import { prepareBodyForSummary } from '../../_shared/services/summary-service'
import { SUMMARY_PATH_REGEXP } from '../../_shared/services/summary-constants'
import arcSummaryActions from './arcSummary-actions'
import { getReportFileName } from 'containers/Summary/_shared/services/summary-helpers'

const getCurrentUserId = (state) => state.rootReducer.userId
const getUserTimezone = (state) => state.rootReducer.timezone
const getAllSelectedFlag = (state) => state.customTreeReducer.isAllSelected

const getLocationsIds = (state) => state.summaryReducer.ARCSummaryReducer.ids
const getDateFilter = (state) =>
  state.summaryReducer.ARCSummaryReducer.dateFilter
const getPeriod = (state) => state.summaryReducer.ARCSummaryReducer.period
const getLocationFilter = (state) =>
  state.summaryReducer.ARCSummaryReducer.locationFilter
const getActiveTab = (state) =>
  state.summaryReducer.sharedSummaryReducer.activeTab
const getOrganizationName = (state) => state.rootReducer.organization.name
const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* loadLocations() {
  try {
    const locationFilter = select(getLocationFilter)
    const userId = yield select(getCurrentUserId)
    const tree = yield select(getInitialNodesTree)

    yield put(
      customTreeActions.loadNodesSucceeded(userId, {
        ...tree,
        [PREF.SERVER_PREF]: locationFilter,
        isServerPref: true,
      })
    )
  } catch (e) {
    yield put(arcSummaryActions.loadLocationTreeFailed(e.message))
  }
}

function* getCyclesReport() {
  try {
    const isAllSelected = yield select(getAllSelectedFlag)
    const ids = isAllSelected ? null : yield select(getLocationsIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getPeriod)
    const timezone = yield select(getUserTimezone)

    const body = prepareBodyForSummary(
      ids,
      dateFilter,
      defaultPeriod,
      timezone,
      DEVICE_TYPE.arc
    )

    const data = yield call(() => {
      return HttpClient.post('/api/secured/report/activities/', { body })
    })

    if (data.message) {
      yield put(arcSummaryActions.getCyclesReportFailed(data.message))
    } else {
      yield put(arcSummaryActions.getCyclesReportSuccess(data, timezone))
    }
  } catch (e) {
    yield put(arcSummaryActions.getCyclesReportFailed(e.message))
  }
}

function* getDisinfectedLocations() {
  try {
    const isAllSelected = yield select(getAllSelectedFlag)
    const ids = isAllSelected ? null : yield select(getLocationsIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getPeriod)
    const timezone = yield select(getUserTimezone)

    const body = prepareBodyForSummary(
      ids,
      dateFilter,
      defaultPeriod,
      timezone,
      DEVICE_TYPE.arc
    )

    const data = yield call(() => {
      return HttpClient.post('/api/secured/report/locations', { body })
    })

    if (data.message) {
      yield put(arcSummaryActions.getDisinfectedLocationsFailed(data.message))
    } else {
      yield put(
        arcSummaryActions.getDisinfectedLocationsSuccess(data, timezone)
      )
    }
  } catch (e) {
    yield put(arcSummaryActions.getDisinfectedLocationsFailed(e.message))
  }
}

function* getActiveDevices() {
  try {
    const isAllSelected = yield select(getAllSelectedFlag)
    const ids = isAllSelected ? null : yield select(getLocationsIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getPeriod)
    const timezone = yield select(getUserTimezone)

    const body = prepareBodyForSummary(
      ids,
      dateFilter,
      defaultPeriod,
      timezone,
      DEVICE_TYPE.arc
    )

    const data = yield call(() => {
      return HttpClient.post('/api/secured/report/devices', { body })
    })

    if (data.message) {
      yield put(arcSummaryActions.getActiveDevicesFailed(data.message))
    } else {
      yield put(arcSummaryActions.getActiveDevicesSuccess(data, timezone))
    }
  } catch (e) {
    yield put(arcSummaryActions.getActiveDevicesFailed(e.message))
  }
}

function* downloadPDFPackage() {
  try {
    const isAllSelected = yield select(getAllSelectedFlag)
    const ids = isAllSelected ? null : yield select(getLocationsIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getPeriod)
    const timezone = yield select(getUserTimezone)
    const activeTab = yield select(getActiveTab)
    const name = yield select(getOrganizationName)

    const body = prepareBodyForSummary(
      ids,
      dateFilter,
      defaultPeriod,
      timezone,
      DEVICE_TYPE.arc
    )

    const data = yield call(() => {
      return HttpClient.post('/api/secured/report/pdf/SummaryArc', {
        body,
        isDownload: true,
        fileName: getReportFileName(name, activeTab),
      })
    })

    if (data.message) {
      yield put(arcSummaryActions.downloadPDFPackageFailed(data.message))
    } else {
      yield put(arcSummaryActions.downloadPDFPackageSuccess())
    }
  } catch (e) {
    yield put(arcSummaryActions.downloadPDFPackageFailed(e.message))
  }
}

function* setupLoadedLocations(action) {
  try {
    const { nodes, skipUpdate } = action.payload
    const userId = yield select(getCurrentUserId)
    const activeTab = yield select(getActiveTab)

    const isTriggeredFromSummary = SUMMARY_PATH_REGEXP.test(
      window.location.href
    )

    if (isTriggeredFromSummary && activeTab === DEVICE_TYPE.arc) {
      yield put(
        arcSummaryActions.prepareLocationFiler(TREE_STATE_ALL, nodes, userId)
      )

      if (!skipUpdate) {
        yield put(arcSummaryActions.getCyclesReport())
      }
    }
  } catch (e) {
    yield put(arcSummaryActions.applyLocationFilterFailed(e.message))
  }
}

function* loadLocationsTreeSaga() {
  yield takeLatest(arcSummaryActions.actionsTypes.LOAD_LOCATIONS, loadLocations)
}

function* getCyclesReportSaga() {
  yield all([
    takeLatest(
      arcSummaryActions.actionsTypes.GET_CYCLES_REPORT,
      getCyclesReport
    ),
    takeLatest(
      arcSummaryActions.actionsTypes.APPLY_LOCATION_FILTER,
      getCyclesReport
    ),
    takeLatest(
      arcSummaryActions.actionsTypes.CHANGE_DATE_FILTER,
      getCyclesReport
    ),
  ])
}

function* locationFilterIsReadySaga() {
  yield takeLatest(
    customTreeActions.actionsTypes.LOAD_NODES_SUCCESS,
    setupLoadedLocations
  )
}

function* getDisinfectedLocationsSaga() {
  yield takeLatest(
    arcSummaryActions.actionsTypes.GET_CYCLES_REPORT_SUCCEEDED,
    getDisinfectedLocations
  )
}

function* getActiveDevicesSaga() {
  yield takeLatest(
    arcSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_SUCCEEDED,
    getActiveDevices
  )
}

function* downloadPDFPackageSaga() {
  yield takeLatest(
    arcSummaryActions.actionsTypes.DOWNLOAD_PDF_PACKAGE,
    downloadPDFPackage
  )
}

export default function* arcSummarySagas() {
  yield fork(loadLocationsTreeSaga)
  yield fork(getCyclesReportSaga)
  yield fork(locationFilterIsReadySaga)
  yield fork(getDisinfectedLocationsSaga)
  yield fork(getActiveDevicesSaga)
  yield fork(downloadPDFPackageSaga)
}
