const customTreeActions = {}

customTreeActions.actionsTypes = {
  SET_EXPAND_NODE: '{CUSTOM_TREE} Set Expand Node',
  SET_PROPERTY_EXPAND_NODE: '{CUSTOM_TREE} Set Property Expand Node',

  SET_PROPERTY_CHECK_NODE: '{CUSTOM_TREE} Set Property Check Node',
  SET_CHECK_NODE: '{CUSTOM_TREE} Set Check Node',

  SET_CHECK_SINGLE_NODE: '{CUSTOM_TREE} Set Check Single Node',

  SELECT_ALL: '{CUSTOM_TREE} Select All',
  SET_SELECT_ALL_STATE: '{CUSTOM_TREE} Set Select All State',

  LOAD_NODES_SUCCESS: '{CUSTOM_TREE} Load Nodes Success',

  RESET_TO_PREVIOUS_STATE: '{CUSTOM_TREE} Reset to previous state',
  RESET_TREE_COPY_STATE: '{CUSTOM_TREE} Reset Tree State',

  LOAD_INITIAL_LOCATION_TREE_SUCCESS:
    '{CUSTOM_TREE} Load initial location tree success',
}

customTreeActions.loadInitialLocationTreeSuccess = function (nodes) {
  return {
    type: customTreeActions.actionsTypes.LOAD_INITIAL_LOCATION_TREE_SUCCESS,
    payload: {
      nodes,
    },
  }
}

customTreeActions.setExpandNode = function (key, propertyId) {
  return {
    type: customTreeActions.actionsTypes.SET_EXPAND_NODE,
    payload: {
      key,
      propertyId,
    },
  }
}

customTreeActions.setPropertyExpandNode = function (key, userId) {
  return {
    type: customTreeActions.actionsTypes.SET_PROPERTY_EXPAND_NODE,
    payload: {
      key,
      userId,
    },
  }
}

customTreeActions.setCheckNode = function (key, bool, mainLevel, propertyId) {
  return {
    type: customTreeActions.actionsTypes.SET_CHECK_NODE,
    payload: {
      key,
      bool,
      mainLevel,
      propertyId,
    },
  }
}

customTreeActions.setPropertyCheckNode = function (key, bool) {
  return {
    type: customTreeActions.actionsTypes.SET_PROPERTY_CHECK_NODE,
    payload: {
      key,
      bool,
    },
  }
}

customTreeActions.setCheckSingleNode = function (key, bool) {
  return {
    type: customTreeActions.actionsTypes.SET_CHECK_SINGLE_NODE,
    payload: {
      key,
      bool,
    },
  }
}

customTreeActions.selectAll = function (isSelectAll) {
  return {
    type: customTreeActions.actionsTypes.SELECT_ALL,
    payload: {
      isSelectAll,
    },
  }
}

customTreeActions.setSelectAllState = function () {
  return {
    type: customTreeActions.actionsTypes.SET_SELECT_ALL_STATE,
  }
}

customTreeActions.loadNodesSucceeded = function (
  id,
  nodes,
  finalNode,
  skipUpdate
) {
  return {
    type: customTreeActions.actionsTypes.LOAD_NODES_SUCCESS,
    payload: {
      id,
      nodes,
      finalNode,
      skipUpdate,
    },
  }
}

customTreeActions.resetTreeState = function () {
  return {
    type: customTreeActions.actionsTypes.RESET_TREE_COPY_STATE,
  }
}

export default customTreeActions
