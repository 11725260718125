import { COLORS } from '../../../../../services/constants'

const style = (theme) => ({
  addDeviceButton: {
    margin: '20px 0 15px',
  },
  locationField: {
    width: '100%',
    display: 'grid',
    borderRadius: '26px',
    paddingLeft: '15px',
    justifyContent: 'left',
    height: '56px',
    margin: '10px 0',
    fontSize: '16px',
    lineHeight: '19px',
    color: COLORS.GRAY,
  },
  locationError: {
    borderColor: COLORS.RED_ORANGE,
    color: COLORS.RED_ORANGE,
  },
  '@media (min-width: 1224px)': {
    addDeviceButton: {
      minWidth: '90px',
      fontSize: '14px',
      margin: 0,
    },
  },
})

export default style
