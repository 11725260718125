import React, { useEffect } from 'react'
import { TextField, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import style from './style'
import { ASSIGN_CYCLE_TIME_MODAL } from '../../service/location-constants'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

const AssignCycleTimeControl = (props) => {
  const {
    timeout,
    classes,
    value,
    setValue,
    inputError,
    setInputError,
    isMobile = true,
  } = props

  useEffect(() => {
    if (timeout !== value) {
      setValue(timeout)
    }

    setInputError(false)
  }, [])

  useEffect(() => {
    if (
      value < ASSIGN_CYCLE_TIME_MODAL.minValue ||
      value > ASSIGN_CYCLE_TIME_MODAL.maxValue
    ) {
      setInputError(ASSIGN_CYCLE_TIME_MODAL.validationMessage)
    } else {
      setInputError(false)
    }
  }, [value])

  const onChange = (event) => {
    const newValue = event.target.value

    if (
      (ASSIGN_CYCLE_TIME_MODAL.timeoutPattern.test(newValue) ||
        newValue === '') &&
      +newValue <= ASSIGN_CYCLE_TIME_MODAL.maxValue
    ) {
      setValue(+newValue)
    }
  }

  const onClickPlus = () => {
    if (value < ASSIGN_CYCLE_TIME_MODAL.maxValue) {
      setValue(value + 1)
    }
  }

  const onClickMinus = () => {
    if (value > ASSIGN_CYCLE_TIME_MODAL.minValue) {
      setValue(value - 1)
    }
  }

  return (
    <div className={classes.assignCycleTimeContainer}>
      <div className={classes.assignCycleTimeWrapper}>
        <IconButton className={classes.iconButton} onClick={onClickMinus}>
          <RemoveIcon />
        </IconButton>
        <TextField
          variant="outlined"
          className={classes.input}
          value={value}
          onChange={onChange}
          error={!!inputError}
          fullWidth
          autoFocus={!isMobile}
        />
        <IconButton className={classes.iconButton} onClick={onClickPlus}>
          <AddIcon />
        </IconButton>
      </div>
      <Typography
        color="textSecondary"
        variant="subtitle1"
        className={classes.subtitle}
      >
        {ASSIGN_CYCLE_TIME_MODAL.subtitle}
      </Typography>
      {inputError && (
        <Typography variant="body2" color="error">
          {inputError}
        </Typography>
      )}
    </div>
  )
}

export default withStyles(style)(AssignCycleTimeControl)
