import React, { useRef } from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import Typography from '@material-ui/core/Typography'
import {
  getActivitiesCalendarDate,
  MMMD,
  utcConverter,
  yyyyMMDFormat,
} from '../../../../../../services/dateFormat-service'
import { DEVICE_CARDS_LABELS } from '../../services/arcDeviceList-constants'
import TooltipWrapper from '../../../../_shared/components/tooltipWrapper/TooltipWrapper'
import { withMediaQuery } from '../../../../../../services/root/root-service'

const DeviceCard = (props) => {
  const { device, openDetails, timezone, isMobile, classes, gaEventSend } =
    props

  const nicknameRef = useRef()

  const lastUsedData = device.transactionId
    ? getActivitiesCalendarDate(
        utcConverter(device.lastEventTime, yyyyMMDFormat, timezone),
        timezone,
        MMMD
      )
    : DEVICE_CARDS_LABELS.noActivities

  const moveToDetails = () => {
    gaEventSend('device', { event_label: device.deviceSerialNumber })
    openDetails(device)
  }

  return (
    <div className={classes.deviceCard} onClick={moveToDetails}>
      <div className={classes.info}>
        <div className={classes.infoColumn}>
          {(!isMobile || device.deviceNickname) && (
            <p className={classes.secondaryText}>
              {device.deviceNickname && device.deviceSerialNumber}
            </p>
          )}
          <TooltipWrapper
            title={device.deviceNickname || device.deviceSerialNumber}
            isMobile={isMobile}
            nicknameRef={nicknameRef}
          >
            <Typography className={classes.mainText} noWrap ref={nicknameRef}>
              {device.deviceNickname || device.deviceSerialNumber}
            </Typography>
          </TooltipWrapper>
        </div>

        <div className={classes.timeColumn}>
          <p className={classes.secondaryText}>{device.propertyLabel}</p>
          <Typography className={classes.mainText} noWrap>
            {device.locationLabel}
          </Typography>
        </div>

        <div className={classes.timeColumn}>
          <p className={classes.secondaryText}>
            {DEVICE_CARDS_LABELS.timeLabel}
          </p>
          <p className={classes.mainText}>{lastUsedData}</p>
        </div>
      </div>
      <div className={classes.navigationRowItem}>
        <ArrowForwardIosIcon className={classes.rowIcon} />
      </div>
    </div>
  )
}

export default withMediaQuery(withStyles(styles)(DeviceCard))
