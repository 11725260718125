const styles = (theme) => ({
  modalTitle: {
    textAlign: 'center',
    marginTop: '14px',
    marginBottom: '6px ',
    paddingLeft: '16px',
  },
  dialogActions: {
    padding: '12px 8px 36px',
  },
  modalTitleContainer: {
    fontSize: 'inherit',
  },
  '@media (min-width: 1224px)': {
    dialogActions: {
      padding: '16px 70px 40px',
    },
    submitButton: {
      width: '100%',
    },
    dialogContent: {
      padding: '16px 70px 0px',
    },
  },
  outlined: {
    [theme.breakpoints.up('lg')]: {
      padding: '15.5px 14px',
    },
  },
})

export default styles
