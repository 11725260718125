import { IconButton, InputAdornment } from '@material-ui/core'
import {
  MANAGE_USERS_LABELS,
  USERS_DOCUMENT_TITLE,
} from './services/users-constants'
import React, { createRef, useEffect, useState } from 'react'
import {
  checkConnection,
  withMediaQuery,
} from '../../services/root/root-service'

import Button from '@material-ui/core/Button'
import ErrorHasOccurred from '../../components/_shared/errors/ErrorHasOccurred'
import LoaderComponent from '../../components/_shared/loader/Loader'
import NetworkError from '../../components/_shared/errors/NetworkError'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import UsersList from './components/UsersList'
import actions from './services/users-actions'
import classnames from 'classnames'
import { connect } from 'react-redux'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'

// we are disabling this because it's not yet supported by GandAuth
const ADD_USER_FEATURE = false

const UsersContainerComponent = (props) => {
  const {
    classes,
    filterUsers,
    isReadOnly,
    history,
    isLoading,
    loadUsers,
    isMobile,
    loadUsersError,
    networkError,
    isBannerPresent,
  } = props
  const [searchField, setSearchField] = useState('')
  const inputRef = createRef()

  const userListContainer = classnames(
    classes.userListContainer,
    isBannerPresent ? classes.userListContainerBannerOffset : ''
  )

  useEffect(() => {
    document.title = USERS_DOCUMENT_TITLE

    loadUsers()
  }, [])

  const handleSearchClick = () => {
    setSearchField(inputRef.current.value)
    filterUsers(inputRef.current.value.trim())
  }

  const onEnterPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearchClick()
    }
  }

  const clearSearch = () => {
    setSearchField('')
    filterUsers('')

    inputRef.current.value = ''
    inputRef.current.focus()
  }

  const addUser = () => {
    history.push('/users/add')
  }

  return (
    <LoaderComponent isLoading={isLoading && !networkError}>
      <div className={classes.usersPage}>
        <Typography
          variant={isMobile ? 'subtitle2' : 'h3'}
          className={classes.title}
        >
          {MANAGE_USERS_LABELS.usersListTitle}
        </Typography>
        {!networkError ? (
          <>
            <div className={classes.buttonAndSearchWrapper}>
              {ADD_USER_FEATURE && !isReadOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addUserButton}
                  onClick={addUser}
                >
                  {MANAGE_USERS_LABELS.addUserAction}
                </Button>
              )}
              <TextField
                className={classes.searchField}
                onKeyDown={onEnterPress}
                placeholder={MANAGE_USERS_LABELS.search}
                inputRef={inputRef}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearchClick}>
                        <SearchIcon className={classes.searchIcon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: { className: classes.input },
                }}
              />
            </div>
            {loadUsersError ? (
              <ErrorHasOccurred hideLogo={true} />
            ) : (
              <div className={userListContainer}>
                <UsersList
                  searchValue={searchField}
                  clearSearch={clearSearch}
                  history={history}
                />
              </div>
            )}
          </>
        ) : (
          <NetworkError hideLogo />
        )}
      </div>
    </LoaderComponent>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterUsers: (value) => dispatch(actions.filterUsersList(value)),
    loadUsers: () => dispatch(checkConnection(actions.loadUsersList)),
  }
}

const mapStateToProps = (state) => ({
  isReadOnly: state.rootReducer.isOperatorView,
  isLoading: state.usersReducer.isLoading,
  loadUsersError: state.usersReducer.loadUsersError,
  networkError: state.rootReducer.networkError,
  isBannerPresent: state.rootReducer.isBannerPresent,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(UsersContainerComponent)))
