import moment from 'moment'
import { DEVICE_TYPE_MAPPER } from 'services/constants'

export const getReportFileName = (
  orgName: string,
  activeTab: keyof typeof DEVICE_TYPE_MAPPER
) => {
  // first 5 initials of the organization name
  const initals = orgName
    .split(/[,.\s]+/)
    .map((n) => n[0])
    .join('')
    .substring(0, 5)

  return `${DEVICE_TYPE_MAPPER[activeTab]}-${initals}-${moment().format(
    'MMDDYYYY'
  )}.pdf`
}
