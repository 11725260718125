const styles = (theme) => ({
  banner: {
    width: '100%',
    height: '90px !important',
    textAlign: 'center',
    lineHeight: '90px',
    backgroundColor: '#36A1FF',
  },
  bannerLink: {
    color: 'white',
    fontFamily: 'Cerebri Sans Pro Regular',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  '@media (max-width: 1224px)': {
    banner: {
      marginBottom: '8px',
    },
  },
})

export default styles
