import { createTheme } from '@material-ui/core/styles'
import generalPagination from './generalThemePart'

const paginationThemeMobile = createTheme({
  palette: generalPagination.palette,
  text: generalPagination.text,
  overrides: {
    ...generalPagination.overrides,
    MuiPaginationItem: {
      root: {
        height: '27px',
        width: '27px',
        fontSize: '14px',
        lineHeight: '17px',
        minWidth: '27px',
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
  },
})

export default paginationThemeMobile
