import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  emptyUsersListContainer: {
    marginTop: '16px',
    paddingTop: '100px',
    width: '100%',
    height: 'calc(100% - 230px)',
    backgroundColor: COLORS.WHITE,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  usersIcon: {
    color: COLORS.GRAY_GAINSBORO,
    width: '100px',
    height: '100px',
  },
  description: {
    color: COLORS.CONTRAST_GRAY,
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '16px',
    lineHeight: '25px',
  },
  clearSearch: {
    color: COLORS.BLUE,
    fontFamily: 'Cerebri Sans Pro Medium',
    fontSize: '14px',
    minWidth: '10%',
    height: 'fit-content',
    backgroundColor: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: COLORS.BLUE_SAN_MARINO,
    },
  },
  '@media (max-width: 1224px)': {
    emptyUsersListContainer: {
      paddingTop: '40px',
    },
  },
})

export default styles
