import { COLORS } from './services/constants'

const styles = (theme) => ({
  authWrapper: {
    display: 'block',
    overflowY: 'auto !important',
  },
  buildInfo: {
    position: 'fixed',
    display: 'block',
    bottom: '10px',
    left: '10px',
    color: COLORS.BLUE,
  },
  hideBuildInfo: {
    display: 'none',
    position: 'fixed',
    bottom: '10px',
  },
  '@media (min-width: 1224px)': {
    pageContainer: {
      backgroundColor: COLORS.COLD_GRAY,
    },
  },
})

export default styles
