import { COLORS } from '../../../../../services/constants'

const styles = (theme) => ({
  filterChip: {
    marginTop: '12px',
    marginBottom: '30px',
  },
  '@media (max-width: 1224px)': {
    actionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      marginLeft: '-7%',
      bottom: 0,
      maxHeight: '200px',
      minHeight: '80px',
      height: 'fit-content',
      width: '100%',
      backgroundColor: COLORS.WHITE,
      borderTop: `1px solid ${COLORS.GRAY_GAINSBORO_SECONDARY}`,
      '& > *': {
        width: '250px',
      },
    },
  },
  '@media (min-width: 1224px)': {
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    filterChip: {
      height: '45px',
      borderRadius: '45px',
      margin: 0,
      padding: '0 20px',
    },
  },
})

export default styles
