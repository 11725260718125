import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  logoImage: {
    height: '30px',
    cursor: 'pointer',
    margin: 'auto 10px auto 0',
  },
  menuButton: {
    marginLeft: '10px',
  },
  tabsContainer: {
    height: 'calc(100% - 120px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
  },
  mobileHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  appBar: {
    height: '90px',
    boxShadow: 'none',
    display: 'grid',
    paddingLeft: '7%',
    paddingRight: '7%',
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  itemName: {
    textDecoration: 'none',
    color: COLORS.CONTRAST_GRAY,
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    margin: 'auto 8px',
  },
  linkTab: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  selectedItem: {
    color: COLORS.DODGER_BLUE,
    padding: '8px 22px',
    borderRadius: '24px',
    backgroundColor: COLORS.COLD_GRAY,
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  account: {
    display: 'contents',
    maxWidth: '15%',
  },
  disabledLink: {
    pointerEvents: 'none',
  },
  paper: {
    width: '70%',
    opacity: '0.9',
    backgroundColor: COLORS.BLACK,
    padding: '45px 10px',
  },
  drawer: {
    '&.MuiDrawer-root': {
      zIndex: '2000 !important',
    },
  },
  iconWithHeight: {
    height: '21px',
    color: COLORS.CONTRAST_GRAY,
  },
  selectedIconWithHeight: {
    color: '#1996FF!important',
    height: '21px',
  },
  noBorder: {
    '&::after': {
      display: 'none',
    },
  },
  listItem: {
    fontFamily: 'Cerebri Sans Pro Regular',
    color: COLORS.WHITE,
    fontSize: '14px',
    lineHeight: '17px',
    paddingTop: '11px',
    paddingBottom: '11px',
    '&::after': {
      content: '""',
      right: '25px',
      borderBottom: `1px solid ${COLORS.WHITE}`,
      position: 'absolute',
      top: '46px',
      left: '50px',
      opacity: '0.1',
    },
    '& .MuiListItemIcon-root': {
      color: COLORS.WHITE,
    },
  },
  listItemText: {
    margin: '0 auto',
  },
  listItemTextSelected: {
    color: COLORS.DODGER_BLUE,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    '&::after': {
      left: '65px',
    },
  },
  icon: {
    color: COLORS.CONTRAST_GRAY,
  },
  selectedIcon: {
    color: '#1996FF!important',
  },
  iconButton: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    marginTop: '12px',
  },
  popover: {
    pointerEvents: 'none',
  },
  name: {
    color: COLORS.WHITE,
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '0 16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  orgName: {
    color: COLORS.WHITE,
    padding: '0 16px',
    marginTop: '3px',
  },
  '@media (min-width: 1224px)': {
    logoImage: {
      height: '36px',
      margin: 'auto 50px auto 0',
    },
    appBar: {
      height: '81px',
      display: 'grid',
      paddingLeft: '23px',
      paddingRight: '35px',
      boxShadow: `0 2px 4px 0 ${COLORS.GRAY_ZIRCON}`,
    },
    noShadow: {
      boxShadow: 'none',
    },
    menuItem: {
      height: '27px',
      display: 'flex',
      alignItems: 'center',
      padding: '8px 12px',
      '&:hover': {
        borderRadius: '24px',
        backgroundColor: COLORS.COLD_GRAY,
      },
    },
    linkItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '12px',
    },
    dropdownMenu: {
      overflow: 'hidden',
      border: `1px solid ${COLORS.WHITE}`,
      borderRadius: '15px',
      boxShadow: `2px 2px 6px 3px ${COLORS.GRAY_ZIRCON}`,
      marginTop: '15px',
      '& .MuiList-padding': {
        padding: '0',
      },
    },
    listItem: {
      color: COLORS.CONTRAST_GRAY,
      paddingTop: '5px',
      paddingBottom: '5px',
      height: '42px',
      '&:not(:last-child)::after': {
        content: '""',
        width: '100%',
        borderBottom: `1px solid ${COLORS.CONTRAST_GRAY}`,
        position: 'absolute',
        opacity: '0.1',
        top: '41px',
      },
      '& .MuiListItemText-root': {
        flex: 'inherit',
      },
      '& .MuiListItemIcon-root': {
        color: COLORS.CONTRAST_GRAY,
      },
    },
    userNameWrapper: {
      height: '44px',
      display: 'flex',
      minWidth: '15%',
      textAlign: 'left',
      padding: '0 12px 16px',
      borderRadius: '18px',
      '&:hover': {
        backgroundColor: COLORS.COLD_GRAY,
      },
    },
    longLine: {
      '&:not(:last-child)::after': {
        left: '0',
        right: '0',
      },
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    accountIcon: {
      borderRadius: '20px',
      color: COLORS.CONTRAST_GRAY,
    },
    orgName: {
      fontSize: '12px',
      opacity: '40%',
      fontFamily: 'Cerebri Sans Pro Medium',
      marginTop: '3px',
      padding: '0',
      color: 'inherit',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    userName: {
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    settingsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '4px',
    },
    activeItemName: {
      color: COLORS.DODGER_BLUE,
      borderRadius: '24px',
      backgroundColor: COLORS.COLD_GRAY,
    },
  },
  '@media (max-width: 1460px)': {
    appBar: {
      padding: '0 1%',
    },
    userNameWrapper: {
      padding: '0 12px 16px',
      maxWidth: '150px',
    },
    logoImage: {
      margin: 'auto 10px auto 0',
    },
    itemName: {
      margin: 'auto 2px',
    },
    userName: {
      maxWidth: '80px',
    },
    orgName: {
      maxWidth: '80px',
    },
  },
  '@media (max-width: 1750px)': {
    settingsWrapper: {
      maxWidth: '150px',
    },
  },
  '@media (max-width: 1223.9px)': {
    appBar: {
      padding: '0 7%',
    },
  },
})

export default styles
