const dashboardActions = {}

dashboardActions.actionsTypes = {
  START_POLLING_MODIFICATION: '{DASHBOARD} Load Last Modification',
  POLL_MODIFICATION_SUCCEEDED: '{DASHBOARD} Load Last Modification Succeeded',
  POLL_MODIFICATION_FAILED: '{DASHBOARD} Load Last Modification Failed',

  STOP_POLLING_MODIFICATION: '{DASHBOARD} Stop Load Last Modification',

  LOAD_ACTIVITIES: '{DASHBOARD} Load Activities',
  LOAD_ACTIVITIES_SUCCEEDED: '{DASHBOARD} Load Activities Succeeded',
  LOAD_ACTIVITIES_FAILED: '{DASHBOARD} Load Activities Failed',

  GET_CYCLE_COUNT: '{DASHBOARD} Get Cycle Count',
  GET_CYCLE_COUNT_SUCCEEDED: '{DASHBOARD} Get Cycle Count Succeeded',
  GET_CYCLE_COUNT_FAILED: '{DASHBOARD} Get Cycle Count Failed',

  CHANGE_FILTER: '{DASHBOARD} Change Filter',
  CHANGE_DATE_FILTER: '{DASHBOARD} Change Date Filter',

  LOAD_LOCATION_TREE: '{DASHBOARD} Load Location Tree',
  LOAD_LOCATION_TREE_FAILED: '{DASHBOARD} Load Location Tree Failed',

  APPLY_LOCATION_FILTER: '{DASHBOARD} Apply Location Filter',
  APPLY_LOCATION_FILTER_FAILED: '{DASHBOARD} Apply Location Filter Failed',

  REFRESH_ACTIVITIES: '{DASHBOARD} Refresh Activities',
  PREPARE_LOCATION_FILTER: '{DASHBOARD} Prepare Location Filter',

  GET_ACTIVITIES: '{DASHBOARD} Get activities',

  SET_EXPANDED: '{DASHBOARD} Set expanded',
  SET_STATUS_LABEL: '{DASHBOARD} Set status label',
  SET_DEVICES_TYPES_LABEL: '{DASHBOARD} Set devices types label',

  RESET_FILTERS: '{DASHBOARD} Reset Filters',

  DOWNLOAD: '{DASHBOARD} Download CSV',
  DOWNLOAD_SUCCEEDED: '{DASHBOARD} Download CSV Succeeded',
  DOWNLOAD_FAILED: '{DASHBOARD} Download CSV Failed',

  DOWNLOAD_REPORT: '{DASHBOARD} Download Report',
  DOWNLOAD_REPORT_SUCCEEDED: '{DASHBOARD} Download Report Succeeded',
  DOWNLOAD_REPORT_FAILED: '{DASHBOARD} Download Report Failed',

  LOAD_DEVICES: '{DASHBOARD} Load Devices',
  LOAD_DEVICES_SUCCEEDED: '{DASHBOARD} Load Devices Succeeded',
  LOAD_DEVICES_FAILED: '{DASHBOARD} Load Devices Failed',

  SET_SELECTED_DEVICES: '{DASHBOARD} Set Selected Devices',

  SET_ROWS_PER_PAGE: '{DASHBOARD} Set Rows Per Page',
  GO_TO_NEXT_PAGE: '{DASHBOARD} Go To Next Page',
  SET_ENABLE_QUICK_COUNT: '{DASHBOARD} Set Enable Quick Count',

  SET_CURRENT_ACTIVITY_LOG_MODAL_ID:
    '{DASHBOARD} Set Current Activity Log Modal Id',
}

dashboardActions.startPollingModification = () => {
  return {
    type: dashboardActions.actionsTypes.START_POLLING_MODIFICATION,
  }
}

dashboardActions.pollModificationSucceeded = (id) => {
  return {
    type: dashboardActions.actionsTypes.POLL_MODIFICATION_SUCCEEDED,
    payload: {
      id,
    },
  }
}

dashboardActions.pollModificationFailed = (message) => {
  return {
    type: dashboardActions.actionsTypes.POLL_MODIFICATION_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.stopPollingModification = () => {
  return {
    type: dashboardActions.actionsTypes.STOP_POLLING_MODIFICATION,
  }
}

dashboardActions.loadActivities = function (period, statuses) {
  return {
    type: dashboardActions.actionsTypes.LOAD_ACTIVITIES,
    payload: {
      period,
      statuses,
    },
  }
}

dashboardActions.loadActivitiesSuccess = function (activities) {
  return {
    type: dashboardActions.actionsTypes.LOAD_ACTIVITIES_SUCCEEDED,
    payload: {
      activities,
    },
  }
}

dashboardActions.loadActivitiesFailed = function (message) {
  return {
    type: dashboardActions.actionsTypes.LOAD_ACTIVITIES_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.getCycleCount = function (query, isLast) {
  return {
    type: dashboardActions.actionsTypes.GET_CYCLE_COUNT,
    payload: {
      query,
      isLast,
    },
  }
}

dashboardActions.getCycleCountSuccess = function (data, isLast) {
  return {
    type: dashboardActions.actionsTypes.GET_CYCLE_COUNT_SUCCEEDED,
    payload: {
      data,
      isLast,
    },
  }
}

dashboardActions.getCycleCountFailed = function (message) {
  return {
    type: dashboardActions.actionsTypes.GET_CYCLE_COUNT_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.changeFilter = function (name, selected) {
  return {
    type: dashboardActions.actionsTypes.CHANGE_FILTER,
    payload: {
      name,
      selected,
    },
  }
}

dashboardActions.changeDateFilter = function ({ dateFilter, period }, label) {
  return {
    type: dashboardActions.actionsTypes.CHANGE_DATE_FILTER,
    payload: {
      dateFilter,
      period,
      label,
    },
  }
}

dashboardActions.loadLocationTree = function () {
  return {
    type: dashboardActions.actionsTypes.LOAD_LOCATION_TREE,
  }
}

dashboardActions.loadLocationTreeFailed = function (message) {
  return {
    type: dashboardActions.actionsTypes.LOAD_LOCATION_TREE_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.applyLocationFilterFailed = function (message) {
  return {
    type: dashboardActions.actionsTypes.APPLY_LOCATION_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.applyLocationFilter = function (nodes) {
  return {
    type: dashboardActions.actionsTypes.APPLY_LOCATION_FILTER,
    payload: {
      nodes,
    },
  }
}

dashboardActions.refreshActions = function () {
  return {
    type: dashboardActions.actionsTypes.REFRESH_ACTIVITIES,
  }
}

dashboardActions.prepareLocationFiler = function (nodes, userPref) {
  return {
    type: dashboardActions.actionsTypes.PREPARE_LOCATION_FILTER,
    payload: {
      nodes,
      userPref,
    },
  }
}

dashboardActions.setExpanded = function (value, id) {
  return {
    type: dashboardActions.actionsTypes.SET_EXPANDED,
    payload: {
      value,
      id,
    },
  }
}

dashboardActions.setStatusLabel = function (statusLabel) {
  return {
    type: dashboardActions.actionsTypes.SET_STATUS_LABEL,
    payload: {
      statusLabel,
    },
  }
}

dashboardActions.setDevicesTypesLabel = function (devicesTypesLabel) {
  return {
    type: dashboardActions.actionsTypes.SET_DEVICES_TYPES_LABEL,
    payload: {
      devicesTypesLabel,
    },
  }
}

dashboardActions.resetFilters = function () {
  return {
    type: dashboardActions.actionsTypes.RESET_FILTERS,
  }
}

dashboardActions.downloadCSV = function () {
  return {
    type: dashboardActions.actionsTypes.DOWNLOAD,
  }
}

dashboardActions.downloadCSVSuccess = function () {
  return {
    type: dashboardActions.actionsTypes.DOWNLOAD_SUCCEEDED,
  }
}

dashboardActions.downloadCSVFailed = function (message) {
  return {
    type: dashboardActions.actionsTypes.DOWNLOAD_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.downloadReport = function () {
  return {
    type: dashboardActions.actionsTypes.DOWNLOAD_REPORT,
  }
}

dashboardActions.downloadReportSuccess = function () {
  return {
    type: dashboardActions.actionsTypes.DOWNLOAD_REPORT_SUCCEEDED,
  }
}

dashboardActions.downloadReportFailed = function (message) {
  return {
    type: dashboardActions.actionsTypes.DOWNLOAD_REPORT_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.loadDevices = function () {
  return {
    type: dashboardActions.actionsTypes.LOAD_DEVICES,
  }
}

dashboardActions.loadDevicesSuccess = function (devices) {
  return {
    type: dashboardActions.actionsTypes.LOAD_DEVICES_SUCCEEDED,
    payload: {
      devices,
    },
  }
}

dashboardActions.loadDevicesFailed = function (message) {
  return {
    type: dashboardActions.actionsTypes.LOAD_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.setSelectedDevices = function (
  selectedDevices,
  includeDeleted
) {
  return {
    type: dashboardActions.actionsTypes.SET_SELECTED_DEVICES,
    payload: {
      selectedDevices,
      includeDeleted,
    },
  }
}

dashboardActions.goToNextPage = function (newPage) {
  return {
    type: dashboardActions.actionsTypes.GO_TO_NEXT_PAGE,
    payload: {
      newPage,
    },
  }
}

dashboardActions.setEnableQuickCount = function (enableQuickCount) {
  return {
    type: dashboardActions.actionsTypes.SET_ENABLE_QUICK_COUNT,
    payload: {
      enableQuickCount,
    },
  }
}

dashboardActions.setCurrentActivityLogModalId = function (state) {
  return {
    type: dashboardActions.actionsTypes.SET_CURRENT_ACTIVITY_LOG_MODAL_ID,
    payload: {
      state,
    },
  }
}

dashboardActions.setRowsPerPage = (rowsPerPage) => {
  return {
    type: dashboardActions.actionsTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }
}

export default dashboardActions
