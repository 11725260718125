import { COLORS } from '../../../../services/constants'

const styles = () => ({
  devicesPage: {
    height: 'calc(100% - 200px)',
  },
  devicesPageWrapper: {
    width: '100%',
  },
  search: {
    width: '80%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '@media (min-width: 1224px)': {
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '80%',
      margin: 'auto',
    },
    devicesPage: {
      height: 'calc(100% - 200px)',
      overflowY: 'auto',
    },
    addDeviceContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  '@media (max-width: 1224px)': {
    devicesPage: {
      height: 'calc(100% - 300px)',
    },
    addDeviceContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      left: 0,
      bottom: 0,
      maxHeight: '200px',
      minHeight: '80px',
      height: 'fit-content',
      width: '100%',
      backgroundColor: COLORS.WHITE,
      borderTop: `1px solid ${COLORS.GRAY_GAINSBORO_SECONDARY}`,
      '& > *': {
        width: '250px',
      },
    },
  },
})

export default styles
