import {
  COLORS,
  DATE_FILTER,
  DEVICE_TYPE,
  LOCATION_FILTER_LABELS,
  PAGINATION,
} from '../../../services/constants'

const DASHBOARD_PAGE_TITLE = 'Dashboard | R-Zero'

const ACTIVITY_DETAILS_NAMES = {
  started: 'created',
  cycle: 'time.cycle',
  remaining: 'time.remaining',
  assigned: 'deviceSerialNumber',
  // error: 'error.calculation'
}

const ACTIVITY_DETAILS_CYCLE_LABELS = {
  [ACTIVITY_DETAILS_NAMES.started]: {
    label: 'Start Time',
    type: 'date',
  },
  [ACTIVITY_DETAILS_NAMES.cycle]: {
    label: 'Cycle Time',
    type: 'time',
  },
  [ACTIVITY_DETAILS_NAMES.remaining]: {
    label: 'Time Remaining',
    type: 'time',
  },
  [ACTIVITY_DETAILS_NAMES.assigned]: {
    label: 'Device',
    type: 'text',
  },
  // [ACTIVITY_DETAILS_NAMES.error]: {
  //     label: 'Errors',
  //     type: 'text'
  // },
}

const ACTIVITY_DETAILS_COUNTDOWN_LABELS = {
  [ACTIVITY_DETAILS_NAMES.started]: {
    label: 'Start Time',
    type: 'date',
  },
  [ACTIVITY_DETAILS_NAMES.assigned]: {
    label: 'Device',
    type: 'text',
  },
}

const ACTIVITY_DETAILS_RUN_TIME_LABELS = {
  [ACTIVITY_DETAILS_NAMES.assigned]: {
    label: 'Device',
    type: 'text',
  },
}

const PROGRESS_LABELS = {
  countdown: 'Countdown',
  stopped: 'Stopped',
  preCycle: 'Pre-Cycle',
}

const RUN_TIME = {
  label: 'Run Time',
  default: '00:00',
}

const STATUS_COLOR = {
  in_progress: COLORS.ROYAL_PURPLE,
  completed: COLORS.GREEN,
  paused: COLORS.YELLOW,
  cancelled: COLORS.RED_ORANGE,
  disconnected: COLORS.ORANGE_NEON_CARROT,
}

const DASHBOARD_TITLE = 'Recent Activity'
const OFFLINE_MODE = 'Offline'

const FIELDS = {
  phase: 'transaction.phase',
  countdown: 'countdown',
  cycle: 'cycle',
  disconnected: 'timeout.disconnected',
  interruptionPeriod: 'time.in_interruption.millis',
  interruptionTimes: 'times.interruption',
  estCompletion: 'est.completion.time',
  eventsSeq: 'events.seq',
  lastEvent: 'time.lastEvent',
  cycleStart: 'time.cycle.start',
  cycleTime: 'time.cycle',
  remaining: 'time.remaining',
  pausePhase: 'uvgi.pause.phase',
  countdownStart: 'uvgi.countdown.start',
  errorCalculation: 'error.calculation',
  inProgress: 'time.in_progress',
  offline: 'transaction.offline',
  eventArrivalTime: 'eventArrivalTime',
  lastOnlineKnownTime: 'lastOnlineKnownTime',
}

const DEVICE_TYPE_FILTER = {
  label: (value) => `Device Type: ${value}`,
  apply: 'Apply',
  filterName: 'Device Type Filter',
}

const EMPTY_ACTIVITIES_TEXT =
  'As you use the R-Zero devices, your recent activity will appear here'

const NOTIFICATION_LABEL = 'Notification'

const INCORRECT_TRANSACTION_DESCRIPTION =
  'Some cycle information is missing or incomplete. This activity will not be included in any summary data.'

const STATUS_FILTER_LABELS = {
  label: (value) => `Status: ${value}`,
  apply: 'Apply',
  filterName: 'Status Filter',
  download: 'Download CSV',
}

const DEVICE_EVENT_TABLE_LABELS = {
  type: 'Type',
  time: 'Time',
  otherInfo: 'Other Info',
}

const DEVICE_LABELS = {
  device: 'Device',
  deviceFilter: 'Device Filter',
  apply: 'Apply',
  all: 'All',
  multiple: 'Multiple',
  selectAll: 'Select All',
  includeDeletedDevices: 'Include Deleted Devices',
  none: '--',
}

const DEVICES_TYPES_FILTER = [DEVICE_TYPE.arc, DEVICE_TYPE.uvgi]

const initialState = {
  activities: [],
  error: '',
  isOnline: window.navigator.onLine,
  isInitialState: true,
  isLoading: false,
  statusFilter: {
    completed: true,
    cancelled: true,
    paused: true,
    in_progress: true,
  },
  defaultTypesFilter: {},
  devicesFilter: {},
  dateFilter: {
    today: false,
    week: true,
    month: false,
    custom: false,
  },
  period: {},
  locationFilter: {},
  deviceFilterLabel: LOCATION_FILTER_LABELS.all,
  statusLabel: LOCATION_FILTER_LABELS.all,
  devicesTypesLabel: LOCATION_FILTER_LABELS.all,
  dateFilterLabel: DATE_FILTER.week,
  devices: [],
  deviceFilter: {
    selectedDevices: [],
    isAllDevicesSelected: true,
    includeDeleted: false,
  },
  page: 1,
  maxPage: 1,
  rowsPerPage: PAGINATION.perPageValues[0],
  firstDayActivity: [],
  lastDayActivity: [],
  needToPollActivities: true,
  currentActivityLogModalId: '',
  isDownloadLoading: false,
  lastModification: null,
  needToUpdateData: true,
}

const DOWNLOAD_LABELS = {
  downloadCSV: 'Download Source Data',
  downloadReport: 'Download Report',
}

const SPEED_OF_REQUEST = 3000

const MORE_LABEL = '...More'

const OK_LABEL = 'OK'

const DEVICE_TYPE_MAPPER = {
  UVI_ARC: 'Arc',
  UR_UVGI: 'Beam',
  FAR_UVC: 'Vive',
}

const TYPES_OF_CYCLE_COUNT = {
  CYCLE_COUNT: 'CycleCount',
  FIRST_CYCLE_COUNT: 'FirstCycleCount',
}

const NONE_VALUE = '- -'

const ZERO_TIME_VALUE = '0:00'

const DOWNLOAD_MODAL = {
  title: 'Select Report to Download',
  report: 'Report',
  sourceData: 'Source Data',
  submit: 'Download',
}

const DEVICE_ERROR_CODE = 'Device Error Code'

const CANCELLATION_ERRORS = {
  'Fan issue': 'NON_FUNCTIONING_FAN',
  'Wall mount issue': 'BAD_MOUNT_CONTACT',
  'Cancelled by command': 'CANCELLED_BY_COMMAND',
  'Standby activated': 'STANDBY_ACTIVATED',
}

const getOfflineMessage = (start, end) =>
  `Activity occurred between ${start} and ${end}.`

export {
  DASHBOARD_PAGE_TITLE,
  ACTIVITY_DETAILS_NAMES,
  ACTIVITY_DETAILS_CYCLE_LABELS,
  ACTIVITY_DETAILS_COUNTDOWN_LABELS,
  ACTIVITY_DETAILS_RUN_TIME_LABELS,
  PROGRESS_LABELS,
  RUN_TIME,
  STATUS_COLOR,
  DASHBOARD_TITLE,
  FIELDS,
  DEVICE_TYPE_FILTER,
  EMPTY_ACTIVITIES_TEXT,
  STATUS_FILTER_LABELS,
  DEVICE_EVENT_TABLE_LABELS,
  DEVICE_LABELS,
  initialState,
  DOWNLOAD_LABELS,
  SPEED_OF_REQUEST,
  MORE_LABEL,
  NOTIFICATION_LABEL,
  INCORRECT_TRANSACTION_DESCRIPTION,
  OK_LABEL,
  DEVICE_TYPE_MAPPER,
  DEVICES_TYPES_FILTER,
  NONE_VALUE,
  ZERO_TIME_VALUE,
  TYPES_OF_CYCLE_COUNT,
  DOWNLOAD_MODAL,
  DEVICE_ERROR_CODE,
  CANCELLATION_ERRORS,
  OFFLINE_MODE,
  getOfflineMessage,
}
