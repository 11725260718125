import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { Spinner } from 'spin.js'
import 'spin.js/spin.css'
import styles from './style'
import SvgIconComponent from '../SvgIconComponent'
import { opts } from './loader-service'
import { COLORS } from '../../../services/constants'

const LoaderComponent = (props) => {
  const {
    isLoading,
    classes,
    children,
    inModal = false,
    loaderKey = 'root',
    fullScreen,
  } = props
  const id = `loader-${loaderKey}`

  useEffect(() => {
    const target = document.getElementById(id)
    const spinner = new Spinner(opts(inModal))

    if (isLoading) {
      spinner.spin(target)
    } else {
      spinner.stop()
    }
  }, [isLoading])

  return isLoading ? (
    fullScreen ? (
      <Backdrop className={classes.fullScreen} open={isLoading}>
        <div className={classes.wrapper}>
          <div id={id} />
        </div>
      </Backdrop>
    ) : (
      <div className={classes.wrapper}>
        <div className={classes.iconWrapper}>
          <SvgIconComponent
            name="devices-2x2"
            size={30}
            color={COLORS.LIGHT_GRAY_SECONDARY}
          />
        </div>
        <div id={id} />
      </div>
    )
  ) : (
    <>{children}</>
  )
}

export default withStyles(styles)(LoaderComponent)
