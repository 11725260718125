import { COLORS } from '../../../../services/constants'

const style = () => ({
  openMenuButton: {
    marginLeft: 'auto',
  },
  modalTitleContainer: {
    paddingBottom: 0,
    fontSize: 'inherit',
  },
  modalTitle: {
    textAlign: 'center',
    marginTop: '14px',
    marginBottom: '6px ',
    paddingLeft: '16px',
  },
  dialogContent: {
    paddingBottom: '35px',
  },
  chipsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '150px',
    margin: 'auto',
    '& > div:last-child': {
      marginTop: '10px',
    },
  },
  selectedChip: {
    borderColor: COLORS.BLUE,
  },
  submitButton: {
    marginTop: '20px',
    minWidth: '170px',
  },
})

export default style
