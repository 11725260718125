import { COLORS } from '../../../services/constants'

const style = () => ({
  selectedTextColor: {
    '& > .MuiSelect-select *': {
      color: COLORS.BLACK,
    },
  },
  smallPaddings: {
    '& > .MuiSelect-outlined': {
      paddingBottom: '12px',
      paddingTop: '12px',
    },
  },
  labelForSmallPaddings: {
    top: '-6px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
})

export default style
