const styles = (theme) => ({
  badge: {
    width: 'fit-content',
    maxWidth: '86%',
  },
  locationFilter: {
    marginRight: '10px',
  },
})

export default styles
