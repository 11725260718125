import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  calendarHeader: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: COLORS.WHITE,
    lineHeight: '46px',
    fontSize: '14px',
  },
  datePicker: {
    fontFamily: 'Cerebri Sans Pro Regular',
    borderRadius: 0,
    borderColor: COLORS.GRAY_IRON,
    '& .react-datepicker__header': {
      backgroundColor: COLORS.WHITE,
      padding: 0,

      '& .react-datepicker__day-names': {
        fontFamily: 'Cerebri Sans Pro Semi Bold',

        '& .react-datepicker__day-name': {
          color: COLORS.GRAY_SILVER_CHALICE,
          fontSize: '10px',
          fontWeight: 'normal',
          margin: '0',
          width: '32px',
        },
      },
      borderBottom: 'none',
    },
    '& div.react-datepicker__day--in-selecting-range': {
      color: COLORS.GRAY_SILVER_CHALICE,
    },
    '& div.react-datepicker__day--disabled': {
      color: COLORS.GRAY_SILVER_CHALICE,
    },
    '& .customDay': {
      fontSize: '12px',
      fontWeight: '300',
      backgroundColor: COLORS.WHITE,
      color: COLORS.BLACK,
      outline: 'none',
      lineHeight: '32px',
      margin: '0.166rem 0',
      width: '32px',
      ' -webkit-tap-highlight-color': 'transparent',
    },
    '& .startDay': {
      backgroundColor: COLORS.WARM_BLUE,
      borderRadius: '17px 0 0 17px',
    },
    '& .endDay': {
      backgroundColor: COLORS.WARM_BLUE,
      borderRadius: '0 17px 17px 0',
    },
    '& .todayDay': {
      color: COLORS.BLUE,
    },
  },
  betweenDay: {
    backgroundColor: COLORS.WARM_BLUE,
    color: COLORS.BLACK,
  },
  todayContent: {
    // color: 'black',
    // backgroundColor: '#1796ff'
  },
  emptyDay: {
    display: 'none',
  },
  selectedDay: {
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,
    borderRadius: '17px',
  },
})

export default styles
