import { COLORS } from '../../../../services/constants'

const style = () => ({
  cardsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  cardContainer: {
    backgroundColor: COLORS.WARM_GRAY,
    padding: '10px',
    width: '42%',
    height: '45px',
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  iconWrapper: {
    marginTop: '23px',
    marginBottom: '23px',
  },
  numberRow: {
    fontSize: '1.3rem',
    lineHeight: '24px',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  filters: {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'center',
  },
  '@media (min-width: 1224px)': {
    cardContainer: {
      backgroundColor: COLORS.WHITE,
      width: '43%',
      maxWidth: '500px',
      height: '102px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    },
    locationsAndDevicesContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > *': {
        width: '45%',
      },
    },
    locationsAndDevicesWrapper: {
      flexDirection: 'column',
      display: 'flex',
      marginBottom: '10px',
    },
    subTitles: {
      lineHeight: '25px',
    },
    numberRow: {
      fontSize: '2rem',
      lineHeight: '32px',
    },
    contentWrapper: {
      padding: '0 27%',
    },
  },
  '@media screen and (max-width: 420px) and (max-height: 820px)': {
    locationsAndDevicesContainer: {
      paddingBottom: '50px',
    },
  },
})

export default style
