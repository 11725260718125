import { COLORS } from '../../services/constants'

const styles = (theme) => ({
  addChildLocation: {
    height: '25px',
    color: COLORS.BLUE,
    padding: '5px 10px',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    marginBottom: 0,
    fontSize: '10px',
    marginRight: '10px',
  },
  addLocationButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  locationsPage: {
    paddingLeft: '7%',
    paddingRight: '7%',
    height: 'calc(100% - 81px)',
    overflowY: 'hidden',
  },
  locationCardsContainer: {
    height: 'calc(100vh - 220px)',
    overflowY: 'auto',
  },
  locationCardsContainerBannerOffset: {
    height: 'calc(100vh - 310px)',
  },
  locationCount: {
    fontSize: '10px',
    color: COLORS.GRAY,
  },
  locationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
    alignItems: 'center',
    backgroundColor: COLORS.WARM_GRAY,
    paddingLeft: '12px',
    marginBottom: '5px',
  },
  locationItem: {
    flexDirection: 'column',
    width: '100%',
    minWidth: '35%',
  },
  navigationRowItem: {
    width: '30px',
    lineHeight: '45px',
    height: '100%',
    borderLeft: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    display: 'flex',
    alignItems: 'center',
  },
  rowIconButton: {
    padding: 0,
    width: '30px',
    height: '30px',
    margin: 'auto 0',
  },
  rowIcon: {
    height: '14px',
    color: COLORS.BLACK,
  },
  card: {
    padding: '0 0 5px 0',
    marginBottom: '10px',
  },
  addLocationButton: {
    height: '27px',
    width: 'fit-content',
    marginBottom: '10px',
  },
  childrenLocationButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: '16px',
    color: COLORS.BLACK,
    textAlign: 'center',
    marginTop: '14px',
    marginBottom: '6px ',
    paddingLeft: '16px',
  },
  dialogActions: {
    padding: '12px 8px 36px',
  },
  closeIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
    color: COLORS.BLACK,
  },
  modalTitleContainer: {
    paddingBottom: 0,
  },
  placementCount: {
    minWidth: '100px',
    margin: 'auto',
    borderLeft: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  editIcon: {
    height: '18px',
    color: COLORS.BLACK,
  },
  controlsWrapper: {
    display: 'flex',
    height: '100%',
  },
  deleteIconWrapper: {
    paddingBottom: '5px',
  },
  '@media (min-width: 1224px)': {
    rowIcon: {
      height: '20px',
    },
    locationCardsContainer: {
      height: 'calc(100vh - 258px)',
    },
    locationCardsContainerBannerOffset: {
      height: 'calc(100vh - 348px)',
    },
    locationContainer: {
      backgroundColor: COLORS.WHITE,
      padding: '10px 0 10px 25px',
    },
    addLocationButton: {
      border: `1px solid ${COLORS.BLUE}`,
      color: COLORS.WHITE,
      padding: '20px',
      width: 'fit-content',
      whiteSpace: 'nowrap',
      marginBottom: 0,
      fontSize: '16px',
      marginLeft: '10px',
    },
    addLocationButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    card: {
      paddingTop: '20px',
    },
    navigationRowItem: {
      borderLeft: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '60px',
    },
    buttonAndNavigationBar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    locationCount: {
      fontSize: '12px',
      marginTop: '5px',
    },
    placementCount: {
      borderLeft: 'none',
    },
    grayIcon: {
      color: COLORS.GRAY,
    },
    locationItemEndPoint: {
      flexDirection: 'column',
      width: 'calc(100% - 120px)',
    },
    cycleTime: {
      marginTop: '5px',
      fontSize: '12px',
    },
  },
  '@media screen and (max-width: 420px) and (max-height: 820px)': {
    locationCardsContainer: {
      height: 'calc(100vh - 265px)',
      paddingBottom: '60px',
    },
  },
})

export default styles
