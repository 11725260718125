import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import useStyles from './style'

export default function CircularProgressWithLabel(props) {
  const { thickness, value } = props
  const classes = useStyles(props)
  const progressValue = `${Math.round(value)}%`

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        className={classes.circularProgress}
        thickness={thickness}
        variant="determinate"
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.label} component="div">
          {progressValue}
        </Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  circlecolor: PropTypes.string.isRequired,
}
