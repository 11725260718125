import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'
import { prepareLocationFiltersForDevice } from '../../../../../_shared/services/devices-service'
import { filterLocationsForDevice } from '../../../../../List/UVGI/services/uvgiDeviceList-service'
import { createDeviceLocationLabelForRooms } from '../../../services/uvgiDeviceDetails-service'
import styles from './style'
import classNames from 'classnames'
import { LOCATION_LABELS } from '../../../../../_shared/services/devices-constants'
import AssignLocationModal from './AssignLocationModal'
import { checkConnection } from '../../../../../../../services/root/root-service'
import actions from '../../../services/uvgiDeviceDetails-actions'
import { TREE_LEVELS } from '../../../../../../../services/constants'

const UVGILocationButton = (props) => {
  const {
    roomsFilter,
    locationFilter,
    setupRoomsFilter,
    nodes,
    disabled = false,
    loadNodesSucceeded,
    userId,
    treeCopy,
    polling,
    startPollingModification,
    stopPollingModification,
    deviceId,
    onTheList = false,
    loadLocationsForDevice,
    classes,
  } = props
  const [open, setOpen] = useState(false)
  const assignedLocations = createDeviceLocationLabelForRooms(
    roomsFilter,
    nodes,
    false
  )
  const [isUVGILocationError, setUVGILocationError] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)

    if (polling && !onTheList) {
      stopPollingModification()
    }
  }

  useEffect(() => {
    return () => {
      if (onTheList) {
        resetChangesAccordingLocationsFilter()
      }
    }
  }, [])

  const handleClose = () => {
    setUVGILocationError(false)
    setOpen(false)

    if (!onTheList) {
      startPollingModification(deviceId, true)
    }
  }

  const resetChangesAccordingLocationsFilter = () => {
    loadNodesSucceeded(
      userId,
      filterLocationsForDevice(treeCopy, locationFilter.selectedBuildings),
      TREE_LEVELS.ROOM
    )

    handleClose()
  }

  const resetChanges = () => {
    loadLocationsForDevice(handleClose)
  }

  const onApply = () => {
    const params = prepareLocationFiltersForDevice(nodes)
    setupRoomsFilter(params)
    setUVGILocationError && setUVGILocationError(!params.selectedName)

    handleClose()
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        className={
          isUVGILocationError
            ? classNames(classes.locationField, classes.locationError)
            : classes.locationField
        }
        label={assignedLocations}
        variant="outlined"
        disabled={disabled}
      />
      <AssignLocationModal
        onApply={onApply}
        actionLabel={LOCATION_LABELS.action}
        modalTitle={LOCATION_LABELS.roomModalTitle}
        resetChanges={resetChanges}
        isOpen={open}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { UVGIDeviceDetailsReducer },
    customTreeReducer,
    rootReducer,
  } = state

  return {
    userId: rootReducer.userId,
    treeCopy: customTreeReducer.treeCopy,
    nodes: customTreeReducer.nodes,
    isAllSelected: customTreeReducer.isAllSelected,
    roomsFilter: UVGIDeviceDetailsReducer.roomsFilter,
    locationFilter: UVGIDeviceDetailsReducer.locationFilter,
    allowedLocationIds: UVGIDeviceDetailsReducer.allowedLocationIds,
    polling: UVGIDeviceDetailsReducer.polling,
    deviceId: UVGIDeviceDetailsReducer.deviceId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startPollingModification: (id, startPoll) =>
      dispatch(actions.startPollingModification(id, startPoll)),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    setupRoomsFilter: (nodes) =>
      dispatch(checkConnection(() => actions.setupRoomsFilter(nodes))),
    loadLocationsForDevice: (onSuccess) =>
      dispatch(
        checkConnection(() => actions.loadLocationsForDevice(onSuccess))
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UVGILocationButton))
