import React, { useState, useEffect } from 'react'
import { find, keys } from 'lodash'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import { DEVICE_STATUS } from '../../../../../_shared/services/devices-constants'
import RunTime from './RunTime'
import { DEVICE_RUN_TIME } from '../../../services/uvgiDeviceDetails-constants'
import styles from './style'
import {
  getMsInSeconds,
  getTimeDifference,
} from '../../../services/uvgiDeviceDetails-service'
import {
  DEVICE_LABELS,
  FIELDS,
} from '../../../../../../Dashboard/services/dashboard-constants'
import StatusDot from '../../../../../_shared/components/statusDot/StatusDot'
import { checkDisconnectedStatus } from '../../../../../../../services/root/root-service'
import { UVGI_DEVICE_STATUSES } from '../../../../../../Summary/UVGI/services/uvgiSummary-constants'

const DeviceRunTime = (props) => {
  const {
    deviceDetailsStatus = {},
    deviceDetailsStatus: { totalRunTimeToday, status: deviceStatus } = {},
    device: { created, details, modified } = {},
    status,
    isInProgress,
    isMobile,
    timezone,
    classes,
  } = props
  const interruptionPeriod = details[FIELDS.interruptionPeriod]

  const [statusLabel, setStatusLabel] = useState('')

  const [isDisconnected, setIsDisconnected] = useState(() =>
    checkDisconnectedStatus(details[FIELDS.disconnected], modified)
  )

  useEffect(() => {
    setIsDisconnected(
      checkDisconnectedStatus(details[FIELDS.disconnected], modified)
    )
  }, [deviceStatus])

  const [difference, setDifference] = useState(() => {
    const nowMoment = isDisconnected ? moment(modified) : moment().utc()
    isInProgress ? getTimeDifference(created, nowMoment, interruptionPeriod) : 0
  })

  const differenceInSeconds = getMsInSeconds(difference)
  const executionTimeBeforeChangeStatus = moment(
    details[FIELDS.lastEvent]
  ).diff(moment(created), 'millisecond')

  //It is a time difference between the  cycle start and the last event, it's time until the interruption
  const totalRunTimeInProgress =
    totalRunTimeToday + //it is total run time today with included time in the not completed cycle
    difference - //It is a difference between the cycle start and  current time. It's required for getting running counter
    (executionTimeBeforeChangeStatus - interruptionPeriod)
  // time is required because 'difference' variable doesn't include  interruption length

  const statusDot = find(
    keys(DEVICE_STATUS),
    (key) => DEVICE_STATUS[key] === statusLabel
  )

  const commonTime =
    isInProgress && difference
      ? getMsInSeconds(totalRunTimeInProgress, true, timezone)
      : getMsInSeconds(totalRunTimeToday, true, timezone)

  useEffect(() => {
    setStatusLabel(deviceDetailsStatus && DEVICE_STATUS[deviceStatus])
  }, [deviceStatus])

  useEffect(() => {
    let differenceInterval

    if (isInProgress) {
      differenceInterval = setInterval(() => {
        const nowMoment = isDisconnected ? moment(modified) : moment().utc()
        setDifference(getTimeDifference(created, nowMoment, interruptionPeriod))
      }, 1000)

      const isDisconnected = checkDisconnectedStatus(
        details[FIELDS.disconnected],
        modified
      )

      setStatusLabel(
        isDisconnected
          ? DEVICE_STATUS[UVGI_DEVICE_STATUSES.DISCONNECTED]
          : DEVICE_STATUS[deviceStatus]
      )
    }

    return () => {
      clearInterval(differenceInterval)
    }
  }, [difference, status])

  useEffect(() => {
    if (!isInProgress) {
      setDifference(0)
    }
  }, [totalRunTimeToday])

  return (
    <div>
      <div className={classes.deviceStatusContainer}>
        <Typography variant={isMobile ? 'subtitle2' : 'h5'}>
          {DEVICE_STATUS.status}
        </Typography>
        <div className={classes.deviceStatus}>
          <StatusDot status={statusDot} />
          <div className={classes.deviceStatusLabel}>
            {DEVICE_LABELS.device} {statusLabel}
          </div>
        </div>
      </div>
      <div className={classes.mainContainer}>
        <RunTime
          time={isInProgress ? differenceInSeconds : ''}
          label={DEVICE_RUN_TIME.current}
          approx={deviceStatus === UVGI_DEVICE_STATUSES.DISCONNECTED}
        />
        <RunTime
          time={commonTime}
          label={DEVICE_RUN_TIME.total}
          approx={deviceStatus === UVGI_DEVICE_STATUSES.DISCONNECTED}
        />
        {deviceStatus === UVGI_DEVICE_STATUSES.DISCONNECTED && (
          <div className={classes.warningDisconnected}>
            <Alert
              severity="warning"
              icon={<span className={classes.warningAsterisk}>*</span>}
            >
              Device was disconnected from the internet. Cumulative time may
              exceed the captured time here.
            </Alert>
          </div>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(DeviceRunTime)
