import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ActivitiesLogDialog from './ActivitiesLogDialog'
import IconButton from '@material-ui/core/IconButton'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import actions from '../../../services/dashboard-actions'
import style from '../style'

const ActivitiesLogInfo = withStyles(style)((props) => {
  const {
    classes,
    activity,
    timezone,
    startPollingModification,
    stopPollingModification,
    currentActivityLogModalId,
    setCurrentActivityLogModalId,
    isInterruption,
    isIncorrect,
    isFaults,
  } = props

  const handleDialogOpen = () => {
    stopPollingModification()

    setCurrentActivityLogModalId(activity.id)
  }

  const handleDialogClose = () => {
    setCurrentActivityLogModalId('')

    startPollingModification()
  }

  return (
    <div className={classes.infoIcon}>
      <IconButton className={classes.infoFab} onClick={handleDialogOpen}>
        <MoreHoriz />
      </IconButton>
      <ActivitiesLogDialog
        isOpen={currentActivityLogModalId === activity.id}
        handleClose={handleDialogClose}
        activity={activity}
        timezone={timezone}
        isInterruption={isInterruption}
        isIncorrect={isIncorrect}
        isFaults={isFaults}
      />
    </div>
  )
})

const mapDispatchToProps = (dispatch) => {
  return {
    startPollingModification: () =>
      dispatch(actions.startPollingModification()),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    setCurrentActivityLogModalId: (state) =>
      dispatch(actions.setCurrentActivityLogModalId(state)),
  }
}

const mapStateToProps = (state) => ({
  currentActivityLogModalId: state.dashboardReducer.currentActivityLogModalId,
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesLogInfo)
