import React, { useEffect, useState } from 'react'
import styles from './style'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { PAGINATION } from '../../../services/constants'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { map } from 'lodash'
import { withMediaQuery } from '../../../services/root/root-service'
import CloseButton from '../modals/closeButton/CloseButton'

const ItemsPerPageModal = (props) => {
  const {
    isOpen,
    rowsPerPage,
    setRowsPerPage,
    isMobile,
    error,
    onCloseModal,
    classes,
  } = props
  const [selectedRows, setSelectedRows] = useState(rowsPerPage)

  useEffect(() => {
    if (isOpen) {
      setSelectedRows(rowsPerPage)
    }
  }, [isOpen])

  const setSelected = (event) => {
    setSelectedRows(+event.currentTarget.innerText)
  }

  const apply = () => {
    setRowsPerPage(selectedRows)
    onCloseModal()
  }

  return (
    <Dialog
      className={classes.dialog}
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle disableTypography>
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          className={classes.modalTitle}
        >
          {PAGINATION.perPage}
        </Typography>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <CloseButton onCloseModal={onCloseModal} />
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'space-around'}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          {map(PAGINATION.perPageValues, (item) => {
            const isSelected = selectedRows === item

            return (
              <Button
                key={item}
                variant="outlined"
                color={isSelected ? 'primary' : 'default'}
                className={classes.perPageChip}
                onClick={setSelected}
              >
                {item}
              </Button>
            )
          })}
        </Box>

        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={apply}
        >
          {PAGINATION.apply}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default withMediaQuery(withStyles(styles)(ItemsPerPageModal))
