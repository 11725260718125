const MENU_ITEMS_KEYS = {
  summaryReport: 'summaryReport',
  recentActivity: 'recentActivity',
  devices: 'devices',
  updateFirmware: ' updateFirmware',
  viewHistory: 'viewHistory',
  locations: 'locations',
  users: 'users',
  resources: 'resources',
  myAccount: 'myAccount',
  account: 'account',
  logout: 'logout',
}

const MENU_ITEMS_LABELS = {
  summaryReport: 'Summary Report',
  recentActivity: 'Recent Activity',
  devices: 'Devices',
  updateFirmware: 'Update Firmware',
  viewHistory: 'View History',
  locations: 'Locations',
  users: 'Users',
  resources: 'Resources',
  myAccount: 'My Account',
  account: 'Account',
  logout: 'Log Out',
}

const MENU_ITEMS = {
  summaryReport: {
    name: 'Summary Report',
    to: '/summary',
    disabled: false,
  },
  dashboard: {
    name: 'Recent Activity',
    to: '/dashboard',
    disabled: false,
  },
  locations: {
    name: 'Locations',
    to: '/locations',
    disabled: false,
  },
  devices: {
    name: 'Devices',
    to: '/devices',
    disabled: false,
  },
  viewHistory: {
    name: 'View History',
    to: '/devices',
    disabled: false,
  },
  resources: {
    name: 'Resources',
    to: '/resources',
    disabled: true,
  },
  updateFirmware: {
    name: 'Update Firmware',
    to: '/updateFirmware',
    disabled: true,
  },
  users: {
    name: 'Users',
    to: '/users',
    disabled: false,
  },
  userManual: {
    name: 'User Manual',
    to: '/user-manual',
    disabled: true,
  },
  marketingMaterials: {
    name: 'Marketing Materials',
    to: '/marketing-materials',
    disabled: true,
  },
  settings: {
    name: 'Settings',
    to: '/account',
    disabled: false,
  },
}

const startsWith = (path) => ({
  summary: path.startsWith(MENU_ITEMS.summaryReport.to),
  dashboard: path.startsWith(MENU_ITEMS.dashboard.to),
  locations: path.startsWith(MENU_ITEMS.locations.to),
  devices: path.startsWith(MENU_ITEMS.devices.to),
  viewHistory: path.startsWith(MENU_ITEMS.viewHistory.to),
  resources: path.startsWith(MENU_ITEMS.resources.to),
  updateFirmware: path.startsWith(MENU_ITEMS.updateFirmware.to),
  users: path.startsWith(MENU_ITEMS.users.to),
  account: path.startsWith(MENU_ITEMS.settings.to),
  userManual: path.startsWith(MENU_ITEMS.userManual.to),
  marketingMaterials: path.startsWith(MENU_ITEMS.marketingMaterials.to),
  settings: path.startsWith(MENU_ITEMS.settings.to),
})

const OPERATORS_MENU_ITEMS_DESKTOP = [
  MENU_ITEMS.dashboard,
  MENU_ITEMS.locations,
  MENU_ITEMS.devices,
  MENU_ITEMS.resources,
  MENU_ITEMS.updateFirmware,
]

const ACCOUNT_OWNER_MENU_ITEMS_DESKTOP = [
  MENU_ITEMS.dashboard,
  MENU_ITEMS.locations,
  MENU_ITEMS.devices,
  MENU_ITEMS.resources,
  MENU_ITEMS.users,
  MENU_ITEMS.account,
  MENU_ITEMS.updateFirmware,
]

const HEADER_CONSTANTS_DESKTOP = [
  MENU_ITEMS.dashboard,
  MENU_ITEMS.locations,
  MENU_ITEMS.devices,
  MENU_ITEMS.resources,
  MENU_ITEMS.users,
  MENU_ITEMS.updateFirmware,
]

const MENU_ELEMENT_ROLES = {
  tab: 'tab',
  link: 'link',
  dropdown: 'dropdown',
}

export {
  MENU_ITEMS,
  startsWith,
  MENU_ITEMS_KEYS,
  MENU_ITEMS_LABELS,
  HEADER_CONSTANTS_DESKTOP,
  OPERATORS_MENU_ITEMS_DESKTOP,
  ACCOUNT_OWNER_MENU_ITEMS_DESKTOP,
  MENU_ELEMENT_ROLES,
}
