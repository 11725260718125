import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import BackToDeviceList from '../../../../_shared/components/backToDeviceList/BackToDeviceList'
import ChangeDeviceDetailsForm from './ChangeDeviceDetailsForm'
import DeleteDeviceButton from '../../../../_shared/components/deleteDeviceButton/DeleteDeviceButton'
import styles from './style'
import actions from '../../services/arcDeviceDetails-actions'
import { CUMULATIVE_USAGE_START } from 'containers/Devices/_shared/services/devices-actions'
import RecentUsageData from './RecentUsageData'
import NetworkError from '../../../../../../components/_shared/errors/NetworkError'
import { checkConnection } from '../../../../../../services/root/root-service'

const ARCDeviceDetails = (props) => {
  const {
    loadDeviceDetails,
    deviceDetails = {},
    classes,
    history,
    deleteError,
    resetDetails,
    isEditView,
    loadAllowedLocationsForDevice,
    getCumulativeUsage,
    updateError,
    timezone,
    nickName,
    deviceLinked,
    serialNumber,
    networkError,
    getDeviceStatistics,
    deleteDevice,
    cumulativeUsageInSecs,
  } = props

  const isError = !!deviceDetails.errorMessage
  const showErrorMessage = isError || !!deleteError || updateError

  useEffect(() => {
    if (
      isEmpty(deviceDetails) ||
      deviceDetails.id !== props.match.params.deviceId
    ) {
      loadDeviceDetails(props.match.params.deviceId)
    }

    loadAllowedLocationsForDevice(props.match.params.deviceId)
    getCumulativeUsage(props.match.params.deviceId)

    return () => {
      resetDetails()
    }
  }, [])

  useEffect(() => {
    if (
      deviceDetails.deviceLinked &&
      parseInt(props.match.params.deviceId) === deviceDetails.deviceId
    ) {
      getDeviceStatistics(props.match.params.deviceId)
    }
  }, [deviceDetails.deviceLinked, props.match.params.deviceId])

  const goBackToDevices = () => {
    history.push('/devices')
  }

  const onDeleteDevice = () => {
    deleteDevice(serialNumber, goBackToDevices)
  }

  return !networkError ? (
    <div className={classes.wrapper}>
      <BackToDeviceList
        serialNumber={serialNumber}
        goBackToDevices={goBackToDevices}
        showErrorMessage={showErrorMessage}
      />

      <div className={classes.contentWrapper}>
        <Typography variant="subtitle2" color="error">
          {deleteError || null}
          {updateError || null}
        </Typography>

        {deviceLinked && (
          <ChangeDeviceDetailsForm
            serialNumber={serialNumber}
            isReadOnlyView={!isEditView}
            initialNickname={nickName}
          />
        )}

        <div className={classes.infoWrapper}>
          <RecentUsageData
            deviceDetails={deviceDetails}
            timezone={timezone}
            cumulativeUsageInSecs={cumulativeUsageInSecs}
          />
        </div>
      </div>
      {isEditView && deviceLinked && (
        <DeleteDeviceButton
          nickName={deviceDetails.deviceNickname || serialNumber}
          onSubmit={onDeleteDevice}
        />
      )}
    </div>
  ) : (
    <NetworkError />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadDeviceDetails: (id) =>
      dispatch(checkConnection(() => actions.loadDeviceDetails(id))),
    deleteDevice: (serialNumber, onClose) =>
      dispatch(
        checkConnection(() => actions.deleteDevice(serialNumber, onClose))
      ),
    loadAllowedLocationsForDevice: (deviceId) =>
      dispatch(
        checkConnection(() => actions.loadAllowedLocationsForDevice(deviceId))
      ),
    getCumulativeUsage: (deviceId) =>
      dispatch(checkConnection(() => CUMULATIVE_USAGE_START({ deviceId }))),
    resetDetails: () => dispatch(actions.resetDetails()),
    getDeviceStatistics: (deviceId) =>
      dispatch(checkConnection(() => actions.getDeviceStatistics(deviceId))),
  }
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { ARCDeviceDetailsReducer },
    rootReducer,
  } = state

  return {
    deviceDetails: ARCDeviceDetailsReducer.deviceDetails,
    deleteError: ARCDeviceDetailsReducer.deleteError,
    updateError: ARCDeviceDetailsReducer.updateError,
    nickName: ARCDeviceDetailsReducer.nickname,
    isEditView: !rootReducer.isOperatorView,
    deviceLinked: ARCDeviceDetailsReducer.deviceLinked,
    serialNumber: ARCDeviceDetailsReducer.serialNumber,
    cumulativeUsageInSecs: ARCDeviceDetailsReducer.cumulativeUsageInSecs,
    timezone: rootReducer.timezone,
    networkError: rootReducer.networkError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ARCDeviceDetails))
