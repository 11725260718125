import { cloneDeep } from 'lodash'
import dashboardActions from './dashboard-actions'
import * as moment from 'moment'
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import {
  createQueryForDeviceTypes,
  getDateQuery,
  prepareParams,
} from './related/query-service'
import { prepareTimeFilter } from './related/filter-service'
import { HttpClient } from '../../../services/HttpClient'
import customTreeActions from '../../../components/_shared/customTree/services/customTree-actions'
import { PREF } from '../../../services/constants'
import { getStorageDataById } from '../../../services/defaultStore-sevice'
import { SPEED_OF_REQUEST } from './dashboard-constants'

export const getLocationFilter = (state) =>
  state.dashboardReducer.locationFilter
export const getLocationTree = (state) => state.customTreeReducer.nodes
export const customTreeReducer = (state) => state.customTreeReducer
export const getAllSelectedFromTree = (state) =>
  state.customTreeReducer.isAllSelected
export const getDateFilter = (state) => state.dashboardReducer.period
export const getCheckedDateFilter = (state) => state.dashboardReducer.dateFilter
export const getStatusFilter = (state) => state.dashboardReducer.statusFilter
export const getTypesFilter = (state) =>
  state.dashboardReducer.devicesTypesFilter
export const getDevicesFilter = (state) => state.dashboardReducer.deviceFilter
export const getLocationsPref = (state) => state.customTreeReducer.userPref
export const getActivities = (state) => state.dashboardReducer.activities
export const getCurrentUserId = (state) => state.rootReducer.userId
export const getUserTimezone = (state) => state.rootReducer.timezone
export const getCurrentPage = (state) => state.dashboardReducer.page
export const getMaxPage = (state) => state.dashboardReducer.maxPage
export const getRowsPerPage = (state) => state.dashboardReducer.rowsPerPage
export const getNeedToPollActivities = (state) =>
  state.dashboardReducer.needToPollActivities
export const getEnableQuickCount = (state) =>
  state.dashboardReducer.enableQuickCount
export const getLastModification = (state) =>
  state.dashboardReducer.lastModification
export const getNeedToUpdateData = (state) =>
  state.dashboardReducer.needToUpdateData
export const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

let prevRequestTime = 0
let numberOfReqAfterChangeEnable = 10
let time = 0

// checking if there are new updated in organisation
function* pollLastModification() {
  while (true) {
    try {
      let needToGetActivities = yield select(getNeedToUpdateData)

      const lastModification = yield select(getLastModification)

      const data = yield call(() => {
        return HttpClient.get(
          `/api/secured/organizations/last-modification/EVENT`
        )
      })

      if (data.message) {
        yield put(dashboardActions.pollModificationFailed(data.message))
      } else {
        if (data['modified'] !== lastModification) {
          needToGetActivities = true
        }

        yield put(dashboardActions.pollModificationSucceeded(data['modified']))

        if (needToGetActivities) {
          yield put(dashboardActions.loadActivities())
        }
      }

      yield delay(2500 + time)
    } catch (e) {
      yield put(dashboardActions.pollModificationFailed(e.message))
    }
  }
}

function* loadActivities() {
  try {
    const locationFilter = yield select(getLocationFilter)
    const defaultPeriod = yield select(getDateFilter)
    const dateFilter = yield select(getCheckedDateFilter)
    const userId = yield select(getCurrentUserId)
    const unknownLocationsFlag = getStorageDataById(
      PREF.UNKNOWN_LOCATIONS,
      userId
    )
    const deletedLocationsFlag = getStorageDataById(
      PREF.DELETED_LOCATIONS,
      userId
    )
    const locationPref = getStorageDataById(PREF.LOCATIONS, userId)
    const timezone = null
    const pageNum = yield select(getCurrentPage)
    const maxPage = yield select(getMaxPage)
    const pageSize = yield select(getRowsPerPage)
    const enableQuickCount = yield select(getEnableQuickCount)

    const period = prepareTimeFilter(dateFilter, defaultPeriod, timezone)

    const statuses = yield select(getStatusFilter)
    const types = yield select(getTypesFilter)
    const deviceFilter = yield select(getDevicesFilter)
    const locPref = locationFilter.selectedBuildings

    // ToDo create one object with all these parameters to not write them every time in this saga
    const params = prepareParams(
      period,
      statuses,
      types,
      deviceFilter,
      locPref,
      unknownLocationsFlag,
      deletedLocationsFlag,
      locationPref,
      timezone,
      pageNum,
      pageSize,
      enableQuickCount
    )

    const start = new Date().getTime()

    const data = yield call(() => {
      return HttpClient.get(
        '/api/secured/devices/transactions/v2/recent-activities?' + params
      )
    })

    numberOfReqAfterChangeEnable++

    if (data.message) {
      yield put(dashboardActions.loadActivitiesFailed(data.message))
    } else {
      if (!data.items.length && pageNum > maxPage) {
        yield put(dashboardActions.goToNextPage(pageNum - 1))
      } else {
        yield put(dashboardActions.loadActivitiesSuccess(data))

        const lastDayPeriod = getDateQuery(data, timezone, true)
        const firstDayPeriod = getDateQuery(data, timezone)

        if (lastDayPeriod || firstDayPeriod) {
          const lastDayParams = prepareParams(
            lastDayPeriod,
            statuses,
            types,
            deviceFilter,
            locPref,
            unknownLocationsFlag,
            deletedLocationsFlag,
            locationPref,
            timezone,
            pageNum,
            pageSize,
            false
          )

          const borderPages = pageNum === 1 || pageNum === maxPage

          if (
            (firstDayPeriod &&
              moment(lastDayPeriod.startDate).isSame(
                firstDayPeriod.startDate,
                'day'
              )) ||
            borderPages
          ) {
            yield put(dashboardActions.getCycleCount(lastDayParams, true))
          } else {
            const firstDayParams = prepareParams(
              firstDayPeriod,
              statuses,
              types,
              deviceFilter,
              locPref,
              unknownLocationsFlag,
              deletedLocationsFlag,
              locationPref,
              timezone,
              pageNum,
              pageSize,
              false
            )

            yield put(dashboardActions.getCycleCount(firstDayParams))
            yield put(dashboardActions.getCycleCount(lastDayParams, true))
          }
        }
      }
    }

    const end = new Date().getTime()
    time = end - start

    prevRequestTime = time

    if (
      prevRequestTime >= SPEED_OF_REQUEST &&
      !enableQuickCount &&
      numberOfReqAfterChangeEnable >= 10
    ) {
      yield put(dashboardActions.setEnableQuickCount(true))

      numberOfReqAfterChangeEnable = 0
    } else if (
      prevRequestTime < SPEED_OF_REQUEST &&
      enableQuickCount &&
      numberOfReqAfterChangeEnable >= 10
    ) {
      yield put(dashboardActions.setEnableQuickCount(false))

      numberOfReqAfterChangeEnable = 0
    }

    const needToPoll = yield select(getNeedToPollActivities)

    if (pageNum !== 1 && !needToPoll) {
      yield put(dashboardActions.stopPollingModification())
    }
  } catch (e) {
    yield put(dashboardActions.loadActivitiesFailed(e.message))
  }
}

function* getCycleCount(action) {
  try {
    const { query, isLast } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        '/api/secured/devices/transactions/v2/recent-activities?' + query
      )
    })

    if (data.message) {
      yield put(dashboardActions.getCycleCountFailed(data.message))
    } else {
      yield put(dashboardActions.getCycleCountSuccess(data, isLast))
    }
  } catch (e) {
    yield put(dashboardActions.getCycleCountFailed(e.message))
  }
}

function* downloadCSV() {
  try {
    const locationFilter = yield select(getLocationFilter)
    const defaultPeriod = yield select(getDateFilter)
    const dateFilter = yield select(getCheckedDateFilter)
    const userId = yield select(getCurrentUserId)
    const unknownLocationsFlag = getStorageDataById(
      PREF.UNKNOWN_LOCATIONS,
      userId
    )
    const deletedLocationsFlag = getStorageDataById(
      PREF.DELETED_LOCATIONS,
      userId
    )
    const locationPref = getStorageDataById(PREF.LOCATIONS, userId)
    const timezone = yield select(getUserTimezone)
    const pageNum = -1
    const pageSize = -1

    let period = prepareTimeFilter(dateFilter, defaultPeriod, timezone)

    const statuses = yield select(getStatusFilter)
    const types = yield select(getTypesFilter)
    const deviceFilter = yield select(getDevicesFilter)

    const params = prepareParams(
      period,
      statuses,
      types,
      deviceFilter,
      locationFilter.selectedBuildings,
      unknownLocationsFlag,
      deletedLocationsFlag,
      locationPref,
      timezone,
      pageNum,
      pageSize
    )

    const data = yield call(() => {
      return HttpClient.get(
        '/api/secured/devices/transactions/downloadSourceData?' + params,
        {
          isDownload: true,
          fileName: 'activities.csv',
        }
      )
    })

    if (data.message) {
      yield put(dashboardActions.downloadCSVFailed(data.message))
    } else {
      yield put(dashboardActions.downloadCSVSuccess())
    }
  } catch (e) {
    yield put(dashboardActions.downloadCSVFailed(e.message))
  }
}

function* downloadReport() {
  try {
    const locationFilter = yield select(getLocationFilter)
    const defaultPeriod = yield select(getDateFilter)
    const dateFilter = yield select(getCheckedDateFilter)
    const userId = yield select(getCurrentUserId)
    const unknownLocationsFlag = getStorageDataById(
      PREF.UNKNOWN_LOCATIONS,
      userId
    )
    const deletedLocationsFlag = getStorageDataById(
      PREF.DELETED_LOCATIONS,
      userId
    )
    const locationPref = getStorageDataById(PREF.LOCATIONS, userId)
    let timezone = yield select(getUserTimezone)
    const pageNum = -1
    const pageSize = -1

    let period = prepareTimeFilter(dateFilter, defaultPeriod, timezone)

    const statuses = yield select(getStatusFilter)
    const types = yield select(getTypesFilter)
    const deviceFilter = yield select(getDevicesFilter)

    const params = prepareParams(
      period,
      statuses,
      types,
      deviceFilter,
      locationFilter.selectedBuildings,
      unknownLocationsFlag,
      deletedLocationsFlag,
      locationPref,
      timezone,
      pageNum,
      pageSize,
      false,
      'report'
    )

    const data = yield call(() => {
      return HttpClient.get(
        '/api/secured/devices/transactions/downloadReport?' + params,
        {
          isDownload: true,
          fileName: 'activities.csv',
        }
      )
    })

    if (data.message) {
      yield put(dashboardActions.downloadReportFailed(data.message))
    } else {
      yield put(dashboardActions.downloadReportSuccess())
    }
  } catch (e) {
    yield put(dashboardActions.downloadReportFailed(e.message))
  }
}

function* loadLocations(action) {
  try {
    const userId = yield select(getCurrentUserId)
    const tree = yield select(getInitialNodesTree)

    yield put(customTreeActions.loadNodesSucceeded(userId, tree))
  } catch (e) {
    yield put(dashboardActions.loadLocationTreeFailed(e.message))
  }
}

function* setupLoadedLocations() {
  try {
    const locationTree = yield select(getLocationTree)
    const userPref = yield select(getLocationsPref)

    if (window.location.href.indexOf('/#/dashboard') !== -1) {
      yield put(dashboardActions.prepareLocationFiler(locationTree, userPref))
    }
  } catch (e) {
    yield put(dashboardActions.applyLocationFilterFailed(e.message))
  }
}

function* updateLocationTree() {
  if (window.location.href.indexOf('/#/dashboard') !== -1) {
    yield put(dashboardActions.loadLocationTree())
  }
}

function* loadDevices(action) {
  try {
    const types = yield select(getTypesFilter)
    const deviceTypeQuery = createQueryForDeviceTypes(types, true)

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/devices/`, {
        body: {
          query: deviceTypeQuery,
          'order.serialNumber': 'asc',
        },
      })
    })

    if (data.message) {
      yield put(dashboardActions.loadDevicesFailed(data.message))
    } else {
      yield put(dashboardActions.loadDevicesSuccess(data))
    }
  } catch (e) {
    yield put(dashboardActions.loadDevicesFailed(e.message))
  }
}

function* resetFilters() {
  try {
    let locationTree = yield select(getLocationTree)
    const userPref = yield select(getLocationsPref)

    yield put(dashboardActions.prepareLocationFiler(locationTree, userPref))
  } catch (e) {
    yield put(dashboardActions.applyLocationFilterFailed(e.message))
  }
}

function* loadLocationsTreeSaga() {
  yield all([
    takeLatest(
      dashboardActions.actionsTypes.LOAD_DEVICES_SUCCEEDED,
      loadLocations
    ),
    takeLatest(dashboardActions.actionsTypes.LOAD_LOCATION_TREE, loadLocations),
  ])
}

function* downloadCSVSaga() {
  yield takeLatest(dashboardActions.actionsTypes.DOWNLOAD, downloadCSV)
}

function* downloadReportSaga() {
  yield takeLatest(
    dashboardActions.actionsTypes.DOWNLOAD_REPORT,
    downloadReport
  )
}

function* locationFilterIsReadySaga() {
  yield takeEvery(
    customTreeActions.actionsTypes.LOAD_NODES_SUCCESS,
    setupLoadedLocations
  )
}

function* pollLastModificationSaga() {
  while (true) {
    yield take(dashboardActions.actionsTypes.PREPARE_LOCATION_FILTER)
    yield race([
      call(pollLastModification),
      take(dashboardActions.actionsTypes.STOP_POLLING_MODIFICATION),
    ])
  }
}

function* startPollModificationSaga() {
  while (true) {
    yield take(dashboardActions.actionsTypes.START_POLLING_MODIFICATION)
    yield race([
      call(pollLastModification),
      take(dashboardActions.actionsTypes.STOP_POLLING_MODIFICATION),
    ])
  }
}

function* loadActivitiesSaga() {
  yield takeEvery(dashboardActions.actionsTypes.LOAD_ACTIVITIES, loadActivities)
}

function* resetFiltersSaga() {
  yield takeLatest(dashboardActions.actionsTypes.RESET_FILTERS, resetFilters)
}

function* loadDevicesSaga() {
  yield takeLatest(dashboardActions.actionsTypes.LOAD_DEVICES, loadDevices)
}

function* getCycleCountSaga() {
  yield takeEvery(dashboardActions.actionsTypes.GET_CYCLE_COUNT, getCycleCount)
}

function* goToNextPageSaga() {
  while (true) {
    yield take(dashboardActions.actionsTypes.GO_TO_NEXT_PAGE)
    yield race([
      call(pollLastModification),
      take(dashboardActions.actionsTypes.STOP_POLLING_MODIFICATION),
    ])
  }
}

function* updateLocationTreeSaga() {
  yield takeLatest(
    customTreeActions.actionsTypes.LOAD_INITIAL_LOCATION_TREE_SUCCESS,
    updateLocationTree
  )
}

export default function* dashboardSagas() {
  yield fork(resetFiltersSaga)
  yield fork(downloadCSVSaga)
  yield fork(loadLocationsTreeSaga)
  yield fork(locationFilterIsReadySaga)
  yield fork(pollLastModificationSaga)
  yield fork(loadActivitiesSaga)
  yield fork(startPollModificationSaga)
  yield fork(loadDevicesSaga)
  yield fork(goToNextPageSaga)
  yield fork(downloadReportSaga)
  yield fork(getCycleCountSaga)
  yield fork(updateLocationTreeSaga)
}
