import { COLORS } from '../../../../services/constants'

const style = (theme) => ({
  dialogContent: {
    padding: '8px 0',
    borderBottom: `1px solid ${COLORS.LIGHT_GRAY}`,
  },
  openButton: {
    margin: '0 0 15px',
    display: 'block',
    padding: '0 15px 4px',
  },
  modalTitle: {
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    fontSize: '16px',
    marginTop: '8%',
    marginBottom: '12px',
  },
  actionLabel: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'left',
    color: COLORS.GRAY,
  },
  actionDescription: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '11px',
    color: COLORS.GRAY,
    lineHeight: '13px',
    marginRight: '32px',
    marginLeft: '32px',
    textAlign: 'left',
  },
  description: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
    color: COLORS.GRAY,
    lineHeight: '21px',
  },
  descriptionContainer: {
    padding: '0 38px 18px 38px',
    borderBottom: `1px solid ${COLORS.LIGHT_GRAY}`,
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 32px',
    borderTop: `1px solid ${COLORS.LIGHT_GRAY}`,
  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  actionTextContainer: {
    textAlign: 'left',
  },
  buttonSubLabel: {
    fontSize: '10px',
    color: COLORS.GRAY,
    lineHeight: '12px',
  },
  '@media (min-width: 1224px)': {
    openButton: {
      minWidth: '250px',
      fontFamily: 'Cerebri Sans Pro Regular',
      fontSize: '13px',
      padding: 0,
      margin: '0 15px 0',
    },
  },
})

export default style
