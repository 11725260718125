import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import ErrorHasOccurred from '../../../components/_shared/errors/ErrorHasOccurred'
import NetworkError from '../../../components/_shared/errors/NetworkError'
import UVGISummaryPage from './components/UVGISummaryPage'
import style from './style'
import actions from './services/uvgiSummary-actions'
import {
  checkConnection,
  withMediaQuery,
} from '../../../services/root/root-service'
import Loader from '../../../components/_shared/loader/Loader'

const UVGISummaryContainer = (props) => {
  const {
    isLoading,
    networkError,
    loadLocations,
    changeDateFilter,
    history,
    putLocationFilter,
    loadSummaryDataError,
    resetDateFilter,
    activeTab,
    classes,
  } = props

  useEffect(() => {
    if (
      !isEmpty(history?.location?.state) &&
      history.location.state.deviceType === activeTab
    ) {
      const { date, dateFilterLabel, location } = history.location.state

      if (location) {
        putLocationFilter(location)
      }

      changeDateFilter(date, dateFilterLabel)
    } else {
      loadLocations()

      resetDateFilter()
    }
  }, [])

  return networkError ? (
    <NetworkError />
  ) : (
    <div className={classes.pageWrapper}>
      {loadSummaryDataError ? (
        <ErrorHasOccurred hideLogo />
      ) : (
        <UVGISummaryPage />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { UVGISummaryReducer, sharedSummaryReducer },
    rootReducer,
  } = state

  return {
    loadSummaryDataError: UVGISummaryReducer.loadSummaryDataError,
    isLoading: UVGISummaryReducer.isLoading,
    networkError: rootReducer.networkError,
    changeDateFilter: UVGISummaryReducer.changeDateFilter,
    activeTab: sharedSummaryReducer.activeTab,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDevices: () => dispatch(checkConnection(actions.getDevices)),
  loadLocations: () => dispatch(checkConnection(actions.loadLocations)),
  changeDateFilter: (dateFilter, label) =>
    dispatch(
      checkConnection(() => actions.changeDateFilter(dateFilter, label))
    ),
  putLocationFilter: (filter) =>
    dispatch(checkConnection(() => actions.putLocationFilter(filter))),
  resetDateFilter: () => dispatch(checkConnection(actions.resetDateFilter)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(style)(UVGISummaryContainer)))
