import React, { useEffect } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { withStyles } from '@material-ui/core'
import style from './style'
import Typography from '@material-ui/core/Typography'
import {
  withMediaQuery,
  getLabelWithCount,
} from '../../../../../services/root/root-service'
import { TOOLTIP_LABELS } from '../../services/summary-constants'

const CombinationChartContainer = (props) => {
  const {
    chartData,
    isMobile,
    classes,
    title,
    subtitle,
    createCompletedCyclesChart,
  } = props

  useEffect(() => {
    const x = map(chartData, (item) => item.x)
    const y = map(chartData, (item) => ({
      label: getLabelWithCount(item.y, TOOLTIP_LABELS.cycle, false),
      y: item.y,
    }))
    const z = map(chartData, (item) => item.z)

    createCompletedCyclesChart(x, y, z, isMobile)
  }, [chartData, isMobile])

  return (
    <div className={classNames('pdf-chart-100', classes.chartContainer)}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.subtitle}
      >
        {subtitle}
      </Typography>
      <div id="completed-cycles-container" className={classes.chart}></div>
      <div
        id="completed-cycles-container-pdf"
        className={classes.chartPdf}
      ></div>
    </div>
  )
}

export default withMediaQuery(withStyles(style)(CombinationChartContainer))
