import React, { createRef, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { LOCATIONS_CONST } from '../../service/location-constants'
import RoomFields from '../addLocation/RoomFields'
import styles from './style'
import { isMobile } from 'react-device-detect'
import DeleteLocationButton from '../deleteModalWithInput/DeleteLocationButton'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'

const EditModal = (props) => {
  const {
    onSubmit,
    onDelete,
    onClose,
    modalTitle,
    placeholder,
    isOpen,
    actionLabel,
    error,
    defaultValue,
    inputProps = {},
    location,
    isRoomFlag,
    classes,
  } = props
  const inputRef = createRef()
  const [isEmptyTextField, setIsEmptyTextField] = useState(false)
  let timer = 0
  let prevent = true
  const isRoom = location.locationType === LOCATIONS_CONST.ROOM || isRoomFlag
  const [roomFields, setRoomFields] = useState({
    baselineAch: location.baselineAch || '',
    improvedEAch: location.improvedEAch || '',
  })

  useEffect(() => {
    if (isOpen) {
      setRoomFields({
        baselineAch: location.baselineAch,
        improvedEAch: location.improvedEAch,
      })
    }
  }, [isOpen])

  const onChangeRoomsFields = (value, name) => {
    setRoomFields({
      ...roomFields,
      [name]: value,
    })
  }

  const onCloseModal = () => {
    setIsEmptyTextField(false)

    onClose()
  }

  const onSubmitButton = (event) => {
    event.preventDefault()
    const value = inputRef.current.value

    if (inputRef.current.value.trim() === '') {
      setIsEmptyTextField(true)
    } else {
      setIsEmptyTextField(false)

      if (error) {
        prevent = true
      }

      timer = setTimeout(function () {
        if (prevent) {
          onSubmit(value, isRoom ? roomFields : false)
          prevent = false
        }
      }, 200)
    }
  }

  function handleDoubleClick() {
    clearTimeout(timer)
    prevent = true
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onSubmitButton(e)
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle className={classes.modalTitleContainer} disableTypography>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            className={classes.modalTitle}
          >
            {modalTitle}
          </Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <CloseButton onCloseModal={onCloseModal} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {isRoom && (
            <RoomFields
              baselineAch={roomFields.baselineAch}
              onChangeRoomsFields={onChangeRoomsFields}
              improvedEAch={roomFields.improvedEAch}
            />
          )}
          <TextField
            variant="outlined"
            inputRef={inputRef}
            error={isEmptyTextField || !!error}
            defaultValue={defaultValue || ''}
            label={!isMobile ? placeholder : ''}
            placeholder={isMobile ? placeholder : ''}
            inputProps={{ className: classes.outlined, ...inputProps }}
            fullWidth
            autoFocus={!isMobile}
            onKeyUp={onKeyUp}
          />
          <div className={classes.buttonsWrapper}>
            <Button
              onDoubleClick={handleDoubleClick}
              variant="contained"
              color="primary"
              onClick={onSubmitButton}
            >
              {actionLabel}
            </Button>
            <DeleteLocationButton
              onSubmit={onDelete}
              location={location}
              error={error}
              onModalClose={onClose}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(EditModal)
