import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  modalTitle: {
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '20px',
  },
  dialogContent: {
    fontFamily: 'Cerebri Sans Pro Regular',
    lineHeight: '13px',
    color: COLORS.GRAY,
    padding: '10px 30px',
    textAlign: 'center',
  },
  modalTitleContainer: {
    paddingBottom: 0,
  },
  input: {
    marginTop: '30px',
    width: '152px',
  },
  submitButton: {
    margin: '30px 0',
  },
  viewButton: {
    paddingRight: '20px',
    paddingLeft: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
    '& button:first-child': {
      marginRight: '10px',
    },
  },
  lists: {
    marginTop: '20px',
  },
  deleteButtonWrapper: {
    marginTop: '20px',
  },
  deleteButton: {
    width: '100%',
  },
  '@media (min-width: 1224px)': {
    submitButton: {
      width: '100%',
    },
    dialogContent: {
      padding: '16px 60px',
      fontSize: '11px',
    },
  },
})

export default styles
