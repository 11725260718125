const DEVICE_SORTING = {
  RECENTLY_USED: 'Recently Used',
  DEVICE_SN: 'Device SN #',
}

const DEFAULT_SORTING_LABEL = 'Recently Used'

const DEVICE_CARDS_LABELS = {
  timeLabel: 'Last Used',
  noActivities: 'No activities',
}

const RESET_CUSTOM_CYCLE_TIME_PAGE = {
  title: 'RESET CUSTOM CYCLE TIMES',
  button: 'Reset Custom Cycle Times',
  text: 'This will overwrite any cycle times you have assigned to specific locations.\n',
  text2: 'This can not be undone.',
  confirm: 'Reset Cycle Times',
}

const SET_DEFAULT_CYCLE_TIMES = {
  title: 'Set Default Cycle Time',
  description:
    'This will set a default cycle time for all of your locations. You will still be able to change' +
    ' the cycle time on the device itself. Cycle time for specific locations can be assigned in the locations tab.',
  actionLabel: 'Apply to all locations',
  actionDescription:
    'This will overwrite any cycle times you have previously assigned. This cannot be undone.',
}

export {
  DEVICE_SORTING,
  DEFAULT_SORTING_LABEL,
  DEVICE_CARDS_LABELS,
  RESET_CUSTOM_CYCLE_TIME_PAGE,
  SET_DEFAULT_CYCLE_TIMES,
}
