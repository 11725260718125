import React from 'react'
import AddDevice from './AddDevice/AddDevice'
import Typography from '@material-ui/core/Typography'
import { DEVICES_LIST_TITLE } from '../services/extDeviceList-constants'
import style from './style'
import { withStyles } from '@material-ui/core/styles/index'
import ExternalDevicesTable from './externalDevicesTable/ExternalDevicesTable'

const ExternalDevicesListPage = (props) => {
  const { devices, history, classes } = props

  return (
    <>
      <div className={classes.titleWrapper}>
        <Typography variant="h2">{DEVICES_LIST_TITLE}</Typography>
      </div>

      <AddDevice />
      {devices.length && (
        <ExternalDevicesTable history={history} devices={devices} />
      )}
    </>
  )
}

export default withStyles(style)(ExternalDevicesListPage)
