import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import {
  ACTIVE_DEVICES,
  TOOLTIP_LABELS,
} from '../../services/summary-constants'
import style from './style'
import Typography from '@material-ui/core/Typography'
import { withMediaQuery } from '../../../../../services/root/root-service'
import { getPieChart } from '../../../ARC/service/arcSummary-service'

const CompletedCyclesContainer = (props) => {
  const {
    devices,
    activeDevicesCount,
    totalDevicesCount,
    isMobile,
    classes,
    title,
    subtitle,
  } = props

  useEffect(() => {
    if (devices) {
      const inactiveDevicesCount = totalDevicesCount - activeDevicesCount

      getPieChart(
        activeDevicesCount,
        inactiveDevicesCount,
        isMobile,
        'active-devices',
        ACTIVE_DEVICES.blueTitle,
        ACTIVE_DEVICES.grayTitle,
        TOOLTIP_LABELS.device
      )
    }
  }, [devices, isMobile, activeDevicesCount])

  return (
    <div className={classNames('pdf-chart-50', classes.chartContainer)}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.subtitle}
      >
        {subtitle(totalDevicesCount)}
      </Typography>
      <div id="active-devices-container" className={classes.chart}></div>
      <div id="active-devices-container-pdf" className={classes.chartPdf}></div>
    </div>
  )
}

export default withMediaQuery(withStyles(style)(CompletedCyclesContainer))
