import { Button, InputAdornment } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import WarningModal from '../../../../components/_shared/modals/warningConfirm/WarningModal'
import SvgIconComponent from '../../../../components/_shared/SvgIconComponent'
import { API_KEY_LABELS } from '../../services/account-constants'
import styles from './style'

const ApiKeyRowComponent = withStyles(styles)((props) => {
  const { classes, regenerateApiKey, apiKey, showMessage } = props
  const [isOpen, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const openConfirm = () => {
    setOpen(true)
  }

  const confirmRegenerate = () => {
    regenerateApiKey(apiKey.id, handleClose)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey.apiKey)
    showMessage()
  }

  return (
    <div className={classes.apiKeyRow}>
      <TextField
        className={classes.apyKeyField}
        label={API_KEY_LABELS.apiKey}
        variant="outlined"
        disabled
        value={apiKey.apiKey}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={copyToClipboard}>
                <SvgIconComponent name="copy_content" size={26} height={18} />
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { className: classes.input },
        }}
      />
      <Button
        variant="contained"
        onClick={openConfirm}
        className={classes.regenerateApiKeyButton}
        color="primary"
      >
        {API_KEY_LABELS.regenerate}
      </Button>
      <WarningModal
        isWarning
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={confirmRegenerate}
        variantHelperText2="subtitle2"
        buttonLabel={API_KEY_LABELS.confirm}
        modalTitle={API_KEY_LABELS.modalTittle}
        dialogHelperText={API_KEY_LABELS.message}
        dialogHelperText2={API_KEY_LABELS.message2}
      />
    </div>
  )
})

export default ApiKeyRowComponent
