import React from 'react'
import { connect } from 'react-redux'
import actions from './../../services/uvgiDeviceList-actions'
import ItemsPerPage from '../../../../../../components/_shared/pagination/ItemsPerPage'
import ListPagination from '../../../../../../components/_shared/pagination/ListPagination'
import PaginationResults from '../../../../../../components/_shared/pagination/PaginationResults'
import { withMediaQuery } from '../../../../../../services/root/root-service'
import styles from './style'
import { withStyles } from '@material-ui/core'

const UVGIDeviceListPagination = (props) => {
  const {
    page,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    goToNextPage,
    isMobile,
    classes,
  } = props

  return (
    <div className={classes.paginationContainer}>
      {isMobile ? (
        <>
          <ListPagination
            page={page}
            goToNextPage={goToNextPage}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
          />
          <div className={classes.pageSettings}>
            <ItemsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
            <PaginationResults
              page={page}
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
            />
          </div>
        </>
      ) : (
        <>
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ListPagination
            page={page}
            goToNextPage={goToNextPage}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
          />
          <PaginationResults
            page={page}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
          />
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { UVGIDeviceListReducer },
  } = state

  return {
    page: UVGIDeviceListReducer.page,
    rowsPerPage: UVGIDeviceListReducer.rowsPerPage,
    totalCount: UVGIDeviceListReducer.totalCount,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setRowsPerPage: (value) => dispatch(actions.setRowsPerPage(value)),
  goToNextPage: (page) => dispatch(actions.changePage(page)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(UVGIDeviceListPagination)))
