import { processWithObue } from './root/root-service'
import { createAuthProvider } from './tokenAuth'
import { forEach } from 'lodash'
import { HOST } from './constants'

export const HttpClient = (function HttpClient() {
  const prepareBody = (body) => {
    if (!body) {
      return undefined
    }

    return typeof body === 'string' ? body : JSON.stringify(body)
  }

  const defaultHeaders = () => {
    return new Headers({
      'Content-Type': 'application/json;',
      'X-Content-Type-Options': 'nosniff',
    })
  }

  const [tp, login, logout] = createAuthProvider({
    accessTokenKey: 'authToken',
    needUpdateToken: true,
  })

  let host = ''
  let unauthorizedHandler
  let excludedUrls = []

  return {
    setHost(h) {
      host = h
    },

    tp,
    login,
    logout,

    setUnauthorizedHandler(handler, urls = []) {
      unauthorizedHandler = handler
      excludedUrls = [...excludedUrls, ...urls]
    },

    async makeRequest(url, method, payloads = {}) {
      let {
        headers = defaultHeaders(),
        onSuccess,
        onError,
        body,
        access_token,
        isUserPref,
        userPrefNames,
        isDownload,
        fileName,
        isObue,
      } = payloads
      const token = await tp.getToken()
      const tokenIssuer = tp.getTokenIssuer()

      if (access_token) {
        headers.append('X-Auth-Token', access_token)
      }

      if (token) {
        headers.append('Authorization', `Bearer ${token}`)
      }

      headers.append('tokenIssuer', tokenIssuer || 0)

      if (isObue) {
        processWithObue(url, token, tokenIssuer, method, onSuccess, onError)
      } else {
        return fetch(HOST + url, {
          method,
          headers,
          cache: 'no-cache',
          credentials: 'include',
          body: prepareBody(body),
        })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              return Promise.resolve(response)
            }

            const isUnauthorizedResponse =
              response.status === 401 || response.status === 403
            const isUnauthorizedHandlerSet =
              typeof unauthorizedHandler === 'function'
            const currentUrlIsNotExcluded = !excludedUrls.includes(url)

            if (
              isUnauthorizedResponse &&
              isUnauthorizedHandlerSet &&
              currentUrlIsNotExcluded
            ) {
              return response.text().then((text) => {
                unauthorizedHandler({ error: text })
              })
            }

            return new Promise((_resolve, reject) => {
              response.text().then((text) => {
                text
                  ? reject(JSON.parse(text))
                  : reject(new Error(response.statusText))
              })
            })
          })
          .then((response) => {
            if (isUserPref) {
              const pref = {}

              forEach(userPrefNames, (item) => {
                let value = response.headers.get(item)

                pref[item] = value ? JSON.parse(value) : ''
              })

              return response.text().then((text) => {
                try {
                  return text
                    ? {
                        ...JSON.parse(text),
                        ...pref,
                      }
                    : {
                        ...pref,
                      }
                } catch {
                  return text
                }
              })
            }

            if (isDownload) {
              return response.blob().then((text) => {
                const url = window.URL.createObjectURL(text)
                const link = document.createElement('a')

                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                return text
              })
            } else {
              return response.text().then((text) => {
                try {
                  return text ? JSON.parse(text) : {}
                } catch {
                  return text
                }
              })
            }
          })
          .catch((error) => {
            console.log(error)
            return error
          })
      }
    },
    get(url, payloads) {
      return this.makeRequest(url, 'GET', payloads)
    },
    post(url, payloads) {
      return this.makeRequest(url, 'POST', payloads)
    },
    put(url, payloads) {
      return this.makeRequest(url, 'PUT', payloads)
    },
    delete(url, payloads) {
      return this.makeRequest(url, 'DELETE', payloads)
    },
  }
})()
