import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import style from './style'
import { DEVICES_TABLE } from '../../../ARC/service/arcSummary-constants'
import DevicesTable from './DevicesTable'

const DevicesTableContainer = (props) => {
  const {
    devices,
    subtitle,
    isToday,
    timezone,
    tableColumns,
    sortByDates = false,
    classes,
  } = props

  return (
    <Accordion defaultExpanded className={classes.container}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        classes={{ content: classes.accordionSummary }}
      >
        <Typography variant="subtitle1">{DEVICES_TABLE.title}</Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <DevicesTable
          devices={devices}
          isToday={isToday}
          timezone={timezone}
          tableColumns={tableColumns}
          sortByDates={sortByDates}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default withStyles(style)(DevicesTableContainer)
