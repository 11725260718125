import Typography from '@material-ui/core/Typography'
import React from 'react'
import { PAGINATION } from '../../../services/constants'
import styles from './style'
import { withStyles } from '@material-ui/core'

const PaginationResultsComponent = (props) => {
  const { page, totalCount, rowsPerPage, enableQuickCount, classes } = props

  return (
    <Typography className={classes.result}>
      {`${PAGINATION.results} ${(page - 1) * rowsPerPage + 1} - ${
        rowsPerPage * page > totalCount ? totalCount : rowsPerPage * page
      } ${!enableQuickCount ? PAGINATION.of : PAGINATION.ofMore} ${totalCount}`}
    </Typography>
  )
}

export default withStyles(styles)(PaginationResultsComponent)
