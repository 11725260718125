import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  pageWrapper: {
    padding: '0 7%',
  },
  apyKeyField: {
    width: '100%',
  },
  pageTitle: {
    marginBottom: '10px',
  },
  regenerateApiKeyButton: {
    width: 'fit-content',
    maxWidth: '110px',
    minWidth: '110px',
    backgroundColor: COLORS.WHITE_SMOKE_GRAY,
    fontSize: '12px',
    lineHeight: '14px',
    color: COLORS.BLUE,
    fontFamily: 'Cerebri Sans Pro Medium',
    padding: 0,
    height: '30px',
    boxShadow: 'none !important',
    marginBottom: '10px',
    marginTop: '10px',
  },
  successSnackbar: {
    borderRadius: '0',
    padding: '0 7% 20px',
    flex: 1,
    '& div': {
      backgroundColor: COLORS.GREEN,
      borderRadius: '0',
    },
  },
  apiKeyRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  apiKeysList: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    flexDirection: 'column',
  },
  backIcon: {
    width: '18px',
    height: '18px',
    color: COLORS.BLUE,
  },
  backButton: {
    paddingLeft: 0,
    paddingRight: '7px',
  },
  backLink: {
    fontFamily: 'Cerebri Sans Pro Medium',
    color: COLORS.CONTRAST_GRAY,
    fontSize: '14px',
    lineHeight: '23px',
    textAlign: 'center',
    paddingTop: '49px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  '@media (min-width: 1024px)': {
    pageTitle: {
      fontFamily: 'Cerebri Sans Pro Semi Bold',
      fontSize: '42px',
      color: COLORS.GRAFIT,
      paddingTop: '28px',
      textAlign: 'center',
      paddingBottom: '20px',
    },
    apiKeyRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    regenerateApiKeyButton: {
      backgroundColor: COLORS.ATHENS_GRAY,
      textAlign: 'center',
      marginLeft: '23px',
    },
  },
})

export default styles
