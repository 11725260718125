import { BasePdfElement } from './BasePdfElement'

const SUBTITLE_COLOR = '#606060'
const TITLE_COLOR = '#000000'

export class PdfImageCard extends BasePdfElement {
  div: HTMLDivElement

  constructor(div: HTMLDivElement) {
    super()
    this.div = div
  }

  async render() {
    const svgs = this.div.querySelectorAll('svg')
    const titleElem = this.div.querySelector('h6:nth-child(1)')
    const subtitleElem = this.div.querySelector('h6:nth-child(2)')

    if (svgs.length == 0 || !titleElem || !subtitleElem) {
      return
    }

    const title = this.getPureText(titleElem)
    const subtitle = this.getPureText(subtitleElem)

    const headerHeight = this.height * 0.3
    const imageHeight = this.height * 0.7

    this.doc.setFontSize(14)
    this.doc.setTextColor(TITLE_COLOR)
    this.doc.text(title, this.position_x, this.position_y + headerHeight / 2)

    this.doc.setFontSize(10)
    this.doc.setTextColor(SUBTITLE_COLOR)
    this.doc.text(
      subtitle,
      this.position_x,
      this.position_y + headerHeight / 2 + 7
    )

    await this.svgToImg(
      svgs[svgs.length - 1],
      this.position_x,
      this.position_y + headerHeight,
      this.width,
      imageHeight
    )
  }
}
