const style = (props) => ({
  deleteButton: {
    margin: 'auto',
    display: 'flex',
    marginTop: '30px',
    width: '190px',
  },
  '@media (min-width: 1224px)': {
    deleteButton: {
      minWidth: '120px',
      marginRight: '230px',
    },
  },
  '@media (min-width: 1650px)': {
    deleteButton: {
      marginRight: '17%',
    },
  },
})

export default style
