const styles = (theme) => ({
  propertyName: {
    margin: '24px 0 12px 0',
  },
  contentWrapper: {
    margin: 'auto',
    overflow: 'auto',
    height: '100%',
    overflowX: 'hidden',
  },
  '@media (min-width: 1224px)': {
    contentWrapper: {
      width: '80%',
    },
  },
})

export default styles
