import React from 'react'
import moment from 'moment'
import { isNull, uniqueId } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  formattedWithTimezoneStartDayString,
  MMMMDDYYYY_hmma,
  MMMMDDYYYY_startOfDay,
  mssFormat,
  utcConverter,
} from '../../../../../services/dateFormat-service'
import {
  getActivityLabels,
  getText,
} from '../../../services/related/transaction-service'
import {
  ACTIVITY_DETAILS_NAMES,
  FIELDS,
  getOfflineMessage,
  NONE_VALUE,
  OFFLINE_MODE,
  ZERO_TIME_VALUE,
} from '../../../services/dashboard-constants'
import ActivitiesLogInfo from '../activityDialog/ActivitiesLogInfo'
import { STATUS_FILTER_VALUES } from '../../../../../services/constants'
import styles from './style'

const ActivityExtraInfo = withStyles(styles)((props) => {
  const {
    activity,
    counter,
    isInProgress,
    timezone,
    isMobile,
    activityEvents,
    timeRemaining,
    isErrorCalculation,
    classes,
  } = props

  const isInterruption = isMobile && !!activityEvents?.length
  const isIncorrect = isMobile && activity.isIncorrect
  const isFaults =
    isMobile &&
    activity.txStatus === STATUS_FILTER_VALUES.cancelled &&
    activity.details?.metaInfo?.cause
  const isOffline = activity.details && activity.details[FIELDS.offline]

  const showMoreInfo = isIncorrect || isInterruption || isFaults

  const getFormattedText = (type, value, key) => {
    let text = getText(
      type,
      value,
      isErrorCalculation,
      timezone,
      activity.details
    )

    if (key === ACTIVITY_DETAILS_NAMES.remaining && isInProgress) {
      text =
        counter >= 1000 ? moment(counter).format(mssFormat) : ZERO_TIME_VALUE
    }

    if (key === ACTIVITY_DETAILS_NAMES.remaining && !isInProgress) {
      text = isNull(timeRemaining)
        ? NONE_VALUE
        : moment(timeRemaining).format(mssFormat)
    }

    if (key === ACTIVITY_DETAILS_NAMES.started && isOffline) {
      text = OFFLINE_MODE
    }

    return (
      <Typography
        className={classes.detailsText}
        variant="body2"
        color="textSecondary"
      >
        {text}
      </Typography>
    )
  }

  const getDetailsProperty = (activity) => {
    const { details = {} } = activity
    const content = []

    const labels = getActivityLabels(activity)

    for (let key in labels) {
      if (details[key] || activity[key]) {
        content.push(
          <div key={key}>
            <Typography
              variant="subtitle2"
              className={classes.detailsText}
              color="textSecondary"
              key={key}
            >
              {labels[key].label}:&nbsp;
            </Typography>
            {getFormattedText(
              labels[key].type,
              details[key] || activity[key],
              key
            )}
          </div>
        )
      }
    }

    if (isOffline) {
      const startTime = details[FIELDS.lastOnlineKnownTime]
        ? utcConverter(
            details[FIELDS.lastOnlineKnownTime],
            MMMMDDYYYY_hmma,
            timezone
          )
        : formattedWithTimezoneStartDayString(
            details[FIELDS.lastOnlineKnownTime],
            timezone,
            MMMMDDYYYY_startOfDay
          )
      const endTime = utcConverter(
        details[FIELDS.eventArrivalTime],
        MMMMDDYYYY_hmma,
        timezone
      )
      const offlineMessage = getOfflineMessage(startTime, endTime)

      content.push(
        <div key={uniqueId()}>
          <Typography
            variant="body2"
            className={classes.detailsText}
            color="error"
          >
            {offlineMessage}
          </Typography>
        </div>
      )
    }

    return content
  }

  return (
    <div className={classes.textContainer}>
      {getDetailsProperty(activity)}
      {showMoreInfo && (
        <ActivitiesLogInfo
          activity={activity}
          timezone={timezone}
          isInterruption={isInterruption}
          isIncorrect={isIncorrect}
          isFaults={isFaults}
        />
      )}
    </div>
  )
})

export default ActivityExtraInfo
