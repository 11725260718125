import React, { useState } from 'react'
import { find } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import { withMediaQuery } from '../../../../services/root/root-service'
import DialogActions from '@material-ui/core/DialogActions'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import { TIMEZONE_PROMPT_LABELS } from '../../services/account-constants'
import styles from './style'
import { connect } from 'react-redux'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'

const TimezonePromptComponent = withMediaQuery(
  withStyles(styles)((props) => {
    const { onClose, isOpen, timezones, classes, isMobile, onSubmit } = props
    const [timezone, setTimezone] = useState('')

    const changeTimezone = (event) => {
      setTimezone(event.target.value)
    }

    const handleSubmit = () => {
      const newTimezone = find(
        timezones,
        (item) => item.timezoneName === timezone
      )

      onSubmit(newTimezone)
    }

    const _timezoneNameTemplate = (item) => (
      <Box textAlign="left">{item.abbreviation}</Box>
    )

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle className={classes.modalTitleContainer} disableTypography>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            className={classes.modalTitle}
          >
            {TIMEZONE_PROMPT_LABELS.title}
          </Typography>
          <CloseButton onCloseModal={onClose} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CustomSelect
            label={TIMEZONE_PROMPT_LABELS.placeholder}
            options={timezones || []}
            onChange={changeTimezone}
            value={timezone}
            valueName="timezoneName"
            template={_timezoneNameTemplate}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!timezone}
            onClick={handleSubmit}
            className={classes.submitButton}
          >
            {TIMEZONE_PROMPT_LABELS.action}
          </Button>
        </DialogActions>
      </Dialog>
    )
  })
)

const mapStateToProps = (state) => {
  return {
    timezones: state.accountReducer.timezones,
  }
}

export default connect(mapStateToProps, null)(TimezonePromptComponent)
