import { isEmpty } from 'lodash'
import customTreeActions from '../../components/_shared/customTree/services/customTree-actions'
import arcDeviceListActions from '../../containers/Devices/List/ARC/services/arcDeviceList-actions'
import summaryActions from '../../containers/Summary/ARC/service/arcSummary-actions'
import uvgiSummaryActions from '../../containers/Summary/UVGI/services/uvgiSummary-actions'
import accountActions from './../../containers/Account/services/account-actions'
import dashboardActions from './../../containers/Dashboard/services/dashboard-actions'
import locationActions from './../../containers/Locations/service/location-actions'
import authorizationActions from './../../containers/LogIn/services/authorization-actions'
import usersActions from './../../containers/Users/services/users-actions'
import rootActions from './root-actions'

import { USER_ROLES } from '../constants'
import {
  getOrgDevices,
  initialState,
  prepareBuildVersionString,
} from './root-service'

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case rootActions.actionTypes.LOAD_USER_PERMISSIONS:
      return {
        ...state,
        isLoading: true,
      }
    case rootActions.actionTypes.LOAD_BUILD_VERSION_SUCCEEDED: {
      const { data } = action.payload
      const showBuildVersion =
        window.location.href.indexOf(' https://dashboard.rzero.com') === -1
      const buildVersion = prepareBuildVersionString(data)

      return {
        ...state,
        showBuildVersion,
        buildVersion,
      }
    }
    case rootActions.actionTypes.LOAD_USER_PERMISSIONS_FAILED:
    case rootActions.actionTypes.LOAD_FEATURE_TOGGLING_FAILED:
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    case accountActions.actionTypes.UPDATE_ORGANIZATION_TIMEZONE_SUCCEEDED:
      const { data } = action.payload

      return {
        ...state,
        firstName: data.firstName,
        lastName: data.lastName,
        organization:
          {
            name: data.organizationName,
            id: data.organizationId,
          } || '',
        timezone: data.useLocalTimezone ? null : data.timezone,
        timezoneName: data.timezoneName,
      }
    case rootActions.actionTypes.LOAD_USER_PERMISSIONS_SUCCEEDED:
      const { permissions } = action.payload

      if (window.FS) {
        window.FS.identify(permissions.id, {
          displayName:
            `${permissions.firstName} ${permissions.lastName}`.trim(),
          email: permissions.email,
        })
      }

      return {
        ...state,
        organization:
          {
            name: permissions.organizationName,
            id: permissions.organizationId,
          } || '',
        userId: permissions.id,
        role: permissions.roleId || '',
        isLoading: false,
        allowedProperties: permissions.allowedProperties || [],
        allowedPropertiesNames: permissions.allowedPropertiesNames || [],
        isOperatorView: permissions.roleId === USER_ROLES.operatorUserRole,
        isAccountOwner: permissions.roleId === USER_ROLES.accountOwnerRole,
        timezone: permissions.useLocalTimezone ? null : permissions.timezone,
        timezoneName: permissions.timezoneName,
        abbreviation: permissions.abbreviation,
        firstName: permissions.firstName,
        lastName: permissions.lastName,
        defaultCycleTime: permissions.defaultCycleTime,
      }
    case authorizationActions.actionTypes.LOGIN_TO_APP_FAILED:
      return {
        ...state,
        loginError: action.payload.message,
      }
    case authorizationActions.actionTypes.LOGIN_TO_APP_SUCCEEDED:
      return {
        ...state,
        loginError: '',
      }
    case authorizationActions.actionTypes.LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      }
    case rootActions.actionTypes.LOAD_FEATURE_TOGGLING_SUCCEEDED:
      const features = action.payload.data
      const orgDevices = getOrgDevices(features)

      return {
        ...state,
        features,
        orgDevices,
        isLocation: false,
      }
    case rootActions.actionTypes.SET_NEW_DEFAULT_CYCLE_TIME:
      return {
        ...state,
        defaultCycleTime: action.payload.defaultCycleTime,
      }
    case rootActions.actionTypes.SET_NETWORK_ERROR:
      const { isOnline } = action.payload.isOnline

      return {
        ...state,
        networkError: !isOnline,
      }
    case rootActions.actionTypes.GET_DEFAULT_DEVICE_MODE_FOR_ACCOUNT_SUCCEEDED:
      const { defaultUvgiMode } = action.payload.orgInfo

      return {
        ...state,
        defaultUvgiMode,
      }
    case usersActions.actionTypes.LOAD_USERS_LIST_SUCCEEDED:
    case summaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_SUCCEEDED:
    case locationActions.actionsTypes.LOAD_LOCATIONS_SUCCEEDED:
    case arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST_SUCCEEDED:
    case dashboardActions.actionsTypes.LOAD_DEVICES_SUCCEEDED:
    case accountActions.actionTypes.GET_ORGANIZATION_INFO_SUCCEEDED:
    case uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA_SUCCEEDED:
      return {
        ...state,
        networkError: false,
      }
    case rootActions.actionTypes.GET_LOCATION_MODIFICATION_ID_SUCCEEDED:
      return {
        ...state,
        lastLocationModification: action.payload.id,
      }
    case customTreeActions.actionsTypes.LOAD_INITIAL_LOCATION_TREE_SUCCESS:
      return {
        ...state,
        firstLoadingFinished: true,
      }
    case rootActions.actionTypes.GET_BLUEPRINT_LINK_SUCCEEDED:
      const { blueprintLink } = action.payload

      return {
        ...state,
        blueprintLink,
        isBannerPresent: !isEmpty(blueprintLink),
      }
    default:
      return state
  }
}
