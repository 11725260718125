import { COLORS } from '../../../services/constants'

const tabsWrapperStyle = () => ({
  tab: {
    textTransform: 'none',
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    '&.Mui-selected': {
      color: COLORS.BLACK,
    },
  },
  tabsWrapper: {
    backgroundColor: COLORS.WARM_GRAY,
  },
  '@media (min-width: 1224px)': {
    tab: {
      width: '150px',
      fontSize: '24px',
      '&.Mui-selected': {
        color: COLORS.BLUE,
      },
    },
    tabsWrapper: {
      margin: '0 auto',
    },
  },
})

export default tabsWrapperStyle
