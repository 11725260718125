import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import customTreeActions from '../../../components/_shared/customTree/services/customTree-actions'
import SummaryPage from './components/ARCSummaryPage'
import { SUMMARY_DOCUMENT_TITLE } from './service/arcSummary-constants'
import NetworkError from '../../../components/_shared/errors/NetworkError'
import ErrorHasOccurred from '../../../components/_shared/errors/ErrorHasOccurred'
import actions from './service/arcSummary-actions'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import {
  withMediaQuery,
  checkConnection,
} from '../../../services/root/root-service'
import { DEVICE_TYPE } from '../../../services/constants'
import Loader from '../../../components/_shared/loader/Loader'

const ARCSummaryContainerComponent = (props) => {
  const {
    loadLocations,
    isLoading,
    loadSummaryDataError,
    networkError,
    putLocationFilter,
    changeDateFilter,
    history,
    classes,
  } = props

  useEffect(() => {
    document.title = SUMMARY_DOCUMENT_TITLE

    if (
      !isEmpty(history?.location?.state) &&
      history.location.state.deviceType === DEVICE_TYPE.arc
    ) {
      const { date, dateFilterLabel, location } = history.location.state

      if (location) {
        putLocationFilter(location)
      }

      changeDateFilter(date, dateFilterLabel)
    } else {
      loadLocations()
    }
  }, [])

  return (
    <div className={classes.pageWrapper}>
      {networkError ? (
        <NetworkError hideLogo />
      ) : loadSummaryDataError ? (
        <ErrorHasOccurred hideLogo />
      ) : (
        <SummaryPage />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { ARCSummaryReducer },
    rootReducer,
  } = state

  return {
    loadSummaryDataError: ARCSummaryReducer.loadSummaryDataError,
    isLoading: ARCSummaryReducer.isLoading,
    networkError: rootReducer.networkError,
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadLocations: () => dispatch(checkConnection(() => actions.loadLocations())),
  changeDateFilter: (dateFilter, label) =>
    dispatch(
      checkConnection(() => actions.changeDateFilter(dateFilter, label))
    ),
  putLocationFilter: (filter) =>
    dispatch(checkConnection(() => actions.putLocationFilter(filter))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(style)(ARCSummaryContainerComponent)))
