import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from './style'
import GeneralActivityInfo from './generalInfo/GeneralActivityInfo'
import ARCActivityDetails from './details/ARC/ActivityDetails'
import UVGIActivityDetails from './details/UVGI/ActivityDetails'
import actions from '../../services/dashboard-actions'
import { withMediaQuery } from '../../../../services/root/root-service'
import ActivitiesLogInfo from './activityDialog/ActivitiesLogInfo'
import TypeInfo from './TypeInfo'
import {
  DEVICE_TYPE,
  STATUS_FILTER_VALUES,
} from '../../../../services/constants'
import { FIELDS } from '../../services/dashboard-constants'

const ActivityCardComponent = withStyles(styles)((props) => {
  const {
    classes,
    activity,
    activity: { expanded, deviceType, details, id, deviceSubType, txStatus },
    setExpanded,
    timezone,
    isMobile,
  } = props

  const activityEvents = details && details[FIELDS.interruptionTimes]
  const isArc = deviceType === DEVICE_TYPE.arc
  const isErrorCalculation =
    !isArc &&
    details &&
    details[FIELDS.errorCalculation] &&
    details[FIELDS.errorCalculation].length
  const isFaults =
    !isArc &&
    txStatus === STATUS_FILTER_VALUES.cancelled &&
    details?.metaInfo?.cause

  const isInterruption = !!activityEvents?.length

  const showMoreInfo = isInterruption || activity.isIncorrect || isFaults

  const handleExpandClick = () => {
    setExpanded(!expanded, id)
  }

  return (
    <>
      <Card className={classes.cardContainer}>
        <TypeInfo type={deviceType} subType={deviceSubType} />
        <CardContent className={classes.cardContent}>
          <div className={classes.itemsContainer}>
            <GeneralActivityInfo
              activity={activity}
              isErrorCalculation={isErrorCalculation}
              timezone={timezone}
            />
            {isMobile ? (
              <div className={classes.arrowColumn}>
                <IconButton
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </div>
            ) : (
              <>
                {isArc ? (
                  <ARCActivityDetails
                    activity={activity}
                    activityEvents={activityEvents}
                    isMobile={isMobile}
                    timezone={timezone}
                  />
                ) : (
                  <UVGIActivityDetails
                    activity={activity}
                    isErrorCalculation={isErrorCalculation}
                    activityEvents={activityEvents}
                    isMobile={isMobile}
                    timezone={timezone}
                  />
                )}
                {showMoreInfo && (
                  <ActivitiesLogInfo
                    activity={activity}
                    timezone={timezone}
                    isInterruption={isInterruption}
                    isIncorrect={activity.isIncorrect}
                    isFaults={isFaults}
                  />
                )}
              </>
            )}
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {isMobile && (
              <CardContent className={classes.collapseContainer}>
                {isArc ? (
                  <ARCActivityDetails
                    activity={activity}
                    activityEvents={activityEvents}
                    isMobile={isMobile}
                    timezone={timezone}
                  />
                ) : (
                  <UVGIActivityDetails
                    activity={activity}
                    isErrorCalculation={isErrorCalculation}
                    activityEvents={activityEvents}
                    isMobile={isMobile}
                    timezone={timezone}
                  />
                )}
              </CardContent>
            )}
          </Collapse>
        </CardContent>
      </Card>
    </>
  )
})

const mapDispatchToProps = (dispatch) => {
  return {
    setExpanded: (value, id) => dispatch(actions.setExpanded(value, id)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withMediaQuery(ActivityCardComponent))
