import { COLORS } from '../../../../../services/constants'

const style = () => ({
  emptyDevicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    '& > *': {
      textAlign: 'center',
    },
  },
  iconWrapper: {
    marginTop: '23px',
    marginBottom: '23px',
  },
  '@media (min-width: 1224px)': {
    emptyDevicesWrapper: {
      height: 'calc(100% - 20px)',
      backgroundColor: COLORS.WHITE,
      marginTop: '20px',
    },
  },
})

export default style
