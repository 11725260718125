import { COLORS } from '../../../../../services/constants'

const style = (theme) => ({
  pageWrapper: {
    height: 'calc(100% - 81px)',
    width: '100%',
    overflowY: 'scroll',
  },
  pageWrapperBannerOffset: {
    height: 'calc(100% - 171px)',
  },
  tableContainer: {
    margin: '1.5% 6% 80px',
  },
  container: {
    backgroundColor: COLORS.WARM_GRAY,
    marginTop: '20px',
  },
  expandMore: {
    color: COLORS.BLACK,
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    padding: 0,
    borderTop: `2px solid ${COLORS.GRAY_LINK_WATER}`,
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  headerCell: {
    padding: '15px',
    borderLeft: `1px solid ${COLORS.GRAY_FOG}`,
    borderBottom: `1px solid ${COLORS.GRAY_FOG}`,
  },
  table: {
    backgroundColor: COLORS.WARM_GRAY,
    height: '100%',
  },
  firstCell: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '12px',
    lineHeight: '14px',
    width: '160px',
    borderRight: `1px solid ${COLORS.GRAY_FOG}`,
  },
  lastCell: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '12px',
    lineHeight: '14px',
    width: '60px',
    borderLeft: `1px solid ${COLORS.GRAY_FOG}`,
  },
  cycleCell: {
    borderRight: `1px solid ${COLORS.GRAY_FOG}`,
    minWidth: '50px',
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '10px',
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '24px',
  },
  locationFilterWrapper: {
    marginLeft: '16px',
  },
  rowName: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '6px 0',
  },
  cycleCellValue: {
    fontFamily: 'Cerebri Sans Pro Medium',
    fontSize: '14px',
    lineHeight: '14px',
  },
  backButtonLabel: {
    color: COLORS.BLACK,
    fontFamily: 'Cerebri Sans Pro Bold',
  },
  backIcon: {
    width: '18px',
    marginRight: '8px',
  },
  titlesWrapper: {
    marginLeft: '6%',
  },
  backButton: {
    padding: '16px 0 0 0',
    '&:hover': {
      background: 'transparent',
    },
  },
  '@media screen and (min-width: 1224px)': {
    container: {
      backgroundColor: COLORS.WHITE,
    },
    tableHead: {
      backgroundColor: COLORS.WHITE,
    },
    cycleCell: {
      backgroundColor: COLORS.WHITE,
    },
    firstCell: {
      position: 'sticky',
      left: '0',
      top: '0',
      zIndex: '2',
      backgroundColor: COLORS.WHITE,
    },
    lastCell: {
      position: 'sticky',
      right: '0',
      top: '0',
      zIndex: '2',
      backgroundColor: COLORS.WHITE,
    },
    headerCell: {
      backgroundColor: COLORS.WHITE,
      position: 'sticky',
      left: '0',
      top: '0',
    },
  },
})

export default style
