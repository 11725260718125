const uvgiSummaryActions = {}

uvgiSummaryActions.actionsTypes = {
  CHANGE_DATE_FILTER: '{UVGI_SUMMARY} Change Date Filter',
  RESET_DATE_FILTER: '{UVGI_SUMMARY} Reset Date Filter',

  APPLY_LOCATION_FILTER: '{UVGI_SUMMARY} Apply Locations Filter',
  APPLY_LOCATION_FILTER_FAILED: '{UVGI_SUMMARY} Apply Locations Filter Failed',
  PREPARE_LOCATION_FILTER: '{UVGI_SUMMARY} Prepare Locations Filter',
  PUT_LOCATION_FILTER: '{UVGI_SUMMARY} Put Location Filter',

  LOAD_LOCATIONS: '{UVGI_SUMMARY} Load Locations',
  LOAD_LOCATIONS_FAILED: '{UVGI_SUMMARY} Load Locations Failure',

  LOAD_UVGI_SUMMARY_DATA: '{UVGI_SUMMARY} Load UVGI Summary Data',
  LOAD_UVGI_SUMMARY_DATA_SUCCEEDED:
    '{UVGI_SUMMARY} Load UVGI Summary Data Succeeded',
  LOAD_UVGI_SUMMARY_DATA_FAILED: '{UVGI_SUMMARY} Load UVGI Summary Data Failed',

  LOAD_UVGI_DEVICES_ACTIVITIES_FOR_SUMMARY:
    '{UVGI_SUMMARY} Load Device Activities For Summary',
  LOAD_UVGI_DEVICES_ACTIVITIES_FOR_SUMMARY_SUCCEEDED:
    '{UVGI_SUMMARY} Load Device Activities For Summary Succeeded',
  LOAD_UVGI_DEVICES_ACTIVITIES_FOR_SUMMARY_FAILED:
    '{UVGI_SUMMARY} Load Device Activities For Summary Failure',

  GET_DISINFECTED_LOCATIONS: '{UVGI_SUMMARY} Get Disinfected Locations Summary',
  GET_DISINFECTED_LOCATIONS_SUCCEEDED:
    '{UVGI_SUMMARY} Get Disinfected Locations Succeeded',
  GET_DISINFECTED_LOCATIONS_FAILED:
    '{UVGI_SUMMARY} Get Disinfected Locations Failure',

  GET_DEVICES: '{UVGI_SUMMARY} Get Devices',
  GET_DEVICES_SUCCEEDED: '{UVGI_SUMMARY} Get Devices Succeeded',
  GET_DEVICES_FAILED: '{UVGI_SUMMARY} Get Devices Failure',

  DOWNLOAD_PDF_PACKAGE: '{UVGI_SUMMARY} Download PDF Package',
  DOWNLOAD_PDF_PACKAGE_SUCCEEDED:
    '{UVGI_SUMMARY} Download PDF Package Succeeded',
  DOWNLOAD_PDF_PACKAGE_FAILED: '{UVGI_SUMMARY}Download PDF Package Failure',
}

uvgiSummaryActions.loadLocations = () => {
  return {
    type: uvgiSummaryActions.actionsTypes.LOAD_LOCATIONS,
  }
}

uvgiSummaryActions.loadLocationTreeFailed = (message) => {
  return {
    type: uvgiSummaryActions.actionsTypes.LOAD_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

uvgiSummaryActions.loadUVGISummaryData = () => {
  return {
    type: uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA,
  }
}

uvgiSummaryActions.loadUVGISummaryDataSuccess = (data) => {
  return {
    type: uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA_SUCCEEDED,
    payload: {
      data,
    },
  }
}

uvgiSummaryActions.loadUVGISummaryDataFailed = (message) => {
  return {
    type: uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA_FAILED,
    payload: {
      message,
    },
  }
}

uvgiSummaryActions.getDevices = () => {
  return {
    type: uvgiSummaryActions.actionsTypes.GET_DEVICES,
  }
}

uvgiSummaryActions.getDevicesSuccess = (data, timezone) => {
  return {
    type: uvgiSummaryActions.actionsTypes.GET_DEVICES_SUCCEEDED,
    payload: {
      data,
      timezone,
    },
  }
}

uvgiSummaryActions.getDevicesFailed = (message) => {
  return {
    type: uvgiSummaryActions.actionsTypes.GET_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

uvgiSummaryActions.changeDateFilter = ({ dateFilter, period }, label) => {
  return {
    type: uvgiSummaryActions.actionsTypes.CHANGE_DATE_FILTER,
    payload: {
      dateFilter,
      period,
      label,
    },
  }
}

uvgiSummaryActions.resetDateFilter = () => {
  return {
    type: uvgiSummaryActions.actionsTypes.RESET_DATE_FILTER,
  }
}

uvgiSummaryActions.applyLocationFilter = (nodes) => {
  return {
    type: uvgiSummaryActions.actionsTypes.APPLY_LOCATION_FILTER,
    payload: {
      nodes,
    },
  }
}

uvgiSummaryActions.applyLocationFilterFailed = (message) => {
  return {
    type: uvgiSummaryActions.actionsTypes.APPLY_LOCATION_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

uvgiSummaryActions.prepareLocationFilter = (nodes, tree, userId) => {
  return {
    type: uvgiSummaryActions.actionsTypes.PREPARE_LOCATION_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

uvgiSummaryActions.loadDeviceActivitiesForSummary = () => {
  return {
    type: uvgiSummaryActions.actionsTypes
      .LOAD_UVGI_DEVICES_ACTIVITIES_FOR_SUMMARY,
  }
}

uvgiSummaryActions.loadDeviceActivitiesForSummarySuccess = (
  data,
  locationTree
) => {
  return {
    type: uvgiSummaryActions.actionsTypes
      .LOAD_UVGI_DEVICES_ACTIVITIES_FOR_SUMMARY_SUCCEEDED,
    payload: {
      data,
      locationTree,
    },
  }
}

uvgiSummaryActions.loadDeviceActivitiesForSummaryFailed = (message) => {
  return {
    type: uvgiSummaryActions.actionsTypes
      .LOAD_UVGI_DEVICES_ACTIVITIES_FOR_SUMMARY_FAILED,
    payload: {
      message,
    },
  }
}

uvgiSummaryActions.loadDisinfectedLocations = () => {
  return {
    type: uvgiSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS,
  }
}

uvgiSummaryActions.loadDisinfectedLocationsSuccess = (data) => {
  return {
    type: uvgiSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_SUCCEEDED,
    payload: {
      data,
    },
  }
}

uvgiSummaryActions.loadDisinfectedLocationsFailed = (message) => {
  return {
    type: uvgiSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

uvgiSummaryActions.putLocationFilter = ({ locationFilter, ids }) => {
  return {
    type: uvgiSummaryActions.actionsTypes.PUT_LOCATION_FILTER,
    payload: {
      locationFilter,
      ids,
    },
  }
}

uvgiSummaryActions.downloadPDFPackage = () => {
  return {
    type: uvgiSummaryActions.actionsTypes.DOWNLOAD_PDF_PACKAGE,
  }
}

uvgiSummaryActions.downloadPDFPackageSuccess = () => {
  return {
    type: uvgiSummaryActions.actionsTypes.DOWNLOAD_PDF_PACKAGE_SUCCEEDED,
  }
}

uvgiSummaryActions.downloadPDFPackageFailed = (message) => {
  return {
    type: uvgiSummaryActions.actionsTypes.DOWNLOAD_PDF_PACKAGE_FAILED,
    payload: {
      message,
    },
  }
}

export default uvgiSummaryActions
