import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'
import { getStorageDataById } from '../../../../services/defaultStore-sevice'
import { prepareLocationFiltersForDevice } from '../../../Devices/_shared/services/devices-service'
import treeActions from './../../../../components/_shared/customTree/services/customTree-actions'
import actions from '../../services/dashboard-actions'
import { saveLocationPrefSettings } from '../../services/dashboard-service'
import LocationCheckboxTree from './LocationCheckboxTree'
import styles from './style'
import { createLocationLabel } from '../../../../services/root/root-service'
import {
  deeps,
  LOCATION_FILTER_LABELS,
  LOCATION_LABELS,
  PREF,
} from '../../../../services/constants'
import { gaEventAction } from '../../../../services/googleAnalytics/ga-actions'

const LocationFilter = withStyles(styles)((props) => {
  const {
    applyLocationFilter,
    nodes,
    locationFilter,
    isAllSelected,
    startPollingModification,
    stopPollingModification,
    loadNodesSucceeded,
    userId,
    treeCopy,
    isInitialState,
    gaEventSend,
  } = props
  const [open, setOpen] = useState(false)
  const [includeUnknownLocations, setFlag] = useState(false)
  const [includeDeletedLocations, setDeletedFlag] = useState(false)
  const [locationLabel, setLocationLabel] = useState('')

  useEffect(() => {
    const label = createLocationLabel(locationFilter, isAllSelected)

    setLocationLabel(label)
  }, [locationFilter, isAllSelected])

  const handleClickOpen = () => {
    if (!isInitialState) {
      setOpen(true)

      setFlag(getStorageDataById(PREF.UNKNOWN_LOCATIONS, userId))
      setDeletedFlag(getStorageDataById(PREF.DELETED_LOCATIONS, userId))
      stopPollingModification()
    }
  }

  const handleClose = () => {
    setOpen(false)

    loadNodesSucceeded(userId, treeCopy, deeps)
  }

  const onApply = () => {
    const params = prepareLocationFiltersForDevice(nodes)

    saveLocationPrefSettings(
      PREF.LOCATIONS,
      userId,
      isAllSelected ? null : params.selectedBuildings
    )
    saveLocationPrefSettings(
      PREF.INDETERMINATE,
      userId,
      params.indeterminateProperties
    )
    saveLocationPrefSettings(
      PREF.UNKNOWN_LOCATIONS,
      userId,
      includeUnknownLocations
    )
    saveLocationPrefSettings(
      PREF.DELETED_LOCATIONS,
      userId,
      includeDeletedLocations
    )

    applyLocationFilter(params)
    setOpen(false)

    gaEventSend('location_filter', {
      event_label: nodes.filter((node) => node.checked).length,
    })

    startPollingModification(true)
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        label={`${LOCATION_LABELS.location}: ${locationLabel}`}
        variant="outlined"
      />
      <LocationCheckboxTree
        onApply={onApply}
        actionLabel={LOCATION_FILTER_LABELS.actionLabel}
        modalTitle={LOCATION_FILTER_LABELS.modalTitle}
        includeUnknownLocations={includeUnknownLocations}
        includeDeletedLocations={includeDeletedLocations}
        setFlag={setFlag}
        setDeletedFlag={setDeletedFlag}
        onClose={handleClose}
        isOpen={open}
      />
    </>
  )
})

const mapStateToProps = (state) => ({
  nodes: state.customTreeReducer.nodes,
  isAllSelected: state.customTreeReducer.isAllSelected,
  locationFilter: state.dashboardReducer.locationFilter,
  treeCopy: state.customTreeReducer.treeCopy,
  userId: state.rootReducer.userId,
  isInitialState: state.dashboardReducer.isInitialState,
})

const mapDispatchToProps = (dispatch) => {
  return {
    applyLocationFilter: (nodes) =>
      dispatch(actions.applyLocationFilter(nodes)),
    startPollingModification: () =>
      dispatch(actions.startPollingModification()),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    loadNodesSucceeded: (id, nodes, deeps) =>
      dispatch(treeActions.loadNodesSucceeded(id, nodes, deeps)),
    gaEventSend: gaEventAction(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationFilter)
