import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import styles from './style'
import { EMPTY_PAGE_TEXT, COLORS } from '../../../../../services/constants'
import SvgIconComponent from '../../../../../components/_shared/SvgIconComponent'

const EmptyDevicesPageComponent = (props) => {
  const { classes } = props

  return (
    <div className={classes.emptyDevicesWrapper}>
      <div className={classes.iconWrapper}>
        <SvgIconComponent
          name="devices-2x2"
          size={54}
          color={COLORS.LIGHT_GRAY_SECONDARY}
        />
      </div>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.emptyListText}
      >
        {EMPTY_PAGE_TEXT}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(EmptyDevicesPageComponent)
