import { jsPDF } from 'jspdf'
import { Canvg } from 'canvg'

export class BasePdfElement {
  doc: jsPDF = new jsPDF()
  position_x = 0
  position_y = 0
  height = 0
  width = 0

  setup(
    doc: jsPDF,
    position_x: number,
    position_y: number,
    height: number,
    width: number
  ) {
    this.doc = doc
    this.position_x = position_x
    this.position_y = position_y
    this.height = height
    this.width = width
  }

  async render() {
    console.log('not implemented')
  }

  getPureText(element: Element) {
    return element.innerHTML.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
  }

  addCenteredTextH(
    text: string,
    position_x: number,
    position_y: number,
    width: number
  ) {
    const dim = this.doc.getTextDimensions(text)
    this.doc.text(text, position_x + (width - dim.w) / 2, position_y)
  }

  addCenteredTextV(
    text: string,
    position_x: number,
    position_y: number,
    height: number
  ) {
    const dim = this.doc.getTextDimensions(text)
    this.doc.text(text, position_x, position_y + dim.h + (height - dim.h) / 2)
  }

  async svgToImg(
    svg: SVGSVGElement,
    x: number,
    y: number,
    maxWidth: number,
    maxHeight: number
  ) {
    const original_h = parseInt(svg.getAttribute('height') || '0')
    const original_w = parseInt(svg.getAttribute('width') || '0')
    const rate = original_h / original_w

    let img_width = maxWidth
    let img_height = maxWidth * rate

    if (img_height > maxHeight) {
      img_height = maxHeight
      img_width = maxHeight / rate
    }

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.clearRect(0, 0, img_width, img_height)
      const canvg = await Canvg.from(context, svg.outerHTML)
      canvg.start()

      this.doc.addImage(canvas, x, y, img_width, img_height)
    }
  }
}
