import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEqual, isNull } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { autoHideDuration } from '../../../../../../services/constants'
import { checkConnection } from '../../../../../../services/root/root-service'
import actions from '../../services/arcDeviceDetails-actions'
import ARCLocationButton from '../../../../_shared/components/addDeviceFunctionality/ARCLocationButton'
import styles from './style'
import {
  CHANGES_SAVED_LABEL,
  DEVICE_DETAILS_LABELS,
  DEVICE_MAX_NICKNAME_LENGTH,
} from '../../../../_shared/services/devices-constants'
import Snackbar from '@material-ui/core/Snackbar'

const ChangeDeviceDetailsForm = (props) => {
  const {
    isReadOnlyView,
    initialNickname,
    serialNumber,
    updateDevice,
    allowedLocationIds,
    locationFilter,
    isAllSelected,
    setupLocationsFilter,
    classes,
  } = props
  const [nickname, setNickname] = useState(initialNickname)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [isChanged, setIsChangedFlag] = useState(false)
  const { selectedBuildings } = locationFilter
  const error = selectedBuildings && !selectedBuildings.length

  useEffect(() => {
    const isNicknameSame = isEqual(nickname, initialNickname)
    const isLocationsSame = isNull(allowedLocationIds)
      ? isAllSelected
      : isEqual(selectedBuildings, allowedLocationIds)

    if (isNicknameSame && isLocationsSame) {
      setIsChangedFlag(false)
    } else {
      setIsChangedFlag(true)
    }
  }, [nickname, selectedBuildings, allowedLocationIds, isAllSelected])

  useEffect(() => {
    setNickname(initialNickname)
  }, [initialNickname])

  const onChangeNickname = (event) => {
    setNickname(event.target.value)
  }

  const disableButton = () => {
    setIsChangedFlag(false)
  }

  const onSuccess = () => {
    disableButton()

    setOpenSnackbar(true)
  }

  const handleUpdate = (event) => {
    event.preventDefault()

    updateDevice(
      serialNumber,
      {
        nickname:
          isNull(initialNickname) && isNull(nickname) ? '' : nickname.trim(),
      },
      onSuccess
    )
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  return (
    <>
      <form onSubmit={handleUpdate} className={classes.formWrapper}>
        <ARCLocationButton
          error={error}
          setupLocationsFilter={setupLocationsFilter}
          locationFilter={locationFilter}
          disabled={isReadOnlyView}
        />

        <TextField
          variant="outlined"
          label={DEVICE_DETAILS_LABELS.nickname}
          value={nickname}
          onChange={onChangeNickname}
          className={classes.input}
          fullWidth
          disabled={isReadOnlyView}
          inputProps={{ maxLength: DEVICE_MAX_NICKNAME_LENGTH }}
        />
        {!isReadOnlyView && (
          <Button
            type="submit"
            disabled={!isChanged || error}
            variant="contained"
            color="primary"
            className={classes.saveButton}
          >
            {DEVICE_DETAILS_LABELS.saveChanges}
          </Button>
        )}
      </form>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        message={CHANGES_SAVED_LABEL}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { ARCDeviceDetailsReducer },
    customTreeReducer,
  } = state

  return {
    allowedLocationIds: ARCDeviceDetailsReducer.allowedLocationIds,
    locationFilter: ARCDeviceDetailsReducer.locationFilter,
    isAllSelected: customTreeReducer.isAllSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateDevice: (serialNumber, body, onSuccess) =>
      dispatch(
        checkConnection(() =>
          actions.updateDevice(serialNumber, body, onSuccess)
        )
      ),
    setupLocationsFilter: (nodes) =>
      dispatch(actions.setupLocationsFilter(nodes)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChangeDeviceDetailsForm))
