import React from 'react'
import { connect } from 'react-redux'
import { forIn, isEmpty, uniqueId, forEach, map } from 'lodash'
import UserCard from './userCard/UserCard'
import CustomDividerComponent from '../../../components/_shared/customDivider/CustomDevider'
import EmptyUsersList from './emptyUsersList/EmptyUsersList'
import { groupUsers } from '../services/users-service'
import { getLabelWithCount } from '../../../services/root/root-service'
import { USER_LABEL } from '../services/users-constants'

const UsersList = (props) => {
  const { searchValue, error, history, currentRole, clearSearch, users } = props

  const renderUsersGroups = (usersGroups) => {
    const isNoUserGroups = isEmpty(usersGroups)
    const elementArray = []

    if (!isNoUserGroups) {
      forIn(usersGroups, (value, key) => {
        const subLabel = getLabelWithCount(value.length, USER_LABEL)

        elementArray.push(
          <div key={uniqueId()}>
            <CustomDividerComponent
              label={key}
              subLabel={subLabel}
              margin="10px"
            />

            {map(value, (item) => {
              return (
                <UserCard
                  key={item.id}
                  currentRole={currentRole}
                  history={history}
                  searchValue={searchValue}
                  error={error}
                  user={item}
                />
              )
            })}
          </div>
        )
      })
    }

    return (
      !isNoUserGroups &&
      forEach(elementArray, (item) => {
        return item
      })
    )
  }

  const usersGroups = groupUsers(users)

  const usersToDisplay =
    users?.length > 0 ? (
      renderUsersGroups(usersGroups)
    ) : (
      <EmptyUsersList clearSearch={clearSearch} />
    )

  return <>{error ? <div>{error}</div> : usersToDisplay}</>
}

const mapStateToProps = (state) => ({
  currentRole: state.rootReducer.role,
  users: state.usersReducer.filteredUsers,
  error: state.usersReducer.error,
})

export default connect(mapStateToProps, null)(UsersList)
