import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import styles from '../style'
import { Link } from 'react-router-dom'
import { LOCATIONS_LABELS } from '../../service/location-constants'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const BackButton = (props) => {
  const { classes, routes, changeLocation } = props
  const item = routes[routes.length - 2]

  const goBack = () => {
    changeLocation(item.pathname)
  }

  return item ? (
    <div className={classes.backToNavigation}>
      <Typography
        color="textSecondary"
        variant="body1"
        className={classes.navLink}
      >
        <Link
          to={item.pathname}
          color="textSecondary"
          underline="none"
          className={classes.navLink}
          onClick={goBack}
        >
          <ArrowBackIcon className={classes.backIcon} />
          {LOCATIONS_LABELS.back}
        </Link>
      </Typography>
    </div>
  ) : (
    <div></div>
  )
}

export default withStyles(styles)(BackButton)
