import { COLORS } from '../../../../../services/constants'

const style = (theme) => ({
  chartContainer: {
    padding: '10px',
    backgroundColor: COLORS.WARM_GRAY,
    marginTop: '14px',
  },
  subtitle: {
    marginTop: '5px',
    fontSize: '12px',
  },
  chart: {
    fontFamily: 'Cerebri Sans Pro Regular',
    height: '250px',
    marginTop: '20px',
  },
  chartPdf: {
    display: 'none',
  },
  '@media (min-width: 1224px)': {
    chartContainer: {
      backgroundColor: COLORS.WHITE,
      padding: '25px',
      height: 'auto',
    },
    chart: {
      height: '300px',
    },
  },
})

export default style
