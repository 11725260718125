const DELETE_DEVICE = 'Delete Device'

const ARCInitialDeviceDetailsState = {
  nodes: {},
  deviceDetails: { details: {} },
  error: '',
  updateError: '',
  deleteError: '',
  locationFilterError: '',
  isLoading: true,
  locationsError: '',
  requestSent: false,
  locationFilter: {},
  nickname: '',
  serialNumber: '',
  deviceLinked: true,
  allowedLocationIds: null,
}

const getDeviceDetailsPageLabel = (deviceNumber) => `Device ${deviceNumber}`

export {
  DELETE_DEVICE,
  getDeviceDetailsPageLabel,
  ARCInitialDeviceDetailsState,
}
