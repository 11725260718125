import React from 'react'
import { isEmpty } from 'lodash'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { withStyles } from '@material-ui/core/styles'
import logo from '../../../assets/icons/rzero-logo-pad.svg'
import actions from '../../../containers/LogIn/services/authorization-actions'
import {
  goToSummary,
  withMediaQuery,
} from '../../../services/root/root-service'
import styles from './style'
import MenuPhoneComponent from './menu/MenuPhoneComponent'
import { MENU_ITEMS } from './menu/services/menu-constants'
import { connect } from 'react-redux'
import MenuDesktopComponent from './menu/MenuDesktopComponent'
import { useLocation } from 'react-router-dom'
import OpenDownloadModalButton from '../../../containers/Dashboard/components/downloadModal/OpenDownloadModalButton'
import { IMAGE_ALT } from '../../../services/constants'
import classNames from 'classnames'

const HeaderComponent = withStyles(styles)((props) => {
  const {
    classes,
    isOperatorView,
    role,
    firstName,
    lastName,
    organizationName,
    isMobile,
    logout,
    blueprintLink,
    isInitialState,
  } = props
  const location = useLocation()

  return (
    <>
      <AppBar
        position="relative"
        color="transparent"
        className={
          isEmpty(blueprintLink)
            ? classes.appBar
            : classNames(classes.appBar, classes.noShadow)
        }
      >
        <Toolbar className={classes.toolbar}>
          {isMobile ? (
            <div className={classes.mobileHeaderWrapper}>
              <img
                alt={IMAGE_ALT.LOGO}
                src={logo}
                className={classes.logoImage}
                onClick={goToSummary}
              />
              <>
                {location.pathname === MENU_ITEMS.dashboard.to && (
                  <OpenDownloadModalButton isInitialState={isInitialState} />
                )}
                <MenuPhoneComponent
                  isOperatorView={isOperatorView}
                  firstName={firstName}
                  lastName={lastName}
                  organizationName={organizationName}
                  logout={logout}
                />
              </>
            </div>
          ) : (
            <MenuDesktopComponent
              role={role}
              firstName={firstName}
              lastName={lastName}
              organizationName={organizationName}
              logout={logout}
            />
          )}
        </Toolbar>
      </AppBar>
    </>
  )
})

const mapStateToProps = (state) => ({
  role: state.rootReducer.role,
  isOperatorView: state.rootReducer.isOperatorView,
  firstName: state.rootReducer.firstName,
  lastName: state.rootReducer.lastName,
  organizationName: state.rootReducer.organization.name,
  isInitialState: state.dashboardReducer.isInitialState,
  blueprintLink: state.rootReducer.blueprintLink,
})

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(HeaderComponent))
