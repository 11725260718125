import React from 'react'
import { connect } from 'react-redux'
import ErrorHasOccurred from '../../../../../components/_shared/errors/ErrorHasOccurred'
import actions from '../services/arcDeviceList-actions'
import DeviceCard from './deviceCard/DeviceCard'
import EmptyDevicesPageComponent from '../../../_shared/components/emptyDevicesPage/EmptyDevicesPage'
import { map } from 'lodash'
import { DEVICE_TYPES } from '../../../../../services/constants'
import { gaEventAction } from '../../../../../services/googleAnalytics/ga-actions'

const ARCDeviceList = (props) => {
  const {
    loadDevicesError,
    putDeviceDetails,
    history,
    devices,
    timezone,
    gaEventSend,
  } = props

  const goToDetails = (device) => {
    const { deviceId } = device

    putDeviceDetails(device)
    history.push(`/devices/${DEVICE_TYPES.arc.path}/${deviceId}`)
  }

  const getPageData = () => {
    return devices && devices.length ? (
      map(devices, (item) => {
        return (
          <DeviceCard
            key={item.id}
            timezone={timezone}
            openDetails={goToDetails}
            device={item}
            gaEventSend={gaEventSend}
          />
        )
      })
    ) : (
      <EmptyDevicesPageComponent />
    )
  }

  return (
    <>
      {loadDevicesError ? <ErrorHasOccurred hideLogo={true} /> : getPageData()}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  putDeviceDetails: (device) => dispatch(actions.putDeviceDetails(device)),
  gaEventSend: gaEventAction(dispatch),
})

const mapStateToProps = (state) => {
  const {
    devicesReducer: { ARCDeviceListReducer },
    rootReducer,
  } = state

  return {
    devices: ARCDeviceListReducer.devices,
    loadDevicesError: ARCDeviceListReducer.loadDevicesError,
    timezone: rootReducer.timezone,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ARCDeviceList)
