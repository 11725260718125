import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { TREE_LEVELS } from '../../../services/constants'
import SingleSelectionTreeNode from './SingleSelectionTreeNode'
import styles from './style'
import actions from './services/customTree-actions'
import { map } from 'lodash'

const SingleSelectionTree = withStyles(styles)((props) => {
  const {
    nodes,
    setExpandNode,
    setCheckSingleNode,
    selectableLevel,
    userId,
    setPropertyExpandNode,
  } = props

  const onCheck = (key, value) => {
    setCheckSingleNode(key, value)
  }

  const onExpand = (key, deep, propertyId) => {
    if (deep === TREE_LEVELS.PROPERTY) {
      setPropertyExpandNode(key, userId)
    } else {
      setExpandNode(key, propertyId)
    }
  }

  const renderTreeNodes = (nodes, parent = {}, deep) => {
    const renderNodes = (nodes, deep) => {
      return map(nodes, (node) => {
        const parentExpanded = parent.key ? parent.expanded : true

        if (!parentExpanded) {
          return null
        }

        const margin = deep * 15 + 'px'
        const nextDeep = deep + 1

        return (
          <div key={node.id}>
            <SingleSelectionTreeNode
              margin={margin}
              isSelectable={node.locationType === selectableLevel}
              value={node.id}
              propertyId={node.propertyId}
              onExpand={onExpand}
              onCheck={onCheck}
              checked={node.checked}
              indeterminate={node.indeterminate || false}
              name={node.name}
              expanded={node.expanded}
              isParent={node.isParent}
              deep={node.locationType}
            >
              {node.isParent && renderTreeNodes(node.children, node, nextDeep)}
            </SingleSelectionTreeNode>
          </div>
        )
      })
    }

    return renderNodes(nodes, deep)
  }

  return renderTreeNodes(nodes, undefined, 0)
})

const mapStateToProps = (state) => ({
  nodes: state.customTreeReducer.nodes,
  userId: state.rootReducer.userId,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setExpandNode: (key, propertyId) =>
      dispatch(actions.setExpandNode(key, propertyId)),
    setPropertyExpandNode: (key, userId) =>
      dispatch(actions.setPropertyExpandNode(key, userId)),
    setCheckSingleNode: (key, bool, mainLevel, propertyId) =>
      dispatch(actions.setCheckSingleNode(key, bool, mainLevel, propertyId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleSelectionTree)
