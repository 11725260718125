const styles = (theme) => ({
  chipWrapper: {
    marginTop: '20px',
  },
  '@media (min-width: 1224px)': {
    chipWrapper: {
      marginTop: 0,
    },
    chip: {
      height: '45px',
      borderRadius: '45px',
    },
  },
})

export default styles
