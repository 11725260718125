import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import { ArrowBack } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import { connect } from 'react-redux'
import { map } from 'lodash'
import { withMediaQuery } from '../../../../services/root/root-service'
import { API_KEY_LABELS } from '../../services/account-constants'
import actions from '../../services/account-actions'
import ApiKeyRow from './ApiKeyRow'
import NetworkError from '../../../../components/_shared/errors/NetworkError'
import styles from './style'

const ApiKeyComponent = withStyles(styles)((props) => {
  const {
    classes,
    getAPIKeyPageAccess,
    apiKeysList,
    regenerateNewEntry,
    error,
    isMobile,
    history,
  } = props
  const [openMessage, setShowMessage] = useState(false)

  useEffect(() => {
    getAPIKeyPageAccess()
  }, [])

  const regenerateApiKey = (id, onSuccess) => {
    regenerateNewEntry(id, onSuccess)
  }

  const hideMessage = () => {
    setShowMessage(false)
  }

  const showMessage = () => {
    setShowMessage(true)
  }

  const goToAccountSettings = () => {
    history.push('/account')
  }

  return (
    <div className={classes.pageWrapper}>
      {isMobile ? (
        <Typography className={classes.pageTitle} variant="subtitle2">
          {API_KEY_LABELS.apiKey}
        </Typography>
      ) : (
        <>
          <Link
            underline="none"
            component="div"
            className={classes.backLink}
            onClick={goToAccountSettings}
            color="inherit"
          >
            {API_KEY_LABELS.backToDesktop}
          </Link>
          <div className={classes.pageTitle}>
            {API_KEY_LABELS.manageApiKeys}
          </div>
        </>
      )}
      <div className={isMobile ? '' : classes.apiKeysList}>
        {isMobile && (
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={goToAccountSettings}
              className={classes.backButton}
            >
              <ArrowBack className={classes.backIcon} />
            </IconButton>
            <Typography variant="subtitle2" color="textPrimary">
              {API_KEY_LABELS.backTo}
            </Typography>
          </Box>
        )}
        {window.navigator.onLine ? (
          error ? (
            <div>{error}</div>
          ) : (
            map(apiKeysList, (item) => {
              return (
                <ApiKeyRow
                  showMessage={showMessage}
                  key={item.id}
                  apiKey={item}
                  regenerateApiKey={regenerateApiKey}
                />
              )
            })
          )
        ) : (
          <NetworkError />
        )}
      </div>
      <Snackbar
        className={classes.successSnackbar}
        open={openMessage}
        autoHideDuration={3000}
        onClose={hideMessage}
        message={API_KEY_LABELS.copied}
      />
    </div>
  )
})

const mapDispatchToProps = (dispatch) => {
  return {
    getAPIKeyPageAccess: () => dispatch(actions.getAPIKeyPageAccess()),
    regenerateNewEntry: (id, onSuccess) =>
      dispatch(actions.regenerateNewEntry(id, onSuccess)),
  }
}

const mapStateToProps = (state) => {
  return {
    apiKeysList: state.accountReducer.apiKeysList,
    error: state.accountReducer.apiKeyError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(ApiKeyComponent))
