import React, { useEffect, useState } from 'react'
import { findIndex, filter, map } from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { withMediaQuery } from '../../../services/root/root-service'
import style from './style'
import Typography from '@material-ui/core/Typography'
import TabPanel from './TabPanel'
import TabsForDevices from './TabsForDevices'
import EmptyScreen from '../emptyScreen/EmptyScreen'
import { NO_DEVICE_TYPES } from '../../../containers/Devices/_shared/services/devices-constants'

const DeviceTabsContainer = ({
  isMobile,
  classes,
  activeTab,
  changeActiveTab,
  orgDevices,
  devicesConfig,
  title,
  fixedHeader = true,
  isBannerPresent,
  history,
}) => {
  const [devicesTypes, setDevices] = useState([])

  const tabsContainer = fixedHeader
    ? classNames(
        classes.tabsContainer,
        isBannerPresent ? classes.tabsContainerBannerOffset : ''
      )
    : classNames(
        classes.tabsContainer,
        classes.overflowAuto,
        isBannerPresent ? classes.tabsContainerBannerOffset : ''
      )

  const containerWithPadding = fixedHeader
    ? classes.pageContainer
    : classes.pageContainerWithoutPadding
  const pageContainer = fixedHeader
    ? classNames(
        containerWithPadding,
        classes.overflowAuto,
        classes.simplePageContainer
      )
    : classNames(containerWithPadding, classes.simplePageContainer)

  const container = fixedHeader
    ? classes.container
    : classNames(classes.container, classes.overflowAuto)

  const handleChange = (event, newValue) => {
    changeActiveTab(newValue, event)
  }

  useEffect(() => {
    if (!devicesTypes.length) {
      const filteredDevices = filter(
        devicesConfig,
        (item) => findIndex(orgDevices, ['id', item.id]) !== -1
      )
      const newActiveTab = activeTab || filteredDevices[0]?.index || 0

      changeActiveTab(newActiveTab)
      setDevices(filteredDevices)
    }
  }, [orgDevices])

  return (
    <div
      id="tabs-container"
      className={devicesTypes.length > 1 ? tabsContainer : container}
    >
      <Typography
        variant={isMobile ? 'subtitle2' : 'h3'}
        className={classes.title}
      >
        {title}
      </Typography>
      {devicesTypes.length === 0 ? (
        <div className={classes.emptyScreenContainer}>
          <EmptyScreen helperText={NO_DEVICE_TYPES} />
        </div>
      ) : devicesTypes.length > 1 ? (
        <>
          <TabsForDevices
            handleChange={handleChange}
            value={activeTab}
            devicesTypes={devicesTypes}
          />
          {map(devicesTypes, (device) => (
            <TabPanel
              value={activeTab}
              key={device.id}
              index={device.index}
              stylesForTabPanel={pageContainer}
              fixedHeader={fixedHeader}
            >
              {device.component(history)}
            </TabPanel>
          ))}
        </>
      ) : (
        <div className={pageContainer}>
          {devicesTypes[0] && devicesTypes[0].component(history)}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  orgDevices: state.rootReducer.orgDevices,
  isBannerPresent: state.rootReducer.isBannerPresent,
})

export default connect(mapStateToProps)(
  withMediaQuery(withStyles(style)(DeviceTabsContainer))
)
