import { COLORS } from '../../../../../../../services/constants'

const style = (theme) => ({
  saveButton: {
    height: '22px',
    fontSize: '12px',
    marginTop: '10px',
  },
  locationField: {
    width: '100%',
    display: 'grid',
    borderRadius: '26px',
    paddingLeft: '15px',
    justifyContent: 'left',
    height: '56px',
    margin: '10px 0',
    fontSize: '16px',
    lineHeight: '19px',
    color: COLORS.GRAY,
  },
  locationError: {
    borderColor: COLORS.RED_ORANGE,
    color: COLORS.RED_ORANGE,
  },
  '@media (min-width: 1224px)': {
    formWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
      borderTop: `1px solid ${COLORS.GRAY_IRON}`,
      borderBottom: `1px solid ${COLORS.GRAY_IRON}`,
      '& > *': {
        width: '30%',
      },
      '& > :first-child': {
        marginRight: '20px',
      },
    },
    leftPadding: {
      paddingLeft: '200px',
    },
    saveButton: {
      position: 'relative',
      bottom: '-452px',
      minWidth: '190px',
      width: '200px',
      left: '6%',
      height: '45px',
      fontSize: '16px',
    },
    inputsWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
      minWidth: '100%',
      '& > *': {
        width: '30%',
      },
      '& > :first-child': {
        marginRight: '20px',
      },
    },
  },
  '@media (min-width: 1650px)': {
    saveButton: {
      left: '10%',
    },
  },
})

export default style
