import React from 'react'
import { connect } from 'react-redux'
import actions from './../../services/dashboard-actions'
import ItemsPerPage from '../../../../components/_shared/pagination/ItemsPerPage'
import ListPagination from '../../../../components/_shared/pagination/ListPagination'
import PaginationResults from '../../../../components/_shared/pagination/PaginationResults'
import { withMediaQuery } from '../../../../services/root/root-service'
import styles from './style'
import { withStyles } from '@material-ui/core'

const DashboardPaginationComponent = withStyles(styles)((props) => {
  const {
    page,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    maxPage,
    goToNextPage,
    isMobile,
    startPollingModification,
    stopPollingModification,
    enableQuickCount,
    classes,
  } = props

  return (
    <div className={classes.dashboardContainer}>
      {isMobile ? (
        <>
          <ListPagination
            page={page}
            maxPage={maxPage}
            goToNextPage={goToNextPage}
            stopPollingModification={stopPollingModification}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            enableQuickCount={enableQuickCount}
          />
          <div className={classes.pageSettings}>
            <ItemsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              stopPolling={stopPollingModification}
              startPolling={startPollingModification}
            />
            <PaginationResults
              page={page}
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
              enableQuickCount={enableQuickCount}
            />
          </div>
        </>
      ) : (
        <>
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            stopPolling={stopPollingModification}
            startPolling={startPollingModification}
          />

          <ListPagination
            page={page}
            maxPage={maxPage}
            stopPollingModification={stopPollingModification}
            goToNextPage={goToNextPage}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            enableQuickCount={enableQuickCount}
          />
          <PaginationResults
            page={page}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            enableQuickCount={enableQuickCount}
          />
        </>
      )}
    </div>
  )
})

const mapStateToProps = (state) => ({
  page: state.dashboardReducer.page,
  rowsPerPage: state.dashboardReducer.rowsPerPage,
  totalCount: state.dashboardReducer.totalCount,
  enableQuickCount: state.dashboardReducer.enableQuickCount,
  maxPage: state.dashboardReducer.maxPage,
})

const mapDispatchToProps = (dispatch) => ({
  setRowsPerPage: (value) => dispatch(actions.setRowsPerPage(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(DashboardPaginationComponent))
