import extDeviceDetailsActions from './extDeviceDetails-actions'
import { prepareDevicesList } from '../../../List/ARC/services/arcDeviceList-service'

export const extDeviceDetailsReducer = (
  state = {
    deviceDetails: {},
    isLoading: true,
    nodes: {},
    allowedLocationIds: null,
    roomsFilter: {},
  },
  action
) => {
  switch (action.type) {
    case extDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case extDeviceDetailsActions.actionTypes.PUT_DEVICE_DETAILS: {
      const { deviceDetails } = action.payload

      return {
        ...state,
        deviceDetails,
        allowedLocationIds: deviceDetails.allowedLocationIds,
        serialNumber: deviceDetails.serialNumber,
        deviceId: deviceDetails.id,
        isLoading: false,
        message: '',
      }
    }
    case extDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    case extDeviceDetailsActions.actionTypes
      .LOAD_LOCATIONS_FOR_DEVICE_SUCCEEDED: {
      const { nodes } = action.payload
      const list = prepareDevicesList(state.list, nodes)

      return {
        ...state,
        nodes,
        list,
        loadDevicesError: '',
        isLoading: false,
      }
    }
    case extDeviceDetailsActions.actionTypes.RESET_ROOMS_FILTER:
      return {
        ...state,
        roomsFilter: {},
      }
    case extDeviceDetailsActions.actionTypes.SETUP_ROOMS_FILTER:
      return {
        ...state,
        roomsFilter: action.payload.nodes,
        locationFilterError: '',
      }
    default:
      return state
  }
}
