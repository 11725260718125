const MAINTENANCE_REMINDER = {
  title: 'Maintenance Reminder',
  hint: 'Wipe down bulbs',
  actionLabel: 'Complete and Dismiss',
}

const DEVICE_RUN_TIME = {
  current: 'Current Run Time',
  total: 'Total Run Time Today',
}

const RECENT_USAGE_DATA = {
  title: 'Most Recent Usage Data',
  lastActive: 'Last Active',
  startTime: 'Start Time',
  endTime: 'End Time',
  cumulativeUsage: 'Bulb lifetime use',
  noInformation: 'No information',
}

const initialStateUVGI = {
  roomsFilter: {},
  deviceDetails: { details: {} },
  nickname: '',
  isLoading: true,
  updateError: '',
  isInitialDetailsState: true,
  allowedLocationIds: null,
  lastModification: null,
  needToUpdateData: true,
  cumulativeUsage: null,
}

const ERRORS = {
  error: 'ERROR',
  off_error: 'OFF_ERROR',
}

export {
  MAINTENANCE_REMINDER,
  DEVICE_RUN_TIME,
  RECENT_USAGE_DATA,
  initialStateUVGI,
  ERRORS,
}
