const style = () => ({
  pageWrapper: {
    padding: '0 5%',
    width: '90%',
  },
  error: {
    marginTop: '5px',
  },
  '@media (min-width: 1224px)': {
    title: {
      textAlign: 'center',
      margin: '40px 0 10px',
    },
    pageWrapper: {
      padding: '10px 0 0',
      width: '100%',
      height: 'fit-content',
    },
  },
})

export default style
