import {
  concat,
  forIn,
  isNull,
  lowerCase,
  map,
  every,
  reduce,
  trim,
} from 'lodash'
import { GROUP_LABELS, LOCATION_LABELS } from './users-constants'
import { USER_ROLES } from '../../../services/constants'
import { filterLocationsWithAllowedProps } from '../../../services/root/root-service'

const filterUsers = (users, filterValue) => {
  const searchedFields = [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'phoneExtension',
    'firstName lastName',
  ]

  const newUsersList = users.filter((item) => {
    return searchedFields.some((key) => {
      const keys = key.split(' ')
      const itemValueByKey = reduce(
        keys,
        (result, keyPart) => result + item[keyPart] + ' ',
        ''
      ).trim()

      return lowerCase(itemValueByKey).indexOf(lowerCase(filterValue)) !== -1
    })
  })

  return newUsersList
}

const prepareUserRoles = (roles) => {
  map(roles, (item) => {
    switch (item.id) {
      case USER_ROLES.globalAdminRole:
        item.description = 'R-Zero Full Access'
        break
      case USER_ROLES.accountOwnerRole:
        item.description = 'Full Account Access'
        break
      case USER_ROLES.adminUserRole:
        item.description = 'View & Manage'
        break
      case USER_ROLES.operatorUserRole:
        item.description = 'View Only'
        break
      default:
        return
    }
  })

  return roles
}

const createLabel = (nodes, preselected) => {
  const names = []
  const isAllTreeSelected =
    every(nodes, ['checked', true]) && (!preselected || !preselected.length)

  if (isNull(preselected) || isAllTreeSelected) {
    return LOCATION_LABELS.all
  }

  if (preselected && preselected.length) {
    map(nodes, (item) => {
      if (preselected.indexOf(item.id) !== -1) {
        names.push(item.name)
      }
    })
  } else {
    map(nodes, (item) => {
      if (item.checked) {
        names.push(item.name)
      }
    })
  }

  return names.join(' , ')
}

const prepareLocationsParams = (nodes) => {
  const nodeList = concat([], nodes)
  const selectedBuildings = []
  const isAllSelected = every(nodeList, (item) => {
    return item.checked
  })

  map(nodeList, (item) => {
    if (item.checked) {
      selectedBuildings.push(item.id)
    }
  })

  return isAllSelected ? null : selectedBuildings
}

const prepareRoles = (roles, allRoles) => {
  const selectOptions = []

  map(allRoles, (item) => {
    if (roles.indexOf(item.id) !== -1) {
      selectOptions.push(item)
    }
  })

  return selectOptions
}

/*
 * (nodesCount === userState.allowedProperties.length) - check that allTreeSelected according state of user
 * */
const createErrorsObj = (userObject, nodesCount) => {
  const requiredFields = ['firstName', 'lastName', 'email', 'role']
  const errorsObj = {}
  const isAllowedProperties =
    userObject.allowedProperties && userObject.allowedProperties.length
  const isNotAllSelected =
    !isNull(userObject.allowedProperties) &&
    !(nodesCount === userObject.allowedProperties.length)

  if (isNotAllSelected && !isAllowedProperties) {
    errorsObj.allowedProperties = true
  }

  forIn(userObject, (value, key) => {
    if (requiredFields.indexOf(key) !== -1 && !value) {
      errorsObj[key] = true
    }
  })

  return errorsObj
}

/*
 * (nodesCount === userState.allowedProperties.length) - check that allTreeSelected according state of user
 * */
const prepareBodyForRequest = (userState, orgId, nodesCount) => {
  let properties = null // select all by default
  const isAdmin = userState.role === USER_ROLES.adminUserRole
  const isAllowedProperties =
    userState.allowedProperties && userState.allowedProperties.length

  // check that we have access to setup properties and that it is not All Selected
  if (
    !isAdmin &&
    !(isAllowedProperties && nodesCount === userState.allowedProperties.length)
  ) {
    properties = isAllowedProperties
      ? userState.allowedProperties
      : isNull(userState.allowedProperties)
      ? null
      : []
  }

  const userObject = {
    firstName: userState.firstName,
    lastName: userState.lastName,
    email: userState.email,
    phoneNumber: userState.phoneNumber,
    phoneExtension: userState.phoneExtension,
    businessTitle: trim(userState.businessTitle),
    organizationId: orgId,
    allowedProperties: properties,
  }

  userObject.role =
    userState.role === USER_ROLES.adminUserRole && userState.billingFlag
      ? USER_ROLES.adminBillingUserRole
      : userState.role

  return userObject
}

const groupUsers = (users) => {
  const groupNames = {
    [USER_ROLES.adminUserRole]: GROUP_LABELS.ADMIN,
    [USER_ROLES.operatorUserRole]: GROUP_LABELS.OPERATORS,
    [USER_ROLES.accountOwnerRole]: GROUP_LABELS.ACCOUNTS,
  }

  const usersGroups = {
    [GROUP_LABELS.ACCOUNTS]: [],
    [GROUP_LABELS.ADMIN]: [],
    [GROUP_LABELS.OPERATORS]: [],
  }

  if (users.length) {
    map(users, (item) => {
      let groupName = groupNames[item.roleId]

      if (usersGroups[groupName] !== undefined) {
        usersGroups[groupName].push(item)
      }
    })
  }

  forIn(usersGroups, (item, key) => {
    if (!item.length) {
      delete usersGroups[key]
    }
  })

  return usersGroups
}

const prepareLocations = (data, allowedProperties) => {
  const locations = filterLocationsWithAllowedProps(data, allowedProperties)
  locations.isServerPref = true

  map(locations.children, (item) => {
    item.children = []
  })

  return locations
}

const initialState = {
  billingFlag: false,
  role: '',
  allowedProperties: null,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneExtension: '',
  organization: '',
  businessTitle: '',
}

const getPropertiesLabel = (names) => {
  if (isNull(names)) {
    return LOCATION_LABELS.allPropertiesLabels
  }

  return names && names.join(' , ')
}

const getEmailPrefInfo = (orgInfo) => {
  const {
    dailyReport = false,
    weeklyReport = false,
    monthlyReport = false,
  } = orgInfo
  const outAll = every(
    [dailyReport, weeklyReport, monthlyReport],
    (item) => !item
  )

  return {
    dailyReport,
    weeklyReport,
    monthlyReport,
    outAll,
  }
}

export {
  filterUsers,
  prepareUserRoles,
  prepareLocationsParams,
  createLabel,
  prepareRoles,
  createErrorsObj,
  initialState,
  prepareBodyForRequest,
  groupUsers,
  prepareLocations,
  getPropertiesLabel,
  getEmailPrefInfo,
}
