import React, { useState } from 'react'
import { connect } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import { TREE_LEVELS } from '../../../../../services/constants'
import {
  checkConnection,
  filterLocationsWithAllowedProps,
} from '../../../../../services/root/root-service'
import { createDeviceLocationLabelForRooms } from '../../../Details/UVGI/services/uvgiDeviceDetails-service'
import { LOCATION_LABELS } from '../../services/devices-constants'
import { prepareLocationFiltersForDevice } from '../../services/devices-service'
import AssignRoomModal from './AssignRoomModal'
import styles from './style'
import classNames from 'classnames'

const UVGILocationButton = (props) => {
  const {
    roomsFilter,
    setupRoomsFilter,
    nodes,
    disabled = false,
    loadNodesSucceeded,
    userId,
    treeCopy,
    startPolling,
    stopPolling,
    classes,
  } = props
  const [open, setOpen] = useState(false)
  const [isUVGILocationError, setUVGILocationError] = useState(false)

  const assignedLocations = createDeviceLocationLabelForRooms(
    roomsFilter,
    nodes,
    false
  )

  const handleClickOpen = () => {
    setOpen(true)

    stopPolling()
  }

  const handleClose = () => {
    setOpen(false)

    startPolling()
  }

  const resetChanges = () => {
    loadNodesSucceeded(
      userId,
      filterLocationsWithAllowedProps(treeCopy, roomsFilter.selectedBuildings),
      TREE_LEVELS.ROOM
    )

    handleClose()
  }

  const onApply = () => {
    const params = prepareLocationFiltersForDevice(nodes)
    setupRoomsFilter(params)
    setUVGILocationError && setUVGILocationError(!params.selectedName)

    handleClose()
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        className={
          isUVGILocationError
            ? classNames(classes.locationField, classes.locationError)
            : classes.locationField
        }
        label={assignedLocations}
        variant="outlined"
        disabled={disabled}
      />
      <AssignRoomModal
        onApply={onApply}
        actionLabel={LOCATION_LABELS.action}
        modalTitle={LOCATION_LABELS.roomModalTitle}
        resetChanges={resetChanges}
        isOpen={open}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { customTreeReducer, rootReducer } = state

  return {
    userId: rootReducer.userId,
    treeCopy: customTreeReducer.treeCopy,
    nodes: customTreeReducer.nodes,
    isAllSelected: customTreeReducer.isAllSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadNodesSucceeded: (id, nodes, finalNode) =>
      dispatch(
        checkConnection(() =>
          customTreeActions.loadNodesSucceeded(id, nodes, finalNode)
        )
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UVGILocationButton))
