import React, { useState } from 'react'
import { connect } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'
import treeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import styles from './style'
import classNames from 'classnames'
import {
  checkConnection,
  filterLocationsWithAllowedProps,
} from '../../../../../services/root/root-service'
import AssignLocationModal from '../../../Details/ARC/components/locationComponent/AssignLocationModal'
import {
  createDeviceLocationLabel,
  getAssignedLocationsLabel,
  prepareLocationFiltersForDevice,
} from '../../services/devices-service'
import { LOCATION_LABELS } from '../../services/devices-constants'

const ARCLocationButton = (props) => {
  const {
    classes,
    locationFilter,
    setupLocationsFilter,
    nodes,
    disabled = false,
    isAllSelected,
    error,
    loadNodesSucceeded,
    userId,
    treeCopy,
  } = props
  const [open, setOpen] = useState(false)

  const assignedLocations = createDeviceLocationLabel(
    locationFilter,
    nodes,
    isAllSelected
  )

  const assignedLocationsLabel = getAssignedLocationsLabel(
    assignedLocations,
    isAllSelected
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const resetChanges = () => {
    loadNodesSucceeded(
      userId,
      filterLocationsWithAllowedProps(treeCopy, locationFilter)
    )

    handleClose()
  }

  const onApply = () => {
    const params = prepareLocationFiltersForDevice(nodes)
    setupLocationsFilter(params)

    handleClose()
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        className={
          error
            ? classNames(classes.locationField, classes.locationError)
            : classes.locationField
        }
        label={assignedLocationsLabel}
        variant="outlined"
        disabled={disabled}
      />
      <AssignLocationModal
        onApply={onApply}
        actionLabel={LOCATION_LABELS.action}
        modalTitle={LOCATION_LABELS.modalTitle}
        resetChanges={resetChanges}
        isOpen={open}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { customTreeReducer, rootReducer } = state

  return {
    userId: rootReducer.userId,
    treeCopy: customTreeReducer.treeCopy,
    nodes: customTreeReducer.nodes,
    isAllSelected: customTreeReducer.isAllSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadNodesSucceeded: (id, nodes) =>
      dispatch(
        checkConnection(() => treeActions.loadNodesSucceeded(id, nodes))
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ARCLocationButton))
