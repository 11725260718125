import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../../services/constants'

const useStyles = makeStyles({
  groupActivitiesDivider: {
    display: 'flex',
    height: '35px',
    marginTop: '20px',
    marginBottom: (props) => props.margin,
  },
  lineDivider: {
    display: 'flex',
    flexGrow: '1',
    marginTop: '10px',
  },
  groupLabel: {
    margin: '0 10px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  subLabel: {
    color: COLORS.GRAY,
    fontSize: '10px',
    lineHeight: '12px',
  },
  mobileGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '@media (min-width: 1224px)': {
    groupLabel: {
      fontSize: '16px',
      color: COLORS.CONTRAST_GRAY,
      lineHeight: '19px',
      width: 'auto',
    },
    subLabel: {
      color: COLORS.GRAY,
      fontSize: '12px',
      lineHeight: '19px',
    },
    groupActivitiesDivider: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      height: '20px',
    },
  },
})

export default useStyles
