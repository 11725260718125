import { isNull } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'
import treeActions from '../../../../components/_shared/customTree/services/customTree-actions'
import { deeps } from '../../../../services/constants'
import {
  checkConnection,
  filterLocationsWithAllowedProps,
} from '../../../../services/root/root-service'
import {
  prepareLocationsParams,
  createLabel,
} from '../../services/users-service'
import styles from './../../style'
import { LOCATION_LABELS } from '../../services/users-constants'
import AddProperty from './AddProperty'
import AssignLocationModal from '../../../Devices/Details/ARC/components/locationComponent/AssignLocationModal'

const LocationButton = withStyles(styles)((props) => {
  const {
    classes,
    nodes,
    changeLocations,
    isEdit,
    allowedProperties,
    required,
    error,
    isAllSelected,
    loadNodesSucceeded,
    userId,
    treeCopy,
    isBlack = false,
  } = props
  const [open, setOpen] = useState(false)
  const [isEditEmpty, setFlag] = useState(true)
  const defaultLabel = required
    ? `${LOCATION_LABELS.fieldLabel} *`
    : LOCATION_LABELS.fieldLabel
  const [label, setLabel] = useState(defaultLabel)

  const chipStyle = error
    ? classNames(classes.locationField, classes.locationError)
    : classes.locationField
  const chipColor = isBlack ? classes.blackColor : ''
  const chip = classNames(chipStyle, chipColor)

  useEffect(() => {
    const isAnyLocationsSelected =
      (allowedProperties && allowedProperties.length) ||
      isNull(allowedProperties)

    if (
      isEditEmpty &&
      isEdit &&
      nodes &&
      nodes.length &&
      isAnyLocationsSelected
    ) {
      setFlag(false)

      setLabel(createLabel(nodes, allowedProperties))
    }
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const resetChanges = () => {
    loadNodesSucceeded(
      userId,
      filterLocationsWithAllowedProps(treeCopy, allowedProperties),
      deeps
    )

    handleClose()
  }

  const onApply = () => {
    const params = prepareLocationsParams(nodes)
    const newLabel = createLabel(nodes)

    changeLocations(params)
    setLabel(newLabel || defaultLabel)
    handleClose()
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        className={chip}
        label={isAllSelected ? LOCATION_LABELS.all : label}
        variant="outlined"
      />
      <AssignLocationModal
        onApply={onApply}
        actionLabel={LOCATION_LABELS.action}
        modalTitle={LOCATION_LABELS.modalTitle}
        onClose={handleClose}
        resetChanges={resetChanges}
        isOpen={open}
      />
      <AddProperty allowedProperties={allowedProperties} />
    </>
  )
})

const mapStateToProps = (state) => ({
  userId: state.rootReducer.userId,
  treeCopy: state.customTreeReducer.treeCopy,
  nodes: state.customTreeReducer.nodes,
  isAllSelected: state.customTreeReducer.isAllSelected,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadNodesSucceeded: (id, nodes, deeps) =>
      dispatch(
        checkConnection(() => treeActions.loadNodesSucceeded(id, nodes, deeps))
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationButton)
