import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  withStyles,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import style from './style'
import { ASSIGN_CYCLE_TIME_MODAL } from '../../service/location-constants'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'

const AssignCycleTimeModal = (props) => {
  const {
    isOpen,
    onSubmit,
    onCloseModal,
    error,
    setError,
    classes,
    isMobile = true,
    location,
    isGlobalCycleTimeModal,
  } = props
  const { timeout } = location
  const [inputError, setInputError] = useState(false)
  const [minutes, setMinutes] = useState(timeout)

  useEffect(() => {
    if (timeout !== minutes) {
      setMinutes(timeout)
    }

    setInputError(false)
  }, [isOpen])

  const onChange = (event) => {
    const value = event.target.value

    if (
      (/^\d+$/.test(value) || value === '') &&
      +value <= ASSIGN_CYCLE_TIME_MODAL.maxValue
    ) {
      setMinutes(+value)
    }
  }

  const onClickPlus = () => {
    if (minutes < ASSIGN_CYCLE_TIME_MODAL.maxValue) {
      setMinutes(minutes + 1)
    }
  }

  const onClickMinus = () => {
    if (minutes > ASSIGN_CYCLE_TIME_MODAL.minValue) {
      setMinutes(minutes - 1)
    }
  }

  const onSubmitButton = (event) => {
    event.preventDefault()

    if (minutes < 3) {
      setError(ASSIGN_CYCLE_TIME_MODAL.validationMessage)
    } else {
      onSubmit({
        ...location,
        timeout: minutes,
      })
    }
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle className={classes.modalTitleContainer} disableTypography>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            className={classes.modalTitle}
          >
            {ASSIGN_CYCLE_TIME_MODAL.title}
          </Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <CloseButton onCloseModal={onCloseModal} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {isGlobalCycleTimeModal && (
            <>
              <Typography variant="subtitle1" color="textSecondary">
                {ASSIGN_CYCLE_TIME_MODAL.textForDefaultGlobal}
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.secondParagraph}
              >
                {ASSIGN_CYCLE_TIME_MODAL.textForDefaultGlobal2}
              </Typography>
            </>
          )}
          <form onSubmit={onSubmitButton} noValidate>
            <Box display="flex" alignItems="center" justifyContent="center">
              <IconButton className={classes.iconButton} onClick={onClickMinus}>
                <RemoveIcon />
              </IconButton>
              <TextField
                variant="outlined"
                className={classes.input}
                value={minutes}
                onChange={onChange}
                error={inputError}
                fullWidth
                autoFocus={!isMobile}
              />
              <IconButton className={classes.iconButton} onClick={onClickPlus}>
                <AddIcon />
              </IconButton>
            </Box>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              className={classes.subtitle}
            >
              {ASSIGN_CYCLE_TIME_MODAL.subtitle}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              type="submit"
            >
              {ASSIGN_CYCLE_TIME_MODAL.actionLabel}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default withStyles(style)(AssignCycleTimeModal)
