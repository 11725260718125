import { TREE_LEVELS } from '../../../../services/constants'

const SELECT_ALL_LABEL = 'Select All'

const initialState = {
  nodes: [],
  isAllSelected: false,
  treeCopy: {},
  userPref: [],
  userPrefCurrent: [],
  isIncludeUnknown: false,
  finalNode: TREE_LEVELS.PLACEMENT,
}

export { SELECT_ALL_LABEL, initialState }
