import { cloneDeep } from 'lodash'
import {
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { HttpClient } from '../../../../services/HttpClient'
import customTreeActions from '../../../../components/_shared/customTree/services/customTree-actions'
import {
  PREF,
  TREE_LEVELS,
  TREE_STATE_ALL,
} from '../../../../services/constants'
import { prepareBodyForSummary } from './summary-service'
import summaryActions from './summary-actions'
import { SUMMARY_PATH_REGEXP } from './summary-constants'

const getRangeType = (state) =>
  state.summaryReducer.sharedSummaryReducer.rangeType
const getLocationIds = (state) => state.summaryReducer.sharedSummaryReducer.ids
const getDateFilter = (state) =>
  state.summaryReducer.sharedSummaryReducer.dateFilter
const getLocationFilter = (state) =>
  state.summaryReducer.sharedSummaryReducer.locationFilter
const getDefaultPeriod = (state) =>
  state.summaryReducer.sharedSummaryReducer.period

const getCurrentUserId = (state) => state.rootReducer.userId
const getTimezone = (state) => state.rootReducer.timezone

const getAllSelectedFlag = (state) => state.customTreeReducer.isAllSelected
const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* getCyclesForTable(action) {
  try {
    const { tableType, deviceSubType } = action.payload

    const isAllSelected = yield select(getAllSelectedFlag)

    const rangeType = yield select(getRangeType)
    const locationIds = isAllSelected ? null : yield select(getLocationIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getDefaultPeriod)
    const timezone = yield select(getTimezone)

    const body = prepareBodyForSummary(
      locationIds,
      dateFilter,
      defaultPeriod,
      timezone,
      deviceSubType
    )

    body.range = rangeType

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/report/${tableType}-activities`, {
        body,
      })
    })

    if (data.message) {
      yield put(summaryActions.getCyclesForTableFailed(data.message))
    } else {
      yield put(summaryActions.getCyclesForTableSuccess(data))
    }
  } catch (e) {
    yield put(summaryActions.getCyclesForTableFailed(e.message))
  }
}

function* loadLocations() {
  try {
    const userId = yield select(getCurrentUserId)
    const tree = yield select(getInitialNodesTree)

    yield put(summaryActions.prepareLocationFiler(TREE_STATE_ALL, tree, userId))
  } catch (e) {
    yield put(summaryActions.loadLocationTreeFailed(e.message))
  }
}

function* setupLoadedLocations(action) {
  try {
    const locationFilter = select(getLocationFilter)
    const { tree, userId } = action.payload

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        {
          ...tree,
          [PREF.SERVER_PREF]: locationFilter,
          isServerPref: true,
        },
        TREE_LEVELS.PLACEMENT
      )
    )
  } catch (e) {
    yield put(summaryActions.applyLocationFilterFailed(e.message))
  }
}

function* updateLocationTree() {
  const isTriggeredFromSummary = SUMMARY_PATH_REGEXP.test(window.location.href)

  if (isTriggeredFromSummary) {
    yield put(summaryActions.loadLocations())
  }
}

function* loadLocationsTreeSaga() {
  yield takeLatest(summaryActions.actionsTypes.LOAD_LOCATIONS, loadLocations)
}

function* updateLocationTreeSaga() {
  yield takeLatest(
    customTreeActions.actionsTypes.LOAD_INITIAL_LOCATION_TREE_SUCCESS,
    updateLocationTree
  )
}

function* locationFilterIsReadySaga() {
  yield takeEvery(
    summaryActions.actionsTypes.PREPARE_LOCATION_FILTER,
    setupLoadedLocations
  )
}

function* getCyclesForTableSaga() {
  yield takeLatest(
    summaryActions.actionsTypes.GET_CYCLES_FOR_TABLE,
    getCyclesForTable
  )
}

export default function* summarySagas() {
  yield fork(loadLocationsTreeSaga)
  yield fork(locationFilterIsReadySaga)
  yield fork(getCyclesForTableSaga)
  yield fork(updateLocationTreeSaga)
}
