import { forIn, compact } from 'lodash'
import moment from 'moment'
import { yyyyMMDDTHHmmss } from './dateFormat-service'

const filterQuery = {}

const _paramsTemplate = (key, filterType, value) =>
  `${key} ${filterType} '${value}'`
const _arrayParamsTemplate = (key, filterType, value) =>
  `${key} ${filterType} ${value}`

const _wrapInParentheses = (text) => {
  return '(' + text + ')'
}

export const _wrapInQuotes = (text) => {
  return "'" + text + "'"
}

const _wrapInSquareBrackets = (text) => {
  return encodeURIComponent('[') + text + encodeURIComponent(']')
}

const _wrapInSquareBracketsForBodyOfRequest = (text) => {
  return `[${text}]`
}

// type = 'start' || 'end' (start or end of date)
export const _formatDate = (date, type, timezone, format = yyyyMMDDTHHmmss) => {
  let processedDate
  const parsedDate = moment(date, format)

  if (timezone) {
    if (type === 'start') {
      processedDate = parsedDate.startOf('day').format(format)
    } else {
      processedDate = parsedDate.endOf('day').format(format)
    }
  } else {
    if (type === 'start') {
      processedDate = parsedDate.startOf('day').utc().format(format)
    } else {
      processedDate = parsedDate.endOf('day').utc().format(format)
    }
  }

  return processedDate
}

filterQuery.getDatePeriodString = (
  start,
  end,
  template,
  key,
  filterType,
  timezone
) => {
  const startDate = _wrapInQuotes(_formatDate(start, 'start', timezone))
  const endDate = _wrapInQuotes(_formatDate(end, 'end', timezone))
  const periodString = startDate + ',' + endDate

  if (template) {
    return template(key, filterType, _wrapInSquareBrackets(periodString))
  }

  return _wrapInSquareBrackets(periodString)
}

filterQuery.getArrayString = (
  array,
  template,
  key,
  filterType,
  queryInBody = false
) => {
  const arrayString = array.join(',')
  const wrapInBrackets = queryInBody
    ? _wrapInSquareBracketsForBodyOfRequest
    : _wrapInSquareBrackets

  if (template) {
    return template(key, filterType, wrapInBrackets(arrayString))
  }

  return wrapInBrackets(arrayString)
}

filterQuery.getArrayStringInQuotes = (
  array,
  template,
  key,
  filterType,
  queryInBody = false
) => {
  const preparedArray = []

  forIn(array, function (item) {
    if (item) {
      preparedArray.push("'" + item + "'")
    }
  })

  return filterQuery.getArrayString(
    preparedArray,
    template,
    key,
    filterType,
    queryInBody
  )
}

filterQuery.getStringFromObj = (
  obj,
  template,
  filterType,
  condition,
  otherTemplate
) => {
  const filters = []

  forIn(obj, (value, key) => {
    if (value && value.length) {
      filters.push(
        template(key, filterType, _wrapInSquareBrackets(value.join(',')))
      )
    }
  })

  if (otherTemplate) {
    filters.push(otherTemplate)
  }

  return _wrapInParentheses(filters.join(' ' + condition + ' '))
}

filterQuery.getQueryString = (value, template, key, filterType) => {
  if (template) {
    return template(key, filterType, value)
  }

  return _wrapInParentheses(value)
}

filterQuery.createQueryStringFromArray = (filtersArray, filterCondition) => {
  filtersArray = compact(filtersArray) || []

  const queryString = filtersArray.join(' ' + filterCondition + ' ')

  return _wrapInParentheses(queryString)
}

filterQuery.createQueryString = (mainFilter, otherFilters, filterCondition) => {
  mainFilter = mainFilter || []
  otherFilters = otherFilters || []
  filterCondition = filterCondition || 'or'

  let queryString

  if (mainFilter && otherFilters) {
    queryString = _wrapInParentheses(
      mainFilter + ' ' + filterCondition + ' ' + otherFilters
    )
  } else {
    queryString = mainFilter || otherFilters
  }

  return queryString
}

filterQuery.templates = {
  default: _paramsTemplate,
  array: _arrayParamsTemplate,
}

export default filterQuery
