import filterQuery from '../../../services/filterQuery-service'
import locationsActions from './location-actions'
import { put, select, takeLatest, call, fork } from 'redux-saga/effects'
import { isNull } from 'lodash'
import { HttpClient } from '../../../services/HttpClient'
import {
  getCurrentLocation,
  wrapInSquareBrackets,
  prepareUsers,
  prepareDevices,
  prepareLocationsForValidation,
  getNodeTypeById,
} from './location-service'
import { TREE_LEVELS } from '../../../services/constants'

export const getCurrentLoc = (state) => state.locationReducer.currentLoc
export const getPathName = (state) => state.locationReducer.pathname
export const getAssignedDevicesState = (state) =>
  state.locationReducer.assignedDevices
export const getTree = (state) => state.locationReducer.tree

function* loadLocations(action) {
  try {
    const { pathname, onSuccess, onError } = action.payload

    yield call(() => {
      return HttpClient.get('/api/secured/locations/', {
        onSuccess,
        onError,
        isObue: true,
      })
    })

    if (pathname) {
      yield put(locationsActions.setCurrentLocation(pathname))
    }
  } catch (e) {
    yield put(locationsActions.loadLocationsFailed(e.message))
  }
}

function* loadCurrentLocationSchema() {
  try {
    const data = yield call(() => {
      return HttpClient.get(
        '/api/secured/location-schema-definition/current-schema'
      )
    })

    if (data.message) {
      yield put(locationsActions.getCurrentLocationSchemaFailed(data.message))
    } else {
      yield put(locationsActions.getCurrentLocationSchemaSucceeded(data))
    }
  } catch (e) {
    yield put(locationsActions.getCurrentLocationSchemaFailed(e.message))
  }
}

function* addNewLocation(action) {
  try {
    let url = '/api/secured/locations/'
    const { body, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.post(url, {
        body,
      })
    })

    if (data.message) {
      yield put(locationsActions.updateLocationFailed(data.message))
    } else {
      yield put(locationsActions.addNewLocationSuccess(data))

      if (onSuccess) {
        onSuccess()
      }
    }
  } catch (e) {
    yield put(locationsActions.updateLocationFailed(e.message))
  }
}

function* addNewChildLocation(action) {
  try {
    const pathname = yield select(getPathName)
    const tree = yield select(getTree)
    const [deep] = getCurrentLocation(pathname)
    const { body, history } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/secured/locations/', {
        body,
      })
    })

    if (data.message) {
      yield put(locationsActions.updateLocationFailed(data.message))
    } else {
      const parentType = getNodeTypeById(tree, data.parentId)
      const url =
        deep === 'room'
          ? pathname
          : `${pathname}/${parentType}-${data.parentId}`

      yield put(locationsActions.addNewChildLocationSuccess(data, url))

      history.push(url)
    }
  } catch (e) {
    yield put(locationsActions.updateLocationFailed(e.message))
  }
}

function* deleteLocation(action) {
  try {
    let pathname = yield select(getPathName)
    let [, parentId] = getCurrentLocation(pathname)
    const { id } = action.payload

    let url = '/api/secured/locations/' + id

    const data = yield call(() => {
      return HttpClient.delete(url)
    })

    if (data.message) {
      yield put(locationsActions.updateLocationFailed(data.message))
    } else {
      yield put(locationsActions.deleteLocationSuccess(id, parentId))
    }
  } catch (e) {
    yield put(locationsActions.updateLocationFailed(e.message))
  }
}

function* deleteLocationWithSubLocations(action) {
  try {
    const { id, parentId, count, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(
        `/api/secured/locations/${id}/sub-locations/${count}`
      )
    })

    if (data.message) {
      yield put(
        locationsActions.deleteLocationWithSubLocationsFailed(data.message)
      )
    } else {
      yield put(
        locationsActions.deleteLocationWithSubLocationsSuccess(id, parentId)
      )

      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(locationsActions.deleteLocationWithSubLocationsFailed(e.message))
  }
}

function* editLocation(action) {
  try {
    const { updatedLoc, onSuccess } = action.payload
    let url = '/api/secured/locations/' + updatedLoc.id

    const data = yield call(() => {
      return HttpClient.put(url, {
        body: updatedLoc,
      })
    })

    if (data.message) {
      yield put(locationsActions.updateLocationFailed(data.message))
    } else {
      yield put(locationsActions.editLocationSucceeded(data))

      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(locationsActions.updateLocationFailed(e.message))
  }
}

function* getSubLocationsCount(action) {
  try {
    const { id } = action.payload

    if (id) {
      const data = yield call(() => {
        return HttpClient.get(`/api/secured/locations/${id}/sub-locations`)
      })

      if (data.message) {
        yield put(locationsActions.getSubLocationsCountFailed(data.message))
      } else {
        yield put(
          locationsActions.getSubLocationsCountSucceeded(data.subLocationsCount)
        )
      }
    }
  } catch (e) {
    yield put(locationsActions.getSubLocationsCountFailed(e.message))
  }
}

function* getAssignedDevices(action) {
  try {
    const { ids, location } = action.payload

    if (ids) {
      const data = yield call(() => {
        return HttpClient.post(`/api/secured/devices/`, {
          body: {
            query: `allowedLocationIds unnestIn ${wrapInSquareBrackets(ids)}`,
          },
        })
      })

      if (data.message) {
        yield put(locationsActions.getAssignedDevicesFailed(data.message))
      } else {
        const devices = prepareDevices(data)

        yield put(locationsActions.getAssignedDevicesSucceeded(devices))

        if (location.locationType === TREE_LEVELS.PROPERTY) {
          yield put(locationsActions.getAssignedUsers(location.id))
        } else {
          if (!devices.length) {
            yield put(locationsActions.getSubLocationsCount(location.id))
          }
        }
      }
    }
  } catch (e) {
    yield put(locationsActions.getAssignedDevicesFailed(e.message))
  }
}

function* getAssignedUsers(action) {
  try {
    const { id } = action.payload
    const devices = yield select(getAssignedDevicesState)

    if (id) {
      const data = yield call(() => {
        return HttpClient.post(`/api/secured/user-profiles/scroll/`, {
          body: {
            query: `allowedProperties unnestIn ${wrapInSquareBrackets(id)}`,
          },
        })
      })

      if (data.message) {
        yield put(locationsActions.getAssignedUsersFailed(data.message))
      } else {
        const users = prepareUsers(data, id)

        yield put(locationsActions.getAssignedUsersSucceeded(users))

        if (!users.length && !devices.length) {
          yield put(locationsActions.getSubLocationsCount(id))
        }
      }
    }
  } catch (e) {
    yield put(locationsActions.getAssignedUsersFailed(e.message))
  }
}

function* updateTimeoutForLocation(action) {
  try {
    const { updatedLoc } = action.payload
    const { id, timeout, disinfectSpaceDaily } = updatedLoc

    if (id) {
      const data = yield call(() => {
        return HttpClient.put(
          `/api/secured/locations/${id}/cycle/?timeout=${timeout}&disinfectSpaceDaily=${!!disinfectSpaceDaily}`
        )
      })

      if (data.message) {
        yield put(locationsActions.updateTimeoutForLocationFailed(data.message))
      } else {
        yield put(
          locationsActions.updateTimeoutForLocationSucceeded(updatedLoc)
        )
      }
    }
  } catch (e) {
    yield put(locationsActions.getAssignedUsersFailed(e.message))
  }
}

function* bulkUploadLocations(action) {
  try {
    const { locations, onSuccess, history } = action.payload
    const tree = yield select(getTree)
    let pathname = yield select(getPathName)
    const [deep, parentId] = getCurrentLocation(pathname)

    const data = yield call(() => {
      return HttpClient.post('/api/secured/locations/bulk', {
        body: locations,
      })
    })

    if (data.message) {
      yield put(locationsActions.bulkUploadLocationsFailed(data.message))
    } else {
      yield put(locationsActions.bulkUploadLocationsSucceeded(data))
      onSuccess()

      const parentType = getNodeTypeById(tree, data[0].parentId)

      if (locations[0].parentId !== parentId && deep !== 'room' && history) {
        history.push(`${pathname}/${parentType}-${locations[0].parentId}`)
      }
    }
  } catch (e) {
    yield put(locationsActions.bulkUploadLocationsFailed(e.message))
  }
}

function* validateLocations(action) {
  try {
    const { isBulk, onSuccess, onError, body } = action.payload
    const { value, parentId } = body

    const locations = isBulk ? prepareLocationsForValidation(value) : value
    const idQuery = isNull(parentId)
      ? filterQuery.getQueryString(
          'null',
          filterQuery.templates.default,
          'parentId',
          'isNull'
        )
      : filterQuery.getQueryString(
          parentId,
          filterQuery.templates.array,
          'parentId',
          'eq'
        )

    const query = [
      idQuery,
      filterQuery.getQueryString(
        locations,
        filterQuery.templates.default,
        'bulkSearchByNameIgnoreCase',
        'custom'
      ),
    ]

    const queryArray = filterQuery.createQueryStringFromArray(query, 'and')

    const data = yield call(() => {
      return HttpClient.get('/api/secured/locations/scroll?query=' + queryArray)
    })

    if (data.message) {
      yield put(locationsActions.validateLocationsFailed(data.message))
    } else {
      yield put(locationsActions.validateLocationsSucceeded(data))

      if (data.length) {
        onError(data)
      } else {
        onSuccess(body, isBulk)
      }
    }
  } catch (e) {
    yield put(locationsActions.validateLocationsFailed(e.message))
  }
}

function* addLocationSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.ADD_NEW_LOCATION,
    addNewLocation
  )
}

function* addChildLocationSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.ADD_NEW_CHILD_LOCATION,
    addNewChildLocation
  )
}

function* loadLocationSaga() {
  yield takeLatest(locationsActions.actionsTypes.LOAD_LOCATIONS, loadLocations)
}

function* deleteLocationSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.DELETE_LOCATION,
    deleteLocation
  )
}

function* deleteLocationWithSubLocationsSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.DELETE_LOCATION_WITH_SUBLOCATIONS,
    deleteLocationWithSubLocations
  )
}

function* editLocationSaga() {
  yield takeLatest(locationsActions.actionsTypes.EDIT_LOCATION, editLocation)
}

function* getSubLocationsCountSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.GET_SUBLOCATIONS_COUNT,
    getSubLocationsCount
  )
}

function* getAssignedDevicesSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.GET_ASSIGNED_DEVICES,
    getAssignedDevices
  )
}

function* getAssignedUsersSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.GET_ASSIGNED_USERS,
    getAssignedUsers
  )
}

function* updateTimeoutForLocationSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.UPDATE_TIMEOUT_FOR_LOCATION,
    updateTimeoutForLocation
  )
}

function* bulkUploadLocationsSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.BULK_UPLOAD_LOCATIONS,
    bulkUploadLocations
  )
}

function* validateLocationsSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.VALIDATE_LOCATIONS,
    validateLocations
  )
}

function* loadCurrentLocationSchemaSaga() {
  yield takeLatest(
    locationsActions.actionsTypes.GET_CURRENT_LOCATIONS_SCHEMA,
    loadCurrentLocationSchema
  )
}

export default function* locationSagas() {
  yield fork(loadLocationSaga)
  yield fork(addLocationSaga)
  yield fork(addChildLocationSaga)
  yield fork(deleteLocationSaga)
  yield fork(deleteLocationWithSubLocationsSaga)
  yield fork(editLocationSaga)
  yield fork(getSubLocationsCountSaga)
  yield fork(getAssignedDevicesSaga)
  yield fork(getAssignedUsersSaga)
  yield fork(updateTimeoutForLocationSaga)
  yield fork(bulkUploadLocationsSaga)
  yield fork(validateLocationsSaga)
  yield fork(loadCurrentLocationSchemaSaga)
}
