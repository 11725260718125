import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  convertTodayToMomentWithTimezone,
  formattedWithTimezoneString,
  yyyyMMDFormat,
} from '../../../services/dateFormat-service'
import styles from './style'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import SvgIconComponent from '../SvgIconComponent'
import { MONTH } from '../../../services/constants'

const CustomCalendar = withStyles(styles)((props) => {
  const { classes, startDate, endDate, onChange, timezone } = props

  const getMonthString = (date) => {
    return MONTH[moment(date).get('month')]
  }

  const isBeforeToday = (date) => {
    const now = moment()
    const todayUtc = formattedWithTimezoneString(now, timezone)

    return moment(date).isBefore(todayUtc) || moment(date).isSame(todayUtc)
  }

  const customHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className={classes.calendarHeader}>
      <IconButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <SvgIconComponent name="arrowLeft" size={22} />
      </IconButton>

      <span>{getMonthString(date)}</span>

      <IconButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <SvgIconComponent name="arrowRight" size={22} />
      </IconButton>
    </div>
  )

  const renderDayContents = (day, date) => {
    const isStartDate = moment(startDate).isSame(date, 'day')
    const isEndDate = moment(endDate).isSame(date, 'day')

    if (isStartDate || isEndDate) {
      return <div className={classes.selectedDay}>{day}</div>
    }

    if (
      moment(date).isAfter(startDate) &&
      endDate &&
      moment(endDate).isAfter(date)
    ) {
      return <div className={classes.betweenDay}>{day}</div>
    }

    return <div>{day}</div>
  }

  const addedDayClass = (date) => {
    const today =
      convertTodayToMomentWithTimezone(timezone).format(yyyyMMDFormat)
    const isStartDate = moment(startDate).isSame(date, 'day')
    const isEndDate = moment(endDate).isSame(date, 'day')
    const renderData = moment(date).format(yyyyMMDFormat)

    if (isEndDate) {
      return 'customDay endDay'
    }

    if (today === renderData) {
      return 'customDay todayDay'
    }

    if (isStartDate && endDate) {
      return 'customDay startDay'
    }

    return 'customDay simpleDay'
  }

  return (
    <div>
      <DatePicker
        calendarClassName={classes.datePicker}
        renderCustomHeader={customHeader}
        onChange={onChange}
        selected={new Date(startDate)}
        startDate={startDate}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        filterDate={isBeforeToday}
        showDisabledMonthNavigation
        renderDayContents={renderDayContents}
        endDate={endDate}
        dayClassName={addedDayClass}
        selectsRange
        inline
      />
    </div>
  )
})

export default CustomCalendar
