import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: '100px',
  },
  mainText: {
    margin: '24px 0',
  },
  logoContainer: {
    width: '300px',
    margin: '0 auto',
  },
  iconWrapper: {
    margin: '10% auto 4%',
    textAlign: 'center',
  },
  backToPreviousPageContainer: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  logoImage: {
    width: '100%',
  },
  colorSecondary: {
    color: COLORS.GRAY_BOULDER,
  },
  secondaryText: {
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  descriptionWithLink: {
    fontFamily: 'Cerebri Sans Pro Medium',
    fontWeight: '600',
    textDecoration: 'none',
    color: COLORS.PICTON_BLUE,
    '&:visited': {
      fontFamily: 'Cerebri Sans Pro Medium',
      fontWeight: '600',
      textDecoration: 'none',
      color: COLORS.PICTON_BLUE,
    },
  },
  '@media (max-width: 1224px)': {
    mainContainer: {
      padding: '0',
    },
    logoContainer: {
      width: '220px',
      margin: '30px auto',
    },
    mainText: {
      margin: '12px 0',
    },
  },
  '@media (orientation: landscape) and (max-height: 500px)': {
    logoContainer: {
      width: '120px',
      margin: '20px auto 20px',
    },
    iconWrapper: {
      marginTop: '4%',
    },
    mainText: {
      margin: '12px 0',
    },
  },
})

export default styles
