import { select, takeEvery } from 'redux-saga/effects'
import { gaEvent } from '../../utils/ga'
import { EventPayload, GA_EVENT_SEND } from './ga-actions'

export const getCurrentUserRole = (state: { rootReducer: { role: object } }) =>
  state.rootReducer.role
export const getCurrentOrganizationName = (state: {
  rootReducer: { organization: { name: string } }
}) => state.rootReducer.organization.name

function* gaSend({ payload }: { payload: EventPayload }) {
  const role: string = yield select(getCurrentUserRole)
  const organization: string = yield select(getCurrentOrganizationName)
  const eventParams = { ...payload.eventParams, organization, role }
  gaEvent(payload.eventCategory, eventParams, payload.eventName)
}

export default function* gaSagas() {
  yield takeEvery(GA_EVENT_SEND.getType() as any, gaSend)
}
