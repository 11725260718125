import React, { useState } from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { concat, forEach, map, orderBy } from 'lodash'
import classNames from 'classnames'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core'
import {
  DMMyyyyAndTime,
  DMyyyy,
  utcConverter,
} from '../../../../../services/dateFormat-service'
import { ACTIVE_DEVICES } from '../../services/summary-constants'
import style from './style'
import Typography from '@material-ui/core/Typography'
import { ORDER } from '../../../ARC/service/arcSummary-constants'

const DevicesTable = (props) => {
  const { devices, isToday, timezone, tableColumns, sortByDates, classes } =
    props
  const [order, setOrder] = useState(ORDER.asc)

  const createSortHandler = () => {
    const isAsc = order === ORDER.asc

    setOrder(isAsc ? ORDER.desc : ORDER.asc)
  }

  const getOrderedData = () => {
    if (sortByDates) {
      const values = []
      const nullValues = []

      forEach(devices, (item) => {
        if (item.transactionStartedAt) {
          values.push(item)
        } else {
          nullValues.push(item)
        }
      })

      const orderedArray = orderBy(values, ['transactionStartedAt'], [order])

      return order === ORDER.desc
        ? concat(orderedArray, nullValues)
        : concat(nullValues, orderedArray)
    } else {
      return orderBy(devices, ['count'], [order])
    }
  }

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              className={classNames(classes.headerCell, classes.firstColumn)}
            >
              <Typography variant="body1" color="textSecondary">
                {tableColumns.firstColumn}
              </Typography>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active
                direction={order}
                onClick={createSortHandler}
              >
                <Typography variant="body1" color="textSecondary">
                  {tableColumns.secondColumn}
                </Typography>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices &&
            map(getOrderedData(), (row) => {
              const dateTemplate =
                isToday && row.isActive ? DMyyyy : DMMyyyyAndTime
              const rowDate = utcConverter(
                row.transactionStartedAt,
                dateTemplate,
                timezone
              )
              const color = row.count || row.isActive ? 'textPrimary' : 'error'

              return (
                <TableRow key={row.deviceId}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classNames(
                      classes.deviceCell,
                      classes.firstColumn
                    )}
                  >
                    <Typography variant="h6" color={color}>
                      {`${row.deviceSerialNumber} ${
                        row.deviceNickName ? `- ${row.deviceNickName}` : ''
                      }`}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.deviceCell} align="left">
                    {isToday ? (
                      <>
                        <Typography variant="h6" color={color}>
                          {row.transactionStartedAt
                            ? rowDate
                            : ACTIVE_DEVICES.notRun}
                        </Typography>
                        {row.isActive && (
                          <div className={classes.active}>
                            <FiberManualRecordIcon
                              fontSize="inherit"
                              className={classes.circle}
                            />
                            ACTIVE
                          </div>
                        )}
                      </>
                    ) : (
                      <Typography variant="h6" color={color}>
                        {row.count}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default withStyles(style)(DevicesTable)
