import { withStyles } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {
  ACTIVE_DEVICES_TITLE,
  DEVICES_TABLE,
} from '../service/arcSummary-constants'
import ActiveDevicesContainer from '../../_shared/components/charts/ActiveDevicesContainer'
import DevicesTableContainer from '../../_shared/components/tables/DevicesTableContainer'
import { withMediaQuery } from '../../../../services/root/root-service'
import style from './style'
import LinkToReport from '../../_shared/components/LinkToReport'

const ARCDevicesSummaryInfo = (props) => {
  const {
    activeDevicesCount,
    totalDevicesCount,
    devices,
    deviceType,
    dateFilter,
    period,
    dateFilterLabel,
    locationFilter,
    ids,
    classes,
  } = props

  return (
    <div className={classes.locationsAndDevicesWrapper}>
      <ActiveDevicesContainer
        title={ACTIVE_DEVICES_TITLE}
        devices={devices}
        subtitle={DEVICES_TABLE.totalDevices}
        totalDevicesCount={totalDevicesCount}
        activeDevicesCount={activeDevicesCount}
      />
      <LinkToReport
        pathname={`/summary/${deviceType}/devices`}
        state={{
          date: {
            dateFilter,
            period,
          },
          dateFilterLabel,
          location: {
            locationFilter,
            ids,
          },
        }}
        label={DEVICES_TABLE.title}
      />
      <DevicesTableContainer
        devices={devices}
        tableColumns={DEVICES_TABLE}
        subtitle={DEVICES_TABLE.subTitle}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { ARCSummaryReducer, sharedSummaryReducer },
  } = state

  return {
    devices: ARCSummaryReducer.devices,
    activeDevicesCount: ARCSummaryReducer.activeDevicesCount,
    totalDevicesCount: ARCSummaryReducer.totalDevicesCount,
    deviceType: sharedSummaryReducer.activeTab,
    dateFilter: ARCSummaryReducer.dateFilter,
    period: ARCSummaryReducer.period,
    dateFilterLabel: ARCSummaryReducer.dateFilterLabel,
    ids: ARCSummaryReducer.ids,
    locationFilter: ARCSummaryReducer.locationFilter,
  }
}

export default connect(mapStateToProps)(
  withMediaQuery(withStyles(style)(ARCDevicesSummaryInfo))
)
