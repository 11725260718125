import { map } from 'lodash'
import rootActions from '../../../services/root/root-actions'
import authorizationActions from '../../LogIn/services/authorization-actions'
import { prepareDevicesTypes } from './dashboard-service'
import { activity } from './related/transaction-service'
import {
  filterDevicesByType,
  prepareDeviceFilter,
} from './related/filter-service'
import dashboardActions from './dashboard-actions'
import { prepareLocationFiltersParams } from '../../../services/root/root-service'
import { initialState } from './dashboard-constants'
import { STATUS_FILTER_VALUES } from '../../../services/constants'

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardActions.actionsTypes.STOP_POLLING_MODIFICATION: {
      return {
        ...state,
        polling: false,
      }
    }
    case dashboardActions.actionsTypes.LOAD_ACTIVITIES_SUCCEEDED: {
      const { items: activities, totalCount } = action.payload.activities
      const { maxPage, page } = state
      let needToRefreshPage = false
      let updatedMaxPage

      if (page > maxPage) {
        updatedMaxPage = state.page
      }

      let activitiesList = map(activities, (item) => {
        if (
          item.txStatus === STATUS_FILTER_VALUES.paused ||
          item.transactionStatus === STATUS_FILTER_VALUES.in_progress
        ) {
          needToRefreshPage = true
        }

        return activity(item, state.activities)
      })

      return {
        ...state,
        activities: activitiesList,
        totalCount:
          totalCount > activities.length ? totalCount : activities.length,
        isInitialState: false,
        needToPollActivities: needToRefreshPage,
        maxPage: updatedMaxPage || maxPage,
        isOnline: window.navigator.onLine,
        needToUpdateData: false,
      }
    }
    case dashboardActions.actionsTypes.SET_EXPANDED: {
      const { value, id } = action.payload

      const newActivities = map(state.activities, (item) =>
        item.id === id
          ? { ...item, expanded: value, details: item.details || {} }
          : { ...item, details: item.details || {} }
      )

      return {
        ...state,
        activities: newActivities,
      }
    }
    case dashboardActions.actionsTypes.DOWNLOAD:
    case dashboardActions.actionsTypes.DOWNLOAD_REPORT:
      return {
        ...state,
        isDownloadLoading: true,
      }
    case dashboardActions.actionsTypes.DOWNLOAD_SUCCEEDED:
    case dashboardActions.actionsTypes.DOWNLOAD_REPORT_SUCCEEDED:
      return {
        ...state,
        isDownloadLoading: false,
      }
    case dashboardActions.actionsTypes.DOWNLOAD_FAILED:
    case dashboardActions.actionsTypes.DOWNLOAD_REPORT_FAILED:
      return {
        ...state,
        error: action.payload.message,
        isOnline: window.navigator.onLine,
        isDownloadLoading: false,
        isInitialState: false,
      }
    case dashboardActions.actionsTypes.GET_CYCLE_COUNT_FAILED:
    case dashboardActions.actionsTypes.LOAD_ACTIVITIES_FAILED:
    case dashboardActions.actionsTypes.LOAD_DEVICES_FAILED:
      return {
        ...state,
        error: action.payload.message,
        isOnline: window.navigator.onLine,
        isLoading: false,
        isInitialState: false,
      }
    case dashboardActions.actionsTypes.LOAD_DEVICES_SUCCEEDED:
      return {
        ...state,
        devices: action.payload.devices,
        error: '',
        isOnline: true,
        isLoading: false,
      }
    case dashboardActions.actionsTypes.SET_SELECTED_DEVICES:
      const { selectedDevices, includeDeleted } = action.payload
      let { isAllDevicesSelected, devices, devicesTypesFilter } = state

      const filteredDevices = filterDevicesByType(devices, devicesTypesFilter)

      isAllDevicesSelected = selectedDevices.length === filteredDevices.length

      return {
        ...state,
        deviceFilter: {
          selectedDevices,
          includeDeleted,
          isAllDevicesSelected,
        },
        page: initialState.page,
        maxPage: initialState.maxPage,
        isLoading: false,
        needToUpdateData: true,
      }
    case dashboardActions.actionsTypes.CHANGE_FILTER: {
      const { name, selected } = action.payload
      let { deviceFilter } = state

      if (name === 'devicesTypesFilter') {
        deviceFilter = prepareDeviceFilter(selected, state)
      }

      return {
        ...state,
        [name]: selected,
        deviceFilter,
        page: initialState.page,
        maxPage: initialState.maxPage,
        firstDayActivity: [],
        lastDayActivity: [],
        needToUpdateData: true,
      }
    }
    case dashboardActions.actionsTypes.CHANGE_DATE_FILTER: {
      const { dateFilter, period, label } = action.payload

      return {
        ...state,
        dateFilter,
        period,
        dateFilterLabel: label,
        page: initialState.page,
        maxPage: initialState.maxPage,
        needToUpdateData: true,
      }
    }
    case dashboardActions.actionsTypes.APPLY_LOCATION_FILTER:
      return {
        ...state,
        locationFilter: action.payload.nodes,
        page: initialState.page,
        maxPage: initialState.maxPage,
        needToUpdateData: true,
      }
    case dashboardActions.actionsTypes.PREPARE_LOCATION_FILTER: {
      const { nodes, userPref } = action.payload

      return {
        ...state,
        locationFilter: prepareLocationFiltersParams(nodes, userPref),
        polling: true,
      }
    }
    case dashboardActions.actionsTypes.SET_STATUS_LABEL: {
      const { statusLabel } = action.payload

      return {
        ...state,
        statusLabel,
        needToUpdateData: true,
      }
    }
    case dashboardActions.actionsTypes.SET_DEVICES_TYPES_LABEL: {
      const { devicesTypesLabel } = action.payload

      return {
        ...state,
        devicesTypesLabel,
      }
    }
    case dashboardActions.actionsTypes.RESET_FILTERS: {
      return {
        ...state,
        statusFilter: initialState.statusFilter,
        dateFilter: initialState.dateFilter,
        devicesTypesFilter: state.defaultTypesFilter,
        devicesTypesLabel: initialState.devicesTypesLabel,
        period: initialState.period,
        statusLabel: initialState.statusLabel,
        dateFilterLabel: initialState.dateFilterLabel,
        deviceFilter: initialState.deviceFilter,
        page: initialState.page,
        maxPage: initialState.maxPage,
      }
    }
    case dashboardActions.actionsTypes.GO_TO_NEXT_PAGE: {
      return {
        ...state,
        page: action.payload.newPage,
        firstDayActivity: [],
        lastDayActivity: [],
        needToUpdateData: true,
      }
    }
    case dashboardActions.actionsTypes.GET_CYCLE_COUNT_SUCCEEDED: {
      const { data, isLast } = action.payload

      return {
        ...state,
        [isLast ? 'lastDayActivity' : 'firstDayActivity']: data.items,
        error: '',
        isOnline: true,
      }
    }
    case dashboardActions.actionsTypes.SET_ENABLE_QUICK_COUNT: {
      return {
        ...state,
        enableQuickCount: action.payload.enableQuickCount,
      }
    }
    case dashboardActions.actionsTypes.SET_CURRENT_ACTIVITY_LOG_MODAL_ID: {
      return {
        ...state,
        currentActivityLogModalId: action.payload.state,
      }
    }
    case dashboardActions.actionsTypes.SET_ROWS_PER_PAGE: {
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
        page: initialState.page,
        maxPage: initialState.maxPage,
        needToUpdateData: true,
      }
    }
    case authorizationActions.actionTypes.LOGOUT_SUCCEEDED:
      return {
        ...state,
        initialState,
      }
    case rootActions.actionTypes.LOAD_FEATURE_TOGGLING_SUCCEEDED:
      const features = action.payload.data
      const filter = prepareDevicesTypes(features)

      return {
        ...state,
        devicesTypesFilter: filter,
        defaultTypesFilter: filter,
      }
    case dashboardActions.actionsTypes.POLL_MODIFICATION_SUCCEEDED:
      return {
        ...state,
        lastModification: action.payload.id,
      }
    default:
      return state
  }
}
