import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import AddLocationButtonsContainer from '../addLocation/AddLocationButtonsContainer'
import BackButton from './BackButton'
import actions from '../../service/location-actions'
import styles from '../style'

const NavigationActionsWrapper = (props) => {
  const {
    classes,
    currentLoc,
    isReadOnly,
    isMobile,
    addNewLocation,
    changeLocation,
    routeParams,
  } = props
  const [deep, id] = currentLoc

  return (
    <div className={classes.navigationActionsWrapper}>
      {!isMobile && (
        <BackButton changeLocation={changeLocation} routes={routeParams} />
      )}
      {!isReadOnly && (
        <AddLocationButtonsContainer
          onSubmit={addNewLocation}
          deep={deep}
          parentId={id}
        />
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewLocation: (body, onClose) =>
      dispatch(checkConnection(() => actions.addNewLocation(body, onClose))),
  }
}

const mapStateToProps = (state) => ({
  currentLoc: state.locationReducer.currentLoc,
  isReadOnly: state.rootReducer.isOperatorView,
  routeParams: state.locationReducer.currentRouteParams,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(NavigationActionsWrapper)))
