import React from 'react'
import { connect } from 'react-redux'
import { forIn, isEmpty, uniqueId, map } from 'lodash'
import ErrorHasOccurred from '../../../components/_shared/errors/ErrorHasOccurred'
import ActivityCardComponent from './activityCard/ActivityCard'
import CustomDividerComponent from '../../../components/_shared/customDivider/CustomDevider'
import { getActivitiesCalendarDate } from '../../../services/dateFormat-service'
import EmptyActivitiesList from './EmptyActivitiesList'
import { getSubLabelsFromActivities } from '../services/dashboard-service'
import { prepareActivitiesByGroups } from '../services/related/transaction-service'
import Loader from '../../../components/_shared/loader/Loader'
import usePageVisibility from 'use-page-visibility'

const CardsContainerComponent = (props) => {
  const {
    activities,
    error,
    timezone,
    isInitialState,
    isOnline,
    devicesTypesFilter,
    startPollingModification,
    stopPollingModification,
    polling,
    currentActivityLogModalId,
    firstDayActivity,
    lastDayActivity,
  } = props

  const handleVisibilityChange = (visible) => {
    if (!visible) {
      stopPollingModification()
    } else if (!polling && !currentActivityLogModalId) {
      startPollingModification()
    }
  }

  usePageVisibility(handleVisibilityChange)

  const renderActivitiesGroups = (activities) => {
    const isNoActivities = isEmpty(activities)
    const elementArray = []

    if (!isNoActivities) {
      const subLabels = getSubLabelsFromActivities(
        activities,
        firstDayActivity,
        lastDayActivity,
        devicesTypesFilter
      )

      forIn(activities, (value, key) => {
        elementArray.push(
          <div key={uniqueId()}>
            <CustomDividerComponent
              label={getActivitiesCalendarDate(key, timezone)}
              subLabel={subLabels[elementArray.length]}
              margin="12px"
            />
            {map(value, (item) => {
              return (
                <ActivityCardComponent
                  key={item.id}
                  timezone={timezone}
                  activity={item}
                />
              )
            })}
          </div>
        )
      })
    }

    return isNoActivities && !isInitialState ? (
      <EmptyActivitiesList />
    ) : (
      <Loader isLoading={isInitialState}>{elementArray}</Loader>
    )
  }

  return (
    <>
      {error && isOnline ? (
        <ErrorHasOccurred hideLogo={true} />
      ) : (
        renderActivitiesGroups(activities)
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  activities: prepareActivitiesByGroups(
    state.dashboardReducer.activities,
    state.rootReducer.timezone
  ),
  period: state.dashboardReducer.period,
  statuses: state.dashboardReducer.statusFilter,
  error: state.dashboardReducer.error,
  isInitialState: state.dashboardReducer.isInitialState,
  isLoading: state.dashboardReducer.isLoading,
  timezone: state.rootReducer.timezone,
  firstDayActivity: state.dashboardReducer.firstDayActivity,
  lastDayActivity: state.dashboardReducer.lastDayActivity,
  devicesTypesFilter: state.dashboardReducer.devicesTypesFilter,
  isOnline: state.dashboardReducer.isOnline,
  currentActivityLogModalId: state.dashboardReducer.currentActivityLogModalId,
})

export default connect(mapStateToProps)(CardsContainerComponent)
