import { reduce, split } from 'lodash'

const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie =
    name +
    '=' +
    encodeURIComponent(value) +
    '; expires=' +
    expires +
    '; path=' +
    path
}

const getCookie = (name) => {
  const cookies = split(document.cookie, '; ')

  return reduce(
    cookies,
    (r, v) => {
      const parts = split(v, '=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    },
    ''
  )
}

export { setCookie, getCookie }
