const extDeviceDetailsActions = {}

extDeviceDetailsActions.actionTypes = {
  LOAD_DEVICE_DETAILS: '{External Device Details} Load device details',
  PUT_DEVICE_DETAILS: '{External Device Details} Put device details',
  LOAD_DEVICE_DETAILS_FAILED: '{External Device Details} Device details failed',

  LOAD_LOCATIONS_FOR_DEVICE:
    '{External Device Details} Load Locations for Device',
  LOAD_LOCATIONS_FOR_DEVICE_SUCCEEDED:
    '{External Device Details} Load Locations for Device Succeeded',
  LOAD_LOCATIONS_FOR_DEVICE_FAILED:
    '{External Device Details} Load Locations for Device Failed',

  SETUP_ROOMS_FILTER: '{External Device Details} Setup Rooms Filter',
  SETUP_ROOMS_FILTER_FAILED:
    '{External Device Details} Setup Rooms Filter Failed',

  UPDATE_DEVICE: '{External Device Details} Update Device',
  UPDATE_DEVICE_SUCCEEDED: '{External Device Details} Update Device Succeeded',
  UPDATE_DEVICE_FAILED: '{External Device Details} Update Device Failed',

  DELETE_DEVICE: '{External Device Details} Delete Device',
  DELETE_DEVICE_SUCCEEDED: '{External Device Details} Delete Device Succeeded',
  DELETE_DEVICE_FAILED: '{External Device Details} Delete Device Failed',
}

extDeviceDetailsActions.loadDeviceDetails = (deviceId) => {
  return {
    type: extDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS,
    payload: {
      deviceId,
    },
  }
}

extDeviceDetailsActions.putDeviceDetails = (deviceDetails) => {
  return {
    type: extDeviceDetailsActions.actionTypes.PUT_DEVICE_DETAILS,
    payload: {
      deviceDetails,
    },
  }
}

extDeviceDetailsActions.loadDeviceDetailsFailed = (message) => {
  return {
    type: extDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS_FAILED,
    payload: {
      message,
    },
  }
}

extDeviceDetailsActions.loadLocationsForDevice = (deviceId) => {
  return {
    type: extDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE,
    payload: {
      deviceId,
    },
  }
}

extDeviceDetailsActions.loadLocationsForDeviceSuccess = (nodes) => {
  return {
    type: extDeviceDetailsActions.actionTypes
      .LOAD_LOCATIONS_FOR_DEVICE_SUCCEEDED,
    payload: {
      nodes,
    },
  }
}

extDeviceDetailsActions.loadLocationsForDeviceFailed = (message) => {
  return {
    type: extDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

extDeviceDetailsActions.setupRoomsFilter = (nodes, tree, userId) => {
  return {
    type: extDeviceDetailsActions.actionTypes.SETUP_ROOMS_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

extDeviceDetailsActions.setupRoomsFilterFailed = (message) => {
  return {
    type: extDeviceDetailsActions.actionTypes.SETUP_ROOMS_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

extDeviceDetailsActions.updateDevice = (serialNumber, body, onSuccess) => {
  return {
    type: extDeviceDetailsActions.actionTypes.UPDATE_DEVICE,
    payload: {
      serialNumber,
      body,
      onSuccess,
    },
  }
}

extDeviceDetailsActions.updateDeviceSuccess = (locationIds, nickname) => {
  return {
    type: extDeviceDetailsActions.actionTypes.UPDATE_DEVICE_SUCCEEDED,
    payload: {
      locationIds,
      nickname,
    },
  }
}

extDeviceDetailsActions.updateDeviceFailed = (message) => {
  return {
    type: extDeviceDetailsActions.actionTypes.UPDATE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

extDeviceDetailsActions.deleteDevice = (serialNumber, onClose) => {
  return {
    type: extDeviceDetailsActions.actionTypes.DELETE_DEVICE,
    payload: {
      serialNumber,
      onClose,
    },
  }
}

extDeviceDetailsActions.deleteDeviceSuccess = () => {
  return {
    type: extDeviceDetailsActions.actionTypes.DELETE_DEVICE_SUCCEEDED,
  }
}

extDeviceDetailsActions.deleteDeviceFailed = (message) => {
  return {
    type: extDeviceDetailsActions.actionTypes.DELETE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

export default extDeviceDetailsActions
