import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import style from './style'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { DOWNLOAD_MODAL } from '../../services/dashboard-constants'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'

const DownloadModal = (props) => {
  const {
    isOpen,
    selectedChip,
    setSelectedChip,
    onCloseModal,
    onSubmit,
    classes,
  } = props

  const handleClickChip = (event) => {
    setSelectedChip(event.target.textContent)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle className={classes.modalTitleContainer} disableTypography>
        <Typography variant="h5" className={classes.modalTitle}>
          {DOWNLOAD_MODAL.title}
        </Typography>
        <CloseButton onCloseModal={onCloseModal} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.chipsWrapper}>
          <Chip
            label={DOWNLOAD_MODAL.report}
            className={
              selectedChip === DOWNLOAD_MODAL.report ? classes.selectedChip : ''
            }
            variant="outlined"
            onClick={handleClickChip}
            clickable
          />
          <Chip
            label={DOWNLOAD_MODAL.sourceData}
            className={
              selectedChip === DOWNLOAD_MODAL.sourceData
                ? classes.selectedChip
                : ''
            }
            variant="outlined"
            onClick={handleClickChip}
            clickable
          />
        </div>

        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={onSubmit}
        >
          {DOWNLOAD_MODAL.submit}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(style)(DownloadModal)
