import {
  STATUS_FILTER_VALUES,
  STATUS_LABELS,
  UNKNOWN_LOCATION,
} from '../../../../../../services/constants'
import { compact, isEmpty } from 'lodash'

import Box from '@material-ui/core/Box'
import { DEVICE_DETAILS_LABELS } from '../../../../_shared/services/devices-constants'
import { FIELDS } from '../../../../../Dashboard/services/dashboard-constants'
import FieldValue from './FieldValue'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { getLastUsedDate } from '../../../../_shared/services/devices-service'
import { getLocationPath } from '../../../../../../services/root/root-service'
import moment from 'moment'
import { mssFormat } from '../../../../../../services/dateFormat-service'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'

const RecentUsageData = (props) => {
  const {
    deviceDetails,
    deviceDetails: { details = {} },
    timezone,
    classes,
    cumulativeUsageInSecs,
  } = props

  const lastUsedData = getLastUsedDate(details[FIELDS.lastEvent], timezone)
  const isInProgress = deviceDetails.status === STATUS_FILTER_VALUES.in_progress
  const isError = !!deviceDetails.errorMessage
  const isLocationPath =
    deviceDetails.locationPath && !isEmpty(compact(deviceDetails.locationPath))
  let lastEventLocationPath

  if (isLocationPath) {
    lastEventLocationPath = getLocationPath(
      deviceDetails.locationPath,
      false,
      ' -> '
    )
  } else {
    lastEventLocationPath = deviceDetails.txStatus ? UNKNOWN_LOCATION : ''
  }

  return (
    <div className={classes.recentUsageContainer}>
      <Typography variant="subtitle2">
        {DEVICE_DETAILS_LABELS.usageData}
      </Typography>
      {isEmpty(deviceDetails) ? (
        <Typography variant="subtitle1">
          {DEVICE_DETAILS_LABELS.noActivities}
        </Typography>
      ) : (
        <Box color="text.secondary" mt="15px">
          <FieldValue
            label={DEVICE_DETAILS_LABELS.lastUsed}
            value={lastUsedData}
          />
          <FieldValue
            label={DEVICE_DETAILS_LABELS.location}
            value={lastEventLocationPath}
          />
          <FieldValue
            label={DEVICE_DETAILS_LABELS.status}
            value={STATUS_LABELS[deviceDetails.txStatus]}
          />
          {deviceDetails.details && deviceDetails.details[FIELDS.cycleTime] && (
            <FieldValue
              label={DEVICE_DETAILS_LABELS.cycleTime}
              value={moment(deviceDetails.details[FIELDS.cycleTime]).format(
                mssFormat
              )}
            />
          )}
          {isInProgress && (
            <FieldValue
              label={DEVICE_DETAILS_LABELS.runTime}
              value={deviceDetails.details[FIELDS.inProgress]}
            />
          )}
          <FieldValue
            label={DEVICE_DETAILS_LABELS.cumulativeUsage}
            value={
              cumulativeUsageInSecs !== null && cumulativeUsageInSecs > 0
                ? `${(cumulativeUsageInSecs / 60 / 60).toFixed(2)} hours`
                : DEVICE_DETAILS_LABELS.noInformation
            }
          />
          {isError && (
            <Box display="flex">
              <Typography variant="subtitle1" color="error" gutterBottom>
                {DEVICE_DETAILS_LABELS.errorCode}:
              </Typography>
              <Typography
                variant="subtitle1"
                color="error"
                className={classes.fieldValue}
                gutterBottom
              >
                {deviceDetails.errorMessage}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </div>
  )
}

export default withStyles(styles)(RecentUsageData)
