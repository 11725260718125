const style = () => ({
  pageWrapper: {
    width: '100%',
  },
  summary: {
    display: 'flex',
    marginBottom: '22px',
    '& > div:nth-child(1)': {
      marginRight: '10px',
    },
  },
  overview: {
    marginBottom: '15px',
    marginLeft: '12px',
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    fontSize: '14px',
    lineHeight: '17px',
  },
  locationsAndDevicesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      width: '45%',
    },
  },
  deviceInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  locationsInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  filters: {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'center',
  },
  '@media (min-width: 1224px)': {
    pageWrapper: {
      padding: '10px 0 0',
      width: '100%',
    },
    summaryWrapper: {
      padding: '0 27% 10px 27%',
    },
    overview: {
      fontSize: '20px',
      lineHeight: '24px',
    },
    contentWrapper: {
      padding: '0 27%',
    },
  },
  '@media screen and (max-width: 420px) and (max-height: 820px)': {
    summaryWrapper: {
      paddingBottom: '50px',
    },
  },
})

export default style
