import {
  isNull,
  forEach,
  indexOf,
  fill,
  findIndex,
  filter,
  round,
  map,
  sortBy,
  orderBy,
  floor,
} from 'lodash'
import { ARC_TYPES, TREE_LEVELS } from '../../../../services/constants'
import {
  DDMMyyyyTHHmm,
  MMM,
  MMMD,
  D,
  MM,
  YYYY,
} from '../../../../services/dateFormat-service'
import { isArc } from '../../../../services/root/root-service'
import { prepareTimeFilter } from '../../../Dashboard/services/related/filter-service'
import {
  RANGE_TYPES_KEYS,
  TABLE_CELL_COLORS,
  ROW_NAME_KEYS,
  TABLE_SINGULAR_LABELS,
  SORTING_BY,
} from './summary-constants'
import { _formatDate } from '../../../../services/filterQuery-service'
import moment from 'moment'

const prepareBodyForSummary = (
  ids,
  dateFilter,
  defaultPeriod,
  timezone,
  deviceSubType
) => {
  const period = prepareTimeFilter(dateFilter, defaultPeriod, timezone)
  const deviceSubTypes = isArc(deviceSubType) ? ARC_TYPES : [deviceSubType]

  const dateFrom = _formatDate(
    period.startDate,
    'start',
    timezone,
    DDMMyyyyTHHmm
  )
  const dateTo = _formatDate(period.endDate, 'end', timezone, DDMMyyyyTHHmm)

  const localZone = moment().format('Z')
  const zoneIdStr = isNull(timezone) ? localZone : ''

  return {
    locationIds: ids,
    dateFrom,
    dateTo,
    zoneIdStr,
    deviceSubType: deviceSubTypes,
  }
}

const getAllSelectedIds = (tree, pref) => {
  const selectedIds = []
  let propertyId

  let traverse = function (current) {
    forEach(current, (item) => {
      if (item.locationType === TREE_LEVELS.PROPERTY) {
        propertyId = item.id
      }

      const isChecked = indexOf(pref, propertyId) !== -1 || item.checked

      if (isChecked) {
        selectedIds.push(item.id)
      }

      if (item.children && item.children.length) {
        traverse(item.children)
      }
    })
  }

  traverse(tree)

  return selectedIds
}

const getAllIds = (tree) => {
  const selectedIds = []

  let traverse = function (current) {
    forEach(current, (item) => {
      selectedIds.push(item.id)

      if (item.children && item.children.length) {
        traverse(item.children)
      }
    })
  }

  traverse(tree)

  return selectedIds
}

const getAbsoluteMonths = (momentDate) => {
  const months = +momentDate.format(MM)
  const years = +momentDate.format(YYYY)

  return months + years * 12
}

const getDrillDownTableHeaderConfig = (
  dateFilter,
  period,
  timezone,
  rangeType
) => {
  const { startDate, endDate } = prepareTimeFilter(dateFilter, period, timezone)

  // to verify week numbers
  switch (rangeType) {
    case RANGE_TYPES_KEYS.MONTH:
      const firstMonthNum = startDate.get('month')
      const months = []

      const startMonths = getAbsoluteMonths(startDate)
      const endMonths = getAbsoluteMonths(endDate)

      const monthDifference = endMonths - startMonths

      for (let i = 0; i <= monthDifference; i++) {
        const currentMonthNum = i + firstMonthNum + 1

        months.push({
          number: currentMonthNum !== 12 ? currentMonthNum % 12 : 12,
          label: moment()
            .month(currentMonthNum - 1)
            .format(MMM),
        })
      }

      return months
    case RANGE_TYPES_KEYS.WEEK:
      const firstWeekNum = startDate.get('week')
      const weeks = []

      // moment makes startOfCurrentWeek and endOfCurrentWeek same as startDate if
      // startOfCurrentWeek becomes equal to endDate. Need to dive deeper to understand
      // this behavior (really strange).

      // It is a workaround of the ^ issue. We need it to handle the case when endDate is
      // the start of new week.
      const proceedCount = +moment({ ...endDate })
        .startOf('week')
        .isSame(endDate)

      let startOfCurrentWeek = moment({ ...startDate })
      let endOfCurrentWeek = moment({ ...startDate })
      endOfCurrentWeek.endOf('week')

      // looping through the selected period by weeks (to get proper labels)
      for (
        let i = 0;
        startOfCurrentWeek < endDate ||
        i <= endDate.diff(startDate, 'weeks') + proceedCount;
        i++
      ) {
        const currentWeekNum = i + firstWeekNum

        weeks.push({
          number: currentWeekNum <= 52 ? currentWeekNum : currentWeekNum % 52,
          label: getDateRangeLabel(
            { ...startOfCurrentWeek },
            endOfCurrentWeek > endDate
              ? { ...endDate }
              : { ...endOfCurrentWeek }
          ),
        })

        startOfCurrentWeek = moment({ ...endOfCurrentWeek }).add(1, 'day')
        endOfCurrentWeek = moment({ ...startOfCurrentWeek }).endOf('week')
      }
      return weeks
    default:
      return
  }
}

const getDateRangeLabel = (startDate, endDate) => {
  const start = moment(startDate)
  const end = moment(endDate)

  if (start.get('month') === end.get('month')) {
    return `${start.format(MMMD)} - ${end.format(D)}`
  }

  return `${start.format(MMMD)} - ${end.format(MMMD)}`
}

const getDataToDisplay = (data, header, tableType, sortByField, order) => {
  const dataToDisplay = []

  const nameKey = ROW_NAME_KEYS[tableType]

  // parsing data to have the following structure: {name: '', cycles: []}
  // please take a look at 'Network' tab in dev tools to see the initial structure
  forEach(data, (item) => {
    // extracting current value of 'locationPath' or 'serialNumber'
    const rowName = item.id[nameKey]

    // searching for the index of rowName in dataToDisplay to update its 'cycles' field
    const indexToUpdate = findIndex(dataToDisplay, { name: rowName })

    // searching for the index of range to update its value in dataToDisplay[indexToUpdate].cycles[...]
    const indexOfRange = findIndex(
      header,
      (rangeNum) => rangeNum.number === item.id.range
    )

    // (indexToUpdate === -1) - means there are cycles on this device or location but we don't have it in our dataToDisplay yet
    // (item.count === 0) - means there are no cycles on this device or location but we need to add it anyway
    if (indexToUpdate === -1 || item.count === 0) {
      const cycles = fill(new Array(header.length), 0)

      // updating range found earlier
      cycles[indexOfRange] = item.count

      dataToDisplay.push({
        name: rowName,
        cycles,
      })
    } else {
      // updating range found earlier
      dataToDisplay[indexToUpdate].cycles[indexOfRange] = item.count
    }
  })

  // calculating median average value
  const dataToSort = map(dataToDisplay, (item) => {
    const filteredCycles = sortBy(filter(item.cycles))
    const numberOfActiveRanges = filteredCycles.length

    const centralIndex = floor(numberOfActiveRanges / 2)

    // taking central element if 'numberOfActiveRanges' is odd
    // taking average of central values if its even
    // 0 if no cycles
    const average =
      numberOfActiveRanges % 2
        ? filteredCycles[centralIndex]
        : round(
            (filteredCycles[centralIndex] + filteredCycles[centralIndex - 1]) /
              2
          ) || 0

    return {
      ...item,
      average,
    }
  })

  // returning sorted data (deafult: in descending order by average)
  return orderBy(dataToSort, SORTING_BY[sortByField], order)
}

const getCellColor = (cellValue, average) => {
  if (cellValue <= 0) {
    return TABLE_CELL_COLORS.red
  } else if (cellValue < average) {
    return TABLE_CELL_COLORS.yellow
  } else {
    return TABLE_CELL_COLORS.green
  }
}

const getTableLabel = (tableType) =>
  `Cycles Per ${TABLE_SINGULAR_LABELS[tableType]}`

export {
  prepareBodyForSummary,
  getAllSelectedIds,
  getAllIds,
  getDrillDownTableHeaderConfig,
  getDataToDisplay,
  getCellColor,
  getTableLabel,
}
