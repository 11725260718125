import React from 'react'
import { withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { getLinkToReportLabel } from '../services/summary-constants'
import styles from './style'

const LinkToReport = (props) => {
  const { state, pathname, label, classes } = props

  const toProp = {
    pathname,
    state,
  }

  return (
    <div className={classes.linkWrapper}>
      <Link to={toProp} className={classes.linkToReport}>
        {getLinkToReportLabel(label)}
      </Link>
    </div>
  )
}

export default withStyles(styles)(LinkToReport)
