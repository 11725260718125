import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import styles from './style'

const StatusDot = (props) => {
  const { status, classes } = props

  return (
    <div className={classes.wrapper}>
      <div className={classNames(classes.statusDot, classes[status])}>
        {status === 'ACTIVE' && <div className={classes.animatedDot} />}
      </div>
    </div>
  )
}

export default withStyles(styles)(StatusDot)
