import React from 'react'
import { connect } from 'react-redux'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'
import { BUTTONS, LOCATIONS_LABELS } from '../../service/location-constants'
import DeleteModalWithInputComponent from './DeleteModalWithInput'
import actions from '../../service/location-actions'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import { searchIdsList } from '../../service/location-service'
import Button from '@material-ui/core/Button'

const DeleteLocationButtonComponent = withStyles(styles)((props) => {
  const {
    onSubmit,
    location,
    subLocationsCount,
    error,
    resetError,
    classes,
    isMobile,
    getAssignedDevices,
    tree,
    resetUsers,
    onModalClose,
  } = props
  const [open, setOpen] = React.useState(false)
  const withSubLocations = subLocationsCount !== 0

  const handleClickOpen = () => {
    resetError()
    resetUsers()

    getAssignedDevices(searchIdsList(location, tree), location)

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    resetError()
  }

  const onSubmitButton = (value) => {
    onSubmit(value, handleClose)
  }

  return (
    <div className={classes.deleteButtonWrapper}>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        className={classes.deleteButton}
      >
        {BUTTONS.deleteLocation}
      </Button>
      <DeleteModalWithInputComponent
        isOpen={open}
        title={
          isMobile
            ? LOCATIONS_LABELS.warningTitle.toUpperCase()
            : LOCATIONS_LABELS.warningTitle
        }
        onClose={handleClose}
        onModalClose={onModalClose}
        onSubmit={onSubmitButton}
        locationId={location.id}
        subLocationsCount={subLocationsCount}
        location={location}
        withInput={withSubLocations}
        subLocationsError={error}
      />
    </div>
  )
})

const mapStateToProps = (state) => ({
  subLocationsCount: state.locationReducer.subLocationsCount,
  error: state.locationReducer.error,
  tree: state.locationReducer.tree,
})

const mapDispatchToProps = (dispatch) => ({
  getAssignedDevices: (ids, location) =>
    dispatch(checkConnection(() => actions.getAssignedDevices(ids, location))),
  resetError: () => dispatch(actions.resetError()),
  resetUsers: (id) => dispatch(actions.resetUsers(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(DeleteLocationButtonComponent))
