import React, { createRef, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withMediaQuery } from '../../../../services/root/root-service'
import { BUTTONS, LOCATIONS_LABELS } from '../../service/location-constants'

const DeleteContent = withMediaQuery(
  withStyles(styles)((props) => {
    const {
      location,
      subLocationsCount,
      subLocationsError,
      isOpen,
      isLoadingSubLocations,
      withInput,
      onSubmit,
      isMobile,
      classes,
    } = props

    const inputRef = createRef()
    const [error, setError] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const dialogHelperText = !withInput
      ? LOCATIONS_LABELS.deleteWithoutSubLocations1(location.name)
      : LOCATIONS_LABELS.deleteWithSubLocations1(
          location.name,
          subLocationsCount
        )
    const dialogHelperText2 = LOCATIONS_LABELS.commonMessage2()
    const dialogHelperText3 = LOCATIONS_LABELS.commonMessage3(
      location.name,
      subLocationsCount
    )
    const deleteWarningConfirm =
      withInput && LOCATIONS_LABELS.deleteWarningConfirm(subLocationsCount)

    useEffect(() => {
      setInputValue('')
    }, [isOpen])

    useEffect(() => {
      if (isOpen && withInput && !isLoadingSubLocations && !isMobile) {
        inputRef.current.focus()
      }
    }, [isLoadingSubLocations])

    const onChange = (event) => {
      let value = event.target.value

      if (/^\d+$/.test(value) || value === '') {
        setInputValue(value)
      }
    }

    const handleSubmit = (event) => {
      event.preventDefault()

      if ((withInput && inputValue) || !withInput) {
        setError(false)
        onSubmit(withInput ? inputValue : 0)
      } else {
        setError(true)
      }
    }

    return (
      <>
        <Typography variant="body2">{dialogHelperText}</Typography>
        <br />
        <Typography variant="body2">{dialogHelperText2}</Typography>
        <br />
        <Typography variant="body2">{dialogHelperText3}</Typography>
        <br />
        <Typography variant="body2">{deleteWarningConfirm}</Typography>

        <form onSubmit={handleSubmit}>
          {withInput && (
            <TextField
              className={classes.input}
              variant="outlined"
              value={inputValue}
              onChange={onChange}
              placeholder={`${subLocationsCount}`}
              inputProps={{ className: classes.outlined }}
              error={error}
              inputRef={inputRef}
            />
          )}
          <Typography variant="subtitle1" color="error">
            {subLocationsError}
          </Typography>

          <Button
            type="submit"
            color="secondary"
            className={classes.submitButton}
            variant="contained"
          >
            {BUTTONS.deleteLocation}
          </Button>
        </form>
      </>
    )
  })
)

export default DeleteContent
