const styles = (theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '7%',
    paddingRight: '7%',
    alignItems: 'center',
  },
  infoBlock: {
    fontSize: '15px',
    lineHeight: '18px',
    paddingBottom: '26px',
  },
  newUserLink: {
    fontSize: '12px',
    lineHeight: '23px',
  },
  changeOwnerButton: {
    position: 'fixed',
    bottom: '41px',
  },
})

export default styles
