import React from 'react'
import { ReactComponent as CommonErrorIcon } from '../../../assets/icons/common_error.svg'
import ErrorPage from './ErrorPage'
import { ERROR_HAS_OCCURRED } from './errors-constants'

const ErrorHasOccurred = (props) => {
  const { hideLogo, backFunction } = props

  return (
    <ErrorPage
      pageTitle={ERROR_HAS_OCCURRED.generalName}
      errorDescriptionPart1={ERROR_HAS_OCCURRED.checkTryAgain}
      errorDescriptionPart2={ERROR_HAS_OCCURRED.problemPersist}
      goToBackText={ERROR_HAS_OCCURRED.backToPreviousPage}
      backFunction={backFunction}
      hideLogo={hideLogo}
      Icon={CommonErrorIcon}
    />
  )
}

export default ErrorHasOccurred
