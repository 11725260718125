const arcDeviceDetailsActions = {}

arcDeviceDetailsActions.actionTypes = {
  UPDATE_DEVICE: '{ARC Device Details} Update Device',
  UPDATE_DEVICE_SUCCEEDED: '{ARC Device Details} Update Device Succeeded',
  UPDATE_DEVICE_FAILED: '{ARC Device Details} Update Device Failed',

  DELETE_DEVICE: '{ARC Device Details} Delete Device',
  DELETE_DEVICE_SUCCEEDED: '{ARC Device Details} Delete Device Succeeded',
  DELETE_DEVICE_FAILED: '{ARC Device Details} Delete Device Failed',

  LOAD_DEVICE_DETAILS: '{ARC Device Details} Load device details',
  LOAD_DEVICE_DETAILS_FAILED: '{ARC Device Details} Device details failed',

  LOAD_ALLOWED_LOCATIONS_FOR_DEVICE:
    '{ARC Device Details} Load allowed locations',
  LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_SUCCEEDED:
    '{ARC Device Details} Load allowed locations succeeded',
  LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_FAILED:
    '{ARC Device Details} Load allowed locations failed',

  RESET_DELETE_ERROR: '{ARC Device Details} Reset Delete Error',
  RESET_UPDATE_ERROR: '{ARC Device Details} Reset Update Error',
  RESET_CHANGE_DEVICE_FORM: '{ARC Device Details} Change Device Form',
  RESET_DETAILS: '{ARC Device Details} Reset Details',

  LOAD_LOCATIONS_FOR_DEVICE: '{ARC Device Details} Load locations list',

  GET_DEVICE_STATISTICS: '{ARC Device Details} Get Device Statistics',
  GET_DEVICE_STATISTICS_SUCCEEDED:
    '{ARC Device Details} Get Device Statistics Succeeded',
  GET_DEVICE_STATISTICS_FAILED:
    '{ARC Device Details} Get Device Statistics Failed',

  SETUP_LOCATIONS_FILTER: '{ARC Device Details} Setup locations Filter',
  SETUP_LOCATIONS_FILTER_FAILED:
    '{ARC Device Details} Setup locations Filter Failed',
}

arcDeviceDetailsActions.resetDetails = function () {
  return {
    type: arcDeviceDetailsActions.actionTypes.RESET_DETAILS,
  }
}

arcDeviceDetailsActions.setupLocationsFilter = function (nodes, tree, userId) {
  return {
    type: arcDeviceDetailsActions.actionTypes.SETUP_LOCATIONS_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

arcDeviceDetailsActions.setupLocationsFilterFailed = function (message) {
  return {
    type: arcDeviceDetailsActions.actionTypes.SETUP_LOCATIONS_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceDetailsActions.updateDevice = function (
  serialNumber,
  body,
  onSuccess
) {
  return {
    type: arcDeviceDetailsActions.actionTypes.UPDATE_DEVICE,
    payload: {
      serialNumber,
      body,
      onSuccess,
    },
  }
}

arcDeviceDetailsActions.updateDeviceSuccess = function (locationIds, nickname) {
  return {
    type: arcDeviceDetailsActions.actionTypes.UPDATE_DEVICE_SUCCEEDED,
    payload: {
      locationIds,
      nickname,
    },
  }
}

arcDeviceDetailsActions.updateDeviceFailed = function (message) {
  return {
    type: arcDeviceDetailsActions.actionTypes.UPDATE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceDetailsActions.deleteDevice = function (serialNumber, onClose) {
  return {
    type: arcDeviceDetailsActions.actionTypes.DELETE_DEVICE,
    payload: {
      serialNumber,
      onClose,
    },
  }
}

arcDeviceDetailsActions.deleteDeviceSuccess = function () {
  return {
    type: arcDeviceDetailsActions.actionTypes.DELETE_DEVICE_SUCCEEDED,
  }
}

arcDeviceDetailsActions.deleteDeviceFailed = function (message) {
  return {
    type: arcDeviceDetailsActions.actionTypes.DELETE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceDetailsActions.loadDeviceDetails = function (deviceId) {
  return {
    type: arcDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS,
    payload: {
      deviceId,
    },
  }
}

arcDeviceDetailsActions.loadDeviceDetailsFailed = function (message) {
  return {
    type: arcDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceDetailsActions.loadAllowedLocationsForDevice = function (deviceId) {
  return {
    type: arcDeviceDetailsActions.actionTypes.LOAD_ALLOWED_LOCATIONS_FOR_DEVICE,
    payload: {
      deviceId,
    },
  }
}

arcDeviceDetailsActions.loadAllowedLocationsForDeviceSucceeded = function (
  deviceInfo = {},
  deviceId
) {
  return {
    type: arcDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_SUCCEEDED,
    payload: {
      deviceInfo,
      deviceId,
    },
  }
}

arcDeviceDetailsActions.loadAllowedLocationsForDeviceFailed = function (
  message
) {
  return {
    type: arcDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceDetailsActions.resetDeleteError = function () {
  return {
    type: arcDeviceDetailsActions.actionTypes.RESET_DELETE_ERROR,
  }
}

arcDeviceDetailsActions.resetUpdateError = function () {
  return {
    type: arcDeviceDetailsActions.actionTypes.RESET_UPDATE_ERROR,
  }
}

arcDeviceDetailsActions.resetChangeDeviceForm = function () {
  return {
    type: arcDeviceDetailsActions.actionTypes.RESET_CHANGE_DEVICE_FORM,
  }
}

arcDeviceDetailsActions.loadLocationsForDevice = function (data, onSuccess) {
  return {
    type: arcDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE,
    payload: {
      allowedLocationIds: data.allowedLocationIds,
      onSuccess,
    },
  }
}

arcDeviceDetailsActions.loadLocationsForDeviceFailed = function (message) {
  return {
    type: arcDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

arcDeviceDetailsActions.getDeviceStatistics = (deviceId) => {
  return {
    type: arcDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS,
    payload: {
      deviceId,
    },
  }
}

arcDeviceDetailsActions.getDeviceStatisticsSuccess = (data) => {
  return {
    type: arcDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS_SUCCEEDED,
    payload: {
      data,
    },
  }
}

arcDeviceDetailsActions.getDeviceStatisticsFailed = (message) => {
  return {
    type: arcDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS_FAILED,
    payload: {
      message,
    },
  }
}

export default arcDeviceDetailsActions
