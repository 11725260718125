import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DeviceTabsContainer from '../../components/_shared/deviceTabPanel/DeviceTabsContainer'
import rootActions from '../../services/root/root-actions'
import {
  devicesConfig,
  SUMMARY_TITLE,
} from './_shared/services/summary-constants'
import actions from './_shared/services/summary-actions'
import {
  checkConnection,
  withMediaQuery,
} from '../../services/root/root-service'
import { DEVICE_TYPE_MAPPER } from '../../services/constants'
import { gaEventAction } from '../../services/googleAnalytics/ga-actions'

const SummaryContainer = ({ history, isMobile }) => {
  const dispatch = useDispatch()
  const gaEventSend = gaEventAction(dispatch)

  const changeActiveTab = useCallback((deviceType, clickEvent) => {
    dispatch(actions.changeActiveTab(deviceType))
    if (clickEvent) {
      gaEventSend('summary_report_tab', {
        event_label: DEVICE_TYPE_MAPPER[deviceType],
      })
    }
  }, [])
  const getLocationModificationId = useCallback(
    () => dispatch(checkConnection(rootActions.getLocationModificationId)),
    []
  )
  const activeTab = useSelector(
    ({ summaryReducer }) => summaryReducer.sharedSummaryReducer.activeTab
  )

  useEffect(() => {
    getLocationModificationId()
  }, [getLocationModificationId])

  return (
    <DeviceTabsContainer
      changeActiveTab={changeActiveTab}
      title={SUMMARY_TITLE}
      devicesConfig={devicesConfig}
      activeTab={activeTab}
      fixedHeader={isMobile}
      history={history}
    />
  )
}

export default withMediaQuery(SummaryContainer)
