import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import styles from './../../../../components/_shared/modals/simpleModal/style'
import TreeList from '../../../../components/_shared/customTree/TreeList'
import { TREE_LEVELS } from '../../../../services/constants'
import { withMediaQuery } from '../../../../services/root/root-service'
import IncludeDeletedLocationsControl from './IncludeDeletedLocationsControl'
import IncludeUnknownLocationsControl from './IncludeUnknownLocationsControl'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'

const LocationCheckboxTreeComponent = (props) => {
  const {
    onApply,
    onClose,
    modalTitle,
    isOpen,
    actionLabel,
    error,
    classes,
    isMobile,
    includeUnknownLocations,
    includeDeletedLocations,
    setFlag,
    setDeletedFlag,
  } = props

  const changeFlag = (event) => {
    setFlag(event.target.checked)
  }

  const changeDeletedFlag = (event) => {
    setDeletedFlag(event.target.checked)
  }

  const onCloseModal = () => {
    onClose()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          className={classes.modalTitleContainer}
          disableTypography={true}
        >
          <Typography
            variant={isMobile ? 'subtitle2' : 'h4'}
            className={classes.modalTitle}
          >
            {modalTitle}
          </Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <CloseButton onCloseModal={onCloseModal} />
        </DialogTitle>
        <DialogContent>
          <IncludeDeletedLocationsControl
            onChange={changeDeletedFlag}
            isInclude={includeDeletedLocations}
          />
          <IncludeUnknownLocationsControl
            onChange={changeFlag}
            isInclude={includeUnknownLocations}
          />
          <TreeList mainLevel={TREE_LEVELS.PROPERTY} />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={onApply} variant="contained" color="primary">
            {actionLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withMediaQuery(withStyles(styles)(LocationCheckboxTreeComponent))
