import { isEmpty } from 'lodash'
import * as sharedActions from 'containers/Devices/_shared/services/devices-actions'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import arcDeviceListActions from '../../../List/ARC/services/arcDeviceList-actions'
import arcDeviceDetailsActions from './arcDeviceDetails-actions'
import { ARCInitialDeviceDetailsState } from './arcDeviceDetails-constants'

export const ARCDeviceDetailsReducer = (
  state = ARCInitialDeviceDetailsState,
  action
) => {
  switch (action.type) {
    case arcDeviceDetailsActions.actionTypes.RESET_DETAILS: {
      return {
        ...ARCInitialDeviceDetailsState,
      }
    }
    case arcDeviceDetailsActions.actionTypes.UPDATE_DEVICE_FAILED: {
      return {
        ...state,
        updateError: action.payload.message,
      }
    }
    case arcDeviceDetailsActions.actionTypes.DELETE_DEVICE_FAILED: {
      return {
        ...state,
        deleteError: action.payload.message,
      }
    }
    case arcDeviceDetailsActions.actionTypes.UPDATE_DEVICE_SUCCEEDED: {
      return {
        ...state,
        updateError: false,
        allowedLocationIds: action.payload.locationIds,
        nickname: action.payload.nickname,
      }
    }
    case arcDeviceDetailsActions.actionTypes.SETUP_LOCATIONS_FILTER:
      return {
        ...state,
        locationFilter: action.payload.nodes,
        locationFilterError: '',
      }
    case arcDeviceDetailsActions.actionTypes.RESET_DELETE_ERROR:
    case arcDeviceDetailsActions.actionTypes.DELETE_DEVICE_SUCCEEDED: {
      return {
        ...state,
        deleteError: false,
      }
    }
    case arcDeviceDetailsActions.actionTypes.RESET_ACTION_ERROR: {
      return {
        ...state,
        actionError: false,
      }
    }
    case arcDeviceDetailsActions.actionTypes.RESET_UPDATE_ERROR: {
      return {
        ...state,
        updateError: '',
        isLoading: false,
      }
    }
    case arcDeviceDetailsActions.actionTypes.RESET_CHANGE_DEVICE_FORM: {
      return {
        ...state,
        allowedLocationIds: null,
        nickname: '',
      }
    }
    case arcDeviceListActions.actionTypes.PUT_DEVICE_DETAILS: {
      const { device = {} } = action.payload
      const deviceSerialNumber = device.deviceSerialNumber
        ? device.deviceSerialNumber
        : state.serialNumber

      return {
        ...state,
        deviceDetails: device,
        serialNumber: deviceSerialNumber,
        isLoading: false,
      }
    }
    case arcDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_SUCCEEDED: {
      const {
        deviceInfo: { allowedLocationIds, nickname, serialNumber },
      } = action.payload
      const deviceSerialNumber = serialNumber
        ? serialNumber
        : state.serialNumber

      return {
        ...state,
        allowedLocationIds,
        nickname: nickname || '',
        serialNumber: deviceSerialNumber,
        deviceLinked: !isEmpty(action.payload.deviceInfo),
        isLoading: false,
      }
    }
    case arcDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS_FAILED:
    case arcDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS_FAILED:
    case arcDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    case customTreeActions.actionsTypes.LOAD_NODES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case arcDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS:
    case arcDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case arcDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE_FAILED: {
      return {
        ...state,
        isLoading: false,
        locationsError: action.payload.message,
      }
    }
    case arcDeviceDetailsActions.actionTypes.RESET_ERROR:
      return {
        ...state,
        error: '',
      }
    case arcDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS_SUCCEEDED:
      const { data } = action.payload

      return {
        ...state,
        isLoading: false,
        deviceDetails: {
          ...state.deviceDetails,
          workedMilliseconds: data['cycles.completed.time.total'],
          bulbMaxTime: data.model.device.bulb.lifecycleInHours,
        },
      }
    case sharedActions.CUMULATIVE_USAGE_SUCCEEDED.getType(): {
      const { cumulativeUsageInSecs } = action.payload
      return {
        ...state,
        cumulativeUsageInSecs,
      }
    }
    default:
      return state
  }
}
