import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'
import actions from '../../services/dashboard-actions'
import styles from './style'
import DeviceFilterModal from './DeviceFilterModal'
import { DEVICE_LABELS } from '../../services/dashboard-constants'
import { createDeviceLabel } from '../../services/dashboard-service'

const DeviceFilterComponent = withStyles(styles)((props) => {
  const {
    startPollingModification,
    stopPollingModification,
    selectedDevices,
    devices,
    isAllDevicesSelected,
    devicesTypesFilter,
    isInitialState,
  } = props
  const [open, setOpen] = useState(false)
  const label = createDeviceLabel(
    devices,
    selectedDevices,
    isAllDevicesSelected
  )

  const handleClickOpen = () => {
    if (!isInitialState) {
      setOpen(true)
      stopPollingModification()
    }
  }

  const handleClose = () => {
    setOpen(false)
    startPollingModification()
  }

  return (
    !isEmpty(devicesTypesFilter) && (
      <>
        <Chip
          onClick={handleClickOpen}
          label={`${DEVICE_LABELS.device}: ${label}`}
          variant="outlined"
        />
        <DeviceFilterModal isOpen={open} onClose={handleClose} />
      </>
    )
  )
})

const mapStateToProps = (state) => ({
  devicesTypesFilter: state.dashboardReducer.devicesTypesFilter,
  selectedDevices: state.dashboardReducer.deviceFilter.selectedDevices,
  devices: state.dashboardReducer.devices,
  isAllDevicesSelected:
    state.dashboardReducer.deviceFilter.isAllDevicesSelected,
  isInitialState: state.dashboardReducer.isInitialState,
})

const mapDispatchToProps = (dispatch) => {
  return {
    startPollingModification: () =>
      dispatch(actions.startPollingModification()),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceFilterComponent)
