import { MAX_NAME_LENGTH } from '../../../services/constants'
import {
  LOCATIONS_CONST,
  LOCATIONS_LABELS_MULTIPLE,
  PROPERTY_NAME,
} from './location-constants'
import {
  compact,
  concat,
  map,
  sortedIndexBy,
  split,
  startCase,
  filter,
  sortBy,
  forEach,
  findIndex,
  join,
  uniqBy,
  differenceBy,
  trim,
  indexOf,
  cloneDeep,
  isNull,
} from 'lodash'

const prepareUrl = (pathname) => {
  const url = pathname.replace('/locations', '')
  const lastIndex = url.lastIndexOf('/')

  return url.slice(0, lastIndex)
}

const getNewLocationLabel = (location) => `+ Add New ${location}`
const getNewChildLocationLabel = (location, isMobile) =>
  isMobile ? `+ ${location}` : `+ Add ${location}`
const getLocationInputLabel = (location) => `${location} Name`

const getAddLocationLabel = (location, isBulk) =>
  `+ Add ${isBulk ? LOCATIONS_LABELS_MULTIPLE[location] : startCase(location)}`

const getUpdateLocationLabel = (location) => `Update ${location}`

const getEditTitle = (name) => `Edit ${name}`

const urlDepends = {
  [LOCATIONS_CONST.LOCATIONS]: LOCATIONS_CONST.PROPERTY,
  [LOCATIONS_CONST.PROPERTY]: LOCATIONS_CONST.BUILDING,
  [LOCATIONS_CONST.BUILDING]: LOCATIONS_CONST.ZONE,
  [LOCATIONS_CONST.ZONE]: LOCATIONS_CONST.ROOM,
  [LOCATIONS_CONST.ROOM]: LOCATIONS_CONST.PLACEMENT,
}

const deeps = ['area', 'property', 'building', 'zone', 'room', 'placement']

const prepareLocationsTree = (tree) => {
  tree.path = [
    {
      name: tree.name,
      pathname: '/locations',
      id: null,
    },
  ]

  const nodeList = concat([], tree.children)

  let traverse = function (current, path, timeout) {
    map(current, (item) => {
      item.isParent = item.children && !!item.children.length

      let newPath = concat([], path)

      const pathItem = {
        ...item,
        pathname: `${path[path.length - 1].pathname}/${item.locationType}-${
          item.id
        }`,
      }

      deleteProps(pathItem)

      newPath.push(pathItem)

      if (!item.timeout) {
        item.timeout = timeout
      }

      item.path = newPath

      if (item.isParent) {
        traverse(item.children, newPath, item.timeout)
      }
    })
  }

  traverse(nodeList, tree.path, tree.timeout)

  tree.children = nodeList

  return tree
}

const findNode = (currentNode, deep, locations, id) => {
  const nodeList = concat([], locations.children)
  let nodeFound = false

  let traverse = function (current) {
    map(current, (item, key) => {
      if (item.locationType === deep && +item.id === +id) {
        currentNode.list = concat([], item.children)
        currentNode.path = concat([], item.path)

        nodeFound = true
      } else if (item.isParent && !nodeFound) {
        traverse(item.children)
      }
    })
  }

  traverse(nodeList)

  return currentNode
}

const getNodeTypeById = (locations, id) => {
  const nodeList = concat([], locations.children)
  let nodeFound = false
  let type = ''

  let traverse = function (current) {
    map(current, (item) => {
      if (+item.id === +id) {
        type = item.locationType

        nodeFound = true
      } else if (item.children && item.children.length && !nodeFound) {
        traverse(item.children)
      }
    })
  }

  traverse(nodeList)

  return type
}

const getCurrentLocation = (path) => {
  const pathParams = path.split('/')
  const currentLocation = pathParams[pathParams.length - 1]

  if (currentLocation.indexOf('-') !== -1) {
    return currentLocation.split('-')
  }

  return ['locations', null]
}

const getCurrentNode = (path, locations) => {
  const locationCopy = cloneDeep(locations)
  let currentNode = {
    list: locationCopy.children,
    path: locationCopy.path,
  }

  const pathParams = path.split('/')
  const currentLocation = pathParams[pathParams.length - 1]

  if (currentLocation.indexOf('-') !== -1) {
    let [deep, id] = currentLocation.split('-')

    findNode(currentNode, deep, locationCopy, id)
  }

  return currentNode
}

const getCurrentLocPathForBulk = (pathname, parentId, parentType) => {
  const pathParams = pathname.split('/')
  const currentLocation = pathParams[pathParams.length - 1]

  if (currentLocation.indexOf('-') !== -1 || parentId) {
    let [deep, id] = currentLocation.split('-')

    return +id !== +parentId && deep !== 'room'
      ? `${pathname}/${parentType}-${parentId}`
      : pathname
  }

  return pathname
}

const bulkAddLocations = (tree, locations) => {
  let nodeList = concat([], tree.children)
  let isLocAdded = false
  const defaultTimeout = +tree.timeout

  if (!locations[0].parentId) {
    let newPath = [...tree.path]

    const childs = map(locations, (item) => {
      const path = {
        ...item,
        pathname: `/locations/property-${item.id}`,
      }

      return {
        ...item,
        children: [],
        path: [...newPath, path],
        deep: LOCATIONS_CONST.PROPERTY,
        timeout: defaultTimeout,
        isParent: false,
      }
    })

    const updatedList = [...nodeList, ...childs]
    nodeList = sortBy(updatedList, ['name'])
  } else {
    let traverse = function (current) {
      forEach(current, (item) => {
        if (item.id === locations[0].parentId) {
          let newPath = [...item.path]

          const childs = map(locations, (loc) => {
            const path = {
              ...loc,
              pathname: `${newPath[newPath.length - 1].pathname}/${
                loc.locationType
              }-${loc.id}`,
            }

            isLocAdded = true

            return {
              ...loc,
              children: [],
              path: concat(newPath, path),
              deep: loc.locationType,
              timeout: !item.isParent ? item.timeout : defaultTimeout,
              isParent: false,
            }
          })

          item.isParent = true

          const updatedList = [...item.children, ...childs]
          item.children = sortBy(updatedList, ['name'])
        } else if (item.isParent && !isLocAdded) {
          traverse(item.children)
        }
      })
    }

    traverse(nodeList)
  }

  tree.children = nodeList

  return tree
}

const addLocation = (tree, params) => {
  let nodeList = concat([], tree.children)
  const { parentId, id } = params
  let isLocAdded = false
  const defaultTimeout = tree.timeout

  if (!parentId) {
    let newPath = concat([], tree.path)

    newPath.push({
      ...params,
      pathname: `/locations/property-${id}`,
    })

    const value = {
      ...params,
      children: [],
      path: newPath,
      timeout: defaultTimeout,
      isParent: false,
    }
    const index = sortedIndexBy(nodeList, value, 'name')

    nodeList.splice(index, 0, value)
  } else {
    let traverse = function (current) {
      map(current, (item) => {
        if (item.id === parentId) {
          let newPath = concat([], item.path)

          newPath.push({
            ...params,
            pathname: `${newPath[newPath.length - 1].pathname}/${
              params.locationType
            }-${id}`,
          })

          isLocAdded = true

          const value = {
            ...params,
            children: [],
            path: newPath,
            timeout: !item.isParent ? item.timeout : defaultTimeout,
            isParent: false,
          }

          item.isParent = true

          const index = sortedIndexBy(item.children, value, 'name')

          item.children.splice(index, 0, value)
        } else if (item.isParent && !isLocAdded) {
          traverse(item.children)
        }
      })
    }

    traverse(nodeList)
  }

  tree.children = nodeList

  return tree
}

const deleteLocation = (tree, params) => {
  const nodeList = concat([], tree.children)
  const { id, parentId } = params
  let isLocDeleted = false
  const defaultTimeout = tree.timeout

  let traverse = function (current) {
    map(current, (item, key) => {
      if (!isLocDeleted) {
        if (parentId) {
          // delete child location
          if (+item.id === +parentId) {
            isLocDeleted = true

            item.children = item.children.filter((child) => {
              return child.id !== id
            })

            if (!item.children.length) {
              item.isParent = false
              item.timeout = defaultTimeout
            }
          } else if (item.isParent && !isLocDeleted) {
            traverse(item.children)
          }
        } else {
          // delete property
          if (+item.id === +id) {
            isLocDeleted = true
            nodeList.splice(key, 1)
          }
        }
      }
    })
  }

  traverse(nodeList)
  tree.children = nodeList

  return tree
}

const editLocation = (tree, params) => {
  const nodeList = concat([], tree.children)
  let isLocEdited = false
  const defaultTimeout = tree.timeout

  let updateChildrenPath = (children, pathIndex, location) => {
    const childrenStack = children.slice()
    while (childrenStack.length) {
      const child = childrenStack.pop()
      if (child.isParent) {
        childrenStack.push(...child.children)
      }
      child.path[pathIndex] = location
    }
  }

  let traverse = function (current) {
    map(current, (item, index) => {
      if (!isLocEdited) {
        if (item.id === params.id) {
          isLocEdited = true

          const location = {
            ...item,
            ...params,
            timeout: params.timeout || defaultTimeout,
          }

          const pathIndex = item.path.length - 1
          if (params.name) {
            location.path[pathIndex] = {
              ...location.path[item.path.length - 1],
              ...params,
            }

            current.splice(index, 1)

            updateChildrenPath(
              item.children,
              pathIndex,
              location.path[pathIndex]
            )
          }

          const neededIndex = sortedIndexBy(current, location, 'name')
          current.splice(neededIndex, 0, location)
        } else if (item.isParent) {
          traverse(item.children)
        }
      }
    })
  }

  traverse(nodeList)
  tree.children = nodeList

  return tree
}

const validateLocationName = (name, isBulk) => {
  if (name.match(PROPERTY_NAME.notAllowedSymbols)) {
    return PROPERTY_NAME.helperText
  }

  if (isBulk) {
    const splitted = split(name, '\n')
    const index = findIndex(splitted, (item) => item.length > MAX_NAME_LENGTH)

    return index === -1 ? '' : splitted[index] + PROPERTY_NAME.lengthExceeds
  } else if (name.length > MAX_NAME_LENGTH) {
    return PROPERTY_NAME.lengthExceeds
  }

  return ''
}

const updateTree = (tree, params, action) => {
  let newTree = cloneDeep(tree)

  switch (action) {
    case 'add':
      newTree = addLocation(tree, params)
      break
    case 'delete':
      newTree = deleteLocation(tree, params)
      break
    case 'edit':
      newTree = editLocation(tree, params)
      break
    case 'bulk':
      newTree = bulkAddLocations(tree, params)
      break
    default:
      return newTree
  }

  return newTree
}

const wrapInSquareBrackets = (text) => {
  return '[' + text + ']'
}

const searchIdsList = (location, tree) => {
  const nodeList = concat([], tree.children)
  const { id } = location
  let locationIsSearched = false

  let result = []

  let traverse = function (current) {
    for (let i = 0; i < current.length; i++) {
      if (!locationIsSearched) {
        const item = current[i]

        if (item.id === id) {
          result.push(item.id)
          locationIsSearched = true

          result = result.concat(saveAllChildrenIds(item.children))

          break
        } else {
          traverse(item.children)
        }
      }
    }
  }

  traverse(nodeList)

  return result.join(', ')
}

const saveAllChildrenIds = (tree) => {
  let result = []

  const traverse = (tree) => {
    forEach(tree, (item) => {
      if (item.children) {
        traverse(item.children)
      }
      result.push(item.id)
    })
  }

  traverse(tree)

  return result
}

const prepareDevices = (devices) => {
  return (
    devices.filter((device) => device.allowedLocationIds.length === 1) || []
  )
}

const prepareUsers = (users, id) => {
  return filter(users, (user) => user.allowedProperties?.includes(id)) || []
}

const prepareBulkLocations = (body) => {
  const { value, parentId, locationType } = body
  const splattedArrays = compact(split(value, '\n'))

  return map(splattedArrays, (item) => {
    return {
      parentId,
      locationType,
      name: trim(item),
    }
  })
}

const getLocationsFromString = (text) => compact(split(text, '\n'))

const prepareLocationsForValidation = (text) => {
  const splattedArrays = getLocationsFromString(text)

  return join(splattedArrays, ',')
}

const getWarningText = (locations, isMultiple) =>
  isMultiple
    ? `Locations named "${locations}" were found. Do you wish to proceed?`
    : `A location with the name "${locations}" was found. Do you wish to proceed?`

const getLocationsNames = (locations) => {
  const names = map(locations, (item) => item.name)

  return getWarningText(join(names, ','), !!names.length)
}

const checkNameCase = (text) => {
  const splattedArrays = getLocationsFromString(text)
  const uniqArr = uniqBy(splattedArrays, (item) => item.toLowerCase())

  if (uniqArr.length === splattedArrays.length) {
    return []
  }

  return differenceBy(splattedArrays, uniqArr)
}

const getWarningUIValidationText = (locations) => {
  const locationString = join(locations, ',')

  return `You are trying to create the following locations "${locationString}" with the same name but different capitalization.`
}

const deleteProps = (item) => {
  delete item.children
  delete item.isParent
  delete item.path
}

const prepareFieldsForRoom = (item, name, roomFields) => ({
  id: item.id,
  parentId: item.parentId,
  locationType: item.locationType,
  name,
  baselineAch: roomFields ? roomFields.baselineAch : item.baselineAch,
  improvedEAch: roomFields ? roomFields.improvedEAch : item.improvedEAch,
})

const prepareGeneralLocationFields = (item, name) => ({
  id: item.id,
  parentId: item.parentId,
  locationType: item.locationType,
  name,
})

const generateLocationObj = (item, name, roomFields) => {
  switch (item.locationType) {
    case LOCATIONS_CONST.ROOM:
      return prepareFieldsForRoom(item, name, roomFields)
    default:
      return prepareGeneralLocationFields(item, name)
  }
}

// const updateLocationObj = (item, minutes, disinfectSpaceDaily) => {
//     const updatedLoc = {
//         ...item,
//         timeout: minutes,
//         disinfectSpaceDaily: disinfectSpaceDaily
//     };
//
//     return updatedLoc;
// }

const getPossibleChildren = (schema, node) => {
  const children = []

  forEach(schema, (item) => {
    const isProperty =
      isNull(item.possibleParents) && node === LOCATIONS_CONST.LOCATIONS

    if (isProperty || indexOf(item.possibleParents, node) !== -1) {
      children.push(item.locationType)
    }
  })

  return children
}

export {
  urlDepends,
  deeps,
  prepareUrl,
  getNewLocationLabel,
  getAddLocationLabel,
  getUpdateLocationLabel,
  getEditTitle,
  prepareLocationsTree,
  getCurrentNode,
  getCurrentLocation,
  updateTree,
  wrapInSquareBrackets,
  searchIdsList,
  prepareDevices,
  prepareUsers,
  validateLocationName,
  prepareBulkLocations,
  getCurrentLocPathForBulk,
  prepareLocationsForValidation,
  getLocationsNames,
  checkNameCase,
  getWarningUIValidationText,
  generateLocationObj,
  getLocationInputLabel,
  getPossibleChildren,
  getNewChildLocationLabel,
  getNodeTypeById,
}
