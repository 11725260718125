import { COLORS } from '../../../../../services/constants'

const style = () => ({
  devicesPageWrapper: {
    width: '80%',
    padding: '50px',
    margin: 'auto',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '15px',
  },
  addDeviceContainer: {
    marginBottom: '20px',
  },
  locationField: {
    width: '100%',
    display: 'grid',
    borderRadius: '26px',
    paddingLeft: '15px',
    justifyContent: 'left',
    height: '56px',
    margin: '10px 0',
    fontSize: '16px',
    lineHeight: '19px',
    color: COLORS.GRAY,
  },
  locationError: {
    borderColor: COLORS.RED_ORANGE,
    color: COLORS.RED_ORANGE,
  },
})

export default style
