import { COLORS } from '../../../../../../services/constants'

const style = () => ({
  progressNumber: {
    fontFamily: 'Cerebri Sans Pro Bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    minHeight: '60px',
    height: '60px',
    textAlign: 'center',
    color: COLORS.YELLOW,
  },
  cycleName: {
    fontSize: '10px',
    lineHeight: '10px',
  },
  circularLabel: {
    fontSize: '35px',
    lineHeight: '40px',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  '@media (min-width: 1224px)': {
    progressNumber: {
      width: 'fit-content',
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderRight: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
      minWidth: '135px',
      height: '100%',
    },
    cycleName: {
      fontSize: '12px',
      lineHeight: '14px',
      fontFamily: 'Cerebri Sans Pro Regular',
    },
    circularLabel: {
      fontSize: '17px',
      lineHeight: '20px',
    },
  },
})

export default style
