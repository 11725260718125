import React, { useState } from 'react'
import SvgIconComponent from '../../../../components/_shared/SvgIconComponent'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles/index'
import style from './style'
import DownloadModal from './DownloadModal'
import actions from '../../services/dashboard-actions'
import { DOWNLOAD_MODAL } from '../../services/dashboard-constants'
import { connect } from 'react-redux'

const OpenDownloadModalButton = (props) => {
  const {
    downloadCSV,
    downloadReport,
    isInitialState,
    startPollingModification,
    stopPollingModification,
    classes,
  } = props
  const [open, setOpen] = useState(false)
  const [selectedChip, setSelectedChip] = useState(null)

  const handleClickOpen = () => {
    if (!isInitialState) {
      setOpen(true)
      stopPollingModification()
    }
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedChip(null)

    startPollingModification()
  }

  const onSubmit = () => {
    if (selectedChip === DOWNLOAD_MODAL.report) {
      downloadReport()
    } else if (selectedChip === DOWNLOAD_MODAL.sourceData) {
      downloadCSV()
    }
  }

  return (
    <>
      <IconButton onClick={handleClickOpen} className={classes.openMenuButton}>
        <SvgIconComponent name="download" size="19" />
      </IconButton>
      <DownloadModal
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
        isOpen={open}
        onCloseModal={handleClose}
        onSubmit={onSubmit}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  downloadCSV: () => dispatch(actions.downloadCSV()),
  downloadReport: () => dispatch(actions.downloadReport()),
  startPollingModification: () => dispatch(actions.startPollingModification()),
  stopPollingModification: () => dispatch(actions.stopPollingModification()),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(style)(OpenDownloadModalButton))
