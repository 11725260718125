import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { find, map, uniqueId } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import LocationCard from './components/LocationCard'
import actions from './service/location-actions'
import { IDS_FOR_FEATURE_TOGGLING } from '../../services/constants'
import { checkConnection } from '../../services/root/root-service'
import classnames from 'classnames'

const LocationCardsContainerComponent = (props) => {
  const {
    addNewChildLocation,
    locations,
    currentLoc,
    history,
    pathname,
    isReadOnly,
    features,
    isBannerPresent,
    classes,
  } = props
  const [enableSetCycle, setEnableSetCycle] = useState(null)

  const locationCardsContainer = classnames(
    classes.locationCardsContainer,
    isBannerPresent ? classes.locationCardsContainerBannerOffset : ''
  )

  useEffect(() => {
    if (features && features.length) {
      const featureCycleTime = find(
        features,
        (item) => item.id === IDS_FOR_FEATURE_TOGGLING.cycleTime
      )

      setEnableSetCycle(featureCycleTime && featureCycleTime.enabled)
    }
  }, [features])

  const onNextLevel = (location) => {
    const url = `${pathname}/${location.locationType}-${location.id}`

    history.push(url)
  }

  const onAddChildLocation = (body) => {
    addNewChildLocation(body, history)
  }

  return (
    <div className={locationCardsContainer}>
      {map(locations, (item) => {
        return (
          <LocationCard
            onNext={onNextLevel}
            onAddLocation={onAddChildLocation}
            key={uniqueId('location-')}
            item={item}
            isReadOnly={isReadOnly}
            currentLoc={currentLoc}
            enableSetCycle={enableSetCycle}
            history={history}
          />
        )
      })}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewChildLocation: (body, history) =>
      dispatch(
        checkConnection(() => actions.addNewChildLocation(body, history))
      ),
  }
}

const mapStateToProps = (state) => ({
  locations: state.locationReducer.currentNode,
  currentLoc: state.locationReducer.currentLoc,
  features: state.rootReducer.features,
  isBannerPresent: state.rootReducer.isBannerPresent,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LocationCardsContainerComponent))
