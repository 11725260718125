import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import styles from '../style'
import CircularProgressWithLabel from '../../../../../../components/_shared/circularProgress/CircularProgressWithLabel'
import { withMediaQuery } from '../../../../../../services/root/root-service'
import { getRemainingTime } from '../../../../services/related/transaction-service'
import { PROGRESS_LABELS } from '../../../../services/dashboard-constants'

const ProgressComponent = withStyles(styles)((props) => {
  const {
    classes,
    isCircular,
    progressValue = 0,
    progressLabel,
    color,
    isMobile,
  } = props

  return (
    <>
      {isCircular ? (
        <CircularProgressWithLabel
          circlecolor={color}
          size={'48px'}
          fontSize={'12px'}
          thickness={3}
          value={progressValue}
        />
      ) : (
        <div className={classes.progressNumber}>
          <Typography className={classes.cycleName} color="textSecondary">
            {`${PROGRESS_LABELS.preCycle} ${progressLabel}`}
          </Typography>
          <span className={classes.circularLabel} style={{ color }}>
            {getRemainingTime(progressValue, isMobile)}
          </span>
        </div>
      )}
    </>
  )
})

export default withMediaQuery(ProgressComponent)
