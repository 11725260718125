import { COLORS } from '../../../../services/constants'

const style = () => ({
  buttonWrapper: {
    minWidth: '40%',
    margin: '15px auto 20px',
  },
  assignCycleTimeContainer: {
    margin: '18px 0',
  },
  iconWrapper: {
    margin: 'auto',
  },
  timerButton: {
    color: COLORS.BLACK,
    padding: 0,
  },
  modalTitle: {
    textAlign: 'center',
    marginTop: '14px',
    marginBottom: '40px ',
    paddingLeft: '16px',
  },
  modalTitleContainer: {
    paddingBottom: 0,
    fontSize: 'inherit',
  },
  dialogContent: {
    paddingBottom: '35px',
  },
  submitButton: {
    marginTop: '20px',
  },
  checkbox: {
    '& svg': {
      width: '15px',
      height: '15px',
    },
  },
  disinfectCheckbox: {
    marginTop: '10px',
  },
  checkboxLabel: {
    lineHeight: '14px',
    display: 'flex',
    paddingLeft: '10px',
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '16px',
    '& span': {
      fontSize: '12px',
    },
  },
  input: {
    width: '70px',
    margin: '0 10px',
  },
  subtitle: {
    marginTop: '10px',
  },
  iconButton: {
    color: COLORS.BLUE,
    border: `1px solid ${COLORS.LIGHT_SILVER}`,
  },
  secondParagraph: {
    margin: '20px 0',
  },
  assignCycleTimeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media (min-width: 1224px)': {
    timerButton: {
      height: '30px',
      width: '30px',
    },
    dialogActions: {
      padding: '16px 70px 40px',
    },
    submitButton: {
      width: '100%',
    },
    dialogContent: {
      padding: '16px 70px 35px',
    },
    checkboxLabel: {
      '& span': {
        fontSize: '16px',
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
    checkbox: {
      '& svg': {
        width: '18px',
        height: '18px',
      },
    },
  },
})

export default style
