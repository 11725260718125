import { COLORS } from '../../../../../services/constants'

const style = () => ({
  filtersContainer: {
    margin: '20px 0',
    display: 'flex',
  },
  '@media (min-width: 1224px)': {
    filtersContainer: {
      margin: '10px 0',
      justifyContent: 'center',
    },
    filtersContainerWithoutScroll: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeScrollFiltersContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'sticky',
      top: 0,
      boxShadow: `0 2px 4px 0 ${COLORS.GRAY_ZIRCON}`,
      backgroundColor: COLORS.LIGHT_GRAY,
      padding: '0 27%',
      zIndex: 1,
    },
  },
})

export default style
