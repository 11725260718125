import { combineReducers } from 'redux'
import { infoTreeReducer } from '../components/_shared/infoTree/services/infoTree-reducer'
import { dashboardReducer } from '../containers/Dashboard/services/dashboard-reducers'
import { locationReducer } from '../containers/Locations/service/location-reducer'
import { customTreeReducer } from '../components/_shared/customTree/services/customTree-reducer'
import { accountReducer } from '../containers/Account/services/account-reducers'
import { authorizationReducer } from '../containers/LogIn/services/authorization-reducer'
import { summaryReducer } from '../containers/Summary/_shared/services/reducers'
import { usersReducer } from '../containers/Users/services/users-reducer'
import { rootReducer } from '../services/root/root-reducer'
import { devicesReducer } from '../containers/Devices/_shared/services/reducers'

export default combineReducers({
  dashboardReducer,
  locationReducer,
  customTreeReducer,
  accountReducer,
  usersReducer,
  rootReducer,
  authorizationReducer,
  infoTreeReducer,
  summaryReducer,
  devicesReducer,
})
