const ORGANIZATION_INFO_KEYS = {
  phoneExtension: 'phoneExtension',
  secondaryPhone: 'secondaryPhone',
  name: 'name',
  address: 'address',
  state: 'state',
  city: 'city',
  zip: 'zip',
}

const NOT_REQUIRED_ORGANIZATION_FIELDS = [
  ORGANIZATION_INFO_KEYS.phoneExtension,
  ORGANIZATION_INFO_KEYS.secondaryPhone,
]

const ORGANIZATION_INFO_LABELS = {
  [ORGANIZATION_INFO_KEYS.phoneExtension]: 'Ext',
  [ORGANIZATION_INFO_KEYS.secondaryPhone]: 'Secondary Phone',
  [ORGANIZATION_INFO_KEYS.name]: 'Organization Name',
  [ORGANIZATION_INFO_KEYS.address]: 'Address',
  [ORGANIZATION_INFO_KEYS.state]: 'State',
  [ORGANIZATION_INFO_KEYS.city]: 'City',
  [ORGANIZATION_INFO_KEYS.zip]: 'Zip',
}

const ACCOUNT_INFO_TITLE_LABEL = 'Account Information'

const ACCOUNT_INFO_DOCUMENT_TITLE = 'Account | R-Zero'

const USER_INFO_KEYS = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phoneNumber: 'phoneNumber',
  phoneExtension: 'phoneExtension',
  businessTitle: 'businessTitle',
}

const NOT_REQUIRED_USER_FIELDS = [
  USER_INFO_KEYS.phoneNumber,
  USER_INFO_KEYS.phoneExtension,
  USER_INFO_KEYS.email,
  USER_INFO_KEYS.businessTitle,
]

const USER_INFO_LABELS = {
  [USER_INFO_KEYS.firstName]: 'First Name',
  [USER_INFO_KEYS.lastName]: 'Last Name',
  [USER_INFO_KEYS.email]: 'Email',
  [USER_INFO_KEYS.businessTitle]: 'Business title',
  [USER_INFO_KEYS.phoneNumber]: 'Business Phone',
  [USER_INFO_KEYS.phoneExtension]: 'Phone Extension',
}

const OTHER_LABELS = {
  confirm: 'Done',
  changeOwner: 'Change Account Owner',
  idpData: 'Login with Own Identity Provider',
}

const CHANGES_SAVED_LABEL = 'Changes saved'

const PASSWORD_CONFIRMATION_FIELDS = {
  email: 'email',
  temporaryPassword: 'temporaryPassword',
  password: 'password',
}

const PASSWORD_CONFIRMATION_LABELS = {
  title: 'Confirm account password',
  email: 'Email',
  temporaryPassword: 'Temporary password',
  password: 'Password',
  confirmNewPassword: 'Confirm',
}

const initialStateOrganization = {
  phoneExtension: '',
  secondaryPhone: '',
  name: '',
  address: '',
  state: '',
  city: '',
  zip: '',
}

const initialStateUser = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneExtension: '',
  businessTitle: '',
}

const CHANGE_ACCOUNT_OWNER_LABELS = {
  page: 'Change Account Owner',
  firstParagraph:
    'Transferring account ownership will change the primary contact for your organization.',
  secondParagraph:
    'This changes your role to “Admin”, and you will need to log back in to access the dashboard.',
  thirdParagraph:
    'This action can only be undone by the R-Zero support team or the new Account Owner.',
  customSelectLabel: 'New Account Owner',
  createNewUser: 'Create New User ->',
  saveChanges: 'Save Changes',
}

const CONFIRM_MODAL_LABELS = {
  modalTitle: 'Change Account Owner',
  actionLabel: 'Confirm',
  closeLabel: 'Cancel',
}

const EXT = 'Ext'

const TIMEZONE_PROMPT_LABELS = {
  title: 'Confirm Time Zone',
  placeholder: 'Select Time Zone',
  action: 'Confirm',
}

const TIMEZONE_LABELS = {
  radioLabel: 'Time Zone',
  account: 'Use Account Time Zone',
  local: 'Use Local Time Zone',
}

const ZIP_ERROR = 'Enter valid zipcode'

const ORGANIZATION_ALREADY_EXISTS = 'This organization already exists.'

const VIEW_ASSIGNED_PROPERTIES_LABELS = {
  viewAssignedProperties: 'View Assigned Properties',
  myProperties: 'My Properties',
  assignedLocations: 'Assigned Locations',
}

const API_KEY_LABELS = {
  apiKey: 'API Key',
  regenerate: 'Regenerate',
  modalTittle: 'Regenerate API Key',
  message:
    'Creating a new API key will invalidate the existing one. Any current integrations will stop working.',
  message2: 'This action cannot be undone.',
  confirm: 'Confirm',
  copied: 'Copied!',
  manageApiKeys: 'Manage API Keys',
  backTo: 'Back to Accounts Settings',
  backToDesktop: '← Back to Accounts Settings',
}

export {
  ORGANIZATION_INFO_KEYS,
  NOT_REQUIRED_ORGANIZATION_FIELDS,
  ORGANIZATION_INFO_LABELS,
  USER_INFO_KEYS,
  NOT_REQUIRED_USER_FIELDS,
  USER_INFO_LABELS,
  ACCOUNT_INFO_TITLE_LABEL,
  ACCOUNT_INFO_DOCUMENT_TITLE,
  OTHER_LABELS,
  CHANGES_SAVED_LABEL,
  PASSWORD_CONFIRMATION_LABELS,
  PASSWORD_CONFIRMATION_FIELDS,
  CHANGE_ACCOUNT_OWNER_LABELS,
  CONFIRM_MODAL_LABELS,
  EXT,
  initialStateOrganization,
  initialStateUser,
  TIMEZONE_PROMPT_LABELS,
  ZIP_ERROR,
  ORGANIZATION_ALREADY_EXISTS,
  TIMEZONE_LABELS,
  VIEW_ASSIGNED_PROPERTIES_LABELS,
  API_KEY_LABELS,
}
