import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  navigationActionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  editActions: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 'auto',
    bottom: '20px',
    width: '86%',
    alignItems: 'center',
  },
  actionButton: {
    padding: '6px 30px',
    marginBottom: '30px',
  },
  editPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  navigationBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
  navLink: {
    fontSize: '10px',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  currentLocationContainer: {
    display: 'flex',
    paddingBottom: '5px',
  },
  rowIconButton: {
    width: '17px',
    height: '17px',
    color: COLORS.BLACK,
  },
  currentLocName: {
    width: 'calc(100% - 30px)',
  },
  editTitle: {
    marginBottom: '25px',
  },
  navigationPathWrapper: {
    display: 'flex',
  },
  locationNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  '@media (min-width: 1224px)': {
    currentLocName: {
      width: 'auto',
    },
    locationNameWrapper: {
      width: '500px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    currentLocationContainer: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginLeft: '34px',
      '& h2': {
        textAlign: 'center',
      },
    },
    navigationPathWrapper: {
      width: '500px',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginRight: '20px',
      marginBottom: '10px',
    },
    rowIconButton: {
      width: '30px',
      height: '30px',
    },
    navigationBarContainer: {
      width: '79%',
      paddingBottom: 0,
    },
    backIcon: {
      width: '16px',
      height: '16px',
      marginRight: '5px',
      marginBottom: '2px',
    },
    navLink: {
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      lineHeight: '20px',
      fontSize: '16px',
      color: COLORS.BLACK,
    },
    backToNavigation: {
      marginTop: '15px',
      minHeight: '40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    editActions: {
      position: 'inherit',
      width: '70%',
      margin: 'auto',
    },
    actionButton: {
      width: '100%',
      marginBottom: '25px',
    },
    deleteButton: {
      backgroundColor: COLORS.COLD_GRAY,
      marginBottom: '40px',
    },
    inputField: {
      width: '70%',
      margin: 'auto',
      marginBottom: '30px',
    },
    editTitle: {
      margin: 'auto',
      marginTop: '30px',
      fontSize: '16px',
      marginBottom: '35px',
    },
    outlined: {
      [theme.breakpoints.up('lg')]: {
        padding: '15.5px 15px',
      },
    },
    notEditMode: {
      marginRight: '40px',
    },
  },
  deleteButton: {
    marginTop: '20px',
  },
})

export default styles
