import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { DOWNLOAD_LABELS } from '../services/dashboard-constants'
import styles from './styles'
import actions from '../services/dashboard-actions'
import SvgIconComponent from '../../../components/_shared/SvgIconComponent'
import IconButton from '@material-ui/core/IconButton'
import { withMediaQuery } from '../../../services/root/root-service'
import Link from '@material-ui/core/Link'
import GetAppIcon from '@material-ui/icons/GetApp'
import Loader from '../../../components/_shared/loader/Loader'
import { gaEventAction } from '../../../services/googleAnalytics/ga-actions'

const DownloadCSVButton = (props) => {
  const {
    downloadCSV,
    downloadReport,
    isDownloadLoading,
    isMobile,
    classes,
    gaEventSend,
  } = props

  const onDownload = () => {
    downloadCSV()
    gaEventSend('report_download', { event_label: 'Source Data' })
  }

  const onDownloadReport = () => {
    downloadReport()
    gaEventSend('report_download', { event_label: 'Report' })
  }

  return (
    <>
      <Loader isLoading={isDownloadLoading} fullScreen />
      {isMobile ? (
        <>
          <IconButton onClick={onDownloadReport}>
            <GetAppIcon color="primary" name="download" size="19" />
          </IconButton>
          <IconButton onClick={onDownload}>
            <SvgIconComponent name="download" size="19" />
          </IconButton>
        </>
      ) : (
        <div className={classes.downloadGroup}>
          <Link
            variant="h6"
            component="div"
            underline="none"
            onClick={onDownloadReport}
            className={classes.link}
          >
            <GetAppIcon className={classes.getApp} />
            <span className={classes.linkName}>
              {DOWNLOAD_LABELS.downloadReport}
            </span>
          </Link>
          <Link
            variant="h6"
            component="div"
            underline="none"
            onClick={onDownload}
            className={classes.link}
          >
            <GetAppIcon className={classes.getApp} />
            <span className={classes.linkName}>
              {DOWNLOAD_LABELS.downloadCSV}
            </span>
          </Link>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  isDownloadLoading: state.dashboardReducer.isDownloadLoading,
})

const mapDispatchToProps = (dispatch) => ({
  downloadCSV: () => dispatch(actions.downloadCSV()),
  downloadReport: () => dispatch(actions.downloadReport()),
  gaEventSend: gaEventAction(dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(DownloadCSVButton)))
