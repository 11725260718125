import { Radio, RadioGroup } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import {
  initialStateOrganization,
  ORGANIZATION_INFO_KEYS,
  ORGANIZATION_INFO_LABELS,
  TIMEZONE_LABELS,
  ZIP_ERROR,
} from '../../services/account-constants'
import { states, USER_TIMEZONE } from '../../services/account-service'
import styles from './style'
import { map } from 'lodash'

const AccountAddress = (props) => {
  const {
    onChangeOrganization,
    onChangeZip,
    organization,
    isReadOnly,
    timezones,
    errors,
    classes,
    abbreviation,
    onChangeTimezoneSettings,
    timezoneSettings,
  } = props
  const [value, setValue] = useState(
    timezoneSettings ? USER_TIMEZONE.ACCOUNT : USER_TIMEZONE.ACCOUNT
  )

  useEffect(() => {
    setValue(timezoneSettings ? USER_TIMEZONE.LOCAL : USER_TIMEZONE.ACCOUNT)
  }, [timezoneSettings])

  const handleChange = (event) => {
    onChangeTimezoneSettings(event.target.value === USER_TIMEZONE.LOCAL)
    setValue(event.target.value)
  }

  return (
    <>
      {isReadOnly ? (
        <>
          <span className={classes.buttonLegend}>
            {TIMEZONE_LABELS.radioLabel}
          </span>
          <RadioGroup value={value} onChange={handleChange}>
            <div className={classes.labelContainer}>
              <Radio value={USER_TIMEZONE.ACCOUNT} color="primary" />
              <Typography variant="subtitle1">
                {`${TIMEZONE_LABELS.account} (${abbreviation})`}
              </Typography>
            </div>
            <div className={classes.labelContainer}>
              <Radio value={USER_TIMEZONE.LOCAL} color="primary" />
              <Typography variant="subtitle1">
                {TIMEZONE_LABELS.local}
              </Typography>
            </div>
          </RadioGroup>
        </>
      ) : (
        <>
          <TextField
            name="address"
            variant="outlined"
            label={ORGANIZATION_INFO_LABELS.address}
            value={organization.address || initialStateOrganization.address}
            onChange={onChangeOrganization}
            error={errors[ORGANIZATION_INFO_KEYS.address]}
            disabled={isReadOnly}
            fullWidth
            required
          />
          <TextField
            name="city"
            variant="outlined"
            label={ORGANIZATION_INFO_LABELS.city}
            value={organization.city || initialStateOrganization.city}
            onChange={onChangeOrganization}
            error={errors[ORGANIZATION_INFO_KEYS.city]}
            disabled={isReadOnly}
            fullWidth
            required
          />
          <Box display="flex" justifyContent="space-between" alignItems="top">
            <FormControl
              fullWidth
              variant="outlined"
              error={errors[ORGANIZATION_INFO_KEYS.state]}
              className={classes.shortInput}
              disabled={isReadOnly}
              required
            >
              <InputLabel id="state">
                {ORGANIZATION_INFO_LABELS.state}
              </InputLabel>
              <Select
                name="state"
                label="state"
                value={organization.state || initialStateOrganization.state}
                onChange={onChangeOrganization}
              >
                {map(states, (item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="zip"
              variant="outlined"
              className={classes.shortInput}
              label={ORGANIZATION_INFO_LABELS.zip}
              value={organization.zip || initialStateOrganization.zip}
              onChange={onChangeZip}
              error={
                errors[ORGANIZATION_INFO_KEYS.zip] ||
                (timezones && !timezones.length)
              }
              helperText={timezones && !timezones.length ? ZIP_ERROR : ''}
              disabled={isReadOnly}
              required
            />
          </Box>
        </>
      )}
    </>
  )
}

export default withStyles(styles)(AccountAddress)
