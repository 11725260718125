import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseButton from '../closeButton/CloseButton'

const WarningModalComponent = withStyles(styles)((props) => {
  const {
    onSubmit,
    onClose,
    error,
    modalTitle = 'WARNING',
    buttonLabel,
    dialogHelperText,
    dialogHelperText2,
    variantHelperText2 = 'body2',
    isOpen,
    isWarning,
    classes,
  } = props

  return (
    <>
      <Dialog open={isOpen} keepMounted onClose={onClose} maxWidth="xs">
        <DialogTitle className={classes.modalTitleContainer} disableTypography>
          <Typography
            variant="subtitle2"
            color={isWarning ? 'secondary' : 'initial'}
            className={classes.modalTitle}
          >
            {modalTitle}
          </Typography>
          {onClose && <CloseButton onCloseModal={onClose} />}
        </DialogTitle>
        <DialogContent
          className={isWarning ? classes.helperTextWarning : classes.helperText}
        >
          {error && (
            <Typography variant="subtitle1" color="error">
              {error}
            </Typography>
          )}
          <Typography variant="body2">{dialogHelperText}</Typography>
          <br />
          <Typography variant={variantHelperText2}>
            {dialogHelperText2}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={onSubmit}
            color={isWarning ? 'secondary' : 'primary'}
            className={classes.submitButton}
            variant="contained"
          >
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default WarningModalComponent
