import {
  forEach,
  find,
  forIn,
  isEmpty,
  isObject,
  map,
  keys,
  split,
  toUpper,
  findLastKey,
} from 'lodash'
import { hhmmaFormat, utcConverter } from '../../../services/dateFormat-service'
import {
  getStorageData,
  setStorageComplicatedData,
} from '../../../services/defaultStore-sevice'
import { getLabelWithCount, isArc } from '../../../services/root/root-service'
import {
  CANCELLATION_ERRORS,
  DEVICE_LABELS,
  DEVICE_TYPE_MAPPER,
} from './dashboard-constants'
import { deeps, DEVICE_TYPE, INDEX_MAP } from '../../../services/constants'

const createDeviceLabel = (devices, selectedDevices, isAllSelected) => {
  if (isAllSelected) {
    return DEVICE_LABELS.all
  }

  if (selectedDevices.length > 1) {
    return DEVICE_LABELS.multiple
  }

  if (selectedDevices.length === 1) {
    const device = find(devices, (device) => device.id === selectedDevices[0])

    return device?.serialNumber || DEVICE_LABELS.none
  }

  return DEVICE_LABELS.none
}

const _messageStartTemplate = (time, timezone) =>
  `Interrupted - ${utcConverter(time, hhmmaFormat, timezone)}`

const getMessageText = (events, timezone) =>
  map(events, (item) => _messageStartTemplate(item, timezone))

const saveLocationPrefSettings = (key, id, locPref) => {
  let dataFromStorage = getStorageData(key)

  if (dataFromStorage && isObject(dataFromStorage)) {
    dataFromStorage[id] = locPref
  } else {
    dataFromStorage = {}
    dataFromStorage[id] = locPref
  }

  setStorageComplicatedData(key, dataFromStorage)
}

const getCountsOfCycles = (data) => {
  const counts = {}

  forEach(data, (item) => {
    const property = isArc(item.deviceType)
      ? item.deviceType
      : item.deviceSubType

    if (counts[property]) {
      counts[property]++
    } else {
      counts[property] = 1
    }
  })

  return counts
}

const prepareDevicesTypes = (features) => {
  const filter = {}

  forEach(features, (item) => {
    let deviceType = INDEX_MAP[item.id]

    if (deviceType) {
      filter[deviceType] = true
    }
  })

  return filter
}

const prepareSubLabel = (filter, counts) => {
  let label = []

  forIn(filter, (value, key) => {
    if (filter[key]) {
      const labelWithCount = getLabelWithCount(
        counts[key] || 0,
        `${DEVICE_TYPE_MAPPER[key]} Cycle`
      )

      label.push(labelWithCount)
    }
  })

  return label.join(' - ')
}

const getTypesInfo = (type, subType) => {
  if (type === DEVICE_TYPE.arc) {
    return DEVICE_TYPE_MAPPER[type]
  } else {
    return subType === DEVICE_TYPE.uvgi
      ? DEVICE_TYPE_MAPPER[type]
      : DEVICE_TYPE_MAPPER.FAR_UVC
  }
}

const onLoadLocationSuccess = (userId, data, loadNodesSucceeded) => {
  loadNodesSucceeded(userId, data, deeps)
}

const extractCancellationError = (cause) => {
  const errorCode = split(cause, ': ')[1] || ''

  return (
    CANCELLATION_ERRORS[errorCode] || toUpper(errorCode.replaceAll(' ', '_'))
  )
}

const getSubLabelsFromActivities = (
  activities,
  firstDatActivity,
  lastDayAcivity,
  devicesTypesFilter
) => {
  const lastKey = findLastKey(activities)
  const allKeys = keys(activities)
  const firstKey = allKeys[0]

  return map(activities, (activityElement, key) => {
    let correspondingCounts

    // counting cycles of the first day
    if (key === firstKey && !isEmpty(firstDatActivity)) {
      correspondingCounts = getCountsOfCycles(firstDatActivity)
    }

    // counting cycles of the last day
    else if (key === lastKey && !isEmpty(lastDayAcivity)) {
      correspondingCounts = getCountsOfCycles(lastDayAcivity)
    }

    // counting cycles of the days in between (regular counting)
    else {
      correspondingCounts = getCountsOfCycles(activityElement)
    }

    return prepareSubLabel(devicesTypesFilter, correspondingCounts)
  })
}

export {
  getMessageText,
  saveLocationPrefSettings,
  createDeviceLabel,
  getCountsOfCycles,
  prepareDevicesTypes,
  prepareSubLabel,
  getTypesInfo,
  onLoadLocationSuccess,
  extractCancellationError,
  getSubLabelsFromActivities,
}
