import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  wrapper: {
    height: 'calc(100% - 100px)',
    overflow: 'auto',
  },
  userEditPageContainer: {
    padding: '0 7%',
  },
  userEditPage: {
    '& > .spinner': {
      display: 'none',
    },
  },
  billingFlagControl: {
    color: COLORS.GRAY_MOUNTAIN_MIST_SECONDARY,
  },
  action: {
    marginTop: '30px',
  },
  form: {
    textAlign: 'center',
  },
  billingFlagLabel: {
    fontSize: '16px',
    lineHeight: '19px',
    color: COLORS.GRAY,
    fontFamily: 'Cerebri Sans Pro Regular',
    textAlign: 'left',
  },
  searchField: {
    width: '100%',
  },
  inputTextColor: {
    color: COLORS.BLACK,
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  jobTitle: {
    marginBottom: '20px',
    fontSize: '12px',
  },
  error: {
    margin: '0 0 20px',
  },
  roleLabelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'left',
    '& > *': {
      color: COLORS.BLACK,
    },
  },
  roleDescription: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  '@media (min-width: 1224px)': {
    userEditPageContainer: {
      width: '60%',
      margin: 'auto',
      padding: 0,
      marginTop: '30px',
      height: 'calc(100% - 60px)',
    },
    firstTriangle: {
      position: 'relative',
      bottom: '180px',
      left: '75px',
      height: '0',
      width: '0',
      opacity: '0.08',
      transform: 'scaleX(-1)',
      borderLeft: `140px solid ${COLORS.BLUE}`,
      borderTop: '140px solid transparent',
    },
    secondTriangle: {
      position: 'relative',
      left: '235px',
      bottom: '175px',
      height: '0',
      width: '0',
      opacity: '0.08',
      transform: 'scaleX(-1)',
      borderRight: `63px solid ${COLORS.BLUE}`,
      borderTop: '63px solid transparent',
    },
    thirdTriangle: {
      position: 'relative',
      left: '65%',
      bottom: '385px',
      height: '0',
      width: '0',
      opacity: '0.08',
      transform: 'scaleX(-1)',
      borderLeft: `197px solid ${COLORS.BLUE}`,
      borderBottom: '197px solid transparent',
    },
    titles: {
      minHeight: '150px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      marginBottom: '-375px',
      marginTop: '55px',
    },
    pageTitle: {
      marginBottom: 0,
    },
    form: {
      backgroundColor: COLORS.WHITE,
      padding: '40px 90px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      width: '45%',
    },
    action: {
      margin: '20px auto 10px',
    },
    backButton: {
      color: COLORS.BLUE,
      marginRight: '10px',
      zIndex: '1',
      '& svg': {
        width: '30px',
        height: '30px',
      },
    },
    roleTitle: {
      marginTop: '40px',
    },
  },
})

export default styles
