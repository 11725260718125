import React from 'react'
import { DEVICE_TYPE, COLORS } from '../../../../services/constants'
import ARCSummaryContainer from '../../ARC/ARCSummaryContainer'
import UVGISummaryContainer from '../../UVGI/UVGISummaryContainer'

const SUMMARY_TITLE = 'Summary'

const ACTIVE_DEVICES = {
  totalLocations: (number) => `${number} Total Devices`,
  blueTitle: 'Active Devices',
  grayTitle: 'Inactive Devices',
  notRun: 'Not Run',
}

const devicesConfig = [
  {
    id: 'ARC_DEVICES',
    component: (history) => <ARCSummaryContainer history={history} />,
    index: DEVICE_TYPE.arc,
  },
  {
    id: 'UVGI_DEVICES',
    component: (history) => <UVGISummaryContainer history={history} />,
    index: DEVICE_TYPE.uvgi,
  },
  {
    id: 'FAR_UVC',
    component: (history) => <UVGISummaryContainer history={history} />,
    index: DEVICE_TYPE.uvc,
  },
]

const TOOLTIP_LABELS = {
  device: 'Device',
  location: 'Location',
  cycle: 'cycle',
}

const HEIGHT_OF_TABS_AND_TITLE = 250

const RANGE_TYPES = {
  MONTH: 'Monthly',
  WEEK: 'Weekly',
}

const RANGE_TYPES_KEYS = {
  MONTH: 'MONTH',
  WEEK: 'WEEK',
}

const DRILL_DOWN_TABLE_LABELS = {
  devices: 'Devices',
  locations: 'Locations',
  average: 'Avg',
}

const TABLE_TYPE = {
  devices: 'devices',
  locations: 'locations',
}

const RANGE_TYPE_FILTER = {
  label: 'View Type',
  apply: 'Apply',
  chipLabel: (type) => `View: ${type}`,
}

const getLinkToReportLabel = (type) => `Detailed ${type} report`

const TABLE_CELL_COLORS = {
  red: COLORS.RED_ORANGE,
  yellow: COLORS.YELLOW,
  green: COLORS.GREEN,
}

const ROW_NAME_KEYS = {
  locations: 'locationPath',
  devices: 'serialNumber',
}

const DEFAULT_RANGE_TYPE = {
  [RANGE_TYPES_KEYS.WEEK]: true,
  [RANGE_TYPES_KEYS.MONTH]: false,
}

const TABLE_SINGULAR_LABELS = {
  devices: 'Device',
  locations: 'Location',
}

const SORTING_ORDER = {
  asc: 'asc',
  desc: 'desc',
}

const SORTING_BY = {
  name: 'name',
  average: 'average',
}

const SUMMARY_PATH_REGEXP = /^.+\/#\/summary$/

const DOWNLOAD_PDF = 'PDF Report'

export {
  SUMMARY_TITLE,
  ACTIVE_DEVICES,
  devicesConfig,
  TOOLTIP_LABELS,
  HEIGHT_OF_TABS_AND_TITLE,
  RANGE_TYPES,
  RANGE_TYPES_KEYS,
  DRILL_DOWN_TABLE_LABELS,
  TABLE_TYPE,
  RANGE_TYPE_FILTER,
  getLinkToReportLabel,
  TABLE_CELL_COLORS,
  ROW_NAME_KEYS,
  DEFAULT_RANGE_TYPE,
  TABLE_SINGULAR_LABELS,
  SORTING_ORDER,
  SORTING_BY,
  SUMMARY_PATH_REGEXP,
  DOWNLOAD_PDF,
}
