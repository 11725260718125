import { isNull, isUndefined, floor, find } from 'lodash'
import moment from 'moment'
import { PREF } from '../../../../../services/constants'
import {
  DDMMyyyyTHHmm,
  utcConverterToDate,
  DDMMYYYYFormat,
} from '../../../../../services/dateFormat-service'
import {
  DEVICE_DETAILS_LABELS,
  DEVICE_STATUS,
} from '../../../_shared/services/devices-constants'
import { _locationTemplate } from '../../../_shared/services/devices-service'

const convertSecondsToRunTime = (seconds) =>
  seconds < 3600
    ? `${floor(seconds / 60) % 60}min ${seconds % 60}s`
    : `${floor(seconds / 3600)}h ${floor(seconds / 60) % 60}m ${seconds % 60}s`

const getDeviceStatusLabel = (status) => `Device ${DEVICE_STATUS[status]}`

const getPeriodForDeviceStatus = (timezone) => {
  const period = {}
  const nowDate = utcConverterToDate(moment(), timezone)

  if (timezone) {
    period.dateFrom = nowDate
      .startOf('day')
      .subtract(timezone, 'hours')
      .format(DDMMyyyyTHHmm)
    period.dateTo = nowDate
      .endOf('day')
      .subtract(timezone, 'hours')
      .format(DDMMyyyyTHHmm)
  } else {
    period.dateFrom = nowDate.startOf('day').utc().format(DDMMyyyyTHHmm)
    period.dateTo = nowDate.add(1, 'd').utc().format(DDMMyyyyTHHmm)
  }

  return period
}

const getMsInSeconds = (msValue, isTotal, timezone) => {
  const seconds = msValue && msValue >= 0 ? floor(msValue / 1000) : 0

  if (!isTotal) {
    return seconds
  }

  // need to check whether current run time is greater than period from
  // 'start of the day' to 'current time' for 'Total Run Time Today'

  const startOfDay = utcConverterToDate(moment(), timezone).startOf('day')

  const periodFromStartOfDay = floor(moment().diff(moment(startOfDay)) / 1000)

  return seconds > periodFromStartOfDay ? periodFromStartOfDay : seconds
}

const filterRoomsWithAllowedProps = (locations, selectedProperties) => {
  let filteredLocations = { ...locations }

  if (selectedProperties) {
    filteredLocations[PREF.SERVER_PREF] = selectedProperties
    filteredLocations.isServerPref = true
  }

  if (isNull(selectedProperties) || isUndefined(selectedProperties)) {
    filteredLocations[PREF.SERVER_PREF] = false
    filteredLocations.isServerPref = true
  }

  return { ...filteredLocations, isSingleSelectionTree: true }
}

const getTimeDifference = (
  transactionStartedAt,
  nowDateMoment,
  interruptionPeriod
) => {
  const nowDate = nowDateMoment.format('yyyy-MM-DDTHH:mm:ss')
  const timeDifference = moment(nowDate).diff(transactionStartedAt)

  return interruptionPeriod
    ? timeDifference - interruptionPeriod
    : timeDifference
}

const getReminderDate = () =>
  moment().add(6, 'month').format(DDMMYYYYFormat).split('/').join('-')

const createDeviceLocationLabelForRooms = (locationFilter) => {
  const { selectedBuildings, selectedName } = locationFilter

  if (selectedBuildings && selectedBuildings.length === 1) {
    return _locationTemplate(selectedName)
  }

  return DEVICE_DETAILS_LABELS.assignToLocation
}

const getPropertyIdWithSelectedRoom = (nodes) => {
  const property = find(nodes, (item) => item.checked || item.indeterminate)

  return property.id
}

export {
  convertSecondsToRunTime,
  getDeviceStatusLabel,
  getPeriodForDeviceStatus,
  getMsInSeconds,
  filterRoomsWithAllowedProps,
  getTimeDifference,
  getReminderDate,
  createDeviceLocationLabelForRooms,
  getPropertyIdWithSelectedRoom,
}
