import React, { useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import style from './style'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core'
import {
  LOCATIONS_TABLE,
  ORDER,
} from '../../../ARC/service/arcSummary-constants'
import Typography from '@material-ui/core/Typography'
import {
  DMMyyyyAndTime,
  DMyyyy,
  formattedWithTimezoneString,
  utcConverter,
  yyyyMMDFormat,
} from '../../../../../services/dateFormat-service'
import { orderBy, forEach, concat, uniqueId, map } from 'lodash'
import { prepareTimeFilter } from '../../../../Dashboard/services/related/filter-service'

const LocationsTable = (props) => {
  const { locations, dateFilter, defaultPeriod, timezone, isToday, classes } =
    props
  const [order, setOrder] = useState(ORDER.asc)

  const period = prepareTimeFilter(dateFilter, defaultPeriod, timezone)
  const start = period.startDate.format(yyyyMMDFormat)
  const end = period.endDate.format(yyyyMMDFormat)

  const createSortHandler = () => {
    const isAsc = order === ORDER.asc

    setOrder(isAsc ? ORDER.desc : ORDER.asc)
  }

  const getOrderedData = () => {
    const values = []
    const nullValues = []

    forEach(locations, (item) => {
      if (item.lastEventDate) {
        values.push(item)
      } else {
        nullValues.push(item)
      }
    })

    const orderedArray = orderBy(values, ['lastEventDate'], [order])

    return order === ORDER.desc
      ? concat(orderedArray, nullValues)
      : concat(nullValues, orderedArray)
  }

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              className={classNames(classes.headerCell, classes.firstColumn)}
            >
              <Typography variant="body1" color="textSecondary">
                {LOCATIONS_TABLE.firstColumn}
              </Typography>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active
                direction={order}
                onClick={createSortHandler}
              >
                <Typography variant="body1" color="textSecondary">
                  {LOCATIONS_TABLE.secondColumn}
                </Typography>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locations &&
            map(getOrderedData(), (row) => {
              const dateTemplate =
                isToday && row.isActive ? DMyyyy : DMMyyyyAndTime
              const rowDate = utcConverter(
                row.lastEventDate,
                dateTemplate,
                timezone
              )

              const toDate = formattedWithTimezoneString(
                row.lastEventDate,
                timezone
              )
              const isBefore = moment(toDate).isBefore(start)
              const isAfter = moment(toDate).isAfter(end)

              const isRed = !row.lastEventDate || isBefore || isAfter

              return (
                <TableRow key={uniqueId()}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.firstColumn}
                  >
                    <Typography
                      variant="h6"
                      color={isRed ? 'error' : 'textPrimary'}
                    >
                      {row.locationPath.replaceAll('>', ' > ')}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="h6"
                      color={isRed ? 'error' : 'textPrimary'}
                    >
                      {row.lastEventDate
                        ? rowDate
                        : LOCATIONS_TABLE.notDisinfected}
                    </Typography>
                    {isToday && row.isActive && (
                      <div className={classes.active}>
                        <FiberManualRecordIcon
                          fontSize="inherit"
                          className={classes.circle}
                        />
                        ACTIVE
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default withStyles(style)(LocationsTable)
