import { COLORS } from '../../../../../../services/constants'

const styles = (theme) => {
  const borderStyle = `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`

  return {
    deviceCard: {
      width: '100%',
      display: 'flex',
      backgroundColor: COLORS.WARM_GRAY,
      minHeight: '50px',
      alignItems: 'center',
      marginBottom: '5px',
      border: borderStyle,
      cursor: 'pointer',
    },
    secondaryText: {
      fontFamily: 'Cerebri Sans Pro Regular',
      fontSize: '12px',
      lineHeight: '14px',
      display: 'inline-block',
      color: COLORS.GRAY_OUTER_SPACE,
      marginBottom: '2px',
    },
    mainText: {
      fontSize: '14px',
      lineHeight: '17px',
      fontFamily: 'Cerebri Sans Pro Regular',
    },
    navigationRowItem: {
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '8%',
      borderLeft: borderStyle,
    },
    rowIcon: {
      height: '14px',
      color: COLORS.BLACK,
    },
    info: {
      width: 'calc(100% - 31px)',
      display: 'flex',
      alignSelf: 'stretch',
      textAlign: 'left',
    },
    columnContainer: {
      minWidth: 'calc(50% - 17px)',
      '& > div': {
        width: 'calc(100% - 8px)',
        verticalAlign: 'middle',
        paddingLeft: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        minHeight: '50px',
        border: 'none',
        '&:first-child': {
          border: 'none',
          borderLeft: borderStyle,
          borderRight: borderStyle,
        },
        '&:nth-child(2)': {
          border: 'none',
          borderLeft: borderStyle,
          borderTop: borderStyle,
        },
        '&:only-child': {
          border: 'none',
          borderBottom: borderStyle,
        },
      },
    },
    statusDotContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '66px',
    },
    '@media (min-width: 1224px)': {
      navigationRowItem: {
        width: '6%',
      },
      columnContainer: {
        minWidth: 'calc(50% - 33px)',
        '& > div': {
          width: 'calc(100% - 20px)',
          textAlign: 'left',
          minHeight: '60px',
          paddingLeft: '20px',
        },
      },
      secondaryText: {
        fontSize: '12px',
        lineHeight: '14px',
      },
      deviceCard: {
        backgroundColor: COLORS.WHITE,
        minHeight: '80px',
      },
      mainText: {
        fontSize: '18px',
        lineHeight: '20px',
        fontFamily: 'Cerebri Sans Pro Semi Bold',
      },
    },
  }
}

export default styles
