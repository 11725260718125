import React from 'react'
import { map } from 'lodash'
import { withStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import styles from './style'
import IconButton from '@material-ui/core/IconButton'
import { withMediaQuery } from '../../../services/root/root-service'

const TreeNode = (props) => {
  const {
    value,
    onCheck,
    children,
    name,
    isParent,
    onExpand,
    expanded,
    checked,
    classes,
    margin,
    indeterminate,
    isMobile,
    deep,
    propertyId,
  } = props

  const renderChildren = () => {
    if (!expanded) {
      return null
    }

    return map(children, (child) => child)
  }

  const onExpandList = () => {
    onExpand(value, deep, propertyId)
  }

  const onCheckRow = (event) => {
    onCheck(value, deep, event.target.checked, propertyId)
  }

  return (
    <>
      <div
        className={classes.treeNodeContainer}
        style={{
          marginLeft: margin,
        }}
      >
        <div className={classes.labelContainer}>
          <Checkbox
            className={classes.treeCheckbox}
            checkedIcon={<CheckBoxOutlinedIcon />}
            checked={checked}
            indeterminate={indeterminate}
            color="primary"
            onChange={onCheckRow}
          />
          <Typography
            className={classes.treeLabel}
            color={checked ? 'primary' : 'inherit'}
            variant={checked ? (isMobile ? 'subtitle2' : 'subtitle1') : 'body2'}
          >
            {name}
          </Typography>
        </div>
        {isParent ? (
          <div className={classes.arrowColumn}>
            <IconButton
              onClick={onExpandList}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
        ) : null}
      </div>
      {renderChildren()}
    </>
  )
}

export default withMediaQuery(withStyles(styles)(TreeNode))
