import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

const _rolesLabelTemplate = (item) => {
  return <RolesLabelComponent item={item} />
}

const RolesLabelComponent = withStyles(styles)((props) => {
  const { classes, item } = props

  return (
    <div>
      <div className={classes.roleLabelWrapper}>
        <Typography variant="body2">{item.name}</Typography>
        <span className={classes.roleDescription}>{item.description}</span>
      </div>
    </div>
  )
})

export default _rolesLabelTemplate
