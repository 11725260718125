import {
  DATE_FILTER,
  LOCATION_FILTER_LABELS,
} from '../../../../services/constants'

const SUMMARY_LABELS = {
  activeDevices: 'Active Devices',
  activeRooms: 'Active Rooms',
  pageTitle: 'Device Overview',
}

const LOCATION_LABEL_TITLE = 'Rooms'

const ACTIVE_DEVICES_TITLE = 'Currently Active Devices'
const DATE_OF_LAST_CYCLE = 'Date of Last Cycle'

const WEEK_LABELS = {
  devices: 'Devices',
  subtitle: DATE_OF_LAST_CYCLE,
  rooms: 'Rooms',
  createLabel: () => DATE_OF_LAST_CYCLE,
}

const INITIAL_DATE_FILTER_STATE = {
  dateFilter: {
    today: true,
    week: false,
    month: false,
    custom: false,
  },
  period: {},
  dateFilterLabel: DATE_FILTER.today,
}

const initialState = {
  isLoading: true,
  error: '',
  loadSummaryDataError: '',
  summaryDataNetworkError: !window.navigator.onLine,
  locationFilter: {},
  devices: [],
  deviceActivities: [],
  activeDevicesCount: 0,
  deviceFilterLabel: LOCATION_FILTER_LABELS.all,
  totalLocationsCount: 0,
  ...INITIAL_DATE_FILTER_STATE,
}

const totalRooms = (number) => `${number} Total Rooms`
const totalDevices = (number) => `${number} Total Devices`

const ACTIVE_ROOMS = {
  title: 'Active Rooms',
  totalLocations: totalRooms,
  blueTitle: 'Active Rooms',
  grayTitle: 'Inactive Rooms',
}

const CYCLE_CHART = {
  title: 'Hours of Cycle Time',
  subtitle: 'Per Day',
}

const TODAY_ROOMS_LABELS = {
  title: 'Currently Active Rooms',
  totalRooms,
}

const TODAY_DEVICES_LABELS = {
  title: 'Currently Active Devices',
  tableSubtitle: 'Date of Last Cycle',
  totalDevices,
}

const WEEK_ROOMS_LABELS = {
  title: 'Active Rooms',
  totalRooms,
}

const WEEK_DEVICES_LABELS = {
  title: 'Active Devices',
  tableSubtitle: 'Number of Cycles',
  totalDevices,
}

const TODAY_DEVICE_TABLE_COLUMNS = {
  firstColumn: 'Device',
  secondColumn: 'Last Run',
}

const WEEK_DEVICE_TABLE_COLUMNS = {
  firstColumn: 'Device',
  secondColumn: 'Cycles',
}

const UVGI_DEVICE_STATUSES = {
  OFF: 'OFF',
  STAND_BY: 'STAND_BY',
  ACTIVE: 'ACTIVE',
  ERROR: 'ERROR',
  DISCONNECTED: 'DISCONNECTED',
}

export {
  initialState,
  INITIAL_DATE_FILTER_STATE,
  SUMMARY_LABELS,
  LOCATION_LABEL_TITLE,
  ACTIVE_DEVICES_TITLE,
  ACTIVE_ROOMS,
  CYCLE_CHART,
  WEEK_LABELS,
  TODAY_ROOMS_LABELS,
  TODAY_DEVICES_LABELS,
  WEEK_ROOMS_LABELS,
  WEEK_DEVICES_LABELS,
  TODAY_DEVICE_TABLE_COLUMNS,
  WEEK_DEVICE_TABLE_COLUMNS,
  UVGI_DEVICE_STATUSES,
}
