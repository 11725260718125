import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './style'
import { EXT } from '../../../containers/Account/services/account-constants'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

const PhoneWithExtensionStateComponent = withStyles(styles)((props) => {
  const {
    isReadOnly,
    phoneNumber,
    phoneExtension,
    phoneLabel,
    onChange,
    phoneName,
    classes,
  } = props

  const onPhoneChange = (event) => {
    let val = event.target.value
    const lengthOfTheString = val.length
    const current = val.replace(/\(|\)| /gm, '')

    // if this is not number all we already have 10 numbers, then do not add new ones
    if (
      isNaN(Number.parseInt(event.target.value[lengthOfTheString - 1])) ||
      current.length > 10
    ) {
      val = val.substring(0, lengthOfTheString - 1)

      onChange(event.target.name, val)
    } else {
      // remove all numbers after ")"
      if (val.length <= 4 && val.includes('(') && !val.includes(')')) {
        onChange(event.target.name, val)
      } else {
        // add new number and rewrite "()"
        let num = `(${current.substring(0, 3)}) ${current.substring(
          3,
          6
        )} ${current.substring(6, current.length)}`

        num = num.trim()

        onChange(event.target.name, num)
      }
    }
  }

  const onExtChange = (event) => {
    let value = event.target.value

    if (/^\d+$/.test(value) || value === '') {
      onChange(event.target.name, value)
    }
  }

  return (
    <Box display="flex">
      <TextField
        variant="outlined"
        name={phoneName || 'phoneNumber'}
        label={phoneLabel}
        disabled={isReadOnly}
        InputProps={{
          className: classes.phoneInput,
          classes: {
            notchedOutline: classes.notchedOutline,
            input: classes.inputTextColor,
          },
        }}
        value={phoneNumber}
        onChange={onPhoneChange}
        fullWidth
      />
      <TextField
        variant="outlined"
        name="phoneExtension"
        label={EXT}
        disabled={isReadOnly}
        value={phoneExtension}
        InputProps={{
          className: classes.extensionInput,
          classes: { input: classes.inputTextColor },
        }}
        inputProps={{ min: '0', maxLength: '4' }}
        className={classes.extension}
        onChange={onExtChange}
      />
    </Box>
  )
})

export default PhoneWithExtensionStateComponent
