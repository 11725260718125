import React, { useState, useEffect, useMemo } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  getCalculatedValue,
  getTimeDifference,
} from '../../../../services/related/transaction-service'
import { FIELDS, RUN_TIME } from '../../../../services/dashboard-constants'
import styles from '../style'
import { checkDisconnectedStatus } from 'services/root/root-service'

const RunTimeComponent = withStyles(styles)((props) => {
  const {
    classes,
    isInProgress,
    startTime,
    modifiedTime,
    interruptionPeriod,
    color,
    isErrorCalculation,
    disconnectedTime,
    details,
  } = props

  const isDisconnected = useMemo(
    () => checkDisconnectedStatus(disconnectedTime, modifiedTime),
    [disconnectedTime, modifiedTime]
  )
  const [runTime, setRunTime] = useState(() =>
    isInProgress && !isDisconnected
      ? getTimeDifference(interruptionPeriod, startTime)
      : getTimeDifference(
          interruptionPeriod,
          startTime,
          details[FIELDS.lastEvent]
        )
  )
  const calculatedRunTime = getCalculatedValue(isErrorCalculation, runTime)

  useEffect(() => {
    let isMounted = true
    let intervalForRunTime

    if (isInProgress && !isDisconnected) {
      intervalForRunTime = setInterval(() => {
        if (isMounted) {
          setRunTime(getTimeDifference(interruptionPeriod, startTime))
        }
      }, 1000)
    }

    return () => {
      isMounted = false
      clearInterval(intervalForRunTime)
    }
  }, [runTime, isDisconnected])

  return (
    <div className={classes.progressNumber}>
      <Typography className={classes.cycleName} color="textSecondary">
        {RUN_TIME.label}
      </Typography>
      <span className={classes.runTimeLabel} style={{ color }}>
        {calculatedRunTime}
      </span>
    </div>
  )
})

export default RunTimeComponent
