import {
  changeChecked,
  changePropertyChecked,
  changePropertyState,
  changeSingleSelectableNode,
  selectAllByProperties,
} from './checkNode-service'
import customTreeActions from './customTree-actions'
import {
  prepareProperties,
  changeExpanded,
  getAllSelectedByUserPref,
  getAllSelectedState,
  buildPropertyTree,
  changeUserPrefByProperty,
} from './customTree-service'
import { cloneDeep, findIndex, slice, clone } from 'lodash'
import { PREF, TREE_LEVELS } from '../../../../services/constants'
import { getStorageDataById } from '../../../../services/defaultStore-sevice'
import { initialState } from './customTree-constants'

export const customTreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case customTreeActions.actionsTypes.LOAD_NODES_SUCCESS: {
      const { nodes, id, finalNode } = action.payload
      const treeCopy = cloneDeep(nodes)
      const userPref = nodes.isServerPref
        ? nodes[PREF.SERVER_PREF].selectedBuildings
        : getStorageDataById(PREF.LOCATIONS, id)
      const userPrefCurrent = clone(userPref)
      const isIncludeUnknown = getStorageDataById(PREF.UNKNOWN_LOCATIONS, id)
      const isAllSelected = !!getAllSelectedByUserPref(id, nodes)

      return {
        ...state,
        finalNode: finalNode || TREE_LEVELS.PLACEMENT,
        userPref,
        userPrefCurrent,
        isIncludeUnknown,
        treeCopy,
        nodes: prepareProperties(
          action.payload,
          userPrefCurrent,
          isAllSelected
        ),
        isAllSelected,
      }
    }
    case customTreeActions.actionsTypes.SET_EXPAND_NODE: {
      const { key, propertyId } = action.payload
      const propertyIndex = findIndex(state.nodes, ['id', propertyId])
      const property = cloneDeep(state.nodes[propertyIndex])
      changeExpanded(key, property.children)

      //TODO check splice(propertyIndex + 1, 0, property). here and below
      return {
        ...state,
        nodes: [
          ...slice(state.nodes, 0, propertyIndex),
          property,
          ...slice(state.nodes, propertyIndex + 1),
        ],
      }
    }
    case customTreeActions.actionsTypes.SET_PROPERTY_EXPAND_NODE: {
      const { userId, key } = action.payload
      const expandedPropertyIndex = findIndex(state.nodes, ['id', key])
      const expandedProperty = buildPropertyTree(
        state,
        expandedPropertyIndex,
        userId
      )

      return {
        ...state,
        nodes: [
          ...slice(state.nodes, 0, expandedPropertyIndex),
          expandedProperty,
          ...slice(state.nodes, expandedPropertyIndex + 1),
        ],
      }
    }
    case customTreeActions.actionsTypes.SET_PROPERTY_CHECK_NODE: {
      const { key, bool } = action.payload
      const propertyIndex = findIndex(state.nodes, ['id', key])
      const property = cloneDeep(state.nodes[propertyIndex])
      changePropertyChecked(property, bool)
      const newCurrentUserPref = changeUserPrefByProperty(
        property,
        state.userPrefCurrent
      )

      return {
        ...state,
        userPrefCurrent: newCurrentUserPref,
        nodes: [
          ...slice(state.nodes, 0, propertyIndex),
          property,
          ...slice(state.nodes, propertyIndex + 1),
        ],
      }
    }
    case customTreeActions.actionsTypes.SET_CHECK_NODE: {
      const { key, bool, propertyId } = action.payload
      const propertyIndex = findIndex(state.nodes, ['id', propertyId])
      const property = cloneDeep(state.nodes[propertyIndex])

      property.children = changeChecked(
        key,
        bool,
        'building',
        property.children
      )

      changePropertyState(property)

      return {
        ...state,
        nodes: [
          ...slice(state.nodes, 0, propertyIndex),
          property,
          ...slice(state.nodes, propertyIndex + 1),
        ],
      }
    }
    case customTreeActions.actionsTypes.SET_CHECK_SINGLE_NODE:
      return {
        ...state,
        nodes: changeSingleSelectableNode(
          action.payload.key,
          action.payload.bool,
          state.nodes
        ),
      }
    case customTreeActions.actionsTypes.SET_SELECT_ALL_STATE:
      return {
        ...state,
        isAllSelected: !!getAllSelectedState(state.nodes),
      }
    case customTreeActions.actionsTypes.SELECT_ALL:
      return {
        ...state,
        nodes: selectAllByProperties(state.nodes, action.payload.isSelectAll),
        isAllSelected: !!action.payload.isSelectAll,
      }
    case customTreeActions.actionsTypes.LOAD_INITIAL_LOCATION_TREE_SUCCESS: {
      const { nodes } = action.payload
      const treeCopy = cloneDeep(nodes)

      return {
        ...state,
        initialNodes: nodes,
        treeCopy,
      }
    }
    case customTreeActions.actionsTypes.RESET_TREE_COPY_STATE:
      return {
        ...state,
        treeCopy: {},
      }
    default:
      return state
  }
}
