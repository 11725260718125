import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import styles from './style'
import { withStyles } from '@material-ui/core'

const CustomTooltip = (props) => {
  const { isMobile, classes, ...tooltipProps } = props

  return <Tooltip classes={isMobile ? classes : null} {...tooltipProps} />
}

export default withStyles(styles)(CustomTooltip)
