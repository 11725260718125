import React, { useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { COMPLETED_CYCLES } from '../service/arcSummary-constants'
import {
  cardsConfig,
  createCompletedCyclesChart,
} from '../service/arcSummary-service'
import ArcDateFilter from './ARCDateFilter'
import LocationFilter from '../../_shared/components/locationFilter/LocationFilter'
import { withStyles } from '@material-ui/core'
import ARCDevicesSummaryInfo from './ARCDevicesSummaryInfo'
import ARCLocationSummaryInfo from './ARCLocationSummaryInfo'
import style from './style'
import CardsContainer from '../../_shared/components/CardsContainer'
import CombinationChartContainer from '../../_shared/components/charts/CombinationChartContainer'
import EmptySummaryPageComponent from '../../_shared/components/EmptySummaryPage'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import actions from '../service/arcSummary-actions'
import DevicesPageWrapper from '../../_shared/components/DevicesPageWrapper/DevicesPageWrapper'
import { DEVICE_TYPE_MAPPER, DEVICE_TYPE } from '../../../../services/constants'
import DownloadPDF from '../../_shared/components/downloadPDF/DownloadPDF'
import { PdfSummaryGenerator } from 'components/_shared/pdf/PdfSummaryGenerator'
import { getReportFileName } from 'containers/Summary/_shared/services/summary-helpers'

const ARCSummaryPageComponent = (props) => {
  const {
    organizationName,
    totalCycles,
    totalCyclesTime,
    avgCyclesTime,
    avgPerDayCycles,
    summaryIsEmpty,
    isMobile,
    classes,
    applyLocationFilter,
    locationFilter,
    completedCyclesData,
  } = props

  const gaContext = useMemo(
    () => ({ device_tab: DEVICE_TYPE_MAPPER.UVI_ARC }),
    []
  )

  const handleGeneratePdf = useCallback(() => {
    const generator = new PdfSummaryGenerator(
      getReportFileName(organizationName, DEVICE_TYPE.arc),
      DEVICE_TYPE_MAPPER.UVI_ARC
    )
    generator.render()
  }, [organizationName])

  const getHeader = () => (
    <div className={classes.actionsWrapper}>
      <div className={classNames('pdf-filters', classes.filters)}>
        <LocationFilter
          applyLocationFilter={applyLocationFilter}
          locationFilter={locationFilter}
          gaContext={gaContext}
        />
        <ArcDateFilter gaContext={gaContext} />
      </div>
      <DownloadPDF downloadPDF={handleGeneratePdf} />
    </div>
  )

  const getPage = () =>
    summaryIsEmpty ? (
      <EmptySummaryPageComponent />
    ) : (
      <div className={classes.contentWrapper}>
        <CardsContainer
          totalCycles={totalCycles}
          cardsConfig={cardsConfig}
          totalCyclesTime={totalCyclesTime}
          avgCyclesTime={avgCyclesTime}
          avgPerDayCycles={avgPerDayCycles}
          isMobile={isMobile}
        />
        <CombinationChartContainer
          title={COMPLETED_CYCLES.title}
          createCompletedCyclesChart={createCompletedCyclesChart}
          chartData={completedCyclesData}
          subtitle={COMPLETED_CYCLES.perDay}
        />
        <div className={classes.locationsAndDevicesContainer}>
          <ARCLocationSummaryInfo />
          <ARCDevicesSummaryInfo />
        </div>
      </div>
    )

  return (
    <>
      <DevicesPageWrapper
        HeaderComponent={getHeader}
        PageComponent={getPage}
        name={DEVICE_TYPE_MAPPER.UVI_ARC}
        isMobile={isMobile}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { ARCSummaryReducer },
    rootReducer,
  } = state

  return {
    totalCycles: ARCSummaryReducer.totalCycles,
    totalCyclesTime: ARCSummaryReducer.totalCyclesTime,
    avgCyclesTime: ARCSummaryReducer.avgCyclesTime,
    avgPerDayCycles: ARCSummaryReducer.avgPerDayCycles,
    summaryIsEmpty: ARCSummaryReducer.summaryIsEmpty,
    locationFilter: ARCSummaryReducer.locationFilter,
    completedCyclesData: ARCSummaryReducer.completedCyclesData,
    organizationName: rootReducer.organization.name,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyLocationFilter: (nodes) =>
      dispatch(checkConnection(() => actions.applyLocationFilter(nodes))),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(style)(ARCSummaryPageComponent)))
