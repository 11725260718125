import { cloneDeep } from 'lodash'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { HttpClient } from '../../../../services/HttpClient'
import customTreeActions from '../../../../components/_shared/customTree/services/customTree-actions'
import {
  DEVICE_TYPE,
  PREF,
  TREE_LEVELS,
  TREE_STATE_ALL,
} from 'services/constants'
import { prepareBodyForSummary } from '../../_shared/services/summary-service'
import { SUMMARY_PATH_REGEXP } from '../../_shared/services/summary-constants'
import uvgiSummaryActions from './uvgiSummary-actions'
import { prepareQueryForDeviceActivity } from './uvgiSummary-service'
import { getReportFileName } from 'containers/Summary/_shared/services/summary-helpers'

const getLocationTree = (state) => state.customTreeReducer.nodes
const getAllSelectedFlag = (state) => state.customTreeReducer.isAllSelected
const getCurrentUserId = (state) => state.rootReducer.userId
const getUserTimezone = (state) => state.rootReducer.timezone
const getOrganizationName = (state) => state.rootReducer.organization.name

const getActiveTab = (state) =>
  state.summaryReducer.sharedSummaryReducer.activeTab
const getLocationsIds = (state) => state.summaryReducer.UVGISummaryReducer.ids
const getDateFilter = (state) =>
  state.summaryReducer.UVGISummaryReducer.dateFilter
const getPeriod = (state) => state.summaryReducer.UVGISummaryReducer.period
const getDevices = (state) => state.summaryReducer.UVGISummaryReducer.devices
const getLocationFilter = (state) =>
  state.summaryReducer.UVGISummaryReducer.locationFilter
const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* loadLocations() {
  try {
    const userId = yield select(getCurrentUserId)
    const locationFilter = select(getLocationFilter)
    const tree = yield select(getInitialNodesTree)

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        {
          ...tree,
          [PREF.SERVER_PREF]: locationFilter,
          isServerPref: true,
        },
        TREE_LEVELS.ROOM
      )
    )
  } catch (e) {
    yield put(uvgiSummaryActions.loadLocationTreeFailed(e.message))
  }
}

function* getActiveDevices() {
  try {
    const isAllSelected = yield select(getAllSelectedFlag)
    const ids = isAllSelected ? null : yield select(getLocationsIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getPeriod)
    const timezone = yield select(getUserTimezone)
    const activeTab = yield select(getActiveTab)

    const body = prepareBodyForSummary(
      ids,
      dateFilter,
      defaultPeriod,
      timezone,
      activeTab
    )

    const data = yield call(() => {
      return HttpClient.post('/api/secured/report/devices', {
        body,
      })
    })

    if (data.message) {
      yield put(uvgiSummaryActions.getDevicesFailed(data.message))
    } else {
      yield put(uvgiSummaryActions.getDevicesSuccess(data))
    }
  } catch (e) {
    yield put(uvgiSummaryActions.getDevicesFailed(e.message))
  }
}

function* loadUVGISummaryData() {
  try {
    const isAllSelected = yield select(getAllSelectedFlag)
    const ids = isAllSelected ? null : yield select(getLocationsIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getPeriod)
    const timezone = yield select(getUserTimezone)
    const activeTab = yield select(getActiveTab)

    const body = prepareBodyForSummary(
      ids,
      dateFilter,
      defaultPeriod,
      timezone,
      activeTab
    )

    const data = yield call(() => {
      return HttpClient.post('/api/secured/report/activities/', {
        body,
      })
    })

    if (data.message) {
      yield put(uvgiSummaryActions.loadUVGISummaryDataFailed(data.message))
    } else {
      yield put(uvgiSummaryActions.loadUVGISummaryDataSuccess(data))
    }
  } catch (e) {
    yield put(uvgiSummaryActions.loadUVGISummaryDataFailed(e.message))
  }
}

function* loadDeviceActivitiesForSummary() {
  try {
    const devices = yield select(getDevices)
    const locationTree = yield select(getLocationTree)
    const body = prepareQueryForDeviceActivity(devices)

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/devices/transactions/last-activities`,
        { body }
      )
    })

    if (data.message) {
      yield put(
        uvgiSummaryActions.loadDeviceActivitiesForSummaryFailed(data.message)
      )
    } else {
      yield put(
        uvgiSummaryActions.loadDeviceActivitiesForSummarySuccess(
          data.items,
          locationTree
        )
      )
    }
  } catch (e) {
    yield put(
      uvgiSummaryActions.loadDeviceActivitiesForSummaryFailed(e.message)
    )
  }
}

function* getDisinfectedLocations() {
  try {
    const isAllSelected = yield select(getAllSelectedFlag)
    const ids = isAllSelected ? null : yield select(getLocationsIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getPeriod)
    const timezone = yield select(getUserTimezone)
    const activeTab = yield select(getActiveTab)

    const body = prepareBodyForSummary(
      ids,
      dateFilter,
      defaultPeriod,
      timezone,
      activeTab
    )

    const data = yield call(() => {
      return HttpClient.post('/api/secured/report/locations', { body })
    })

    if (data.message) {
      yield put(uvgiSummaryActions.loadDisinfectedLocationsFailed(data.message))
    } else {
      yield put(
        uvgiSummaryActions.loadDisinfectedLocationsSuccess(data, timezone)
      )
    }
  } catch (e) {
    yield put(uvgiSummaryActions.loadDisinfectedLocationsFailed(e.message))
  }
}

function* setupLoadedLocations(action) {
  try {
    const { nodes, skipUpdate } = action.payload
    const userId = yield select(getCurrentUserId)
    const activeTab = yield select(getActiveTab)

    const isTriggeredFromSummary = SUMMARY_PATH_REGEXP.test(
      window.location.href
    )
    const onBeamOrViveTab =
      activeTab === DEVICE_TYPE.uvgi || activeTab === DEVICE_TYPE.uvc

    if (isTriggeredFromSummary && onBeamOrViveTab) {
      yield put(
        uvgiSummaryActions.prepareLocationFilter(TREE_STATE_ALL, nodes, userId)
      )

      if (!skipUpdate) {
        yield put(uvgiSummaryActions.loadUVGISummaryData())
      }
    }
  } catch (e) {
    yield put(uvgiSummaryActions.applyLocationFilterFailed(e.message))
  }
}

function* downloadPDFPackage() {
  try {
    const isAllSelected = yield select(getAllSelectedFlag)
    const ids = isAllSelected ? null : yield select(getLocationsIds)
    const dateFilter = yield select(getDateFilter)
    const defaultPeriod = yield select(getPeriod)
    const timezone = yield select(getUserTimezone)
    const activeTab = yield select(getActiveTab)
    const name = yield select(getOrganizationName)

    const body = prepareBodyForSummary(
      ids,
      dateFilter,
      defaultPeriod,
      timezone,
      activeTab
    )

    const data = yield call(() => {
      return HttpClient.post('/api/secured/report/pdf/SummaryUvgi', {
        body,
        isDownload: true,
        fileName: getReportFileName(name, activeTab),
      })
    })

    if (data.message) {
      yield put(uvgiSummaryActions.downloadPDFPackageFailed(data.message))
    } else {
      yield put(uvgiSummaryActions.downloadPDFPackageSuccess())
    }
  } catch (e) {
    yield put(uvgiSummaryActions.downloadPDFPackageFailed(e.message))
  }
}

function* loadLocationsTreeSaga() {
  yield takeLatest(
    uvgiSummaryActions.actionsTypes.LOAD_LOCATIONS,
    loadLocations
  )
}

function* getCyclesReportSaga() {
  yield all([
    takeLatest(
      uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA,
      loadUVGISummaryData
    ),
    takeLatest(
      uvgiSummaryActions.actionsTypes.APPLY_LOCATION_FILTER,
      loadUVGISummaryData
    ),
    takeLatest(
      uvgiSummaryActions.actionsTypes.CHANGE_DATE_FILTER,
      loadUVGISummaryData
    ),
  ])
}

function* getDevicesSaga() {
  yield takeLatest(
    uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA_SUCCEEDED,
    getActiveDevices
  )
}

function* getActiveDevicesSaga() {
  yield takeLatest(
    uvgiSummaryActions.actionsTypes.GET_DEVICES_SUCCEEDED,
    loadDeviceActivitiesForSummary
  )
}

function* getDisinfectedLocationsSaga() {
  yield takeLatest(
    uvgiSummaryActions.actionsTypes
      .LOAD_UVGI_DEVICES_ACTIVITIES_FOR_SUMMARY_SUCCEEDED,
    getDisinfectedLocations
  )
}

function* locationFilterIsReadySaga() {
  yield takeLatest(
    customTreeActions.actionsTypes.LOAD_NODES_SUCCESS,
    setupLoadedLocations
  )
}

function* downloadPDFPackageSaga() {
  yield takeLatest(
    uvgiSummaryActions.actionsTypes.DOWNLOAD_PDF_PACKAGE,
    downloadPDFPackage
  )
}

export default function* UVGISummarySagas() {
  yield fork(loadLocationsTreeSaga)
  yield fork(locationFilterIsReadySaga)
  yield fork(getCyclesReportSaga)
  yield fork(getDevicesSaga)
  yield fork(getActiveDevicesSaga)
  yield fork(getDisinfectedLocationsSaga)
  yield fork(downloadPDFPackageSaga)
}
