export const gaEvent = (
  eventCategory: string,
  eventParams: { [label: string]: string },
  eventName = 'click'
) => {
  gtag('event', eventName, {
    event_category: eventCategory,
    ...eventParams,
  })
}
