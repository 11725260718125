import React, { createRef, useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { connect } from 'react-redux'
import styles from '../../../../../components/_shared/modals/simpleModal/style'
import { withMediaQuery } from '../../../../../services/root/root-service'
import { isMobileOnly } from 'react-device-detect'
import WarningModal from '../../../../../components/_shared/modals/warningConfirm/WarningModal'
import { ADD_DEVICE_LABELS } from '../../services/devices-constants'
import CloseButton from '../../../../../components/_shared/modals/closeButton/CloseButton'

const AddDeviceModal = withMediaQuery(
  withStyles(styles)((props) => {
    const {
      onSubmit,
      onClose,
      modalTitle,
      placeholder,
      isOpen,
      actionLabel,
      error,
      nodes,
      popupOnError = {},
      defaultValue,
      inputProps = {},
      locationFilter,
      isAllSelected,
      isMobile,
      resetError,
      isArc,
      resetRoomsFilter,
      children,
      classes,
    } = props
    const inputRef = createRef()
    const [isEmptyTextField, setIsEmptyTextField] = useState(false)
    const [openMessageModal, setOpenMessageModal] = useState(false)
    const {
      isPopup,
      errorAction,
      errorActionLabel,
      errorText,
      errorModalTitle,
    } = popupOnError
    const { selectedBuildings: ids } = locationFilter

    let timer = 0
    let prevent = true

    const handleClose = () => {
      setOpenMessageModal(false)
      resetError()
    }

    useEffect(() => {
      if (error && popupOnError && isPopup) {
        setOpenMessageModal(true)
      }
    }, [error, isPopup])

    const onCloseModal = () => {
      setIsEmptyTextField(false)
      resetRoomsFilter()

      if (openMessageModal) {
        handleClose()
      }

      onClose()
    }

    const confirmWarningModal = () => {
      handleClose()
      errorAction()
    }

    function onSubmitButton(event) {
      event.preventDefault()

      const isValidLocation = isArc
        ? isAllSelected || ids?.length
        : ids?.length === 1

      const value = inputRef.current.value.trim()

      setIsEmptyTextField(!value)

      if (value && isValidLocation) {
        if (error) {
          prevent = true
        }

        timer = setTimeout(function () {
          if (prevent) {
            onSubmit(value)
            prevent = false
          }
        }, 200)
      }
    }

    function handleDoubleClick() {
      clearTimeout(timer)
      prevent = true
    }

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={onCloseModal}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            className={classes.modalTitleContainer}
            disableTypography
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              className={classes.modalTitle}
            >
              {modalTitle}
            </Typography>
            <CloseButton onCloseModal={onCloseModal} />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <form onSubmit={onSubmitButton}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                inputRef={inputRef}
                error={isEmptyTextField}
                defaultValue={defaultValue}
                label={!isMobileOnly ? placeholder : ''}
                placeholder={isMobileOnly ? placeholder : ''}
                inputProps={{ className: classes.outlined, ...inputProps }}
                fullWidth
                autoFocus={!isMobileOnly}
              />
              {children}
              {!nodes.length && (
                <Typography variant="body2">
                  {isArc
                    ? ADD_DEVICE_LABELS.noLocations
                    : ADD_DEVICE_LABELS.noRooms}
                </Typography>
              )}

              <Button
                type="submit"
                onDoubleClick={handleDoubleClick}
                variant="contained"
                color="primary"
                disabled={
                  isArc ? !isAllSelected && ids && !ids.length : !nodes.length
                }
                className={classes.submitButton}
              >
                {actionLabel}
              </Button>
            </form>
          </DialogContent>
          <WarningModal
            modalTitle={errorModalTitle}
            onSubmit={confirmWarningModal}
            dialogHelperText={errorText}
            buttonLabel={errorActionLabel}
            isOpen={openMessageModal}
            onClose={handleClose}
          />
        </Dialog>
      </div>
    )
  })
)

const mapStateToProps = (state) => ({
  isAllSelected: state.customTreeReducer.isAllSelected,
  nodes: state.customTreeReducer.nodes,
})

export default connect(mapStateToProps)(AddDeviceModal)
