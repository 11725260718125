import { forIn } from 'lodash'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import { PAGINATION } from '../../../../../services/constants'
import arcDeviceListActions from './arcDeviceList-actions'
import { getDevicesAndLoadingState } from './arcDeviceList-service'

export const ARCDeviceListReducer = (
  state = {
    devices: [],
    nodes: {},
    actionError: '',
    sorting: {
      RECENTLY_USED: true,
      DEVICE_SN: false,
    },
    isLoading: true,
    failedSerialNumber: null,
    locationFilter: {},
    loadDevicesError: '',
    totalCount: 0,
    page: 1,
    rowsPerPage: PAGINATION.perPageValues[0],
    searchField: '',
  },
  action
) => {
  switch (action.type) {
    case arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST: {
      return {
        ...state,
        failedSerialNumber: null,
        isLoading: true,
      }
    }
    case arcDeviceListActions.actionTypes.ADD_NEW_DEVICE_FAILED: {
      return {
        ...state,
        failedSerialNumber: action.payload.serialNumber,
        actionError: action.payload.message,
        isLoading: false,
      }
    }
    case arcDeviceListActions.actionTypes.SETUP_LOCATIONS_FILTER:
      return {
        ...state,
        locationFilter: action.payload.nodes,
        locationFilterError: '',
      }
    case arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST_SUCCEEDED: {
      const {
        devices: { items, totalCount },
        allDevicesAssignedToAllProps,
        nodes,
      } = action.payload
      const data = getDevicesAndLoadingState(
        items,
        allDevicesAssignedToAllProps,
        nodes
      )

      return {
        ...state,
        devices: data.devices,
        loadDevicesError: '',
        isLoading: data.isLoading,
        totalCount,
      }
    }
    case arcDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED: {
      return {
        ...state,
        actionError: false,
        failedSerialNumber: '',
        isLoading: false,
      }
    }
    case arcDeviceListActions.actionTypes.CHANGE_SORTING: {
      const updatedSorting = forIn(state.sorting, (value, key) => {
        state.sorting[key] = key === action.payload.sortBy
      })

      return {
        ...state,
        sorting: {
          ...state.sorting,
          ...updatedSorting,
        },
      }
    }
    case arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST_FAILED: {
      return {
        ...state,
        loadDevicesError: action.payload.message,
        isLoading: false,
      }
    }
    case arcDeviceListActions.actionTypes.SET_GLOBAL_TIMEOUT_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    case customTreeActions.actionsTypes.LOAD_NODES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case arcDeviceListActions.actionTypes.SET_GLOBAL_TIMEOUT_SUCCEEDED:
      return {
        ...state,
        globalTimeout: action.payload.data,
      }
    case arcDeviceListActions.actionTypes.RESET_ACTION_ERROR: {
      return {
        ...state,
        actionError: false,
      }
    }
    case arcDeviceListActions.actionTypes.SET_ROWS_PER_PAGE: {
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
        page: 1,
      }
    }
    case arcDeviceListActions.actionTypes.GO_TO_NEXT_PAGE: {
      return {
        ...state,
        page: action.payload.newPage,
      }
    }
    case arcDeviceListActions.actionTypes.RESET_STATE: {
      return {
        ...state,
        devices: [],
        totalCount: 0,
        page: 1,
        rowsPerPage: PAGINATION.perPageValues[0],
        searchField: '',
      }
    }
    case arcDeviceListActions.actionTypes.SEARCH_BY_SN: {
      return {
        ...state,
        searchField: action.payload.content,
      }
    }
    default:
      return state
  }
}
