import React, { useEffect, useState } from 'react'
import { isEmpty, isUndefined } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { UPDATE_USER_LABELS } from '../../services/users-constants'
import { MAX_NAME_LENGTH, USER_ROLES } from '../../../../services/constants'
import styles from './style'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import LocationButton from '../locations/LocationButton'
import {
  createErrorsObj,
  initialState,
  prepareBodyForRequest,
  prepareRoles,
} from '../../services/users-service'
import PhoneWithExtensionState from '../../../../components/_shared/phoneWithExtesion/PhoneWithExtensionState'
import _rolesLabelTemplate from './RoleSelectTemplate'
import { withMediaQuery } from '../../../../services/root/root-service'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const UsersEditPageComponent = (props) => {
  const {
    classes,
    roles,
    updateUser,
    actionLabel,
    user,
    organization,
    error,
    loadAllUserRolesError,
    nodesCount,
    currentUserRole,
    allRoles,
  } = props
  const [errors, setErrors] = useState({})
  const [isUserEmpty, setFlag] = useState(true)
  const [userState, setUserState] = useState(initialState)
  const isReadOnly = !!(user && currentUserRole === user.role)
  const readOnlyRole = user && prepareRoles([user.role], allRoles)

  useEffect(() => {
    if (isUserEmpty && user) {
      setFlag(false)

      const isAdminBilling = user.role === USER_ROLES.adminBillingUserRole
      const userCopy = { ...user }

      userCopy.role = isAdminBilling ? USER_ROLES.adminUserRole : user.role
      userCopy.billingFlag = isAdminBilling
      userCopy.businessTitle = user.businessTitle || initialState.businessTitle

      setUserState(userCopy)
    }
  })

  const changeRole = (event) => {
    setUserState({ ...userState, role: event.target.value })
  }

  const changeLocations = (allowedProperties) => {
    setUserState({ ...userState, allowedProperties })
  }

  const changePhone = (key, value) => {
    setUserState({ ...userState, [key]: value })
  }

  const validateForm = (userObject) => {
    const errorsObj = createErrorsObj(userObject, nodesCount)

    setErrors(errorsObj)

    return errorsObj
  }

  const addUser = (event) => {
    event.preventDefault()

    const userObject = prepareBodyForRequest(
      userState,
      organization.id,
      nodesCount
    )
    const errorsObj = validateForm(userObject)

    if (isEmpty(errorsObj)) {
      updateUser(userObject)
    }
  }

  // TODO will be returned in future
  // const handleChange = (event) => {
  //     setUserState({...userState, billingFlag: event.target.checked});
  // };

  const changeTextField = (event) => {
    setUserState({ ...userState, [event.target.name]: event.target.value })
  }

  return (
    <>
      {error && (
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      )}
      {loadAllUserRolesError && (
        <Typography variant="subtitle1" color="error" className={classes.error}>
          {loadAllUserRolesError}
        </Typography>
      )}
      <form className={classes.form} onSubmit={addUser} noValidate>
        <Box display={{ lg: 'flex' }} justifyContent="space-between">
          <div className={classes.column}>
            <TextField
              required
              name="firstName"
              className={classes.searchField}
              InputProps={{ classes: { input: classes.inputTextColor } }}
              label={UPDATE_USER_LABELS.FIRST_NAME}
              value={userState.firstName}
              error={errors.firstName}
              onChange={changeTextField}
              disabled={isReadOnly}
              inputProps={{ maxLength: MAX_NAME_LENGTH }}
              variant="outlined"
            />

            <TextField
              required
              name="lastName"
              className={classes.searchField}
              InputProps={{ classes: { input: classes.inputTextColor } }}
              label={UPDATE_USER_LABELS.LAST_NAME}
              value={userState.lastName}
              error={errors.lastName}
              onChange={changeTextField}
              disabled={isReadOnly}
              inputProps={{ maxLength: MAX_NAME_LENGTH }}
              variant="outlined"
            />

            <TextField
              required
              name="email"
              className={classes.searchField}
              InputProps={{ classes: { input: classes.inputTextColor } }}
              label={UPDATE_USER_LABELS.EMAIL}
              helperText={errors.email && 'Please, enter a valid email'}
              value={userState.email}
              error={errors.email}
              disabled={!isUndefined(user)}
              onChange={changeTextField}
              variant="outlined"
            />

            <PhoneWithExtensionState
              isReadOnly={isReadOnly}
              onChange={changePhone}
              phoneNumber={userState.phoneNumber}
              phoneExtension={userState.phoneExtension}
              phoneLabel={UPDATE_USER_LABELS.PHONE}
            />
          </div>

          <div className={classes.column}>
            <CustomSelect
              options={isReadOnly ? readOnlyRole : roles}
              error={errors.role}
              label={UPDATE_USER_LABELS.ROLE}
              onChange={changeRole}
              value={userState.role || ''}
              valueName="id"
              template={_rolesLabelTemplate}
              isReadOnly={isReadOnly}
              isRequired
              selectedOnlyBlack
              smallPaddings
            />

            <TextField
              name="businessTitle"
              className={classes.searchField}
              InputProps={{ classes: { input: classes.inputTextColor } }}
              label={UPDATE_USER_LABELS.BUSINESS_TITLE}
              value={userState.businessTitle}
              error={errors.businessTitle}
              onChange={changeTextField}
              disabled={isReadOnly}
              inputProps={{ maxLength: MAX_NAME_LENGTH }}
              variant="outlined"
            />

            <TextField
              className={classes.searchField}
              InputProps={{ classes: { input: classes.inputTextColor } }}
              label={UPDATE_USER_LABELS.ORGANIZATION}
              value={organization.name}
              disabled
              variant="outlined"
            />
            {userState.role === USER_ROLES.operatorUserRole && (
              <LocationButton
                isEdit={!!user}
                disabled={isReadOnly}
                error={errors.allowedProperties}
                allowedProperties={userState.allowedProperties}
                changeLocations={changeLocations}
                required
                isBlack
              />
            )}
          </div>
        </Box>
        <div className={classes.actionsWrapper}>
          {/* TODO will be returned in future */}

          {/*{*/}
          {/*    userState.role === USER_ROLES.adminUserRole &&*/}
          {/*    <FormControlLabel*/}
          {/*        classes={{*/}
          {/*            label: classes.billingFlagLabel*/}
          {/*        }}*/}
          {/*        control={*/}
          {/*            <Checkbox*/}
          {/*                className={classes.billingFlagControl}*/}
          {/*                checked={userState.billingFlag || false}*/}
          {/*                onChange={handleChange}*/}
          {/*                color="primary"*/}
          {/*            />*/}
          {/*        }*/}
          {/*        label={ALLOW_BILLING_CHECKBOX}*/}
          {/*    />*/}
          {/*}*/}
          {!isReadOnly && (
            <Button
              type="submit"
              variant="contained"
              className={classes.action}
              color="primary"
            >
              {actionLabel}
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export default withMediaQuery(withStyles(styles)(UsersEditPageComponent))
