import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import actions from '../../service/location-actions'
import styles from '../style'
import { concat, map, uniqueId } from 'lodash'
import { Link } from 'react-router-dom'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import EditLocationButton from '../editModal/EditLocationButton'
import {
  generateLocationObj,
  getEditTitle,
} from '../../service/location-service'

const NavigationBar = (props) => {
  const {
    classes,
    routeParams,
    isReadOnly,
    isMobile,
    error,
    changeLocation,
    editLocation,
    resetError,
    deleteLocationWithSubLocations,
  } = props
  const routes = concat([], routeParams)
  const currentLoc = routes.pop()
  const currentRoute = routeParams[routeParams.length - 1]
  const isEditMode = currentLoc.id && !isReadOnly
  const isRoom = currentLoc && currentLoc.pathname.indexOf('room') !== -1
  const placeholder = currentRoute ? currentRoute.name || '' : 'Root'

  const navigationPath = () => {
    return map(routes, (item, key) => {
      return (
        <Typography
          color="textSecondary"
          variant="body2"
          key={uniqueId('navLink-')}
          className={classes.navLink}
        >
          <Link
            to={item.pathname}
            color="textSecondary"
            underline="none"
            onClick={() => changeLocation(item.pathname)}
          >
            {item.name}
          </Link>
          &nbsp;
          {key < routes.length - 1 ? '>' : ''}
          &nbsp;
        </Typography>
      )
    })
  }

  const onEditLocation = (value, roomFields, handleClose) => {
    const updatedLoc = generateLocationObj(currentRoute, value, roomFields)

    editLocation(updatedLoc, handleClose)
  }

  const success = (way) => {
    way.pop()
    changeLocation(way.join('/'))
  }

  const onDeleteLocation = (count) => {
    const way = currentRoute.pathname.split('/')
    const parentParams = way[way.length - 2]
    let [, parentId] = parentParams.split('-')

    deleteLocationWithSubLocations({
      id: currentRoute.id,
      parentId,
      count,
      onSuccess: () => {
        success(way)
      },
    })
  }

  return (
    <>
      {currentLoc ? (
        <div className={classes.navigationBarContainer}>
          <div>
            <div className={classes.currentLocationContainer}>
              <div className={classes.navigationPathWrapper}>
                {navigationPath()}
              </div>
            </div>
            <div className={classes.currentLocationContainer}>
              <div className={classes.locationNameWrapper}>
                <Typography
                  variant={isMobile ? 'subtitle2' : 'h2'}
                  className={!isEditMode ? classes.notEditMode : null}
                >
                  {currentLoc.name}
                </Typography>
                {isEditMode && (
                  <EditLocationButton
                    modalTitle={getEditTitle(currentRoute.name)}
                    isMobile={isMobile}
                    location={currentLoc}
                    isRoom={isRoom}
                    placeholder={placeholder}
                    onSubmit={onEditLocation}
                    onDelete={onDeleteLocation}
                    error={error}
                    bigIcon
                    resetError={resetError}
                    type="root"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteLocationWithSubLocations: (values) =>
      dispatch(
        checkConnection(() => actions.deleteLocationWithSubLocations(values))
      ),
    editLocation: (updatedLoc, onSuccess) =>
      dispatch(
        checkConnection(() => actions.editLocation(updatedLoc, onSuccess))
      ),
    resetError: () => dispatch(actions.resetError()),
  }
}

const mapStateToProps = (state) => ({
  error: state.locationReducer.error,
  routeParams: state.locationReducer.currentRouteParams,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(NavigationBar)))
