const extDeviceListActions = {}

extDeviceListActions.actionTypes = {
  ADD_NEW_DEVICE: '{External Device List} Add New Device',
  ADD_NEW_DEVICE_SUCCEEDED: '{External Device List} Add New Device Succeeded',
  ADD_NEW_DEVICE_FAILED: '{External Device List} Add New Device Failed',

  SETUP_ROOMS_FILTER: '{External Device List} Setup Rooms Filter',
  RESET_ROOMS_FILTER: '{External Device List} Reset Rooms Filter',

  RESET_ERROR: '{External Device List} Reset Error',

  SETUP_TREE_FOR_ADD_DEVICE_MODAL:
    '{External Device List} Setup Tree For Add Device Modal',
  SETUP_LOCATIONS_FILTER_FAILED:
    '{External Device List} Setup Locations Filter Failed',

  LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL:
    '{External Device List} Load locations for add list',

  LOAD_DEVICES_LIST: '{External Device List} Load devices list',
  LOAD_DEVICES_LIST_SUCCEEDED:
    '{External Device List} Load devices list Succeeded',
  LOAD_DEVICES_LIST_FAILED: '{External Device List} Load devices list Failed',
}

extDeviceListActions.addNewDevice = (serialNumber, onClose) => {
  return {
    type: extDeviceListActions.actionTypes.ADD_NEW_DEVICE,
    payload: {
      serialNumber,
      onClose,
    },
  }
}

extDeviceListActions.addNewDeviceSuccess = () => ({
  type: extDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED,
})

extDeviceListActions.addNewDeviceFailed = (message, serialNumber) => {
  return {
    type: extDeviceListActions.actionTypes.ADD_NEW_DEVICE_FAILED,
    payload: {
      message,
      serialNumber,
    },
  }
}

extDeviceListActions.resetRoomsFilter = () => {
  return {
    type: extDeviceListActions.actionTypes.RESET_ROOMS_FILTER,
  }
}

extDeviceListActions.setupRoomsFilter = (nodes, tree, userId) => {
  return {
    type: extDeviceListActions.actionTypes.SETUP_ROOMS_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

extDeviceListActions.resetActionError = () => {
  return {
    type: extDeviceListActions.actionTypes.RESET_ERROR,
  }
}

extDeviceListActions.setupTreeForAddDeviceModal = (nodes, tree, userId) => {
  return {
    type: extDeviceListActions.actionTypes.SETUP_TREE_FOR_ADD_DEVICE_MODAL,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

extDeviceListActions.setupLocationsFilterFailed = (message) => {
  return {
    type: extDeviceListActions.actionTypes.SETUP_LOCATIONS_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

extDeviceListActions.loadLocationsForAddDeviceModal = (onSuccess) => {
  return {
    type: extDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL,
    payload: {
      onSuccess,
    },
  }
}

extDeviceListActions.loadDevicesList = () => {
  return {
    type: extDeviceListActions.actionTypes.LOAD_DEVICES_LIST,
  }
}

extDeviceListActions.loadDevicesListSuccess = (list) => ({
  type: extDeviceListActions.actionTypes.LOAD_DEVICES_LIST_SUCCEEDED,
  payload: {
    list,
  },
})

export default extDeviceListActions
