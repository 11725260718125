import { find, isEmpty, isNull, isUndefined, map } from 'lodash'
import {
  ARC_TYPES,
  DEVICE_REMOVED_QUERY,
  LOCATION_LABELS,
  ORDER,
} from '../../../../../services/constants'
import filterQuery from '../../../../../services/filterQuery-service'
import {
  getLocationNameById,
  prepareLocationFiltersForDevice,
} from '../../../_shared/services/devices-service'

const prepareBody = (orderType, searchField) => {
  const deviceTypeQuery = filterQuery.getArrayStringInQuotes(
    ARC_TYPES,
    filterQuery.templates.array,
    'deviceSubType',
    'in',
    true
  )

  let sortField = 'order.deviceDateTime'
  let sortType = ORDER.desc
  let searchQuery = ''

  if (orderType.DEVICE_SN) {
    sortField = 'order.deviceSerialNumber'
    sortType = ORDER.asc
  }

  if (searchField) {
    searchQuery = filterQuery.getQueryString(
      `*${searchField}*`,
      filterQuery.templates.default,
      'deviceSerialNumber',
      'contains'
    )
  }

  return {
    query: filterQuery.createQueryStringFromArray(
      [deviceTypeQuery, DEVICE_REMOVED_QUERY, searchQuery],
      'and'
    ),
    [sortField]: sortType,
    pageSize: 100000,
  }
}

const getPropertyNameById = (id, tree) => {
  const properties = tree.children
  const property = find(properties, (prop) => prop.id === id)

  return property.name
}

const prepareDeviceListByPref = (devices, nodes) => {
  const preparedDevices = map(devices, (device) => {
    const userPref = device.deviceAllowedLocationIds

    if (isNull(userPref)) {
      return { ...device, locationLabel: LOCATION_LABELS.allProperties }
    }

    if (userPref.length > 1) {
      return { ...device, locationLabel: LOCATION_LABELS.multipleProperties }
    }

    const selectedName = getLocationNameById(nodes.children, userPref[0])

    return { ...device, locationLabel: selectedName }
  })

  return preparedDevices || []
}

const getLocationFilterParams = (nodes, userPref) => {
  const params = {
    selectedBuildings: userPref,
    selectedName: '',
  }

  if (isNull(userPref)) {
    return { ...params, selectedName: LOCATION_LABELS.allProperties }
  }

  if (isUndefined(userPref)) {
    return params
  }

  if (userPref.length > 1) {
    return { ...params, selectedName: LOCATION_LABELS.multipleProperties }
  }

  const selectedName = getLocationNameById(nodes.children, userPref[0])

  return { ...params, selectedName: selectedName }
}

//TODO check everywhere and maybe use prepareDeviceListByPref instead
const prepareDevicesList = (devices, nodes) => {
  const preparedDevices = map(devices, (device) => {
    const propObject = prepareLocationFiltersForDevice(
      nodes.children,
      device.deviceAllowedLocationIds
    )
    const { selectedBuildings, indeterminateProperties, selectedName } =
      propObject

    if (
      (!selectedBuildings || selectedBuildings.length === 0) &&
      indeterminateProperties?.length === 0
    ) {
      return { ...device, locationLabel: LOCATION_LABELS.allProperties }
    }

    if (
      indeterminateProperties?.length === 1 &&
      selectedBuildings?.length === 1
    ) {
      const propertyLabel = getPropertyNameById(
        indeterminateProperties[0],
        nodes
      )

      return { ...device, locationLabel: selectedName, propertyLabel }
    }

    if (selectedBuildings?.length > 1 || indeterminateProperties?.length > 1) {
      return { ...device, locationLabel: LOCATION_LABELS.multipleProperties }
    }

    return { ...device, locationLabel: selectedName }
  })

  return preparedDevices || []
}

const getDevicesAndLoadingState = (
  devices,
  allDevicesAssignedToAllProps,
  nodes
) => {
  if (!allDevicesAssignedToAllProps) {
    const needAddLocationsColumn = !isEmpty(nodes)

    return {
      devices: needAddLocationsColumn
        ? prepareDeviceListByPref(devices, nodes)
        : devices,
      isLoading: !needAddLocationsColumn,
    }
  } else {
    return {
      devices: map(devices, (device) => ({
        ...device,
        locationLabel: LOCATION_LABELS.allProperties,
      })),
      isLoading: false,
    }
  }
}

export {
  prepareBody,
  prepareDevicesList,
  getDevicesAndLoadingState,
  getLocationFilterParams,
}
