import { cloneDeep } from 'lodash'
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { HttpClient } from '../../../../../services/HttpClient'
import extDeviceListActions from './extDeviceList-actions'
import { prepareLocationFiltersForDevice } from '../../../_shared/services/devices-service'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import { filterLocationsWithAllowedProps } from '../../../../../services/root/root-service'
import { getPropertyIdWithSelectedRoom } from '../../../Details/UVGI/services/uvgiDeviceDetails-service'

const getRoomsFilter = (state) =>
  state.devicesReducer.extDeviceListReducer.roomsFilter
const getLocationTree = (state) => state.customTreeReducer.nodes
const getCurrentUserId = (state) => state.rootReducer.userId
const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* addNewDevice(action) {
  try {
    const locationFilter = yield select(getRoomsFilter)
    const { selectedBuildings: ids } = locationFilter

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/ext-device/${action.payload.serialNumber}/register`,
        {
          body: {
            locationIds: ids && ids.length ? ids : null,
          },
        }
      )
    })

    if (data.message) {
      yield put(
        extDeviceListActions.addNewDeviceFailed(
          data.message,
          action.payload.serialNumber
        )
      )
    } else {
      action.payload.onClose()

      yield put(extDeviceListActions.addNewDeviceSuccess(data))
    }
  } catch (e) {
    yield put(
      extDeviceListActions.addNewDeviceFailed(
        e.message,
        action.payload.serialNumber
      )
    )
  }
}

function* loadLocationsForAddDeviceButton(action) {
  try {
    const { onSuccess } = action.payload
    const tree = yield select(getInitialNodesTree)
    const userId = yield select(getCurrentUserId)

    yield put(
      extDeviceListActions.setupTreeForAddDeviceModal(
        prepareLocationFiltersForDevice(tree.children, []),
        tree,
        userId
      )
    )

    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield put(extDeviceListActions.loadLocationsForDeviceFailed(e.message))
  }
}

function* setupLoadedLocations(action) {
  try {
    const { nodes, tree, userId } = action.payload

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        filterLocationsWithAllowedProps(tree, nodes)
      )
    )

    const locationTree = yield select(getLocationTree)
    const propertyId = getPropertyIdWithSelectedRoom(locationTree)

    yield put(customTreeActions.setPropertyExpandNode(propertyId))
  } catch (e) {
    yield put(extDeviceListActions.setupLocationsFilterFailed(e.message))
  }
}

function* loadDevicesList() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/devices/ext-device/page-view')
    })

    if (data.message) {
      yield put(extDeviceListActions.loadDevicesListFailed(data.message))
    } else {
      yield put(extDeviceListActions.loadDevicesListSuccess(data))
    }
  } catch (e) {
    yield put(extDeviceListActions.loadDevicesListFailed(e.message))
  }
}

function* addNewDeviceSaga() {
  yield takeLatest(
    extDeviceListActions.actionTypes.ADD_NEW_DEVICE,
    addNewDevice
  )
}

function* openAddDeviceModalTreeSaga() {
  yield takeEvery(
    extDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL,
    loadLocationsForAddDeviceButton
  )
}

function* loadDevicesListSaga() {
  yield all([
    takeEvery(
      extDeviceListActions.actionTypes.LOAD_DEVICES_LIST,
      loadDevicesList
    ),
    takeEvery(
      extDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED,
      loadDevicesList
    ),
    takeEvery(
      extDeviceListActions.actionTypes.SETUP_TREE_FOR_ADD_DEVICE_MODAL,
      setupLoadedLocations
    ),
  ])
}

export default function* extDeviceListSaga() {
  yield fork(addNewDeviceSaga)
  yield fork(openAddDeviceModalTreeSaga)
  yield fork(loadDevicesListSaga)
}
