import { changeExpanded } from '../../customTree/services/customTree-service'
import infoTreeActions from './infoTree-actions'
import { prepareInfoTree } from './infoTree-service'

export const infoTreeReducer = (
  state = {
    nodes: [],
  },
  action
) => {
  switch (action.type) {
    case infoTreeActions.actionsTypes.LOAD_NODES_SUCCESS: {
      return {
        ...state,
        nodes: prepareInfoTree(action.payload.nodes),
      }
    }
    case infoTreeActions.actionsTypes.SET_EXPAND_NODE:
      return {
        ...state,
        nodes: changeExpanded(action.payload.key, state.nodes),
      }
    default:
      return state
  }
}
