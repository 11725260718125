import React from 'react'
import { withStyles } from '@material-ui/core'
import style from './style'
import Typography from '@material-ui/core/Typography'

const InfoCardComponent = (props) => {
  const { number, label, classes } = props

  return (
    <div className={classes.cardContainer}>
      <Typography className={classes.numberRow} component={'span'} noWrap>
        {number}
      </Typography>
      <Typography variant="body2" noWrap>
        {label}
      </Typography>
    </div>
  )
}

export default withStyles(style)(InfoCardComponent)
