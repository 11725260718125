import { BasePdfElement } from './BasePdfElement'

const BACKGROUND_COLOR = [238, 238, 238]

export class PdfCards extends BasePdfElement {
  div: HTMLDivElement

  constructor(div: HTMLDivElement) {
    super()
    this.div = div
  }

  renderCard(
    itemElement: Element,
    x: number,
    y: number,
    width: number,
    height: number
  ) {
    const spanElement = itemElement.querySelector('span')
    const pElement = itemElement.querySelector('p')
    if (!pElement || !spanElement) {
      return
    }

    const textValue = this.getPureText(spanElement)
    const textLabel = this.getPureText(pElement)

    this.doc.setFontSize(14)

    this.doc.setFillColor(
      BACKGROUND_COLOR[0],
      BACKGROUND_COLOR[1],
      BACKGROUND_COLOR[2]
    )
    this.doc.rect(x, y, width, height, 'F')
    this.addCenteredTextH(textValue, x, y + height / 2 - 2, width)
    this.addCenteredTextH(textLabel, x, y + height / 2 + 5, width)
  }

  async render() {
    const rowCount = Math.ceil(
      this.div.querySelectorAll('.pdf-cards > div').length / 2
    )
    const sep = 2

    const itemHeight = this.height / rowCount - sep
    const itemWidth = this.width / 2 - sep

    this.div.querySelectorAll('.pdf-cards > div').forEach((element, i) => {
      this.renderCard(
        element,
        i % 2 == 0 ? this.position_x : this.position_x + itemWidth + sep,
        this.position_y + Math.floor(i / rowCount) * (itemHeight + sep),
        itemWidth,
        itemHeight
      )
    })
  }
}
