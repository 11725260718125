const accountActions = {}

accountActions.actionTypes = {
  GET_ORGANIZATION_INFO: '{Account} Get Organization Info',
  GET_ORGANIZATION_INFO_SUCCEEDED: '{Account} Get Organization Info Succeeded',
  GET_ORGANIZATION_INFO_FAILED: '{Account} Get Organization Info Failed',

  GET_USER_INFO: '{Account} Get User Info',
  GET_USER_INFO_SUCCEEDED: '{Account} Get User Info Succeeded',
  GET_USER_INFO_FAILED: '{Account} Get User Info Failed',

  CHECK_ORGANIZATION_INFO: '{Account} Check Organization Info',
  CHECK_ORGANIZATION_INFO_SUCCEEDED:
    '{Account} Check Organization Info Succeeded',
  CHECK_ORGANIZATION_INFO_FAILED: '{Account} Check Organization Info Failed',

  UPDATE_ACCOUNT_INFO: '{Account} Update Account Info',
  UPDATE_ACCOUNT_INFO_SUCCEEDED: '{Account} Update Account Info Succeeded',
  UPDATE_ACCOUNT_INFO_FAILED: '{Account} Update Account Info Failed',

  RESEND_EMAIL: '{Account} Resend email',
  RESEND_EMAIL_SUCCEEDED: '{Account} Resend email Succeeded',
  RESEND_EMAIL_FAILED: '{Account} Resend email Failed',

  RESET_ACTION_EMAIL_ERROR: '{Account} Reset email Error',
  SET_PASSWORD: '{Account} Set password (confirm account)',
  SET_PASSWORD_SUCCEEDED: '{Account} Set password (confirm account) Succeeded',
  SET_PASSWORD_FAILED: '{Account} Set password (confirm account) Failed',
  RESET_UPDATE_ERRORS: '{Account} Reset Update Errors',

  GET_POTENTIAL_ACCOUNT_OWNERS: '{Account} Get Potential Account Owners',
  GET_POTENTIAL_ACCOUNT_OWNERS_SUCCEEDED:
    '{Account} Get Potential Account Owners Succeeded',
  GET_POTENTIAL_ACCOUNT_OWNERS_FAILED:
    '{Account} Get Potential Account Owners Failed',

  CHANGE_ACCOUNT_OWNER: '{Account} Change Account Owner',
  CHANGE_ACCOUNT_OWNER_SUCCEEDED: '{Account} Change Account Owner Succeeded',
  CHANGE_ACCOUNT_OWNER_FAILED: '{Account} Change Account Owner Failed',

  GET_TIMEZONES_BY_ZIP: '{Account} Get Timezones By Zip',
  GET_TIMEZONES_BY_ZIP_SUCCEEDED: '{Account} Get Timezones By Zip Succeeded',
  GET_TIMEZONES_BY_ZIP_FAILED: '{Account} Get Timezones By Zip Failed',

  GET_PROPERTIES: '{Account} Get Properties',
  GET_PROPERTIES_SUCCEEDED: '{Account} Get Properties Succeeded',
  GET_PROPERTIES_FAILED: '{Account} Get Properties Failed',

  GET_API_KEY_PAGE_ACCESS: '{Account} Get API Key Page Access',
  GET_API_KEY_PAGE_ACCESS_SUCCEEDED:
    '{Account} Get API Key Page Access Succeeded',
  GET_API_KEY_PAGE_ACCESS_FAILED: '{Account} Get API Key Page Access Failed',

  GENERATE_NEW_ENTRY: '{Account} Generate New Entry',
  GENERATE_NEW_ENTRY_SUCCEEDED: '{Account} Generate New Entry Succeeded',
  GENERATE_NEW_ENTRY_FAILED: '{Account} Generate New Entry Failed',

  REGENERATE_NEW_ENTRY: '{Account} Regenerate New Entry',
  REGENERATE_NEW_ENTRY_SUCCEEDED: '{Account} Regenerate New Entry Succeeded',
  REGENERATE_NEW_ENTRY_FAILED: '{Account} Regenerate New Entry Failed',

  UPDATE_ORGANIZATION_TIMEZONE: '{Account} Update Organization Timezone',
  UPDATE_ORGANIZATION_TIMEZONE_SUCCEEDED:
    '{Account} Update Organization Timezone Succeeded',
  UPDATE_ORGANIZATION_TIMEZONE_FAILED:
    '{Account} Update Organization Timezone Failed',

  GET_IDP_DATA_SUCCEEDED: '{Account} Get Idp Data Succeeded',
  GET_IDP_DATA_FAILED: '{Account} Get Idp Data Failed',

  STOP_LOADING: '{Account} Stop Loading',
  RESET_INITIAL_LOADING: '{Account} Reset Initial Loading',
}

accountActions.getOrganizationInfo = function (id) {
  return {
    type: accountActions.actionTypes.GET_ORGANIZATION_INFO,
    payload: {
      id,
    },
  }
}

accountActions.getOrganizationInfoSuccess = function (data) {
  return {
    type: accountActions.actionTypes.GET_ORGANIZATION_INFO_SUCCEEDED,
    payload: {
      data,
    },
  }
}

accountActions.getOrganizationInfoFailed = function (message) {
  return {
    type: accountActions.actionTypes.GET_ORGANIZATION_INFO_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.getUserInfo = function (id) {
  return {
    type: accountActions.actionTypes.GET_USER_INFO,
    payload: {
      id,
    },
  }
}

accountActions.getUserInfoSuccess = function (data) {
  return {
    type: accountActions.actionTypes.GET_USER_INFO_SUCCEEDED,
    payload: {
      data,
    },
  }
}

accountActions.getUserInfoFailed = function (message) {
  return {
    type: accountActions.actionTypes.GET_USER_INFO_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.updateAccountInfo = function (body, logOut) {
  return {
    type: accountActions.actionTypes.UPDATE_ACCOUNT_INFO,
    payload: {
      body,
      logOut,
    },
  }
}

accountActions.updateAccountInfoSuccess = function (data) {
  return {
    type: accountActions.actionTypes.UPDATE_ACCOUNT_INFO_SUCCEEDED,
    payload: {
      data,
      id: data.organizationId,
    },
  }
}

accountActions.updateAccountInfoFailed = function (message) {
  return {
    type: accountActions.actionTypes.UPDATE_ACCOUNT_INFO_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.checkOrganizationInfo = function (
  id,
  body,
  userId,
  user,
  onSuccess
) {
  return {
    type: accountActions.actionTypes.CHECK_ORGANIZATION_INFO,
    payload: {
      id,
      body,
      userId,
      user,
      onSuccess,
    },
  }
}

accountActions.checkOrganizationInfoFailed = function (message) {
  return {
    type: accountActions.actionTypes.CHECK_ORGANIZATION_INFO_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.checkOrganizationInfoSucceeded = function () {
  return {
    type: accountActions.actionTypes.CHECK_ORGANIZATION_INFO_SUCCEEDED,
  }
}

accountActions.resendEmail = function (email) {
  return {
    type: accountActions.actionTypes.RESEND_EMAIL,
    payload: {
      email,
    },
  }
}

accountActions.resendEmailSuccess = function () {
  return {
    type: accountActions.actionTypes.RESEND_EMAIL_SUCCEEDED,
  }
}

accountActions.resendEmailFailed = function (message) {
  return {
    type: accountActions.actionTypes.RESEND_EMAIL_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.resetActionEmailError = function () {
  return {
    type: accountActions.actionTypes.RESET_ACTION_EMAIL_ERROR,
  }
}

accountActions.setPassword = function (body) {
  return {
    type: accountActions.actionTypes.SET_PASSWORD,
    payload: {
      body,
    },
  }
}

accountActions.setPasswordSuccess = function () {
  return {
    type: accountActions.actionTypes.SET_PASSWORD_SUCCEEDED,
  }
}

accountActions.setPasswordFailed = function (message) {
  return {
    type: accountActions.actionTypes.SET_PASSWORD_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.resetUpdateErrors = function () {
  return {
    type: accountActions.actionTypes.RESET_UPDATE_ERRORS,
  }
}

accountActions.loadPotentialAccountOwners = function (history) {
  return {
    type: accountActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS,
    payload: {
      history,
    },
  }
}

accountActions.loadPotentialAccountOwnersSuccess = function (owners) {
  return {
    type: accountActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS_SUCCEEDED,
    payload: {
      owners,
    },
  }
}

accountActions.loadPotentialAccountOwnersFailed = function (message) {
  return {
    type: accountActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.changeAccountOwner = function (newId, onSuccess) {
  return {
    type: accountActions.actionTypes.CHANGE_ACCOUNT_OWNER,
    payload: {
      newId,
      onSuccess,
    },
  }
}

accountActions.changeAccountOwnerSuccess = function () {
  return {
    type: accountActions.actionTypes.CHANGE_ACCOUNT_OWNER_SUCCEEDED,
  }
}

accountActions.changeAccountOwnerFailed = function (message) {
  return {
    type: accountActions.actionTypes.CHANGE_ACCOUNT_OWNER_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.getTimezonesByZip = function (zip, onSuccess) {
  return {
    type: accountActions.actionTypes.GET_TIMEZONES_BY_ZIP,
    payload: {
      zip,
      onSuccess,
    },
  }
}

accountActions.getTimezonesByZipSuccess = function (timezones) {
  return {
    type: accountActions.actionTypes.GET_TIMEZONES_BY_ZIP_SUCCEEDED,
    payload: {
      timezones,
    },
  }
}

accountActions.getTimezonesByZipFailed = function (message) {
  return {
    type: accountActions.actionTypes.GET_TIMEZONES_BY_ZIP_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.getProperties = function () {
  return {
    type: accountActions.actionTypes.GET_PROPERTIES,
  }
}

accountActions.getPropertiesSuccess = function (properties) {
  return {
    type: accountActions.actionTypes.GET_PROPERTIES_SUCCEEDED,
    payload: {
      properties,
    },
  }
}

accountActions.getPropertiesFailed = function (message) {
  return {
    type: accountActions.actionTypes.GET_PROPERTIES_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.getAPIKeyPageAccess = function () {
  return {
    type: accountActions.actionTypes.GET_API_KEY_PAGE_ACCESS,
  }
}

accountActions.getAPIKeyPageAccessSuccess = function (APIKeyPageAccess) {
  return {
    type: accountActions.actionTypes.GET_API_KEY_PAGE_ACCESS_SUCCEEDED,
    payload: {
      APIKeyPageAccess,
    },
  }
}

accountActions.getAPIKeyPageAccessFailed = function (message) {
  return {
    type: accountActions.actionTypes.GET_API_KEY_PAGE_ACCESS_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.generateNewEntry = function (onSuccess) {
  return {
    type: accountActions.actionTypes.GENERATE_NEW_ENTRY,
    payload: {
      onSuccess,
    },
  }
}

accountActions.generateNewEntrySuccess = function (APIKey) {
  return {
    type: accountActions.actionTypes.GENERATE_NEW_ENTRY_SUCCEEDED,
    payload: {
      APIKey,
    },
  }
}

accountActions.generateNewEntryFailed = function (message) {
  return {
    type: accountActions.actionTypes.GENERATE_NEW_ENTRY_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.regenerateNewEntry = function (id, onSuccess) {
  return {
    type: accountActions.actionTypes.REGENERATE_NEW_ENTRY,
    payload: {
      id,
      onSuccess,
    },
  }
}

accountActions.regenerateNewEntrySuccess = function (id, onSuccess) {
  return {
    type: accountActions.actionTypes.REGENERATE_NEW_ENTRY_SUCCEEDED,
    payload: {
      id,
      onSuccess,
    },
  }
}

accountActions.regenerateNewEntryFailed = function (message) {
  return {
    type: accountActions.actionTypes.REGENERATE_NEW_ENTRY_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.updateOrganizationTimezone = function (id) {
  return {
    type: accountActions.actionTypes.UPDATE_ORGANIZATION_TIMEZONE,
    payload: {
      id,
    },
  }
}

accountActions.updateOrganizationTimezoneSuccess = function (data) {
  return {
    type: accountActions.actionTypes.UPDATE_ORGANIZATION_TIMEZONE_SUCCEEDED,
    payload: {
      data,
    },
  }
}

accountActions.updateOrganizationTimezoneFailed = function (message) {
  return {
    type: accountActions.actionTypes.UPDATE_ORGANIZATION_TIMEZONE_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.getIdpDataSuccess = function (data) {
  return {
    type: accountActions.actionTypes.GET_IDP_DATA_SUCCEEDED,
    payload: {
      data,
    },
  }
}

accountActions.getIdpDataFailed = function (message) {
  return {
    type: accountActions.actionTypes.GET_IDP_DATA_FAILED,
    payload: {
      message,
    },
  }
}

accountActions.stopLoading = function () {
  return {
    type: accountActions.actionTypes.STOP_LOADING,
  }
}

accountActions.resetInitialLoading = function () {
  return {
    type: accountActions.actionTypes.RESET_INITIAL_LOADING,
  }
}

export default accountActions
