const DEVICES_LIST_TITLE = 'External Devices'

const NO_PERMISSIONS = 'You have no permissions to see this page'

const HEADER_LABELS = {
  id: 'Id',
  serialNumber: 'Serial Number',
  extDeviceType: 'External Device Type',
  extDeviceSubType: 'External Device SubType',
  created: 'Created',
  action: null,
}

export { DEVICES_LIST_TITLE, NO_PERMISSIONS, HEADER_LABELS }
