import React from 'react'
import Typography from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import { withStyles } from '@material-ui/core/styles'
import { getPropertiesLabel } from '../../services/users-service'
import styles from './style'
import { USER_ROLES, COLORS } from '../../../../services/constants'
import { withMediaQuery } from '../../../../services/root/root-service'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import DeleteUserButton from '../DeleteUserButton/DeleteUserButton'
import classNames from 'classnames'
import { map } from 'lodash'

const UserCardComponent = withStyles(styles)((props) => {
  const { classes, user, searchValue, history, error, currentRole, isMobile } =
    props
  const propertiesLabel = getPropertiesLabel(user.allowedPropertiesNames)

  const editUser = () => {
    history.push(`/users/edit/${user.id}`)
  }

  const getHighlightedText = (text) => {
    // Split on highlight term and include term into parts, ignore case
    if (searchValue) {
      const parts = text.split(new RegExp(`(${searchValue})`, 'gi'))

      return (
        <span>
          {map(parts, (part, i) => (
            <span
              key={i}
              style={
                part.toLowerCase() === searchValue.toLowerCase()
                  ? { backgroundColor: COLORS.BISQUE }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      )
    }

    return text
  }

  // for now user deletion will be disabled cause we need to implement it in GandAuth first (PZ-671)
  // const cantBeDeleted =
  //   user.roleId === USER_ROLES.accountOwnerRole ||
  //   (currentRole === USER_ROLES.adminUserRole &&
  //     user.roleId === USER_ROLES.adminUserRole)
  const cantBeDeleted = true

  return (
    <div className={classes.userCard}>
      <div
        className={
          !cantBeDeleted
            ? classes.info
            : classNames(classes.info, classes.infoOwner)
        }
      >
        <div className={classes.infoColumn}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.emailAndProps}
          >
            {getHighlightedText(user.email)}
          </Typography>
          <Typography variant={isMobile ? 'subtitle1' : 'h4'} noWrap>
            {getHighlightedText(`${user.firstName} ${user.lastName}`)}
          </Typography>
        </div>
        <div className={classes.infoColumn}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.emailAndProps}
          >
            {propertiesLabel}
          </Typography>
          <Typography variant={isMobile ? 'subtitle1' : 'h4'}>
            {user.roleName}
            {user.roleId === USER_ROLES.adminBillingUserRole && (
              <MonetizationOn className={classes.billingIcon} />
            )}
          </Typography>
        </div>
      </div>
      {!cantBeDeleted && (
        <div className={classNames(classes.iconRow, classes.rightBorder)}>
          <DeleteUserButton error={error} user={user} />
        </div>
      )}
      <div className={classes.iconRow}>
        <IconButton
          className={classes.iconButton}
          onClick={editUser}
          aria-label="show more"
        >
          {isMobile ? (
            <CreateIcon className={classes.editIcon} />
          ) : (
            <ArrowForwardIosIcon className={classes.rowIcon} />
          )}
        </IconButton>
      </div>
    </div>
  )
})

export default withMediaQuery(UserCardComponent)
