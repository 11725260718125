import React, { useState } from 'react'
import { forIn, mapValues, isEmpty } from 'lodash'
import Chip from '@material-ui/core/Chip'
import DateFilterModal from '../../../../components/_shared/modals/dateFilter/DateFilterModal'
import { DATE_FILTER } from '../../../../services/constants'
import { convertTodayToMomentWithTimezone } from '../../../../services/dateFormat-service'
import { getLabelForDateFilter } from '../../UVGI/services/uvgiSummary-service'

const DateFilter = (props) => {
  const {
    dateFilter = {},
    changeSelectedChip,
    period,
    label,
    timezone,
    gaContext = {},
    gaEventSend,
  } = props
  const [open, setOpen] = useState(false)
  const today = convertTodayToMomentWithTimezone(timezone)
  const week = convertTodayToMomentWithTimezone(timezone).subtract(7, 'days')

  const defaultPeriod = isEmpty(period)
    ? {
        startDate: dateFilter.week ? week : today,
        endDate: today,
      }
    : period
  const [filter, setDateFilter] = useState({ dateFilter, defaultPeriod })

  const handleClickOpen = () => {
    setDateFilter({ dateFilter, period })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeSelected = (name, startDate, endDate) => {
    const dateFilter = mapValues(filter.dateFilter, (item, key) => {
      return name === key
    })
    const period = { startDate, endDate }

    setDateFilter({ dateFilter, period })
  }

  const onApply = () => {
    let filterLabel
    let gaEventData = { ...gaContext }

    forIn(filter.dateFilter, (item, key) => {
      if (item) {
        if (key === 'custom' && !isEmpty(filter.period)) {
          filterLabel = getLabelForDateFilter(filter.period)
          gaEventData.event_label = 'Custom'
          gaEventData.period = filterLabel
        }

        if (key !== 'custom') {
          filterLabel = DATE_FILTER[key]
          gaEventData.event_label = filterLabel
        }
      }
    })

    changeSelectedChip(filter, filterLabel)
    gaEventSend('date_filter', gaEventData)
    handleClose()
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        label={'Range: ' + label}
        variant="outlined"
      />
      <DateFilterModal
        open={open}
        timezone={timezone}
        period={defaultPeriod}
        dateFilter={filter.dateFilter}
        onChange={changeSelected}
        onClose={handleClose}
        onSubmit={onApply}
      />
    </>
  )
}

export default DateFilter
