import { COLORS } from '../../../../../../../services/constants'

const styles = () => ({
  maintenanceReminderContainer: {
    backgroundColor: COLORS.GRAY_ALTO,
    padding: '30px',
  },
  maintenanceReminderTitle: {
    marginBottom: '20px',
  },
  maintenanceReminderHint: {
    color: COLORS.GRAY,
  },
  maintenanceReminderButton: {
    width: '60%',
    height: '30px',
    fontSize: '12px',
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE,
    borderRadius: '15px',
    marginTop: '30px',
  },
  '@media (min-width: 1224px)': {
    maintenanceReminderContainer: {
      padding: '12px',
      backgroundColor: COLORS.WHITE,
    },
    maintenanceReminderButton: {
      width: '163px',
    },
  },
})

export default styles
