import { withStyles } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import style from '../../_shared/components/charts/style'
import DevicesTableContainer from '../../_shared/components/tables/DevicesTableContainer'
import ActiveDevicesContainer from '../../_shared/components/charts/ActiveDevicesContainer'
import { withMediaQuery } from '../../../../services/root/root-service'
import LinkToReport from '../../_shared/components/LinkToReport'
import { WEEK_LABELS } from '../services/uvgiSummary-constants'

const UVGIDevicesSummaryInfo = (props) => {
  const {
    activeDevicesCount,
    totalDevicesCount,
    devices,
    classes,
    title,
    subtitle,
    isToday,
    timezone,
    deviceActivities,
    tableSubtitle,
    dateFilter,
    period,
    dateFilterLabel,
    deviceType,
    locationFilter,
    ids,
    tableColumns,
  } = props

  return (
    <div className={classes.deviceInfo}>
      <ActiveDevicesContainer
        title={title}
        devices={devices}
        totalDevicesCount={totalDevicesCount}
        subtitle={subtitle}
        activeDevicesCount={activeDevicesCount}
      />
      <LinkToReport
        pathname={`/summary/${deviceType}/devices`}
        state={{
          date: {
            dateFilter,
            period,
          },
          dateFilterLabel,
          location: {
            locationFilter,
            ids,
          },
        }}
        label={WEEK_LABELS.devices}
      />
      <DevicesTableContainer
        devices={deviceActivities}
        isToday={isToday}
        tableColumns={tableColumns}
        timezone={timezone}
        subtitle={tableSubtitle}
        sortByDates
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { UVGISummaryReducer, sharedSummaryReducer },
    rootReducer,
  } = state

  return {
    devices: UVGISummaryReducer.devices,
    deviceActivities: UVGISummaryReducer.deviceActivities,
    activeDevicesCount: UVGISummaryReducer.activeDevicesCount,
    totalDevicesCount: UVGISummaryReducer.totalDevicesCount,
    timezone: rootReducer.timezone,
    deviceType: sharedSummaryReducer.activeTab,
    dateFilter: UVGISummaryReducer.dateFilter,
    period: UVGISummaryReducer.period,
    dateFilterLabel: UVGISummaryReducer.dateFilterLabel,
    locationFilter: UVGISummaryReducer.locationFilter,
    ids: UVGISummaryReducer.ids,
  }
}

export default connect(mapStateToProps)(
  withMediaQuery(withStyles(style)(UVGIDevicesSummaryInfo))
)
