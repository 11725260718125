import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles/index'
import style from './style'

const CloseButton = (props) => {
  const { onCloseModal, classes } = props

  return (
    <IconButton
      className={classes.closeIcon}
      aria-label="close"
      onClick={onCloseModal}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default withStyles(style)(CloseButton)
