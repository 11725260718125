import React from 'react'
import { ReactComponent as NetworkErrorIcon } from '../../../assets/icons/network_error.svg'
import ErrorPage from './ErrorPage'
import { NETWORK_ERROR } from './errors-constants'

const NetworkError = () => {
  return (
    <ErrorPage
      pageTitle={NETWORK_ERROR.generalName}
      errorDescriptionPart1={NETWORK_ERROR.description1}
      errorDescriptionPart2={NETWORK_ERROR.description2}
      goToBackText={NETWORK_ERROR.backToPreviousPage}
      hideLogo={true}
      Icon={NetworkErrorIcon}
    />
  )
}

export default NetworkError
