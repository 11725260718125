const infoTreeActions = {}

infoTreeActions.actionsTypes = {
  SET_EXPAND_NODE: '{INFO_TREE} Set Expand Node',
  LOAD_NODES_SUCCESS: '{INFO_TREE} Load Nodes Success',
}

infoTreeActions.setExpandNode = function (key) {
  return {
    type: infoTreeActions.actionsTypes.SET_EXPAND_NODE,
    payload: {
      key,
    },
  }
}

infoTreeActions.loadNodesSucceeded = function (nodes) {
  return {
    type: infoTreeActions.actionsTypes.LOAD_NODES_SUCCESS,
    payload: {
      nodes,
    },
  }
}

export default infoTreeActions
