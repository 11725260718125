import React from 'react'
import { map } from 'lodash'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import { VIEW_ASSIGNED_PROPERTIES_LABELS } from '../../services/account-constants'
import { withMediaQuery } from '../../../../services/root/root-service'
import DoneIcon from '@material-ui/icons/Done'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'

const ListModal = (props) => {
  const { isOpen, onClose, properties, isMobile, classes } = props

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
        <DialogTitle className={classes.modalTitleContainer} disableTypography>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            className={classes.modalTitle}
          >
            {isMobile
              ? VIEW_ASSIGNED_PROPERTIES_LABELS.myProperties
              : VIEW_ASSIGNED_PROPERTIES_LABELS.assignedLocations}
          </Typography>
          <CloseButton onCloseModal={onClose} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {map(properties, (property) => (
            <div className={classes.propertyWrapper} key={property}>
              <Typography
                className={classes.property}
                variant={isMobile ? 'subtitle1' : 'subtitle2'}
                color={isMobile ? 'textPrimary' : 'primary'}
              >
                {!isMobile && <DoneIcon className={classes.doneIcon} />}
                {property}
              </Typography>
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default withMediaQuery(withStyles(styles)(ListModal))
