import React from 'react'
import SvgIconComponent from '../../../SvgIconComponent'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import { COLORS, USER_ROLES } from '../../../../../services/constants'
import { startsWith, MENU_ELEMENT_ROLES } from './menu-constants'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

const changeCurrentUrl = (route, handleClose) => {
  window.location.href = route

  handleClose()
}

const getMenuConfig = (role, user, logout, handleClose) => {
  const isOperator = role === USER_ROLES.operatorUserRole
  const isIframe = window.location !== window.parent.location

  return {
    summary: {
      name: 'Summary Report',
      to: '/summary',
      width: '130px',
      disabled: false,
      icon: (path, classes) => (
        <InsertDriveFileIcon
          className={
            startsWith(path).summary
              ? classes.selectedIconWithHeight
              : classes.iconWithHeight
          }
        />
      ),
      visible: true,
      role: MENU_ELEMENT_ROLES.tab,
    },
    dashboard: {
      name: 'Recent Activity',
      to: '/dashboard',
      width: '117px',
      disabled: false,
      icon: (path, classes) => (
        <ListRoundedIcon
          className={
            startsWith(path).dashboard ? classes.selectedIcon : classes.icon
          }
        />
      ),
      visible: true,
      role: MENU_ELEMENT_ROLES.tab,
    },
    locations: {
      name: 'Locations',
      to: '/locations',
      width: '74px',
      disabled: false,
      icon: (path, classes) => (
        <LocationOnIcon
          className={
            startsWith(path).locations ? classes.selectedIcon : classes.icon
          }
        />
      ),
      visible: true,
      role: MENU_ELEMENT_ROLES.tab,
      popperValue: 'Set up Locations',
    },
    devices: {
      name: 'Devices',
      disabled: false,
      to: '/devices',
      width: '61px',
      icon: (path) => (
        <SvgIconComponent
          name="devices-2x2"
          size={26}
          height={18}
          color={
            startsWith(path).updateFirmware || startsWith(path).viewHistory
              ? COLORS.DODGER_BLUE
              : COLORS.CONTRAST_GRAY
          }
        />
      ),
      visible: true,
      role: MENU_ELEMENT_ROLES.tab,
      popperValue: 'Add Devices',
    },
    users: {
      name: 'Users',
      to: '/users',
      disabled: false,
      width: '43px',
      icon: (path, classes) => (
        <SupervisorAccountIcon
          className={
            startsWith(path).users ? classes.selectedIcon : classes.icon
          }
        />
      ),
      visible: !isOperator,
      role: MENU_ELEMENT_ROLES.tab,
      popperValue: 'Add Users',
    },
    resources: {
      name: 'Resources',
      disabled: false,
      to: 'https://rzerosystems.zendesk.com/hc/en-us',
      target: '_blank',
      icon: (path) => (
        <SvgIconComponent
          name="folder"
          size={22}
          color={
            startsWith(path).marketingMaterials
              ? COLORS.DODGER_BLUE
              : COLORS.CONTRAST_GRAY
          }
        />
      ),
      visible: true,
      role: MENU_ELEMENT_ROLES.link,
    },
    myAccount: {
      name: `${user.firstName || ''} ${user.lastName || ''}`,
      notClickable: true,
      visible: true,
      role: MENU_ELEMENT_ROLES.dropdown,
      disabled: true,
      subTabs: {
        settings: {
          name: 'Settings',
          longLine: true,
          visible: true,
          onClick: () => changeCurrentUrl('/#/account', handleClose),
        },
        logout2: {
          name: 'Log out',
          onClick: logout,
          longLine: true,
          visible: !isIframe,
        },
      },
    },
  }
}

export { getMenuConfig }
