import React from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import styles from './../style'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import LinkMaterial from '@material-ui/core/Link'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ViewListIcon from '@material-ui/icons/ViewList'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SvgIconComponent from '../../SvgIconComponent'
import { MENU_ITEMS_LABELS } from './services/menu-constants'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import classNames from 'classnames'
import { COLORS } from '../../../../services/constants'

const MenuPhoneComponent = withStyles(styles)((props) => {
  const {
    classes,
    isOperatorView,
    firstName,
    lastName,
    organizationName,
    logout,
  } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(false)
  }

  return (
    <>
      <IconButton
        className={classes.menuButton}
        onClick={handleClick}
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      <Drawer
        anchor="left"
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.paper }}
        transitionDuration={350}
        className={classes.drawer}
      >
        <Box marginBottom="20px">
          <div className={classes.name}>
            {firstName}&nbsp;{lastName}
          </div>
          <Typography variant="h6" className={classes.orgName} noWrap>
            {organizationName}
          </Typography>
        </Box>

        <List className={classes.tabsContainer}>
          <div>
            <ListItem
              className={classes.listItem}
              button
              key={MENU_ITEMS_LABELS.summaryReport}
              component={Link}
              to="/summary"
              onClick={handleClose}
            >
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText
                primary={MENU_ITEMS_LABELS.summaryReport}
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>

            <ListItem
              className={classes.listItem}
              button
              key={MENU_ITEMS_LABELS.recentActivity}
              component={Link}
              to="/dashboard"
              onClick={handleClose}
            >
              <ListItemIcon>
                <ViewListIcon />
              </ListItemIcon>
              <ListItemText
                primary={MENU_ITEMS_LABELS.recentActivity}
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>

            <ListItem
              className={classes.listItem}
              button
              key={MENU_ITEMS_LABELS.locations}
              component={Link}
              to="/locations"
              onClick={handleClose}
            >
              <ListItemIcon>
                <LocationOnIcon />
              </ListItemIcon>
              <ListItemText
                primary={MENU_ITEMS_LABELS.locations}
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>

            <ListItem
              className={classes.listItem}
              button
              key={MENU_ITEMS_LABELS.devices}
              component={Link}
              to="/devices"
              onClick={handleClose}
            >
              <ListItemIcon>
                <SvgIconComponent
                  name="devices-2x2"
                  size={26}
                  height={18}
                  color={COLORS.WHITE}
                />
              </ListItemIcon>
              <ListItemText
                primary={MENU_ITEMS_LABELS.devices}
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>

            {!isOperatorView && (
              <ListItem
                className={classes.listItem}
                button
                key={MENU_ITEMS_LABELS.users}
                component={Link}
                to="/users"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText
                  primary={MENU_ITEMS_LABELS.users}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                />
              </ListItem>
            )}

            <ListItem
              className={classes.listItem}
              button
              component={Link}
              key={MENU_ITEMS_LABELS.account}
              to="/account"
              onClick={handleClose}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText
                primary={MENU_ITEMS_LABELS.account}
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>

            <ListItem
              className={classes.listItem}
              href="https://rzerosystems.zendesk.com/hc/en-us"
              component={LinkMaterial}
              target="_blank"
              key={MENU_ITEMS_LABELS.resources}
            >
              <ListItemIcon>
                <SvgIconComponent
                  name="folder"
                  size={22}
                  color={COLORS.WHITE}
                />
              </ListItemIcon>
              <ListItemText
                primary={MENU_ITEMS_LABELS.resources}
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>
          </div>
          <div>
            <ListItem
              className={classNames(classes.noBorder, classes.listItem)}
              button
              key={MENU_ITEMS_LABELS.logout}
              onClick={logout}
            >
              <ListItemIcon>
                <SvgIconComponent
                  name="logout"
                  size={20}
                  color={COLORS.WHITE}
                />
              </ListItemIcon>
              <ListItemText
                primary={MENU_ITEMS_LABELS.logout}
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>
          </div>
        </List>
      </Drawer>
    </>
  )
})

const mapStateToProps = (state) => ({
  firstName: state.rootReducer.firstName,
  lastName: state.rootReducer.lastName,
  organizationName: state.rootReducer.organization.name,
})

export default connect(mapStateToProps, null)(MenuPhoneComponent)
