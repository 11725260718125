import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import styles from '../../../../../../components/_shared/modals/simpleModal/style'
import TreeList from '../../../../../../components/_shared/customTree/TreeList'
import { TREE_LEVELS } from '../../../../../../services/constants'
import { withMediaQuery } from '../../../../../../services/root/root-service'
import CloseButton from '../../../../../../components/_shared/modals/closeButton/CloseButton'

const AssignLocationModal = (props) => {
  const {
    onApply,
    modalTitle,
    isOpen,
    actionLabel,
    error,
    classes,
    isMobile,
    resetChanges,
  } = props

  return (
    <Dialog
      open={isOpen}
      onClose={resetChanges}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className={classes.modalTitleContainer} disableTypography>
        <Typography
          variant={isMobile ? 'subtitle2' : 'h5'}
          className={classes.modalTitle}
        >
          {modalTitle}
        </Typography>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <CloseButton onCloseModal={resetChanges} />
      </DialogTitle>
      <DialogContent>
        <TreeList mainLevel={TREE_LEVELS.PROPERTY} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onApply} variant="contained" color="primary">
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withMediaQuery(withStyles(styles)(AssignLocationModal))
