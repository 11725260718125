import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import UserEditPage from './UserEditPage'
import actions from '../../services/users-actions'
import { MANAGE_USERS_LABELS } from '../../services/users-constants'
import Typography from '@material-ui/core/Typography'
import LoaderComponent from '../../../../components/_shared/loader/Loader'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import { ArrowBack } from '@material-ui/icons'
import { USER_ROLES, USER_ROLES_LABELS } from '../../../../services/constants'
import NetworkError from '../../../../components/_shared/errors/NetworkError'

class UsersEditPageContainerComponent extends React.Component {
  componentDidMount() {
    const { loadUserInfo, loadAllRoles, loadUserLocations, match, isEdit } =
      this.props

    if (isEdit) {
      loadUserInfo(match.params.id)
    } else {
      loadUserLocations()
    }

    loadAllRoles()
  }

  onUpdateUser = (user) => {
    this.props.updateUser(user, this.props.user.id)
  }

  render() {
    const {
      classes,
      error,
      loadAllUserRolesError,
      roles,
      createUser,
      isEdit,
      user,
      organization,
      isLoading,
      nodesCount,
      isMobile,
      currentUserRole,
      allRoles,
      networkError,
    } = this.props

    const onClose = () => {
      window.location.href = '/#/users'
    }

    const getTitleWithBackButton = (title) => (
      <Box display="flex" justifyContent="center">
        <IconButton onClick={onClose} className={classes.backButton}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h3" className={classes.pageTitle}>
          {title}
        </Typography>
      </Box>
    )

    return (
      <LoaderComponent isLoading={isLoading}>
        <div className={classes.wrapper}>
          {!networkError && window.navigator.onLine ? (
            <div className={classes.userEditPageContainer}>
              <div className={classes.titles}>
                {isMobile ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.pageTitle}
                    >
                      {isEdit
                        ? MANAGE_USERS_LABELS.editUser
                        : MANAGE_USERS_LABELS.addUser}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.jobTitle}
                    >
                      {isEdit && USER_ROLES_LABELS[user.role]}
                      {user.role === USER_ROLES.operatorUserRole &&
                        ` - ${
                          user.allowedProperties
                            ? user.allowedProperties.length
                            : 'All'
                        } properties`}
                    </Typography>
                  </>
                ) : isEdit ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    {getTitleWithBackButton(
                      `${user.firstName} ${user.lastName}`
                    )}
                    <Typography variant="h4" className={classes.roleTitle}>
                      {USER_ROLES_LABELS[user.role]}
                    </Typography>
                  </Box>
                ) : (
                  <>{getTitleWithBackButton(MANAGE_USERS_LABELS.addUser)}</>
                )}

                <div className={classes.firstTriangle} />
                <div className={classes.secondTriangle} />
                <div className={classes.thirdTriangle} />
              </div>

              <div className={classes.userEditPage}>
                {isEdit ? (
                  <UserEditPage
                    roles={roles}
                    currentUserRole={currentUserRole}
                    error={error}
                    loadAllUserRolesError={loadAllUserRolesError}
                    user={user}
                    organization={organization}
                    updateUser={this.onUpdateUser}
                    nodesCount={nodesCount}
                    actionLabel={MANAGE_USERS_LABELS.updateUserAction}
                    allRoles={allRoles}
                  />
                ) : (
                  <UserEditPage
                    roles={roles}
                    currentUserRole={currentUserRole}
                    error={error}
                    loadAllUserRolesError={loadAllUserRolesError}
                    updateUser={createUser}
                    organization={organization}
                    nodesCount={nodesCount}
                    actionLabel={MANAGE_USERS_LABELS.addUserAction}
                  />
                )}
              </div>
            </div>
          ) : (
            <NetworkError />
          )}
        </div>
      </LoaderComponent>
    )
  }
}

const mapStateToProps = (state) => ({
  roles: state.usersReducer.roles,
  allRoles: state.usersReducer.allRoles,
  user: state.usersReducer.userInfo,
  error: state.usersReducer.error,
  loadAllUserRolesError: state.usersReducer.loadAllUserRolesError,
  organization: state.rootReducer.organization,
  currentUserRole: state.rootReducer.role,
  isLoading: state.usersReducer.isLoading,
  nodesCount: state.customTreeReducer.nodes.length,
  networkError: state.rootReducer.networkError,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllRoles: () => dispatch(checkConnection(actions.loadAllRoles)),
    createUser: (user) =>
      dispatch(checkConnection(() => actions.createUser(user))),
    updateUser: (user, id) =>
      dispatch(checkConnection(() => actions.updateUser(user, id))),
    loadUserInfo: (id) =>
      dispatch(checkConnection(() => actions.loadUserInfo(id))),
    loadUserLocations: () =>
      dispatch(checkConnection(actions.loadUserLocations)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(UsersEditPageContainerComponent)))
