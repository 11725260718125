import React from 'react'
import Icons from './../../assets/icons/icons.svg'

const SvgIconComponent = ({ name, color, size, height }) => (
  <svg
    className={`icon icon-${name}`}
    fill={color}
    width={size}
    height={height || size}
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
)

export default SvgIconComponent
