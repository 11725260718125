import { COLORS } from '../../../../../services/constants'

const styles = (theme) => ({
  wrapper: {
    width: '24px',
    height: '24px',
    display: 'flex',
    margin: '5px',
  },
  statusDot: {
    borderRadius: '12px',
    height: '12px',
    width: '12px',
    justifyContent: 'center',
  },
  ACTIVE: {
    backgroundColor: 'rgba(55, 179, 74, 0.3)',
    height: '24px',
    width: '24px',
  },
  OFF: {
    backgroundColor: COLORS.WHITE,
    boxSizing: 'border-box',
    margin: '6px',
    border: `2px solid ${COLORS.DARK_GRAY}`,
    borderRadius: '10px',
  },
  STAND_BY: {
    backgroundColor: COLORS.DARK_GRAY,
    margin: '6px',
  },
  DISCONNECTED: {
    backgroundColor: COLORS.ORANGE_BRANDY_PUNCH,
    margin: '6px',
  },
  ERROR: {
    backgroundColor: COLORS.RED,
    margin: '6px',
  },
  OFF_ERROR: {
    backgroundColor: COLORS.WHITE,
    boxSizing: 'border-box',
    margin: '6px',
    border: `2px solid ${COLORS.RED}`,
    borderRadius: '10px',
  },
  animatedDot: {
    height: '24px',
    width: '24px',
    borderRadius: '12px',
    backgroundColor: COLORS.GREEN,
    animation: '$heartbeat 1.2s infinite',
  },

  '@keyframes heartbeat': {
    '0%': {
      transform: 'scale(0.6)',
    },
    '50%': {
      transform: 'scale(0.56)',
    },
    '100%': {
      transform: 'scale(0.6)',
    },
  },
  '@media (min-width: 1224px)': {
    wrapper: {
      width: 'inherit',
    },
    statusDot: {
      height: '16px',
      width: '16px',
      fontFamily: '20px',
      margin: 'auto 20px',
    },
    ACTIVE: {
      height: '24px',
      width: '24px',
    },
    whiteDot: {
      backgroundColor: COLORS.WHITE,
      margin: '2px',
      width: '12px',
      height: '12px',
      borderRadius: '8px',
    },
  },
})

export default styles
