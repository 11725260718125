import { COLORS } from '../../../services/constants'

const style = (theme) => ({
  emptyListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyListText: {
    lineHeight: '21px',
    color: COLORS.GRAY,
    width: '70%',
    textAlign: 'center',
  },
  iconWrapper: {
    marginTop: '23px',
    marginBottom: '23px',
  },
})

export default style
