import { COLORS } from '../../../services/constants'

const generalPagination = {
  palette: {
    primary: {
      main: COLORS.BLUE,
    },
    info: {
      main: COLORS.GRAY,
    },
  },
  text: {
    secondary: COLORS.GRAY,
  },
  overrides: {
    MuiPagination: {
      ul: {
        '& li': {
          '&:first-child': {
            '& button': {
              border: 'none',
            },
          },
          '&:last-child': {
            '& button': {
              border: 'none',
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      outlinedPrimary: {
        '&.Mui-selected': {
          backgroundColor: COLORS.WHITE,
        },
      },
    },
  },
}

export default generalPagination
