import React, { createRef, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import styles from './style'
import { withMediaQuery } from '../../../../services/root/root-service'
import { isMobileOnly } from 'react-device-detect'
import CloseButton from '../closeButton/CloseButton'

const SimpleModalComponent = withMediaQuery(
  withStyles(styles)((props) => {
    const {
      onSubmit,
      onClose,
      modalTitle,
      placeholder,
      isOpen,
      actionLabel,
      error,
      defaultValue,
      inputProps = {},
      isMobile,
      classes,
    } = props
    const inputRef = createRef()
    const [isEmptyTextField, setIsEmptyTextField] = useState(false)
    let timer = 0
    let prevent = true

    const onCloseModal = () => {
      setIsEmptyTextField(false)

      onClose()
    }

    const onSubmitButton = (event) => {
      event.preventDefault()
      const value = inputRef.current.value

      if (inputRef.current.value.trim() === '') {
        setIsEmptyTextField(true)
      } else {
        setIsEmptyTextField(false)

        if (error) {
          prevent = true
        }

        timer = setTimeout(function () {
          if (prevent) {
            onSubmit(value)
            prevent = false
          }
        }, 200)
      }
    }

    function handleDoubleClick() {
      clearTimeout(timer)
      prevent = true
    }

    const onKeyUp = (e) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        onSubmitButton(e)
      }
    }

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={onCloseModal}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            className={classes.modalTitleContainer}
            disableTypography
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              className={classes.modalTitle}
            >
              {modalTitle}
            </Typography>
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
            <CloseButton onCloseModal={onCloseModal} />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <TextField
              variant="outlined"
              inputRef={inputRef}
              error={isEmptyTextField || !!error}
              defaultValue={defaultValue}
              label={!isMobileOnly ? placeholder : ''}
              placeholder={isMobileOnly ? placeholder : ''}
              inputProps={{ className: classes.outlined, ...inputProps }}
              fullWidth
              autoFocus={!isMobileOnly}
              onKeyUp={onKeyUp}
            />
            <Button
              onDoubleClick={handleDoubleClick}
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={onSubmitButton}
            >
              {actionLabel}
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    )
  })
)

export default SimpleModalComponent
