import { createTheme } from '@material-ui/core/styles'
import generalPagination from './generalThemePart'

const paginationThemeDesktop = createTheme({
  palette: generalPagination.palette,
  text: generalPagination.text,
  overrides: {
    ...generalPagination.overrides,
  },
})

export default paginationThemeDesktop
