import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import NetworkError from '../../../../components/_shared/errors/NetworkError'
import { checkConnection } from '../../../../services/root/root-service'
import styles from './style'
import {
  CHANGE_ACCOUNT_OWNER_LABELS,
  CONFIRM_MODAL_LABELS,
} from '../../services/account-constants'
import actions from '../../services/account-actions'
import Loading from '../../../../components/_shared/loader/Loader'
import ConfirmModal from '../../../../components/_shared/modals/confirmModal/ConfirmModal'
import {
  _accountOwnerTemplate,
  getConfirmText,
  getUserName,
} from '../../services/account-service'

const ChangeAccountOwnerComponent = (props) => {
  const {
    classes,
    owners = [],
    loadPotentialAccountOwners,
    isLoading,
    error,
    changeAccountOwner,
    networkError,
  } = props

  const [accountOwner, setAccountOwner] = useState('')
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmText, setConfirmText] = useState('')

  useEffect(() => {
    loadPotentialAccountOwners()
  }, [])

  const changeAccount = (event) => {
    const id = event.target.value
    setAccountOwner(id)

    const userName = getUserName(owners, id)
    setConfirmText(getConfirmText(userName))
  }

  const createNewUser = () => {
    props.history.push('/users/add')
  }

  const closeConfirm = () => {
    setOpenConfirm(false)
  }

  const goToSummary = () => {
    closeConfirm()
    props.history.push('/summary')
  }

  const requestConfirm = () => {
    setOpenConfirm(true)
  }

  const saveChanges = () => {
    changeAccountOwner(accountOwner, goToSummary)
  }

  return (
    <Loading isLoading={isLoading && !networkError}>
      {networkError ? (
        <NetworkError />
      ) : (
        <div className={classes.pageContainer}>
          <Typography variant="subtitle2">
            {CHANGE_ACCOUNT_OWNER_LABELS.pageName}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.infoBlock}
          >
            {CHANGE_ACCOUNT_OWNER_LABELS.firstParagraph}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.infoBlock}
          >
            {CHANGE_ACCOUNT_OWNER_LABELS.secondParagraph}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.infoBlock}
          >
            {CHANGE_ACCOUNT_OWNER_LABELS.thirdParagraph}
          </Typography>

          <CustomSelect
            options={owners}
            label={CHANGE_ACCOUNT_OWNER_LABELS.customSelectLabel}
            onChange={changeAccount}
            value={accountOwner}
            valueName="id"
            template={_accountOwnerTemplate}
          />
          <Typography className={classes.newUserLink} variant="body2">
            <Link onClick={createNewUser} color="textSecondary">
              {CHANGE_ACCOUNT_OWNER_LABELS.createNewUser}
            </Link>
          </Typography>

          <Button
            color="primary"
            variant="contained"
            error={error}
            onClick={requestConfirm}
            className={classes.changeOwnerButton}
            disabled={!accountOwner}
            type="submit"
          >
            {CHANGE_ACCOUNT_OWNER_LABELS.saveChanges}
          </Button>
          <ConfirmModal
            isOpen={openConfirm}
            error={error}
            onClose={closeConfirm}
            onSubmit={saveChanges}
            confirmText={confirmText}
            modalTitle={CONFIRM_MODAL_LABELS.modalTitle}
            actionLabel={CONFIRM_MODAL_LABELS.actionLabel}
            closeLabel={CONFIRM_MODAL_LABELS.closeLabel}
          />
        </div>
      )}
    </Loading>
  )
}

const mapStateToProps = (state) => ({
  error: state.accountReducer.error,
  owners: state.accountReducer.owners,
  isLoading: state.accountReducer.isLoading,
  networkError: state.rootReducer.networkError,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadPotentialAccountOwners: () =>
      dispatch(checkConnection(actions.loadPotentialAccountOwners)),
    changeAccountOwner: (id, onSuccess) =>
      dispatch(
        checkConnection(() => actions.changeAccountOwner(id, onSuccess))
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChangeAccountOwnerComponent))
