import { createAction } from 'redux-act'

const devicesActions = {}

export const CUMULATIVE_USAGE_START = createAction(
  '{Devices} Get Cumulative Usage'
)
export const CUMULATIVE_USAGE_SUCCEEDED = createAction(
  '{Devices} Cumulative Usage Succeeded'
)
export const CUMULATIVE_USAGE_FAILED = createAction(
  '{Devices} Cumulative Usage Failed'
)

devicesActions.actionTypes = {
  SET_TAB: '{Devices} Set Tab',

  SUBMIT_REQUEST: '{Devices} Submit Request',
  SUBMIT_REQUEST_SUCCEEDED: '{Devices} Submit Request Succeeded',
  SUBMIT_REQUEST_FAILED: '{Devices} Submit Request Failed',

  GET_SERVICE_REQUEST_MESSAGE: '{Devices} Get Service Request Message',
  GET_SERVICE_REQUEST_MESSAGE_SUCCEEDED:
    '{Devices} Get Service Request Message Succeeded',
  GET_SERVICE_REQUEST_MESSAGE_FAILED: '{Devices} Get Service Request Message',

  CLEAR_NODES: '{Devices} Clear Nodes',

  CUMULATIVE_USAGE_START: CUMULATIVE_USAGE_START.getType(),
  CUMULATIVE_USAGE_SUCCEEDED: CUMULATIVE_USAGE_SUCCEEDED.getType(),
  CUMULATIVE_USAGE_FAILED: CUMULATIVE_USAGE_FAILED.getType(),
}

devicesActions.clearNodes = function () {
  return {
    type: devicesActions.actionTypes.CLEAR_NODES,
  }
}

devicesActions.setTab = (tab) => ({
  type: devicesActions.actionTypes.SET_TAB,
  payload: {
    tab,
  },
})

devicesActions.submitRequest = (id, message, userProfileId) => ({
  type: devicesActions.actionTypes.SUBMIT_REQUEST,
  payload: {
    id,
    message,
    userProfileId,
  },
})

devicesActions.submitRequestSucceeded = () => ({
  type: devicesActions.actionTypes.SUBMIT_REQUEST_SUCCEEDED,
})

devicesActions.submitRequestFailed = (message) => ({
  type: devicesActions.actionTypes.SUBMIT_REQUEST_FAILED,
  payload: {
    message,
  },
})

devicesActions.getRequestServiceMessage = (deviceId, userId) => {
  return {
    type: devicesActions.actionTypes.GET_SERVICE_REQUEST_MESSAGE,
    payload: {
      deviceId,
      userId,
    },
  }
}

devicesActions.getRequestServiceMessageSucceeded = (data) => {
  return {
    type: devicesActions.actionTypes.GET_SERVICE_REQUEST_MESSAGE_SUCCEEDED,
    payload: {
      data,
    },
  }
}

devicesActions.getRequestServiceMessageFailed = (message) => {
  return {
    type: devicesActions.actionTypes.GET_SERVICE_REQUEST_MESSAGE_FAILED,
    payload: {
      message,
    },
  }
}

export default devicesActions
