import React, { useState } from 'react'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import WarningModalComponent from '../../../../../components/_shared/modals/warningConfirm/WarningModal'
import { DEVICE_DETAILS_LABELS } from '../../services/devices-constants'
import { DELETE_DEVICE } from '../../../Details/ARC/services/arcDeviceDetails-constants'
import { getDeviceDeleteMessage } from '../../services/devices-service'

const DeleteDeviceButtonComponent = withStyles(styles)((props) => {
  const { onSubmit, classes, nickName } = props
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmitButton = () => {
    handleClose()
    onSubmit()
  }

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        className={classes.deleteButton}
      >
        {DELETE_DEVICE}
      </Button>
      <WarningModalComponent
        isWarning
        isOpen={open}
        onClose={handleClose}
        onSubmit={onSubmitButton}
        dialogHelperText={getDeviceDeleteMessage(nickName)}
        buttonLabel={DEVICE_DETAILS_LABELS.deleteButtonLabel}
      />
    </>
  )
})

export default DeleteDeviceButtonComponent
