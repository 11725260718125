import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../style'
import { STATUS_FILTER_VALUES } from '../../../../../../services/constants'
import {
  getColor,
  getRemainingCountdownTime,
} from '../../../../services/related/transaction-service'
import { FIELDS } from '../../../../services/dashboard-constants'
import RunTimeComponent from '../UVGI/RunTimeComponent'
import ActivityExtraInfo from '../ActivityExtraInfo'
import CountDownComponent from './CountdownComponent'

const ActivityDetailsComponent = withStyles(styles)((props) => {
  const {
    classes,
    activity,
    activity: { txStatus, created, modified, details },
    timezone,
    isErrorCalculation,
    activityEvents,
    isMobile,
  } = props
  const countdownStart = details[FIELDS.countdownStart]

  const isInProgress = txStatus === STATUS_FILTER_VALUES.in_progress
  const isCountDown =
    txStatus === STATUS_FILTER_VALUES.paused &&
    details[FIELDS.pausePhase] === FIELDS.countdown
  const interruptionPeriod = activity.details[FIELDS.interruptionPeriod]
  const disconnectedTime = activity.details[FIELDS.disconnected]

  const timeRemaining = getRemainingCountdownTime(countdownStart)
  const [remainingValue, setRemainingValue] = useState(timeRemaining)
  const [counter, setCounter] = useState(timeRemaining)

  useEffect(() => {
    let isMounted = true

    counter >= 1000 &&
      isCountDown &&
      setTimeout(() => {
        if (isMounted) {
          setCounter(counter - 1000)
          setRemainingValue(getRemainingCountdownTime(countdownStart))
        }
      }, 1000)

    return () => (isMounted = false)
  }, [counter])

  return (
    <div className={classes.infoContainer}>
      <ActivityExtraInfo
        activity={activity}
        isErrorCalculation={isErrorCalculation}
        timezone={timezone}
        isMobile={isMobile}
        activityEvents={activityEvents}
      />
      <div className={classes.progress}>
        {isCountDown ? (
          <CountDownComponent
            progressValue={remainingValue}
            isErrorCalculation={isErrorCalculation}
            isMobile={isMobile}
          />
        ) : (
          <RunTimeComponent
            color={getColor(activity)}
            isErrorCalculation={isErrorCalculation}
            isInProgress={isInProgress}
            startTime={created}
            modifiedTime={modified}
            interruptionPeriod={interruptionPeriod}
            disconnectedTime={disconnectedTime}
            details={details}
          />
        )}
      </div>
    </div>
  )
})

export default ActivityDetailsComponent
