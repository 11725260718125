import { combineReducers } from 'redux'
import { ARCDeviceDetailsReducer } from '../../Details/ARC/services/arcDeviceDetails-reducer'
import { UVGIDeviceDetailsReducer } from '../../Details/UVGI/services/uvgiDeviceDetails-reducer'
import { ARCDeviceListReducer } from '../../List/ARC/services/arcDeviceList-reducer'
import { UVGIDeviceListReducer } from '../../List/UVGI/services/uvgiDeviceList-reducer'
import { sharedDevicesReducer } from './devices-reducer'
import { extDeviceListReducer } from '../../List/EXT_DEVICE/services/extDeviceList-reducer'
import { extDeviceDetailsReducer } from '../../Details/EXT_DEVICE/services/extDeviceDetails-reducer'

export const devicesReducer = combineReducers({
  sharedDevicesReducer,
  ARCDeviceListReducer,
  UVGIDeviceListReducer,
  extDeviceListReducer,
  ARCDeviceDetailsReducer,
  extDeviceDetailsReducer,
  UVGIDeviceDetailsReducer,
})
