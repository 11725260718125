import React from 'react'
import { map, uniqueId, filter, sortBy } from 'lodash'
import UVGIDeviceCard from './deviceCard/UVGIDeviceCard'
import Typography from '@material-ui/core/Typography'
import {
  checkDeviceByProperty,
  getFullRevertPathForDevice,
  getProperties,
} from '../services/uvgiDeviceList-service'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'
import { withMediaQuery } from '../../../../../services/root/root-service'
import { connect } from 'react-redux'
import EmptyDevicesPageComponent from '../../../_shared/components/emptyDevicesPage/EmptyDevicesPage'

const UVGIDeviceList = (props) => {
  const { devices, history, isMobile, error, activeTab, classes } = props

  const properties = getProperties(devices)

  const getDevicesList = (property) => {
    const filteredByProperty = filter(devices, (item) =>
      checkDeviceByProperty(item, property)
    )
    const sortedDevicesAlphabetically = sortBy(
      filteredByProperty,
      getFullRevertPathForDevice
    )

    return map(sortedDevicesAlphabetically, (item) => (
      <UVGIDeviceCard
        key={item.deviceId}
        history={history}
        activeTab={activeTab}
        device={item}
      />
    ))
  }

  return (
    <div className={classes.contentWrapper}>
      {error && (
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      )}
      {properties && properties.length ? (
        map(properties, (property) => (
          <div key={uniqueId()}>
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              className={classes.propertyName}
            >
              {property}
            </Typography>
            {getDevicesList(property)}
          </div>
        ))
      ) : (
        <EmptyDevicesPageComponent />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { UVGIDeviceListReducer, sharedDevicesReducer },
  } = state

  return {
    devices: UVGIDeviceListReducer.list,
    error: UVGIDeviceListReducer.error,
    activeTab: sharedDevicesReducer.tab,
  }
}

export default connect(mapStateToProps)(
  withMediaQuery(withStyles(styles)(UVGIDeviceList))
)
