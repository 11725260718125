import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import styles from './style'
import SvgIconComponent from '../SvgIconComponent'
import { COLORS } from '../../../services/constants'

const EmptyScreenComponent = (props) => {
  const { helperText, classes } = props

  return (
    <div className={classes.emptyListWrapper}>
      <div className={classes.iconWrapper}>
        <SvgIconComponent
          name="devices-2x2"
          size={54}
          color={COLORS.LIGHT_GRAY_SECONDARY}
        />
      </div>
      <Typography variant="subtitle1" className={classes.emptyListText}>
        {helperText}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(EmptyScreenComponent)
