import {
  forEach,
  forIn,
  map,
  filter,
  indexOf,
  entries,
  every,
  sortBy,
} from 'lodash'
import * as moment from 'moment'

import { convertTodayToStringWithTimezone } from '../../../../services/dateFormat-service'
import { MAIN_LABELS, STATUS_LABELS } from '../../../../services/constants'
import { isArc } from '../../../../services/root/root-service'

const getStatusFilterLabel = (selectedStatuses) => {
  const filterLabel = []

  forIn(selectedStatuses, function (item, key) {
    if (item) {
      filterLabel.push(STATUS_LABELS[key])
    }
  })

  return filterLabel.length ? filterLabel.join() : MAIN_LABELS.none
}

const getDevicesTypesFilterLabel = (types, selectedTypes) => {
  const filterLabel = []

  forIn(selectedTypes, (item, key) => {
    if (item) {
      filterLabel.push(types[key])
    }
  })

  return filterLabel.length ? filterLabel.join() : MAIN_LABELS.none
}

const prepareTimeFilter = (type, period, timezone) => {
  const todayWithTimezone = convertTodayToStringWithTimezone(timezone)
  const todayLocal = convertTodayToStringWithTimezone()
  const isSameDay = moment(todayWithTimezone).isSame(todayLocal)
  // account in previous day from user
  const isBefore = moment(todayWithTimezone).isBefore(todayLocal)

  if (type.today) {
    if (isSameDay) {
      return {
        startDate: moment(),
        endDate: isSameDay ? moment() : moment().add(1, 'd'),
      }
    } else {
      return isBefore
        ? {
            startDate: moment().subtract(1, 'days'),
            endDate: moment().subtract(1, 'days'),
          }
        : {
            startDate: moment().add(1, 'd'),
            endDate: isSameDay ? moment() : moment().add(1, 'd'),
          }
    }
  }

  if (type.week) {
    return isBefore
      ? {
          startDate: moment().subtract(8, 'days'),
          endDate: moment().subtract(1, 'days'),
        }
      : {
          startDate: moment().subtract(1, 'week'),
          endDate: isSameDay ? moment() : moment().add(1, 'day'),
        }
  }

  if (type.month) {
    return isBefore
      ? {
          startDate: moment().subtract(1, 'months').subtract(1, 'days'),
          endDate: moment().subtract(1, 'days'),
        }
      : {
          startDate: moment().subtract(1, 'months'),
          endDate: isSameDay ? moment() : moment().add(1, 'day'),
        }
  }

  if (type.custom) {
    if (isSameDay) {
      return {
        startDate: moment(period.startDate),
        endDate: period.endDate
          ? moment(period.endDate)
          : moment(period.startDate),
      }
    } else {
      return isBefore
        ? {
            startDate: moment(period.startDate),
            endDate: period.endDate
              ? moment(period.endDate)
              : moment(period.startDate),
          }
        : {
            startDate: moment(period.startDate),
            endDate: period.endDate
              ? moment(period.endDate)
              : moment(period.startDate),
          }
    }
  }
}

const prepareDevicesTypesFilter = (devicesTypes) => {
  const result = {}

  forEach(Object.entries(devicesTypes), ([key, value]) => {
    result[key] = true
  })

  return result
}

const filterDevicesByType = (devices, devicesTypesFilter) => {
  if (every(devicesTypesFilter)) {
    return devices
  }

  let filteredDevices = []

  forEach(entries(devicesTypesFilter), ([key, value]) => {
    if (value) {
      filteredDevices = [
        ...filteredDevices,
        ...filter(
          devices,
          (device) =>
            (isArc(key) && device.deviceType === key) ||
            device.deviceSubType === key
        ),
      ]
    }
  })

  return sortBy(filteredDevices, 'serialNumber')
}

const prepareDeviceFilter = (selectedTypes, state) => {
  const {
    devices,
    deviceFilter: { selectedDevices, isAllDevicesSelected, includeDeleted },
  } = state

  const newSelected = filter(devices, (device) => {
    const isInSelected = indexOf(selectedDevices, device.id) !== -1

    const key = isArc(device.deviceSubType)
      ? device.deviceType
      : device.deviceSubType

    return isInSelected && selectedTypes[key]
  })

  return {
    selectedDevices: map(newSelected, 'id'),
    isAllDevicesSelected,
    includeDeleted,
  }
}

export {
  getStatusFilterLabel,
  getDevicesTypesFilterLabel,
  prepareTimeFilter,
  prepareDevicesTypesFilter,
  filterDevicesByType,
  prepareDeviceFilter,
}
