const styles = (theme) => ({
  downloadButton: {
    width: '200px',
    backgroundColor: '#ffffff',
    fontFamily: 'Cerebri Sans Pro Regular',
    fontcolor: '#6D6E70',
    paddingLeft: '5px',
    paddingTop: '5px',
    '& > .MuiChip-label': {
      paddingBottom: '5px',
      paddingLeft: '6px',
    },
  },
})

export default styles
