import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  userCard: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: COLORS.WARM_GRAY,
    height: '100px',
    alignItems: 'center',
    marginBottom: '5px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 94px)',
    borderRight: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  '@media (max-width: 1224px)': {
    infoOwner: {
      width: 'calc(100% - 46px)',
    },
  },
  infoColumn: {
    height: '50px',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '12px',
    '&:first-child': {
      borderBottom: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    },
  },
  iconRow: {
    width: '46px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  rightBorder: {
    borderRight: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  emailAndProps: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  editIcon: {
    color: COLORS.BLACK,
  },
  billingIcon: {
    fontSize: '12px',
    lineHeight: '17px',
    paddingLeft: '10px',
    paddingBottom: '3px',
    verticalAlign: 'middle',
  },
  '@media (min-width: 1224px)': {
    userCard: {
      backgroundColor: COLORS.WHITE,
      height: '80px',
      marginBottom: '10px',
    },
    info: {
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      borderRight: 'none',
      width: 'calc(100% - 55px)',
    },
    infoColumn: {
      height: '100%',
      justifyContent: 'center',
      marginLeft: '10px',
      '&:first-child': {
        borderBottom: 'none',
        minWidth: '250px',
        width: '250px',
        borderRight: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
      },
    },
    iconRow: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      width: '55px',
    },
    rowIcon: {
      color: COLORS.BLACK,
      height: '17px',
    },
    emailAndProps: {
      fontSize: '12px',
      marginBottom: '3px',
    },
    billingIcon: {
      fontSize: '17px',
    },
    iconButton: {
      height: '45px',
      width: '45px',
    },
    rightBorder: {
      borderRight: 'none',
    },
  },
})

export default styles
