import React from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import styles from './../style'
import {
  ASSIGN_CYCLE_TIME_MODAL,
  LOCATIONS_CONST,
} from '../service/location-constants'
import actions from '../service/location-actions'
import {
  checkConnection,
  withMediaQuery,
} from '../../../services/root/root-service'
import { generateLocationObj, getEditTitle } from '../service/location-service'
import AddLocationButtonsContainer from './addLocation/AddLocationButtonsContainer'
import EditLocationButton from './editModal/EditLocationButton'
import AssignCycleTimeButton from './assignCycleTimeModal/AssignCycleTimeButton'

const WORDING_MAP = {
  property: { singular: 'property', plural: 'properties' },
  building: { singular: 'building', plural: 'buildings' },
  floor: { singular: 'floor', plural: 'floors' },
  zone: { singular: 'zone', plural: 'zones' },
  room: { singular: 'room', plural: 'rooms' },
  placement: { singular: 'placement', plural: 'placements' },
  default: { singular: 'child', plural: 'children' },
}

const getSublevelWording = (children) => {
  const elems = children.reduce((subitems, child) => {
    subitems[child.locationType] = 1 + (subitems[child.locationType] || 0)
    return subitems
  }, {})

  return Object.keys(elems).reduce((acc, locationType, index) => {
    const { singular, plural } =
      WORDING_MAP[locationType] || WORDING_MAP['default']
    const label = `${elems[locationType]} ${
      elems[locationType] !== 1 ? plural : singular
    }`
    return acc + (index > 0 ? ', ' : '') + label
  }, '')
}

export const LocationCard = (props) => {
  const {
    classes,
    item,
    onNext,
    onAddLocation,
    deleteLocationWithSubLocations,
    history,
    isReadOnly,
    editLocation,
    isMobile,
    error,
    resetError,
    enableSetCycle,
    updateTimeoutForLocation,
    defaultTimeout,
  } = props
  const childNumbers = item.children.length
  const isNotEndPoint = item.locationType !== LOCATIONS_CONST.PLACEMENT
  const enableSetCycleFunctionality = !childNumbers && enableSetCycle

  const onNextLevel = () => {
    onNext(item)
  }

  const onDeleteLocation = (count) => {
    const { path } = item
    const way = path[path.length - 1].pathname.split('/')
    const parentParams = way[way.length - 2]
    let [, parentId] = parentParams.split('-')

    deleteLocationWithSubLocations({
      id: item.id,
      parentId,
      count,
    })
  }

  const onSubmitButton = (name, roomFields) => {
    const updatedLoc = generateLocationObj(item, name, roomFields)

    editLocation(updatedLoc)
  }

  return (
    <div className={classes.locationContainer}>
      <div className={classes.locationItem}>
        <Typography variant={isMobile ? 'subtitle1' : 'h4'} noWrap>
          {item.name}
        </Typography>
        {isNotEndPoint && !!childNumbers && (
          <Typography variant="body2" className={classes.locationCount}>
            {getSublevelWording(item.children)}
          </Typography>
        )}
        {enableSetCycleFunctionality && (
          <Typography
            variant="h6"
            color={isMobile ? 'textSecondary' : 'primary'}
            className={classes.cycleTime}
          >
            {item.timeout || defaultTimeout}:00{' '}
            {ASSIGN_CYCLE_TIME_MODAL.cycleTime}
          </Typography>
        )}
      </div>
      {!isReadOnly && (
        <div className={classes.controlsWrapper}>
          {!childNumbers && (
            <AddLocationButtonsContainer
              onSubmit={onAddLocation}
              isChild={true}
              parentId={item.id}
              deep={item.locationType}
              history={history}
            />
          )}
          {enableSetCycleFunctionality && (
            <div className={classes.navigationRowItem}>
              <AssignCycleTimeButton
                location={item}
                onSubmit={updateTimeoutForLocation}
                error={error}
                resetError={resetError}
              />
            </div>
          )}
          <div className={classes.navigationRowItem}>
            <EditLocationButton
              modalTitle={getEditTitle(item.name)}
              location={item}
              onDelete={onDeleteLocation}
              isMobile={isMobile}
              placeholder={item.name}
              onSubmit={onSubmitButton}
              error={error}
              resetError={resetError}
              type="child"
            />
          </div>
        </div>
      )}
      {isNotEndPoint && !!childNumbers && (
        <div className={classes.navigationRowItem}>
          <IconButton
            className={classes.rowIconButton}
            onClick={onNextLevel}
            aria-label="show more"
          >
            <ArrowForwardIosIcon className={classes.rowIcon} />
          </IconButton>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  editLocation: (updatedLoc, onSuccess) =>
    dispatch(
      checkConnection(() => actions.editLocation(updatedLoc, onSuccess))
    ),
  resetError: () => dispatch(actions.resetError()),
  setError: (error) => dispatch(actions.setError(error)),
  updateTimeoutForLocation: (updatedLoc) =>
    dispatch(
      checkConnection(() => actions.updateTimeoutForLocation(updatedLoc))
    ),
  deleteLocationWithSubLocations: (values) =>
    dispatch(
      checkConnection(() => actions.deleteLocationWithSubLocations(values))
    ),
})

const mapStateToProps = (state) => ({
  error: state.locationReducer.error,
  defaultTimeout: state.locationReducer.defaultTimeout,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(LocationCard)))
