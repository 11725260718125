import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import style from './style'
import NetworkError from '../../../../../components/_shared/errors/NetworkError'
import Loader from '../../../../../components/_shared/loader/Loader'
import { connect } from 'react-redux'
import { find, isEmpty } from 'lodash'
import { IDS_FOR_FEATURE_TOGGLING } from '../../../../../services/constants'
import EmptyScreen from '../../../../../components/_shared/emptyScreen/EmptyScreen'
import ExternalDevicesListPage from './ExternalDevicesListPage'
import { NO_PERMISSIONS } from '../services/extDeviceList-constants'
import extDeviceListActions from '../services/extDeviceList-actions'
import { checkConnection } from '../../../../../services/root/root-service'

const ExternalDevicesListContainer = (props) => {
  const {
    devices,
    loadDevicesList,
    isLoading,
    networkError,
    features,
    history,
    classes,
  } = props
  const [noPermissions, setNoPermissions] = useState(false)

  useEffect(() => {
    loadDevicesList()
  }, [])

  useEffect(() => {
    const extDevicesEnabled = find(
      features,
      (item) => item.id === IDS_FOR_FEATURE_TOGGLING.extDevice
    )

    if (isEmpty(extDevicesEnabled)) {
      setNoPermissions(true)
    }
  }, [features])

  return (
    <div className={classes.devicesPageWrapper}>
      <Loader isLoading={isLoading && !networkError}>
        {networkError ? (
          <NetworkError />
        ) : noPermissions ? (
          <EmptyScreen helperText={NO_PERMISSIONS} />
        ) : (
          <ExternalDevicesListPage history={history} devices={devices} />
        )}
      </Loader>
    </div>
  )
}

const mapStateToProps = (state) => ({
  features: state.rootReducer.features,
  devices: state.devicesReducer.extDeviceListReducer.list,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadDevicesList: () =>
      dispatch(checkConnection(extDeviceListActions.loadDevicesList)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ExternalDevicesListContainer))
