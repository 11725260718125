const BUTTONS = {
  updateLocation: 'Update Location',
  deleteLocation: 'Delete Location',
  viewUsers: 'View Users',
  viewDevices: 'View Devices',
}

const LOCATIONS_CONST = {
  PROPERTY: 'property',
  BUILDING: 'building',
  ZONE: 'zone',
  FLOOR: 'floor',
  ROOM: 'room',
  LOCATIONS: 'locations',
  PLACEMENT: 'placement',
}

const LOCATIONS_LABELS = {
  warningTitle: 'Warning',
  deleteWithSubLocations1: (
    locationName,
    subLocationsCount
  ) => `Are you sure you want to delete "${locationName}" and its 
    (${subLocationsCount}) sub-locations?`,
  deleteWithoutSubLocations1: (locationName) =>
    `Are you sure you want to delete "${locationName}"?`,
  commonMessage2:
    () => `You will not be able to assign users or devices to this location or filter recent activity
     by this location.`,
  commonMessage3: (locationName, subLocationCount) =>
    `"${locationName}"  ${
      subLocationCount ? ' and its sub-locations' : ''
    } will also be removed as a location option on your R-Zero Arc.`,
  deleteWarningConfirm: (subLocationsCount) =>
    `To delete, enter (${subLocationsCount}) below and click "Delete Location".`,

  assignedLocationsAndUsers: (locationName) =>
    `The following users and devices are currently assigned to ${locationName}. Please, re-assign them before ` +
    `deleting this location.`,
  back: 'Back',
}

const ADD_LOCATION_LABELS = {
  action: '+ Add Location',
  addBulk: 'Bulk Add',
}

const LOCATIONS_DOCUMENT_TITLE = 'Locations | R-Zero'

const LOCATIONS_PLACEHOLDERS = {
  [LOCATIONS_CONST.PROPERTY]: 'Property Name',
  [LOCATIONS_CONST.BUILDING]: 'Building Name',
  [LOCATIONS_CONST.ZONE]: 'Zone Name',
  [LOCATIONS_CONST.ROOM]: 'Room Number or Name',
  [LOCATIONS_CONST.PLACEMENT]: 'Placement Description',
}

const LOCATIONS_LABELS_MULTIPLE = {
  [LOCATIONS_CONST.PROPERTY]: 'Properties',
  [LOCATIONS_CONST.BUILDING]: 'Buildings',
  [LOCATIONS_CONST.ZONE]: 'Zones',
  [LOCATIONS_CONST.FLOOR]: 'Floors',
  [LOCATIONS_CONST.ROOM]: 'Rooms',
  [LOCATIONS_CONST.PLACEMENT]: 'Placements',
}

const ASSIGN_CYCLE_TIME_MODAL = {
  button: 'Set Default Cycle Time',
  title: 'Set Cycle Time',
  subtitle: 'minutes',
  textForDefaultGlobal:
    'This will set a default cycle time for your devices. You will still be able to change the' +
    ' cycle time on the device itself.',
  textForDefaultGlobal2:
    'Cycle times for specific locations can be assigned in the locations tab.',
  actionLabel: 'Confirm',
  defaultGlobalValue: 7,
  maxValue: 25,
  minValue: 3,
  cycleTime: 'Cycle Time',
  validationMessage: 'Please, enter a value from 3 to 25.',
  disinfectSpaceDaily: 'Disinfect this Space Daily',
  timeoutPattern: /^\d+$/,
}

const PROPERTY_NAME = {
  // eslint-disable-next-line
    notAllowedSymbols: /[\@\$\^\_\~\`\\\|\{\}\[\]]/,
  helperText: 'The following symbols are not allowed: @ $ ^ _ ~ ` \\ | { } [ ]',
  lengthExceeds: ' location name exceeds 30 characters.',
}

const ROOMS_FIELDS = {
  baselineAch: 'Baseline ACH',
  improvedEAch: 'Improved eACH with R-Zero',
}

const roomsFilterInitial = {
  baselineAch: '',
  improvedEAch: '',
}

export {
  BUTTONS,
  LOCATIONS_CONST,
  LOCATIONS_LABELS,
  ADD_LOCATION_LABELS,
  LOCATIONS_DOCUMENT_TITLE,
  LOCATIONS_PLACEHOLDERS,
  ASSIGN_CYCLE_TIME_MODAL,
  PROPERTY_NAME,
  LOCATIONS_LABELS_MULTIPLE,
  ROOMS_FIELDS,
  roomsFilterInitial,
}
