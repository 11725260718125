import { concat, map, uniqueId } from 'lodash'

const prepareInfoTree = (params) => {
  const nodeList = concat([], params.children)

  const deeps = ['area', 'property', 'building', 'zone']

  var traverse = function (current, deep, parent) {
    //process current node here
    //visit children of current
    map(current, (item, key) => {
      item.isParent = item.children && !!item.children.length
      item.value = uniqueId('children_')
      item.deep = deeps[deep]

      // default values
      item.expanded = true

      if (parent) {
        item.parent = parent
      }

      if (item.isParent) {
        let nextDeep = deep + 1

        traverse(item.children, nextDeep, item.value)
      }
    })
  }

  traverse(nodeList, 1)

  return nodeList
}

const changeExpanded = (value, locations) => {
  const nodeList = concat([], locations)

  var traverse = function (current) {
    map(current, (item, key) => {
      if (item.value === value) {
        item.expanded = !item.expanded
      }

      if (item.isParent) {
        traverse(item.children)
      }
    })
  }

  traverse(nodeList)

  return nodeList
}

export { prepareInfoTree, changeExpanded }
