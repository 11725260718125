const styles = (theme) => ({
  devicePage: {
    height: '100%',
    width: '100%',
  },
  infoWrapper: {
    paddingBottom: '90px',
    height: 'calc(100% - 80px)',
    overflowY: 'hidden',
  },
  infoWrapperWhenCycleEnabled: {
    height: 'calc(100% - 165px)',
  },
  listWrapper: {
    overflowY: 'auto',
    position: 'relative',
    display: 'block',
    height: 'calc(100% - 200px)',
    overflowX: 'hidden',
  },
  '@media (min-width: 1224px)': {
    infoWrapper: {
      height: '100%',
      width: '80%',
    },
    infoWrapperWhenCycleEnabled: {
      height: '100%',
    },
    desktopContentWrapper: {
      width: '80%',
      margin: 'auto',
      height: 'calc(100% - 20px)',
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '20px 0',
    },
  },
})

export default styles
