import { map, forEach, uniqBy, trim } from 'lodash'
import {
  NOT_REQUIRED_ORGANIZATION_FIELDS,
  NOT_REQUIRED_USER_FIELDS,
  ORGANIZATION_INFO_LABELS,
  USER_INFO_LABELS,
} from './account-constants'
import filterQuery from '../../../services/filterQuery-service'
import { USER_ROLES } from '../../../services/constants'

function validateAccountForm(organization, user) {
  const errors = {}

  forEach(ORGANIZATION_INFO_LABELS, (value, key) => {
    if (
      (!organization[key] || !trim(organization[key])) &&
      NOT_REQUIRED_ORGANIZATION_FIELDS.indexOf(key) === -1
    ) {
      organization[key] = null
      errors[key] = true
    }
  })

  forEach(USER_INFO_LABELS, (value, key) => {
    if (!user[key] && NOT_REQUIRED_USER_FIELDS.indexOf(key) === -1) {
      user[key] = null
      errors[key] = true
    }
  })

  return errors
}

//https://localhost:8443/api/secured/user-profiles/scroll?query=roleAuthorities unnestIn ['ADMIN_USER']
const prepareAccountOwnerQuery = () => {
  const authoritiesArray = []

  authoritiesArray.push("'" + USER_ROLES.adminUserRole + "'")
  authoritiesArray.push("'" + USER_ROLES.adminBillingUserRole + "'")

  return filterQuery.getArrayString(
    authoritiesArray,
    filterQuery.templates.array,
    'roleId',
    'in',
    true
  )
}

const getConfirmText = (name) => {
  return `Please confirm that you are transitioning account ownership to ${name}`
}

const _accountOwnerTemplate = (item) => `${item.firstName} ${item.lastName}`

const getUserName = (owners, id) => {
  let name = ''

  forEach(owners, (item) => {
    if (item.id === id) {
      name = _accountOwnerTemplate(item)
    }
  })

  return name
}

const states = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

const getGreeting = (user) => `Welcome, ${user.firstName} ${user.lastName}!`

const filterTimezones = (timezones) => uniqBy(timezones, 'timezoneName')

const prepareBodyForUpdate = (organization, user, timezone, emailPref = {}) => {
  const { timezoneName, abbreviation } = timezone
  const newOrganization = { ...organization, timezoneName, abbreviation }
  const { dailyReport, weeklyReport, monthlyReport } = emailPref

  delete user.abbreviation
  delete user.timezoneName

  return {
    ...user,
    organization: newOrganization,
    dailyReport,
    weeklyReport,
    monthlyReport,
  }
}

const USER_TIMEZONE = {
  ACCOUNT: 'account',
  LOCAL: 'local',
}

const getOnlyProperties = (tree) => {
  return tree.children && map(tree.children, (prop) => prop.name)
}

export {
  validateAccountForm,
  prepareAccountOwnerQuery,
  getConfirmText,
  _accountOwnerTemplate,
  getUserName,
  states,
  getGreeting,
  filterTimezones,
  prepareBodyForUpdate,
  USER_TIMEZONE,
  getOnlyProperties,
}
