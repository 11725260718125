import React from 'react'
import { SUMMARY_PAGE } from './arcSummary-constants'
import { forEach, floor, filter, reduce } from 'lodash'
import Highcharts from 'highcharts'
import {
  MMDFormat,
  yyyyMMDFormatForCharts,
} from '../../../../services/dateFormat-service'
import { prepareTimeFilter } from '../../../Dashboard/services/related/filter-service'
import { getLabelWithCount } from '../../../../services/root/root-service'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { COLORS } from '../../../../services/constants'

const cardsConfig = [
  {
    prop: 'totalCycles',
    label: () => SUMMARY_PAGE.completedCycles,
  },
  {
    prop: 'totalCyclesTime',
    label: (inMinutes) =>
      inMinutes
        ? SUMMARY_PAGE.minutesOfCycleTime
        : SUMMARY_PAGE.hoursOfCycleTime,
    convertToHours: true,
  },
  {
    prop: 'avgPerDayCycles',
    label: () => SUMMARY_PAGE.avgCyclesPerDay,
  },
  {
    prop: 'avgCyclesTime',
    label: () => SUMMARY_PAGE.avgTimePerCycle,
    convertToMinutes: true,
  },
]

const prepareIdsArray = (ids) => {
  let str = ''

  forEach(ids, (item) => {
    str += `lid=${item}&`
  })

  return str
}

const prepareCompletedCycles = (
  dateFilter,
  defaultPeriod,
  timezone,
  cycles
) => {
  const period = prepareTimeFilter(dateFilter, defaultPeriod, timezone)

  const startDate = period.startDate
  const endDate = period.endDate.add(1, 'days')

  return getDaysBetweenDates(cycles, startDate, endDate)
}

let getDaysBetweenDates = function (cycles, startDate, endDate) {
  let currentDate = startDate.clone()
  let dates = []

  while (currentDate.isBefore(endDate)) {
    // cycles of all Arc revisions from current date
    const currentDateCycles = filter(
      cycles,
      ({ id: { date } }) => date === currentDate.format(yyyyMMDFormatForCharts)
    )

    // sum of cycle count from different Arc revisions
    const numberOfCycles = reduce(
      currentDateCycles,
      (count, cycle) => count + cycle.count,
      0
    )

    dates.push({
      x: currentDate.format(MMDFormat),
      y: numberOfCycles,
      z: 0,
    })

    currentDate.add(1, 'days')
  }

  return dates
}

const createCompletedCyclesChart = (x, y, isMobile) => {
  const getOptions = (isMobile, animation = true, extraOptions = {}) => ({
    chart: {
      type: 'column',
      backgroundColor: isMobile ? COLORS.WARM_GRAY : COLORS.WHITE,
      style: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      ...extraOptions,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: x,
      crosshair: true,
    },
    title: {
      text: null,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      opposite: true,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<td style="padding:0"><b>{point.y} {point.label}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        animation,
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: !isMobile
          ? {
              enabled: true,
              crop: false,
              overflow: 'none',
              color: COLORS.GRAY_STORM_DUST,
              style: {
                fontWeight: 'normal',
              },
            }
          : {},
      },
    },
    series: [
      {
        data: y,
        color: COLORS.BLUE,
      },
    ],
  })
  Highcharts.chart('completed-cycles-container', getOptions(isMobile))
  Highcharts.chart(
    'completed-cycles-container-pdf',
    getOptions(true, false, { height: 300, width: 787, animation: false })
  )
}

const convertToMinutes = (number, isMobile, classes) => {
  const fullValue = moment.duration(number)
  const minutes = fullValue.asMinutes()
  const seconds = fullValue.seconds()

  const min = (
    <Typography
      variant="body2"
      component={'span'}
      className={classes.subTitles}
    >
      {minutes === 1 ? 'min' : 'mins'}
    </Typography>
  )
  const sec = (
    <Typography
      variant="body2"
      component={'span'}
      className={classes.subTitles}
    >
      sec
    </Typography>
  )

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent={isMobile ? 'flex-start' : 'center'}
    >
      {Math.trunc(minutes)}&nbsp;{min}&nbsp;{seconds}&nbsp;{sec}
    </Box>
  )
}

const convertToHours = (number) => {
  const fullValue = moment.duration(number)
  const days = floor(fullValue.asDays())
  const hours = fullValue.hours()
  const minutes = fullValue.minutes()
  const seconds = fullValue.seconds()

  if (fullValue.asHours() >= 1 || days >= 1) {
    return {
      value: `${days * 24 + hours}:${minutes >= 10 ? minutes : `0${minutes}`}`,
      inMinutes: false,
    }
  }

  return {
    value: `${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`,
    inMinutes: true,
  }
}

const getPieChart = (
  first,
  second,
  isMobile,
  id,
  firstTitle,
  secondTitle,
  dimension
) => {
  const getOptions = (isMobile, animation = true, extraOptions = {}) => ({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      backgroundColor: isMobile ? COLORS.WARM_GRAY : COLORS.WHITE,
      style: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      ...extraOptions,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: `<b>{point.percentage:.1f}%</b> | {point.y} {point.label}`,
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        animation,
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontWeight: 'normal',
      },
    },
    series: [
      {
        name: null,
        colorByPoint: true,
        data: [
          {
            name: firstTitle,
            y: first,
            label: getLabelWithCount(first, dimension, false),
            color: COLORS.BLUE,
          },
          {
            name: secondTitle,
            y: second,
            label: getLabelWithCount(second, dimension, false),
            color: COLORS.GRAY_MOUNTAIN_MIST,
          },
        ],
      },
    ],
  })
  Highcharts.chart(`${id}-container`, getOptions(isMobile))
  Highcharts.chart(
    `${id}-container-pdf`,
    getOptions(false, false, { width: 326, height: 300, animation: false })
  )
}

export {
  cardsConfig,
  prepareIdsArray,
  prepareCompletedCycles,
  createCompletedCyclesChart,
  getDaysBetweenDates,
  convertToMinutes,
  convertToHours,
  getPieChart,
}
