import React from 'react'
import { uniqueId, map } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { UNKNOWN_LOCATION } from '../../../../../services/constants'
import styles from '../style'
import {
  getMessageText,
  extractCancellationError,
} from '../../../services/dashboard-service'
import {
  NOTIFICATION_LABEL,
  INCORRECT_TRANSACTION_DESCRIPTION,
  OK_LABEL,
  FIELDS,
  DEVICE_ERROR_CODE,
} from '../../../services/dashboard-constants'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import classnames from 'classnames'

const ActivitiesLogDialogComponent = withStyles(styles)((props) => {
  const {
    classes,
    timezone,
    activity,
    isOpen,
    handleClose,
    isIncorrect,
    isInterruption,
    isFaults,
  } = props

  const activityEvents = activity.details[FIELDS.interruptionTimes]
  const messageText = getMessageText(activityEvents, timezone)
  const location =
    activity.locationPath && activity.locationPath.length
      ? activity.locationPath[0]
      : UNKNOWN_LOCATION

  const descriptionClasses = isInterruption
    ? classnames(classes.dialogContent, classes.dialogDescription)
    : classes.dialogContent

  const errorCodesClasses =
    isInterruption || isIncorrect
      ? classnames(classes.dialogContent, classes.errorCodes)
      : classes.dialogContent

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className={classes.dialogContainer}
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            {NOTIFICATION_LABEL}
          </Typography>
          <Typography className={classes.dialogSubTitle} color="textSecondary">
            {location}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {isIncorrect && (
            <Typography className={descriptionClasses}>
              {INCORRECT_TRANSACTION_DESCRIPTION}
            </Typography>
          )}
          {isInterruption &&
            map(messageText, (item) => (
              <Typography key={uniqueId()} className={classes.dialogContent}>
                {item}
              </Typography>
            ))}
          {isFaults && (
            <>
              <Typography className={errorCodesClasses}>
                {DEVICE_ERROR_CODE}
              </Typography>
              <Typography className={classes.dialogContent}>
                {extractCancellationError(activity.details.metaInfo.cause)}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} variant="contained" color="primary">
            {OK_LABEL}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default ActivitiesLogDialogComponent
