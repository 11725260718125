import { COLORS } from '../../services/constants'

const styles = (theme) => ({
  usersPage: {
    paddingLeft: '7%',
    paddingRight: '7%',
    height: 'calc(100% - 81px)',
  },
  addUserButton: {
    height: '27px',
    width: 'fit-content',
    margin: '16px 0 18px 0',
  },
  buttonAndSearchWrapper: {
    '& > .spinner': {
      display: 'none',
    },
  },
  description: {
    fontSize: '10px',
    lineHeight: '12px',
    color: COLORS.GRAY,
  },
  roleOption: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '-8px 0',
  },
  roleSelect: {
    padding: 0,
  },
  locationField: {
    width: '100%',
    display: 'grid',
    borderRadius: '26px',
    paddingLeft: '15px',
    justifyContent: 'left',
    height: '56px',
    margin: '10px 0',
    fontSize: '16px',
    lineHeight: '19px',
    color: COLORS.GRAY,
  },
  blackColor: {
    color: COLORS.BLACK,
  },
  locationError: {
    borderColor: COLORS.RED_ORANGE,
    color: COLORS.RED_ORANGE,
  },
  userListContainer: {
    height: 'calc(100% - 200px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  userListContainerBannerOffset: {
    height: 'calc(100% - 290px)',
  },
  locationButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  locationLink: {
    fontSize: '12px',
    lineHeight: '23px',
    cursor: 'pointer',
  },
  searchField: {
    width: '100%',
  },
  searchIcon: {
    color: COLORS.BLUE,
  },
  '@media (min-width: 1224px)': {
    usersPage: {
      padding: '30px 0',
      width: '70%',
      margin: 'auto',
    },
    title: {
      textAlign: 'center',
      marginBottom: '10px',
    },
    addUserButton: {
      minWidth: '150px',
      height: 'auto',
    },
    searchIcon: {
      color: COLORS.BLUE,
    },
    buttonAndSearchWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      '&::after': {
        content: '""',
        display: 'block',
        width: '150px',
        height: '50px',
      },
    },
    userListContainer: {
      height: 'calc(100% - 180px)',
    },
    userListContainerBannerOffset: {
      height: 'calc(100% - 270px)',
    },
    input: {
      '&::placeholder': {
        color: COLORS.GRAY,
      },
    },
    searchField: {
      maxWidth: '300px',
      marginLeft: '40px',
    },
  },
  '@media screen and (max-width: 420px) and (max-height: 820px)': {
    userListContainer: {
      height: 'calc(100% - 240px)',
      paddingBottom: '40px',
    },
    userListContainerBannerOffset: {
      height: 'calc(100% - 330px)',
    },
  },
})

export default styles
