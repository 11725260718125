const styles = (theme) => ({
  badge: {
    width: 'fit-content',
    maxWidth: '86%',
  },
  checkbox: {
    '& svg': {
      width: '15px',
      height: '15px',
    },
  },
  checkboxLabel: {
    lineHeight: '14px',
    display: 'flex',
    paddingLeft: '10px',
    '& span': {
      fontSize: '12px',
    },
  },
  '@media (min-width: 1224px)': {
    checkboxLabel: {
      paddingLeft: '26px',
      '& span': {
        fontSize: '16px',
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
    checkbox: {
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
})

export default styles
