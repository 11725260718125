import React, { useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import TimerIcon from '@material-ui/icons/Timer'
import { withStyles } from '@material-ui/core'
import style from './style'
import AssignCycleTimeModal from './AssignCycleTimeModal'
import Button from '@material-ui/core/Button'
import { ASSIGN_CYCLE_TIME_MODAL } from '../../service/location-constants'

const AssignCycleTimeButton = (props) => {
  const {
    onSubmit,
    error,
    resetError,
    isGlobalCycleTimeModal = false,
    location,
    classes,
  } = props
  const [open, setOpen] = useState(false)
  const [errorValue, setError] = useState(error)

  useEffect(() => {
    setError(error)
  }, [error])

  const openModal = () => {
    resetError()
    setError('')

    setOpen(!open)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <div
      className={
        isGlobalCycleTimeModal ? classes.buttonWrapper : classes.iconWrapper
      }
    >
      {!isGlobalCycleTimeModal ? (
        <IconButton onClick={openModal} className={classes.timerButton}>
          <TimerIcon />
        </IconButton>
      ) : (
        <Button variant="outlined" onClick={openModal} fullWidth>
          {ASSIGN_CYCLE_TIME_MODAL.button}
        </Button>
      )}
      <AssignCycleTimeModal
        isOpen={open}
        isGlobalCycleTimeModal={isGlobalCycleTimeModal}
        onCloseModal={closeModal}
        onSubmit={onSubmit}
        location={location}
        error={errorValue}
        setError={setError}
      />
    </div>
  )
}

export default withStyles(style)(AssignCycleTimeButton)
