import {
  getFaultsList,
  getLastUsedDate,
} from '../../../../../_shared/services/devices-service'
import {
  hhmmaFormat,
  utcConverter,
} from '../../../../../../../services/dateFormat-service'

import { DEVICE_NOT_WORKING } from '../../../../../_shared/services/devices-constants'
import { FIELDS } from '../../../../../../Dashboard/services/dashboard-constants'
import FieldValue from './FieldValue'
import { RECENT_USAGE_DATA } from '../../../services/uvgiDeviceDetails-constants'
import React from 'react'
import { STATUS_FILTER_VALUES } from '../../../../../../../services/constants'
import Typography from '@material-ui/core/Typography'
import { getLabelWithCount } from '../../../../../../../services/root/root-service'
import { isEmpty } from 'lodash'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'

const RecentUsageData = (props) => {
  const { device, timezone, isError, faults, classes, cumulativeUsageInSecs } =
    props
  const deviceStartTime = device.created
    ? utcConverter(device.created, hhmmaFormat, timezone)
    : ''

  const deviceEndTime = device.modified
    ? utcConverter(device.modified, hhmmaFormat, timezone)
    : ''

  const errorCodes = getFaultsList(faults)

  const isInProgress =
    device.transactionStatus === STATUS_FILTER_VALUES.in_progress

  return (
    <div className={classes.recentUsageContainer}>
      <Typography variant="h5" className={classes.titleWrapper}>
        {RECENT_USAGE_DATA.title}
      </Typography>
      <FieldValue
        label={RECENT_USAGE_DATA.lastActive}
        value={
          !isEmpty(device)
            ? getLastUsedDate(device?.details[FIELDS.lastEvent], timezone)
            : ''
        }
      />
      <FieldValue
        label={RECENT_USAGE_DATA.startTime}
        value={!isEmpty(device) ? deviceStartTime : ''}
      />
      <FieldValue
        label={RECENT_USAGE_DATA.endTime}
        value={isInProgress || isEmpty(device) ? '' : deviceEndTime}
      />
      <FieldValue
        label={RECENT_USAGE_DATA.cumulativeUsage}
        value={
          cumulativeUsageInSecs !== null && cumulativeUsageInSecs > 0
            ? `${(cumulativeUsageInSecs / 60 / 60).toFixed(2)} hours`
            : RECENT_USAGE_DATA.noInformation
        }
      />
      {isError && (
        <FieldValue
          label={getLabelWithCount(
            faults.length,
            DEVICE_NOT_WORKING.statement,
            false
          )}
          value={errorCodes}
          isError
        />
      )}
    </div>
  )
}

export default withStyles(styles)(RecentUsageData)
