const styles = (theme) => ({
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '15px auto 0',
    width: '100%',
  },
  pageSettings: {
    display: 'flex',
    padding: '10px 0',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  '@media (min-width: 1224px)': {
    paginationContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '80%',
    },
  },
  '@media (min-width: 1903px)': {
    paginationContainer: {
      width: '68%',
    },
  },
  '@media screen and (max-width: 420px) and (max-height: 820px)': {
    paginationContainer: {
      height: '225px',
    },
  },
})

export default styles
