import { COLORS } from '../../../../../services/constants'

const styles = (theme) => ({
  infoContainer: {
    width: '100%',
    display: 'contents',
  },
  infoWrapper: {
    width: 'calc(61% + 17.5px)',
    display: 'flex',
  },
  secondaryText: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
  },
  mainText: {
    fontSize: '14px',
    lineHeight: '17px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  timeColumn: {
    minWidth: 'calc(38% - 40px)',
    borderLeft: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    paddingLeft: '10px',
    boxSizing: 'border-box',
  },
  statusColumn: {
    width: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  locationColumn: {
    minWidth: '90px',
    padding: '10px',
    borderLeft: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  circle: {
    height: '20px',
    width: '20px',
    margin: '0 2px',
    borderRadius: '50px',
    fill: COLORS.WARM_GRAY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media (max-width: 465px)': {
    timeColumn: {
      maxWidth: 'calc(38% - 40px)',
    },
    statusColumn: {
      minWidth: '35px',
    },
  },
  '@media (min-width: 1224px)': {
    infoContainer: {
      maxWidth: '40%',
      display: 'flex',
    },
    statusColumn: {
      width: '65px',
    },
    locationColumn: {
      width: 'calc(82% - 33px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '10px',
    },
    timeColumn: {
      width: '30%',
    },
    secondaryText: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    mainText: {
      fontSize: '17px',
      lineHeight: '20px',
    },
  },
  '@media (max-width: 1224px)': {
    secondaryText: {
      '&:hover': {
        whiteSpace: 'normal',
      },
    },
  },
})

export default styles
