import React, { useRef } from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { withStyles } from '@material-ui/core/styles'
import StatusDot from '../../../../_shared/components/statusDot/StatusDot'
import { DEVICE_STATUS } from '../../../../_shared/services/devices-constants'
import styles from './style'
import Typography from '@material-ui/core/Typography'
import {
  withMediaQuery,
  getLocationPath,
  getDevicePath,
} from '../../../../../../services/root/root-service'
import TooltipWrapper from '../../../../_shared/components/tooltipWrapper/TooltipWrapper'

const UVGIDeviceCard = (props) => {
  const { history, device, classes, isMobile, activeTab } = props
  const {
    lastEventLocationPath,
    calculatedParameters,
    deviceId,
    deviceNickname,
    deviceSerialNumber,
  } = device

  const nicknameRef = useRef()

  const path = lastEventLocationPath || []
  const [location, lastEventLocationPathText] = getLocationPath(path, true)

  const deviceStatusKey = calculatedParameters.device_status

  const goToDetails = () => {
    const path = getDevicePath(activeTab)

    history.push(`/devices/${path}/${deviceId}`)
  }

  return (
    <div className={classes.deviceCard} onClick={goToDetails}>
      <div className={classes.info}>
        <div className={classes.statusDotContainer}>
          <StatusDot status={deviceStatusKey} />
        </div>

        <div className={classes.columnContainer}>
          <div>
            <p className={classes.secondaryText}>
              {deviceNickname && deviceSerialNumber}
            </p>
            <TooltipWrapper
              title={deviceNickname || deviceSerialNumber}
              isMobile={isMobile}
              nicknameRef={nicknameRef}
            >
              <Typography className={classes.mainText} noWrap ref={nicknameRef}>
                {deviceNickname || deviceSerialNumber}
              </Typography>
            </TooltipWrapper>
          </div>

          <div>
            <p className={classes.secondaryText}>{DEVICE_STATUS.status}</p>
            <Typography className={classes.mainText} noWrap>
              {DEVICE_STATUS[deviceStatusKey]}
            </Typography>
          </div>
        </div>

        <div className={classes.columnContainer}>
          <div>
            <Typography className={classes.secondaryText} noWrap>
              {lastEventLocationPathText}
            </Typography>
            <Typography className={classes.mainText} noWrap>
              {location}
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.navigationRowItem}>
        <ArrowForwardIosIcon className={classes.rowIcon} />
      </div>
    </div>
  )
}

export default withMediaQuery(withStyles(styles)(UVGIDeviceCard))
