import {
  DATE_FILTER,
  LOCATION_FILTER_LABELS,
} from '../../../../services/constants'

const initialState = {
  isLoading: true,
  dateFilter: {
    today: false,
    week: true,
    month: false,
    custom: false,
  },
  period: {},
  error: '',
  loadSummaryDataError: '',
  summaryDataNetworkError: !window.navigator.onLine,
  locationFilter: {},
  deviceFilterLabel: LOCATION_FILTER_LABELS.all,
  dateFilterLabel: DATE_FILTER.week,
}

const SUMMARY_DOCUMENT_TITLE = 'Summary | R-Zero'

const SUMMARY_PAGE = {
  title: 'Summary',
  completedCycles: 'Completed Cycles',
  hoursOfCycleTime: 'Hours of Cycle Time',
  minutesOfCycleTime: 'Minutes of Cycle Time',
  avgCyclesPerDay: 'Avg. Cycles Per Day',
  avgTimePerCycle: 'Avg. Time Per Cycle',
}

const COMPLETED_CYCLES = {
  title: 'Completed Cycles',
  perDay: 'Per Day',
}

const DISINFECTED_LOCATIONS = {
  title: 'Disinfected Locations',
  totalLocations: (number) => `${number} Total Locations`,
  blueTitle: 'Disinfected Locations',
  grayTitle: 'Locations with no Cycles',
}

const LOCATIONS_TABLE = {
  title: 'Locations',
  subTitle: 'Date of Last Cycle',
  notDisinfected: 'Not Disinfected',
  firstColumn: 'Location',
  secondColumn: 'Last Run',
}

const DEVICES_TABLE = {
  title: 'Devices',
  subTitle: 'Number of Cycles',
  firstColumn: 'Device',
  secondColumn: 'Cycles',
  totalDevices: (number) => `${number} Total Devices`,
}

const ORDER = {
  asc: 'asc',
  desc: 'desc',
}

const ACTIVE_DEVICES_TITLE = 'Active Devices'

export {
  initialState,
  SUMMARY_DOCUMENT_TITLE,
  SUMMARY_PAGE,
  COMPLETED_CYCLES,
  DISINFECTED_LOCATIONS,
  LOCATIONS_TABLE,
  DEVICES_TABLE,
  ORDER,
  ACTIVE_DEVICES_TITLE,
}
