import React, { useState } from 'react'
import { connect } from 'react-redux'
import { forIn } from 'lodash'
import { withStyles } from '@material-ui/styles'

import Chip from '@material-ui/core/Chip'
import ChipsFilterModal from '../../../../../../components/_shared/modals/chipsFilter/ChipsFilterModal'

import {
  RANGE_TYPES,
  RANGE_TYPES_KEYS,
  RANGE_TYPE_FILTER,
  DEFAULT_RANGE_TYPE,
} from '../../../services/summary-constants'
import styles from './style'
import actions from '../../../services/summary-actions'

const RangeTypeFilter = (props) => {
  const { setRangeType, rangeType, classes } = props

  const [open, setOpen] = useState(false)
  const [currentRangeType, setCurrentRangeType] = useState(
    rangeType
      ? {
          [RANGE_TYPES_KEYS.WEEK]: rangeType === RANGE_TYPES_KEYS.WEEK,
          [RANGE_TYPES_KEYS.MONTH]: rangeType === RANGE_TYPES_KEYS.MONTH,
        }
      : { ...DEFAULT_RANGE_TYPE }
  )

  const [rangeTypeValue, setRangeTypeValue] = useState(
    rangeType ? RANGE_TYPES[rangeType] : RANGE_TYPES_KEYS.WEEK
  )
  const [rangeTypeLabel, setRangeTypeLabel] = useState(
    rangeType ? RANGE_TYPES[rangeType] : RANGE_TYPES.WEEK
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeSelected = (type) => {
    const updatedType = forIn(currentRangeType, (_value, key, object) => {
      object[key] = key === type
    })

    setCurrentRangeType(updatedType)
    setRangeTypeValue(type)
  }

  const onApply = () => {
    setRangeTypeLabel(RANGE_TYPES[rangeTypeValue])
    setRangeType(rangeTypeValue)

    handleClose()
  }

  return (
    <div className={classes.filterWrapper}>
      <Chip
        onClick={handleClickOpen}
        className={classes.filterChip}
        label={RANGE_TYPE_FILTER.chipLabel(rangeTypeLabel)}
        variant="outlined"
      />
      <ChipsFilterModal
        open={open}
        constants={RANGE_TYPES}
        onSubmitLabel={RANGE_TYPE_FILTER.apply}
        filterName={RANGE_TYPE_FILTER.label}
        statusFilter={currentRangeType}
        onApply={onApply}
        onChange={changeSelected}
        onClose={handleClose}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setRangeType: (type) => dispatch(actions.setRangeType(type)),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(RangeTypeFilter))
