import { isEmpty, forEach } from 'lodash'
import {
  getStorageData,
  removeStorageData,
  setStorageComplicatedData,
} from './defaultStore-sevice'
import { HOST } from './constants'

const createAuthProvider = ({
  accessTokenExpireKey,
  accessTokenKey,
  localStorageKey = 'RZONE_AUTH_TOKEN',
  needUpdateToken,
  onHydratation,
}) => {
  const tp = createTokenProvider({
    accessTokenExpireKey,
    accessTokenKey,
    localStorageKey,
    needUpdateToken,
    onHydratation,
  })

  const login = (newTokens) => {
    tp.setToken(newTokens)
  }

  const logout = () => {
    tp.setToken(null)
  }

  return [tp, login, logout]
}

const createTokenProvider = ({
  localStorageKey,
  accessTokenKey,
  accessTokenExpireKey,
  needUpdateToken,
  onHydratation,
}) => {
  let listeners = []

  const getTokenInternal = () => {
    const data = getStorageData(localStorageKey)

    return (data && JSON.parse(data)) || null
  }

  const subscribe = (listener) => {
    listeners.push(listener)
  }

  const unsubscribe = (listener) => {
    listeners = listeners.filter((l) => l !== listener)
  }

  const jwtExp = (token) => {
    if (!(typeof token === 'string')) {
      return null
    }

    const split = token.split('.')

    if (split.length < 2) {
      return null
    }

    try {
      const jwt = JSON.parse(atob(token.split('.')[1]))

      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
        return jwt.exp * 1000
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  const getExpire = (token) => {
    if (!token) {
      return null
    }

    if (accessTokenExpireKey) {
      return token[accessTokenExpireKey]
    }

    if (accessTokenKey) {
      const exp = jwtExp(token[accessTokenKey])

      if (exp) {
        return exp
      }
    }

    return jwtExp(token)
  }

  const isExpired = (exp) => {
    if (!exp) {
      return false
    }

    return Date.now() > exp
  }

  // 'http://localhost:8080'
  const updateToken = (token) =>
    fetch(HOST + '/api/public/refresh-jwt', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json;',
        'X-Content-Type-Options': 'nosniff',
        tokenIssuer: getTokenIssuer() || 0,
      }),
      body: JSON.stringify({ refreshToken: token.refreshToken }),
    }).then((r) => r.json())

  const checkExpiry = async () => {
    const token = getTokenInternal()

    if (token && isExpired(getExpire(token))) {
      const newToken = needUpdateToken ? await updateToken(token) : null

      if (newToken) {
        setToken(newToken)
      } else {
        removeStorageData(localStorageKey)
      }
    }
  }

  const getToken = async () => {
    await checkExpiry()

    if (accessTokenKey) {
      const token = getTokenInternal()

      return token && token[accessTokenKey]
    }

    return getTokenInternal()
  }

  const getTokenIssuer = () => {
    const token = getTokenInternal()

    return token && token['tokenIssuer']
  }

  const isLoggedIn = () => {
    const token = getTokenInternal()

    if (onHydratation) onHydratation(token)

    return !!token && !isEmpty(token)
  }

  const setToken = (token) => {
    const oldTokenString = getStorageData(localStorageKey)
    const tokenObj = oldTokenString && JSON.parse(oldTokenString)

    if (token) {
      if (tokenObj && !isEmpty(tokenObj)) {
        token.refreshToken = tokenObj.refreshToken
      }

      if (tokenObj && tokenObj.tokenIssuer) {
        token.tokenIssuer = tokenObj.tokenIssuer
      }

      setStorageComplicatedData(localStorageKey, token)
    } else {
      removeStorageData(localStorageKey)
    }

    notify()
  }

  const notify = () => {
    const isLogged = isLoggedIn()

    forEach(listeners, (l) => l(isLogged))
  }

  return {
    getToken,
    getTokenIssuer,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  }
}

export { createAuthProvider, createTokenProvider }
