import { isEmpty } from 'lodash'
import { prepareLocationFiltersForDevice } from '../../../Devices/_shared/services/devices-service'
import {
  getAllSelectedIds,
  getAllIds,
} from '../../_shared/services/summary-service'
import { initialState } from './arcSummary-constants'
import arcSummaryActions from './arcSummary-actions'
import { prepareCompletedCycles } from './arcSummary-service'

export const ARCSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case arcSummaryActions.actionsTypes.GET_CYCLES_REPORT:
    case arcSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case arcSummaryActions.actionsTypes.GET_CYCLES_REPORT_SUCCEEDED: {
      const { data, timezone } = action.payload

      if (!isEmpty(data)) {
        const completedCyclesData = prepareCompletedCycles(
          state.dateFilter,
          state.period,
          timezone,
          data.cycles
        )

        const dataCopy = { ...data }

        delete dataCopy.cycles

        return {
          ...state,
          ...dataCopy,
          completedCyclesData,
          loadSummaryDataError: '',
          isLoading: false,
          summaryIsEmpty: false,
        }
      }

      return {
        ...state,
        loadSummaryDataError: '',
        isLoading: false,
        summaryIsEmpty: true,
      }
    }
    case arcSummaryActions.actionsTypes.GET_CYCLES_REPORT_FAILED:
    case arcSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_FAILED:
    case arcSummaryActions.actionsTypes.GET_ACTIVE_DEVICES_FAILED: {
      const { message } = action.payload

      return {
        ...state,
        loadSummaryDataError: message,
        isLoading: false,
      }
    }
    case arcSummaryActions.actionsTypes.LOAD_LOCATIONS_FAILED: {
      const { message } = action.payload

      return {
        ...state,
        error: message,
        isLoading: false,
      }
    }
    case arcSummaryActions.actionsTypes.CHANGE_DATE_FILTER: {
      const { dateFilter, period, label } = action.payload

      return {
        ...state,
        dateFilter,
        period,
        dateFilterLabel: label,
      }
    }
    case arcSummaryActions.actionsTypes.APPLY_LOCATION_FILTER: {
      const { nodes } = action.payload
      const params = prepareLocationFiltersForDevice(nodes)
      const ids = getAllSelectedIds(nodes, params.selectedBuildings)

      return {
        ...state,
        locationFilter: params,
        ids,
      }
    }
    case arcSummaryActions.actionsTypes.PREPARE_LOCATION_FILTER: {
      const { nodes, tree } = action.payload
      const ids = getAllIds(tree.children)

      return {
        ...state,
        locationFilter: nodes,
        ids,
      }
    }
    case arcSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        totalLocationsCount: data.totalLocations,
        locations: data.locations,
        disinfectedLocationsCount: data.disinfectedLocations,
        loadSummaryDataError: '',
        isLoading: false,
      }
    }
    case arcSummaryActions.actionsTypes.GET_ACTIVE_DEVICES_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        totalDevicesCount: data.totalDevices,
        activeDevicesCount: data.activeDevices,
        devices: data.devices,
        loadSummaryDataError: '',
        isLoading: false,
      }
    }
    case arcSummaryActions.actionsTypes.PUT_LOCATION_FILTER: {
      const { locationFilter, ids } = action.payload

      return {
        ...state,
        locationFilter,
        ids,
      }
    }
    default:
      return state
  }
}
