import { includes } from 'lodash'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import authorizationActions from './../services/authorization-actions'

const FactoryLoginPlug = (props) => {
  const { loginFromFactory } = props

  useEffect(() => {
    if (includes(window.location.href, '/factory/')) {
      localStorage.setItem('loginMethod', 'factory')

      loginFromFactory()
    }
  }, [])

  return null
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginFromFactory: () => dispatch(authorizationActions.loginFromFactory()),
  }
}

export default connect(null, mapDispatchToProps)(FactoryLoginPlug)
