import summaryActions from './summary-actions'
import { DATE_FILTER } from '../../../../services/constants'
import { getAllSelectedIds, getAllIds } from '../services/summary-service'
import { RANGE_TYPES_KEYS } from './summary-constants'
import { prepareLocationFiltersForDevice } from '../../../Devices/_shared/services/devices-service'

export const sharedSummaryReducer = (
  state = {
    isLoading: true,
    dateFilter: {
      today: false,
      week: true,
      month: false,
      custom: false,
    },
    period: {},
    rangeType: RANGE_TYPES_KEYS.WEEK,
    locationFilter: {},
    filters: {},
    data: [],
    dateFilterLabel: DATE_FILTER.week,
    error: '',
  },
  action
) => {
  switch (action.type) {
    case summaryActions.actionsTypes.CHANGE_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload.deviceType,
      }
    }
    case summaryActions.actionsTypes.GET_CYCLES_FOR_TABLE: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case summaryActions.actionsTypes.GET_CYCLES_FOR_TABLE_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        data,
        error: '',
        isLoading: false,
      }
    }
    case summaryActions.actionsTypes.GET_CYCLES_FOR_TABLE_FAILED: {
      const { message } = action.payload

      return {
        ...state,
        error: message,
        isLoading: false,
      }
    }
    case summaryActions.actionsTypes.SET_RANGE_TYPE: {
      const { rangeType } = action.payload

      return {
        ...state,
        rangeType: rangeType,
      }
    }
    case summaryActions.actionsTypes.CHANGE_DATE_FILTER: {
      const { dateFilter, period, label } = action.payload

      return {
        ...state,
        dateFilter,
        period,
        dateFilterLabel: label,
      }
    }
    case summaryActions.actionsTypes.APPLY_LOCATION_FILTER: {
      const { nodes } = action.payload
      const params = prepareLocationFiltersForDevice(nodes)
      const ids = getAllSelectedIds(nodes, params.selectedBuildings)

      return {
        ...state,
        locationFilter: params,
        ids,
      }
    }
    case summaryActions.actionsTypes.PREPARE_LOCATION_FILTER: {
      const { nodes, tree } = action.payload
      const ids = getAllIds(tree.children)

      return {
        ...state,
        locationFilter: nodes,
        ids,
      }
    }
    case summaryActions.actionsTypes.PUT_LOCATION_FILTER: {
      const { locationFilter, ids } = action.payload

      return {
        ...state,
        locationFilter,
        ids,
      }
    }
    default:
      return state
  }
}
