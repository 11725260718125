import { call, fork, put, takeEvery, select, all } from 'redux-saga/effects'
import accountActions from './account-actions'
import { HttpClient } from '../../../services/HttpClient'
import {
  filterTimezones,
  getOnlyProperties,
  prepareAccountOwnerQuery,
} from './account-service'
import rootActions from '../../../services/root/root-actions'
import { ORGANIZATION_ALREADY_EXISTS } from './account-constants'
import { replace } from 'lodash'

export const getUserId = (state) => state.rootReducer.userId
export const getOrganizationId = (state) => state.rootReducer.organization.id

function* getOrganizationInfo(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/organizations/${id}`)
    })

    if (data.message) {
      yield put(accountActions.getOrganizationInfoFailed(data.message))
    } else {
      yield put(accountActions.getOrganizationInfoSuccess(data))
    }
  } catch (e) {
    yield put(accountActions.getOrganizationInfoFailed(e.message))
  }
}

function* getUserInfo(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/user-profiles/${id}`)
    })

    if (data.message) {
      yield put(accountActions.getUserInfoFailed(data.message))
    } else {
      yield put(accountActions.getUserInfoSuccess(data))
    }
  } catch (e) {
    yield put(accountActions.getUserInfoFailed(e.message))
  }
}

function* checkOrganizationInfo(action) {
  try {
    const { body, onSuccess } = action.payload
    const escapedOrg = replace(body.name, '/', '%2F')

    const data = yield call(() => {
      return HttpClient.get(
        `/api/public/organizations/exists/?phone=${
          body.secondaryPhone || ' '
        }&name=${escapedOrg}`
      )
    })

    if (data.message) {
      yield put(accountActions.checkOrganizationInfoFailed(data.message))
    } else {
      if (!data.exists) {
        yield put(accountActions.resetUpdateErrors())
        yield put(accountActions.getTimezonesByZip(body.zip, onSuccess))

        yield put(accountActions.checkOrganizationInfoSucceeded())
      } else {
        yield put(
          accountActions.checkOrganizationInfoFailed(
            ORGANIZATION_ALREADY_EXISTS
          )
        )
      }
    }
  } catch (e) {
    yield put(accountActions.checkOrganizationInfoFailed(e.message))
  }
}

function* updateAccountInfo(action) {
  try {
    const { body, logOut } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/user-profiles`, { body })
    })

    if (data.message) {
      yield put(accountActions.updateAccountInfoFailed(data.message))
    } else {
      yield put(accountActions.updateAccountInfoSuccess(body))

      logOut && logOut()
    }
  } catch (e) {
    yield put(accountActions.updateAccountInfoFailed(e.message))
  }
}

function* resendEmail(action) {
  try {
    const { email } = action.payload

    const data = yield call(() => {
      return HttpClient.post(
        '/api/public/user-profiles/resend-confirmation-code',
        { body: { email } }
      )
    })

    if (data.message) {
      yield put(accountActions.resendEmailFailed(data.message))
    } else {
      yield put(accountActions.resendEmailSuccess(data))
    }
  } catch (e) {
    yield put(accountActions.resendEmailFailed(e.message))
  }
}

function* setPassword(action) {
  try {
    const { body } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/user-profiles/confirm', { body })
    })

    if (data.message) {
      yield put(accountActions.setPasswordFailed(data.message))
    } else {
      yield put(accountActions.setPasswordSuccess())
    }
  } catch (e) {
    yield put(accountActions.resendEmailFailed(e.message))
  }
}

function* loadAccountOwners() {
  try {
    const query = prepareAccountOwnerQuery()

    const data = yield call(() => {
      return HttpClient.post('/api/secured/user-profiles/scroll/', {
        body: { query },
      })
    })

    if (data.message) {
      yield put(accountActions.loadPotentialAccountOwnersFailed(data.message))
    } else {
      yield put(accountActions.loadPotentialAccountOwnersSuccess(data))
    }
  } catch (e) {
    yield put(accountActions.loadPotentialAccountOwnersFailed(e.message))
  }
}

function* changeAccountOwner(action) {
  try {
    const userId = yield select(getUserId)
    const { newId, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/user-profiles/owner/change/${userId}/${newId}`
      )
    })

    if (data.message) {
      yield put(accountActions.changeAccountOwnerFailed(data.message))
    } else {
      yield put(accountActions.changeAccountOwnerSuccess(data))
      onSuccess()
    }
  } catch (e) {
    yield put(accountActions.changeAccountOwnerFailed(e.message))
  }
}

function* getTimezonesByZip(action) {
  try {
    const { zip, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/public/timezones/${zip}`)
    })

    if (data.message) {
      yield put(accountActions.getTimezonesByZipFailed(data.message))
    } else {
      const timezones = filterTimezones(data)

      yield put(accountActions.getTimezonesByZipSuccess(timezones))
      onSuccess(timezones)
    }
  } catch (e) {
    yield put(accountActions.getTimezonesByZipFailed(e.message))
  }
}

function* getProperties() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/locations/')
    })

    if (data.message) {
      yield put(accountActions.getPropertiesFailed(data.message))
    } else {
      yield put(accountActions.getPropertiesSuccess(getOnlyProperties(data)))
    }
  } catch (e) {
    yield put(accountActions.getPropertiesFailed(e.message))
  }
}

function* getAPIKeyPageAccess() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/api-entry/')
    })

    if (data.message) {
      yield put(accountActions.getAPIKeyPageAccessFailed(data.message))
    } else {
      if (data.totalCount === 0) {
        yield put(accountActions.generateNewEntry())
      }

      yield put(accountActions.getAPIKeyPageAccessSuccess(data))
    }
  } catch (e) {
    yield put(accountActions.getAPIKeyPageAccessFailed(e.message))
  }
}

function* generateAPIKey(action) {
  try {
    const { onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/secured/api-entry/')
    })

    if (data.message) {
      yield put(accountActions.generateNewEntryFailed(data.message))
    } else {
      yield put(accountActions.generateNewEntrySuccess(data))

      if (onSuccess) {
        onSuccess()
      }
    }
  } catch (e) {
    yield put(accountActions.generateNewEntryFailed(e.message))
  }
}

function* getOrganizationTimezone() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/user-profiles/current')
    })

    if (data.message) {
      yield put(accountActions.updateOrganizationTimezoneFailed(data.message))
    } else {
      yield put(accountActions.updateOrganizationTimezoneSuccess(data))
    }
  } catch (e) {
    yield put(accountActions.updateOrganizationTimezoneFailed(e.message))
  }
}

function* getIdpData() {
  try {
    const orgId = yield select(getOrganizationId)

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/organizations/idp/${orgId}`)
    })

    if (data.message) {
      yield put(accountActions.getIdpDataFailed(data.message))
    } else {
      yield put(accountActions.getIdpDataSuccess(data))
    }
  } catch (e) {
    yield put(accountActions.getIdpDataFailed(e.message))
  }
}

function* reloadCurrentUser() {
  yield put(rootActions.loadUserPermissions())
}

function* getTimezonesByZipSaga() {
  yield takeEvery(
    accountActions.actionTypes.GET_TIMEZONES_BY_ZIP,
    getTimezonesByZip
  )
}

function* getOrganizationInfoSaga() {
  yield all([
    takeEvery(
      accountActions.actionTypes.GET_ORGANIZATION_INFO,
      getOrganizationInfo
    ),
    takeEvery(
      accountActions.actionTypes.UPDATE_ACCOUNT_INFO_SUCCEEDED,
      getOrganizationInfo
    ),
  ])
}

function* getUserInfoSaga() {
  yield takeEvery(accountActions.actionTypes.GET_USER_INFO, getUserInfo)
}

function* updateAccountInfoSaga() {
  yield takeEvery(
    accountActions.actionTypes.UPDATE_ACCOUNT_INFO,
    updateAccountInfo
  )
}

function* checkOrganizationInfoSaga() {
  yield takeEvery(
    accountActions.actionTypes.CHECK_ORGANIZATION_INFO,
    checkOrganizationInfo
  )
}

function* resendEmailSaga() {
  yield takeEvery(accountActions.actionTypes.RESEND_EMAIL, resendEmail)
}

function* setPasswordSaga() {
  yield takeEvery(accountActions.actionTypes.SET_PASSWORD, setPassword)
}

function* loadAccountOwnersSaga() {
  yield takeEvery(
    accountActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS,
    loadAccountOwners
  )
}

function* changeAccountOwnerSaga() {
  yield takeEvery(
    accountActions.actionTypes.CHANGE_ACCOUNT_OWNER,
    changeAccountOwner
  )
}

function* getPropertiesSaga() {
  yield takeEvery(accountActions.actionTypes.GET_PROPERTIES, getProperties)
}

function* getAPIKeyPageAccessSaga() {
  yield takeEvery(
    accountActions.actionTypes.GET_API_KEY_PAGE_ACCESS,
    getAPIKeyPageAccess
  )
}

function* generateAPIKeySaga() {
  yield takeEvery(accountActions.actionTypes.GENERATE_NEW_ENTRY, generateAPIKey)
}

function* regenerateAPIKeySaga() {
  yield takeEvery(
    accountActions.actionTypes.REGENERATE_NEW_ENTRY,
    generateAPIKey
  )
}

function* reloadCurrentUserSaga() {
  yield takeEvery(
    accountActions.actionTypes.CHANGE_ACCOUNT_OWNER_SUCCEEDED,
    reloadCurrentUser
  )
}

function* getOrganizationTimezoneSaga() {
  yield takeEvery(
    accountActions.actionTypes.UPDATE_ACCOUNT_INFO_SUCCEEDED,
    getOrganizationTimezone
  )
}

function* getIdpDataSaga() {
  yield takeEvery(
    accountActions.actionTypes.GET_USER_INFO_SUCCEEDED,
    getIdpData
  )
}

export default function* accountSaga() {
  yield fork(getOrganizationInfoSaga)
  yield fork(getUserInfoSaga)
  yield fork(updateAccountInfoSaga)
  yield fork(checkOrganizationInfoSaga)
  yield fork(resendEmailSaga)
  yield fork(setPasswordSaga)
  yield fork(loadAccountOwnersSaga)
  yield fork(changeAccountOwnerSaga)
  yield fork(reloadCurrentUserSaga)
  yield fork(getOrganizationTimezoneSaga)
  yield fork(getTimezonesByZipSaga)
  yield fork(getPropertiesSaga)
  yield fork(getAPIKeyPageAccessSaga)
  yield fork(generateAPIKeySaga)
  yield fork(regenerateAPIKeySaga)
  yield fork(getIdpDataSaga)
}
