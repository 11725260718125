import React, { useState } from 'react'
import { connect } from 'react-redux'
import actions from '../../service/location-actions'
import { withStyles } from '@material-ui/core'
import styles from './style'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import { startCase } from 'lodash'
import {
  getUpdateLocationLabel,
  urlDepends,
  validateLocationName,
} from '../../service/location-service'
import { LOCATIONS_PLACEHOLDERS } from '../../service/location-constants'
import EditModal from './EditModal'

const EditLocationButtonComponent = withStyles(styles)((props) => {
  const {
    modalTitle,
    isMobile,
    placeholder,
    location,
    onSubmit,
    onDelete,
    error,
    classes,
    bigIcon = false,
    resetError,
    currentLoc,
    type,
    setError,
    isRoom,
  } = props
  const [open, setOpen] = useState(false)

  const [deep] = currentLoc
  const locationType = type === 'child' ? urlDepends[deep] : deep
  const updateLocationButton = getUpdateLocationLabel(startCase(locationType))

  const onSubmitButton = (value, roomFields) => {
    const validationError = validateLocationName(value)

    if (validationError) {
      setError(validationError)
    } else {
      onSubmit(value.trim(), roomFields, handleClose)
    }
  }

  const handleOpen = () => {
    resetError()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={bigIcon ? classes.bugIconWrapper : classes.editIconWrapper}>
      <IconButton
        onClick={handleOpen}
        className={classes.rowIconButton}
        aria-label="show more"
      >
        {isMobile ? (
          <EditIcon className={bigIcon ? classes.bigIcon : classes.editIcon} />
        ) : (
          <CreateOutlinedIcon className={classes.editIcon} />
        )}
      </IconButton>
      <EditModal
        isOpen={open}
        isRoomFlag={isRoom}
        location={location}
        onClose={handleClose}
        onDelete={onDelete}
        onSubmit={onSubmitButton}
        modalTitle={modalTitle}
        defaultValue={placeholder}
        actionLabel={updateLocationButton}
        placeholder={LOCATIONS_PLACEHOLDERS[locationType]}
        error={error}
        inputProps={{ maxLength: 30 }}
      />
    </div>
  )
})

const mapDispatchToProps = (dispatch) => ({
  setError: (error) => dispatch(actions.setError(error)),
})

const mapStateToProps = (state) => ({
  currentLoc: state.locationReducer.currentLoc,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditLocationButtonComponent))
