import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { ROOMS_FIELDS } from '../../service/location-constants'
import styles from './style'
import { withMediaQuery } from '../../../../services/root/root-service'
import { isMobileOnly } from 'react-device-detect'

const RoomFields = withMediaQuery((props) => {
  const {
    onChangeRoomsFields,
    defaultValue = '',
    baselineAch = '',
    improvedEAch = '',
  } = props

  const onChange = (event) => {
    const value = event.target.value < 0 ? '' : event.target.value

    onChangeRoomsFields(value, event.target.name)
  }

  return (
    <>
      <TextField
        variant="outlined"
        name="baselineAch"
        value={baselineAch}
        defaultValue={defaultValue}
        label={!isMobileOnly ? ROOMS_FIELDS.baselineAch : ''}
        placeholder={isMobileOnly ? ROOMS_FIELDS.baselineAch : ''}
        fullWidth
        inputProps={{ type: 'number', min: 0 }}
        onChange={onChange}
      />
      <TextField
        variant="outlined"
        name="improvedEAch"
        value={improvedEAch}
        defaultValue={defaultValue}
        label={!isMobileOnly ? ROOMS_FIELDS.improvedEAch : ''}
        placeholder={isMobileOnly ? ROOMS_FIELDS.improvedEAch : ''}
        fullWidth
        inputProps={{ type: 'number', min: 0 }}
        onChange={onChange}
      />
    </>
  )
})

export default withStyles(styles)(RoomFields)
