import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'

import DateFilter from '../../DateFilter'

import { checkConnection } from '../../../../../../services/root/root-service'
import actions from '../../../services/summary-actions'
import styles from './style'
import { gaEventAction } from '../../../../../../services/googleAnalytics/ga-actions'

const TableDateFilter = (props) => {
  const {
    dateFilter = {},
    changeSelectedChip,
    period,
    label,
    timezone,
    classes,
    gaEventSend,
  } = props

  return (
    <div className={classes.filterWrapper}>
      <DateFilter
        timezone={timezone}
        dateFilter={dateFilter}
        changeSelectedChip={changeSelectedChip}
        label={label}
        period={period}
        gaEventSend={gaEventSend}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { sharedSummaryReducer },
    rootReducer,
  } = state

  return {
    dateFilter: sharedSummaryReducer.dateFilter,
    label: sharedSummaryReducer.dateFilterLabel,
    period: sharedSummaryReducer.period,
    timezone: rootReducer.timezone,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedChip: (data, label) =>
      dispatch(checkConnection(() => actions.changeDateFilter(data, label))),
    gaEventSend: gaEventAction(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TableDateFilter))
