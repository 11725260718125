import { COLORS } from '../../../../../services/constants'

const styles = (theme) => ({
  textContainer: {
    display: 'grid',
    width: '62%',
    paddingLeft: '40px',
    borderRight: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    paddingTop: '10px',
    '& div': {
      display: 'inline-flex',
      paddingBottom: '7px',
    },
  },
  progress: {
    width: '38%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    display: 'flex',
    width: '100%',
    minHeight: '86px',
  },
  detailsText: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  progressNumber: {
    fontFamily: 'Cerebri Sans Pro Bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    minHeight: '60px',
    height: '60px',
    textAlign: 'center',
  },
  circularLabel: {
    fontSize: '35px',
    lineHeight: '40px',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  runTimeLabel: {
    fontSize: '25px',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  cycleName: {
    fontSize: '10px',
    lineHeight: '10px',
  },
  infoIcon: {
    paddingRight: '5px',
    '& button': {
      padding: '0',
    },
  },
  incorrectWrapper: {
    paddingBottom: 0,
  },
  incorrect: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.WHITE,
    maxHeight: '20px',
    maxWidth: '20px',
    backgroundColor: COLORS.RED_ORANGE,
    boxShadow: '0 0 5px 1px  rgba(122,122,122,0.5)',
    borderRadius: '20px',
    padding: '0!important',
    '&:hover': {
      backgroundColor: COLORS.RED_ORANGE,
    },
  },
  '@media (min-width: 550px)': {
    textContainer: {
      width: '60.5%',
    },
  },
  '@media (min-width: 1224px)': {
    progressNumber: {
      width: 'fit-content',
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderRight: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
      minWidth: '135px',
      height: '100%',
    },
    progressPercent: {
      marginLeft: '10px',
    },
    cycleName: {
      fontSize: '12px',
      lineHeight: '14px',
      fontFamily: 'Cerebri Sans Pro Regular',
    },
    circularLabel: {
      fontSize: '17px',
      lineHeight: '20px',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      width: 'auto',
      maxWidth: '60%',
      minHeight: '61px',
      marginLeft: '-10px',
    },
    textContainer: {
      width: '100%',
      margin: 'auto',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '3px 15px 0 10px',
      '& div': {
        paddingBottom: '3px',
        paddingRight: '7px',
        border: 'none',
      },
    },
    detailsText: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    progress: {
      width: 'fit-content',
      maxWidth: '160px',
    },
    runTimeLabel: {
      fontSize: '17px',
      lineHeight: '20px',
    },
  },
  '@media (min-width: 1550px)': {
    infoContainer: {
      marginLeft: '-14px',
    },
  },
})

export default styles
