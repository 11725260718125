import { COLORS } from '../../services/constants'

const styles = (theme) => ({
  dashboardWrapper: {
    height: 'calc(100% - 90px)',
  },
  dashboardPage: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '7%',
    paddingRight: '7%',
    height: '100%',
  },
  dashboardPageBannerOffset: {
    height: 'calc(100% - 90px)',
  },
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '14px',
    '& div': {
      width: 'fit-content',
      maxWidth: '100%',
      marginBottom: '10px',
      height: '30px',
      paddingLeft: '13px',
      paddingRight: '13px',
      marginRight: '10px',
    },
    '& > .spinner': {
      display: 'none',
    },
  },
  activityTitle: {
    paddingBottom: '10px',
  },
  cardsGroup: {
    overflowY: 'auto',
    height: 'calc(100%)',
  },
  cardsGroupBannerOffset: {
    height: 'calc(100% - 90px)',
  },
  emptyListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyListText: {
    lineHeight: '21px',
    color: COLORS.GRAY,
    width: '70%',
    textAlign: 'center',
  },
  iconWrapper: {
    marginTop: '23px',
    marginBottom: '23px',
  },
  '@media (min-width: 1224px)': {
    dashboardPage: {
      height: 'calc(100% - 100px)',
    },
    dashboardPageBannerOffset: {
      height: 'calc(100% - 190px)',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: '80%',
      paddingLeft: '7%',
      paddingRight: '7%',
      margin: 'auto',
      '& div': {
        width: 'fit-content',
        marginLeft: '15px',
        height: '36px',
        borderRadius: '20px',
        borderColor: COLORS.GRAY_IRON,
      },
    },
    activityTitle: {
      paddingBottom: '20px',
      paddingTop: '40px',
      fontSize: '42px',
      lineHeight: '51px',
      textAlign: 'center',
    },
    cardsGroup: {
      width: '80%',
      margin: 'auto',
      backgroundColor: COLORS.WHITE,
      padding: '0 19px 19px',
      marginTop: '16px',
      flexGrow: '1',
    },
  },
  '@media (min-width: 1903px)': {
    cardsGroup: {
      width: '66%',
    },
    filterContainer: {
      maxWidth: '66%',
    },
  },
  '@media screen and (max-width: 420px) and (max-height: 820px)': {
    cardsGroup: {
      height: 'auto',
      overflowY: 'hidden',
    },
    dashboardPage: {
      overflowY: 'auto',
    },
    dashboardWrapper: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  },
})

export default styles
