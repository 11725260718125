import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import styles from './style'
import {
  getCalculatedValue,
  getColor,
  getIconName,
  getTimeLabel,
  getTimeValue,
  findStartEvent,
} from '../../../services/related/transaction-service'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {
  STATUS_FILTER_VALUES,
  GENERAL_ACTIVITY_INFO_LABELS,
  COLORS,
  UNKNOWN_LOCATION,
} from '../../../../../services/constants'
import {
  withMediaQuery,
  getLocationPath,
} from '../../../../../services/root/root-service'
import CustomTooltip from '../../../../../components/_shared/customTooltip/CustomTooltip'

const GeneralActivityInfoComponent = withStyles(styles)((props) => {
  const {
    classes,
    activity,
    activity: { txStatus, details },
    timezone,
    isMobile,
    isErrorCalculation,
  } = props
  const startedData = getTimeValue(activity, timezone)
  const statusIconSize = isMobile ? 22 : 26

  const path = activity.locationPath || []
  const [location, locationPath] = getLocationPath(path, true)

  const timeLabel = getTimeLabel(activity)
  const IconName = getIconName(txStatus)
  const isInProgress = txStatus === STATUS_FILTER_VALUES.in_progress
  const isStartEvent =
    findStartEvent(details) &&
    timeLabel === GENERAL_ACTIVITY_INFO_LABELS.startTime
  const calculatedStartedData = getCalculatedValue(
    !isStartEvent && isErrorCalculation,
    startedData
  )

  return (
    <>
      <div className={classes.infoContainer}>
        <div className={classes.infoWrapper}>
          <div className={classes.statusColumn}>
            {!isInProgress ? (
              <IconName
                style={{ fill: getColor(activity), borderRadius: '300px' }}
                size={statusIconSize}
              />
            ) : (
              <div
                className={classes.circle}
                style={{ backgroundColor: getColor(activity) }}
              >
                <MoreHorizIcon
                  size={statusIconSize}
                  style={{ fill: COLORS.WARM_GRAY }}
                />
              </div>
            )}
          </div>
          <div className={classes.locationColumn}>
            <CustomTooltip title={locationPath} isMobile={isMobile}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.secondaryText}
              >
                {locationPath}
              </Typography>
            </CustomTooltip>
            <CustomTooltip
              title={location || UNKNOWN_LOCATION}
              isMobile={isMobile}
            >
              <Typography variant="subtitle1" className={classes.mainText}>
                {location || UNKNOWN_LOCATION}
              </Typography>
            </CustomTooltip>
          </div>
        </div>
        <div
          className={classes.timeColumn}
          style={{
            borderRight: isMobile
              ? 'none'
              : `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
          }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.secondaryText}
          >
            {timeLabel}
          </Typography>
          <Typography variant="subtitle1" className={classes.mainText}>
            {calculatedStartedData}
          </Typography>
        </div>
      </div>
    </>
  )
})

export default withMediaQuery(GeneralActivityInfoComponent)
