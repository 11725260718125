import React from 'react'
import { map } from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import styles from './style'
import IconButton from '@material-ui/core/IconButton'
import { withMediaQuery } from '../../../services/root/root-service'

const SingleSelectionTreeNode = (props) => {
  const {
    value,
    onCheck,
    children,
    name,
    isParent,
    onExpand,
    expanded,
    checked,
    classes,
    margin,
    indeterminate,
    isMobile,
    isSelectable,
    deep,
    propertyId,
  } = props
  const isActive = checked && isSelectable

  const renderChildren = () => {
    if (!expanded) {
      return null
    }

    return map(children, (child) => child)
  }

  const onExpandList = () => {
    onExpand(value, deep, propertyId)
  }

  const onCheckRow = (event) => {
    onCheck(value, event.target.checked)
  }

  return (
    <>
      <div
        className={classNames(
          classes.treeNodeContainer,
          classes.singleSelectionNode
        )}
        style={{
          marginLeft: margin,
        }}
      >
        <div className={classes.labelContainer}>
          {isSelectable && (
            <Checkbox
              className={classes.singleSelectionTreeCheckbox}
              checkedIcon={<CheckBoxOutlinedIcon />}
              checked={checked}
              indeterminate={indeterminate}
              color="primary"
              onChange={onCheckRow}
            />
          )}
          <Typography
            className={
              isSelectable
                ? classes.singleSelectionTreeLabel
                : classes.singleSelectionTreeLabelSelectable
            }
            color={isActive ? 'primary' : 'inherit'}
            variant={
              isActive ? (isMobile ? 'subtitle2' : 'subtitle1') : 'body2'
            }
          >
            {name}
          </Typography>
        </div>
        {isParent && !isSelectable ? (
          <div className={classes.arrowColumn}>
            <IconButton
              onClick={onExpandList}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
        ) : null}
      </div>
      {!isSelectable && renderChildren()}
    </>
  )
}

export default withMediaQuery(withStyles(styles)(SingleSelectionTreeNode))
