import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import styles from './style'
import { LOCATION_FILTER_LABELS, COLORS } from '../../../../services/constants'

const IncludeDeletedLocationsControl = (props) => {
  const { classes, onChange, isInclude } = props

  return (
    <FormControlLabel
      className={classes.checkboxLabel}
      style={{
        color: isInclude ? COLORS.BLUE : 'rgba(0, 0, 0, 0.87)',
      }}
      control={
        <Checkbox
          checked={isInclude}
          checkedIcon={<CheckBoxOutlinedIcon />}
          className={classes.checkbox}
          color="primary"
          onChange={onChange}
        />
      }
      label={LOCATION_FILTER_LABELS.includeDeletedLocations}
    />
  )
}

export default withStyles(styles)(IncludeDeletedLocationsControl)
