const styles = (theme) => ({
  logoImage: {
    marginBottom: '10%',
    width: '68%',
    textAlign: 'center',
  },
  loginInput: {
    marginBottom: '0.5%',
    width: '84%',
  },
  loginButton: {
    marginTop: '40px',
    marginBottom: '15px',
    width: '42%',
  },
  loginWithOkta: {
    marginBottom: '30px',
  },
  forgotPassword: {
    fontSize: '12px',
    fontFamily: 'Cerebri Sans Pro Semi Bold',
  },
  helperTextInvisible: {
    visibility: 'hidden',
  },
  loginWrapperMobile: {
    margin: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 60px)',
  },
  loginWrapperDesktop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 60px)',
    width: '400px',
    paddingLeft: '250px',
  },
  desktopLogin: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  mobileLogin: {
    height: 'calc(100% - 60px)',
  },
  pictureWrapper: {
    width: 'calc(100% - 650px)',
    marginTop: '50px',
    textAlign: 'center',
  },
  lampPictureWrapper: {
    height: '100%',
  },
  errorText: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '12px',
    lineHeight: '14px',
  },
  loginHelper: {
    marginBottom: '1.1em',
  },
  '@media screen and (max-width: 1224px) and (orientation: landscape)': {
    loginWrapperMobile: {
      height: '100%',
    },
    logoImage: {
      marginBottom: '4%',
      width: '28%',
    },
    loginInput: {
      marginTop: '1%',
      width: '60%',
    },
    loginButton: {
      marginTop: '1%',
      marginBottom: '1%',
      width: '42%',
    },
  },
  message: {
    fontFamily: 'Cerebri Sans Pro Regular',
    display: 'flex',
    justifyContent: 'center',
    margin: '30px',
  },
  '@media screen and (max-width: 800px) and (orientation: landscape)': {
    loginInput: {
      marginTop: '0',
      width: '68%',
    },
  },
  '@media screen and (max-width: 900px) and (orientation: landscape) and (max-height: 400px)':
    {
      logoImage: {
        marginBottom: '2%',
        width: '20%',
      },
      loginInput: {
        marginTop: '0',
        width: '68%',
      },
    },
})

export default styles
