import usersActions from './users-actions'
import { filterUsers, prepareRoles, prepareUserRoles } from './users-service'

export const usersReducer = (
  state = {
    users: [],
    filteredUsers: [],
    allRoles: [],
    roles: [],
    userInfo: '',
    isLoading: true,
    error: '',
    addPropertyError: '',
    loadUsersError: '',
  },
  action
) => {
  switch (action.type) {
    case usersActions.actionTypes.LOAD_USERS_LIST:
      return {
        ...state,
        isLoading: true,
        loadUsersError: '',
        error: '',
      }
    case usersActions.actionTypes.LOAD_USER_LOCATIONS:
      return {
        ...state,
        isLoading: true,
        addPropertyError: '',
        error: '',
      }
    case usersActions.actionTypes.ADD_PROPERTY:
      return {
        ...state,
        addPropertyError: '',
        error: '',
      }
    case usersActions.actionTypes.LOAD_USERS_LIST_FAILED:
      return {
        ...state,
        loadUsersError: action.payload.message,
        isLoading: false,
      }
    case usersActions.actionTypes.UPDATE_USER_FAILED:
    case usersActions.actionTypes.CREATE_USER_FAILED:
    case usersActions.actionTypes.DELETE_USER_FAILED:
    case usersActions.actionTypes.LOAD_USER_INFO_FAILED:
    case usersActions.actionTypes.LOAD_ALL_ROLES_FAILED:
    case usersActions.actionTypes.LOAD_USER_LOCATIONS_FAILED:
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    case usersActions.actionTypes.LOAD_USER_ROLES_FAILED:
      return {
        ...state,
        loadAllUserRolesError: action.payload.message,
        isLoading: false,
      }
    case usersActions.actionTypes.LOAD_USERS_LIST_SUCCEEDED:
      return {
        ...state,
        users: action.payload.users || [],
        filteredUsers: action.payload.users || [],
        isLoading: false,
        loadUsersError: '',
        userInfo: '',
      }
    case usersActions.actionTypes.FILTER_USERS_LIST:
      return {
        ...state,
        filteredUsers: filterUsers(state.users, action.payload.searchValue),
        isLoading: false,
      }
    case usersActions.actionTypes.LOAD_USER_ROLES_SUCCEEDED:
      return {
        ...state,
        roles: prepareRoles(action.payload.userRoles, state.allRoles),
        isLoading: false,
        loadAllUserRolesError: '',
      }
    case usersActions.actionTypes.LOAD_USER_ROLES:
      return {
        ...state,
        allRoles: prepareUserRoles(action.payload.allRoles),
        isLoading: true,
      }
    case usersActions.actionTypes.LOAD_USER_INFO:
      return {
        ...state,
        userInfo: '',
        isLoading: true,
      }
    case usersActions.actionTypes.LOAD_USER_INFO_SUCCEEDED:
      return {
        ...state,
        userInfo: action.payload.user,
        // isLoading: false,
        error: '',
      }
    case usersActions.actionTypes.RESET_ERROR:
    case usersActions.actionTypes.ADD_PROPERTY_SUCCEEDED:
    case usersActions.actionTypes.UPDATE_USER_SUCCEEDED:
    case usersActions.actionTypes.CREATE_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        addPropertyError: '',
        error: '',
      }
    case usersActions.actionTypes.ADD_PROPERTY_FAILED:
      return {
        ...state,
        addPropertyError: action.payload.message,
      }
    default:
      return state
  }
}
