import React, { useCallback, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'

import Link from '@material-ui/core/Link'

import { BLUEPRINT_BANNER_LABEL } from '../../../services/constants'
import { checkConnection } from '../../../services/root/root-service'
import actions from '../../../services/root/root-actions'
import styles from './style'
import { gaEventAction } from '../../../services/googleAnalytics/ga-actions'

const BannerWithLink = (props) => {
  const { getBlueprintLink, blueprintLink, classes, gaEventSend } = props

  useEffect(() => {
    getBlueprintLink()
  }, [])

  const handleClickGAEvent = useCallback(() => {
    gaEventSend('navigate_to_blueprint', { referring_view: document.title })
  }, [])

  return isEmpty(blueprintLink) ? null : (
    <div className={classes.banner}>
      <Link
        href={blueprintLink}
        onClick={handleClickGAEvent}
        className={classes.bannerLink}
      >
        {BLUEPRINT_BANNER_LABEL}
      </Link>
    </div>
  )
}

const mapStateToProps = (state) => ({
  blueprintLink: state.rootReducer.blueprintLink,
})

const mapDispatchToProps = (dispatch) => ({
  getBlueprintLink: (orgId) =>
    dispatch(checkConnection(() => actions.getBlueprintLink(orgId))),
  gaEventSend: gaEventAction(dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BannerWithLink))
