import React from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import useStyles from './style'
import { withMediaQuery } from '../../../services/root/root-service'

const CustomDividerComponent = (props) => {
  const { label, subLabel, isMobile } = props
  const classes = useStyles(props)

  return (
    <>
      {isMobile ? (
        <div className={classes.groupActivitiesDivider}>
          <Divider className={classes.lineDivider} />
          <div className={classes.mobileGroup}>
            <Typography className={classes.groupLabel} variant="body2">
              {label}
            </Typography>
            <Typography className={classes.subLabel} variant="body2">
              {subLabel}
            </Typography>
          </div>
          <Divider className={classes.lineDivider} />
        </div>
      ) : (
        <div className={classes.groupActivitiesDivider}>
          <Typography className={classes.groupLabel} variant="subtitle2">
            {label}
          </Typography>
          <Typography className={classes.subLabel} variant="body2">
            {subLabel}
          </Typography>
        </div>
      )}
    </>
  )
}

export default withMediaQuery(CustomDividerComponent)
