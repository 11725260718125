const locationActions = {}

locationActions.actionsTypes = {
  LOAD_LOCATIONS: '{LOCATIONS} Load Locations',
  LOAD_LOCATIONS_SUCCEEDED: '{LOCATIONS} Load Locations Succeeded',
  LOAD_LOCATIONS_FAILED: '{LOCATIONS} Load Locations Failed',

  ADD_NEW_LOCATION: '{LOCATIONS} Add New Location',
  ADD_NEW_LOCATION_SUCCEEDED: '{LOCATIONS} Add New Location Succeeded',

  ADD_NEW_CHILD_LOCATION: '{LOCATIONS} Add New Child Location',
  ADD_NEW_CHILD_LOCATION_SUCCEEDED:
    '{LOCATIONS} Add New Child Location Succeeded',

  DELETE_LOCATION: '{LOCATIONS} Delete Location',
  DELETE_LOCATION_SUCCEEDED: '{LOCATIONS} Delete Location Succeeded',

  DELETE_LOCATION_WITH_SUBLOCATIONS:
    '{LOCATIONS} Delete Location with Sub Locations',
  DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED:
    '{LOCATIONS} Delete Location with Sub Locations Succeeded',
  DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED:
    '{LOCATIONS} Delete Location with Sub Locations Failed',

  EDIT_LOCATION: '{LOCATIONS} Edit Location',
  EDIT_LOCATION_SUCCEEDED: '{LOCATIONS} Edit Location Succeeded',
  UPDATE_LOCATION_FAILED: '{LOCATIONS} Update Location Failed',

  SET_CURRENT_LOCATION: '{LOCATIONS} Set Current Location',
  SET_ERROR: '{LOCATIONS} Set Error',

  GET_SUBLOCATIONS_COUNT: '{LOCATIONS} Get Sub Locations count',
  GET_SUBLOCATIONS_COUNT_SUCCEEDED:
    '{LOCATIONS} Get Sub Locations count Succeeded',
  GET_SUBLOCATIONS_COUNT_FAILED: '{LOCATIONS} Get Sub Locations count Failed',

  GET_ASSIGNED_DEVICES: '{LOCATIONS} Get Assigned Devices',
  GET_ASSIGNED_DEVICES_SUCCEEDED: '{LOCATIONS} Get Assigned Devices Succeeded',
  GET_ASSIGNED_DEVICES_FAILED: '{LOCATIONS} Get Assigned Devices Failed',

  GET_ASSIGNED_USERS: '{LOCATIONS} Get Assigned Users',
  GET_ASSIGNED_USERS_SUCCEEDED: '{LOCATIONS} Get Assigned Users Succeeded',
  GET_ASSIGNED_USERS_FAILED: '{LOCATIONS} Get Assigned Users Failed',

  RESET_SUBLOCATIONS_COUNT: '{LOCATIONS} Reset Sub Locations Count',
  RESET_ERROR: '{LOCATIONS} Reset error',
  RESET_USERS: '{LOCATIONS} Reset users list',

  UPDATE_TIMEOUT_FOR_LOCATION: '{LOCATIONS} Update Timeout For Location',
  UPDATE_TIMEOUT_FOR_LOCATION_SUCCEEDED:
    '{LOCATIONS} Update Timeout For Location Succeeded',
  UPDATE_TIMEOUT_FOR_LOCATION_FAILED:
    '{LOCATIONS} Update Timeout For Location Failed',

  BULK_UPLOAD_LOCATIONS: '{LOCATIONS} Bulk Upload Locations',
  BULK_UPLOAD_LOCATIONS_SUCCEEDED:
    '{LOCATIONS} Bulk Upload Locations Succeeded',
  BULK_UPLOAD_LOCATIONS_FAILED: '{LOCATIONS} Bulk Upload Locations Failed',

  VALIDATE_LOCATIONS: '{LOCATIONS} Validate Locations',
  VALIDATE_LOCATIONS_SUCCEEDED: '{LOCATIONS} Validate Locations Succeeded',
  VALIDATE_LOCATIONS_FAILED: '{LOCATIONS} Validate Locations Failed',

  GET_CURRENT_LOCATIONS_SCHEMA: '{LOCATIONS} Get Current Locations Schema',
  GET_CURRENT_LOCATIONS_SCHEMA_SUCCEEDED:
    '{LOCATIONS} Get Current Locations Schema Succeeded',
  GET_CURRENT_LOCATIONS_SCHEMA_FAILED:
    '{LOCATIONS} Get Current Locations Schema Failed',
}

locationActions.loadLocations = (pathname, onSuccess, onError) => {
  return {
    type: locationActions.actionsTypes.LOAD_LOCATIONS,
    payload: {
      pathname,
      onSuccess,
      onError,
    },
  }
}

locationActions.loadLocationsSuccess = (locations) => {
  return {
    type: locationActions.actionsTypes.LOAD_LOCATIONS_SUCCEEDED,
    payload: {
      locations,
    },
  }
}

locationActions.loadLocationsFailed = (message) => {
  return {
    type: locationActions.actionsTypes.LOAD_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.addNewLocation = (body, onSuccess) => {
  return {
    type: locationActions.actionsTypes.ADD_NEW_LOCATION,
    payload: {
      body,
      onSuccess,
    },
  }
}

locationActions.bulkUploadLocations = (locations, onSuccess, history) => {
  return {
    type: locationActions.actionsTypes.BULK_UPLOAD_LOCATIONS,
    payload: {
      locations,
      onSuccess,
      history,
    },
  }
}

locationActions.bulkUploadLocationsSucceeded = (locations) => {
  return {
    type: locationActions.actionsTypes.BULK_UPLOAD_LOCATIONS_SUCCEEDED,
    payload: {
      locations,
    },
  }
}

locationActions.bulkUploadLocationsFailed = (message) => {
  return {
    type: locationActions.actionsTypes.BULK_UPLOAD_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.addNewChildLocation = (body, history) => {
  return {
    type: locationActions.actionsTypes.ADD_NEW_CHILD_LOCATION,
    payload: {
      body,
      history,
    },
  }
}

locationActions.addNewLocationSuccess = (addedLocation) => {
  return {
    type: locationActions.actionsTypes.ADD_NEW_LOCATION_SUCCEEDED,
    payload: {
      addedLocation,
    },
  }
}

locationActions.addNewChildLocationSuccess = (location, url) => {
  return {
    type: locationActions.actionsTypes.ADD_NEW_CHILD_LOCATION_SUCCEEDED,
    payload: {
      location,
      url,
    },
  }
}

locationActions.setCurrentLocation = (pathname) => {
  return {
    type: locationActions.actionsTypes.SET_CURRENT_LOCATION,
    payload: {
      pathname,
    },
  }
}

locationActions.setError = (error) => {
  return {
    type: locationActions.actionsTypes.SET_ERROR,
    payload: {
      error,
    },
  }
}

locationActions.deleteLocation = (id) => {
  return {
    type: locationActions.actionsTypes.DELETE_LOCATION,
    payload: {
      id,
    },
  }
}

locationActions.deleteLocationSuccess = (id, parentId) => {
  return {
    type: locationActions.actionsTypes.DELETE_LOCATION_SUCCEEDED,
    payload: {
      id,
      parentId,
    },
  }
}

locationActions.deleteLocationWithSubLocations = (params) => {
  return {
    type: locationActions.actionsTypes.DELETE_LOCATION_WITH_SUBLOCATIONS,
    payload: {
      ...params,
    },
  }
}

locationActions.deleteLocationWithSubLocationsSuccess = (id, parentId) => {
  return {
    type: locationActions.actionsTypes
      .DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED,
    payload: {
      id,
      parentId,
    },
  }
}

locationActions.deleteLocationWithSubLocationsFailed = (message) => {
  return {
    type: locationActions.actionsTypes.DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.updateLocationFailed = (message) => {
  return {
    type: locationActions.actionsTypes.UPDATE_LOCATION_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.editLocation = (updatedLoc, onSuccess) => {
  return {
    type: locationActions.actionsTypes.EDIT_LOCATION,
    payload: {
      updatedLoc,
      onSuccess,
    },
  }
}

locationActions.editLocationSucceeded = (updatedLoc) => {
  return {
    type: locationActions.actionsTypes.EDIT_LOCATION_SUCCEEDED,
    payload: {
      updatedLoc,
    },
  }
}

locationActions.getSubLocationsCount = (id) => {
  return {
    type: locationActions.actionsTypes.GET_SUBLOCATIONS_COUNT,
    payload: {
      id,
    },
  }
}

locationActions.getSubLocationsCountSucceeded = (subLocationsCount) => {
  return {
    type: locationActions.actionsTypes.GET_SUBLOCATIONS_COUNT_SUCCEEDED,
    payload: {
      subLocationsCount,
    },
  }
}

locationActions.getSubLocationsCountFailed = (message) => {
  return {
    type: locationActions.actionsTypes.GET_SUBLOCATIONS_COUNT_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.getAssignedDevices = (ids, location) => {
  return {
    type: locationActions.actionsTypes.GET_ASSIGNED_DEVICES,
    payload: {
      ids,
      location,
    },
  }
}

locationActions.getAssignedDevicesSucceeded = (devices) => {
  return {
    type: locationActions.actionsTypes.GET_ASSIGNED_DEVICES_SUCCEEDED,
    payload: {
      devices,
    },
  }
}

locationActions.getAssignedDevicesFailed = (message) => {
  return {
    type: locationActions.actionsTypes.GET_ASSIGNED_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.getAssignedUsers = (id) => {
  return {
    type: locationActions.actionsTypes.GET_ASSIGNED_USERS,
    payload: {
      id,
    },
  }
}

locationActions.getAssignedUsersSucceeded = (users) => {
  return {
    type: locationActions.actionsTypes.GET_ASSIGNED_USERS_SUCCEEDED,
    payload: {
      users,
    },
  }
}

locationActions.getAssignedUsersFailed = (message) => {
  return {
    type: locationActions.actionsTypes.GET_ASSIGNED_USERS_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.resetSubLocationsCount = () => {
  return {
    type: locationActions.actionsTypes.RESET_SUBLOCATIONS_COUNT,
  }
}

locationActions.resetError = () => {
  return {
    type: locationActions.actionsTypes.RESET_ERROR,
  }
}

locationActions.resetUsers = () => {
  return {
    type: locationActions.actionsTypes.RESET_USERS,
  }
}

locationActions.updateTimeoutForLocation = (updatedLoc) => {
  return {
    type: locationActions.actionsTypes.UPDATE_TIMEOUT_FOR_LOCATION,
    payload: {
      updatedLoc,
    },
  }
}

locationActions.updateTimeoutForLocationSucceeded = (updatedLoc) => {
  return {
    type: locationActions.actionsTypes.UPDATE_TIMEOUT_FOR_LOCATION_SUCCEEDED,
    payload: {
      updatedLoc,
    },
  }
}

locationActions.updateTimeoutForLocationFailed = (message) => {
  return {
    type: locationActions.actionsTypes.UPDATE_TIMEOUT_FOR_LOCATION_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.validateLocations = (body, isBulk, onSuccess, onError) => {
  return {
    type: locationActions.actionsTypes.VALIDATE_LOCATIONS,
    payload: {
      body,
      isBulk,
      onSuccess,
      onError,
    },
  }
}

locationActions.validateLocationsSucceeded = (message) => {
  return {
    type: locationActions.actionsTypes.VALIDATE_LOCATIONS_SUCCEEDED,
    payload: {
      message,
    },
  }
}

locationActions.validateLocationsFailed = (message) => {
  return {
    type: locationActions.actionsTypes.VALIDATE_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

locationActions.getCurrentLocationSchema = () => {
  return {
    type: locationActions.actionsTypes.GET_CURRENT_LOCATIONS_SCHEMA,
  }
}

locationActions.getCurrentLocationSchemaSucceeded = (schema) => {
  return {
    type: locationActions.actionsTypes.GET_CURRENT_LOCATIONS_SCHEMA_SUCCEEDED,
    payload: {
      schema,
    },
  }
}

locationActions.getCurrentLocationSchemaFailed = (message) => {
  return {
    type: locationActions.actionsTypes.GET_CURRENT_LOCATIONS_SCHEMA_FAILED,
    payload: {
      message,
    },
  }
}

export default locationActions
