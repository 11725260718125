import React, { useEffect } from 'react'
import { isUndefined } from 'lodash'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

import style from './style'
import Typography from '@material-ui/core/Typography'
import { withMediaQuery } from '../../../../../services/root/root-service'
import { getPieChart } from '../../../ARC/service/arcSummary-service'
import { TOOLTIP_LABELS } from '../../services/summary-constants'

const LocationsChartContainer = (props) => {
  const {
    isMobile,
    totalLocationsCount,
    secondaryLocationsCount,
    classes,
    createSubtitle,
    title,
    blueTitle,
    grayTitle,
  } = props
  const notAssignedLocations = totalLocationsCount - secondaryLocationsCount

  useEffect(() => {
    if (
      !isUndefined(totalLocationsCount) &&
      !isUndefined(secondaryLocationsCount)
    ) {
      getPieChart(
        secondaryLocationsCount,
        notAssignedLocations,
        isMobile,
        'disinfected-locations',
        blueTitle,
        grayTitle,
        TOOLTIP_LABELS.location
      )
    }
  }, [secondaryLocationsCount, totalLocationsCount, isMobile])

  return (
    <div className={classNames('pdf-chart-50', classes.chartContainer)}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.subtitle}
      >
        {createSubtitle(totalLocationsCount)}
      </Typography>
      <div id="disinfected-locations-container" className={classes.chart}></div>
      <div
        id="disinfected-locations-container-pdf"
        className={classes.chartPdf}
      ></div>
    </div>
  )
}

export default withMediaQuery(withStyles(style)(LocationsChartContainer))
