const rootActions = {}

rootActions.actionTypes = {
  LOAD_USER_PERMISSIONS: '{ROOT} Load User Permissions',
  LOAD_USER_PERMISSIONS_SUCCEEDED: '{ROOT} Load User Permissions Succeeded',
  LOAD_USER_PERMISSIONS_FAILED: '{ROOT} Load User Permissions Failed',

  LOAD_FEATURE_TOGGLING: '{ROOT} Load Feature Toggling',
  LOAD_FEATURE_TOGGLING_SUCCEEDED: '{ROOT} Load Feature Toggling Succeeded',
  LOAD_FEATURE_TOGGLING_FAILED: '{ROOT} Load Feature Toggling Failed',

  SET_NEW_DEFAULT_CYCLE_TIME: '{ROOT} Set New Default Cycle Time',
  SET_NETWORK_ERROR: '{ROOT} Set Network Error',

  LOAD_BUILD_VERSION_SUCCEEDED: '{ROOT} Load Build Version Succeeded',
  LOAD_BUILD_VERSION_FAILED: '{ROOT} Load Build Version Failed',

  GET_DEFAULT_DEVICE_MODE_FOR_ACCOUNT: '{ROOT} Get Default Device Mode',
  GET_DEFAULT_DEVICE_MODE_FOR_ACCOUNT_SUCCEEDED:
    '{ROOT} Get Default Device Mode Succeeded',
  GET_DEFAULT_DEVICE_MODE_FOR_ACCOUNT_FAILED:
    '{ROOT} Get Default Device Mode  ',

  LOAD_LOCATIONS: '{ROOT} Load Locations',
  LOAD_LOCATIONS_FAILED: '{ROOT} Load Locations Fail',

  GET_LOCATION_MODIFICATION_ID: '{ROOT} Get Location Modification Id',
  GET_LOCATION_MODIFICATION_ID_SUCCEEDED:
    '{ROOT} Get Location Modification Id Succeeded',
  GET_LOCATION_MODIFICATION_ID_FAILED:
    '{ROOT} Get Location Modification Id Failed',

  GET_BLUEPRINT_LINK: '{ROOT} Get Blueprint Link',
  GET_BLUEPRINT_LINK_SUCCEEDED: '{ROOT} Get Blueprint Link Succeeded',
  GET_BLUEPRINT_LINK_FAILED: '{ROOT} Get Blueprint Link Failed',

  LOAD_CONFIGURATIONS: '{ROOT} Load Configuration',
}

rootActions.loadConfiguration = () => {
  return {
    type: rootActions.actionTypes.LOAD_CONFIGURATIONS,
  }
}

rootActions.getLocationModificationId = () => {
  return {
    type: rootActions.actionTypes.GET_LOCATION_MODIFICATION_ID,
  }
}

rootActions.getLocationModificationIdSuccess = (id) => {
  return {
    type: rootActions.actionTypes.GET_LOCATION_MODIFICATION_ID_SUCCEEDED,
    payload: {
      id,
    },
  }
}

rootActions.getLocationModificationIdFailed = (message) => {
  return {
    type: rootActions.actionTypes.GET_LOCATION_MODIFICATION_ID_FAILED,
    payload: {
      message,
    },
  }
}

rootActions.loadUserPermissions = () => {
  return {
    type: rootActions.actionTypes.LOAD_USER_PERMISSIONS,
  }
}

rootActions.loadUserPermissionsSuccess = (permissions) => {
  return {
    type: rootActions.actionTypes.LOAD_USER_PERMISSIONS_SUCCEEDED,
    payload: {
      permissions,
    },
  }
}

rootActions.loadUserPermissionsFailed = (message) => {
  return {
    type: rootActions.actionTypes.LOAD_USER_PERMISSIONS_FAILED,
    payload: {
      message,
    },
  }
}

rootActions.loadBuildVersionSuccess = (data) => {
  return {
    type: rootActions.actionTypes.LOAD_BUILD_VERSION_SUCCEEDED,
    payload: {
      data,
    },
  }
}

rootActions.loadBuildVersionFailed = (message) => {
  return {
    type: rootActions.actionTypes.LOAD_BUILD_VERSION_FAILED,
    payload: {
      message,
    },
  }
}

rootActions.loadFeatureToggling = () => {
  return {
    type: rootActions.actionTypes.LOAD_FEATURE_TOGGLING,
  }
}

rootActions.loadFeatureTogglingSuccess = (data) => {
  return {
    type: rootActions.actionTypes.LOAD_FEATURE_TOGGLING_SUCCEEDED,
    payload: {
      data,
    },
  }
}

rootActions.loadFeatureTogglingFailed = (message) => {
  return {
    type: rootActions.actionTypes.LOAD_FEATURE_TOGGLING_FAILED,
    payload: {
      message,
    },
  }
}

rootActions.setNewDefaultCycleTime = (defaultCycleTime) => {
  return {
    type: rootActions.actionTypes.SET_NEW_DEFAULT_CYCLE_TIME,
    payload: {
      defaultCycleTime,
    },
  }
}

rootActions.setNetworkError = (isOnline) => {
  return {
    type: rootActions.actionTypes.SET_NETWORK_ERROR,
    payload: {
      isOnline,
    },
  }
}

rootActions.getDefaultAccountMode = () => ({
  type: rootActions.actionTypes.GET_DEFAULT_DEVICE_MODE_FOR_ACCOUNT,
})

rootActions.getDefaultAccountModeSucceeded = (orgInfo) => ({
  type: rootActions.actionTypes.GET_DEFAULT_DEVICE_MODE_FOR_ACCOUNT_SUCCEEDED,
  payload: {
    orgInfo,
  },
})

rootActions.getDefaultAccountModeFailed = (message) => ({
  type: rootActions.actionTypes.GET_DEFAULT_DEVICE_MODE_FOR_ACCOUNT_FAILED,
  payload: {
    message,
  },
})

rootActions.getBlueprintLink = (orgId) => ({
  type: rootActions.actionTypes.GET_BLUEPRINT_LINK,
  payload: {
    orgId,
  },
})

rootActions.getBlueprintLinkSucceeded = (blueprintLink) => ({
  type: rootActions.actionTypes.GET_BLUEPRINT_LINK_SUCCEEDED,
  payload: {
    blueprintLink,
  },
})

rootActions.getBlueprintLinkFailed = (message) => ({
  type: rootActions.actionTypes.GET_BLUEPRINT_LINK_FAILED,
  payload: {
    message,
  },
})

rootActions.loadLocations = () => {
  return {
    type: rootActions.actionTypes.LOAD_LOCATIONS,
  }
}

export default rootActions
