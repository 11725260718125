import { Dispatch } from 'redux'
import { createAction } from 'redux-act'

export type EventPayload = {
  eventCategory: string
  eventParams: { [label: string]: string }
  eventName: string
}

export const GA_EVENT_SEND = createAction<EventPayload>(
  '{Google Analytics} Send event'
)

export const gaEventAction =
  (dispatch: Dispatch) =>
  (
    eventCategory: string,
    eventParams: { [label: string]: string },
    eventName = 'click'
  ) => {
    dispatch(
      GA_EVENT_SEND({
        eventCategory,
        eventParams,
        eventName,
      })
    )
  }
