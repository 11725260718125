import { createTheme } from '@material-ui/core/styles'
import { COLORS } from './services/constants'

const mobileTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1224,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: COLORS.BLUE,
    },
    info: {
      main: COLORS.GRAY,
    },
    error: {
      main: COLORS.RED_ORANGE,
    },
    secondary: {
      main: COLORS.RED,
    },
  },
  text: {
    secondary: COLORS.GRAY,
    error: COLORS.RED,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '26.5px',
        textTransform: 'none',
        minWidth: '40%',
        fontSize: '16px',
        height: '45px',
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        padding: '6px 30px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiButtonBase: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '26.5px',
      },
      notchedOutline: {
        paddingLeft: '16px',
      },
      input: {
        paddingLeft: '25px',
        '&::placeholder': {
          color: COLORS.LIGHT_SILVER,
          opacity: 1,
          fontFamily: 'Cerebri Sans Pro Regular',
        },
      },
    },
    MuiInputLabel: {
      root: {
        paddingLeft: '12px',
      },
    },
    MuiTypography: {
      body2: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      subtitle2: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
      },
      subtitle1: {
        fontFamily: 'Cerebri Sans Pro Regular',
        fontSize: '14px',
        lineHeight: '17px',
      },
      body1: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        fontSize: '10px',
        lineHeight: '12px',
      },
      h6: {
        fontFamily: 'Cerebri Sans Pro Regular',
        fontSize: '10px',
        lineHeight: '12px',
      },
      h5: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        fontSize: '16px',
        lineHeight: '17px',
      },
      h4: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        fontSize: '20px',
      },
      h3: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
      },
      h2: {
        fontFamily: 'Cerebri Sans Pro Medium',
        fontSize: '36px',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        padding: '20px 8px',
      },
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
      },
    },
    MuiDialogContent: {
      root: {
        textAlign: 'center',
        fontSize: '10px',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        width: '77%',
      },
      paperWidthSm: {
        maxWidth: '500px',
      },
    },
    MuiFormControl: {
      root: {
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    MuiGrid: {
      root: {
        minHeight: '100vh',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
        color: COLORS.LIGHT_SILVER,
        '&$error': {
          color: COLORS.RED_CINNABAR,
        },
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
    MuiToolbar: {
      regular: {
        padding: 0,
      },
    },
    MuiSelect: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
        color: COLORS.GRAY,
      },
      select: {
        '&:focus': {
          borderRadius: '26.5px',
        },
      },
      iconOutlined: {
        right: '17px',
        color: COLORS.BLACK,
        fontSize: '24px',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      input: {
        color: COLORS.GRAY,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '35px',
      },
    },
    MuiChip: {
      label: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
        display: 'block',
        textOverflow: 'ellipsis',
        lineHeight: '35px',
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 0,
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
  },
})

export default mobileTheme
