import React from 'react'
import { PROGRESS_LABELS } from '../../../../services/dashboard-constants'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import { getCalculatedValue } from '../../../../services/related/transaction-service'
import style from './style'
import * as moment from 'moment'
import { mssFormat } from '../../../../../../services/dateFormat-service'

const CountdownComponent = (props) => {
  const { progressValue = 0, isErrorCalculation, classes } = props
  const calculatedTime = moment(progressValue).format(mssFormat)

  return (
    <div className={classes.progressNumber}>
      <Typography className={classes.cycleName} color="textSecondary">
        {`${PROGRESS_LABELS.preCycle} ${PROGRESS_LABELS.countdown}`}
      </Typography>
      <span className={classes.circularLabel}>
        {getCalculatedValue(isErrorCalculation, calculatedTime)}
      </span>
    </div>
  )
}

export default withStyles(style)(CountdownComponent)
