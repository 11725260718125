import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import styles from './style'
import { withMediaQuery } from '../../../../../services/root/root-service'
import { getDeviceDetailsPageLabel } from '../../../Details/ARC/services/arcDeviceDetails-constants'

const BackToDeviceList = withStyles(styles)((props) => {
  const { serialNumber, isMobile, classes, goBackToDevices, showErrorMessage } =
    props

  return (
    <div className={classes.titlesWrapper}>
      <IconButton onClick={goBackToDevices} className={classes.backButton}>
        <ArrowBack className={classes.backIcon} />
      </IconButton>
      <Typography
        variant={isMobile ? 'subtitle2' : 'h3'}
        color={isMobile && showErrorMessage ? 'error' : 'inherit'}
      >
        {isMobile ? getDeviceDetailsPageLabel(serialNumber) : serialNumber}
      </Typography>
    </div>
  )
})

export default withMediaQuery(BackToDeviceList)
