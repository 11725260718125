import { cloneDeep } from 'lodash'
import {
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import customTreeActions from '../../../../components/_shared/customTree/services/customTree-actions'
import { HttpClient } from '../../../../services/HttpClient'
import devicesActions from './devices-actions'
import { DEVICES_PATH_REGEXP } from './devices-service'

const getCurrentUserId = (state) => state.rootReducer.userId
const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* submitDeviceRequest(action) {
  try {
    const { id, message, userProfileId } = action.payload

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/devices/${id}/serviceRequests/send`,
        {
          body: {
            message,
            userProfileId,
          },
        }
      )
    })

    if (data.message && !data.id) {
      yield put(devicesActions.submitRequestFailed(data.message))
    } else {
      yield put(devicesActions.submitRequestSucceeded(data))
    }
  } catch (e) {
    yield put(devicesActions.submitRequestFailed(e.message))
  }
}

function* getRequestServiceMessage(action) {
  try {
    const { deviceId, userId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/${deviceId}/serviceRequests/${userId}/template`
      )
    })

    if (data.message) {
      yield put(devicesActions.getRequestServiceMessageFailed(data.message))
    } else {
      yield put(devicesActions.getRequestServiceMessageSucceeded(data))
    }
  } catch (e) {
    yield put(devicesActions.getRequestServiceMessageFailed(e.message))
  }
}

function* getCumulativeUsageSaga() {
  yield takeLatest(
    devicesActions.actionTypes.CUMULATIVE_USAGE_START,
    function* (action) {
      try {
        const { deviceId } = action.payload

        const data = yield call(() => {
          return HttpClient.get(
            `/api/secured/devices/transactions/${deviceId}/cumulative-data`
          )
        })

        if (data.message) {
          yield put({
            type: devicesActions.actionTypes.CUMULATIVE_USAGE_FAILED,
            payload: data.message,
          })
        } else {
          yield put({
            type: devicesActions.actionTypes.CUMULATIVE_USAGE_SUCCEEDED,
            payload: data,
          })
        }
      } catch (e) {
        yield put(
          devicesActions.getRequestServiceMessageFailed({
            type: devicesActions.actionTypes.CUMULATIVE_USAGE_FAILED,
            payload: devicesActions.getRequestServiceMessageFailed(e.message),
          })
        )
      }
    }
  )
}

function* updateLocationTree() {
  const isTriggeredFromDevices = DEVICES_PATH_REGEXP.test(window.location.href)
  const userId = yield select(getCurrentUserId)
  const tree = yield select(getInitialNodesTree)

  if (isTriggeredFromDevices) {
    yield put(customTreeActions.loadNodesSucceeded(userId, tree))
  }
}

function* clearTreeState() {
  yield put(customTreeActions.resetTreeState())
}

function* getRequestServiceMessageSaga() {
  yield takeEvery(
    devicesActions.actionTypes.GET_SERVICE_REQUEST_MESSAGE,
    getRequestServiceMessage
  )
}

function* submitRequestSaga() {
  yield takeEvery(
    devicesActions.actionTypes.SUBMIT_REQUEST,
    submitDeviceRequest
  )
}

function* clearTreeSaga() {
  yield takeEvery(devicesActions.actionTypes.CLEAR_NODES, clearTreeState)
}

function* updateLocationTreeSaga() {
  yield takeLatest(
    customTreeActions.actionsTypes.LOAD_INITIAL_LOCATION_TREE_SUCCESS,
    updateLocationTree
  )
}

export default function* deviceSaga() {
  yield fork(submitRequestSaga)
  yield fork(getRequestServiceMessageSaga)
  yield fork(clearTreeSaga)
  yield fork(updateLocationTreeSaga)
  yield fork(getCumulativeUsageSaga)
}
