import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  textWrapper: {
    display: 'flex',
    margin: '20px 0',
    cursor: 'pointer',
  },
  rowIcon: {
    marginLeft: '5px',
    height: '18px',
  },
  modalTitle: {
    marginTop: '20px',
  },
  dialogContent: {
    marginBottom: '25px',
  },
  propertyWrapper: {
    textAlign: 'left',
    backgroundColor: COLORS.WARM_GRAY,
    marginTop: '10px',
    height: '32px',
    padding: '0 10px',
  },
  property: {
    lineHeight: '32px',
  },
  '@media (min-width: 1224px)': {
    textWrapper: {
      justifyContent: 'center',
      marginTop: 0,
    },
    propertyWrapper: {
      backgroundColor: COLORS.WHITE,
    },
    doneIcon: {
      height: '12px',
      marginRight: '3px',
    },
    dialogContent: {
      borderTop: `1px solid ${COLORS.GRAY_SILVER_SAND}`,
      padding: '8px 45px',
    },
    modalTitle: {
      margin: '20px 0',
    },
  },
})

export default styles
