const styles = (theme) => ({
  filterChip: {
    marginBottom: '8px',
  },
  filterWrapper: {
    marginLeft: '16px',
  },
})

export default styles
