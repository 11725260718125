import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

import WarningModal from '../warningConfirm/WarningModal'

import { CONFIRM_BUTTON_LABEL } from './checkboxModal-constants'
import styles from './style'
import CloseButton from '../closeButton/CloseButton'

const CheckboxModal = withStyles(styles)((props) => {
  const {
    classes,
    open,
    error,
    title,
    description,
    actionLabel,
    actionDescription,
    onClose,
    ContentComponent,
    inputProps,
    isWarning = false,
    warningProps,
    onConfirm,
    hideCheckbox,
    defaultValue,
  } = props

  const [value, setValue] = useState(defaultValue || 0)
  const [checked, setChecked] = useState(false)

  const [inputError, setInputError] = useState(false)

  const [isWarningOpen, setIsWarningOpen] = useState(false)

  const isValid = !inputError && !error

  const handleClose = () => {
    setChecked(false)

    if (isWarningOpen) {
      setIsWarningOpen(false)
    }

    onClose()
  }

  const handleCheckboxClick = () => {
    setChecked(!checked)
  }

  const changeIsWarningState = () => {
    setIsWarningOpen(!isWarningOpen)
  }

  const handleConfirm = () => {
    if (isValid) {
      onConfirm(value, handleClose, checked)
    }
  }

  const onApply = () => {
    if (checked && isValid && isWarning) {
      changeIsWarningState()
    } else {
      handleConfirm()
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle disableTypography={true}>
          <Typography className={classes.modalTitle}>{title}</Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <CloseButton onCloseModal={handleClose} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.descriptionContainer}>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </div>

          <ContentComponent
            {...inputProps}
            value={value}
            setValue={setValue}
            inputError={inputError}
            setInputError={setInputError}
          />

          {!hideCheckbox && (
            <div className={classes.checkboxContainer}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" onChange={handleCheckboxClick} />
                }
                label={actionLabel}
                classes={{
                  label: classes.actionLabel,
                }}
              />
              <FormHelperText className={classes.actionDescription}>
                {actionDescription}
              </FormHelperText>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onApply} color="primary">
            {CONFIRM_BUTTON_LABEL}
          </Button>
          <WarningModal
            isWarning
            isOpen={isWarningOpen}
            onClose={changeIsWarningState}
            onSubmit={handleConfirm}
            error={error}
            {...warningProps}
          />
        </DialogActions>
      </Dialog>
    </>
  )
})

export default CheckboxModal
