import { forEach, indexOf } from 'lodash'
import { deeps, TREE_LEVELS } from './constants'

const getAllIds = (tree) => {
  const selectedIds = []

  let traverse = function (current) {
    forEach(current, (item) => {
      if (item.locationType === TREE_LEVELS.ROOM) {
        selectedIds.push(item.id)
      }

      if (item.children && item.children.length) {
        traverse(item.children)
      }
    })
  }

  traverse(tree, 1)

  return selectedIds
}

const getSelectedIds = (tree, pref) => {
  const selectedIds = []
  let propertyId

  let traverse = function (current) {
    forEach(current, (item) => {
      if (item.locationType === TREE_LEVELS.PROPERTY) {
        propertyId = item.id
      }

      if (item.locationType === TREE_LEVELS.ROOM) {
        let isChecked = indexOf(pref, propertyId) !== -1 || item.checked

        if (isChecked) {
          selectedIds.push(item.id)
        }
      }

      if (item.children && item.children.length) {
        traverse(item.children)
      }
    })
  }

  traverse(tree)

  return selectedIds
}

export { getSelectedIds, getAllIds }
