import { jsPDF } from 'jspdf'

import { BasePdfElement } from './BasePdfElement'

export class PdfRow extends BasePdfElement {
  pdfElement: BasePdfElement[]

  constructor(pdfImageCards: BasePdfElement[]) {
    super()
    this.pdfElement = pdfImageCards
  }

  setup(
    doc: jsPDF,
    position_x: number,
    position_y: number,
    height: number,
    width: number
  ): void {
    super.setup(doc, position_x, position_y, height, width)
    const sep = 2
    const widthCol = width / this.pdfElement.length - sep
    let posCol_x = position_x
    this.pdfElement.map((colElement) => {
      colElement.setup(doc, posCol_x, position_y, height, widthCol)
      posCol_x += sep + widthCol
    })
  }

  async render() {
    await Promise.all(this.pdfElement.map((colElement) => colElement.render()))
  }
}
