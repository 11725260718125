import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import style from './styles'
import { VIEW_ASSIGNED_PROPERTIES_LABELS } from '../../services/account-constants'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Link from '@material-ui/core/Link'
import ListModal from './ListModal'
import { withMediaQuery } from '../../../../services/root/root-service'

const ViewAssignedPropertiesModal = (props) => {
  const { allowedPropertiesNames, propertiesNames, isMobile, classes } = props
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const openModal = () => {
    setOpen(true)
  }

  return (
    <>
      <Link
        className={classes.textWrapper}
        onClick={openModal}
        component="div"
        variant="subtitle1"
        color="primary"
      >
        {VIEW_ASSIGNED_PROPERTIES_LABELS.viewAssignedProperties}
        {isMobile ? (
          <ArrowForwardIcon className={classes.rowIcon} />
        ) : (
          <ArrowForwardIosIcon className={classes.rowIcon} />
        )}
      </Link>
      <ListModal
        isOpen={open}
        onClose={handleClose}
        properties={
          allowedPropertiesNames.length
            ? allowedPropertiesNames
            : propertiesNames
        }
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  allowedPropertiesNames: state.rootReducer.allowedPropertiesNames,
  propertiesNames: state.accountReducer.propertiesNames,
})

export default connect(mapStateToProps)(
  withMediaQuery(withStyles(style)(ViewAssignedPropertiesModal))
)
