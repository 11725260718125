import { combineReducers } from 'redux'
import { ARCSummaryReducer } from '../../ARC/service/arcSummary-reducer'
import { UVGISummaryReducer } from '../../UVGI/services/uvgiSummary-reducer'
import { sharedSummaryReducer } from './summary-reducers'

export const summaryReducer = combineReducers({
  sharedSummaryReducer,
  ARCSummaryReducer,
  UVGISummaryReducer,
})
