import { COLORS } from '../../../../../../../services/constants'

const styles = () => ({
  recentUsageContainer: {
    padding: '25px',
    border: `1px solid ${COLORS.LIGHT_SILVER}`,
    '& > *': {
      marginBottom: '12px',
      color: COLORS.GRAY,
    },
  },
  titleWrapper: {
    marginBottom: '24px',
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    color: COLORS.GRAFIT,
  },
  secondaryText: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
  },
  error: {
    '& span': {
      color: COLORS.RED,
    },
  },
  rowName: {
    fontFamily: 'Cerebri Sans Pro Semi Bold',
  },
  '@media (min-width: 1224px)': {
    recentUsageContainer: {
      boxSizing: 'border-box',
      backgroundColor: COLORS.WHITE,
      border: 'none',
      borderRight: `1px solid ${COLORS.GRAY_IRON}`,
      width: '50%',
    },
  },
})

export default styles
