import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { MAINTENANCE_REMINDER } from '../../../services/uvgiDeviceDetails-constants'
import styles from './style'

const MaintenanceReminder = (props) => {
  const { isMobile, onClose, classes } = props

  return (
    <div className={classes.maintenanceReminderContainer}>
      <div className={classes.info}>
        <Typography
          className={classes.maintenanceReminderTitle}
          variant={isMobile ? 'h5' : 'subtitle2'}
        >
          {MAINTENANCE_REMINDER.title}
        </Typography>
        <Typography
          className={classes.maintenanceReminderHint}
          variant={isMobile ? 'h5' : 'subtitle1'}
        >
          {MAINTENANCE_REMINDER.hint}
        </Typography>
      </div>
      <Button onClick={onClose} className={classes.maintenanceReminderButton}>
        {MAINTENANCE_REMINDER.actionLabel}
      </Button>
    </div>
  )
}

export default withStyles(styles)(MaintenanceReminder)
