import React from 'react'
import { getTypesInfo } from '../../services/dashboard-service'
import style from './style'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const DeviceTypeInfo = (props) => {
  const { type, subType, classes } = props

  return (
    <div className={classes.typeContainer}>
      <Typography variant="body1" className={classes.typeWrapper}>
        {getTypesInfo(type, subType)}
      </Typography>
    </div>
  )
}

export default withStyles(style)(DeviceTypeInfo)
