import React from 'react'
import { connect } from 'react-redux'
import DateFilter from '../../_shared/components/DateFilter'
import actions from '../services/uvgiSummary-actions'
import { checkConnection } from '../../../../services/root/root-service'
import { gaEventAction } from '../../../../services/googleAnalytics/ga-actions'

const UVGIDateFilter = (props) => {
  const {
    dateFilter = {},
    changeSelectedChip,
    period,
    label,
    timezone,
    gaContext = {},
    gaEventSend,
  } = props

  return (
    <DateFilter
      timezone={timezone}
      dateFilter={dateFilter}
      changeSelectedChip={changeSelectedChip}
      label={label}
      period={period}
      gaContext={gaContext}
      gaEventSend={gaEventSend}
    />
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { UVGISummaryReducer },
    rootReducer,
  } = state

  return {
    dateFilter: UVGISummaryReducer.dateFilter,
    label: UVGISummaryReducer.dateFilterLabel,
    period: UVGISummaryReducer.period,
    timezone: rootReducer.timezone,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedChip: (data, label) =>
      dispatch(checkConnection(() => actions.changeDateFilter(data, label))),
    gaEventSend: gaEventAction(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UVGIDateFilter)
