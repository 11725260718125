import { withStyles } from '@material-ui/core'
import React from 'react'
import Chip from '@material-ui/core/Chip'
import SvgIconComponent from '../../../../../components/_shared/SvgIconComponent'
import { DOWNLOAD_PDF } from '../../services/summary-constants'
import styles from './styles'

const DownloadPDF = (props) => {
  const { downloadPDF, classes } = props

  return (
    <Chip
      className={classes.downloadButton}
      icon={<SvgIconComponent name="pdf" size={23} />}
      onClick={downloadPDF}
      label={DOWNLOAD_PDF}
      clickable
      variant="outlined"
    />
  )
}

export default withStyles(styles)(DownloadPDF)
