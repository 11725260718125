import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CheckboxModal from './CheckboxModal'

import styles from './style'
import { withMediaQuery } from '../../../../services/root/root-service'

const CheckboxModalButton = withStyles(styles)((props) => {
  const {
    error,
    resetError,
    buttonSubLabel = '',
    title,
    description = '',
    actionLabel,
    actionDescription = '',
    ContentComponent,
    inputProps, // porps for content component
    warningProps, // props for warning modal: modalTitle, buttonLabel, descriptionText, descriptionText2
    isWarning,
    onConfirm,
    hideCheckbox,
    defaultValue,
    onClose,
    onOpen,
    isMobile,
    classes,
  } = props

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)

    if (onClose) {
      onOpen()
    }
  }

  const handleClose = () => {
    setOpen(false)

    if (onClose) {
      onClose()
    }

    resetError()
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        className={classes.openButton}
      >
        {isMobile ? title : `${title}: ${buttonSubLabel}`}
        {isMobile && (
          <Typography className={classes.buttonSubLabel}>
            {buttonSubLabel}
          </Typography>
        )}
      </Button>
      <CheckboxModal
        open={open}
        hideCheckbox={hideCheckbox}
        onClose={handleClose}
        defaultValue={defaultValue}
        title={title}
        error={error}
        description={description}
        actionLabel={actionLabel}
        actionDescription={actionDescription}
        ContentComponent={ContentComponent}
        inputProps={inputProps}
        isWarning={isWarning}
        warningProps={warningProps}
        onConfirm={onConfirm}
      />
    </>
  )
})

export default withMediaQuery(CheckboxModalButton)
