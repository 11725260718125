import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './style'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import SvgIcon from '@material-ui/core/SvgIcon'
import { API_KEY_LABELS } from '../../services/account-constants'
import Link from '@material-ui/core/Link'

const ApiKeyButton = withStyles(styles)((props) => {
  const { classes } = props

  const goToApiKeyPage = () => {
    window.location.href = '/#/api_key'
  }

  return (
    <>
      <Card className={classes.apiKey}>
        <CardContent className={classes.apiKeyContent}>
          <Box color="text.secondary" display="flex">
            <Link
              color="textSecondary"
              className={classes.link}
              onClick={goToApiKeyPage}
              underline="none"
            >
              <Typography variant="subtitle1">
                {API_KEY_LABELS.apiKey}
              </Typography>
            </Link>
            <SvgIcon component={ArrowForwardIcon} className={classes.rowIcon} />
          </Box>
        </CardContent>
      </Card>
    </>
  )
})

export default ApiKeyButton
