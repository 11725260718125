import { isEmpty } from 'lodash'
import infoTreeActions from '../../../../components/_shared/infoTree/services/infoTree-actions'
import { getAllIds, getSelectedIds } from '../../../../services/uvgi-service'
import { prepareLocationFiltersForDevice } from '../../../Devices/_shared/services/devices-service'
import {
  initialState,
  INITIAL_DATE_FILTER_STATE,
} from './uvgiSummary-constants'
import uvgiSummaryActions from './uvgiSummary-actions'
import customTreeActions from '../../../../components/_shared/customTree/services/customTree-actions'
import {
  prepareCompletedCycles,
  prepareDevicesActivities,
  prepareLocationsActivities,
  prepareLocationsForSummary,
} from './uvgiSummary-service'

export const UVGISummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA_SUCCEEDED: {
      const { data, timezone } = action.payload

      if (!isEmpty(data)) {
        const completedCyclesData = prepareCompletedCycles(
          state.dateFilter,
          state.period,
          timezone,
          data.cycles
        )

        const dataCopy = { ...data }

        delete dataCopy.cycles

        return {
          ...state,
          ...dataCopy,
          completedCyclesData,
          loadSummaryDataError: '',
          isLoading: false,
          summaryIsEmpty: false,
        }
      }

      return {
        ...state,
        loadSummaryDataError: '',
        isLoading: false,
        summaryIsEmpty: true,
      }
    }
    case infoTreeActions.actionsTypes.LOAD_NODES_SUCCESS:
    case customTreeActions.actionsTypes.LOAD_NODES_SUCCESS: {
      return {
        ...state,
        locationTree: action.payload.nodes,
        error: '',
        isLoading: false,
      }
    }
    case uvgiSummaryActions.actionsTypes.GET_DEVICES_FAILED:
    case uvgiSummaryActions.actionsTypes.LOAD_UVGI_SUMMARY_DATA_FAILED: {
      return {
        ...state,
        loadSummaryDataError: action.payload.message,
        isLoading: false,
      }
    }
    case uvgiSummaryActions.actionsTypes.LOAD_LOCATIONS_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    case uvgiSummaryActions.actionsTypes.GET_DEVICES_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        totalDevicesCount: data.totalDevices,
        activeDevicesCount: state.dateFilter.today
          ? state.activeDevicesCount
          : data.activeDevices,
        devices: data.devices,
        loadSummaryDataError: '',
        isLoading: false,
      }
    }
    case uvgiSummaryActions.actionsTypes.CHANGE_DATE_FILTER: {
      const { dateFilter, period, label } = action.payload

      return {
        ...state,
        dateFilter,
        period,
        dateFilterLabel: label,
      }
    }
    case uvgiSummaryActions.actionsTypes.RESET_DATE_FILTER: {
      return {
        ...state,
        ...INITIAL_DATE_FILTER_STATE,
      }
    }
    case uvgiSummaryActions.actionsTypes.APPLY_LOCATION_FILTER: {
      const { nodes } = action.payload
      const params = prepareLocationFiltersForDevice(nodes)
      const ids = getSelectedIds(nodes, params.selectedBuildings)

      return {
        ...state,
        locationFilter: params,
        ids,
      }
    }
    case uvgiSummaryActions.actionsTypes.PREPARE_LOCATION_FILTER: {
      const { nodes, tree } = action.payload
      const ids = getAllIds(tree.children)

      return {
        ...state,
        locationFilter: nodes,
        ids,
      }
    }
    case uvgiSummaryActions.actionsTypes
      .LOAD_UVGI_DEVICES_ACTIVITIES_FOR_SUMMARY_SUCCEEDED: {
      const { data } = action.payload
      const generalDeviceData = prepareDevicesActivities(state.devices, data)
      const lastActivitiesSummary = prepareLocationsForSummary(
        data,
        state.dateFilter
      )

      return {
        ...state,
        deviceActivities: generalDeviceData.activeDevices,
        activeDevicesCount: state.dateFilter.today
          ? generalDeviceData.activeCount
          : state.activeDevicesCount,
        lastActivitiesSummary: lastActivitiesSummary.locations,
        disinfectedLocationsCount: lastActivitiesSummary.activeCount,
        loadSummaryDataError: '',
        isLoading: false,
      }
    }
    case uvgiSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_SUCCEEDED: {
      const {
        data: { totalLocations, locations, disinfectedLocations },
      } = action.payload
      const newLocations = prepareLocationsActivities(
        locations,
        state.lastActivitiesSummary
      )

      return {
        ...state,
        disinfectedLocationsCount: state.dateFilter.today
          ? state.disinfectedLocationsCount
          : disinfectedLocations,
        totalLocationsCount: totalLocations,
        locations: newLocations,
      }
    }
    case uvgiSummaryActions.actionsTypes.PUT_LOCATION_FILTER: {
      const { locationFilter, ids } = action.payload

      return {
        ...state,
        locationFilter,
        ids,
      }
    }
    default:
      return state
  }
}
