import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEqual, isNull } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import styles from './style'
import Snackbar from '@material-ui/core/Snackbar'
import extDeviceDetailsActions from '../../services/extDeviceDetails-actions'
import ExtDeviceLocationButton from './ExtDeviceLocationButton'
import {
  CHANGES_SAVED_LABEL,
  DEVICE_DETAILS_LABELS,
  DEVICE_MAX_NICKNAME_LENGTH,
} from '../../../../_shared/services/devices-constants'
import { autoHideDuration } from '../../../../../../services/constants'
import { checkConnection } from '../../../../../../services/root/root-service'

const ChangeDeviceDetailsForm = withStyles(styles)((props) => {
  const {
    isEditView,
    initialNickname,
    serialNumber,
    updateDevice,
    allowedLocationIds,
    roomsFilter,
    classes,
  } = props
  const [nickname, setNickname] = useState(initialNickname)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [isChanged, setIsChangedFlag] = useState(false)
  const { selectedBuildings } = roomsFilter
  const error = selectedBuildings && !selectedBuildings.length

  useEffect(() => {
    const isNicknameSame = isEqual(nickname, initialNickname)
    const isLocationsSame = isEqual(selectedBuildings, allowedLocationIds)

    if (isNicknameSame && isLocationsSame) {
      setIsChangedFlag(false)
    } else {
      setIsChangedFlag(true)
    }
  }, [nickname, selectedBuildings, allowedLocationIds])

  useEffect(() => {
    setNickname(initialNickname)
  }, [initialNickname])

  const onChangeNickname = (event) => {
    setNickname(event.target.value)
  }

  const disableButton = () => {
    setIsChangedFlag(false)
  }

  const onSuccess = () => {
    disableButton()

    setOpenSnackbar(true)
  }

  const handleUpdate = (event) => {
    event.preventDefault()
    const nicknameForRequest =
      isNull(initialNickname) && isNull(nickname) ? '' : nickname.trim()

    updateDevice(serialNumber, { nickname: nicknameForRequest }, onSuccess)
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  return (
    <>
      <form onSubmit={handleUpdate}>
        <div className={classes.formWrapper}>
          <ExtDeviceLocationButton error={error} disabled={!isEditView} />

          <TextField
            variant="outlined"
            label={DEVICE_DETAILS_LABELS.nickname}
            value={nickname}
            onChange={onChangeNickname}
            fullWidth
            disabled={!isEditView}
            inputProps={{ maxLength: DEVICE_MAX_NICKNAME_LENGTH }}
          />
        </div>

        <Button
          type="submit"
          disabled={!isChanged || error}
          variant="contained"
          color="primary"
          className={classes.saveButton}
        >
          {DEVICE_DETAILS_LABELS.saveChanges}
        </Button>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        message={CHANGES_SAVED_LABEL}
      />
    </>
  )
})

const mapStateToProps = (state) => {
  const {
    devicesReducer: { extDeviceDetailsReducer },
  } = state

  return {
    allowedLocationIds: extDeviceDetailsReducer.allowedLocationIds,
    serialNumber: extDeviceDetailsReducer.serialNumber,
    roomsFilter: extDeviceDetailsReducer.roomsFilter,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateDevice: (serialNumber, body, onSuccess) =>
      dispatch(
        checkConnection(() =>
          extDeviceDetailsActions.updateDevice(serialNumber, body, onSuccess)
        )
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeDeviceDetailsForm)
