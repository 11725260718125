import { split, filter, trim } from 'lodash'
import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { connect } from 'react-redux'
import WarningModal from '../../../../components/_shared/modals/warningConfirm/WarningModal'
import { API_KEY_LABELS } from '../../../Account/services/account-constants'
import {
  LOCATIONS_CONST,
  roomsFilterInitial,
} from '../../service/location-constants'
import ControlPart from './ControlPart'
import RoomFields from './RoomFields'
import styles from './style'
import { withMediaQuery } from '../../../../services/root/root-service'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'

const AddLocationModal = withMediaQuery(
  withStyles(styles)((props) => {
    const {
      onSubmit,
      onClose,
      modalTitle,
      isOpen,
      locationType,
      error,
      defaultValue,
      isMobile,
      warningOpen,
      onWarningConfirm,
      warningText,
      closeWarningModal,
      classes,
    } = props
    const [isEmptyTextField, setIsEmptyTextField] = useState(false)
    const [locationName, setLocationName] = useState('')
    const [isBulk, setBulkFlag] = useState(false)
    const [roomFields, setRoomFields] = useState(roomsFilterInitial)

    useEffect(() => {
      setLocationName('')
      setBulkFlag(false)
      setRoomFields({ ...roomsFilterInitial })
    }, [isOpen])

    let timer = 0
    let prevent = true

    const onChangeRoomsFields = (value, name) => {
      setRoomFields({
        ...roomFields,
        [name]: value,
      })
    }

    const handleChange = (event) => {
      setBulkFlag(event.target.checked)
    }

    const onCloseModal = () => {
      setIsEmptyTextField(false)

      setRoomFields({
        baselineAch: '',
        improvedEAch: '',
      })

      onClose()
    }

    const onChangeLocationName = (e) => {
      const locationName = e.target.value
      const splitted = split(locationName, '\n')

      const prepared = filter(splitted, (item) => {
        return item && trim(item)
      }).join('\n')

      if (
        splitted[splitted.length - 1] === '\n' ||
        splitted[splitted.length - 1] === ''
      ) {
        setLocationName(prepared + '\n')
      } else {
        setLocationName(prepared)
      }
    }

    const onSubmitButton = (event) => {
      event.preventDefault()
      const value = locationName

      if (value.trim() === '') {
        setIsEmptyTextField(true)
      } else {
        setIsEmptyTextField(false)

        if (error) {
          prevent = true
        }

        timer = setTimeout(function () {
          const isSendRoomField =
            locationType === LOCATIONS_CONST.ROOM && !isBulk

          if (prevent) {
            onSubmit(value, isBulk, isSendRoomField ? roomFields : false)
            prevent = false
          }
        }, 200)
      }
    }

    function handleDoubleClick() {
      clearTimeout(timer)
      prevent = true
    }

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={onCloseModal}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            className={classes.modalTitleContainer}
            disableTypography
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              className={classes.modalTitle}
            >
              {modalTitle}
            </Typography>
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
            <CloseButton onCloseModal={onCloseModal} />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {locationType === LOCATIONS_CONST.ROOM && !isBulk && (
              <RoomFields
                baselineAch={roomFields.baselineAch}
                onChangeRoomsFields={onChangeRoomsFields}
                improvedEAch={roomFields.improvedEAch}
              />
            )}
            <ControlPart
              locationType={locationType}
              handleDoubleClick={handleDoubleClick}
              onSubmitButton={onSubmitButton}
              error={error}
              defaultValue={defaultValue}
              isBulk={isBulk}
              handleChange={handleChange}
              onChangeLocationName={onChangeLocationName}
              locationName={locationName}
              isEmptyTextField={isEmptyTextField}
            />
          </DialogContent>
          <WarningModal
            isWarning
            isOpen={warningOpen}
            onClose={closeWarningModal}
            onSubmit={onWarningConfirm}
            variantHelperText2="subtitle2"
            buttonLabel={API_KEY_LABELS.confirm}
            dialogHelperText={warningText}
          />
        </Dialog>
      </div>
    )
  })
)

const mapStateToProps = (state) => ({
  error: state.locationReducer.error,
})

export default connect(mapStateToProps)(AddLocationModal)
