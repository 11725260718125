import React from 'react'
import CustomDividerComponent from '../../../components/_shared/customDivider/CustomDevider'
import EmptyScreen from '../../../components/_shared/emptyScreen/EmptyScreen'
import { EMPTY_ACTIVITIES_TEXT } from '../services/dashboard-constants'

const EmptyActivitiesListComponent = () => (
  <>
    <CustomDividerComponent label="Today" subLabel="0 Cycles" margin="12px" />
    <EmptyScreen helperText={EMPTY_ACTIVITIES_TEXT} />
  </>
)

export default EmptyActivitiesListComponent
