const styles = (theme) => ({
  filtersContainer: {
    flexWrap: 'wrap',
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
    marginBottom: '14px',
  },
  filtersChips: {
    marginBottom: '10px',
    width: 'fit-content',
  },
  filtersChipsModal: {
    marginRight: '5px',
    marginBottom: '5px',
    fontFamily: 'Cerebri Sans Pro Regular',
    width: '100px',
    fontSize: '10px',
  },
  filtersChipsModalSelected: {
    marginRight: '5px',
    width: 'fit-content',
    fontSize: '10px',
  },
  pickerContent: {
    padding: 0,
  },
})

export default styles
