import { COLORS } from '../../../../services/constants'

const style = () => ({
  emptySummaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 120px)',
    justifyContent: 'center',
    '& > *': {
      textAlign: 'center',
    },
  },
  iconWrapper: {
    marginTop: '23px',
    marginBottom: '23px',
  },
  emptyListText: {
    lineHeight: '21px',
    color: COLORS.GRAY,
    width: '70%',
    textAlign: 'center',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  linkWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: '12px 0 0',
  },
  linkToReport: {
    textDecoration: 'none',
    color: COLORS.BLUE,
  },
  '@media (min-width: 1224px)': {
    emptySummaryWrapper: {
      height: '58vh',
      backgroundColor: COLORS.WHITE,
      margin: '0 27%',
    },
  },
})

export default style
