import authorizationActions from './authorization-actions'
import { LOGIN_METHODS } from './authorization-constants'

export const authorizationReducer = (
  state = {
    error: '',
    resetPasswordError: '',
    setupNewPasswordError: '',
    deactivatedError: '',
    hrefForOktaLogin: '',
    loginMethod: '',
    setupNewPasswordSending: false,
    setupNewPasswordSuccess: false,
    resetPasswordSending: false,
    loginInProgress: false,
  },
  action
) => {
  switch (action.type) {
    case authorizationActions.actionTypes.LOGIN_TO_APP:
      return {
        ...state,
        loginInProgress: true,
      }
    case authorizationActions.actionTypes.LOGIN_FROM_FACTORY_FAILED:
    case authorizationActions.actionTypes.LOGIN_TO_APP_FAILED:
    case authorizationActions.actionTypes.SET_ERROR:
    case authorizationActions.actionTypes.GET_OKTA_IS_ENABLED_FLAG_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loginInProgress: false,
      }
    case authorizationActions.actionTypes.LOGIN_FROM_FACTORY_SUCCEEDED:
      return Object.assign({}, state, {
        loginMethod: LOGIN_METHODS.factory,
        error: '',
      })
    case authorizationActions.actionTypes.LOGIN_TO_APP_SUCCEEDED:
      localStorage.removeItem('loginMethod')

      return {
        ...state,
        loginMethod: LOGIN_METHODS.customer,
        error: '',
        loginInProgress: false,
      }
    case authorizationActions.actionTypes.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordSending: true,
      }
    case authorizationActions.actionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordError: action.payload.message,
        resetPasswordSending: false,
      }
    case authorizationActions.actionTypes.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        error: '',
        resetPasswordError: '',
        resetPasswordSending: false,
      }
    case authorizationActions.actionTypes.SETUP_NEW_PASSWORD:
      return {
        ...state,
        setupNewPasswordError: '',
        resetPasswordError: '',
        setupNewPasswordSending: true,
        setupNewPasswordSuccess: false,
      }
    case authorizationActions.actionTypes.SETUP_NEW_PASSWORD_FAILED:
      return {
        ...state,
        setupNewPasswordError: action.payload.message,
        setupNewPasswordSending: false,
        setupNewPasswordSuccess: false,
      }
    case authorizationActions.actionTypes.SETUP_NEW_PASSWORD_SUCCEEDED:
      return {
        ...state,
        error: '',
        setupNewPasswordError: '',
        resetPasswordError: '',
        setupNewPasswordSending: false,
        setupNewPasswordSuccess: true,
      }
    case authorizationActions.actionTypes.RESET_LOGIN_ERRORS:
      return {
        ...state,
        error: '',
        resetPasswordError: '',
      }
    case authorizationActions.actionTypes.GET_PROVIDERS_LIST_SUCCEEDED:
      return {
        ...state,
        hrefForOktaLogin: action.payload.list[0]?.loginUrl,
      }
    case authorizationActions.actionTypes.LOGOUT_SUCCEEDED:
      const error = action.payload.data?.error

      localStorage.removeItem('loginMethod')

      return {
        ...state,
        error,
      }
    case authorizationActions.actionTypes.GET_OKTA_IS_ENABLED_FLAG_SUCCEEDED:
      return {
        ...state,
        isOktaEnabled: action.payload.enabled,
      }
    default:
      return state
  }
}
