import React from 'react'
import { ListSubheader } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { map, uniqueId } from 'lodash'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import style from './style'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles/index'

const CustomSelectComponent = ({
  options,
  error,
  label,
  onChange,
  value,
  valueName,
  template,
  isRequired,
  isReadOnly,
  subheaderField,
  name,
  selectedOnlyBlack = false,
  smallPaddings = false,
  classes,
}) => {
  const textColor = selectedOnlyBlack ? classes.selectedTextColor : ''
  const anyValueSelectedAndSmallPaddings = smallPaddings && !!value
  const className = anyValueSelectedAndSmallPaddings
    ? classNames(classes.smallPaddings, textColor)
    : textColor

  return (
    <FormControl
      fullWidth
      variant="outlined"
      required={isRequired}
      error={error}
    >
      <InputLabel
        htmlFor="outlined-simple"
        className={
          anyValueSelectedAndSmallPaddings ? classes.labelForSmallPaddings : ''
        }
      >
        {label}
      </InputLabel>
      <Select
        disabled={isReadOnly}
        error={error}
        name={name}
        value={value}
        autoWidth
        onChange={onChange}
        label={label}
        IconComponent={ExpandMoreIcon}
        className={className}
        inputProps={{
          id: 'outlined-simple',
        }}
      >
        {map(options, (item) =>
          subheaderField && item[subheaderField] ? (
            <ListSubheader key={uniqueId('subhead_')} disableSticky>
              {template(item)}
            </ListSubheader>
          ) : (
            <MenuItem key={uniqueId('menuItem_')} value={item[valueName]}>
              {template(item)}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  )
}

export default withStyles(style)(CustomSelectComponent)
