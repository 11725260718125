const summaryActions = {}

summaryActions.actionsTypes = {
  CHANGE_ACTIVE_TAB: '{SUMMARY} Change Active Tab',

  GET_CYCLES_FOR_TABLE: '{SUMMARY} Get Cycles For Table',
  GET_CYCLES_FOR_TABLE_SUCCEEDED: '{SUMMARY} Get Cycles For Table Succeeded',
  GET_CYCLES_FOR_TABLE_FAILED: '{SUMMARY} Get Cycles For Table Failure',

  SET_RANGE_TYPE: '{SUMMARY} Set Range Type',

  CHANGE_DATE_FILTER: '{SUMMARY} Change Date Filter',

  LOAD_LOCATIONS: '{SUMMARY} Load Locations',
  LOAD_LOCATIONS_FAILED: '{SUMMARY} Load Locations Failure',

  APPLY_LOCATION_FILTER: '{SUMMARY} Apply Location Filter',
  APPLY_LOCATION_FILTER_FAILED: '{SUMMARY} Apply Location Filter Failed',

  PREPARE_LOCATION_FILTER: '{SUMMARY} Prepare Location Filter',

  PUT_LOCATION_FILTER: '{SUMMARY} Put Location Filter',
}

summaryActions.changeActiveTab = (deviceType) => {
  return {
    type: summaryActions.actionsTypes.CHANGE_ACTIVE_TAB,
    payload: {
      deviceType,
    },
  }
}

summaryActions.getCyclesForTable = (tableType, deviceSubType) => {
  return {
    type: summaryActions.actionsTypes.GET_CYCLES_FOR_TABLE,
    payload: {
      tableType,
      deviceSubType,
    },
  }
}

summaryActions.getCyclesForTableSuccess = (data) => {
  return {
    type: summaryActions.actionsTypes.GET_CYCLES_FOR_TABLE_SUCCEEDED,
    payload: {
      data,
    },
  }
}

summaryActions.getCyclesForTableFailed = (message) => {
  return {
    type: summaryActions.actionsTypes.GET_CYCLES_FOR_TABLE_FAILED,
    payload: {
      message,
    },
  }
}

summaryActions.setRangeType = (rangeType) => {
  return {
    type: summaryActions.actionsTypes.SET_RANGE_TYPE,
    payload: {
      rangeType,
    },
  }
}

summaryActions.changeDateFilter = ({ dateFilter, period }, label) => {
  return {
    type: summaryActions.actionsTypes.CHANGE_DATE_FILTER,
    payload: {
      dateFilter,
      period,
      label,
    },
  }
}

summaryActions.loadLocations = () => {
  return {
    type: summaryActions.actionsTypes.LOAD_LOCATIONS,
  }
}

summaryActions.loadLocationTreeFailed = (message) => {
  return {
    type: summaryActions.actionsTypes.LOAD_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

summaryActions.applyLocationFilter = (nodes) => {
  return {
    type: summaryActions.actionsTypes.APPLY_LOCATION_FILTER,
    payload: {
      nodes,
    },
  }
}

summaryActions.applyLocationFilterFailed = (message) => {
  return {
    type: summaryActions.actionsTypes.APPLY_LOCATION_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

summaryActions.prepareLocationFiler = (nodes, tree, userId) => {
  return {
    type: summaryActions.actionsTypes.PREPARE_LOCATION_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

summaryActions.putLocationFilter = ({ locationFilter, ids }) => {
  return {
    type: summaryActions.actionsTypes.PUT_LOCATION_FILTER,
    payload: {
      locationFilter,
      ids,
    },
  }
}

export default summaryActions
