import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  resetFilters: {
    width: 'fit-content',
    maxWidth: '110px',
    minWidth: '110px',
    backgroundColor: COLORS.WHITE_SMOKE_GRAY,
    fontSize: '12px',
    lineHeight: '14px',
    color: COLORS.BLUE,
    fontFamily: 'Cerebri Sans Pro Medium',
    padding: 0,
    height: '30px',
    boxShadow: 'none !important',
    marginBottom: '10px',
  },
  downloadGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  resetIcon: {
    fontSize: '14px',
    paddingBottom: '2px',
  },
  '@media (min-width: 1224px)': {
    resetFilters: {
      height: '36px',
      marginLeft: '15px',
      backgroundColor: COLORS.BLUE_GRAY,
    },
    closeIcon: {
      fontSize: '14px',
      paddingBottom: '2px',
      color: COLORS.BLACK,
    },
    link: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      fontSize: '14px',
      top: '40px',
      marginLeft: '10px',
      position: 'relative',
    },
    linkName: {
      marginLeft: '5px',
      fontSize: '12px',
    },
    getApp: {
      fontSize: '16px',
    },
  },
  '@media (max-width: 1224px)': {
    resetFiltersWhenDownloadLoading: {
      zIndex: -1,
    },
  },
})

export default styles
