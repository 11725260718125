import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Loader from '../../../../components/_shared/loader/Loader'
import DeleteContent from './DeleteContent'
import AssignedDevicesAndUsers from './AssignedDevicesAndUsers'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'

const DeleteModalWithInputComponent = withStyles(styles)((props) => {
  const {
    location,
    onSubmit,
    onClose,
    onModalClose,
    withInput,
    subLocationsCount,
    isOpen,
    subLocationsError,
    title,
    isLoadingSubLocations,
    isLoadingAssignedDevices,
    isLoadingAssignedUsers,
    locationId,
    assignedDevices,
    assignedUsers,
    classes,
  } = props
  const openDevicesAndUsersList =
    (assignedDevices && assignedDevices.length) ||
    (assignedUsers && assignedUsers.length)

  return (
    <Dialog open={isOpen} keepMounted onClose={onClose} maxWidth="xs">
      <DialogTitle className={classes.modalTitleContainer} disableTypography>
        <Typography
          variant="subtitle2"
          color="secondary"
          className={classes.modalTitle}
        >
          {title}
        </Typography>
        <CloseButton onCloseModal={onClose} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Loader
          isLoading={
            isLoadingSubLocations ||
            isLoadingAssignedDevices ||
            isLoadingAssignedUsers
          }
          loaderKey={locationId}
          inModal
        >
          {openDevicesAndUsersList ? (
            <AssignedDevicesAndUsers
              location={location}
              assignedDevices={assignedDevices}
              assignedUsers={assignedUsers}
              onModalClose={onModalClose}
            />
          ) : (
            <DeleteContent
              location={location}
              subLocationsCount={subLocationsCount}
              subLocationsError={subLocationsError}
              isOpen={isOpen}
              isLoadingSubLocations={isLoadingSubLocations}
              withInput={withInput}
              onSubmit={onSubmit}
            />
          )}
        </Loader>
      </DialogContent>
    </Dialog>
  )
})

const mapStateToProps = (state) => ({
  isLoadingSubLocations: state.locationReducer.isLoadingSubLocations,
  isLoadingAssignedDevices: state.locationReducer.isLoadingAssignedDevices,
  isLoadingAssignedUsers: state.locationReducer.isLoadingAssignedUsers,
  assignedDevices: state.locationReducer.assignedDevices,
  assignedUsers: state.locationReducer.assignedUsers,
})

export default connect(mapStateToProps, null)(DeleteModalWithInputComponent)
