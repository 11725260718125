const arcSummaryActions = {}

arcSummaryActions.actionsTypes = {
  CHANGE_DATE_FILTER: '{ARC_SUMMARY} Change Date Filter',

  APPLY_LOCATION_FILTER: '{ARC_SUMMARY} Apply Location Filter',
  APPLY_LOCATION_FILTER_FAILED: '{ARC_SUMMARY} Apply Location Filter Failed',

  PREPARE_LOCATION_FILTER: '{ARC_SUMMARY} Prepare Location Filter',
  PUT_LOCATION_FILTER: '{ARC_SUMMARY} Put Location Filter',

  LOAD_LOCATIONS: '{ARC_SUMMARY} Load Locations',
  LOAD_LOCATIONS_FAILED: '{ARC_SUMMARY} Load Locations Failure',

  GET_CYCLES_REPORT: '{ARC_SUMMARY} Get Cycles Report',
  GET_CYCLES_REPORT_SUCCEEDED: '{ARC_SUMMARY} Get Cycles Report Succeeded',
  GET_CYCLES_REPORT_FAILED: '{ARC_SUMMARY} Get Cycles Report Failure',

  GET_DISINFECTED_LOCATIONS: '{ARC_SUMMARY} Get Disinfected Locations',
  GET_DISINFECTED_LOCATIONS_SUCCEEDED:
    '{ARC_SUMMARY} Get Disinfected Locations Succeeded',
  GET_DISINFECTED_LOCATIONS_FAILED:
    '{ARC_SUMMARY} Get Disinfected Locations Failure',

  GET_ACTIVE_DEVICES: '{ARC_SUMMARY} Get Active Devices',
  GET_ACTIVE_DEVICES_SUCCEEDED: '{ARC_SUMMARY} Get Active Devices Succeeded',
  GET_ACTIVE_DEVICES_FAILED: '{ARC_SUMMARY} Get Active Devices Failure',

  DOWNLOAD_PDF_PACKAGE: '{ARC_SUMMARY} Download PDF Package',
  DOWNLOAD_PDF_PACKAGE_SUCCEEDED:
    '{ARC_SUMMARY} Download PDF Package Succeeded',
  DOWNLOAD_PDF_PACKAGE_FAILED: '{ARC_SUMMARY}Download PDF Package Failure',
}

arcSummaryActions.loadLocations = () => {
  return {
    type: arcSummaryActions.actionsTypes.LOAD_LOCATIONS,
  }
}

arcSummaryActions.loadLocationTreeFailed = (message) => {
  return {
    type: arcSummaryActions.actionsTypes.LOAD_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

arcSummaryActions.getCyclesReport = () => {
  return {
    type: arcSummaryActions.actionsTypes.GET_CYCLES_REPORT,
  }
}

arcSummaryActions.getCyclesReportSuccess = (data, timezone) => {
  return {
    type: arcSummaryActions.actionsTypes.GET_CYCLES_REPORT_SUCCEEDED,
    payload: {
      data,
      timezone,
    },
  }
}

arcSummaryActions.getCyclesReportFailed = (message) => {
  return {
    type: arcSummaryActions.actionsTypes.GET_CYCLES_REPORT_FAILED,
    payload: {
      message,
    },
  }
}

arcSummaryActions.changeDateFilter = ({ dateFilter, period }, label) => {
  return {
    type: arcSummaryActions.actionsTypes.CHANGE_DATE_FILTER,
    payload: {
      dateFilter,
      period,
      label,
    },
  }
}

arcSummaryActions.applyLocationFilter = function (nodes) {
  return {
    type: arcSummaryActions.actionsTypes.APPLY_LOCATION_FILTER,
    payload: {
      nodes,
    },
  }
}

arcSummaryActions.applyLocationFilterFailed = function (message) {
  return {
    type: arcSummaryActions.actionsTypes.APPLY_LOCATION_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

arcSummaryActions.prepareLocationFiler = function (nodes, tree, userId) {
  return {
    type: arcSummaryActions.actionsTypes.PREPARE_LOCATION_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

arcSummaryActions.getDisinfectedLocations = () => {
  return {
    type: arcSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS,
  }
}

arcSummaryActions.getDisinfectedLocationsSuccess = (data, timezone) => {
  return {
    type: arcSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_SUCCEEDED,
    payload: {
      data,
      timezone,
    },
  }
}

arcSummaryActions.getDisinfectedLocationsFailed = (message) => {
  return {
    type: arcSummaryActions.actionsTypes.GET_DISINFECTED_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

arcSummaryActions.getActiveDevices = () => {
  return {
    type: arcSummaryActions.actionsTypes.GET_ACTIVE_DEVICES,
  }
}

arcSummaryActions.getActiveDevicesSuccess = (data, timezone) => {
  return {
    type: arcSummaryActions.actionsTypes.GET_ACTIVE_DEVICES_SUCCEEDED,
    payload: {
      data,
      timezone,
    },
  }
}

arcSummaryActions.getActiveDevicesFailed = (message) => {
  return {
    type: arcSummaryActions.actionsTypes.GET_ACTIVE_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

arcSummaryActions.putLocationFilter = ({ locationFilter, ids }) => {
  return {
    type: arcSummaryActions.actionsTypes.PUT_LOCATION_FILTER,
    payload: {
      locationFilter,
      ids,
    },
  }
}

arcSummaryActions.downloadPDFPackage = () => {
  return {
    type: arcSummaryActions.actionsTypes.DOWNLOAD_PDF_PACKAGE,
  }
}

arcSummaryActions.downloadPDFPackageSuccess = () => {
  return {
    type: arcSummaryActions.actionsTypes.DOWNLOAD_PDF_PACKAGE_SUCCEEDED,
  }
}

arcSummaryActions.downloadPDFPackageFailed = (message) => {
  return {
    type: arcSummaryActions.actionsTypes.DOWNLOAD_PDF_PACKAGE_FAILED,
    payload: {
      message,
    },
  }
}

export default arcSummaryActions
