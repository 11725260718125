import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/styles'
import style from './style'
import { connect } from 'react-redux'
import { checkConnection } from '../../../../../../services/root/root-service'
import extDeviceDetailsActions from '../../services/extDeviceDetails-actions'
import { isEmpty } from 'lodash'
import BackToDeviceList from '../../../../_shared/components/backToDeviceList/BackToDeviceList'
import ChangeDeviceDetailsForm from '../locationsAndNickname/ChangeDeviceDetailsForm'
import DeleteDeviceButton from '../../../../_shared/components/deleteDeviceButton/DeleteDeviceButton'
import { DEVICE_TYPES } from '../../../../../../services/constants'

const ExtDeviceDetails = (props) => {
  const {
    deviceDetails,
    deleteDevice,
    loadDeviceDetails,
    serialNumber,
    history,
    classes,
  } = props

  useEffect(() => {
    if (
      isEmpty(deviceDetails) ||
      deviceDetails.id !== props.match.params.deviceId
    ) {
      loadDeviceDetails(props.match.params.deviceId)
    }
  }, [])

  const goBackToDevices = () => {
    history.push(`/devices/${DEVICE_TYPES.ext.path}`)
  }

  const onDeleteDevice = () => {
    deleteDevice(serialNumber, goBackToDevices)
  }

  return (
    <div className={classes.wrapper}>
      <BackToDeviceList
        serialNumber={deviceDetails.serialNumber}
        goBackToDevices={goBackToDevices}
        showErrorMessage={false}
      />

      <ChangeDeviceDetailsForm
        serialNumber={deviceDetails.serialNumber}
        initialNickname={deviceDetails.nickname || ''}
        isEditView
      />

      <DeleteDeviceButton
        onSubmit={onDeleteDevice}
        nickName={deviceDetails.deviceNickname || serialNumber}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { extDeviceDetailsReducer },
  } = state

  return {
    deviceDetails: extDeviceDetailsReducer.deviceDetails,
    serialNumber: extDeviceDetailsReducer.serialNumber,
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadDeviceDetails: (id) =>
    dispatch(
      checkConnection(() => extDeviceDetailsActions.loadDeviceDetails(id))
    ),
  deleteDevice: (serialNumber, handleClose) =>
    dispatch(
      checkConnection(() =>
        extDeviceDetailsActions.deleteDevice(serialNumber, handleClose)
      )
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ExtDeviceDetails))
