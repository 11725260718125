import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import styles from './style'

const FieldValue = (props) => {
  const { value, label, classes } = props

  return (
    <Box display="flex">
      <Typography variant="subtitle1" gutterBottom>
        {label}:
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.fieldValue}
        gutterBottom
      >
        {value}
      </Typography>
    </Box>
  )
}

export default withStyles(styles)(FieldValue)
