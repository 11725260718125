import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import CloseButton from '../closeButton/CloseButton'

const ChipsFilterModal = withStyles(styles)((props) => {
  const {
    classes,
    open,
    statusFilter,
    constants,
    onSubmitLabel,
    filterName,
    onClose,
    onChange,
    onApply,
  } = props
  const getChipContent = () => {
    let content = []

    for (let key in statusFilter) {
      content.push(
        <Chip
          className={classes.filtersChipsModal}
          key={key}
          label={constants[key]}
          color={statusFilter[key] ? 'primary' : 'default'}
          onClick={() => onChange(key)}
          variant="outlined"
        />
      )
    }

    return content
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <Typography variant="subtitle2">{filterName}</Typography>
          <CloseButton onCloseModal={onClose} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.filtersContainer}>{getChipContent()}</div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onApply} color="primary">
            {onSubmitLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default ChipsFilterModal
