import React from 'react'
import { map } from 'lodash'
import InfoCard from '../../ARC/components/InfoCard'
import { withStyles } from '@material-ui/core'
import style from '../../ARC/components/style'
import Box from '@material-ui/core/Box'
import classNames from 'classnames'

import {
  convertToHours,
  convertToMinutes,
} from '../../ARC/service/arcSummary-service'

const CardsContainer = (props) => {
  const { isMobile, classes, cardsConfig } = props

  return (
    <Box className={classNames('pdf-cards', classes.cardsContainer)}>
      {map(cardsConfig, (item) => {
        const inMinutes =
          item.convertToMinutes &&
          convertToMinutes(props[item.prop], isMobile, classes)
        const inHours = item.convertToHours && convertToHours(props[item.prop])
        const infoCardValue =
          inMinutes || (inHours && inHours.value) || props[item.prop]

        return (
          <InfoCard
            key={item.label}
            number={infoCardValue}
            label={item.label(inHours && inHours.inMinutes)}
          />
        )
      })}
    </Box>
  )
}

export default withStyles(style)(CardsContainer)
