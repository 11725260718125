import {
  concat,
  map,
  isNull,
  isUndefined,
  every,
  forEach,
  filter,
  cloneDeep,
  remove,
  indexOf,
} from 'lodash'
import { deeps, PREF, TREE_LEVELS } from '../../../../services/constants'
import { getStorageDataById } from '../../../../services/defaultStore-sevice'
import {
  setupIndeterminateCheckbox,
  setupIndeterminateCheckboxWithForSingleSelection,
} from './checkNode-service'

const changeUserPrefByProperty = (property, userPref) => {
  const idsArray = []

  const traverse = function (current) {
    //process current node here
    //visit children of current
    forEach(current, (item) => {
      // default values
      let userPrefIndex = indexOf(userPref, item.id)

      if (userPrefIndex !== -1) {
        idsArray.push([item.id])
      }

      if (item.isParent) {
        traverse(item.children)
      }
    })
  }

  traverse(property.children)

  return remove(userPref, (item) => {
    return indexOf(idsArray, item) !== -1
  })
}
/*
 * select or unselect all child nodes
 * current - array of child nodes
 * bool - checked parent checkbox
 */
const traverseChecked = function (current, bool) {
  map(current, (item, key) => {
    item.checked = bool
    item.indeterminate = false

    if (item.isParent) {
      traverseChecked(item.children, bool)
    }
  })
}

const prepareLocationTree = (params, userPrefCurrent, isAllSelected) => {
  const { nodes, finalNode } = params
  const userPref = nodes.isServerPref
    ? nodes[PREF.SERVER_PREF]
    : userPrefCurrent

  const nodeList = concat([], nodes.children)
  const propertyId = nodes.id

  var traverse = function (current) {
    //process current node here
    //visit children of current
    map(current, (item) => {
      item.isParent = item.children && !!item.children.length
      // item.deep = deeps[deep];
      item.propertyId = propertyId

      // default values
      let isSelectedInUserPref = !!(
        userPref &&
        userPref.length &&
        userPref.indexOf(item.id) !== -1
      )

      item.checked = nodes.indeterminate
        ? !!(
            nodes.checked ||
            item.checked ||
            isSelectedInUserPref ||
            isAllSelected
          )
        : nodes.checked
      item.expanded = item.checked

      if (finalNode && item.locationType === finalNode) {
        item.isParent = false
        item.children = []
      } else {
        if (item.isParent) {
          if (item.checked) {
            traverseChecked(item.children, true)
          }

          traverse(item.children)
        }
      }
    })
  }

  traverse(nodeList, 2)

  if (!nodes.isSingleSelectionTree) {
    setupIndeterminateCheckbox(nodeList)
  } else {
    setupIndeterminateCheckboxWithForSingleSelection(nodeList)
  }

  return nodeList
}

const prepareProperties = (params, userPrefCurrent, isAllSelected) => {
  const { nodes, id } = params
  const userPref = nodes.isServerPref
    ? nodes[PREF.SERVER_PREF].selectedBuildings
    : userPrefCurrent
  const isIndeterminate = nodes.isServerPref
    ? nodes[PREF.SERVER_PREF].indeterminateProperties
    : getStorageDataById(PREF.INDETERMINATE, id)

  const properties = concat([], nodes.children)

  map(properties, (item) => {
    item.isParent = item.children && !!item.children.length
    // item.deep = TREE_LEVELS.PROPERTY;
    item.isBuildingsPrepared = false

    // default values
    let isSelectedInUserPref = !!(
      userPref &&
      userPref.length &&
      userPref.indexOf(item.id) !== -1
    )
    item.checked = !!(item.checked || isSelectedInUserPref || isAllSelected)
    item.indeterminate = item.checked
      ? false
      : !!(
          isIndeterminate &&
          isIndeterminate.length &&
          isIndeterminate.indexOf(item.id) !== -1
        )
    item.expanded = false
  })

  return properties
}

const changeExpanded = (value, locations) => {
  const traverse = function (current) {
    map(current, (item, key) => {
      if (item.id === value) {
        item.expanded = !item.expanded
      }

      if (item.isParent) {
        traverse(item.children)
      }
    })
  }

  traverse(locations)
}

const getAllSelectedByUserPref = (userId, locationsTree) => {
  const userPref = locationsTree.isServerPref
    ? locationsTree[PREF.SERVER_PREF].selectedBuildings
    : getStorageDataById(PREF.LOCATIONS, userId)

  // if all locations was selected or if user not setup any settings yet
  if (isNull(userPref) || isUndefined(userPref)) {
    return true
  }

  return every(locationsTree.children, (item) => {
    return !!(userPref.length && userPref.indexOf(item.id) !== -1)
  })
}

const getAllSelectedState = (nodes) => {
  return nodes.every((item) => item.checked)
}

const getTreeNodesWithSelectableLevel = (nodes, selectableLevel = 'zone') => {
  // generateDeeps(nodes.children, 1);

  const isNodeWithCurrentLevel = (locationNode) => {
    if (locationNode.locationType === selectableLevel) {
      locationNode.children = []

      return true
    }

    if (locationNode.children.length > 0) {
      locationNode.children = filter(
        locationNode.children,
        isNodeWithCurrentLevel
      )

      return locationNode.children.length > 0
    }

    return false
  }

  nodes.children = filter(nodes.children, isNodeWithCurrentLevel)

  return nodes
}

const buildPropertyTree = (state, index, userId) => {
  const { nodes, isAllSelected, userPrefCurrent, finalNode } = state
  const expandedProperty = cloneDeep(nodes[index])

  expandedProperty.expanded = !expandedProperty.expanded

  if (!expandedProperty.isBuildingsPrepared) {
    const newPropertyChildren = prepareLocationTree(
      {
        nodes: expandedProperty,
        id: userId,
        finalNode,
      },
      userPrefCurrent,
      isAllSelected
    )

    expandedProperty.children = newPropertyChildren
    expandedProperty.isBuildingsPrepared = true
  }

  return expandedProperty
}

export {
  prepareLocationTree,
  getAllSelectedByUserPref,
  getAllSelectedState,
  changeExpanded,
  getTreeNodesWithSelectableLevel,
  prepareProperties,
  buildPropertyTree,
  changeUserPrefByProperty,
}
