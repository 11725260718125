import createSagaMiddleware from 'redux-saga'
import { createStore, compose, applyMiddleware } from 'redux'
import reducer from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

let middlewares = applyMiddleware(sagaMiddleware)

export default function configureStore() {
  const storage = createStore(reducer, composeEnhancers(middlewares))

  sagaMiddleware.run(rootSaga)

  return storage
}
