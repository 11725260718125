const styles = (theme) => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    marginBottom: '19px',
    width: '190px',
  },
  infoBlock: {
    fontSize: '16px',
    lineHeight: '19px',
  },
  modalTitle: {
    fontSize: '14px',
    lineHeight: '17px',
    paddingTop: '20px',
  },
})

export default styles
