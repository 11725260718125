import React from 'react'
import { withStyles } from '@material-ui/core'
import style from './style'
import { withMediaQuery } from '../../../services/root/root-service'

const TabPanel = ({
  children,
  value,
  index,
  stylesForTabPanel,
  fixedHeader = true,
  classes,
}) => {
  return (
    <div
      className={
        fixedHeader ? classes.tabContentFixedHeader : classes.tabContent
      }
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div className={stylesForTabPanel}>{children}</div>}
    </div>
  )
}

export default withMediaQuery(withStyles(style)(TabPanel))
