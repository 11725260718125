import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import authorizationActions from '../../../LogIn/services/authorization-actions'
import { EMAIL_PREF_INITIAL_STATE } from '../../../Users/services/users-constants'
import { getEmailPrefInfo } from '../../../Users/services/users-service'
import EmailPreferences from './EmailPreferences'
import {
  ACCOUNT_INFO_TITLE_LABEL,
  OTHER_LABELS,
  CHANGES_SAVED_LABEL,
  initialStateOrganization,
  initialStateUser,
  ORGANIZATION_INFO_KEYS,
  ORGANIZATION_INFO_LABELS,
  USER_INFO_KEYS,
  USER_INFO_LABELS,
  ACCOUNT_INFO_DOCUMENT_TITLE,
} from '../../services/account-constants'
import actions from '../../services/account-actions'
import PhoneWithExtensionState from '../../../../components/_shared/phoneWithExtesion/PhoneWithExtensionState'
import {
  getGreeting,
  prepareBodyForUpdate,
  validateAccountForm,
} from '../../services/account-service'
import Loading from '../../../../components/_shared/loader/Loader'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import ApiKeyButton from './ApiKeyButton'
import styles from './style'
import TimezonePrompt from '../timezonePrompt/TimezonePrompt'
import AccountAddress from './AccountAddress'
import {
  autoHideDuration,
  MAX_NAME_LENGTH,
  USER_ROLES_LABELS,
} from '../../../../services/constants'
import ViewAssignedPropertiesModal from '../viewAssignedPropertiesModal/ViewAssignedPropertiesModal'
import NetworkError from '../../../../components/_shared/errors/NetworkError'
import ErrorHasOccurred from '../../../../components/_shared/errors/ErrorHasOccurred'

const AccountInfoComponent = (props) => {
  const {
    getOrganizationInfo,
    getUserInfo,
    getProperties,
    organizationId,
    userId,
    allowedProperties,
    isOperator,
    resetUpdateErrors,
    resetInitialLoading,
    organizationInfo,
    updateAccountInfoError,
    updateAccountInfo,
    stopLoading,
    userInfo,
    getOrganizationInfoError,
    getUserInfoError,
    isLoading,
    isMobile,
    timezones,
    isAccountOwner,
    abbreviation,
    propertiesNames,
    networkError,
    isInitialLoading,
    classes,
    logout,
    timezoneName,
    getTimezonesByZip,
    checkOrganizationInfo,
    history,
    idpName,
    isBannerPresent,
  } = props

  const [organization, setOrganization] = useState(initialStateOrganization)
  const [emailPref, setEmailPref] = useState(EMAIL_PREF_INITIAL_STATE)
  const [user, setUser] = useState(initialStateUser)
  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [openTimezone, setOpenTimezone] = useState(false)

  const wrapper = classNames(
    classes.wrapper,
    isBannerPresent ? classes.wrapperBannerOffset : ''
  )

  useEffect(() => {
    document.title = ACCOUNT_INFO_DOCUMENT_TITLE

    getOrganizationInfo(organizationId)
    getUserInfo(userId)

    if (!allowedProperties.length && isOperator) {
      getProperties()
    }

    return () => {
      resetUpdateErrors()
      resetInitialLoading()
    }
  }, [])

  useEffect(() => {
    setOrganization(organizationInfo)
  }, [organizationInfo])

  useEffect(() => {
    if (updateAccountInfoError !== '') {
      const isOpen = !updateAccountInfoError

      setOpen(isOpen)

      if (isOpen) {
        stopLoading()

        resetUpdateErrors()
      }
    }
  }, [updateAccountInfoError])

  useEffect(() => {
    const pref = getEmailPrefInfo(userInfo)

    setUser(userInfo)
    setEmailPref(pref)
  }, [userInfo])

  const onChangeOrganization = (event) => {
    setOrganization({
      ...organization,
      [event.target.name]: event.target.value,
    })
  }

  const onChangeZip = (event) => {
    let value = event.target.value

    if (/^\d+$/.test(value) || value === '') {
      setOrganization({
        ...organization,
        [event.target.name]: value,
      })
    }
  }

  const onChangeUser = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const changePhoneUser = (key, value) => {
    setUser({
      ...user,
      [key]: value,
    })
  }

  const onChangeTimezoneSettings = (value) => {
    setUser({
      ...user,
      useLocalTimezone: value,
    })
  }

  const changePhoneOrganisation = (key, value) => {
    setOrganization({
      ...organization,
      [key]: value,
    })
  }

  const changeEmailPref = (pref) => {
    setEmailPref(pref)
  }

  const openTimeZoneModal = (timezones) => {
    if (isAccountOwner) {
      if (timezones.length === 1) {
        onTimeZoneSubmit(timezones[0])
      } else if (timezones.length) {
        stopLoading()

        setOpenTimezone(true)
      }
    } else {
      const logoutFunc =
        userInfo.email !== user.email
          ? function () {
              logout({ email: user.email })
            }
          : false

      updateAccountInfo(
        prepareBodyForUpdate(
          organization,
          user,
          { timezoneName, abbreviation },
          emailPref
        ),
        logoutFunc
      )
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const errorsObj = validateAccountForm(organization, user)

    if (isEmpty(errorsObj)) {
      if (
        organizationInfo.name !== organization.name ||
        organizationInfo.secondaryPhone !== organization.secondaryPhone
      ) {
        checkOrganizationInfo(
          organizationId,
          organization,
          userId,
          user,
          openTimeZoneModal
        )
      } else {
        getTimezonesByZip(organization.zip, openTimeZoneModal)
      }
    }

    setErrors(errorsObj)
  }

  const onTimeZoneSubmit = (timezone) => {
    const logoutFunc =
      userInfo.email !== user.email
        ? function () {
            logout({ email: user.email })
          }
        : false

    const body = prepareBodyForUpdate(
      organization,
      {
        ...user,
        businessTitle: user.businessTitle?.trim() || '',
      },
      timezone,
      emailPref
    )

    updateAccountInfo(body, logoutFunc)
    closeTimezonePrompt()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeAccountOwner = () => {
    history.push('/account/changeOwner')
  }

  const closeTimezonePrompt = () => {
    setOpenTimezone(false)
  }

  const isError = getOrganizationInfoError || getUserInfoError

  const propsNumber =
    (allowedProperties && allowedProperties.length) ||
    (propertiesNames && propertiesNames.length)

  return (
    <Loading isLoading={isInitialLoading || isLoading}>
      <div className={wrapper}>
        <form className={classes.container} onSubmit={handleSubmit} noValidate>
          {!networkError && !isError && (
            <>
              <div className={classes.firstTriangle} />
              <div className={classes.secondTriangle} />
              <div className={classes.thirdTriangle} />
              <div className={classes.titles}>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={classNames(classes.title, classes.welcome)}
                >
                  {!isMobile && getGreeting(userInfo)}
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  {isMobile && ACCOUNT_INFO_TITLE_LABEL}
                </Typography>
                <Typography
                  variant={isMobile ? 'subtitle1' : null}
                  color="textSecondary"
                  className={classes.operatorTitle}
                >
                  {USER_ROLES_LABELS[user.role]}
                  {` - ${isOperator ? propsNumber : 'All'} properties`}
                </Typography>
              </div>

              {updateAccountInfoError && (
                <Typography variant="subtitle1" color="error">
                  {updateAccountInfoError}
                </Typography>
              )}
            </>
          )}
          {!networkError && !isError ? (
            <>
              {isOperator && <ViewAssignedPropertiesModal />}
              <Box display={{ md: 'flex' }} justifyContent="space-between">
                <div className={classes.contentWrapper}>
                  <TextField
                    name="name"
                    variant="outlined"
                    label={ORGANIZATION_INFO_LABELS.name}
                    value={organization.name || initialStateOrganization.name}
                    onChange={onChangeOrganization}
                    error={errors[ORGANIZATION_INFO_KEYS.name]}
                    disabled={!isAccountOwner}
                    fullWidth
                    required
                  />
                  <TextField
                    name="firstName"
                    variant="outlined"
                    label={USER_INFO_LABELS.firstName}
                    value={user.firstName || initialStateUser.firstName}
                    onChange={onChangeUser}
                    error={errors[USER_INFO_KEYS.firstName]}
                    inputProps={{ maxLength: MAX_NAME_LENGTH }}
                    fullWidth
                    required
                  />
                  <TextField
                    name="lastName"
                    variant="outlined"
                    label={USER_INFO_LABELS.lastName}
                    value={user.lastName || initialStateUser.lastName}
                    onChange={onChangeUser}
                    error={errors[USER_INFO_KEYS.lastName]}
                    inputProps={{ maxLength: MAX_NAME_LENGTH }}
                    fullWidth
                    required
                  />
                  <TextField
                    name="email"
                    variant="outlined"
                    label={USER_INFO_LABELS.email}
                    value={user.email || initialStateUser.email}
                    onChange={onChangeUser}
                    fullWidth
                    required
                  />
                  <TextField
                    name="businessTitle"
                    variant="outlined"
                    label={USER_INFO_LABELS.businessTitle}
                    value={user.businessTitle || initialStateUser.businessTitle}
                    onChange={onChangeUser}
                    error={errors[USER_INFO_KEYS.businessTitle]}
                    inputProps={{ maxLength: MAX_NAME_LENGTH }}
                    fullWidth
                  />
                  <div className={classes.apiKeyWideScreen}>
                    {isAccountOwner && <ApiKeyButton />}
                    <EmailPreferences
                      onChange={changeEmailPref}
                      state={emailPref}
                    />
                  </div>
                </div>
                <div className={classes.contentWrapper}>
                  <PhoneWithExtensionState
                    onChange={changePhoneUser}
                    phoneName="phoneNumber"
                    phoneNumber={
                      user.phoneNumber || initialStateUser.phoneNumber
                    }
                    phoneExtension={
                      user.phoneExtension || initialStateUser.phoneExtension
                    }
                    phoneLabel={USER_INFO_LABELS.phoneNumber}
                  />
                  <PhoneWithExtensionState
                    onChange={changePhoneOrganisation}
                    isReadOnly={!isAccountOwner}
                    phoneName="secondaryPhone"
                    phoneNumber={
                      organization.secondaryPhone ||
                      initialStateOrganization.secondaryPhone
                    }
                    phoneExtension={
                      organization.phoneExtension ||
                      initialStateOrganization.phoneExtension
                    }
                    phoneLabel={ORGANIZATION_INFO_LABELS.secondaryPhone}
                  />
                  <AccountAddress
                    onChangeOrganization={onChangeOrganization}
                    onChangeTimezoneSettings={onChangeTimezoneSettings}
                    timezoneSettings={user.useLocalTimezone}
                    onChangeZip={onChangeZip}
                    organization={organization}
                    timezones={timezones}
                    errors={errors}
                    abbreviation={abbreviation}
                    isReadOnly={!isAccountOwner}
                  />
                  <TextField
                    name="businessTitle"
                    variant="outlined"
                    label={OTHER_LABELS.idpData}
                    value={idpName}
                    fullWidth
                    disabled
                  />
                </div>
              </Box>
              <div className={classes.apiKeyNarrowScreen}>
                {isAccountOwner && <ApiKeyButton />}
                <EmailPreferences
                  onChange={changeEmailPref}
                  state={emailPref}
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                className={classes.createAccountButton}
                type="submit"
              >
                {OTHER_LABELS.confirm}
              </Button>
              {isAccountOwner && (
                <Button
                  variant="outlined"
                  className={classes.changeOwnerButton}
                  onClick={changeAccountOwner}
                >
                  {OTHER_LABELS.changeOwner}
                </Button>
              )}
              <Snackbar
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
                message={CHANGES_SAVED_LABEL}
              />
              <TimezonePrompt
                isOpen={openTimezone}
                onClose={closeTimezonePrompt}
                onSubmit={onTimeZoneSubmit}
              />
            </>
          ) : !networkError ? (
            <ErrorHasOccurred hideLogo />
          ) : (
            <NetworkError />
          )}
        </form>
      </div>
    </Loading>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOrganizationInfo: (id) =>
      dispatch(checkConnection(() => actions.getOrganizationInfo(id))),
    getUserInfo: (id) =>
      dispatch(checkConnection(() => actions.getUserInfo(id))),
    checkOrganizationInfo: (id, body, userId, user, onSuccess) =>
      dispatch(
        checkConnection(() =>
          actions.checkOrganizationInfo(id, body, userId, user, onSuccess)
        )
      ),
    updateAccountInfo: (body, logOut) =>
      dispatch(checkConnection(() => actions.updateAccountInfo(body, logOut))),
    resetUpdateErrors: () => dispatch(actions.resetUpdateErrors()),
    getTimezonesByZip: (zip, onSuccess) =>
      dispatch(
        checkConnection(() => actions.getTimezonesByZip(zip, onSuccess))
      ),
    getProperties: () => dispatch(checkConnection(actions.getProperties)),
    logout: (data) =>
      dispatch(checkConnection(() => authorizationActions.logout(data))),
    stopLoading: () => dispatch(actions.stopLoading()),
    resetInitialLoading: () => dispatch(actions.resetInitialLoading()),
  }
}

const mapStateToProps = (state) => {
  return {
    organizationInfo: state.accountReducer.organizationInfo,
    userInfo: state.accountReducer.userInfo,
    allowedProperties: state.rootReducer.allowedProperties,
    isOperator: state.rootReducer.isOperatorView,
    organizationId: state.rootReducer.organization.id,
    userId: state.rootReducer.userId,
    updateAccountInfoError: state.accountReducer.updateAccountInfoError,
    getOrganizationInfoError: state.accountReducer.getOrganizationInfoError,
    getUserInfoError: state.accountReducer.getUserInfoError,
    isLoading: state.accountReducer.isLoading,
    propertiesNames: state.accountReducer.propertiesNames,
    timezones: state.accountReducer.timezones,
    isAccountOwner: state.rootReducer.isAccountOwner,
    timezoneName: state.rootReducer.timezoneName,
    abbreviation: state.rootReducer.abbreviation,
    networkError: state.rootReducer.networkError,
    isInitialLoading: state.accountReducer.isInitialLoading,
    idpName: state.accountReducer.idpName,
    isBannerPresent: state.rootReducer.isBannerPresent,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMediaQuery(AccountInfoComponent)))
