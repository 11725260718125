const MANAGE_USERS_LABELS = {
  usersListTitle: 'Users',
  adminRole: 'Admin',
  operatorRole: 'Operators',
  search: 'Search Users',
  addUser: 'Add User',
  editUser: 'Edit User',
  addUserAction: '+ Add New User',
  updateUserAction: 'Update User',
  deleteUser: 'Delete User',
}

const UPDATE_USER_LABELS = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PHONE: 'Phone',
  EXT: 'Ext',
  BUSINESS_TITLE: 'Business Title',
  ROLE: 'Role',
  LOCATION: 'Location(s)',
  ORGANIZATION: 'Organization Name',
}

const USER_INFO_KEYS = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phoneNumber: 'phoneNumber',
  phoneExtension: 'phoneExtension',
  organization: 'organization',
}

const AUTHORITIES = {
  adminUser: 'ADMIN_USER',
  accountOwner: 'ACCOUNT_OWNER',
  operatorUser: 'OPERATOR_USER',
  billingInformation: 'BILLING_INFORMATION',
}

const GROUP_LABELS = {
  GLOBAL_ADMIN: 'Global Admin',
  ADMIN: 'Admins',
  OPERATORS: 'Operators',
  ACCOUNTS: 'Account Owner',
}

const LOCATION_LABELS = {
  action: 'Apply',
  modalTitle: 'Select Location(s)',
  fieldLabel: 'Assign to Property',
  all: 'Locations: All',
  allPropertiesLabels: 'All Properties',
}

const ADD_PROPERTY_LABELS = {
  addProperty: 'Add Property ->',
  modalTitle: 'Property',
  placeholder: 'Property Name',
  actionLabel: '+ Add Property',
}

const DELETE_USER_LABELS = {
  deleteUser: 'Delete User',
  confirmText: (name) =>
    `Are you sure you want to remove ${name} from your organization?`,
  delete: 'Confirm',
  close: 'Close',
}

const ALLOW_BILLING_CHECKBOX = 'Allow Access to Billing Information'

const DEVICE_MAX_PROPERTY_LENGTH = 30

const USERS_DOCUMENT_TITLE = 'Users | R-Zero'

const EMAIL_PREF_LABELS = {
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  outAll: 'Opt out all emails',
  title: 'Email Preferences',
  description:
    'Summary reports sent directly to your inbox with formatted pdfs',
  outAllDescription:
    'If you would prefer to check your dashboard instead of getting reports directly in your inbox',
}

const EMAIL_PREF_INITIAL_STATE = {
  dailyReport: false,
  weeklyReport: false,
  monthlyReport: false,
  outAll: true,
}

const EMAIL_PREF_NAMES_MAP = {
  dailyReport: 'dailyReport',
  weeklyReport: 'weeklyReport',
  monthlyReport: 'monthlyReport',
  outAll: 'outAll',
}

const EMAIL_PREF_NAMES = [
  EMAIL_PREF_NAMES_MAP.dailyReport,
  EMAIL_PREF_NAMES_MAP.weeklyReport,
  EMAIL_PREF_NAMES_MAP.monthlyReport,
]

const EMPTY_USERS_PAGE = {
  description: 'No users match your search.',
  clearSearch: 'Clear search',
}

const USER_LABEL = 'User'

export {
  UPDATE_USER_LABELS,
  MANAGE_USERS_LABELS,
  ALLOW_BILLING_CHECKBOX,
  USER_INFO_KEYS,
  AUTHORITIES,
  LOCATION_LABELS,
  GROUP_LABELS,
  ADD_PROPERTY_LABELS,
  USERS_DOCUMENT_TITLE,
  DEVICE_MAX_PROPERTY_LENGTH,
  DELETE_USER_LABELS,
  EMAIL_PREF_LABELS,
  EMAIL_PREF_INITIAL_STATE,
  EMAIL_PREF_NAMES,
  EMAIL_PREF_NAMES_MAP,
  EMPTY_USERS_PAGE,
  USER_LABEL,
}
