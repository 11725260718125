import React, { useEffect, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import { convertTodayToMomentWithTimezone } from '../../../../services/dateFormat-service'
import { isEmpty } from 'lodash'
import styles from './style'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import CustomCalendar from '../../calendar/СustomCalendar'
import moment from 'moment'
import { DATE_FILTER } from '../../../../services/constants'
import CloseButton from '../closeButton/CloseButton'

const DateFilterModal = withStyles(styles)((props) => {
  const {
    classes,
    open,
    onClose,
    dateFilter,
    onChange,
    onSubmit,
    period,
    timezone,
  } = props

  const today = convertTodayToMomentWithTimezone(timezone)
  const todayDay = convertTodayToMomentWithTimezone(timezone).date()
  const week = convertTodayToMomentWithTimezone(timezone).subtract(7, 'days')
  const prevMonthFirstDay = convertTodayToMomentWithTimezone(timezone)
    .subtract(1, 'months')
    .date(todayDay)

  const [startDate, setStartDate] = useState(
    isEmpty(period) ? week : period.startDate
  )
  const [endDate, setEndDate] = useState(
    isEmpty(period) ? today : period.endDate
  )

  useEffect(() => {
    if (dateFilter.week) {
      setStartDate(week)
      setEndDate(today)
    }
  }, [dateFilter.week])

  useEffect(() => {
    if (open) {
      setStartDate(isEmpty(period) ? week : period.startDate)
      setEndDate(isEmpty(period) ? today : period.endDate)
    }
  }, [open])

  const changeSelectedData = (dates) => {
    const [start, end] = dates
    const startMoment = moment(start)
    const endMoment = moment(end)

    setStartDate(startMoment >= endMoment ? end : start)
    setEndDate(startMoment >= endMoment ? null : end)

    // if one selected day
    if (!end) {
      let isToday = moment(start).isSame(today, 'day')

      if (isToday) {
        onChange('today', today, today)
      } else {
        onChange('custom', start, end)
      }
    } else {
      // if period selected
      if (moment(end).isSame(today, 'day')) {
        // if last week
        if (moment(start).isSame(week, 'day')) {
          onChange('week', week, today)
        } else if (moment(start).isSame(prevMonthFirstDay, 'day')) {
          // if last month
          onChange('month', startMoment, today)
        } else {
          onChange('custom', start, end)
        }
      } else if (start >= end) {
        onChange('custom', end, null)
      } else {
        onChange('custom', start, end)
      }
    }
  }

  const onApply = () => {
    onSubmit(startDate, endDate)
  }

  const changeSelectedFilter = (key) => {
    let start
    let end

    switch (key) {
      case 'today':
        start = today
        end = null
        break
      case 'month':
        start = prevMonthFirstDay
        end = today
        break
      default:
        start = week
        end = today
        break
    }

    setStartDate(start)
    setEndDate(end)

    onChange(key, start, end)
  }

  const getChipContent = () => {
    let content = []

    for (let key in dateFilter) {
      content.push(
        <Chip
          className={classes.filtersChipsModal}
          key={key}
          color={dateFilter[key] ? 'primary' : 'default'}
          label={DATE_FILTER[key]}
          onClick={() => changeSelectedFilter(key)}
          variant="outlined"
        />
      )
    }

    return content
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <Typography variant="subtitle2">Date Range Filter</Typography>
          <CloseButton onCloseModal={onClose} />
        </DialogTitle>
        <DialogContent className={classes.pickerContent}>
          <div className={classes.filtersContainer}>{getChipContent()}</div>
          <CustomCalendar
            startDate={startDate}
            timezone={timezone}
            endDate={endDate}
            onChange={changeSelectedData}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onApply} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default DateFilterModal
