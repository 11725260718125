import {
  compact,
  isEmpty,
  isNull,
  isUndefined,
  join,
  map,
  sortBy,
  uniq,
} from 'lodash'
import { DEVICE_REMOVED_QUERY, PREF } from '../../../../../services/constants'
import filterQuery from '../../../../../services/filterQuery-service'
import { getDeviceSubTypeQuery } from '../../../../../services/root/root-service'

const getProperties = (devices = []) => {
  const locations = map(
    devices,
    (device) =>
      !isNull(device.lastEventLocationPath) &&
      device.lastEventLocationPath[device.lastEventLocationPath.length - 1]
  )

  const properties = uniq(compact(locations))

  return sortBy(properties)
}

const prepareQueryForRequest = (locations, activeTab, searchField) => {
  const typeQuery = getDeviceSubTypeQuery(activeTab, true)
  const arrQuery = [typeQuery]

  if (searchField) {
    arrQuery.push(
      filterQuery.getQueryString(
        `*${searchField}*`,
        filterQuery.templates.default,
        'deviceSerialNumber',
        'contains'
      )
    )
  }

  if (locations) {
    const allowedLocationsQuery = filterQuery.getArrayString(
      isEmpty(locations) ? ['-1'] : locations,
      filterQuery.templates.array,
      'deviceAllowedLocationIds',
      'unnestIn',
      true
    )

    arrQuery.push(`(((${allowedLocationsQuery}) and ${DEVICE_REMOVED_QUERY}))`)
  } else {
    arrQuery.push(DEVICE_REMOVED_QUERY)
  }

  return { query: filterQuery.createQueryStringFromArray(arrQuery, 'and') }
}

const filterLocationsForDevice = (locations, selectedProperties) => {
  let filteredLocations = { ...locations }

  if (
    selectedProperties ||
    isNull(selectedProperties) ||
    isUndefined(selectedProperties)
  ) {
    filteredLocations[PREF.SERVER_PREF] = selectedProperties
    filteredLocations.isServerPref = true
  }

  return filteredLocations
}

const getFullRevertPathForDevice = (device) =>
  join([...(device.lastEventLocationPath || [])].reverse(), ' > ')

const checkDeviceByProperty = (device, property) =>
  !isNull(device.lastEventLocationPath) &&
  device.lastEventLocationPath[device.lastEventLocationPath.length - 1] ===
    property

export {
  getProperties,
  prepareQueryForRequest,
  filterLocationsForDevice,
  getFullRevertPathForDevice,
  checkDeviceByProperty,
}
