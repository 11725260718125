const usersActions = {}

usersActions.actionTypes = {
  LOAD_USERS_LIST: '{USERS} Load users list',
  LOAD_USERS_LIST_SUCCEEDED: '{USERS} Load users list Succeeded',
  LOAD_USERS_LIST_FAILED: '{USERS} Load users list Failed',
  FILTER_USERS_LIST: '{USERS} Filter users list',

  LOAD_ALL_ROLES: '{USERS} Load All Roles',
  LOAD_ALL_ROLES_FAILED: '{USERS} Load All Roles Failed',

  LOAD_USER_ROLES: '{USERS} Load Users Roles',
  LOAD_USER_ROLES_SUCCEEDED: '{USERS} Load Users Roles Succeeded',
  LOAD_USER_ROLES_FAILED: '{USERS} Load Users Roles Failed',

  LOAD_USER_LOCATIONS: '{USERS} Load Users Locations',
  LOAD_USER_LOCATIONS_FAILED: '{USERS} Load Users Locations Failed',

  CREATE_USER: '{USERS} Create User',
  CREATE_USER_SUCCEEDED: '{USERS} Create User Succeeded',
  CREATE_USER_FAILED: '{USERS} Create User Failed',

  DELETE_USER: '{USERS} Delete User',
  DELETE_USER_SUCCEEDED: '{USERS} Delete User Succeeded',
  DELETE_USER_FAILED: '{USERS} Delete User Failed',

  UPDATE_USER: '{USERS} Update User',
  UPDATE_USER_SUCCEEDED: '{USERS} Update User Succeeded',
  UPDATE_USER_FAILED: '{USERS} Update User Failed',

  LOAD_USER_INFO: '{USERS} Load User Info',
  LOAD_USER_INFO_SUCCEEDED: '{USERS} Load User Info Succeeded',
  LOAD_USER_INFO_FAILED: '{USERS} Load User Info Failed',

  ADD_PROPERTY: '{USERS} Add Property',
  ADD_PROPERTY_SUCCEEDED: '{USERS} Add Property Succeeded',
  ADD_PROPERTY_FAILED: '{USERS} Add Property Failed',

  RESET_ERROR: '{USERS} Reset Error',
}

usersActions.resetError = function () {
  return {
    type: usersActions.actionTypes.RESET_ERROR,
  }
}

usersActions.addProperty = function (name, closeAction, pref) {
  return {
    type: usersActions.actionTypes.ADD_PROPERTY,
    payload: {
      name,
      closeAction,
      pref,
    },
  }
}

usersActions.addPropertySuccess = function (pref) {
  return {
    type: usersActions.actionTypes.ADD_PROPERTY_SUCCEEDED,
    payload: {
      pref,
    },
  }
}

usersActions.addPropertyFailed = function (message) {
  return {
    type: usersActions.actionTypes.ADD_PROPERTY_FAILED,
    payload: {
      message,
    },
  }
}

usersActions.loadUsersList = function () {
  return {
    type: usersActions.actionTypes.LOAD_USERS_LIST,
  }
}

usersActions.loadUsersListSuccess = function (users) {
  return {
    type: usersActions.actionTypes.LOAD_USERS_LIST_SUCCEEDED,
    payload: {
      users,
    },
  }
}

usersActions.loadUsersListFailed = function (message) {
  return {
    type: usersActions.actionTypes.LOAD_USERS_LIST_FAILED,
    payload: {
      message,
    },
  }
}

usersActions.filterUsersList = function (searchValue) {
  return {
    type: usersActions.actionTypes.FILTER_USERS_LIST,
    payload: {
      searchValue,
    },
  }
}

usersActions.loadUserRoles = function (allRoles) {
  return {
    type: usersActions.actionTypes.LOAD_USER_ROLES,
    payload: {
      allRoles,
    },
  }
}

usersActions.loadUserRolesSuccess = function (userRoles) {
  return {
    type: usersActions.actionTypes.LOAD_USER_ROLES_SUCCEEDED,
    payload: {
      userRoles,
    },
  }
}

usersActions.loadUserRolesFailed = function (message) {
  return {
    type: usersActions.actionTypes.LOAD_USER_ROLES_FAILED,
    payload: {
      message,
    },
  }
}

usersActions.loadAllRoles = function () {
  return {
    type: usersActions.actionTypes.LOAD_ALL_ROLES,
  }
}

usersActions.loadAllRolesFailed = function (message) {
  return {
    type: usersActions.actionTypes.LOAD_ALL_ROLES_FAILED,
    payload: {
      message,
    },
  }
}

usersActions.loadUserLocations = function (pref = null) {
  return {
    type: usersActions.actionTypes.LOAD_USER_LOCATIONS,
    payload: {
      pref,
    },
  }
}

usersActions.loadUserLocationsFailed = function (message) {
  return {
    type: usersActions.actionTypes.LOAD_USER_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

usersActions.createUser = function (user) {
  return {
    type: usersActions.actionTypes.CREATE_USER,
    payload: {
      user,
    },
  }
}

usersActions.createUserSuccess = function (user) {
  return {
    type: usersActions.actionTypes.CREATE_USER_SUCCEEDED,
    payload: {
      user,
    },
  }
}

usersActions.createUserFailed = function (message) {
  return {
    type: usersActions.actionTypes.CREATE_USER_FAILED,
    payload: {
      message,
    },
  }
}

usersActions.deleteUser = function (id, onSuccess) {
  return {
    type: usersActions.actionTypes.DELETE_USER,
    payload: {
      id,
      onSuccess,
    },
  }
}

usersActions.deleteUserSuccess = function () {
  return {
    type: usersActions.actionTypes.DELETE_USER_SUCCEEDED,
  }
}

usersActions.deleteUserFailed = function (message) {
  return {
    type: usersActions.actionTypes.DELETE_USER_FAILED,
    payload: {
      message,
    },
  }
}

usersActions.loadUserInfo = function (id) {
  return {
    type: usersActions.actionTypes.LOAD_USER_INFO,
    payload: {
      id,
    },
  }
}

usersActions.loadUserInfoSuccess = function (user) {
  return {
    type: usersActions.actionTypes.LOAD_USER_INFO_SUCCEEDED,
    payload: {
      user,
      pref: user.allowedProperties,
    },
  }
}

usersActions.loadUserInfoFailed = function (message) {
  return {
    type: usersActions.actionTypes.LOAD_USER_INFO_FAILED,
    payload: {
      message,
    },
  }
}

usersActions.updateUser = function (user, profile_id) {
  return {
    type: usersActions.actionTypes.UPDATE_USER,
    payload: {
      user,
      profile_id,
    },
  }
}

usersActions.updateUserSuccess = function (user) {
  return {
    type: usersActions.actionTypes.UPDATE_USER_SUCCEEDED,
    payload: {
      user,
    },
  }
}

usersActions.updateUserFailed = function (message) {
  return {
    type: usersActions.actionTypes.UPDATE_USER_FAILED,
    payload: {
      message,
    },
  }
}

export default usersActions
