import dashboardSagas from '../containers/Dashboard/services/dashboard-sagas'
import { fork } from 'redux-saga/effects'
import ARCDeviceDetailsSaga from '../containers/Devices/Details/ARC/services/arcDeviceDetails-sagas'
import UVGIDeviceDetailsSaga from '../containers/Devices/Details/UVGI/services/uvgiDeviceDetails-sagas'
import ARCDeviceListSaga from '../containers/Devices/List/ARC/services/arcDeviceList-sagas'
import UVGIDeviceListSaga from '../containers/Devices/List/UVGI/services/uvgiDeviceList-sagas'
import locationSagas from '../containers/Locations/service/location-sagas'
import accountSaga from '../containers/Account/services/account-sagas'
import authorizationSaga from '../containers/LogIn/services/authorization-sagas'
import usersSaga from '../containers/Users/services/users-sagas'
import UVGISummarySagas from '../containers/Summary/UVGI/services/uvgiSummary-sagas'
import rootSaga from '../services/root/root-sagas'
import gaSagas from '../services/googleAnalytics/ga-sagas'
import arcSummarySagas from '../containers/Summary/ARC/service/arcSummary-sagas'
import deviceSaga from '../containers/Devices/_shared/services/devices-sagas'
import summarySaga from '../containers/Summary/_shared/services/summary-sagas'
import extDeviceListSaga from '../containers/Devices/List/EXT_DEVICE/services/extDeviceList-sagas'
import extDeviceDetailsSaga from '../containers/Devices/Details/EXT_DEVICE/services/extDeviceDetails-sagas'

export default function* sagas() {
  yield fork(dashboardSagas)
  yield fork(locationSagas)
  yield fork(deviceSaga)
  yield fork(accountSaga)
  yield fork(usersSaga)
  yield fork(rootSaga)
  yield fork(authorizationSaga)
  yield fork(arcSummarySagas)
  yield fork(UVGISummarySagas)
  yield fork(summarySaga)
  yield fork(ARCDeviceListSaga)
  yield fork(UVGIDeviceListSaga)
  yield fork(extDeviceListSaga)
  yield fork(ARCDeviceDetailsSaga)
  yield fork(UVGIDeviceDetailsSaga)
  yield fork(extDeviceDetailsSaga)
  yield fork(gaSagas)
}
