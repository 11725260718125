import usersActions from './users-actions'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { HttpClient } from '../../../services/HttpClient'
import customTreeActions from '../../../components/_shared/customTree/services/customTree-actions'
import { deeps } from '../../Locations/service/location-service'
import { prepareLocations } from './users-service'
import authorizationActions from '../../LogIn/services/authorization-actions'
import { LOCATIONS_CONST } from '../../Locations/service/location-constants'

export const getCurrentUserRole = (state) => state.rootReducer.role
export const getUserInfo = (state) => state.usersReducer.userInfo
export const getCurrentUserId = (state) => state.rootReducer.userId

function* loadUsersList() {
  try {
    const data = yield call(() => {
      return HttpClient.post('/api/secured/user-profiles/scroll', {
        body: {
          query: 'active eq true',
        },
      })
    })

    if (data.message) {
      yield put(usersActions.loadUsersListFailed(data.message))
    } else {
      yield put(usersActions.loadUsersListSuccess(data))
    }
  } catch (e) {
    yield put(usersActions.loadUsersListFailed(e.message))
  }
}

function* loadAllRoles() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/public/roles')
    })

    if (data.message) {
      yield put(usersActions.loadAllRolesFailed(data.message))
    } else {
      yield put(usersActions.loadUserRoles(data))
    }
  } catch (e) {
    yield put(usersActions.loadAllRolesFailed(e.message))
  }
}

function* loadUserRoles() {
  try {
    const role = yield select(getCurrentUserRole)

    const data = yield call(() => {
      return HttpClient.get(`/api/public/subordinate-roles/${role}`)
    })

    if (data.message) {
      yield put(usersActions.loadUserRolesFailed(data.message))
    } else {
      yield put(usersActions.loadUserRolesSuccess(data))
    }
  } catch (e) {
    yield put(usersActions.loadUserRolesFailed(e.message))
  }
}

function* loadUserLocations(action) {
  try {
    let userId = yield select(getCurrentUserId)
    const { pref } = action.payload

    const data = yield call(() => {
      return HttpClient.get('/api/secured/locations/')
    })

    if (data.message) {
      yield put(usersActions.loadUserLocationsFailed(data.message))
    } else {
      yield put(
        customTreeActions.loadNodesSucceeded(
          userId,
          prepareLocations(data, pref),
          deeps
        )
      )
    }
  } catch (e) {
    yield put(usersActions.loadUserLocationsFailed(e.message))
  }
}

function* createUser(action) {
  try {
    const user = action.payload.user

    const data = yield call(() => {
      return HttpClient.post('/api/secured/user-profiles/users', {
        body: user,
      })
    })

    if (data.message) {
      yield put(usersActions.createUserFailed(data.message))
    } else {
      yield put(usersActions.createUserSuccess(data))

      window.location.href = '/#/users'
    }
  } catch (e) {
    yield put(usersActions.createUserFailed(e.message))
  }
}

function* deleteUser(action) {
  try {
    const { id, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/profiles/${id}/deactivate`)
    })

    if (data.message) {
      yield put(usersActions.deleteUserFailed(data.message))
    } else {
      yield put(usersActions.deleteUserSuccess(data))

      onSuccess()
    }
  } catch (e) {
    yield put(usersActions.deleteUserFailed(e.message))
  }
}

function* updateUser(action) {
  try {
    const { user, profile_id } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/user-profiles/${profile_id}`, {
        body: {
          ...user,
          id: profile_id,
        },
      })
    })

    if (data.message) {
      yield put(usersActions.updateUserFailed(data.message))
    } else {
      yield put(usersActions.updateUserSuccess(data))
      window.location.href = '/#/users'
    }
  } catch (e) {
    yield put(usersActions.updateUserFailed(e.message))
  }
}

function* loadUserInfo(action) {
  try {
    const id = action.payload.id

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/user-profiles/${id}`)
    })

    if (data.message) {
      yield put(usersActions.loadUserInfoFailed(data.message))

      window.location.href = '/#/users'
    } else {
      yield put(usersActions.loadUserInfoSuccess(data))
    }
  } catch (e) {
    yield put(usersActions.loadUserInfoFailed(e.message))
  }
}

function* addProperty(action) {
  try {
    const { name, closeAction, pref } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/secured/locations/', {
        body: {
          parentId: null,
          name,
          locationType: LOCATIONS_CONST.PROPERTY,
        },
      })
    })

    if (data.message) {
      yield put(usersActions.addPropertyFailed(data.message))
    } else {
      yield put(usersActions.addPropertySuccess(pref))

      closeAction()
    }
  } catch (e) {
    yield put(usersActions.addPropertyFailed(e.message))
  }
}

function* addPropertySaga() {
  yield takeEvery(usersActions.actionTypes.ADD_PROPERTY, addProperty)
}

function* loadUsersListSaga() {
  yield all([
    yield takeEvery(usersActions.actionTypes.LOAD_USERS_LIST, loadUsersList),
    yield takeEvery(
      usersActions.actionTypes.DELETE_USER_SUCCEEDED,
      loadUsersList
    ),
    yield takeEvery(
      authorizationActions.actionTypes.LOGIN_TO_APP_SUCCEEDED,
      loadUsersList
    ),
    yield takeEvery(
      authorizationActions.actionTypes.LOGIN_FROM_FACTORY_SUCCEEDED,
      loadUsersList
    ),
  ])
}

function* loadUserRolesSaga() {
  yield takeEvery(usersActions.actionTypes.LOAD_USER_ROLES, loadUserRoles)
}

function* loadAllRolesSaga() {
  yield takeEvery(usersActions.actionTypes.LOAD_ALL_ROLES, loadAllRoles)
}

function* loadUserLocationsSaga() {
  yield all([
    takeEvery(usersActions.actionTypes.LOAD_USER_LOCATIONS, loadUserLocations),
    takeEvery(
      usersActions.actionTypes.LOAD_USER_INFO_SUCCEEDED,
      loadUserLocations
    ),
    takeEvery(
      usersActions.actionTypes.ADD_PROPERTY_SUCCEEDED,
      loadUserLocations
    ),
  ])
}

function* createUserSaga() {
  yield takeEvery(usersActions.actionTypes.CREATE_USER, createUser)
}

function* deleteUserSaga() {
  yield takeEvery(usersActions.actionTypes.DELETE_USER, deleteUser)
}

function* loadUserInfoSaga() {
  yield all([takeEvery(usersActions.actionTypes.LOAD_USER_INFO, loadUserInfo)])
}

function* updateUserSaga() {
  yield takeEvery(usersActions.actionTypes.UPDATE_USER, updateUser)
}

export default function* usersSaga() {
  yield fork(loadUsersListSaga)
  yield fork(loadUserLocationsSaga)
  yield fork(loadAllRolesSaga)
  yield fork(loadUserRolesSaga)
  yield fork(createUserSaga)
  yield fork(deleteUserSaga)
  yield fork(loadUserInfoSaga)
  yield fork(updateUserSaga)
  yield fork(addPropertySaga)
}
