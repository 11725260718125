import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  deviceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: COLORS.WARM_GRAY,
    marginBottom: '5px',
    minHeight: '32px',
    textAlign: 'left',
  },
  selectAll: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  treeLabel: {
    fontSize: '11px',
  },
  selectAllLabel: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  checkbox: {
    '& svg': {
      width: '14px',
      height: '14px',
    },
  },
  arrowColumn: {
    minWidth: '25px',
    height: '32px',
    borderLeft: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    '& button': {
      padding: '4px 0',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: COLORS.BLACK,
    },
  },
  divider: {
    margin: '12px 0',
  },
  '@media (min-width: 1224px)': {
    deviceContainer: {
      padding: '5px 0',
    },
    treeNodeContainer: {
      backgroundColor: COLORS.WHITE,
    },
    treeLabel: {
      fontSize: 'initial',
      fontFamily: 'Cerebri Sans Pro Semi Bold',
    },
    checkbox: {
      '& svg': {
        width: '18px',
        height: '18px',
      },
    },
    selectAllLabel: {
      fontFamily: 'Cerebri Sans Pro Regular',
      fontSize: 'initial',
    },
  },
})

export default styles
