import { withStyles } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {
  DISINFECTED_LOCATIONS,
  LOCATIONS_TABLE,
} from '../service/arcSummary-constants'
import LocationsChartContainer from '../../_shared/components/charts/LocationsChartContainer'
import LocationsTableContainer from './../../_shared/components/tables/LocationsTableContainer'
import LinkToReport from '../../_shared/components/LinkToReport'
import { withMediaQuery } from '../../../../services/root/root-service'
import style from './style'

const ARCLocationSummaryInfo = (props) => {
  const {
    locations,
    dateFilter,
    defaultPeriod,
    timezone,
    disinfectedLocationsCount,
    totalLocationsCount,
    deviceType,
    dateFilterLabel,
    locationFilter,
    ids,
    classes,
  } = props

  return (
    <div className={classes.locationsAndDevicesWrapper}>
      <LocationsChartContainer
        secondaryLocationsCount={disinfectedLocationsCount}
        title={DISINFECTED_LOCATIONS.title}
        blueTitle={DISINFECTED_LOCATIONS.blueTitle}
        grayTitle={DISINFECTED_LOCATIONS.grayTitle}
        createSubtitle={DISINFECTED_LOCATIONS.totalLocations}
        totalLocationsCount={totalLocationsCount}
      />
      <LinkToReport
        pathname={`/summary/${deviceType}/locations`}
        state={{
          date: {
            dateFilter,
            period: defaultPeriod,
          },
          dateFilterLabel,
          location: {
            locationFilter,
            ids,
          },
        }}
        label={LOCATIONS_TABLE.title}
      />
      <LocationsTableContainer
        locations={locations}
        title={LOCATIONS_TABLE.title}
        dateFilter={dateFilter}
        defaultPeriod={defaultPeriod}
        timezone={timezone}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { ARCSummaryReducer, sharedSummaryReducer },
    rootReducer,
  } = state

  return {
    locations: ARCSummaryReducer.locations,
    dateFilter: ARCSummaryReducer.dateFilter,
    defaultPeriod: ARCSummaryReducer.period,
    timezone: rootReducer.timezone,
    disinfectedLocationsCount: ARCSummaryReducer.disinfectedLocationsCount,
    totalLocationsCount: ARCSummaryReducer.totalLocationsCount,
    deviceType: sharedSummaryReducer.activeTab,
    dateFilterLabel: ARCSummaryReducer.dateFilterLabel,
    ids: ARCSummaryReducer.ids,
    locationFilter: ARCSummaryReducer.locationFilter,
  }
}

export default connect(mapStateToProps)(
  withMediaQuery(withStyles(style)(ARCLocationSummaryInfo))
)
