import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../style'
import { connect } from 'react-redux'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../../../services/root/root-service'
import AddExtDeviceButton from './AddExtDeviceButton'
import extDeviceListActions from '../../services/extDeviceList-actions'
import ExtDeviceLocationButton from './ExtDeviceLocationButton'

const AddDevice = (props) => {
  const {
    error,
    roomsFilter,
    devices,
    failedSerialNumber,
    addNewDevice,
    resetActionError,
    setupRoomsFilter,
    classes,
  } = props

  const onAddDevice = (serialNumber, onClose) => {
    addNewDevice(serialNumber, onClose)
  }

  return (
    <div className={classes.addDeviceContainer}>
      <AddExtDeviceButton
        onSubmit={onAddDevice}
        resetActionError={resetActionError}
        locationFilter={roomsFilter}
        error={error}
        devices={devices}
        failedSerialNumber={failedSerialNumber}
      >
        <ExtDeviceLocationButton
          setupRoomsFilter={setupRoomsFilter}
          roomsFilter={roomsFilter}
        />
      </AddExtDeviceButton>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { extDeviceListReducer },
  } = state

  return {
    error: extDeviceListReducer.error,
    roomsFilter: extDeviceListReducer.roomsFilter,
    failedSerialNumber: extDeviceListReducer.failedSerialNumber,
    devices: extDeviceListReducer.list,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetActionError: () => dispatch(extDeviceListActions.resetActionError()),
  addNewDevice: (serialNumber, onClose) =>
    dispatch(
      checkConnection(() =>
        extDeviceListActions.addNewDevice(serialNumber, onClose)
      )
    ),
  setupRoomsFilter: (nodes, tree, userId) =>
    dispatch(extDeviceListActions.setupRoomsFilter(nodes, tree, userId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(AddDevice)))
