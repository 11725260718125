import React, { useState } from 'react'
import { connect } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'
import LocationCheckboxTree from '../../../../../components/_shared/LocationCheckboxTree/LocationCheckboxTree'
import styles from './style'
import {
  checkConnection,
  createLocationLabel,
  filterLocationsWithAllowedProps,
} from '../../../../../services/root/root-service'
import {
  LOCATION_FILTER_LABELS,
  LOCATION_LABELS,
  TREE_LEVELS,
} from '../../../../../services/constants'
import treeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import { gaEventAction } from '../../../../../services/googleAnalytics/ga-actions'

const LocationFilter = withStyles(styles)((props) => {
  const {
    applyLocationFilter,
    nodes,
    locationFilter,
    isAllSelected,
    loadNodesSucceeded,
    userId,
    treeCopy,
    isArc = true,
    classes,
    gaContext = {},
    gaEventSend,
  } = props
  const [open, setOpen] = useState(false)

  const locationLabel = createLocationLabel(locationFilter, isAllSelected)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onApply = () => {
    applyLocationFilter(nodes)
    setOpen(false)
    gaEventSend('location_filter', {
      event_label: nodes.filter((node) => node.checked).length,
      ...gaContext,
    })
  }

  const resetChanges = () => {
    loadNodesSucceeded(
      userId,
      filterLocationsWithAllowedProps(treeCopy, locationFilter),
      isArc ? null : TREE_LEVELS.ROOM,
      true
    )

    handleClose()
  }

  return (
    <div>
      <Chip
        onClick={handleClickOpen}
        className={classes.locationFilter}
        label={`${LOCATION_LABELS.location}: ${locationLabel}`}
        variant="outlined"
      />
      <LocationCheckboxTree
        onApply={onApply}
        actionLabel={LOCATION_FILTER_LABELS.actionLabel}
        modalTitle={LOCATION_FILTER_LABELS.modalTitle}
        onClose={resetChanges}
        isOpen={open}
      />
    </div>
  )
})

const mapStateToProps = (state) => {
  const { customTreeReducer, rootReducer } = state

  return {
    treeCopy: customTreeReducer.treeCopy,
    nodes: customTreeReducer.nodes,
    isAllSelected: customTreeReducer.isAllSelected,
    userId: rootReducer.userId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadNodesSucceeded: (id, nodes, deeps, skipUpdate) =>
    dispatch(
      checkConnection(() =>
        treeActions.loadNodesSucceeded(id, nodes, deeps, skipUpdate)
      )
    ),
  gaEventSend: gaEventAction(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationFilter)
