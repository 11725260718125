import React, { useState } from 'react'
import styles from './style'
import { withStyles } from '@material-ui/core'
import { PAGINATION } from '../../../services/constants'
import ItemsPerPageModal from './ItemsPerPageModal'
import Button from '@material-ui/core/Button'

const ItemsPerPageComponent = (props) => {
  const { rowsPerPage, setRowsPerPage, stopPolling, startPolling, classes } =
    props
  const [open, setOpen] = useState(false)

  const openModal = () => {
    if (stopPolling) {
      stopPolling()
    }

    setOpen(true)
  }

  const closeModal = () => {
    if (startPolling) {
      startPolling()
    }

    setOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        className={classes.perPageButton}
        onClick={openModal}
      >
        {PAGINATION.perPage}: {rowsPerPage}
      </Button>
      <ItemsPerPageModal
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        isOpen={open}
        onCloseModal={closeModal}
      />
    </>
  )
}

export default withStyles(styles)(ItemsPerPageComponent)
