import React, { useEffect } from 'react'
import DeviceTabsContainer from '../../components/_shared/deviceTabPanel/DeviceTabsContainer'
import rootActions from '../../services/root/root-actions'
import { checkConnection } from '../../services/root/root-service'
import {
  DEVICES_PAGE_TITLE,
  devicesTypesConfig,
} from './_shared/services/devices-constants'
import actions from './_shared/services/devices-actions'
import { connect } from 'react-redux'
import { DEVICE_TYPE_MAPPER } from '../../services/constants'
import { gaEventAction } from '../../services/googleAnalytics/ga-actions'

const DevicesListContainer = (props) => {
  const { tab, setTab, history, getLocationModificationId, gaEventSend } = props

  useEffect(() => {
    getLocationModificationId()
  }, [])

  const changeActiveTab = (tab, clickEvent) => {
    setTab(tab)
    if (clickEvent) {
      gaEventSend('device_report_tab', { event_label: DEVICE_TYPE_MAPPER[tab] })
    }
  }

  return (
    <DeviceTabsContainer
      changeActiveTab={changeActiveTab}
      history={history}
      title={DEVICES_PAGE_TITLE}
      devicesConfig={devicesTypesConfig}
      activeTab={tab}
    />
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { sharedDevicesReducer },
  } = state

  return {
    tab: sharedDevicesReducer.tab,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setTab: (tab) => dispatch(actions.setTab(tab)),
  getLocationModificationId: () =>
    dispatch(checkConnection(rootActions.getLocationModificationId)),
  gaEventSend: gaEventAction(dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesListContainer)
