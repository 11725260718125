import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { LOCATION_FILTER_LABELS, PREF } from '../../../services/constants'
import { saveLocationPrefSettings } from '../services/dashboard-service'
import styles from './styles'
import actions from '../services/dashboard-actions'
import customTreeActions from '../../../components/_shared/customTree/services/customTree-actions'
import { withMediaQuery } from '../../../services/root/root-service'
import classNames from 'classnames'

const ResetFilter = withStyles(styles)((props) => {
  const {
    classes,
    isDownloadLoading,
    resetFilters,
    stopPollingModification,
    selectAll,
    isMobile,
    userId,
  } = props

  const onResetFilters = () => {
    stopPollingModification()
    selectAll()

    saveLocationPrefSettings(PREF.LOCATIONS, userId, null)
    saveLocationPrefSettings(PREF.UNKNOWN_LOCATIONS, userId, false)
    saveLocationPrefSettings(PREF.DELETED_LOCATIONS, userId, false)

    resetFilters()
  }

  return (
    <Button
      className={
        !isDownloadLoading
          ? classes.resetFilters
          : classNames(
              classes.resetFilters,
              classes.resetFiltersWhenDownloadLoading
            )
      }
      onClick={onResetFilters}
      variant="contained"
    >
      {!isMobile && <CloseIcon className={classes.resetIcon} color="primary" />}
      {LOCATION_FILTER_LABELS.resetFilter}
    </Button>
  )
})

const mapStateToProps = (state) => ({
  userId: state.rootReducer.userId,
  isDownloadLoading: state.dashboardReducer.isDownloadLoading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    resetFilters: () => dispatch(actions.resetFilters()),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    selectAll: () => dispatch(customTreeActions.selectAll(true)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(ResetFilter))
