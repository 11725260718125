import { COLORS } from '../../../../../services/constants'

const styles = () => ({
  backIcon: {
    width: '18px',
    height: '18px',
    color: COLORS.BLUE,
  },
  titlesWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  backButton: {
    marginRight: '5px',
    '&.MuiIconButton-root': {
      padding: '2px',
    },
  },
  '@media (min-width: 1224px)': {
    backButton: {
      marginRight: '16px',
    },
    backIcon: {
      width: '34px',
      height: '34px',
    },
    titlesWrapper: {
      justifyContent: 'center',
      margin: '40px 0',
    },
  },
})

export default styles
