import { jsPDF } from 'jspdf'
import moment from 'moment'
import { BasePdfElement } from './BasePdfElement'
import { PdfCards } from './PdfCards'
import { PdfFilers } from './PdfFilters'
import { PdfImageCard } from './PdfImageCard'
import { PdfRow } from './PdfRow'

const PAPER_WIDTH = 210
const PAPER_HEIGHT = 297
const MARGIN_LEFT = 10
const MARGIN_RIGHT = 10
const MARGIN_TOP = 12
const MARGIN_BOTTOM = 10
const CONTENT_HEIGHT = PAPER_HEIGHT - MARGIN_TOP - MARGIN_BOTTOM
const CONTENT_WIDTH = PAPER_WIDTH - MARGIN_LEFT - MARGIN_RIGHT
const BACKGROUND_COLOR = [238, 238, 238]

export class PdfSummaryGenerator {
  title: string
  position_y: number
  doc: jsPDF
  filename: string

  constructor(filename: string, name: string) {
    this.title = name
    this.position_y = 0
    this.doc = new jsPDF()
    this.filename = filename
  }

  addPageIfNeeded = (heightToAdd: number) => {
    if (this.position_y + heightToAdd > PAPER_HEIGHT - MARGIN_BOTTOM) {
      this.position_y = MARGIN_TOP
      this.doc.addPage()
    }
    if (this.position_y == 0) {
      this.position_y = MARGIN_TOP
    }
  }

  addHeader = async (text: string, height: number) => {
    this.doc.setFontSize(14)

    this.doc.setFillColor(
      BACKGROUND_COLOR[0],
      BACKGROUND_COLOR[1],
      BACKGROUND_COLOR[2]
    )
    this.doc.rect(0, 0, PAPER_WIDTH, height, 'F')
    this.doc.text('Summary - ' + text, MARGIN_LEFT, height / 2 + 2)
    this.doc.text(
      moment().format('MM/DD/YYYY'),
      PAPER_WIDTH - MARGIN_RIGHT - 30,
      height / 2 + 2
    )
  }

  addFooter = async (current: number, total: number, height: number) => {
    this.doc.setFontSize(10)

    this.doc.setFillColor(
      BACKGROUND_COLOR[0],
      BACKGROUND_COLOR[1],
      BACKGROUND_COLOR[2]
    )
    this.doc.rect(0, PAPER_HEIGHT - height, PAPER_WIDTH, height, 'F')
    this.doc.text(
      `Page ${current} of ${total}`,
      MARGIN_LEFT,
      PAPER_HEIGHT - height / 2 + 2
    )
  }

  async render() {
    const contentBlocks: {
      height: number
      cb: (height: number) => BasePdfElement | null
    }[] = []

    // filters
    contentBlocks.push({
      height: CONTENT_HEIGHT * 0.08,
      cb: () => {
        const element = document.querySelector<HTMLDivElement>('.pdf-filters')
        return element ? new PdfFilers(element) : null
      },
    })

    // cards
    contentBlocks.push({
      height: CONTENT_HEIGHT * 0.2,
      cb: () => {
        const element = document.querySelector<HTMLDivElement>('.pdf-cards')
        return element ? new PdfCards(element) : null
      },
    })

    // big charts of full width
    document
      .querySelectorAll<HTMLDivElement>('.pdf-chart-100')
      .forEach((elm) => {
        contentBlocks.push({
          height: CONTENT_HEIGHT * 0.37,
          cb: () => new PdfImageCard(elm),
        })
      })

    // small charts displayed in two columns
    const chartsGridItems =
      document.querySelectorAll<HTMLDivElement>('.pdf-chart-50')
    // group all elements containing pdf-chart-50 class in groups of 2
    const rows = Math.ceil(chartsGridItems.length / 2)
    let itemCount = 0
    for (let pos = 0; pos < rows; pos++) {
      contentBlocks.push({
        height: CONTENT_HEIGHT * 0.35,
        cb: () => {
          const columns = [new PdfImageCard(chartsGridItems[itemCount])]
          itemCount++
          if (itemCount < chartsGridItems.length) {
            columns.push(new PdfImageCard(chartsGridItems[itemCount]))
            itemCount++
          }

          return new PdfRow(columns)
        },
      })
    }

    for (let i = 0; i < contentBlocks.length; i++) {
      const pdfElement = contentBlocks[i].cb(contentBlocks[i].height)
      if (pdfElement) {
        this.addPageIfNeeded(contentBlocks[i].height)
        const total = this.doc.getNumberOfPages()
        this.doc.setPage(total)
        pdfElement.setup(
          this.doc,
          MARGIN_LEFT,
          this.position_y,
          contentBlocks[i].height,
          CONTENT_WIDTH
        )
        await pdfElement.render()
        this.position_y += contentBlocks[i].height
      }
    }

    const total = this.doc.getNumberOfPages()
    for (let i = 0; i < total; i++) {
      this.doc.setPage(i + 1)
      this.addHeader(this.title, MARGIN_TOP)
      this.addFooter(i + 1, total, MARGIN_BOTTOM)
    }
    this.doc.save(this.filename)
  }
}
