const styles = (theme) => ({
  perPage: {
    height: '25px',
    fontSize: '12px',
    lineHeight: '14px',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  result: {
    fontSize: '12px',
    lineHeight: '14px',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  pagination: {
    '& ul > li:last-child': {
      display: 'none',
    },
  },
  moreButton: {
    textTransform: 'none',
    fontFamily: 'Cerebri Sans Pro Regular !important',
  },
  perPageButton: {
    fontFamily: 'Cerebri Sans Pro Regular',
    minWidth: '100px',
    fontSize: '12px',
    height: '35px',
  },
  perPageChip: {
    marginBottom: '10px',
    fontFamily: 'Cerebri Sans Pro Regular',
    minWidth: '120px',
    fontSize: '12px',
    height: '35px',
  },
  submitButton: {
    margin: '20px 0',
    minWidth: '120px',
  },
  dialog: {
    '& .MuiDialogTitle-root': {
      padding: '24px',
    },
  },
  '@media (min-width: 1224px)': {
    dialog: {
      '& .MuiDialog-paperWidthXs': {
        maxWidth: '500px',
      },
      '& .MuiDialogContent-root': {
        padding: '15px 45px',
      },
      '& .MuiDialogTitle-root': {
        padding: '30px 24px',
      },
    },
    submitButton: {
      minWidth: '400px',
    },
  },
})

export default styles
