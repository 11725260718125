import extDeviceListActions from './extDeviceList-actions'
import { LOCATION_FILTER_LABELS } from '../../../../../services/constants'

export const extDeviceListReducer = (
  state = {
    list: [],
    locationFilter: {},
    roomsFilter: {},
    isLoading: false,
    locationFilterLabel: LOCATION_FILTER_LABELS.all,
    allowedLocationIds: null,
  },
  action
) => {
  switch (action.type) {
    case extDeviceListActions.actionTypes.ADD_NEW_DEVICE: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case extDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED: {
      return {
        ...state,
        isLoading: false,
        error: '',
      }
    }
    case extDeviceListActions.actionTypes.ADD_NEW_DEVICE_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      }
    }
    case extDeviceListActions.actionTypes.SETUP_ROOMS_FILTER:
      return {
        ...state,
        roomsFilter: action.payload.nodes,
        locationFilterError: '',
      }
    case extDeviceListActions.actionTypes.RESET_ERROR:
      return {
        ...state,
        error: '',
      }
    case extDeviceListActions.actionTypes.RESET_ROOMS_FILTER:
      return {
        ...state,
        roomsFilter: {},
      }
    case extDeviceListActions.actionTypes.LOAD_DEVICES_LIST_SUCCEEDED:
      return {
        ...state,
        list: action.payload.list?.items,
      }
    default:
      return state
  }
}
