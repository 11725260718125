import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { map, startCase, uniqueId } from 'lodash'
import { withMediaQuery } from '../../../../services/root/root-service'
import styles from '../../style'
import {
  getLocationInputLabel,
  getNewChildLocationLabel,
  getNewLocationLabel,
  getPossibleChildren,
} from '../../service/location-service'
import AddLocation from './AddLocation'

const AddLocationButtonsContainer = (props) => {
  const {
    classes,
    currentSchema,
    onSubmit,
    isMobile,
    parentId,
    deep,
    isChild,
    history,
  } = props
  const possibleChildren = getPossibleChildren(currentSchema, deep)

  return (
    <div
      className={
        isMobile
          ? classes.childrenLocationButtons
          : classes.addLocationButtonContainer
      }
    >
      {map(possibleChildren, (children) => {
        const buttonLabel = isChild
          ? getNewChildLocationLabel(startCase(children), isMobile)
          : getNewLocationLabel(startCase(children))
        const locationLabel = getLocationInputLabel(startCase(children))

        return (
          <AddLocation
            buttonLabel={buttonLabel}
            history={history}
            locationType={children}
            key={uniqueId()}
            locationLabel={locationLabel}
            isChild={isChild}
            onSubmit={onSubmit}
            parentId={parentId}
          />
        )
      })}
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSchema: state.locationReducer.currentSchema,
})

export default connect(mapStateToProps)(
  withMediaQuery(withStyles(styles)(AddLocationButtonsContainer))
)
