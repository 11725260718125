import { find, map, isUndefined } from 'lodash'
import * as moment from 'moment'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorIcon from '@material-ui/icons/Error'
import {
  hhmmaFormat,
  mssFormat,
  utcConverter,
  yyyyMMDFormat,
} from '../../../../services/dateFormat-service'
import { checkDisconnectedStatus } from '../../../../services/root/root-service'
import {
  ACTIVITY_DETAILS_COUNTDOWN_LABELS,
  ACTIVITY_DETAILS_CYCLE_LABELS,
  ACTIVITY_DETAILS_RUN_TIME_LABELS,
  FIELDS,
  STATUS_COLOR,
  NONE_VALUE,
} from '../dashboard-constants'
import {
  DEVICE_TYPE,
  STATUS_FILTER_VALUES,
} from '../../../../services/constants'

const prepareActivitiesByGroups = function (data, timezone) {
  const activityGroups = {}

  if (data.length) {
    map(data, function (item) {
      const timeStarted = item.modified
      const timezoneTime = timezone
        ? moment.utc(timeStarted).utcOffset(timezone)
        : moment.utc(timeStarted).local()
      const startedDate = timezoneTime.format(yyyyMMDFormat)

      if (activityGroups[startedDate]) {
        activityGroups[startedDate].push(item)
      } else {
        activityGroups[startedDate] = [item]
      }
    })
  }

  return activityGroups
}

const getIsCircularProperty = (activity) => {
  const { details, txStatus } = activity

  return !(
    (details[FIELDS.phase] === FIELDS.countdown &&
      txStatus === STATUS_FILTER_VALUES.in_progress) ||
    (details[FIELDS.phase] === FIELDS.countdown &&
      txStatus === STATUS_FILTER_VALUES.cancelled)
  )
}

const getColor = (activity) => {
  const { details = {}, txStatus, modified, deviceType } = activity
  const phase = details[FIELDS.phase]

  const isInProgress = phase === FIELDS.cycle || !phase

  const isPaused = phase === FIELDS.countdown

  // didn't recieve any events for (msgFreq * 2 + 10 sec)
  const isDisconnected = checkDisconnectedStatus(
    details[FIELDS.disconnected],
    modified
  )

  // fault message was sent in status event
  const isFault = details.faults && details.faults.length >= 1

  let color

  switch (txStatus) {
    case STATUS_FILTER_VALUES.in_progress:
      if (isPaused) {
        color = STATUS_COLOR.paused
      }

      if (isInProgress) {
        color = STATUS_COLOR.in_progress
      }

      if (isDisconnected || isFault) {
        color = STATUS_COLOR.disconnected
      }

      break
    case STATUS_FILTER_VALUES.cancelled:
      if (isPaused) {
        color = STATUS_COLOR.paused
      } else {
        color = STATUS_COLOR.cancelled
      }

      break
    case STATUS_FILTER_VALUES.paused:
      if (deviceType === DEVICE_TYPE.arc) {
        color = STATUS_COLOR.cancelled
      }

      if (deviceType === DEVICE_TYPE.uvgi) {
        color = STATUS_COLOR.paused
      }

      break
    case STATUS_FILTER_VALUES.completed:
      color = STATUS_COLOR.completed

      break
    default:
      return
  }

  return color
}

const getCircularValue = (activity, timeRemaining) => {
  const { details, txStatus } = activity

  const txTime = moment
    .utc(details[FIELDS.estCompletion])
    .diff(moment.utc(details[FIELDS.cycleStart]))
  const interruptedTime = txTime - details[FIELDS.cycleTime]

  const timeCycle = txTime - interruptedTime

  const isInProgress = txStatus === STATUS_FILTER_VALUES.in_progress

  let value

  if (timeCycle) {
    value =
      timeRemaining < 0 ? 100 : ((timeCycle - timeRemaining) / timeCycle) * 100

    if (timeRemaining > timeCycle) {
      value = 0
    }
  } else if (activity.deviceType === DEVICE_TYPE.arc) {
    value = timeRemaining < 0 ? 100 : 0
  } else if (isInProgress) {
    value = moment(details[FIELDS.lastEvent]).valueOf()
  }

  if (details[FIELDS.phase] === FIELDS.countdown && isInProgress) {
    value = timeRemaining < 0 ? 0 : timeRemaining
  }

  if (txStatus === STATUS_FILTER_VALUES.completed) {
    value = 100
  }

  if (
    details[FIELDS.phase] === FIELDS.countdown &&
    txStatus === STATUS_FILTER_VALUES.cancelled
  ) {
    const countdownValue = details[FIELDS.remaining]

    value = countdownValue < 0 ? 0 : countdownValue
  }

  return value
}

const getTimeValue = (activity, timezone) => {
  const { details = {}, txStatus, created, modified } = activity
  const phase = details[FIELDS.phase]
  const isInProgress = txStatus === STATUS_FILTER_VALUES.in_progress
  let value = ''

  if (isInProgress && phase === FIELDS.cycle) {
    value = details[FIELDS.estCompletion]
  } else if (isInProgress && !phase) {
    value = created
  } else if (
    STATUS_FILTER_VALUES.paused === txStatus &&
    details[FIELDS.interruptionTimes]
  ) {
    const [interruptionStartTime] = details[FIELDS.interruptionTimes]

    value = interruptionStartTime
  } else if (details[FIELDS.lastEvent]) {
    value = details[FIELDS.lastEvent]
  } else {
    value = modified
  }

  return utcConverter(value, hhmmaFormat, timezone)
}

const getTimeLabel = (activity) => {
  const { details, txStatus, deviceType } = activity
  const phase = details[FIELDS.phase]
  let label = ''

  switch (txStatus) {
    case STATUS_FILTER_VALUES.in_progress:
      if (phase === FIELDS.countdown) {
        label = 'Cycle Starts'
      }

      if (phase === FIELDS.cycle) {
        label = 'Est. Completion'
      }

      if (deviceType === DEVICE_TYPE.uvgi) {
        label = 'Start Time'
      }

      break
    case STATUS_FILTER_VALUES.cancelled:
      label = 'Cancelled'
      break
    case STATUS_FILTER_VALUES.paused:
      label = 'Interrupted'
      break
    case STATUS_FILTER_VALUES.completed:
      label = 'Completed'
      break
    default:
      return
  }

  return label
}

const getCalculatedValue = (isError, value) => (isError ? NONE_VALUE : value)

const findStartEvent = (details) => {
  if (!details[FIELDS.eventsSeq]) {
    return false
  }

  return find(
    details[FIELDS.eventsSeq],
    (event) => event === 'EVT301:start' || event === 'EVT311:start'
  )
}

const getText = (type, value, isErrorCalculation, timezone, details) => {
  let text = value

  switch (type) {
    case 'date':
      const calculatedDateValue = utcConverter(value, hhmmaFormat, timezone)

      const isStartEvent = findStartEvent(details)

      text = getCalculatedValue(
        !isStartEvent && isErrorCalculation,
        calculatedDateValue
      )
      break
    case 'time':
      const calculatedTimeValue =
        value > 0 ? moment(value).format(mssFormat) : 0

      text = getCalculatedValue(isErrorCalculation, calculatedTimeValue)
      break
    default:
      return text
  }

  return text
}

const getIconName = (statusName) => {
  switch (statusName) {
    case STATUS_FILTER_VALUES.cancelled:
      return CancelIcon
    case STATUS_FILTER_VALUES.completed:
      return CheckCircleIcon
    case STATUS_FILTER_VALUES.paused:
      return ErrorIcon
    default:
      return
  }
}

const activity = (activity = { details: {} }, prevActivities = []) => {
  let isIncorrect = false
  const prevActivity = find(prevActivities, (item) => item.id === activity.id)

  if (activity.txStatus === STATUS_FILTER_VALUES.completed) {
    isIncorrect = findStartEvent(activity.details)
  }

  return {
    ...activity,
    isIncorrect: isUndefined(isIncorrect),
    details: activity.details || {},
    expanded: prevActivity ? prevActivity.expanded : false,
  }
}

const getRemainingTime = (time, isMobile) => {
  return isMobile
    ? moment(time).format(mssFormat)
    : `${Math.round(time / 1000)} seconds`
}

const normalizeTime = (time) => {
  const timeValue = time > 0 ? time : 0

  return `${timeValue >= 10 ? timeValue : '0' + timeValue}`
}

const getRemainingCycleTime = (endTime, txStatus, details) => {
  let finish

  // some broken transactions might not have estCompletion field; thus, we can not
  // be sure about the remaining cycle time.
  if (!details[FIELDS.estCompletion]) {
    return null
  }

  const txTime = moment
    .utc(details[FIELDS.estCompletion])
    .diff(moment.utc(details[FIELDS.cycleStart]))
  const interruptedTime = txTime - details[FIELDS.cycleTime]

  const useLastEventAsStart =
    txStatus === STATUS_FILTER_VALUES.paused ||
    txStatus === STATUS_FILTER_VALUES.cancelled

  const start = useLastEventAsStart
    ? moment.utc(details[FIELDS.lastEvent])
    : moment.utc()

  if (
    txStatus === STATUS_FILTER_VALUES.cancelled &&
    details[FIELDS.interruptionPeriod]
  ) {
    // details[FIELDS.interruptionPeriod] - represent full time in interruption
    // interruptedTime - represents time in interruption before the last 'cycle resume' event

    /*
            'cycle resume' shifts est.completion.time, but 'cycle cancel' doesn't.

            'cycle cancel' though increases details[FIELDS.interruptionPeriod] which means that
            we need to shift est.completion.time manually to get correct remaining time
        */
    finish = moment
      .utc(details[FIELDS.estCompletion])
      .add(details[FIELDS.interruptionPeriod] - interruptedTime, 'milliseconds')
  } else if (
    txStatus === STATUS_FILTER_VALUES.cancelled &&
    !details[FIELDS.interruptionPeriod]
  ) {
    return details[FIELDS.remaining]
  } else if (txStatus === STATUS_FILTER_VALUES.completed) {
    finish = start
  } else {
    finish = moment.utc(endTime)
  }

  return finish.diff(start)
}

const getRemainingCountdownTime = (startTime, countdownValue = 300000) => {
  const start = moment.utc(startTime)
  const now = moment.utc()

  if (now.diff(start) < countdownValue) {
    return countdownValue - now.diff(start)
  }

  return 0
}

const getTimeDifference = (interruptionPeriod, startTime, endTime) => {
  const start = moment.utc(startTime)
  const finish = endTime ? moment.utc(endTime) : moment.utc()

  const duration = moment
    .duration(finish.diff(start))
    .subtract(interruptionPeriod, 'milliseconds')

  return `${normalizeTime(duration.hours())}:${normalizeTime(
    duration.minutes()
  )}:${normalizeTime(duration.seconds())}`
}

const getActivityLabels = (activity) => {
  const isCountDown = activity.details[FIELDS.phase] === FIELDS.countdown

  if (activity.deviceType === DEVICE_TYPE.arc) {
    return isCountDown
      ? ACTIVITY_DETAILS_COUNTDOWN_LABELS
      : ACTIVITY_DETAILS_CYCLE_LABELS
  } else {
    return activity.txStatus === STATUS_FILTER_VALUES.in_progress
      ? ACTIVITY_DETAILS_RUN_TIME_LABELS
      : ACTIVITY_DETAILS_CYCLE_LABELS
  }
}

export {
  prepareActivitiesByGroups,
  getIsCircularProperty,
  getColor,
  getCircularValue,
  getTimeLabel,
  getTimeValue,
  getText,
  getIconName,
  activity,
  getRemainingTime,
  getTimeDifference,
  getActivityLabels,
  getRemainingCycleTime,
  getRemainingCountdownTime,
  findStartEvent,
  getCalculatedValue,
}
