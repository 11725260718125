import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ErrorHasOccurred from '../../components/_shared/errors/ErrorHasOccurred'
import NetworkError from '../../components/_shared/errors/NetworkError'
import NavigationActionsWrapper from './components/navigationBar/NavigationActionsWrapper'
import styles from './style'
import actions from './service/location-actions'
import NavigationBar from './components/navigationBar/NavigationBar'
import LocationCardsContainer from './LocationCardsContainer'
import Container from '@material-ui/core/Container'
import LoaderComponent from '../../components/_shared/loader/Loader'
import { LOCATIONS_DOCUMENT_TITLE } from './service/location-constants'
import { checkConnection } from '../../services/root/root-service'

const LocationContainer = (props) => {
  const {
    loadLocations,
    location,
    loadLocationsSuccess,
    loadLocationsFailed,
    setCurrentLocation,
    classes,
    history,
    isReadOnly,
    isLoading,
    loadLocationsError,
    networkError,
    getCurrentLocationSchema,
  } = props

  useEffect(() => {
    document.title = LOCATIONS_DOCUMENT_TITLE
    getCurrentLocationSchema()

    loadLocations(location.pathname, loadLocationsSuccess, loadLocationsFailed)
  }, [])

  useEffect(() => {
    setCurrentLocation(location.pathname)
  }, [location.pathname])

  const changeLocation = (pathname) => {
    history.push(pathname)
  }

  return (
    <LoaderComponent isLoading={isLoading && !networkError}>
      <div className={classes.locationsPage}>
        {networkError ? (
          <NetworkError />
        ) : (
          <Container maxWidth="md" disableGutters className={classes.card}>
            <div className={classes.buttonAndNavigationBar}>
              <NavigationBar
                changeLocation={changeLocation}
                isReadOnly={isReadOnly}
              />
              <NavigationActionsWrapper changeLocation={changeLocation} />
            </div>
            {loadLocationsError ? (
              <ErrorHasOccurred hideLogo={true} />
            ) : (
              <LocationCardsContainer
                history={history}
                isReadOnly={isReadOnly}
                pathname={location.pathname}
              />
            )}
          </Container>
        )}
      </div>
    </LoaderComponent>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentLocation: (pathname) =>
      dispatch(checkConnection(() => actions.setCurrentLocation(pathname))),
    loadLocations: (pathname, onSuccess, onError) =>
      dispatch(
        checkConnection(() =>
          actions.loadLocations(pathname, onSuccess, onError)
        )
      ),
    loadLocationsSuccess: (data) =>
      dispatch(actions.loadLocationsSuccess(data)),
    loadLocationsFailed: (message) =>
      dispatch(actions.loadLocationsFailed(message)),
    getCurrentLocationSchema: () =>
      dispatch(checkConnection(actions.getCurrentLocationSchema)),
  }
}

const mapStateToProps = (state) => ({
  isReadOnly: state.rootReducer.isOperatorView,
  routeParams: state.locationReducer.currentRouteParams,
  isLoading: state.locationReducer.isLoading,
  loadLocationsError: state.locationReducer.loadLocationsError,
  networkError: state.rootReducer.networkError,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LocationContainer))
