import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  treeNodeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: COLORS.WARM_GRAY,
    marginBottom: '5px',
    height: '32px',
  },
  singleSelectionNode: {
    height: '51px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  nodesWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    maxHeight: 'calc(100vh - 380px)',
    minHeight: '200px',
    display: 'block',
  },
  treeLabel: {
    fontSize: '11px',
  },
  singleSelectionTreeLabel: {
    fontSize: '14px',
  },
  singleSelectionTreeLabelSelectable: {
    fontSize: '14px',
    marginLeft: '22px',
  },
  selectAllLabel: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  treeCheckbox: {
    '& svg': {
      width: '14px',
      height: '14px',
    },
  },
  singleSelectionTreeCheckbox: {
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  arrowColumn: {
    minWidth: '25px',
    height: '32px',
    borderLeft: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    '& button': {
      padding: '4px 0',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: COLORS.BLACK,
    },
  },
  divider: {
    margin: '12px 0',
  },
  '@media (min-width: 1224px)': {
    treeLabel: {
      fontSize: '16px',
      fontFamily: 'Cerebri Sans Pro Medium',
    },
    treeCheckbox: {
      paddingLeft: '24px',
      paddingRight: '10px',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    selectAllLabel: {
      fontFamily: 'Cerebri Sans Pro Regular',
      fontSize: 'initial',
    },
    treeNodeContainer: {
      height: '61px',
    },
    arrowColumn: {
      minWidth: '25px',
      height: '61px',
      borderLeft: 'none',
      '& button': {
        padding: '20px 19px',
        color: COLORS.SHUTTLE_GRAY,
      },
    },
    divider: {
      margin: '12px -24px',
    },
    singleSelectionTreeCheckbox: {
      marginLeft: '10px',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    singleSelectionTreeLabel: {
      fontFamily: 'Cerebri Sans Pro Medium',
      fontSize: '16px',
    },
    singleSelectionTreeLabelSelectable: {
      fontFamily: 'Cerebri Sans Pro Medium',
      fontSize: '16px',
    },
  },
})

export default styles
