import { MuiThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { withMediaQuery } from '../../../services/root/root-service'
import paginationThemeDesktop from './paginationThemeDesktop'
import paginationThemeMobile from './paginationThemeMobile'
import styles from './style'
import { withStyles } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { ceil } from 'lodash'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { MORE_LABEL } from '../../../containers/Dashboard/services/dashboard-constants'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const ListPaginationComponent = (props) => {
  const {
    isMobile,
    page,
    totalCount,
    rowsPerPage,
    goToNextPage,
    stopPollingModification,
    enableQuickCount,
    maxPage,
    classes,
  } = props
  let pageCount = totalCount >= rowsPerPage ? ceil(totalCount / rowsPerPage) : 1

  if (enableQuickCount) {
    pageCount = maxPage > pageCount ? maxPage : pageCount
  }

  const onChange = (event, value) => {
    if (page !== value) {
      stopPollingModification && stopPollingModification()
      goToNextPage(value)
    }
  }

  const clickMore = () => {
    stopPollingModification && stopPollingModification()
    goToNextPage(page + 1)
  }

  return (
    <MuiThemeProvider
      theme={isMobile ? paginationThemeMobile : paginationThemeDesktop}
    >
      <Box display="flex" alignItems="center">
        <Pagination
          count={pageCount}
          variant="outlined"
          color="primary"
          size={isMobile ? 'small' : 'medium'}
          page={page}
          onChange={onChange}
          className={enableQuickCount ? classes.pagination : ''}
        />
        {enableQuickCount && (
          <Button onClick={clickMore} className={classes.moreButton}>
            <span>{MORE_LABEL}</span>
            <ChevronRightIcon className={classes.moreIcon} />
          </Button>
        )}
      </Box>
    </MuiThemeProvider>
  )
}

export default withStyles(styles)(withMediaQuery(ListPaginationComponent))
