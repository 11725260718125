import * as sharedActions from 'containers/Devices/_shared/services/devices-actions'
import { getSelectedIds } from '../../../../../services/uvgi-service'
import { prepareLocationFiltersForDevice } from '../../../_shared/services/devices-service'
import uvgiDeviceDetailsActions from './uvgiDeviceDetails-actions'
import { isEmpty } from 'lodash'
import { initialStateUVGI } from './uvgiDeviceDetails-constants'
import authorizationActions from '../../../../LogIn/services/authorization-actions'

export const UVGIDeviceDetailsReducer = (state = initialStateUVGI, action) => {
  switch (action.type) {
    case uvgiDeviceDetailsActions.actionTypes.RESET_DEVICE_DETAILS: {
      return {
        ...initialStateUVGI,
      }
    }
    case uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATUS:
    case uvgiDeviceDetailsActions.actionTypes.LOAD_ALLOWED_LOCATIONS_FOR_DEVICE:
      return {
        ...state,
        isLoading: state.isInitialDetailsState,
      }
    case uvgiDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS_FAILED:
    case uvgiDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_FAILED:
    case uvgiDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE:
    case uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATUS_FAILED:
    case uvgiDeviceDetailsActions.actionTypes.DELETE_DEVICE_FAILED:
    case uvgiDeviceDetailsActions.actionTypes.UPDATE_DEVICE_FAILED:
    case uvgiDeviceDetailsActions.actionTypes.SET_MAINTENANCE_REMINDER_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isInitialDetailsState: false,
        isLoading: false,
      }
    }
    case uvgiDeviceDetailsActions.actionTypes.UPDATE_DEVICE_SUCCEEDED:
      const { nickname, locationIds } = action.payload

      return {
        ...state,
        nickname,
        allowedLocationIds: locationIds,
      }
    case uvgiDeviceDetailsActions.actionTypes.APPLY_LOCATION_FILTER: {
      const { nodes } = action.payload
      const params = prepareLocationFiltersForDevice(nodes)
      const ids = getSelectedIds(nodes, params.selectedBuildings)

      return {
        ...state,
        locationFilter: params,
        ids,
        isLoading: false,
        needToUpdateData: true,
      }
    }
    case uvgiDeviceDetailsActions.actionTypes.PUT_DEVICE_DETAILS: {
      const { device } = action.payload
      let deviceSerialNumber =
        device && device.deviceSerialNumber
          ? device.deviceSerialNumber
          : state.serialNumber

      return {
        ...state,
        deviceDetails: device || initialStateUVGI.deviceDetails,
        serialNumber: deviceSerialNumber,
        needToUpdateData: false,
      }
    }
    case uvgiDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_SUCCEEDED: {
      const {
        deviceInfo: { allowedLocationIds, nickname, serialNumber, id, mode },
      } = action.payload
      const deviceSerialNumber = serialNumber
        ? serialNumber
        : state.serialNumber

      return {
        ...state,
        allowedLocationIds,
        nickname: nickname || initialStateUVGI.nickname,
        deviceId: id,
        serialNumber: deviceSerialNumber,
        deviceLinked: !isEmpty(action.payload.deviceInfo),
        mode,
        needToUpdateData: true,
      }
    }
    case uvgiDeviceDetailsActions.actionTypes.SETUP_LOCATIONS_FILTER:
      return {
        ...state,
        locationFilter: action.payload.nodes,
        locationFilterError: '',
      }
    case uvgiDeviceDetailsActions.actionTypes.RESET_ROOMS_FILTER:
      return {
        ...state,
        roomsFilter: {},
      }
    case uvgiDeviceDetailsActions.actionTypes.SETUP_ROOMS_FILTER:
      return {
        ...state,
        roomsFilter: action.payload.nodes,
        locationFilterError: '',
      }
    case uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATUS_SUCCEEDED:
      return {
        ...state,
        deviceDetailsStatus: action.payload.data,
        locationFilterError: '',
        isInitialDetailsState: false,
        isLoading: false,
      }
    case uvgiDeviceDetailsActions.actionTypes.SET_MAINTENANCE_REMINDER_SUCCESS:
      return {
        ...state,
        deviceDetailsStatus: {
          ...state.deviceDetailsStatus,
          reminder: false,
        },
      }
    case uvgiDeviceDetailsActions.actionTypes.DELETE_DEVICE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      }
    case uvgiDeviceDetailsActions.actionTypes.RESET_ERROR: {
      return {
        ...state,
        error: '',
      }
    }
    case uvgiDeviceDetailsActions.actionTypes.RESET_CHANGE_DEVICE_FORM: {
      return {
        ...state,
        allowedLocationIds: undefined,
        nickname: initialStateUVGI.nickname,
      }
    }
    case uvgiDeviceDetailsActions.actionTypes.RESET_UPDATE_ERROR:
      return {
        ...state,
        updateError: initialStateUVGI.updateError,
      }
    case uvgiDeviceDetailsActions.actionTypes.RESET_INITIAL_DETAILS_STATE:
      return {
        ...state,
        isInitialDetailsState: true,
      }
    case uvgiDeviceDetailsActions.actionTypes.START_POLLING_MODIFICATION:
    case uvgiDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS:
      return {
        ...state,
        polling: true,
      }
    case uvgiDeviceDetailsActions.actionTypes.STOP_POLLING_MODIFICATION:
      return {
        ...state,
        polling: false,
      }
    case authorizationActions.actionTypes.LOGOUT_SUCCEEDED:
      return {
        ...state,
        ...initialStateUVGI,
      }
    case uvgiDeviceDetailsActions.actionTypes.POLL_MODIFICATION_SUCCEEDED:
      return {
        ...state,
        lastModification: action.payload.lastModification,
      }
    case uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS_SUCCEEDED:
      const { data } = action.payload

      return {
        ...state,
        isLoading: false,
        workedMilliseconds: data['cycles.completed.time.total'],
        bulbMaxTime: data.model.device.bulb.lifecycleInHours,
      }
    case uvgiDeviceDetailsActions.actionTypes
      .LOAD_LOCATIONS_FOR_DEVICE_SUCCEEDED:
      return {
        ...state,
        needToUpdateData: true,
      }
    case sharedActions.CUMULATIVE_USAGE_SUCCEEDED.getType(): {
      const { cumulativeUsageInSecs } = action.payload
      return {
        ...state,
        cumulativeUsageInSecs,
      }
    }
    default:
      return state
  }
}
