import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import style from './style'
import { LOCATIONS_TABLE } from '../../../ARC/service/arcSummary-constants'
import LocationsTable from './LocaitonsTable'

const LocationsTableContainer = (props) => {
  const {
    locations,
    dateFilter,
    defaultPeriod,
    timezone,
    title,
    isToday,
    classes,
  } = props

  return (
    <Accordion defaultExpanded className={classes.container}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        classes={{ content: classes.accordionSummary }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.subtitle}
        >
          {LOCATIONS_TABLE.subTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <LocationsTable
          locations={locations}
          dateFilter={dateFilter}
          defaultPeriod={defaultPeriod}
          isToday={isToday}
          timezone={timezone}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default withStyles(style)(LocationsTableContainer)
