import { unionBy, remove } from 'lodash'
import accountActions from './account-actions'

export const accountReducer = (
  state = {
    organizationInfo: {},
    timezones: false,
    userInfo: {},
    getOrganizationInfoError: '',
    getUserInfoError: '',
    updateAccountInfoError: '',
    resendEmailError: '',
    setPasswordError: '',
    isLoading: false,
    owners: [],
    isOwnersLoaded: false,
    isInitialLoading: true,
    timezoneError: '',
    apiKeysList: [],
    apiKeysCount: 0,
    apiKeyError: '',
    idpName: '',
  },
  action
) => {
  switch (action.type) {
    case accountActions.actionTypes.GENERATE_NEW_ENTRY:
    case accountActions.actionTypes.GET_API_KEY_PAGE_ACCESS:
    case accountActions.actionTypes.GET_ORGANIZATION_INFO:
    case accountActions.actionTypes.GET_PROPERTIES:
    case accountActions.actionTypes.CHECK_ORGANIZATION_INFO:
    case accountActions.actionTypes.UPDATE_ACCOUNT_INFO:
    case accountActions.actionTypes.GET_TIMEZONES_BY_ZIP:
      return {
        ...state,
        isLoading: !state.isInitialLoading,
      }
    case accountActions.actionTypes.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      }
    case accountActions.actionTypes.GET_ORGANIZATION_INFO_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        organizationInfo: data,
        getOrganizationInfoError: false,
      }
    }
    case accountActions.actionTypes.GET_ORGANIZATION_INFO_FAILED: {
      const { message } = action

      return {
        ...state,
        getOrganizationInfoError: message,
        isLoading: false,
      }
    }
    case accountActions.actionTypes.GET_USER_INFO_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        userInfo: data,
        getUserInfoError: false,
        isInitialLoading: false,
      }
    }
    case accountActions.actionTypes.GET_USER_INFO_FAILED: {
      const { message } = action.payload

      return {
        ...state,
        getUserInfoError: message,
        isInitialLoading: false,
        isLoading: false,
      }
    }
    case accountActions.actionTypes.UPDATE_ACCOUNT_INFO_SUCCEEDED: {
      const { organization } = action.payload.data
      const userInfo = action.payload.data
      delete userInfo['organization']

      return {
        ...state,
        organizationInfo: organization,
        userInfo,
        updateAccountInfoError: false,
      }
    }
    case accountActions.actionTypes.UPDATE_ACCOUNT_INFO_FAILED:
    case accountActions.actionTypes.CHECK_ORGANIZATION_INFO_FAILED: {
      const { message } = action.payload

      return {
        ...state,
        updateAccountInfoError: message,
        isLoading: false,
      }
    }
    case accountActions.actionTypes.RESEND_EMAIL_SUCCEEDED:
      return {
        ...state,
        resendEmailError: false,
      }
    case accountActions.actionTypes.RESEND_EMAIL_FAILED:
      return {
        ...state,
        resendEmailError: action.payload.message,
        isLoading: false,
      }
    case accountActions.actionTypes.RESET_ACTION_EMAIL_ERROR:
      return {
        ...state,
        resendEmailError: '',
      }
    case accountActions.actionTypes.SET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        setPasswordError: false,
      }
    case accountActions.actionTypes.SET_PASSWORD_FAILED:
      return {
        ...state,
        setPasswordError: action.payload.message,
        isLoading: false,
      }
    case accountActions.actionTypes.RESET_UPDATE_ERRORS:
      return {
        ...state,
        updateAccountInfoError: '',
      }
    case accountActions.actionTypes.RESET_INITIAL_LOADING:
      return {
        ...state,
        isInitialLoading: true,
      }
    case accountActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS:
      return {
        ...state,
        isLoading: true,
      }
    case accountActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS_SUCCEEDED:
      return {
        ...state,
        owners: action.payload.owners,
        isOwnersLoaded: true,
        isLoading: false,
      }
    case accountActions.actionTypes.CHANGE_ACCOUNT_OWNER_FAILED:
    case accountActions.actionTypes.GET_IDP_DATA_FAILED:
    case accountActions.actionTypes.GET_PROPERTIES_FAILED:
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    case accountActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS_FAILED:
      return {
        ...state,
        error: action.payload.message,
        isOwnersLoaded: false,
        isLoading: false,
      }
    case accountActions.actionTypes.GET_TIMEZONES_BY_ZIP_SUCCEEDED:
      return {
        ...state,
        timezones: action.payload.timezones,
        updateAccountInfoError: '',
      }
    case accountActions.actionTypes.GET_TIMEZONES_BY_ZIP_FAILED:
      return {
        ...state,
        updateAccountInfoError: action.payload.message,
        isLoading: false,
      }
    case accountActions.actionTypes.CHECK_ORGANIZATION_INFO_SUCCEEDED:
      return {
        ...state,
      }
    case accountActions.actionTypes.GET_PROPERTIES_SUCCEEDED:
      const { properties } = action.payload

      return {
        ...state,
        propertiesNames: properties,
      }
    case accountActions.actionTypes.GENERATE_NEW_ENTRY_FAILED:
    case accountActions.actionTypes.GET_API_KEY_PAGE_ACCESS_FAILED:
      return {
        ...state,
        apiKeyError: action.payload.message,
        isLoading: false,
      }
    case accountActions.actionTypes.GET_API_KEY_PAGE_ACCESS_SUCCEEDED:
      const { APIKeyPageAccess } = action.payload

      return {
        ...state,
        apiKeyError: '',
        apiKeysList: APIKeyPageAccess.items,
        apiKeysCount: APIKeyPageAccess.totalCount,
        isLoading: false,
      }
    case accountActions.actionTypes.GENERATE_NEW_ENTRY_SUCCEEDED: {
      const { APIKey } = action.payload
      const updatedList = unionBy([APIKey], state.apiKeysList, 'id')

      return {
        ...state,
        apiKeyError: '',
        apiKeysList: updatedList,
        apiKeysCount: state.totalCount + 1,
        isLoading: false,
      }
    }
    case accountActions.actionTypes.REGENERATE_NEW_ENTRY_SUCCEEDED: {
      const { id } = action.payload
      const updatedList = remove(state.apiKeysList, (item) => {
        return item.id !== id
      })

      return {
        ...state,
        apiKeyError: '',
        apiKeysList: updatedList,
        apiKeysCount: state.totalCount + 1,
        isLoading: false,
      }
    }
    case accountActions.actionTypes.GET_IDP_DATA_SUCCEEDED: {
      return {
        ...state,
        idpName: action.payload.data.name || '',
      }
    }
    default:
      return state
  }
}
