import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { map, uniqueId } from 'lodash'
import { HEADER_LABELS } from '../../services/extDeviceList-constants'
import IconButton from '@material-ui/core/IconButton'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { withStyles } from '@material-ui/core/styles/index'
import style from './style'
import { DEVICE_TYPES } from '../../../../../../services/constants'

const ExternalDevicesTable = (props) => {
  const { devices, history, classes } = props

  const goToDetails = (event) => {
    history.push(`/devices/${DEVICE_TYPES.ext.path}/${event.currentTarget.id}`)
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {map(HEADER_LABELS, (value, key) => (
              <TableCell key={key}>{value}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(devices, (device) => (
            <TableRow key={device.id}>
              {map(HEADER_LABELS, (value, key) => (
                <TableCell key={uniqueId()}>
                  {device[key] || (
                    <IconButton onClick={goToDetails} id={device.id}>
                      <ArrowForwardIosIcon className={classes.icon} />
                    </IconButton>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default withStyles(style)(ExternalDevicesTable)
