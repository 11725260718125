const styles = (theme) => ({
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
  },
  filtersChips: {
    marginBottom: '10px',
    width: 'fit-content',
  },
  filtersChipsModal: {
    marginRight: '10px',
    marginBottom: '10px',
    width: 'fit-content',
    paddingLeft: '7px',
    paddingRight: '7px',
  },
  '@media (min-width: 1224px)': {
    filtersContainer: {
      justifyContent: 'center',
    },
  },
})

export default styles
