import { COLORS } from '../../../../../../../services/constants'

const styles = () => ({
  deviceStatusLabel: {
    marginTop: '4px',
  },
  deviceStatus: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
  },
  deviceStatusContainer: {
    marginTop: '30px',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  warningDisconnected: {
    justifyContent: 'center',
    display: 'flex',
    '& > div': {
      width: '50%',
    },
    marginBottom: '1rem',
  },
  warning: {
    color: '#E28C36',
  },
  warningAsterisk: {
    color: '#E28C36',
    fontSize: 32,
    fontFamily: 'Calabri Sans Pro Medium',
  },
  runTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '48%',
    height: '75px',
    backgroundColor: COLORS.WARM_GRAY,
  },
  timeContainer: {
    fontFamily: 'Cerebri Sans Pro Medium',
    fontSize: '22px',
    margin: '16px 0 0 12px',
  },
  label: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '12px',
    marginLeft: '12px',
  },
  '@media (min-width: 1224px)': {
    deviceStatusLabel: {
      marginTop: 0,
      fontSize: '16px',
    },
    deviceStatus: {
      marginTop: 0,
      fontSize: '20px',
    },
    deviceStatusContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    mainContainer: {
      justifyContent: 'center',
      padding: '0 0 20px 0',
      '& > div:first-child': {
        marginRight: '20px',
      },
    },
    runTimeContainer: {
      width: '250px',
    },
    label: {
      fontSize: '12px',
    },
    timeContainer: {
      fontSize: '32px',
      margin: '12px 12px 0 12px',
    },
  },
})

export default styles
