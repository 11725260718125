import { withStyles } from '@material-ui/core/styles'
import { convertSecondsToRunTime } from '../../../services/uvgiDeviceDetails-service'
import styles from './style'
import { withMediaQuery } from '../../../../../../../services/root/root-service'

type RunTimeProps = {
  time: string
  label: string
  classes: any
  approx: boolean
}

const RunTime = ({ time, label, classes, approx = false }: RunTimeProps) => {
  return (
    <div className={classes.runTimeContainer}>
      <div className={classes.timeContainer}>
        {time !== '' ? convertSecondsToRunTime(time) : '--'}
        {time !== '' && approx && <span className={classes.warning}>*</span>}
      </div>

      <span className={classes.label}>{label}</span>
    </div>
  )
}

export default withMediaQuery(withStyles(styles as any)(RunTime))
