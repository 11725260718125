import { map } from 'lodash'
import React from 'react'
import { Paper, Tab, Tabs } from '@material-ui/core'
import { DEVICE_TYPE_MAPPER } from '../../../services/constants'
import { withMediaQuery } from '../../../services/root/root-service'
import { withStyles } from '@material-ui/core/styles'
import tabsWrapperStyle from './tabsWrapperStyle'

const TabsForDevices = withStyles(tabsWrapperStyle)((props) => {
  const { value, handleChange, devicesTypes, classes } = props

  return (
    <Paper square className={classes.tabsWrapper} elevation={0}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        {map(devicesTypes, (device) => (
          <Tab
            key={device.id}
            value={device.index}
            label={DEVICE_TYPE_MAPPER[device.index]}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </Paper>
  )
})

export default withMediaQuery(TabsForDevices)
