const uvgiDeviceDetailsActions = {}

uvgiDeviceDetailsActions.actionTypes = {
  START_POLLING_MODIFICATION: '{UVGI Device Details} Load Last Modification',
  POLL_MODIFICATION_SUCCEEDED:
    '{UVGI Device Details} Load Last Modification Succeeded',
  POLL_MODIFICATION_FAILED:
    '{UVGI Device Details} Load Last Modification Failed',

  STOP_POLLING_MODIFICATION:
    '{UVGI Device Details} Stop Load Last Modification',

  PUT_DEVICE_DETAILS: '{UVGI Device Details} Put Device Details',

  LOAD_DEVICE_DETAILS: '{UVGI Device Details} Load Device Details',
  LOAD_DEVICE_DETAILS_FAILED:
    '{UVGI Device Details} Load Device Details Failed',

  LOAD_ALLOWED_LOCATIONS_FOR_DEVICE:
    '{UVGI Device Details} Load allowed locations',
  LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_SUCCEEDED:
    '{UVGI Device Details} Load allowed locations succeeded',
  LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_FAILED:
    '{UVGI Device Details} Load allowed locations failed',

  LOAD_LOCATIONS_FOR_DEVICE: '{UVGI Device Details} Load locations list',
  LOAD_LOCATIONS_FOR_DEVICE_SUCCEEDED:
    '{UVGI Device Details} Load locations list succeeded',
  LOAD_LOCATIONS_FOR_DEVICE_FAILED:
    '{UVGI Device Details} Load locations list Failed',

  UPDATE_DEVICE: '{UVGI Device Details} Update Device',
  UPDATE_DEVICE_SUCCEEDED: '{UVGI Device Details} Update Device Succeeded',
  UPDATE_DEVICE_FAILED: '{UVGI Device Details} Update Device Failed',

  RESET_CHANGE_DEVICE_FORM: '{UVGI Device Details} Change Device Form',

  SETUP_LOCATIONS_FILTER: '{UVGI Device Details} Setup locations Filter',
  SETUP_LOCATIONS_FILTER_FAILED:
    '{UVGI Device Details} Setup locations Filter Failed',

  SETUP_ROOMS_FILTER: '{UVGI Device Details} Setup rooms Filter',
  RESET_ROOMS_FILTER: '{UVGI Device Details} Reset rooms Filter',
  SETUP_ROOMS_FILTER_FAILED: '{UVGI Device Details} Setup rooms Filter Failed',

  GET_DEVICE_STATUS: '{UVGI Device Details} Get Device Status',
  GET_DEVICE_STATUS_SUCCEEDED:
    '{UVGI DeviceDetails} Get Device Status Succeeded',
  GET_DEVICE_STATUS_FAILED: '{UVGI Device Details} Get Device Status Failed',

  DELETE_DEVICE: '{UVGI Device Details} Delete Device',
  DELETE_DEVICE_SUCCEEDED: '{UVGI Device Details} Delete Device Succeeded',
  DELETE_DEVICE_FAILED: '{UVGI Device Details} Delete Device Failed',

  RESET_ERROR: '{UVGI Device Details} Reset Delete Error',
  RESET_UPDATE_ERROR: '{UVGI Device Details} Reset Update Error',

  RESET_INITIAL_DETAILS_STATE:
    '{UVGI Device Details} Reset Initial Details State',

  SET_MAINTENANCE_REMINDER: '{UVGI Device Details} Set Maintenance Reminder',
  SET_MAINTENANCE_REMINDER_SUCCESS:
    '{UVGI Device Details} Set Maintenance Reminder Success',
  SET_MAINTENANCE_REMINDER_FAILED:
    '{UVGI Device Details} Set Maintenance Reminder Failed',

  GET_DEVICE_STATISTICS: '{UVGI Device Details} Get Device Statistics',
  GET_DEVICE_STATISTICS_SUCCEEDED:
    '{UVGI Device Details} Get Device Statistics Succeeded',
  GET_DEVICE_STATISTICS_FAILED:
    '{UVGI Device Details} Get Device Statistics Failed',

  RESET_DEVICE_DETAILS: '{UVGI Device Details} Set need to update data',
}

uvgiDeviceDetailsActions.startPollingModification = (id, needToPoll) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.START_POLLING_MODIFICATION,
    payload: {
      id,
      needToPoll,
    },
  }
}

uvgiDeviceDetailsActions.resetDeviceDetails = () => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.RESET_DEVICE_DETAILS,
  }
}

uvgiDeviceDetailsActions.pollModificationSucceeded = (lastModification) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.POLL_MODIFICATION_SUCCEEDED,
    payload: {
      lastModification,
    },
  }
}

uvgiDeviceDetailsActions.pollModificationFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.POLL_MODIFICATION_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.stopPollingModification = () => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.STOP_POLLING_MODIFICATION,
  }
}

uvgiDeviceDetailsActions.getDeviceStatistics = (deviceId) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS,
    payload: {
      deviceId,
    },
  }
}

uvgiDeviceDetailsActions.getDeviceStatisticsSuccess = (data) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS_SUCCEEDED,
    payload: {
      data,
    },
  }
}

uvgiDeviceDetailsActions.getDeviceStatisticsFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.putDeviceDetails = (deviceId, device) => ({
  type: uvgiDeviceDetailsActions.actionTypes.PUT_DEVICE_DETAILS,
  payload: {
    deviceId,
    device,
  },
})

uvgiDeviceDetailsActions.loadDeviceDetails = (id, needToPoll) => ({
  type: uvgiDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS,
  payload: {
    id,
    needToPoll,
  },
})

uvgiDeviceDetailsActions.loadDeviceDetailsFailed = (message) => ({
  type: uvgiDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS_FAILED,
  payload: {
    message,
  },
})

uvgiDeviceDetailsActions.loadAllowedLocationsForDevice = (deviceId) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE,
    payload: {
      deviceId,
    },
  }
}

uvgiDeviceDetailsActions.loadAllowedLocationsForDeviceSuccess = (
  deviceInfo = {},
  deviceId
) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_SUCCEEDED,
    payload: {
      deviceInfo,
      deviceId,
    },
  }
}

uvgiDeviceDetailsActions.loadAllowedLocationsForDeviceFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes
      .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.loadLocationsForDevice = (onSuccess) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE,
    payload: {
      onSuccess,
    },
  }
}

uvgiDeviceDetailsActions.loadLocationsForAddDeviceModal = function (onSuccess) {
  return {
    type: uvgiDeviceDetailsActions.actionTypes
      .LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL,
    payload: {
      onSuccess,
    },
  }
}

uvgiDeviceDetailsActions.loadLocationsForDeviceSuccess = () => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes
      .LOAD_LOCATIONS_FOR_DEVICE_SUCCEEDED,
  }
}

uvgiDeviceDetailsActions.loadLocationsForDeviceFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.updateDevice = (serialNumber, body, onSuccess) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.UPDATE_DEVICE,
    payload: {
      serialNumber,
      body,
      onSuccess,
    },
  }
}

uvgiDeviceDetailsActions.updateDeviceSuccess = (locationIds, nickname) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.UPDATE_DEVICE_SUCCEEDED,
    payload: {
      locationIds,
      nickname,
    },
  }
}

uvgiDeviceDetailsActions.updateDeviceFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.UPDATE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.resetChangeDeviceForm = () => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.RESET_CHANGE_DEVICE_FORM,
  }
}

uvgiDeviceDetailsActions.getDeviceStatus = (deviceId) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATUS,
    payload: {
      deviceId,
    },
  }
}

uvgiDeviceDetailsActions.getDeviceStatusSuccess = (data) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATUS_SUCCEEDED,
    payload: {
      data,
    },
  }
}

uvgiDeviceDetailsActions.getDeviceStatusFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATUS_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.setupLocationsFilter = (nodes, tree, userId) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.SETUP_LOCATIONS_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

uvgiDeviceDetailsActions.setupLocationsFilterFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.SETUP_LOCATIONS_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.setupRoomsFilter = (nodes, tree, userId) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.SETUP_ROOMS_FILTER,
    payload: {
      nodes,
      tree,
      userId,
    },
  }
}

uvgiDeviceDetailsActions.resetRoomsFilter = () => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.RESET_ROOMS_FILTER,
  }
}

uvgiDeviceDetailsActions.setupRoomsFilterFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.SETUP_ROOMS_FILTER_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.deleteDevice = (serialNumber, onClose) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.DELETE_DEVICE,
    payload: {
      serialNumber,
      onClose,
    },
  }
}

uvgiDeviceDetailsActions.deleteDeviceSuccess = () => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.DELETE_DEVICE_SUCCEEDED,
  }
}

uvgiDeviceDetailsActions.deleteDeviceFailed = (message) => {
  return {
    type: uvgiDeviceDetailsActions.actionTypes.DELETE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

uvgiDeviceDetailsActions.resetError = () => ({
  type: uvgiDeviceDetailsActions.actionTypes.RESET_ERROR,
})

uvgiDeviceDetailsActions.resetUpdateError = () => ({
  type: uvgiDeviceDetailsActions.actionTypes.RESET_UPDATE_ERROR,
})

uvgiDeviceDetailsActions.resetInitialDetailsState = () => ({
  type: uvgiDeviceDetailsActions.actionTypes.RESET_INITIAL_DETAILS_STATE,
})

uvgiDeviceDetailsActions.setMaintenanceReminder = (deviceId) => ({
  type: uvgiDeviceDetailsActions.actionTypes.SET_MAINTENANCE_REMINDER,
  payload: {
    deviceId,
  },
})

uvgiDeviceDetailsActions.setMaintenanceReminderSuccess = () => ({
  type: uvgiDeviceDetailsActions.actionTypes.SET_MAINTENANCE_REMINDER_SUCCESS,
})

uvgiDeviceDetailsActions.setMaintenanceReminderFailed = (message) => ({
  type: uvgiDeviceDetailsActions.actionTypes.SET_MAINTENANCE_REMINDER_FAILED,
  payload: {
    message,
  },
})

export default uvgiDeviceDetailsActions
