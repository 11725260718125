import React, { useState } from 'react'
import { connect } from 'react-redux'
import { forIn, mapValues } from 'lodash'
import Chip from '@material-ui/core/Chip'
import DateFilterModal from '../../../components/_shared/modals/dateFilter/DateFilterModal'
import actions from '../services/dashboard-actions'
import { DATE_FILTER } from '../../../services/constants'
import { gaEventAction } from '../../../services/googleAnalytics/ga-actions'

const DateFilter = ({
  dateFilter,
  changeSelectedChip,
  period,
  startPollingModification,
  stopPollingModification,
  label,
  timezone,
  isInitialState,
  gaEventSend,
}) => {
  const [open, setOpen] = useState(false)
  const [filter, setDateFilter] = useState({ dateFilter, period })
  const activeFilter = getActiveDataFilter()

  function getActiveDataFilter() {
    let name = DATE_FILTER.week

    forIn(filter.dateFilter, (item, key) => {
      if (item) {
        name = DATE_FILTER[key]
      }
    })

    return name
  }

  const handleClickOpen = () => {
    if (!isInitialState) {
      setDateFilter({ dateFilter, period })
      setOpen(true)

      stopPollingModification()
    }
  }

  const handleClose = () => {
    startPollingModification()
    setOpen(false)
  }

  const changeSelected = (name, startDate, endDate) => {
    const dateFilter = mapValues(filter.dateFilter, (item, key) => {
      return name === key
    })
    const period = { startDate, endDate }

    setDateFilter({ dateFilter, period })
  }

  const onApply = () => {
    changeSelectedChip(filter, activeFilter)

    handleClose()
    gaEventSend('date_filter', { event_label: activeFilter })
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        label={'Range: ' + label}
        variant="outlined"
      />
      <DateFilterModal
        open={open}
        timezone={timezone}
        period={period}
        dateFilter={filter.dateFilter}
        onChange={changeSelected}
        onClose={handleClose}
        onSubmit={onApply}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  dateFilter: state.dashboardReducer.dateFilter,
  label: state.dashboardReducer.dateFilterLabel,
  period: state.dashboardReducer.period,
  timezone: state.rootReducer.timezone,
  isInitialState: state.dashboardReducer.isInitialState,
})

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedChip: (data, label) =>
      dispatch(actions.changeDateFilter(data, label)),
    startPollingModification: () =>
      dispatch(actions.startPollingModification()),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    gaEventSend: gaEventAction(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)
