import React from 'react'
import { connect } from 'react-redux'
import { map, includes, keys, values } from 'lodash'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core'
import styles from './style'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import Typography from '@material-ui/core/Typography'
import { withMediaQuery } from '../../../../services/root/root-service'
import { DEVICE_LABELS } from '../../services/dashboard-constants'
import { filterDevicesByType } from '../../services/related/filter-service'

const DevicesListComponent = (props) => {
  const {
    selected,
    setSelected,
    isAllSelected,
    setAllSelected,
    includeDeleted,
    setIncludeDeleted,
    devices,
    isMobile,
    devicesTypesFilter,
    filteredDevices,
    classes,
  } = props

  const onSelectAll = () => {
    const newValue = !isAllSelected

    setAllSelected(newValue)

    if (newValue) {
      setSelected(map(filteredDevices, 'id'))
    } else {
      setSelected([])
    }
  }

  const onCheckRow = (event) => {
    const { id } = event.target
    const selectedCopy = [...selected]

    if (includes(selected, +id)) {
      const index = selectedCopy.indexOf(+id)

      selectedCopy.splice(index, 1)
      setSelected(selectedCopy)

      if (isAllSelected) {
        setAllSelected(false)
      }
    } else {
      setSelected([...selectedCopy, +id])

      if (filteredDevices.length === selectedCopy.length + 1) {
        setAllSelected(true)
      }
    }
  }

  const checkingDeviceFiltering = () => {
    if (
      keys(devicesTypesFilter).length === 1 &&
      values(devicesTypesFilter)[0]
    ) {
      return devices
    }

    return filterDevicesByType(devices, devicesTypesFilter)
  }

  const renderList = () => {
    const devicesList = checkingDeviceFiltering()

    return map(devicesList, (device) => {
      const checked = includes(selected, device.id)

      return (
        <div key={device.id} className={classes.deviceContainer}>
          <Checkbox
            className={classes.checkbox}
            checkedIcon={<CheckBoxOutlinedIcon />}
            checked={checked}
            color="primary"
            id={device.id.toString()}
            onChange={onCheckRow}
          />
          <Typography
            className={classes.treeLabel}
            color={checked ? 'primary' : 'inherit'}
            variant={isMobile ? 'subtitle2' : 'subtitle1'}
          >
            {`${device.nickname ? `${device.nickname} - ` : ''}${
              device.serialNumber
            }`}
          </Typography>
        </div>
      )
    })
  }

  const onIncludeDeleted = () => {
    setIncludeDeleted(!includeDeleted)
  }

  return (
    <>
      <div className={classes.selectAll}>
        <Checkbox
          className={classes.checkbox}
          checkedIcon={<CheckBoxOutlinedIcon />}
          checked={includeDeleted}
          color={includeDeleted ? 'primary' : 'default'}
          onChange={onIncludeDeleted}
        />
        <Typography
          className={classes.selectAllLabel}
          color={includeDeleted ? 'primary' : 'inherit'}
          variant="subtitle2"
        >
          {DEVICE_LABELS.includeDeletedDevices}
        </Typography>
      </div>
      <div className={classes.selectAll}>
        <Checkbox
          className={classes.checkbox}
          checkedIcon={<CheckBoxOutlinedIcon />}
          checked={isAllSelected}
          color={isAllSelected ? 'primary' : 'default'}
          onChange={onSelectAll}
        />
        <Typography
          className={classes.selectAllLabel}
          color={isAllSelected ? 'primary' : 'inherit'}
          variant="subtitle2"
        >
          {DEVICE_LABELS.selectAll}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      {renderList()}
    </>
  )
}

const mapStateToProps = (state) => ({
  devices: state.dashboardReducer.devices,
  selectedDevices: state.dashboardReducer.deviceFilter.selectedDevices,
  devicesTypesFilter: state.dashboardReducer.devicesTypesFilter,
})

export default connect(
  mapStateToProps,
  null
)(withMediaQuery(withStyles(styles)(DevicesListComponent)))
