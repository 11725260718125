import { Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { uniqueId } from 'lodash'
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import './App.css'
import ApiKey from './containers/Account/components/apiKey/ApiKey'
import DashboardContainer from './containers/Dashboard/DashboardContainer'
import HeaderContainer from './components/_shared/header/HeaderComponent'
import ARCDeviceDetails from './containers/Devices/Details/ARC/components/deviceDetails/ARCDeviceDetails'
import UVGIDeviceDetails from './containers/Devices/Details/UVGI/components/deviceDetails/UVGIDeviceDetails'
import LocationContainer from './containers/Locations/LocationContainer'
import FactoryLoginPlug from './containers/LogIn/pages/FactoryLoginPlug'
import LogIn from './containers/LogIn/pages/login/LogIn'
import ResetPassword from './containers/LogIn/pages/resetPassword/ResetPassword'
import SummaryContainer from './containers/Summary/SummaryContainer'
import { HttpClient } from './services/HttpClient'
import styles from './style'
import UsersListContainer from './containers/Users/UsersContainer'
import AccountInfo from './containers/Account/components/AccountInfo/AccountInfo'
import UsersEditPageContainer from './containers/Users/components/updateUserForm/UserEditPageContainer'
import actions from './services/root/root-actions'
import {
  pushLoginEventToDataLayer,
  getPageAndTitle,
} from './services/root/root-service'
import authorizationActions from './containers/LogIn/services/authorization-actions'
import ChangeAccountOwner from './containers/Account/components/changeOwner/ChangeAccountOwner'
import DevicesListContainer from './containers/Devices/DevicesListContainer'
import DrillDownTableContainer from './containers/Summary/_shared/components/DrillDownTable/DrillDownTableContainer'
import ExternalDevicesListContainer from './containers/Devices/List/EXT_DEVICE/components/ExternalDevicesListContainer'
import ExternalDeviceDetails from './containers/Devices/Details/EXT_DEVICE/components/externalDeviceDetails/ExtDeviceDetails'
import { DEVICE_TYPES } from './services/constants'
import { LOGIN_METHODS } from './containers/LogIn/services/authorization-constants'
import { createBrowserHistory } from 'history'
import BannerWithLink from './components/_shared/banner/BannerWithLink'

const App = (props) => {
  const [isLogged, setIsLogged] = useState(HttpClient.tp.isLoggedIn())
  const [needToProvideLoginEvent, setNeedToProvideLoginEvent] = useState(false)
  const history = createBrowserHistory()

  const {
    role,
    isOperatorView,
    logout,
    isAccountOwner,
    buildVersion,
    showBuildVersion,
    features,
    loadConfiguration,
    organization,
    userId,
    loginMethod,
    classes,
  } = props
  const showApp = role && isLogged && features && features.length
  const isImpersonationURL = window.location.hash.startsWith('#/factory')

  const listener = useCallback(
    (newIsLogged) => {
      setIsLogged(newIsLogged)
      setNeedToProvideLoginEvent(true)
    },
    [setIsLogged]
  )

  useEffect(() => {
    if (!role && isLogged && !isImpersonationURL) {
      loadConfiguration()
    }

    HttpClient.setUnauthorizedHandler((data) => {
      logout(data)
    })
  }, [])

  useEffect(() => {
    let historyListener

    const loginMethodFromStorage = localStorage.getItem('loginMethod')

    if (
      organization.id &&
      userId &&
      loginMethodFromStorage !== LOGIN_METHODS.factory
    ) {
      // pushing user info to dataLayer to provide access from another tags
      window.dataLayer.push({
        user_id: userId,
        organization: organization.name,
        organization_id: organization.id,
      })

      historyListener = history.listen(() => {
        const [page, title] = getPageAndTitle()

        window.dataLayer.push({
          event: 'pageview',
          page: page,
          title: title,
        })
      })

      if (needToProvideLoginEvent && loginMethod === LOGIN_METHODS.customer) {
        pushLoginEventToDataLayer(userId, organization.name, organization.id)

        setNeedToProvideLoginEvent(false)
      }
    }

    return historyListener
  }, [userId, organization, loginMethod])

  useEffect(() => {
    HttpClient.tp.subscribe(listener)

    return () => {
      HttpClient.tp.unsubscribe(listener)
    }
  }, [listener])

  return (
    <HashRouter>
      {isLogged && <HeaderContainer />}
      {isImpersonationURL ? (
        <Route
          exact
          path="/factory/:id/:email"
          render={(props) => <FactoryLoginPlug {...props} />}
        />
      ) : (
        showApp && (
          <div className={classes.pageContainer}>
            <BannerWithLink />
            <Switch>
              <Redirect exact from="/" to="/summary" />
              <Route
                path="/dashboard"
                render={(props) => <DashboardContainer {...props} />}
              />
              <Route
                path="/locations"
                render={(props) => <LocationContainer {...props} />}
              />
              <Route
                exact
                path="/devices"
                render={(props) => <DevicesListContainer {...props} />}
              />
              <Route
                exact
                path={`/devices/${DEVICE_TYPES.arc.path}/:deviceId`}
                render={(props) => <ARCDeviceDetails {...props} />}
              />
              <Route
                exact
                path={`/devices/${DEVICE_TYPES.ext.path}`}
                render={(props) => <ExternalDevicesListContainer {...props} />}
              />
              <Route
                exact
                path={`/devices/${DEVICE_TYPES.ext.path}/:deviceId`}
                render={(props) => <ExternalDeviceDetails {...props} />}
              />
              <Route
                exact
                path="/summary"
                render={(props) => <SummaryContainer {...props} />}
              />
              <Route
                path={`/devices/${DEVICE_TYPES.uvc.path}/:deviceId`}
                render={(props) => <UVGIDeviceDetails {...props} />}
              />
              <Route
                path={`/devices/${DEVICE_TYPES.uvgi.path}/:deviceId`}
                render={(props) => <UVGIDeviceDetails {...props} />}
              />
              <Route
                path="/summary/:deviceType/:tableType"
                render={(props) => <DrillDownTableContainer {...props} />}
              />
              <Route
                exact
                path="/account"
                render={(props) => <AccountInfo {...props} />}
              />
              <Route
                exact
                path="/account/changeOwner"
                render={(props) => <ChangeAccountOwner {...props} />}
              />
              <Route
                exact
                path="/factory/:id/:email"
                render={(props) => <FactoryLoginPlug {...props} />}
              />
              {isAccountOwner && (
                <Route
                  exact
                  path="/api_key"
                  render={(props) => <ApiKey {...props} />}
                  push
                />
              )}
              {!isOperatorView && [
                <Route
                  exact
                  path="/users"
                  key={uniqueId()}
                  render={(props) => <UsersListContainer {...props} />}
                />,
                <Route
                  exact
                  path="/users/add"
                  key={uniqueId()}
                  render={(props) => <UsersEditPageContainer {...props} />}
                />,
                <Route
                  exact
                  path="/users/edit/:id"
                  key={uniqueId()}
                  render={(props) => (
                    <UsersEditPageContainer isEdit={true} {...props} />
                  )}
                />,
              ]}
              <Redirect to="/summary" push />
            </Switch>
            <Tooltip title={buildVersion || ''} placement="left">
              <InfoIcon
                className={
                  showBuildVersion ? classes.buildInfo : classes.hideBuildInfo
                }
              />
            </Tooltip>
          </div>
        )
      )}
      {!isLogged && (
        <div className={classes.authWrapper}>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route path="/login" component={(props) => <LogIn {...props} />} />
            <Route
              path="/login/redirectFromOkta"
              render={(props) => <LogIn {...props} />}
            />
            <Route
              exact
              path="/resetPassword"
              render={(props) => <ResetPassword {...props} />}
            />
            <Redirect to="/login" push />
          </Switch>
        </div>
      )}
    </HashRouter>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserPermissions: () => dispatch(actions.loadUserPermissions()),
    loadConfiguration: () => dispatch(actions.loadConfiguration()),
    logout: (data) => dispatch(authorizationActions.logout(data)),
  }
}

const mapStateToProps = (state) => ({
  role: state.rootReducer.role,
  userId: state.rootReducer.userId,
  organization: state.rootReducer.organization,
  isOperatorView: state.rootReducer.isOperatorView,
  isAccountOwner: state.rootReducer.isAccountOwner,
  buildVersion: state.rootReducer.buildVersion,
  showBuildVersion: state.rootReducer.showBuildVersion,
  features: state.rootReducer.features,
  loginMethod: state.authorizationReducer.loginMethod,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App))
