import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { HttpClient } from '../../../../../services/HttpClient'
import uvgiDeviceDetailsActions from './uvgiDeviceDetails-actions'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import {
  getPeriodForDeviceStatus,
  getPropertyIdWithSelectedRoom,
  getReminderDate,
} from './uvgiDeviceDetails-service'
import { filterLocationsWithAllowedProps } from '../../../../../services/root/root-service'
import { cloneDeep, isEmpty } from 'lodash'
import {
  getQueryForDeviceDetails,
  prepareLocationFiltersForDevice,
} from '../../../_shared/services/devices-service'

const getCurrentUserId = (state) => state.rootReducer.userId
const getRoomsFilter = (state) =>
  state.devicesReducer.UVGIDeviceDetailsReducer.roomsFilter
const getAllowedLocationIds = (state) =>
  state.devicesReducer.UVGIDeviceDetailsReducer.allowedLocationIds
const getTimezone = (state) => state.rootReducer.timezone
const getLocationTree = (state) => state.customTreeReducer.nodes
const getLastModification = (state) =>
  state.devicesReducer.UVGIDeviceDetailsReducer.lastModification
const getNeedToUpdateData = (state) =>
  state.devicesReducer.UVGIDeviceDetailsReducer.needToUpdateData
const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* pollLastModification(action) {
  while (true) {
    try {
      let needToUpdateDevices = yield select(getNeedToUpdateData)

      const lastModification = yield select(getLastModification)

      const data = yield call(() => {
        return HttpClient.get(
          `/api/secured/organizations/last-modification/EVENT`
        )
      })

      if (data.message) {
        yield put(uvgiDeviceDetailsActions.pollModificationFailed(data.message))
      } else {
        if (data['modified'] !== lastModification) {
          needToUpdateDevices = true
        }

        yield put(
          uvgiDeviceDetailsActions.pollModificationSucceeded(data['modified'])
        )

        if (needToUpdateDevices && action) {
          const { id, needToPoll } = action.payload

          yield put(uvgiDeviceDetailsActions.loadDeviceDetails(id, needToPoll))
        }
      }

      yield delay(3000)
    } catch (e) {
      yield put(uvgiDeviceDetailsActions.pollModificationFailed(e.message))
    }
  }
}

function* loadDeviceDetails(action) {
  try {
    const { id, needToPoll } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/transactions/v2/recent-activities?` +
          `query=${getQueryForDeviceDetails(id)}`
      )
    })

    if (data.message) {
      yield put(uvgiDeviceDetailsActions.loadDeviceDetailsFailed(data.message))
    } else {
      yield put(uvgiDeviceDetailsActions.putDeviceDetails(id, data.items[0]))
    }

    if (!needToPoll) {
      yield put(uvgiDeviceDetailsActions.stopPollingModification())
    }
  } catch (e) {
    yield put(uvgiDeviceDetailsActions.loadDeviceDetailsFailed(e.message))
  }
}

function* loadAllowedLocationsForDevice(action) {
  try {
    const { deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/ur-uvgi/${deviceId}`)
    })

    if (data.message) {
      yield put(
        uvgiDeviceDetailsActions.loadAllowedLocationsForDeviceFailed(
          data.message
        )
      )
    } else {
      yield put(
        uvgiDeviceDetailsActions.loadAllowedLocationsForDeviceSuccess(
          data,
          deviceId
        )
      )

      yield put(
        uvgiDeviceDetailsActions.startPollingModification(
          deviceId,
          !isEmpty(data)
        )
      )
    }
  } catch (e) {
    yield put(
      uvgiDeviceDetailsActions.loadAllowedLocationsForDeviceFailed(e.message)
    )
  }
}

function* loadLocationsForDevice(action) {
  try {
    const allowedLocationIds = yield select(getAllowedLocationIds)
    const { onSuccess } = action.payload
    const userId = yield select(getCurrentUserId)
    const tree = yield select(getInitialNodesTree)

    yield put(
      uvgiDeviceDetailsActions.setupRoomsFilter(
        prepareLocationFiltersForDevice(tree.children, allowedLocationIds),
        tree,
        userId
      )
    )

    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield put(uvgiDeviceDetailsActions.loadLocationsForDeviceFailed(e.message))
  }
}

function* updateDevice(action) {
  try {
    const { serialNumber, body, onSuccess } = action.payload

    const roomFilter = yield select(getRoomsFilter)

    if (roomFilter.selectedBuildings && roomFilter.selectedBuildings.length) {
      body.locationIds = roomFilter.selectedBuildings
    } else {
      body.locationIds = []
    }

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/devices/ur-uvgi/${serialNumber}`, {
        body,
      })
    })

    if (data.message) {
      yield put(uvgiDeviceDetailsActions.updateDeviceFailed(data.message))
    } else {
      onSuccess()

      yield put(
        uvgiDeviceDetailsActions.updateDeviceSuccess(
          body.locationIds,
          body.nickname
        )
      )
    }
  } catch (e) {
    yield put(uvgiDeviceDetailsActions.updateDeviceFailed(e.message))
  }
}

function* getDeviceStatus(action) {
  try {
    const { deviceId } = action.payload

    const timezone = yield select(getTimezone)

    const period = getPeriodForDeviceStatus(timezone)

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/ur-uvgi/status/${deviceId}?dateFrom=${period.dateFrom}&dateTo=${period.dateTo}`
      )
    })

    if (data.message) {
      yield put(uvgiDeviceDetailsActions.getDeviceStatusFailed(data.message))
    } else {
      yield put(uvgiDeviceDetailsActions.getDeviceStatusSuccess(data))
    }
  } catch (e) {
    yield put(uvgiDeviceDetailsActions.getDeviceStatusFailed(e.message))
  }
}

function* deleteDevice(action) {
  try {
    const { serialNumber, onClose } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(
        `/api/secured/devices/ur-uvgi/${serialNumber}/deregister`
      )
    })

    if (data.message) {
      yield put(uvgiDeviceDetailsActions.deleteDeviceFailed(data.message))
    } else {
      yield put(uvgiDeviceDetailsActions.deleteDeviceSuccess(data))

      onClose()
    }
  } catch (e) {
    yield put(uvgiDeviceDetailsActions.deleteDeviceFailed(e.message))
  }
}

function* setupLoadedLocations(action) {
  try {
    const { nodes, tree, userId } = action.payload

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        filterLocationsWithAllowedProps(tree, nodes)
      )
    )

    const locationTree = yield select(getLocationTree)
    const propertyId = getPropertyIdWithSelectedRoom(locationTree)

    yield put(customTreeActions.setPropertyExpandNode(propertyId))
  } catch (e) {
    yield put(uvgiDeviceDetailsActions.setupLocationsFilterFailed(e.message))
  }
}

function* setMaintenanceReminder(action) {
  try {
    const { deviceId } = action.payload
    const reminderDate = getReminderDate()

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/ur-uvgi/${deviceId}/reminder`,
        {
          body: {
            reminderDate,
          },
        }
      )
    })

    if (data.message) {
      yield put(
        uvgiDeviceDetailsActions.setMaintenanceReminderFailed(data.message)
      )
    } else {
      yield put(uvgiDeviceDetailsActions.setMaintenanceReminderSuccess())
    }
  } catch (e) {
    yield put(uvgiDeviceDetailsActions.setMaintenanceReminderFailed(e.message))
  }
}

function* loadDeviceStatistic(action) {
  try {
    const { deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/${deviceId}/statistics`)
    })

    if (data.message) {
      yield put(
        uvgiDeviceDetailsActions.getDeviceStatisticsFailed(data.message)
      )
    } else {
      yield put(uvgiDeviceDetailsActions.getDeviceStatisticsSuccess(data))
    }
  } catch (e) {
    yield put(uvgiDeviceDetailsActions.getDeviceStatisticsFailed(e.message))
  }
}

function* pollLastModificationSaga() {
  while (true) {
    const action = yield take(
      uvgiDeviceDetailsActions.actionTypes.START_POLLING_MODIFICATION
    )

    yield race([
      call(pollLastModification, action),
      take(uvgiDeviceDetailsActions.actionTypes.STOP_POLLING_MODIFICATION),
    ])
  }
}

function* loadAllowedLocationsForDeviceSaga() {
  yield takeEvery(
    uvgiDeviceDetailsActions.actionTypes.LOAD_ALLOWED_LOCATIONS_FOR_DEVICE,
    loadAllowedLocationsForDevice
  )
}

function* loadLocationsForDeviceSaga() {
  yield all([
    takeEvery(
      uvgiDeviceDetailsActions.actionTypes
        .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_SUCCEEDED,
      loadLocationsForDevice
    ),
    takeEvery(
      uvgiDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE,
      loadLocationsForDevice
    ),
  ])
}

function* updateDeviceSaga() {
  yield takeEvery(
    uvgiDeviceDetailsActions.actionTypes.UPDATE_DEVICE,
    updateDevice
  )
}

function* getDeviceStatusSaga() {
  yield takeEvery(
    uvgiDeviceDetailsActions.actionTypes.PUT_DEVICE_DETAILS,
    getDeviceStatus
  )
}

function* deleteDeviceSaga() {
  yield takeEvery(
    uvgiDeviceDetailsActions.actionTypes.DELETE_DEVICE,
    deleteDevice
  )
}

function* locationFilterIsReadySaga() {
  yield takeEvery(
    uvgiDeviceDetailsActions.actionTypes.SETUP_ROOMS_FILTER,
    setupLoadedLocations
  )
}

function* setMaintenanceReminderSaga() {
  yield takeEvery(
    uvgiDeviceDetailsActions.actionTypes.SET_MAINTENANCE_REMINDER,
    setMaintenanceReminder
  )
}

function* loadDeviceDetailsSaga() {
  yield takeEvery(
    uvgiDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS,
    loadDeviceDetails
  )
}

function* loadDeviceStatisticSaga() {
  yield takeEvery(
    uvgiDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS,
    loadDeviceStatistic
  )
}

export default function* UVGIDeviceDetailsSaga() {
  yield fork(pollLastModificationSaga)
  yield fork(loadAllowedLocationsForDeviceSaga)
  yield fork(loadLocationsForDeviceSaga)
  yield fork(updateDeviceSaga)
  yield fork(getDeviceStatusSaga)
  yield fork(deleteDeviceSaga)
  yield fork(locationFilterIsReadySaga)
  yield fork(setMaintenanceReminderSaga)
  yield fork(loadDeviceDetailsSaga)
  yield fork(loadDeviceStatisticSaga)
}
