import React, { useState } from 'react'
import { connect } from 'react-redux'
import { every, isEmpty, mapValues } from 'lodash'
import Chip from '@material-ui/core/Chip'
import ChipsFilterModal from '../../../components/_shared/modals/chipsFilter/ChipsFilterModal'
import actions from '../services/dashboard-actions'
import { getDevicesTypesFilterLabel } from '../services/related/filter-service'
import {
  DEVICE_TYPE_MAPPER,
  DEVICE_TYPE_FILTER,
} from '../services/dashboard-constants'
import { gaEventAction } from '../../../services/googleAnalytics/ga-actions'

const StatusFilter = (props) => {
  const {
    devicesTypesFilter,
    changeSelectedChip,
    label,
    setDevicesTypesLabel,
    startPollingModification,
    stopPollingModification,
    isInitialState,
    gaEventSend,
  } = props
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(devicesTypesFilter)

  const handleClickOpen = () => {
    if (!isInitialState) {
      setSelected(devicesTypesFilter)
      setOpen(true)

      stopPollingModification()
    }
  }

  const handleClose = () => {
    setOpen(false)

    startPollingModification()
  }

  const changeSelected = (name) => {
    const newSelected = mapValues(selected, (item, key) => {
      return name === key ? !selected[key] : selected[key]
    })

    setSelected(newSelected)
  }

  const onApply = () => {
    const isAllSelected = every(selected)
    let filterLabel

    if (isAllSelected) {
      filterLabel = 'All'
    } else {
      filterLabel = getDevicesTypesFilterLabel(DEVICE_TYPE_MAPPER, selected)
    }
    setDevicesTypesLabel(filterLabel)

    changeSelectedChip('devicesTypesFilter', selected)
    gaEventSend('device_type_filter', { event_label: filterLabel })

    handleClose()
  }

  return (
    !isEmpty(devicesTypesFilter) && (
      <>
        <Chip
          onClick={handleClickOpen}
          label={DEVICE_TYPE_FILTER.label(label)}
          variant="outlined"
        />
        <ChipsFilterModal
          open={open}
          constants={DEVICE_TYPE_MAPPER}
          onSubmitLabel={DEVICE_TYPE_FILTER.apply}
          filterName={DEVICE_TYPE_FILTER.filterName}
          statusFilter={selected}
          onApply={onApply}
          onChange={changeSelected}
          onClose={handleClose}
        />
      </>
    )
  )
}

const mapStateToProps = (state) => ({
  devicesTypesFilter: state.dashboardReducer.devicesTypesFilter,
  label: state.dashboardReducer.devicesTypesLabel,
  isInitialState: state.dashboardReducer.isInitialState,
})

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedChip: (name, key) =>
      dispatch(actions.changeFilter(name, key)),
    setDevicesTypesLabel: (label) =>
      dispatch(actions.setDevicesTypesLabel(label)),
    startPollingModification: () =>
      dispatch(actions.startPollingModification()),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    gaEventSend: gaEventAction(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusFilter)
