import React, { useState, useEffect } from 'react'
import CustomTooltip from '../../../../../components/_shared/customTooltip/CustomTooltip'

const TooltipWrapper = (props) => {
  const { title, isMobile, nicknameRef, children } = props

  const [isTooltip, setIsTooltip] = useState(false)

  useEffect(() => {
    setIsTooltip(
      nicknameRef.current.scrollWidth > nicknameRef.current.offsetWidth
    )

    window.addEventListener('resize', trackNicknameSize)

    return () => window.removeEventListener('resize', trackNicknameSize)
  }, [isTooltip])

  const trackNicknameSize = () => {
    setIsTooltip(
      nicknameRef.current.scrollWidth > nicknameRef.current.offsetWidth
    )
  }

  return isTooltip ? (
    <CustomTooltip title={title} isMobile={isMobile}>
      {children}
    </CustomTooltip>
  ) : (
    children
  )
}

export default TooltipWrapper
