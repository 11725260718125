import devicesActions from './devices-actions'
import authorizationActions from '../../../LogIn/services/authorization-actions'

export const sharedDevicesReducer = (state = {}, action) => {
  switch (action.type) {
    case devicesActions.actionTypes.SET_TAB: {
      const { tab } = action.payload

      return {
        ...state,
        tab,
      }
    }
    case authorizationActions.actionTypes.LOGOUT_SUCCEEDED: {
      return {
        ...state,
        tab: null,
      }
    }
    case devicesActions.actionTypes.GET_SERVICE_REQUEST_MESSAGE_FAILED: {
      return {
        ...state,
        error: action.payload.message,
      }
    }
    default:
      return state
  }
}
