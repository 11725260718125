import { COLORS } from '../../../../../../services/constants'

const styles = (theme) => ({
  deviceCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: COLORS.WARM_GRAY,
    height: '50px',
    alignItems: 'center',
    marginBottom: '5px',
  },
  secondaryText: {
    fontSize: '10px',
    lineHeight: '12px',
    display: 'inline-block',
    color: COLORS.GRAY_OUTER_SPACE,
    marginBottom: '2px',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  mainText: {
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  navigationRowItem: {
    width: '30px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    borderLeft: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  rowIcon: {
    margin: 'auto 0',
    height: '14px',
    width: '14px',
    color: COLORS.BLACK,
  },
  info: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    height: 'inherit',
    textAlign: 'left',
  },
  infoColumn: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingRight: '0',
    paddingLeft: '12px',
  },
  timeColumn: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '20%',
    paddingLeft: '8px',
    borderLeft: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  '@media (min-width: 1224px)': {
    deviceCard: {
      height: '81px',
      backgroundColor: COLORS.WHITE,
      marginBottom: '10px',
      cursor: 'pointer',
    },
    infoColumn: {
      width: '30%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingLeft: '25px',
    },
    timeColumn: {
      paddingLeft: '25px',
      width: '30%',
    },
    navigationRowItem: {
      width: '90px',
      borderLeft: 'none',
      color: COLORS.BLACK,
    },
    secondaryText: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    mainText: {
      fontSize: '18px',
      lineHeight: '20px',
      fontFamily: 'Cerebri Sans Pro Semi Bold',
    },
  },
})

export default styles
