import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {
  ENTER_CODE_KEYS,
  MAX_NAME_LENGTH,
} from '../../../../services/constants'
import {
  ADD_LOCATION_LABELS,
  LOCATIONS_LABELS_MULTIPLE,
  LOCATIONS_PLACEHOLDERS,
} from '../../service/location-constants'
import { getAddLocationLabel } from '../../service/location-service'
import styles from './style'
import { withMediaQuery } from '../../../../services/root/root-service'
import { isMobileOnly } from 'react-device-detect'

const ControlPart = withMediaQuery(
  withStyles(styles)((props) => {
    const {
      locationType,
      error,
      defaultValue,
      isBulk,
      handleChange,
      onChangeLocationName,
      locationName,
      isEmptyTextField,
      onSubmitButton,
      handleDoubleClick,
      classes,
    } = props

    const actionLabel = getAddLocationLabel(locationType, isBulk)
    const placeholder = isBulk
      ? LOCATIONS_LABELS_MULTIPLE[locationType]
      : LOCATIONS_PLACEHOLDERS[locationType]

    const onKeyUp = (e) => {
      if (e.key === ENTER_CODE_KEYS.key || e.keyCode === ENTER_CODE_KEYS.code) {
        onSubmitButton(e)
      }
    }

    return (
      <>
        <FormControlLabel
          className={classes.checkboxLabel}
          control={
            <Checkbox
              checked={isBulk}
              className={classes.checkbox}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label={ADD_LOCATION_LABELS.addBulk}
        />
        <TextField
          variant="outlined"
          value={locationName}
          error={isEmptyTextField || !!error}
          defaultValue={defaultValue}
          label={!isMobileOnly ? placeholder : ''}
          placeholder={isMobileOnly ? placeholder : ''}
          inputProps={{
            className: classes.outlined,
            maxLength: isBulk ? '' : MAX_NAME_LENGTH,
          }}
          fullWidth
          onChange={onChangeLocationName}
          multiline={isBulk}
          rows={10}
          autoFocus={!isMobileOnly}
          onKeyUp={isBulk ? null : onKeyUp}
        />
        <Button
          onDoubleClick={handleDoubleClick}
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={onSubmitButton}
        >
          {actionLabel}
        </Button>
      </>
    )
  })
)

export default ControlPart
