import { trim } from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { checkConnection } from '../../../../services/root/root-service'
import actions from '../../service/location-actions'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import styles from '../../style'
import {
  validateLocationName,
  prepareBulkLocations,
  getLocationsNames,
  checkNameCase,
  getWarningUIValidationText,
} from '../../service/location-service'
import AddLocationModal from './AddLocationModal'

const AddLocation = (props) => {
  const {
    onSubmit,
    classes,
    locationLabel,
    resetError,
    setError,
    validateLocations,
    bulkUploadLocations,
    buttonLabel,
    locationType,
    history,
    isChild,
    parentId,
  } = props
  const [open, setOpen] = useState(false)
  const [warningOpen, setWarningOpen] = useState(false)
  const [locationsParams, setLocationsParams] = useState({})
  const [warningText, setWarningText] = useState('')

  const openCreateLocationModal = () => {
    resetError && resetError()
    setOpen(true)
  }

  const closeCreateLocationModal = () => {
    setLocationsParams({})
    setOpen(false)
  }

  const openWarningModal = (duplicates, isPrepared) => {
    const helperText = isPrepared
      ? getWarningUIValidationText(duplicates)
      : getLocationsNames(duplicates)

    setWarningText(helperText)
    setWarningOpen(true)
  }

  const closeWarningModal = () => {
    resetError && resetError()
    setWarningOpen(false)
  }

  const onConfirm = (body, isBulk) => {
    if (isBulk) {
      const newLocations = prepareBulkLocations(body)

      bulkUploadLocations(newLocations, closeCreateLocationModal, history)
    } else {
      body.name = trim(body.value)
      delete body.value

      onSubmit(body, closeCreateLocationModal)
    }
  }

  const onWarningConfirm = () => {
    const { value, isBulk } = locationsParams

    const body = {
      value,
      parentId,
      locationType,
    }

    setWarningOpen(false)
    onConfirm(body, isBulk)
  }

  const validateLocationsNames = (value, isBulk, roomFields) => {
    // validation on UI side (allowed symbols and name length)
    const validationError = validateLocationName(value, isBulk)

    if (validationError) {
      setError(validationError)
    } else {
      setLocationsParams({
        value,
        isBulk,
      })

      const uiValidationChecking = checkNameCase(value)

      if (isBulk && uiValidationChecking.length) {
        // if duplicates was founded, then open warning modal
        // to warn user that locations with similar names already exist
        openWarningModal(uiValidationChecking, true)
      } else {
        const body = {
          value,
          parentId,
          ...(roomFields ? roomFields : {}),
          locationType,
        }

        // validation on BE side, find possible duplicates
        // if duplicates was founded, then open warning modal
        // to warn user that locations with similar names already exist
        validateLocations(body, isBulk, onConfirm, openWarningModal)
      }
    }
  }

  return (
    <div className={classes.addLocationButtonContainer}>
      {isChild ? (
        <Button
          variant="outlined"
          color="primary"
          className={classes.addChildLocation}
          onClick={openCreateLocationModal}
        >
          {buttonLabel}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.addLocationButton}
          onClick={openCreateLocationModal}
        >
          {buttonLabel}
        </Button>
      )}
      <AddLocationModal
        isOpen={open}
        onClose={closeCreateLocationModal}
        onSubmit={validateLocationsNames}
        locationType={locationType}
        modalTitle={locationLabel}
        warningOpen={warningOpen}
        onWarningConfirm={onWarningConfirm}
        closeWarningModal={closeWarningModal}
        warningText={warningText}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setError: (error) => dispatch(actions.setError(error)),
  bulkUploadLocations: (locations, onClose, history) =>
    dispatch(
      checkConnection(() =>
        actions.bulkUploadLocations(locations, onClose, history)
      )
    ),
  validateLocations: (body, isBulk, onSuccess, onError) =>
    dispatch(
      checkConnection(() =>
        actions.validateLocations(body, isBulk, onSuccess, onError)
      )
    ),
  resetError: () => dispatch(actions.resetError()),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(AddLocation))
