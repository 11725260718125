import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import style from './style'
import Typography from '@material-ui/core/Typography'
import {
  HEIGHT_OF_TABS_AND_TITLE,
  SUMMARY_TITLE,
} from '../../services/summary-constants'

const SummaryPageByTypeWrapper = (props) => {
  const { HeaderComponent, PageComponent, name, isMobile, classes } = props
  const [, setScrolledValue] = useState()

  const [showHeader, setShowHeader] = useState(false)
  const [classForFiltersWrapper, setClassForFiltersWrapper] = useState(
    classes.filtersContainerWithoutScroll
  )

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('scroll', listenScrollEvent, true)
    }

    return () => {
      if (!isMobile) {
        window.removeEventListener('scroll', listenScrollEvent, true)
      }
    }
  }, [])

  const listenScrollEvent = () => {
    let scrolled = document.getElementById('tabs-container').scrollTop

    // these checks are needed to not reset scroll position after re-render!
    setScrolledValue((prev) => {
      if (prev > 100 && scrolled === 0) {
        document.getElementById('tabs-container').scrollTop = prev
        scrolled = prev

        return prev
      }

      return scrolled
    })

    setShowHeader((prevShowHeader) => {
      if (scrolled > HEIGHT_OF_TABS_AND_TITLE && !prevShowHeader) {
        setClassForFiltersWrapper(classes.activeScrollFiltersContainer)

        return true
      } else if (scrolled <= HEIGHT_OF_TABS_AND_TITLE && prevShowHeader) {
        setClassForFiltersWrapper(classes.filtersContainerWithoutScroll)

        return false
      }

      return prevShowHeader
    })
  }

  return (
    <>
      <div className={classForFiltersWrapper}>
        {showHeader && (
          <Typography variant="h4">{`${name} ${SUMMARY_TITLE}`}</Typography>
        )}
        <div className={classes.filtersContainer}>
          <HeaderComponent />
        </div>
      </div>
      <PageComponent />
    </>
  )
}

export default withStyles(style)(SummaryPageByTypeWrapper)
