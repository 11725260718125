import {
  DEVICE_TYPE,
  LOCATION_FILTER_LABELS,
  PAGINATION,
} from '../../../../../services/constants'

const initialLocationFilter = {
  [DEVICE_TYPE.uvgi]: {
    ids: [],
    locations: {},
    label: LOCATION_FILTER_LABELS.all,
  },
  [DEVICE_TYPE.uvc]: {
    ids: [],
    locations: {},
    label: LOCATION_FILTER_LABELS.all,
  },
}

const initialStateUVGI = {
  list: [],
  locationFilter: initialLocationFilter,
  roomsFilter: {},
  isLoading: true,
  allowedLocationIds: null,
  lastModification: null,
  needToUpdateData: true,
  reqForDevicesIsExecuted: true,
  totalCount: 0,
  page: 1,
  rowsPerPage: PAGINATION.perPageValues[0],
  searchField: '',
}

export { initialStateUVGI, initialLocationFilter }
