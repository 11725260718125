import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import classNames from 'classnames'

const FieldValue = (props) => {
  const { value, label, isError = false, classes } = props

  return (
    <Typography
      variant="h5"
      className={
        !isError
          ? classes.secondaryText
          : classNames(classes.secondaryText, classes.error)
      }
    >
      <span className={classes.rowName}>{`${label}: `}</span>
      <span>{value}</span>
    </Typography>
  )
}

export default withStyles(styles)(FieldValue)
