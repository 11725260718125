import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  extension: {
    width: '30%',
  },
  extensionInput: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  phoneInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  notchedOutline: {
    borderRightWidth: 0,
  },
  inputTextColor: {
    color: COLORS.BLACK,
  },
})

export default styles
