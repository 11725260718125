import { cloneDeep, every, isEmpty } from 'lodash'
import { prepareLocationFiltersForDevice } from '../../../_shared/services/devices-service'
import arcDeviceListActions from './arcDeviceList-actions'
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { HttpClient } from '../../../../../services/HttpClient'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import { filterLocationsWithAllowedProps } from '../../../../../services/root/root-service'
import { prepareBody } from './arcDeviceList-service'
import rootActions from '../../../../../services/root/root-actions'

export const getCurrentPage = (state) =>
  state.devicesReducer.ARCDeviceListReducer.page
export const getRowsPerPage = (state) =>
  state.devicesReducer.ARCDeviceListReducer.rowsPerPage
export const getOrderType = (state) =>
  state.devicesReducer.ARCDeviceListReducer.sorting
export const getLocationCopyTree = (state) => state.customTreeReducer.treeCopy
export const getNodes = (state) =>
  state.devicesReducer.ARCDeviceListReducer.nodes
export const getLocationFilter = (state) =>
  state.devicesReducer.ARCDeviceListReducer.locationFilter
export const getIsAllSelected = (state) => state.customTreeReducer.isAllSelected
export const getSearchField = (state) =>
  state.devicesReducer.ARCDeviceListReducer.searchField
export const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* loadDevicesList() {
  try {
    const tree = yield select(getInitialNodesTree)
    const orderType = yield select(getOrderType)
    const searchField = yield select(getSearchField)
    const body = prepareBody(orderType, searchField)
    const pageNum = yield select(getCurrentPage)
    const pageSize = yield select(getRowsPerPage)

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/devices/transactions/last-activities`,
        {
          body: {
            ...body,
            pageNum: pageNum - 1,
            pageSize,
          },
        }
      )
    })

    if (data.message) {
      yield put(arcDeviceListActions.loadDevicesListFailed(data.message))
    } else {
      const allDevicesAssignedToAllProps = every(data, [
        'deviceAllowedLocationIds',
        null,
      ])

      yield put(
        arcDeviceListActions.loadDevicesListSuccess(
          data,
          allDevicesAssignedToAllProps,
          tree
        )
      )
    }
  } catch (e) {
    yield put(arcDeviceListActions.loadDevicesListFailed(e.message))
  }
}

function* getLocationsForList() {
  try {
    const nodes = yield select(getInitialNodesTree)

    yield put(arcDeviceListActions.loadLocationsForListSuccess(nodes))
  } catch (e) {
    yield put(arcDeviceListActions.loadLocationsForListFailed(e.message))
  }
}

function* addDevice(action) {
  try {
    const locationFilter = yield select(getLocationFilter)
    const isAllSelected = yield select(getIsAllSelected)
    const { selectedBuildings: ids } = locationFilter

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/uvi-arc/${action.payload.serialNumber}/register`,
        {
          body: {
            locationIds: ids && ids.length && !isAllSelected ? ids : null,
          },
        }
      )
    })

    if (data.message) {
      yield put(
        arcDeviceListActions.addNewDeviceFailed(
          data.message,
          action.payload.serialNumber
        )
      )
    } else {
      action.payload.onCLose()

      yield put(arcDeviceListActions.addNewDeviceSuccess(data))
    }
  } catch (e) {
    yield put(
      arcDeviceListActions.addNewDeviceFailed(
        e.message,
        action.payload.serialNumber
      )
    )
  }
}

function* loadLocationsForAddDeviceButton(action) {
  try {
    const { onSuccess } = action.payload
    let tree = yield select(getLocationCopyTree)

    if (isEmpty(tree)) {
      tree = yield call(() => {
        return HttpClient.get('/api/secured/locations/')
      })
    }

    if (tree.message) {
      yield put(arcDeviceListActions.setupLocationsFilterFailed(tree.message))
    } else {
      yield put(
        arcDeviceListActions.setupLocationsFilter(
          prepareLocationFiltersForDevice(tree.children, []),
          tree
        )
      )

      if (onSuccess) {
        onSuccess()
      }
    }
  } catch (e) {
    yield put(arcDeviceListActions.setupLocationsFilterFailed(e.message))
  }
}

function* setupLoadedLocations(action) {
  try {
    const { tree, userId, nodes } = action.payload

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        filterLocationsWithAllowedProps(tree, nodes)
      )
    )
  } catch (e) {
    yield put(arcDeviceListActions.setupLocationsFilterFailed(e.message))
  }
}

function* setGlobalTimeout(action) {
  try {
    const { timeout, close, applyToAllLocations } = action.payload

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/organizations/cycleTime/${timeout}?applyToAllLocations=${applyToAllLocations}`
      )
    })

    if (data.message) {
      yield put(arcDeviceListActions.setGlobalTimeoutFailed(data.message))
    } else {
      yield put(arcDeviceListActions.setGlobalTimeoutSucceeded(data))
      yield put(rootActions.setNewDefaultCycleTime(timeout))

      close()
    }
  } catch (e) {
    yield put(arcDeviceListActions.setGlobalTimeoutFailed(e.message))
  }
}

function* loadDevicesListSaga() {
  yield all([
    takeLatest(
      arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST,
      loadDevicesList
    ),
    takeLatest(
      arcDeviceListActions.actionTypes.SET_ROWS_PER_PAGE,
      loadDevicesList
    ),
    takeLatest(
      arcDeviceListActions.actionTypes.GO_TO_NEXT_PAGE,
      loadDevicesList
    ),
    takeLatest(arcDeviceListActions.actionTypes.SEARCH_BY_SN, loadDevicesList),
  ])
}

function* getLocationsForListSaga() {
  yield takeEvery(
    arcDeviceListActions.actionTypes.LOAD_DEVICES_LIST_SUCCEEDED,
    getLocationsForList
  )
}

function* addDeviceSaga() {
  yield takeEvery(arcDeviceListActions.actionTypes.ADD_NEW_DEVICE, addDevice)
}

function* deviceWasAddedSaga() {
  yield takeEvery(
    arcDeviceListActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED,
    loadDevicesList
  )
}

function* changeSortingSaga() {
  yield takeEvery(
    arcDeviceListActions.actionTypes.CHANGE_SORTING,
    loadDevicesList
  )
}

function* locationFilterIsReadySaga() {
  yield takeEvery(
    arcDeviceListActions.actionTypes.SETUP_LOCATIONS_FILTER,
    setupLoadedLocations
  )
}

function* setGlobalTimeoutSaga() {
  yield takeEvery(
    arcDeviceListActions.actionTypes.SET_GLOBAL_TIMEOUT,
    setGlobalTimeout
  )
}

function* openAddDeviceModalTreeSaga() {
  yield takeEvery(
    arcDeviceListActions.actionTypes.LOAD_LOCATIONS_FOR_ADD_DEVICE_MODAL,
    loadLocationsForAddDeviceButton
  )
}

export default function* ARCDeviceListSaga() {
  yield fork(loadDevicesListSaga)
  yield fork(getLocationsForListSaga)
  yield fork(addDeviceSaga)
  yield fork(changeSortingSaga)
  yield fork(deviceWasAddedSaga)
  yield fork(locationFilterIsReadySaga)
  yield fork(setGlobalTimeoutSaga)
  yield fork(openAddDeviceModalTreeSaga)
}
