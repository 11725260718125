import { cloneDeep } from 'lodash'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import { filterLocationsWithAllowedProps } from '../../../../../services/root/root-service'
import arcDeviceListActions from '../../../List/ARC/services/arcDeviceList-actions'
import { getLocationFilterParams } from '../../../List/ARC/services/arcDeviceList-service'
import arcDeviceDetailsActions from './arcDeviceDetails-actions'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { HttpClient } from '../../../../../services/HttpClient'
import {
  getQueryForDeviceDetails,
  prepareLocationFiltersForDevice,
} from '../../../_shared/services/devices-service'

export const getAllowedLocationIds = (state) =>
  state.devicesReducer.ARCDeviceDetailsReducer.allowedLocationIds
export const getLocationFilter = (state) =>
  state.devicesReducer.ARCDeviceDetailsReducer.locationFilter
export const getIsAllSelected = (state) => state.customTreeReducer.isAllSelected
export const getCurrentUserId = (state) => state.rootReducer.userId
export const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* loadDeviceDetails(action) {
  try {
    const { deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/transactions/v2/recent-activities?` +
          `query=${getQueryForDeviceDetails(deviceId)}`
      )
    })

    if (data.message) {
      yield put(arcDeviceDetailsActions.loadDeviceDetailsFailed(data.message))
    } else {
      yield put(arcDeviceListActions.putDeviceDetails(data.items[0]))
    }
  } catch (e) {
    yield put(arcDeviceDetailsActions.loadDeviceDetailsFailed(e.message))
  }
}

function* loadAllowedLocationsForDevice(action) {
  try {
    const { deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/uvi-arc/${deviceId}`)
    })

    if (data.message) {
      yield put(
        arcDeviceDetailsActions.loadAllowedLocationsForDeviceFailed(
          data.message
        )
      )
    } else {
      yield put(
        arcDeviceDetailsActions.loadAllowedLocationsForDeviceSucceeded(
          data,
          deviceId
        )
      )
    }
  } catch (e) {
    yield put(
      arcDeviceDetailsActions.loadAllowedLocationsForDeviceFailed(e.message)
    )
  }
}

function* updateDevice(action) {
  try {
    const locationFilter = yield select(getLocationFilter)
    const isAllSelected = yield select(getIsAllSelected)
    const { serialNumber, body, onSuccess } = action.payload

    body.locationIds = isAllSelected ? null : locationFilter.selectedBuildings

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/devices/uvi-arc/${serialNumber}`, {
        body,
      })
    })

    if (data.message) {
      yield put(arcDeviceDetailsActions.updateDeviceFailed(data.message))
    } else {
      onSuccess()

      yield put(
        arcDeviceDetailsActions.updateDeviceSuccess(
          body.locationIds,
          body.nickname
        )
      )
    }
  } catch (e) {
    yield put(arcDeviceDetailsActions.updateDeviceFailed(e.message))
  }
}

function* deleteDevice(action) {
  try {
    const { serialNumber, onClose } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(
        `/api/secured/devices/uvi-arc/${serialNumber}/deregister`
      )
    })

    if (data.message) {
      yield put(arcDeviceDetailsActions.deleteDeviceFailed(data.message))
    } else {
      onClose()

      yield put(arcDeviceDetailsActions.deleteDeviceSuccess(data))
    }
  } catch (e) {
    yield put(arcDeviceDetailsActions.deleteDeviceFailed(e.message))
  }
}

function* loadLocationsForDevice(action) {
  try {
    const allowedLocationIds = yield select(getAllowedLocationIds)
    const tree = yield select(getInitialNodesTree)
    const { onSuccess } = action.payload
    const userId = yield select(getCurrentUserId)
    const filter = getLocationFilterParams(tree, allowedLocationIds)

    yield put(
      arcDeviceDetailsActions.setupLocationsFilter(filter, tree, userId)
    )

    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield put(arcDeviceDetailsActions.loadLocationsForDeviceFailed(e.message))
  }
}

function* loadDeviceStatistic(action) {
  try {
    const { deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/${deviceId}/statistics`)
    })

    if (data.message) {
      yield put(arcDeviceDetailsActions.getDeviceStatisticsFailed(data.message))
    } else {
      yield put(arcDeviceDetailsActions.getDeviceStatisticsSuccess(data))
    }
  } catch (e) {
    yield put(arcDeviceDetailsActions.getDeviceStatisticsFailed(e.message))
  }
}

function* setupLoadedLocations(action) {
  try {
    const { tree, userId, nodes } = action.payload
    const locationParams = prepareLocationFiltersForDevice(
      tree,
      nodes.selectedBuildings
    )

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        filterLocationsWithAllowedProps(tree, locationParams)
      )
    )
  } catch (e) {
    yield put(arcDeviceDetailsActions.setupLocationsFilterFailed(e.message))
  }
}

function* locationFilterIsReadySaga() {
  yield takeEvery(
    arcDeviceDetailsActions.actionTypes.SETUP_LOCATIONS_FILTER,
    setupLoadedLocations
  )
}

function* loadDeviceDetailsSaga() {
  yield takeEvery(
    arcDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS,
    loadDeviceDetails
  )
}

function* loadAllowedLocationsForDeviceSaga() {
  yield takeEvery(
    arcDeviceDetailsActions.actionTypes.LOAD_ALLOWED_LOCATIONS_FOR_DEVICE,
    loadAllowedLocationsForDevice
  )
}

function* updateDeviceSaga() {
  yield takeEvery(
    arcDeviceDetailsActions.actionTypes.UPDATE_DEVICE,
    updateDevice
  )
}

function* deleteDeviceSaga() {
  yield takeEvery(
    arcDeviceDetailsActions.actionTypes.DELETE_DEVICE,
    deleteDevice
  )
}

function* loadLocationsForDeviceSaga() {
  yield all([
    takeEvery(
      arcDeviceDetailsActions.actionTypes
        .LOAD_ALLOWED_LOCATIONS_FOR_DEVICE_SUCCEEDED,
      loadLocationsForDevice
    ),
    takeEvery(
      arcDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE,
      loadLocationsForDevice
    ),
  ])
}

function* loadDeviceStatisticSaga() {
  yield takeEvery(
    arcDeviceDetailsActions.actionTypes.GET_DEVICE_STATISTICS,
    loadDeviceStatistic
  )
}

export default function* ARCDeviceDetailsSaga() {
  yield fork(loadDeviceDetailsSaga)
  yield fork(loadAllowedLocationsForDeviceSaga)
  yield fork(updateDeviceSaga)
  yield fork(deleteDeviceSaga)
  yield fork(loadLocationsForDeviceSaga)
  yield fork(loadDeviceStatisticSaga)
  yield fork(locationFilterIsReadySaga)
}
