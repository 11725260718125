import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  cardContainer: {
    backgroundColor: COLORS.WARM_GRAY,
    boxShadow: 'none',
    margin: '10px 0',
  },
  infoFab: {
    color: COLORS.BLUE,
    maxHeight: '27px',
    maxWidth: '27px',
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.LIGHT_SILVER}`,
  },
  incorrectWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: '5px',
    paddingBottom: 0,
  },
  incorrect: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.WHITE,
    maxHeight: '20px',
    maxWidth: '20px',
    backgroundColor: COLORS.RED_ORANGE,
    boxShadow: '0 0 5px 1px  rgba(122,122,122,0.5)',
    borderRadius: '20px',
    padding: '0!important',
    '&:hover': {
      backgroundColor: COLORS.RED_ORANGE,
    },
  },
  cardContent: {
    padding: 0,
    '&.MuiCardContent-root:last-child': {
      padding: 0,
    },
  },
  itemsContainer: {
    display: 'flex',
    minHeight: '48px',
  },
  collapseContainer: {
    display: 'inline-flex',
    borderTop: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    padding: 0,
    width: '100%',
  },
  arrowColumn: {
    display: 'flex',
    justifyContent: 'center',
    borderLeft: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    '& button': {
      padding: '0',
      margin: 'auto',
    },
  },
  eventsLog: {
    borderTop: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    padding: '10px',
  },
  table: {
    width: '100%',
    border: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  tableColumn: {
    width: '33%',
    borderBottom: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  tooltip: {
    backgroundColor: COLORS.WHITE,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    display: 'flex',
    flexDirection: 'column',
    fontSize: 11,
  },
  dialogTitle: {
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    fontSize: '16px',
    lineHeight: '17px',
    marginTop: '17px',
    color: COLORS.RED,
  },
  dialogSubTitle: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
    lineHeight: '12px',
    marginTop: '10px',
  },
  dialogContent: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '16px',
    lineHeight: '25px',
    color: COLORS.GRAY,
  },
  dialogDescription: {
    marginBottom: '24px',
  },
  errorCodes: {
    marginTop: '24px',
  },
  dialogActions: {
    marginBottom: '17px',
  },
  infoIcon: {
    paddingRight: '5px',
    '& button': {
      padding: '0',
    },
  },
  typeContainer: {
    backgroundColor: COLORS.BLUE_GRAY_SECONDARY,
  },
  typeWrapper: {
    color: COLORS.GRAY,
    padding: '5px 8px',
  },
  '@media (min-width: 1224px)': {
    itemsContainer: {
      minHeight: '61px',
    },
    infoIcon: {
      display: 'flex',
      justifyContent: 'center',
      paddingRight: '8px',
      marginLeft: 'auto',
      '& button': {
        padding: '0',
        margin: 'auto',
      },
    },
    typeWrapper: {
      color: COLORS.BLACK,
      fontSize: '12px',
      fontFamily: 'Cerebri Sans Pro Medium',
      padding: '5px 15px',
    },
  },
})

export default styles
