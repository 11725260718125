const BAD_GATEWAY = {
  performingScheduleMaintenance: 'Perferming Scheduled Maintenance',
  pageUnavailable: 'The page you are looking for is currently unavailable.',
  checkBackAgain: 'Please check back again soon.',
  backToPreviousPage: 'Back to Previous Page',
}

const ERROR_HAS_OCCURRED = {
  generalName: 'An Error Has Occurred',
  checkTryAgain: 'Please try again. Contact support if this',
  problemPersist: 'problem persist.',
  backToPreviousPage: 'Back to Previous Page',
}

const NETWORK_ERROR = {
  generalName: 'No Internet Connection',
  description1: 'Please check your internet connection and',
  description2: 'try again.',
  backToPreviousPage: 'Back to Previous Page',
}

export { BAD_GATEWAY, ERROR_HAS_OCCURRED, NETWORK_ERROR }
