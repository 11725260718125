import React, { useState, useRef } from 'react'
import { map } from 'lodash'
import { goToSummary } from '../../../../services/root/root-service'
import styles from './../style'
import { withStyles } from '@material-ui/core/styles/index'
import {
  MENU_ITEMS_KEYS,
  startsWith,
  MENU_ELEMENT_ROLES,
} from './services/menu-constants'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ButtonBase from '@material-ui/core/ButtonBase'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import classNames from 'classnames'
import LinkMaterial from '@material-ui/core/Link'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../../assets/icons/rzero-logo-pad.svg'
import { getMenuConfig } from './services/menu-service'
import { IMAGE_ALT } from '../../../../services/constants'

const MenuDesktopComponent = withStyles(styles)((props) => {
  const { classes, role, firstName, lastName, organizationName, logout } = props
  const [anchorElements, setAnchorElements] = useState({})

  const ref = useRef(null)

  const handleClose = () => {
    setAnchorElements({})
  }

  const menuConfig = getMenuConfig(
    role,
    { firstName, lastName },
    logout,
    handleClose
  )

  const location = useLocation()
  const { pathname: path } = location

  const handleOpen = (event) => {
    setAnchorElements({
      ...anchorElements,
      [event.currentTarget.id]: event.currentTarget,
    })
  }

  const generateSubTabs = (key, tabs) => {
    return (
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorElements[key]}
        open={Boolean(anchorElements[key])}
        classes={{ paper: classes.dropdownMenu }}
        onClose={handleClose}
      >
        <List
          component="div"
          style={{ width: ref.current?.offsetWidth }}
          disablePadding
        >
          {map(tabs, (value, key) => {
            const selectedConditionClasses = isSelected(key, value)
              ? classNames(classes.listItem, classes.selectedItem)
              : classes.listItem

            return (
              value.visible && (
                <ListItem
                  button
                  key={value.name}
                  className={
                    value.longLine
                      ? classNames(classes.longLine, classes.listItem)
                      : selectedConditionClasses
                  }
                  disabled={value.disabled}
                  onClick={value.onClick || handleClose}
                >
                  {value.icon && (
                    <ListItemIcon>{value.icon(path, classes)}</ListItemIcon>
                  )}
                  <ListItemText
                    primary={value.name}
                    className={
                      isSelected(key, value)
                        ? classNames(
                            classes.listItemTextSelected,
                            classes.listItemText
                          )
                        : classes.listItemText
                    }
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                  />
                </ListItem>
              )
            )
          })}
        </List>
      </Menu>
    )
  }

  const isSelected = (name, item) => {
    if (item.subTabs) {
      let result = false

      for (let key in item.subTabs) {
        result = result || startsWith(path)[key]
      }

      return result
    } else {
      return startsWith(path)[name]
    }
  }

  const getContent = (content) => {
    return map(content, (value, key) => {
      const selectedConditionClasses = value.disabled
        ? classNames(classes.itemName, classes.disabledLink)
        : classes.itemName

      let menuElement

      switch (value.role) {
        case MENU_ELEMENT_ROLES.dropdown:
          menuElement = (
            <div key={key}>
              <ButtonBase
                className={classes.userNameWrapper}
                onClick={handleOpen}
                aria-haspopup="true"
                ref={ref}
                id={key}
              >
                <Box
                  className={classNames(
                    classes.settingsWrapper,
                    classes.itemName
                  )}
                >
                  <div className={classes.userName}>{value.name}</div>
                  {key === MENU_ITEMS_KEYS.myAccount && (
                    <div className={classes.orgName}>{organizationName}</div>
                  )}
                </Box>
                <Icon className={classes.iconButton}>
                  <KeyboardArrowDownIcon />
                </Icon>
              </ButtonBase>
              {generateSubTabs(key, value.subTabs)}
            </div>
          )

          break

        case MENU_ELEMENT_ROLES.link:
          menuElement = (
            <LinkMaterial
              key={value.name}
              href={value.to}
              target={value.target}
              className={classNames(classes.itemName, classes.linkTab)}
              style={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              <div className={classes.menuItem} key={key}>
                {value.icon && value.icon(path, classes)}
                <Box
                  className={classNames(
                    classes.settingsWrapper,
                    classes.itemName
                  )}
                >
                  {value.name}
                </Box>
              </div>
            </LinkMaterial>
          )

          break

        case MENU_ELEMENT_ROLES.tab:
          menuElement = (
            <Link
              key={value.name}
              id={value.name}
              to={value.target || value.to}
              className={
                isSelected(key, value)
                  ? classNames(classes.itemName, classes.activeItemName)
                  : selectedConditionClasses
              }
              style={{ cursor: 'pointer' }}
            >
              <div className={classes.menuItem} key={key}>
                {value.icon && value.icon(path, classes)}
                <Box className={classes.settingsWrapper}>
                  {value.name}
                  {key === MENU_ITEMS_KEYS.myAccount && (
                    <span className={classes.orgName}>{organizationName}</span>
                  )}
                </Box>
              </div>
            </Link>
          )

          break

        default:
          menuElement = null

          break
      }

      return value.visible && menuElement
    })
  }

  const mainMenuItems = { ...menuConfig }
  delete mainMenuItems[MENU_ITEMS_KEYS.myAccount]

  return (
    <>
      <div className={classes.tabsWrapper}>
        <img
          alt={IMAGE_ALT.LOGO}
          src={logo}
          className={classes.logoImage}
          onClick={goToSummary}
        />
        <div className={classes.menuContainer}>{getContent(mainMenuItems)}</div>
      </div>

      <div className={classes.account}>
        {getContent({ myAccount: menuConfig.myAccount })}
      </div>
    </>
  )
})

export default MenuDesktopComponent
