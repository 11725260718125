import { concat, forEach, every, some } from 'lodash'
import { deeps, TREE_LEVELS } from '../../../../services/constants'

/*
 * select or unselect all child nodes
 * current - array of child nodes
 * bool - checked parent checkbox
 */
const traverseChecked = function (current, bool) {
  forEach(current, (item) => {
    item.checked = bool
    item.indeterminate = false

    if (item.isParent) {
      traverseChecked(item.children, bool)
    }
  })
}

const isAllChecked = (list) => every(list, (child) => child.checked)
const isAllUnchecked = (list) => every(list, (child) => !child.checked)
const isSomeChecked = (list) => some(list, (child) => child.checked)

const setupIndeterminateCheckbox = (nodeList) => {
  /*
   * set parent node as indeterminate if some of child node selected
   * list - array of child nodes
   * parent - id of parent node
   */
  const checkParent = (list, parent) => {
    forEach(list, (item) => {
      if (item.id === parent) {
        if (isAllChecked(item.children)) {
          item.checked = true
          item.indeterminate = false
        } else if (
          some(item.children, (child) => child.checked || child.indeterminate)
        ) {
          item.checked = false
          item.indeterminate = true
          item.expanded = true

          if (item.locationType !== TREE_LEVELS.PROPERTY) {
            checkParent(nodeList, item.parentId)
          }
        } else if (isAllUnchecked(item.children)) {
          item.checked = false
          item.indeterminate = false
        }
      } else {
        checkParent(item.children, parent)
      }
    })
  }

  const traverse = function (current) {
    forEach(current, (item) => {
      if (!item.checked && isSomeChecked(item.children)) {
        item.indeterminate = true
        item.expanded = true
      }

      if (item.parentId) {
        checkParent(nodeList, item.parentId)
      }

      if (item.isParent) {
        traverse(item.children)
      }
    })
  }

  traverse(nodeList)
}

const setupIndeterminateCheckboxWithForSingleSelection = (nodeList) => {
  const checkParent = (list, parent) => {
    forEach(list, (item) => {
      if (item.id === parent) {
        if (isAllChecked(item.children)) {
          item.indeterminate = false
        } else if (
          item.children.some((child) => child.checked || child.indeterminate)
        ) {
          item.checked = false
          item.indeterminate = true
          item.expanded = true

          if (item.locationType !== TREE_LEVELS.PROPERTY) {
            checkParent(nodeList, item.parentId)
          }
        } else if (isAllUnchecked(item.children)) {
          item.checked = false
          item.indeterminate = false
        }
      } else {
        checkParent(item.children, parent)
      }
    })
  }

  const traverse = function (current) {
    forEach(current, (item) => {
      if (!item.checked && isSomeChecked(item.children)) {
        item.indeterminate = true
        item.expanded = true
      }

      if (item.parentId) {
        checkParent(nodeList, item.parentId)
      }

      if (item.isParent) {
        traverse(item.children)
      }
    })
  }

  traverse(nodeList)
}

const selectAll = (nodes, isSelectAll) => {
  const nodeList = concat([], nodes)

  const traverse = function (current) {
    forEach(current, (item) => {
      item.checked = isSelectAll
      item.indeterminate = false

      if (item.children && item.children.length) {
        traverse(item.children)
      }
    })
  }

  traverse(nodeList)

  return nodeList
}

const selectAllByProperties = (nodes, isSelectAll) => {
  const nodeList = concat([], nodes)

  const traverse = function (current) {
    forEach(current, (item) => {
      item.checked = isSelectAll
      item.indeterminate = false

      const isProperty = item.locationType === TREE_LEVELS.PROPERTY

      if ((item.isBuildingsPrepared && isProperty) || !isProperty) {
        if (item.children && item.children.length) {
          traverse(item.children)
        }
      }
    })
  }

  traverse(nodeList)

  return nodeList
}

const changePropertyChecked = (property, checked) => {
  property.checked = checked
  property.indeterminate = false

  if (property.isBuildingsPrepared) {
    selectAll(property.children, checked)
  }
}

const changePropertyState = (property) => {
  if (every(property.children, ['checked', true])) {
    property.checked = true
    property.indeterminate = false
  } else if (
    every(property.children, (item) => !item.checked && !item.indeterminate)
  ) {
    property.checked = false
    property.indeterminate = false
  } else {
    property.checked = false
    property.indeterminate = true
  }
}

const changeChecked = (value, bool, mainLevel, locations) => {
  const nodeList = concat([], locations)

  /*
   * set parent node as unchecked because the child was unchecked
   * list - array of child nodes
   * parent - id of parent node
   */
  const uncheckParent = (list, parent) => {
    forEach(list, (item) => {
      if (item.id === parent) {
        item.checked = false

        if (
          item.children.some((child) => child.checked || child.indeterminate)
        ) {
          item.indeterminate = true
          item.expanded = true
        } else if (isAllUnchecked(item.children)) {
          item.checked = false
          item.indeterminate = false
        }

        if (item.locationType !== mainLevel) {
          uncheckParent(nodeList, item.parentId)
        }
      } else {
        uncheckParent(item.children, parent)
      }
    })
  }

  /*
   * set parent node as checked because the child was unchecked
   * list - array of child nodes
   * parent - id of parent node
   */
  const checkParent = (list, parent) => {
    forEach(list, (item) => {
      if (item.id === parent) {
        if (item.children.every((item) => item.checked)) {
          item.checked = true
          item.indeterminate = false

          if (item.locationType !== mainLevel) {
            checkParent(nodeList, item.parentId)
          }
        } else if (
          item.children.some((item) => item.checked || item.indeterminate)
        ) {
          item.checked = false
          item.indeterminate = true
          item.expanded = true

          if (item.locationType !== mainLevel) {
            checkParent(nodeList, item.parentId)
          }
        }
      } else {
        checkParent(item.children, parent)
      }
    })
  }

  const traverse = function (current) {
    forEach(current, (item) => {
      if (item.id === value) {
        item.checked = bool
        item.indeterminate = false

        if (item.parentId && !bool) {
          uncheckParent(nodeList, item.parentId)
        } else if (item.parentId && bool) {
          checkParent(nodeList, item.parentId)
        }

        if (item.isParent) {
          traverseChecked(item.children, bool)
        }
      } else if (item.isParent) {
        traverse(item.children)
      }
    })
  }

  traverse(nodeList)

  return nodeList
}

const changeSingleSelectableNode = (value, bool, locations) => {
  const nodeList = concat([], locations)

  const traverse = function (current) {
    forEach(current, (item) => {
      if (item.id === value) {
        item.checked = bool
        item.indeterminate = false

        if (item.isParent) {
          traverseChecked(item.children, false)
        }
      } else {
        item.checked = false

        if (item.isParent) {
          traverse(item.children)
        }
      }
    })
  }

  traverse(nodeList)

  return nodeList
}

export {
  changeChecked,
  changePropertyChecked,
  selectAll,
  changeSingleSelectableNode,
  setupIndeterminateCheckbox,
  setupIndeterminateCheckboxWithForSingleSelection,
  changePropertyState,
  selectAllByProperties,
}
