import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  circularProgress: {
    color: (props) => props.circlecolor,
    height: (props) => props.size,
    width: (props) => props.size,
  },
  label: {
    fontFamily: 'Cerebri Sans Pro Bold',
    fontSize: (props) => props.fontSize,
    color: (props) => props.circlecolor,
    letterSpacing: '0.4px',
  },
})

export default useStyles
