import React, { useState } from 'react'
import { connect } from 'react-redux'
import { forIn, keys, pickBy } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import ChipsFilterModal from '../../../../../components/_shared/modals/chipsFilter/ChipsFilterModal'
import actions from '../services/arcDeviceList-actions'
import styles from './style'
import {
  DEVICE_SORTING,
  DEFAULT_SORTING_LABEL,
} from '../services/arcDeviceList-constants'
import { DATA_SORTING, ACTION_LABELS } from '../../../../../services/constants'
import { checkConnection } from '../../../../../services/root/root-service'
import { gaEventAction } from '../../../../../services/googleAnalytics/ga-actions'

const SortButton = (props) => {
  const { sorting, classes, changeSorting, gaEventSend } = props

  const [open, setOpen] = useState(false)
  const [label, setLabel] = useState(
    sorting.RECENTLY_USED ? DEFAULT_SORTING_LABEL : DEVICE_SORTING.DEVICE_SN
  )
  const [statusFilterSorting, setStatusFilterSorting] = useState({ ...sorting })

  const getSelectedSorting = (object) => keys(pickBy(object))[0]

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setStatusFilterSorting({ ...sorting })
  }

  const changeSelected = (name) => {
    const updatedSorting = forIn(statusFilterSorting, (value, key, object) => {
      object[key] = key === name
    })

    setStatusFilterSorting({ ...updatedSorting })
  }

  const onApply = () => {
    const selectedSorting = getSelectedSorting(statusFilterSorting)

    setLabel(DEVICE_SORTING[selectedSorting])
    changeSorting(selectedSorting)
    gaEventSend('device_report_sort', {
      event_label: DEVICE_SORTING[selectedSorting],
    })

    handleClose()
  }

  return (
    <>
      <Chip
        onClick={handleClickOpen}
        className={classes.filterChip}
        label={`${DATA_SORTING.label}: ${label}`}
        variant="outlined"
      />
      <ChipsFilterModal
        open={open}
        constants={DEVICE_SORTING}
        onSubmitLabel={ACTION_LABELS.apply}
        filterName={DATA_SORTING.label}
        statusFilter={statusFilterSorting}
        onApply={onApply}
        onChange={changeSelected}
        onClose={handleClose}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { ARCDeviceListReducer },
  } = state

  return {
    sorting: ARCDeviceListReducer.sorting,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSorting: (key) =>
      dispatch(checkConnection(() => actions.changeSorting(key))),
    gaEventSend: gaEventAction(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SortButton))
