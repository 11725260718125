const styles = (theme) => ({
  modalTitle: {
    textAlign: 'center',
    marginTop: '14px',
    marginBottom: '6px ',
    paddingLeft: '16px',
  },
  dialogActions: {
    padding: '12px 8px 36px',
  },
  modalTitleContainer: {
    paddingBottom: 0,
    fontSize: 'inherit',
  },
  dialogContent: {
    paddingBottom: '35px',
  },
  submitButton: {
    marginTop: '20px',
  },
  checkbox: {
    '& svg': {
      width: '15px',
      height: '15px',
    },
  },
  checkboxLabel: {
    lineHeight: '14px',
    display: 'flex',
    paddingLeft: '10px',
    '& span': {
      fontSize: '12px',
    },
  },
  '@media (min-width: 1224px)': {
    dialogActions: {
      padding: '16px 70px 40px',
    },
    submitButton: {
      width: '100%',
    },
    dialogContent: {
      padding: '16px 70px 35px',
    },
    checkboxLabel: {
      '& span': {
        fontSize: '16px',
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
    checkbox: {
      '& svg': {
        width: '18px',
        height: '18px',
      },
    },
  },
  outlined: {
    [theme.breakpoints.up('lg')]: {
      padding: '15.5px 14px',
    },
  },
})

export default styles
