import { COLORS } from '../../../../../../services/constants'

const styles = () => ({
  recentUsageContainer: {
    padding: '25px',
    border: `1px solid ${COLORS.LIGHT_SILVER}`,
    '& > *': {
      marginBottom: '12px',
      color: COLORS.GRAY,
    },
  },
  wrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 115px)',
    paddingBottom: '15px',
    paddingLeft: '7%',
    paddingRight: '7%',
  },
  locations: {
    minWidth: '100%',
    marginTop: '25px',
  },
  fieldValue: {
    marginLeft: '5px',
  },
  saveButton: {
    width: '163px',
    height: '27px',
    fontSize: '12px',
    marginBottom: '10px',
  },
  '@media (min-width: 1224px)': {
    contentWrapper: {
      backgroundColor: COLORS.WHITE,
    },
    formWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '42px 0 46px 0',
      borderBottom: `1px solid ${COLORS.GRAY_IRON}`,
      '& > *': {
        width: '30%',
      },
      '& > :first-child': {
        marginRight: '20px',
      },
      paddingLeft: '200px',
    },
    saveButton: {
      position: 'relative',
      bottom: '-377px',
      minWidth: '190px',
      width: '200px',
      left: '6%',
      height: '45px',
      fontSize: '16px',
    },
    wrapper: {
      paddingLeft: '15%',
      paddingRight: '15%',
    },
    recentUsageContainer: {
      boxSizing: 'border-box',
      backgroundColor: COLORS.WHITE,
      border: 'none',
      borderRight: `1px solid ${COLORS.GRAY_IRON}`,
      width: '50%',
    },
    infoWrapper: {
      display: 'flex',
      minHeight: '225px',
      marginTop: 0,
    },
  },
  '@media (min-width: 1650px)': {
    saveButton: {
      left: '10%',
    },
  },
})

export default styles
