import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './assets/fonts/fonts.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import configureStore from './store/store'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import App from './App'
import mobileTheme from './mobileTheme'

export const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={mobileTheme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
