import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import styles from './../../style'
import SimpleModal from '../../../../components/_shared/modals/simpleModal/SimpleModal'
import actions from '../../services/users-actions'
import { validateLocationName } from '../../../Locations/service/location-service'
import {
  ADD_PROPERTY_LABELS,
  DEVICE_MAX_PROPERTY_LENGTH,
} from '../../services/users-constants'
import { checkConnection } from '../../../../services/root/root-service'

const AddPropertyComponent = withStyles(styles)((props) => {
  const {
    classes,
    addProperty,
    addPropertyError,
    resetError,
    allowedProperties,
  } = props

  const [validationError, setValidationError] = useState('')
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    resetError()
    setOpen(true)
  }

  const handleClose = () => {
    setValidationError('')

    setOpen(false)
  }

  const createProperty = (name) => {
    const validationErrorMessage = validateLocationName(name)

    if (validationErrorMessage) {
      setValidationError(validationErrorMessage)
    } else {
      addProperty(name.trim(), handleClose, allowedProperties)
    }
  }

  return (
    <div className={classes.locationButton}>
      <Link
        variant="body2"
        color="textSecondary"
        className={classes.locationLink}
        onClick={handleClickOpen}
      >
        {ADD_PROPERTY_LABELS.addProperty}
      </Link>
      <SimpleModal
        isOpen={open}
        error={addPropertyError || validationError}
        onClose={handleClose}
        onSubmit={createProperty}
        inputProps={{ maxLength: DEVICE_MAX_PROPERTY_LENGTH }}
        modalTitle={ADD_PROPERTY_LABELS.modalTitle}
        placeholder={ADD_PROPERTY_LABELS.placeholder}
        actionLabel={ADD_PROPERTY_LABELS.actionLabel}
      />
    </div>
  )
})

const mapStateToProps = (state) => ({
  addPropertyError: state.usersReducer.addPropertyError,
})

const mapDispatchToProps = (dispatch) => {
  return {
    addProperty: (name, handleClose, allowedProperties) =>
      dispatch(
        checkConnection(() =>
          actions.addProperty(name, handleClose, allowedProperties)
        )
      ),
    resetError: () => dispatch(actions.resetError()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPropertyComponent)
