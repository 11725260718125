import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { every, indexOf } from 'lodash'
import {
  EMAIL_PREF_INITIAL_STATE,
  EMAIL_PREF_LABELS,
  EMAIL_PREF_NAMES,
  EMAIL_PREF_NAMES_MAP,
} from '../../../Users/services/users-constants'
import styles from './style'

const EmailPreferencesComponent = withStyles(styles)((props) => {
  const { classes, onChange, state } = props
  const { dailyReport, weeklyReport, monthlyReport, outAll } = state

  const onClick = (event) => {
    const emailPref = { ...state }
    const { name, checked } = event.target

    if (name === EMAIL_PREF_NAMES_MAP.outAll && checked) {
      onChange(EMAIL_PREF_INITIAL_STATE)
    } else {
      if (indexOf(EMAIL_PREF_NAMES, name) !== -1 && checked) {
        emailPref.outAll = false
        emailPref[name] = checked
      } else {
        emailPref[name] = checked

        emailPref.outAll = every(emailPref, (item) => !item)
      }

      onChange(emailPref)
    }
  }

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <Typography variant="subtitle2" className={classes.emailPref}>
        {EMAIL_PREF_LABELS.title}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.description}
        color="textSecondary"
      >
        {EMAIL_PREF_LABELS.description}
      </Typography>
      <FormGroup>
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          control={
            <Checkbox
              checked={dailyReport}
              onChange={onClick}
              checkedIcon={<CheckBoxOutlinedIcon />}
              name="dailyReport"
              color="primary"
            />
          }
          label={EMAIL_PREF_LABELS.daily}
        />
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          control={
            <Checkbox
              checked={weeklyReport}
              checkedIcon={<CheckBoxOutlinedIcon />}
              onChange={onClick}
              name="weeklyReport"
              color="primary"
            />
          }
          label={EMAIL_PREF_LABELS.weekly}
        />
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          control={
            <Checkbox
              checked={monthlyReport}
              checkedIcon={<CheckBoxOutlinedIcon />}
              onChange={onClick}
              name="monthlyReport"
              color="primary"
            />
          }
          label={EMAIL_PREF_LABELS.monthly}
        />
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          control={
            <Checkbox
              checked={outAll}
              checkedIcon={<CheckBoxOutlinedIcon />}
              onChange={onClick}
              name="outAll"
              color="primary"
            />
          }
          label={EMAIL_PREF_LABELS.outAll}
        />
        <FormHelperText
          color="textSecondary"
          className={classes.outAllDescription}
        >
          {EMAIL_PREF_LABELS.outAllDescription}
        </FormHelperText>
      </FormGroup>
    </FormControl>
  )
})

export default EmailPreferencesComponent
