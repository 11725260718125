import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../style'
import {
  FIELDS,
  PROGRESS_LABELS,
} from '../../../../services/dashboard-constants'
import { STATUS_FILTER_VALUES } from '../../../../../../services/constants'
import {
  getCircularValue,
  getColor,
  getIsCircularProperty,
  getRemainingCycleTime,
} from '../../../../services/related/transaction-service'
import ProgressComponent from './ProgressComponent'
import ActivityExtraInfo from '../ActivityExtraInfo'

const ActivityDetailsComponent = withStyles(styles)((props) => {
  const {
    classes,
    activity,
    activity: { txStatus, details = {} },
    timezone,
    activityEvents,
    isMobile,
  } = props

  const isInProgress = txStatus === STATUS_FILTER_VALUES.in_progress
  const isCircular = getIsCircularProperty(activity)
  const timeRemaining = getRemainingCycleTime(
    details[FIELDS.estCompletion],
    txStatus,
    details
  )
  const [counter, setCounter] = useState(timeRemaining)
  const initialValue = getCircularValue(activity, timeRemaining)
  const progressLabel =
    details[FIELDS.phase] === FIELDS.countdown
      ? PROGRESS_LABELS.countdown
      : PROGRESS_LABELS.stopped
  const [progressValue, setProgressValue] = useState(initialValue)

  useEffect(() => {
    let isMounted = true

    counter >= 1000 &&
      isInProgress &&
      setTimeout(() => {
        if (isMounted) {
          setCounter(counter - 1000)
          setProgressValue(getCircularValue(activity, counter - 1000))
        }
      }, 1000)

    return () => (isMounted = false)
  }, [counter])

  return (
    <div className={classes.infoContainer}>
      <ActivityExtraInfo
        activity={activity}
        counter={counter}
        isInProgress={isInProgress}
        timezone={timezone}
        isMobile={isMobile}
        activityEvents={activityEvents}
        timeRemaining={timeRemaining}
      />
      <div className={classes.progress}>
        <ProgressComponent
          color={getColor(activity)}
          progressValue={progressValue}
          progressLabel={progressLabel}
          isCircular={isCircular}
        />
      </div>
    </div>
  )
})

export default ActivityDetailsComponent
