import React, { useEffect, useState } from 'react'
import { map } from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { withMediaQuery } from '../../../../services/root/root-service'
import { withStyles } from '@material-ui/core/styles'
import styles from './../../../../components/_shared/modals/simpleModal/style'
import DevicesList from './DevicesList'
import { connect } from 'react-redux'
import actions from '../../services/dashboard-actions'
import { DEVICE_LABELS } from '../../services/dashboard-constants'
import { filterDevicesByType } from '../../services/related/filter-service'
import CloseButton from '../../../../components/_shared/modals/closeButton/CloseButton'
import { gaEventAction } from '../../../../services/googleAnalytics/ga-actions'

const DeviceFilterModal = ({
  isOpen,
  isMobile,
  onClose,
  setSelectedDevices,
  selectedDevices,
  isAllDevicesSelected,
  includeDeletedDevices,
  devices,
  error,
  devicesTypesFilter,
  classes,
  gaEventSend,
}) => {
  const [selected, setSelected] = useState(selectedDevices)
  const [isAllSelected, setAllSelected] = useState(isAllDevicesSelected)
  const [includeDeleted, setIncludeDeleted] = useState(includeDeletedDevices)
  const filteredDevices = filterDevicesByType(devices, devicesTypesFilter)

  useEffect(() => {
    if (
      isAllDevicesSelected &&
      filteredDevices.length &&
      selectedDevices.length !== filteredDevices.length
    ) {
      const newSelected = map(filteredDevices, 'id')

      setSelectedDevices(newSelected, includeDeletedDevices)
      setSelected(newSelected)
      setIncludeDeleted(includeDeletedDevices)
    }
  }, [isAllDevicesSelected, devices, selectedDevices, includeDeletedDevices])

  useEffect(() => {
    if (isAllSelected !== isAllDevicesSelected) {
      setAllSelected(isAllDevicesSelected)
    }
  }, [isAllDevicesSelected])

  useEffect(() => {
    if (selected.length !== selectedDevices.length) {
      setSelected(selectedDevices)
    }
  }, [selectedDevices])

  const applyChanges = () => {
    setSelectedDevices(selected, includeDeleted)
    gaEventSend('device_filter', { event_label: selected.length })
    onClose()
  }

  const handleClose = () => {
    setSelected(selectedDevices)
    setAllSelected(isAllDevicesSelected)
    setIncludeDeleted(includeDeletedDevices)

    onClose()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          className={classes.modalTitleContainer}
          disableTypography={true}
        >
          <Typography
            variant={isMobile ? 'subtitle2' : 'h4'}
            className={classes.modalTitle}
          >
            {DEVICE_LABELS.deviceFilter}
          </Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <CloseButton onCloseModal={handleClose} />
        </DialogTitle>
        <DialogContent>
          <DevicesList
            selected={selected}
            setSelected={setSelected}
            isAllSelected={isAllSelected}
            setAllSelected={setAllSelected}
            includeDeleted={includeDeleted}
            setIncludeDeleted={setIncludeDeleted}
            filteredDevices={filteredDevices}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={applyChanges} variant="contained" color="primary">
            {DEVICE_LABELS.apply}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedDevices: state.dashboardReducer.deviceFilter.selectedDevices,
  isAllDevicesSelected:
    state.dashboardReducer.deviceFilter.isAllDevicesSelected,
  includeDeletedDevices: state.dashboardReducer.deviceFilter.includeDeleted,
  devices: state.dashboardReducer.devices,
  devicesTypesFilter: state.dashboardReducer.devicesTypesFilter,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedDevices: (selectedDevices, includeDeleted) =>
    dispatch(actions.setSelectedDevices(selectedDevices, includeDeleted)),
  gaEventSend: gaEventAction(dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(DeviceFilterModal)))
