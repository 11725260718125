const authorizationActions = {}

authorizationActions.actionTypes = {
  LOGIN_TO_APP: '{AUTHORIZATION} User Login',
  LOGIN_TO_APP_SUCCEEDED: '{AUTHORIZATION} User Login Succeeded',
  LOGIN_TO_APP_FAILED: '{AUTHORIZATION} User Login Failed',

  LOGOUT: '{AUTHORIZATION} User Logout',
  LOGOUT_SUCCEEDED: '{AUTHORIZATION} User Logout Succeeded',
  LOGOUT_FAILED: '{AUTHORIZATION} User Logout Failed',

  RESET_PASSWORD: '{AUTHORIZATION} Reset Password',
  RESET_PASSWORD_SUCCEEDED: '{AUTHORIZATION} Reset Password Succeeded',
  RESET_PASSWORD_FAILED: '{AUTHORIZATION} Reset Password Failed',

  CHECK_TEMPORARY_PASSWORD: '{AUTHORIZATION} Check Temporary Password',
  CHECK_TEMPORARY_PASSWORD_SUCCEEDED:
    '{AUTHORIZATION} Check Temporary Password Succeeded',
  CHECK_TEMPORARY_PASSWORD_FAILED:
    '{AUTHORIZATION} Check Temporary Password Failed',

  SETUP_NEW_PASSWORD: '{AUTHORIZATION} Setup New Password',
  SETUP_NEW_PASSWORD_SUCCEEDED: '{AUTHORIZATION} Setup New Password Succeeded',
  SETUP_NEW_PASSWORD_FAILED: '{AUTHORIZATION} Setup New Password Failed',

  RESET_LOGIN_ERRORS: '{AUTHORIZATION} Reset Login Errors',

  LOGIN_FROM_FACTORY: '{AUTHORIZATION} Login From Factory Portal',
  LOGIN_FROM_FACTORY_SUCCEEDED:
    '{AUTHORIZATION} Login From Factory Portal Succeeded',
  LOGIN_FROM_FACTORY_FAILED: '{AUTHORIZATION} Login From Factory Portal Failed',

  GET_PROVIDERS_LIST: '{AUTHORIZATION} Get Providers List',
  GET_PROVIDERS_LIST_SUCCEEDED: '{AUTHORIZATION} Get Providers List Succeeded',
  GET_PROVIDERS_LIST_FAILED: '{AUTHORIZATION} Get Providers List Failed',

  GET_OKTA_IS_ENABLED_FLAG: '{AUTHORIZATION} Get Okta is Enabled Flag',
  GET_OKTA_IS_ENABLED_FLAG_SUCCEEDED:
    '{AUTHORIZATION} Get Okta is Enabled Flag Succeeded',
  GET_OKTA_IS_ENABLED_FLAG_FAILED:
    '{AUTHORIZATION} Get Okta is Enabled Flag Failed',

  SET_ERROR: '{AUTHORIZATION} Set Error',
}

authorizationActions.login = (body, onSuccess) => {
  return {
    type: authorizationActions.actionTypes.LOGIN_TO_APP,
    payload: {
      body,
      onSuccess,
    },
  }
}

authorizationActions.loginSucceeded = (data) => {
  return {
    type: authorizationActions.actionTypes.LOGIN_TO_APP_SUCCEEDED,
    payload: {
      data,
    },
  }
}

authorizationActions.loginFailed = (message) => {
  return {
    type: authorizationActions.actionTypes.LOGIN_TO_APP_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.logout = (requestData) => {
  return {
    type: authorizationActions.actionTypes.LOGOUT,
    payload: {
      requestData,
    },
  }
}

authorizationActions.logoutSucceeded = (data) => {
  return {
    type: authorizationActions.actionTypes.LOGOUT_SUCCEEDED,
    payload: {
      data,
    },
  }
}

authorizationActions.logoutFailed = (message) => {
  return {
    type: authorizationActions.actionTypes.LOGOUT_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.resetPassword = (login, onSuccess) => {
  return {
    type: authorizationActions.actionTypes.RESET_PASSWORD,
    payload: {
      login,
      onSuccess,
    },
  }
}

authorizationActions.resetPasswordSucceeded = () => {
  return {
    type: authorizationActions.actionTypes.RESET_PASSWORD_SUCCEEDED,
  }
}

authorizationActions.resetPasswordFailed = (message) => {
  return {
    type: authorizationActions.actionTypes.RESET_PASSWORD_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.checkTemporaryPassword = (body, onSuccess) => {
  return {
    type: authorizationActions.actionTypes.CHECK_TEMPORARY_PASSWORD,
    payload: {
      body,
      onSuccess,
    },
  }
}

authorizationActions.checkTemporaryPasswordSucceeded = () => {
  return {
    type: authorizationActions.actionTypes.CHECK_TEMPORARY_PASSWORD_SUCCEEDED,
  }
}

// deprecated
authorizationActions.checkTemporaryPasswordFailed = (message) => {
  return {
    type: authorizationActions.actionTypes.CHECK_TEMPORARY_PASSWORD_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.setupNewPassword = ({
  login,
  token,
  newPassword,
  onSuccess,
}) => {
  return {
    type: authorizationActions.actionTypes.SETUP_NEW_PASSWORD,
    payload: {
      login,
      token,
      newPassword,
      onSuccess,
    },
  }
}

authorizationActions.setupNewPasswordSucceeded = () => {
  return {
    type: authorizationActions.actionTypes.SETUP_NEW_PASSWORD_SUCCEEDED,
  }
}

authorizationActions.resetLoginErrors = () => {
  return {
    type: authorizationActions.actionTypes.RESET_LOGIN_ERRORS,
  }
}

authorizationActions.setupNewPasswordFailed = (message) => {
  return {
    type: authorizationActions.actionTypes.SETUP_NEW_PASSWORD_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.loginFromFactory = () => {
  return {
    type: authorizationActions.actionTypes.LOGIN_FROM_FACTORY,
  }
}

authorizationActions.loginFromFactorySucceeded = (data) => {
  return {
    type: authorizationActions.actionTypes.LOGIN_FROM_FACTORY_SUCCEEDED,
    payload: {
      data,
    },
  }
}

authorizationActions.loginFromFactoryFailed = (message) => {
  return {
    type: authorizationActions.actionTypes.LOGIN_FROM_FACTORY_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.getProvidersList = () => {
  return {
    type: authorizationActions.actionTypes.GET_PROVIDERS_LIST,
  }
}

authorizationActions.getProvidersListSucceeded = (list) => {
  return {
    type: authorizationActions.actionTypes.GET_PROVIDERS_LIST_SUCCEEDED,
    payload: {
      list,
    },
  }
}

authorizationActions.getProvidersListFailed = (message) => {
  return {
    type: authorizationActions.actionTypes.GET_PROVIDERS_LIST_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.getOktaIsEnabledFlag = () => {
  return {
    type: authorizationActions.actionTypes.GET_OKTA_IS_ENABLED_FLAG,
  }
}

authorizationActions.getOktaIsEnabledFlagSucceeded = (enabled) => {
  return {
    type: authorizationActions.actionTypes.GET_OKTA_IS_ENABLED_FLAG_SUCCEEDED,
    payload: {
      enabled,
    },
  }
}

authorizationActions.getOktaIsEnabledFlagFailed = (message) => {
  return {
    type: authorizationActions.actionTypes.GET_OKTA_IS_ENABLED_FLAG_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.setError = (message) => {
  return {
    type: authorizationActions.actionTypes.SET_ERROR,
    payload: {
      message,
    },
  }
}

export default authorizationActions
