import { forIn, isEmpty, map, isNull, keys } from 'lodash'
import * as moment from 'moment'
import filterQuery from '../../../../services/filterQuery-service'
import {
  getDeviceSubTypeQuery,
  prepareDeviceSubTypeQuery,
} from '../../../../services/root/root-service'
import { prepareTimeFilter } from './filter-service'
import { DEVICE_TYPE, DEVICE_TYPES } from '../../../../services/constants'

// query=(transactionStatus in ['in_progress', 'completed'] and transactionStartedAt between ['2020-10-05T00:09:15', '2020-10-05T23:09:15'])
const prepareParams = (
  period = {},
  statuses,
  types,
  deviceFilter,
  locations,
  unknownLocationsFlag,
  deletedLocationsFlag,
  locationPref,
  timezone,
  pageNum,
  pageSize,
  quickCount = false,
  type
) => {
  const statusArray = []
  const typesFilter = {}
  let filtersArray,
    zoneId = ''

  let currentPageNum = pageNum

  // filter by date
  const dateQuery = filterQuery.getDatePeriodString(
    period.startDate,
    period.endDate,
    filterQuery.templates.array,
    'modified',
    'between',
    timezone
  )

  if (moment(period.endDate).isSame(period.startDate, 'day')) {
    currentPageNum = 1
  }

  // filter by status
  forIn(statuses, function (item, key) {
    if (item) {
      statusArray.push("'" + key + "'")
    }
  })

  const statusQuery = filterQuery.getArrayString(
    statusArray,
    filterQuery.templates.array,
    'txStatus',
    'in'
  )

  const statusQueryArrayForPause = [
    statusQuery,
    filterQuery.templates.default('wasEventInState', 'custom', 'interrupted'),
    filterQuery.templates.default('wasEventInState', 'custom', 'pause'),
  ]

  // filter by type
  forIn(types, function (item, key) {
    if (item) {
      typesFilter[key] = item
    }
  })

  const typesQuery = isEmpty(typesFilter)
    ? false
    : createQueryForDeviceTypes(typesFilter)

  if (statuses.paused) {
    filtersArray = [
      dateQuery,
      typesQuery,
      filterQuery.createQueryStringFromArray(statusQueryArrayForPause, 'or'),
    ]
  } else {
    filtersArray = [dateQuery, statusQuery, typesQuery]
  }

  const isNullTemplate = unknownLocationsFlag && `locationId isNull 'null'`
  const includeDeleteTemplate =
    deletedLocationsFlag && `locationRemoved eq ${!!deletedLocationsFlag}`

  const {
    selectedDevices,
    includeDeleted: includeDeletedDevices,
    isAllDevicesSelected,
  } = deviceFilter
  const devicesArray = map(selectedDevices, (item) => +item)

  const includeDeletedDevicesTemplate =
    isAllDevicesSelected && includeDeletedDevices
      ? ''
      : `deviceLinked ${includeDeletedDevices ? 'neq' : 'eq'} true`

  const deviceQuery =
    !isAllDevicesSelected && devicesArray.length
      ? filterQuery.getArrayString(
          devicesArray,
          filterQuery.templates.array,
          'deviceId',
          'in'
        )
      : ''

  const deviceArray = deviceQuery ? [deviceQuery] : []
  let weHaveNoDevices = false

  if (deviceArray.length || includeDeletedDevicesTemplate) {
    if (
      !deviceArray.length &&
      includeDeletedDevicesTemplate === 'deviceLinked eq true' &&
      !isAllDevicesSelected
    ) {
      weHaveNoDevices = true
    }

    deviceArray.push(includeDeletedDevicesTemplate)

    const devicesTemplate = filterQuery.createQueryStringFromArray(
      deviceArray,
      selectedDevices.length && !isAllDevicesSelected && includeDeletedDevices
        ? 'or'
        : 'and'
    )

    filtersArray.push(devicesTemplate)
  }

  if (weHaveNoDevices) {
    const noLocations = getQueryForNoDevices()

    filtersArray.push(noLocations)
  } else {
    // if all checkbox selected no send location query
    if (!(!locationPref && includeDeleteTemplate && unknownLocationsFlag)) {
      if (locations) {
        let locationQuery = ''

        if (locationPref) {
          const locQuery = filterQuery.getQueryString(
            isEmpty(locations) ? '-1' : locations.join(','),
            filterQuery.templates.default,
            'locationId',
            'custom'
          )

          locationQuery = includeDeleteTemplate
            ? filterQuery.createQueryStringFromArray(
                [locQuery, includeDeleteTemplate],
                'or'
              )
            : locQuery
        } else {
          // if 'select all' checkbox selected
          const allLocationsSelected = filterQuery.getQueryString(
            '',
            filterQuery.templates.default,
            'locationId',
            'isNotNull'
          )

          locationQuery = !includeDeleteTemplate
            ? filterQuery.createQueryStringFromArray(
                [
                  allLocationsSelected,
                  filterQuery.getQueryString(
                    false,
                    filterQuery.templates.array,
                    'locationRemoved',
                    'eq'
                  ),
                ],
                'and'
              )
            : allLocationsSelected
        }

        const templatesArray = locationQuery ? [locationQuery] : []

        if (isNullTemplate) {
          templatesArray.push(isNullTemplate)
        }

        const locationsTemplates = filterQuery.createQueryStringFromArray(
          templatesArray,
          'or'
        )

        filtersArray.push(locationsTemplates)
      } else {
        filtersArray.push(includeDeleteTemplate)

        if (unknownLocationsFlag) {
          filtersArray.push(isNullTemplate)
        }
      }
    }
  }

  const queryString = filterQuery.createQueryStringFromArray(
    filtersArray,
    'and'
  )

  if (type === 'report') {
    const localZone = moment().format('Z')

    zoneId = `&zoneId=${isNull(timezone) ? localZone.replace('+', '%2b') : ''}`
  }

  return (
    `query=${queryString}&pageNum=${
      currentPageNum - 1
    }&pageSize=${pageSize}&quickCount=${quickCount}&order.modified=desc` +
    zoneId
  )
}

const getQueryForNoDevices = () => {
  return filterQuery.getQueryString(
    '-1',
    filterQuery.templates.default,
    'locationId',
    'custom'
  )
}

const createQueryForDeviceTypes = (
  devicesTypesFilter,
  forDeviceRest = false
) => {
  if (!devicesTypesFilter || isEmpty(devicesTypesFilter)) {
    return
  }

  const devicesTypesList = keys(devicesTypesFilter)

  const typesQuery = map(devicesTypesList, (type) => {
    if (forDeviceRest) {
      if (
        DEVICE_TYPES.uvgi.deviceSubType === type ||
        DEVICE_TYPES.uvc.deviceSubType === type
      ) {
        return prepareDeviceSubTypeQuery(DEVICE_TYPE.uvgi, type)
      } else {
        return filterQuery.getQueryString(
          type,
          filterQuery.templates.default,
          'deviceType',
          'eq'
        )
      }
    } else {
      return getDeviceSubTypeQuery(type)
    }
  })

  return `(${typesQuery.join(' or ')})`
}

const getDateQuery = (activities, timezone, isLast) => {
  if (!activities.items.length) {
    return
  }

  const lastActivity = isLast ? activities.items.pop() : activities.items[0]

  const timeStarted = lastActivity.modified
  const timezoneTime = timezone
    ? moment.utc(timeStarted).utcOffset(timezone)
    : moment.utc(timeStarted).local()

  return prepareTimeFilter(
    {
      custom: true,
    },
    {
      endDate: null,
      startDate: timezoneTime,
    },
    timezone
  )
}

export { prepareParams, getDateQuery, createQueryForDeviceTypes }
