import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import ARCLocationButton from '../../../_shared/components/addDeviceFunctionality/ARCLocationButton'
import actions from '../services/arcDeviceList-actions'
import AddDeviceButton from '../../../_shared/components/addDeviceFunctionality/AddDeviceButton'
import { checkConnection } from '../../../../../services/root/root-service'
import CheckboxModalButton from '../../../../../components/_shared/modals/chekboxModal/CheckboxModalButton'
import AssignCycleTimeControl from '../../../../Locations/components/assignCycleTimeModal/AssignCycleTimeControl'
import {
  SET_DEFAULT_CYCLE_TIMES,
  RESET_CUSTOM_CYCLE_TIME_PAGE,
} from '../services/arcDeviceList-constants'
import style from './style'

const ActionsWrapper = (props) => {
  const {
    locationFilter,
    devices,
    failedSerialNumber,
    onAddDevice,
    setGlobalTimeout,
    resetError,
    defaultCycleTime,
    enableSetCycle,
    error,
    resetActionError,
    setupLocationsFilter,
    classes,
  } = props

  return (
    <div className={classes.actionsContainer}>
      <AddDeviceButton
        onSubmit={onAddDevice}
        resetActionError={resetActionError}
        locationFilter={locationFilter}
        error={error}
        devices={devices}
        failedSerialNumber={failedSerialNumber}
      >
        <ARCLocationButton
          locationFilter={locationFilter}
          setupLocationsFilter={setupLocationsFilter}
        />
      </AddDeviceButton>
      {enableSetCycle && (
        <CheckboxModalButton
          {...SET_DEFAULT_CYCLE_TIMES}
          buttonSubLabel={`${defaultCycleTime} min`}
          ContentComponent={(props) => <AssignCycleTimeControl {...props} />}
          inputProps={{
            timeout: defaultCycleTime,
          }}
          isWarning
          warningProps={{
            modalTitle: RESET_CUSTOM_CYCLE_TIME_PAGE.title,
            dialogHelperText: RESET_CUSTOM_CYCLE_TIME_PAGE.text,
            dialogHelperText2: RESET_CUSTOM_CYCLE_TIME_PAGE.text2,
            buttonLabel: RESET_CUSTOM_CYCLE_TIME_PAGE.confirm,
          }}
          error={error}
          resetError={resetError}
          onConfirm={setGlobalTimeout}
        />
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(actions.resetError()),
  resetActionError: () => dispatch(actions.resetActionError()),
  setGlobalTimeout: (value, close, applyToAllLocations) =>
    dispatch(
      checkConnection(() =>
        actions.setGlobalTimeout(value, close, applyToAllLocations)
      )
    ),
  setupLocationsFilter: (nodes) =>
    dispatch(checkConnection(() => actions.setupLocationsFilter(nodes))),
})

const mapStateToProps = (state) => {
  const {
    devicesReducer: { ARCDeviceListReducer },
    rootReducer,
  } = state

  return {
    defaultCycleTime: rootReducer.defaultCycleTime,
    locationFilter: ARCDeviceListReducer.locationFilter,
    devices: ARCDeviceListReducer.devices,
    failedSerialNumber: ARCDeviceListReducer.failedSerialNumber,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ActionsWrapper))
