import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import rootActions from '../../services/root/root-actions'
import DashboardPagination from './components/pagination/DashboardPagination'
import styles from './style'
import StatusFilter from './components/StatusFilter'
import DateFilter from './components/DateFilter'
import CardsContainerComponent from './components/CardsContainer'
import LocationFilter from './components/locationFilter/LocationFilter'
import actions from './services/dashboard-actions'
import { connect } from 'react-redux'
import {
  DASHBOARD_TITLE,
  DASHBOARD_PAGE_TITLE,
} from './services/dashboard-constants'
import ResetFilter from './components/ResetFilters'
import DownloadCSVButton from './components/DownloadCSVButton'
import {
  checkConnection,
  withMediaQuery,
} from '../../services/root/root-service'
import DeviceFilter from './components/DeviceFilter/DeviceFilter'
import NetworkError from '../../components/_shared/errors/NetworkError'
import DeviceTypeFilter from './components/DeviceTypeFilter'
import { isUndefined } from 'lodash'
import classnames from 'classnames'

const DashboardContainerComponent = (props) => {
  const {
    loadDevices,
    devicesTypesFilter,
    startPollingModification,
    goToNextPage,
    isMobile,
    stopPollingModification,
    getLocationModificationId,
    isOnline,
    polling,
    isBannerPresent,
    classes,
  } = props

  const modifiedClasses = {
    dashboardPage: classnames(
      classes.dashboardPage,
      isBannerPresent ? classes.dashboardPageBannerOffset : ''
    ),
    cardsGroup: classnames(
      classes.cardsGroup,
      isBannerPresent ? classes.cardsGroupBannerOffset : ''
    ),
  }

  useEffect(() => {
    document.title = DASHBOARD_PAGE_TITLE

    getLocationModificationId()

    if (!isUndefined(devicesTypesFilter)) {
      loadDevices()
    }

    return () => {
      stopPollingModification()
    }
  }, [])

  const handlePageChange = (page) => {
    goToNextPage(page)

    document.getElementById('container').scrollTop = 0
  }

  const dashboardContentToDisplay = (
    <>
      <Typography className={classes.activityTitle} variant="subtitle2">
        {DASHBOARD_TITLE}
      </Typography>
      {isOnline ? (
        <>
          <div className={classes.filterContainer}>
            <DeviceTypeFilter />
            <LocationFilter />
            <DateFilter />
            <StatusFilter />
            <DeviceFilter />
            {!isMobile && <ResetFilter />}
          </div>
          {isMobile && <ResetFilter />}
          <div className={modifiedClasses.cardsGroup} id="container">
            {!isMobile && <DownloadCSVButton />}
            <CardsContainerComponent
              startPollingModification={startPollingModification}
              stopPollingModification={stopPollingModification}
              polling={polling}
            />
          </div>
          <DashboardPagination
            goToNextPage={handlePageChange}
            startPollingModification={startPollingModification}
            stopPollingModification={stopPollingModification}
          />
        </>
      ) : (
        <NetworkError />
      )}
    </>
  )

  return (
    <div className={modifiedClasses.dashboardPage}>
      {isMobile ? (
        <div className={classes.dashboardWrapper}>
          {dashboardContentToDisplay}
        </div>
      ) : (
        dashboardContentToDisplay
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadDevices: () => dispatch(actions.loadDevices()),
    startPollingModification: () =>
      dispatch(actions.startPollingModification()),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    goToNextPage: (page) => dispatch(actions.goToNextPage(page)),
    getLocationModificationId: () =>
      dispatch(checkConnection(rootActions.getLocationModificationId)),
  }
}

const mapStateToProps = (state) => {
  return {
    isOnline: state.dashboardReducer.isOnline,
    polling: state.dashboardReducer.polling,
    devicesTypesFilter: state.dashboardReducer.devicesTypesFilter,
    isBannerPresent: state.rootReducer.isBannerPresent,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(DashboardContainerComponent)))
