import React from 'react'
import { connect } from 'react-redux'
import { map, uniqueId } from 'lodash'

import { withStyles } from '@material-ui/styles'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableSortLabel,
} from '@material-ui/core'

import {
  DRILL_DOWN_TABLE_LABELS,
  SORTING_BY,
  SORTING_ORDER,
} from '../../services/summary-constants'
import {
  getDrillDownTableHeaderConfig,
  getDataToDisplay,
  getCellColor,
} from '../../services/summary-service'
import styles from './style'

const DrillDownTable = (props) => {
  const {
    tableType,
    data,
    rangeType,
    period,
    dateFilter,
    timezone,
    order,
    orderBy,
    onRequestSort,
    classes,
  } = props

  const header = getDrillDownTableHeaderConfig(
    dateFilter,
    period,
    timezone,
    rangeType
  )

  const generateTableHead = () =>
    map(header, ({ number, label }) => (
      <TableCell className={classes.headerCell} key={label}>
        <Typography variant="body1" color="textSecondary">
          {label}
        </Typography>
      </TableCell>
    ))

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const dataToDisplay = getDataToDisplay(
    data,
    header,
    tableType,
    orderBy,
    order
  )

  const isOrderByName = orderBy === SORTING_BY.name
  const isOrderByAverage = orderBy === SORTING_BY.average

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              className={classes.firstCell}
              sortDirection={isOrderByName ? order : false}
            >
              <TableSortLabel
                color="textSecondary"
                active={isOrderByName}
                direction={isOrderByName ? order : SORTING_ORDER.asc}
                onClick={createSortHandler(SORTING_BY.name)}
              >
                {DRILL_DOWN_TABLE_LABELS[tableType]}
              </TableSortLabel>
            </TableCell>
            {generateTableHead()}
            <TableCell
              className={classes.lastCell}
              sortDirection={isOrderByAverage ? order : false}
            >
              <TableSortLabel
                color="textSecondary"
                active={isOrderByAverage}
                direction={isOrderByAverage ? order : SORTING_ORDER.asc}
                onClick={createSortHandler(SORTING_BY.average)}
              >
                {DRILL_DOWN_TABLE_LABELS.average}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(dataToDisplay, (row) => (
            <TableRow key={row.name}>
              <TableCell
                component="th"
                scope="row"
                className={classes.firstCell}
              >
                <Typography variant="h6" className={classes.rowName}>
                  {row.name}
                </Typography>
              </TableCell>
              {map(row.cycles, (cellValue) => (
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.cycleCell}
                  key={uniqueId()}
                >
                  <Typography
                    className={classes.cycleCellValue}
                    style={{
                      color: getCellColor(cellValue, row.average),
                    }}
                  >
                    {cellValue}
                  </Typography>
                </TableCell>
              ))}
              <TableCell
                component="th"
                scope="row"
                className={classes.lastCell}
              >
                <Typography variant="h6" className={classes.rowName}>
                  {row.average}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state) => ({
  data: state.summaryReducer.sharedSummaryReducer.data,
  dateFilter: state.summaryReducer.sharedSummaryReducer.dateFilter,
  period: state.summaryReducer.sharedSummaryReducer.period,
  timezone: state.rootReducer.timezone,
  rangeType: state.summaryReducer.sharedSummaryReducer.rangeType,
})

export default connect(mapStateToProps)(withStyles(styles)(DrillDownTable))
