import { call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { HttpClient } from '../../../../../services/HttpClient'
import extDeviceDetailsActions from './extDeviceDetails-actions'
import { cloneDeep, isEmpty } from 'lodash'
import { prepareLocationFiltersForDevice } from '../../../_shared/services/devices-service'
import customTreeActions from '../../../../../components/_shared/customTree/services/customTree-actions'
import { filterLocationsWithAllowedProps } from '../../../../../services/root/root-service'
import { getPropertyIdWithSelectedRoom } from '../../UVGI/services/uvgiDeviceDetails-service'

const getNodes = (state) => state.devicesReducer.extDeviceDetailsReducer.nodes
const getAllowedLocationIds = (state) =>
  state.devicesReducer.extDeviceDetailsReducer.allowedLocationIds
const getCurrentUserId = (state) => state.rootReducer.userId
const getLocationTree = (state) => state.customTreeReducer.nodes
const getRoomsFilter = (state) =>
  state.devicesReducer.extDeviceDetailsReducer.roomsFilter
const getInitialNodesTree = (state) => {
  const nodes = state.customTreeReducer.initialNodes

  return cloneDeep(nodes)
}

function* loadDeviceDetails(action) {
  try {
    const { deviceId } = action.payload
    const nodes = yield select(getNodes)

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/ext-device/${deviceId}`)
    })

    if (data.message) {
      yield put(extDeviceDetailsActions.loadDeviceDetailsFailed(data.message))
    } else {
      yield put(extDeviceDetailsActions.putDeviceDetails(data))

      if (isEmpty(nodes)) {
        yield put(extDeviceDetailsActions.loadLocationsForDevice())
      }
    }
  } catch (e) {
    yield put(extDeviceDetailsActions.loadDeviceDetailsFailed(e.message))
  }
}

function* loadLocationsForDevice(action) {
  try {
    const { onSuccess } = action.payload
    const userId = yield select(getCurrentUserId)
    const allowedLocationIds = yield select(getAllowedLocationIds)
    const tree = yield select(getInitialNodesTree)

    yield put(
      extDeviceDetailsActions.setupRoomsFilter(
        prepareLocationFiltersForDevice(tree.children, allowedLocationIds),
        tree,
        userId
      )
    )

    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield put(extDeviceDetailsActions.loadLocationsForDeviceFailed(e.message))
  }
}

function* setupLoadedLocations(action) {
  try {
    const { nodes, tree, userId } = action.payload

    yield put(
      customTreeActions.loadNodesSucceeded(
        userId,
        filterLocationsWithAllowedProps(tree, nodes)
      )
    )

    const locationTree = yield select(getLocationTree)
    const propertyId = getPropertyIdWithSelectedRoom(locationTree)

    yield put(customTreeActions.setPropertyExpandNode(propertyId))
  } catch (e) {
    yield put(extDeviceDetailsActions.setupRoomsFilterFailed(e.message))
  }
}

function* updateDevice(action) {
  try {
    const { serialNumber, body, onSuccess } = action.payload
    const bodyCopy = { ...body }

    const roomFilter = yield select(getRoomsFilter)

    if (roomFilter.selectedBuildings && roomFilter.selectedBuildings.length) {
      bodyCopy.locationIds = roomFilter.selectedBuildings
    } else {
      bodyCopy.locationIds = []
    }

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/devices/ext-device/${serialNumber}`, {
        body: bodyCopy,
      })
    })

    if (data.message) {
      yield put(extDeviceDetailsActions.updateDeviceFailed(data.message))
    } else {
      onSuccess()

      yield put(
        extDeviceDetailsActions.updateDeviceSuccess(
          bodyCopy.locationIds,
          bodyCopy.nickname
        )
      )
    }
  } catch (e) {
    yield put(extDeviceDetailsActions.updateDeviceFailed(e.message))
  }
}

function* deleteDevice(action) {
  try {
    const { serialNumber, onClose } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(
        `/api/secured/devices/ext-device/${serialNumber}/deregister`
      )
    })

    if (data.message) {
      yield put(extDeviceDetailsActions.deleteDeviceFailed(data.message))
    } else {
      yield put(extDeviceDetailsActions.deleteDeviceSuccess(data))

      onClose()
    }
  } catch (e) {
    yield put(extDeviceDetailsActions.deleteDeviceFailed(e.message))
  }
}

function* loadDeviceDetailsSaga() {
  yield takeEvery(
    extDeviceDetailsActions.actionTypes.LOAD_DEVICE_DETAILS,
    loadDeviceDetails
  )
}

function* loadLocationsForListSaga() {
  yield takeEvery(
    extDeviceDetailsActions.actionTypes.LOAD_LOCATIONS_FOR_DEVICE,
    loadLocationsForDevice
  )
}

function* locationFilterIsReadySaga() {
  yield takeEvery(
    extDeviceDetailsActions.actionTypes.SETUP_ROOMS_FILTER,
    setupLoadedLocations
  )
}

function* updateDeviceSaga() {
  yield takeEvery(
    extDeviceDetailsActions.actionTypes.UPDATE_DEVICE,
    updateDevice
  )
}

function* deleteDeviceSaga() {
  yield takeEvery(
    extDeviceDetailsActions.actionTypes.DELETE_DEVICE,
    deleteDevice
  )
}

export default function* extDeviceDetailsSaga() {
  yield fork(loadDeviceDetailsSaga)
  yield fork(loadLocationsForListSaga)
  yield fork(locationFilterIsReadySaga)
  yield fork(updateDeviceSaga)
  yield fork(deleteDeviceSaga)
}
