import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  spinner: {
    // display: 'none'
  },
  wrapper: {
    overflow: 'auto',
    height: 'calc(100% - 100px)',
    padding: '0 7%',
  },
  wrapperBannerOffset: {
    height: 'calc(100% - 190px)',
  },
  contentWrapper: {
    marginTop: '10px',
  },
  shortInput: {
    width: '45%',
  },
  createAccountButton: {
    display: 'flex',
    margin: 'auto',
    width: '190px',
    marginTop: '30px',
    marginBottom: '30px',
  },
  changeOwnerButton: {
    display: 'flex',
    margin: 'auto',
    width: 'fit-content',
    marginTop: '10px',
    marginBottom: '40px',
  },
  welcome: {
    marginBottom: '15px',
  },
  grayCard: {
    backgroundColor: COLORS.LIGHT_GRAY_SECONDARY,
    padding: '14px',
    width: 'calc(100%-28px)',
    paddingBottom: '30px',
    boxShadow: 'none',
  },
  apiKey: {
    backgroundColor: COLORS.LIGHT_GRAY_SECONDARY,
    padding: '23px',
    width: 'calc(100%-28px)',
    boxShadow: 'none',
  },
  cardContent: {
    '& > *:not(:nth-last-child(-n+2))': {
      color: COLORS.GRAY,
    },
  },
  apiKeyContent: {
    marginTop: 0,
    padding: '0 0 0',
    '& > *:not(:nth-last-child(-n+2))': {
      color: COLORS.GRAY,
    },
  },
  rowIcon: {
    width: '14px',
    height: '17px',
    marginLeft: '10px',
  },
  emailName: {
    marginTop: '10px',
    fontSize: '14px',
  },
  logoImage: {
    height: '55px',
  },
  changePasswordWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '7%',
    marginRight: '7%',
    color: COLORS.GRAY,
  },
  confirmPasswordButton: {
    width: '190px',
    marginTop: '60px',
  },
  confirmTitle: {
    margin: '30px 0',
  },
  label: {
    marginLeft: '13px',
  },
  labelFocused: {
    marginLeft: 0,
  },
  fromChangeTemporaryPassword: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: COLORS.GRAY,
  },
  buttonLegend: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '11px',
    lineHeight: '13px',
    color: COLORS.LIGHT_SILVER,
    paddingLeft: '9px',
    paddingTop: '10px',
  },
  operatorTitle: {
    marginBottom: '10px',
  },
  emailPref: {
    marginBottom: '30px',
    marginTop: '10px',
  },
  description: {
    marginBottom: '30px',
  },
  outAllDescription: {
    fontSize: '10px',
    lineHeight: '12px',
    marginLeft: '30px',
  },
  link: {
    cursor: 'pointer',
  },
  checkboxLabel: {
    fontSize: '16px',
    fontFamily: 'Cerebri Sans Pro Regular',
    fontWeight: 400,
    lineHeight: '19px',
    color: COLORS.GRAY,
  },
  '@media (min-width: 1224px)': {
    container: {
      width: '70%',
      margin: 'auto',
      marginTop: '30px',
    },
    titles: {
      minHeight: '150px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-380px',
    },
    firstTriangle: {
      position: 'relative',
      bottom: '10px',
      left: '75px',
      height: '0',
      width: '0',
      opacity: '0.08',
      transform: 'scaleX(-1)',
      borderLeft: `140px solid ${COLORS.BLUE}`,
      borderTop: '140px solid transparent',
    },
    secondTriangle: {
      position: 'relative',
      left: '235px',
      bottom: '15px',
      height: '0',
      width: '0',
      opacity: '0.08',
      transform: 'scaleX(-1)',
      borderRight: `63px solid ${COLORS.BLUE}`,
      borderTop: '63px solid transparent',
    },
    thirdTriangle: {
      position: 'relative',
      left: '65%',
      bottom: '210px',
      height: '0',
      width: '0',
      opacity: '0.08',
      transform: 'scaleX(-1)',
      borderLeft: `197px solid ${COLORS.BLUE}`,
      borderBottom: '197px solid transparent',
    },
    title: {
      textAlign: 'center',
      '&:nth-child(2)': {
        color: COLORS.GRAY,
        marginTop: '20px',
      },
    },
    welcome: {
      margin: '20px 20px 0',
    },
    contentWrapper: {
      width: '45%',
    },
    operatorTitle: {
      textAlign: 'center',
      fontFamily: 'Cerebri Sans Pro Regular',
      fontSize: '18px',
    },
    jobTitle: {
      textAlign: 'center',
      marginTop: '20px',
    },
  },
  '@media (min-width: 2000px)': {
    thirdTriangle: {
      left: '80%',
    },
  },
  '@media (min-width: 959px)': {
    apiKeyWideScreen: {
      display: 'block',
    },
    apiKeyNarrowScreen: {
      display: 'none',
    },
  },
  '@media (max-width: 959px)': {
    apiKeyWideScreen: {
      display: 'none',
    },
    apiKeyNarrowScreen: {
      display: 'block',
    },
  },
})

export default styles
