import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { includes, isEmpty } from 'lodash'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowBack from '@material-ui/icons/ArrowBack'

import DrillDownTable from './DrillDownTable'
import RangeTypeFilter from './filters/RangeTypeFilter'
import TableDateFilter from './filters/TableDateFilter'
import LocationFilter from '../locationFilter/LocationFilter'
import Loader from '../../../../../components/_shared/loader/Loader'

import { DEVICE_TYPE } from '../../../../../services/constants'
import {
  TABLE_TYPE,
  DRILL_DOWN_TABLE_LABELS,
  getLinkToReportLabel,
  SORTING_BY,
  SORTING_ORDER,
} from '../../services/summary-constants'
import { checkConnection } from '../../../../../services/root/root-service'
import { getTableLabel } from '../../services/summary-service'
import actions from '../../services/summary-actions'
import style from './style'
import classnames from 'classnames'

const DevicesTableContainer = (props) => {
  const {
    isLoading,
    getCyclesForTable,
    period,
    locationFilter,
    applyLocationFilter,
    rangeType,
    loadLocations,
    changeDateFilter,
    dateFilter,
    ids,
    dateFilterLabel,
    putLocationFilter,
    history,
    isBannerPresent,
    classes,
  } = props

  const { deviceType, tableType } = props.match.params

  const [order, setOrder] = React.useState(SORTING_ORDER.desc)
  const [orderBy, setOrderBy] = React.useState(SORTING_BY.average)

  const pageWrapper = classnames(
    classes.pageWrapper,
    isBannerPresent ? classes.pageWrapperBannerOffset : ''
  )

  // checking url
  useEffect(() => {
    if (!TABLE_TYPE[tableType] || !includes(DEVICE_TYPE, deviceType)) {
      window.location.href = '/#/'
    }
  }, [deviceType, tableType])

  // setting up filters
  useEffect(() => {
    // to apply filters from Summary Page
    if (!isEmpty(props.location?.state?.date)) {
      const { date, dateFilterLabel, location } = props.location.state

      if (location) {
        putLocationFilter(location)
      }

      changeDateFilter(date, dateFilterLabel)
    } else {
      // to load locations after refresh or when entering page not from Summary
      loadLocations()
    }
  }, [])

  // getting cycles
  useEffect(() => {
    getCyclesForTable(tableType, deviceType)
  }, [
    getCyclesForTable,
    period,
    locationFilter,
    rangeType,
    deviceType,
    tableType,
  ])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === SORTING_ORDER.asc
    setOrder(isAsc ? SORTING_ORDER.desc : SORTING_ORDER.asc)
    setOrderBy(property)
  }

  const goBackToSummary = () => {
    history.push({
      pathname: '/summary',
      state: {
        date: {
          dateFilter,
          period,
        },
        dateFilterLabel,
        location: {
          locationFilter,
          ids,
        },
        deviceType,
      },
    })
  }

  return (
    <div className={pageWrapper}>
      <div className={classes.titlesWrapper}>
        <IconButton onClick={goBackToSummary} className={classes.backButton}>
          <ArrowBack className={classes.backIcon} color="primary" />
          <Typography variant="h5" className={classes.backButtonLabel}>
            {getLinkToReportLabel(DRILL_DOWN_TABLE_LABELS[tableType])}
          </Typography>
        </IconButton>
      </div>

      <div className={classes.tableContainer}>
        <Accordion defaultExpanded className={classes.container}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            classes={{ content: classes.accordionSummary }}
          >
            <Typography variant="subtitle1">
              {getTableLabel(tableType)}
            </Typography>
          </AccordionSummary>
          <div className={classes.filtersContainer}>
            <RangeTypeFilter rangeType={rangeType} />
            <TableDateFilter />
            <div className={classes.locationFilterWrapper}>
              <LocationFilter
                applyLocationFilter={applyLocationFilter}
                locationFilter={locationFilter}
              />
            </div>
          </div>
          <AccordionDetails className={classes.details}>
            <Loader isLoading={isLoading}>
              <DrillDownTable
                tableType={tableType}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
            </Loader>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.summaryReducer.sharedSummaryReducer.isLoading,
  period: state.summaryReducer.sharedSummaryReducer.period,
  dateFilter: state.summaryReducer.sharedSummaryReducer.dateFilter,
  locationFilter: state.summaryReducer.sharedSummaryReducer.locationFilter,
  ids: state.summaryReducer.sharedSummaryReducer.ids,
  dateFilterLabel: state.summaryReducer.sharedSummaryReducer.dateFilterLabel,
  rangeType: state.summaryReducer.sharedSummaryReducer.rangeType,
  isBannerPresent: state.rootReducer.isBannerPresent,
})

const mapDispatchToProps = (dispatch) => ({
  getCyclesForTable: (tableType, deviceType) =>
    dispatch(
      checkConnection(() => actions.getCyclesForTable(tableType, deviceType))
    ),
  applyLocationFilter: (nodes) =>
    dispatch(checkConnection(() => actions.applyLocationFilter(nodes))),
  changeDateFilter: (dateFilter, label) =>
    dispatch(
      checkConnection(() => actions.changeDateFilter(dateFilter, label))
    ),
  putLocationFilter: (filter) =>
    dispatch(checkConnection(() => actions.putLocationFilter(filter))),
  loadLocations: () => dispatch(checkConnection(() => actions.loadLocations())),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(DevicesTableContainer))
