import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import LocationsChartContainer from '../../_shared/components/charts/LocationsChartContainer'
import LocationsTableContainer from '../../_shared/components/tables/LocationsTableContainer'
import {
  ACTIVE_ROOMS,
  LOCATION_LABEL_TITLE,
  WEEK_LABELS,
} from '../services/uvgiSummary-constants'
import style from './../style'
import { withMediaQuery } from '../../../../services/root/root-service'
import LinkToReport from '../../_shared/components/LinkToReport'

const UVGILocationsSummaryInfo = (props) => {
  const {
    classes,
    locations,
    dateFilter,
    defaultPeriod,
    timezone,
    disinfectedLocationsCount,
    totalLocationsCount,
    createSubtitle,
    title,
    isToday,
    deviceType,
    locationFilter,
    ids,
    dateFilterLabel,
  } = props

  return (
    <div className={classes.locationsInfo}>
      <LocationsChartContainer
        secondaryLocationsCount={disinfectedLocationsCount}
        title={title}
        blueTitle={ACTIVE_ROOMS.blueTitle}
        grayTitle={ACTIVE_ROOMS.grayTitle}
        createSubtitle={createSubtitle}
        totalLocationsCount={totalLocationsCount}
      />
      <LinkToReport
        pathname={`/summary/${deviceType}/locations`}
        state={{
          date: {
            dateFilter,
            period: defaultPeriod,
          },
          dateFilterLabel,
          location: {
            locationFilter,
            ids,
          },
        }}
        label={WEEK_LABELS.rooms}
      />
      <LocationsTableContainer
        locations={locations}
        dateFilter={dateFilter}
        defaultPeriod={defaultPeriod}
        title={LOCATION_LABEL_TITLE}
        isToday={isToday}
        timezone={timezone}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { UVGISummaryReducer, sharedSummaryReducer },
    rootReducer,
  } = state

  return {
    timezone: rootReducer.timezone,
    locations: UVGISummaryReducer.locations,
    dateFilter: UVGISummaryReducer.dateFilter,
    defaultPeriod: UVGISummaryReducer.period,
    disinfectedLocationsCount: UVGISummaryReducer.disinfectedLocationsCount,
    totalLocationsCount: UVGISummaryReducer.totalLocationsCount,
    deviceType: sharedSummaryReducer.activeTab,
    dateFilterLabel: UVGISummaryReducer.dateFilterLabel,
    locationFilter: UVGISummaryReducer.locationFilter,
    ids: UVGISummaryReducer.ids,
  }
}

export default connect(mapStateToProps)(
  withMediaQuery(withStyles(style)(UVGILocationsSummaryInfo))
)
