import React from 'react'
import {
  map,
  concat,
  isNull,
  has,
  findIndex,
  filter,
  trim,
  indexOf,
  isArray,
  take,
  find,
} from 'lodash'
import useMediaQuery from '@material-ui/core/useMediaQuery/index'
import mobileTheme from '../../mobileTheme'
import {
  ARC_TYPES,
  devicesConfig,
  LOCATION_LABELS,
  PREF,
  TREE_LEVELS,
  DEVICE_TYPES,
  PAGES,
  HOST,
} from '../constants'
import filterQuery from '../filterQuery-service'
import actions from './root-actions'
import moment from 'moment'

const logOut = () => {
  window.location.href = '/api/secured/user-profiles/logout'
}

const isEmailValid = (email) => {
  return /^[a-z0-9]{1}[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}$/g.test(email)
}

// allowedProperties - selected(allowedProperties)
const filterLocationsWithAllowedProps = (locations, userPref) => {
  let filteredLocations = { ...locations }

  if (userPref || isNull(userPref)) {
    if (isArray(userPref) || isNull(userPref)) {
      filteredLocations[PREF.SERVER_PREF] = { selectedBuildings: userPref }
    } else {
      filteredLocations[PREF.SERVER_PREF] = userPref
    }

    filteredLocations.isServerPref = true
  }

  return filteredLocations
}

const prepareLocationFiltersParams = (nodes, userPref) => {
  const nodeList = concat([], nodes)
  const params = {
    selectedName: '',
    selectedBuildings: [],
    indeterminateProperties: [],
  }
  let isParentChecked = false

  var traverse = function (current) {
    map(current, (item) => {
      let hasChecked = has(item, 'checked')
      let inUserPref = indexOf(userPref, item.id) !== -1
      let isChecked = hasChecked ? item.checked : inUserPref

      if (item.children && item.children.length) {
        if (isChecked) {
          if (!isParentChecked || item.locationType === TREE_LEVELS.PROPERTY) {
            params.selectedBuildings.push(item.id)
            params.selectedName = item.name
          }
        } else {
          isParentChecked = false

          traverse(item.children)
        }
      } else {
        if (isChecked) {
          if (!isParentChecked || item.locationType === TREE_LEVELS.PROPERTY) {
            params.selectedBuildings.push(item.id)
            params.selectedName = item.name
          }
        } else {
          isParentChecked = false
        }
      }
    })
  }

  traverse(nodeList)

  return params
}

const createLocationLabel = (locationFilter, isAllSelected) => {
  const { selectedBuildings, selectedName } = locationFilter

  if (!isAllSelected) {
    if (selectedBuildings && selectedBuildings.length > 1) {
      return LOCATION_LABELS.multiple
    }

    if (selectedBuildings && selectedBuildings.length === 1) {
      return selectedName
    }

    if (selectedBuildings && selectedBuildings.length === 0) {
      return LOCATION_LABELS.none
    }
  } else {
    return LOCATION_LABELS.all
  }
}

const withMediaQuery = (Component) => (props) => {
  const mediaQuery = useMediaQuery(mobileTheme.breakpoints.down('md'))

  return <Component isMobile={mediaQuery} {...props} />
}

const checkConnection = (action) => {
  const isOnline = window.navigator.onLine

  return isOnline ? action() : actions.setNetworkError(isOnline)
}

const initialState = {
  authorities: [],
  orgDevices: [],
  role: '',
  organization: '',
  isLoading: false,
  isOperatorView: false,
  isAccountOwner: false,
  allowedProperties: [],
  userId: '',
  timezone: null,
  loginError: '',
  networkError: !window.navigator.onLine,
  defaultUvgiMode: '',
  firstLoadingFinished: false,
}

const getOrgDevices = (features) =>
  filter(devicesConfig, (item) => findIndex(features, ['id', item.id]) !== -1)

const prepareBuildVersionString = (data) => {
  const { branch, buildTime, buildVersion, hashCommitAbbrev } = data

  return `branch: ${branch} / buildTime: ${buildTime}/ buildVersion: ${buildVersion}/ hashCommitAbbrev: ${hashCommitAbbrev}`
}

const prepareDeviceSubTypeQuery = (type, sybType) => {
  const deviceTypeQuery = filterQuery.getQueryString(
    type,
    filterQuery.templates.default,
    'deviceType',
    'eq'
  )

  const sybTypeQuery = filterQuery.getQueryString(
    sybType,
    filterQuery.templates.default,
    'deviceSubType',
    'eq'
  )

  return filterQuery.createQueryStringFromArray(
    [deviceTypeQuery, sybTypeQuery],
    'and'
  )
}

const isArc = (deviceType) => indexOf(ARC_TYPES, deviceType) !== -1

const getDeviceSubTypeQuery = (subType, queryInBody) => {
  const deviceSubTypes = isArc(subType) ? ARC_TYPES : [subType]

  const sybTypeQuery = filterQuery.getArrayStringInQuotes(
    deviceSubTypes,
    filterQuery.templates.array,
    'deviceSubType',
    'in',
    queryInBody
  )

  return sybTypeQuery
}

const processWithObue = (
  url,
  token,
  tokenIssuer,
  method,
  onSuccess,
  onError
) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const oboe = require('oboe')

  oboe({
    url: HOST + url,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
      tokenIssuer: tokenIssuer || 0,
    },

    method: method,
  })
    .on('done', (things) => {
      onSuccess(things)
    })
    .on('fail', (error) => {
      onError && onError(error)
    })
}

const getLabelWithCount = (count, label, isCount = true) =>
  trim(`${isCount ? count : ''} ${count === 1 ? label : label + 's'}`)

const getLocationPath = (path, extended = false, separator = ' > ') => {
  if (extended) {
    return [path[path.length - 1], take(path, path.length - 1).join(separator)]
  }

  return path.join(separator)
}

const checkDisconnectedStatus = (timeout, modified) => {
  if (!timeout) {
    return false
  }

  const nowDate = moment().utc().format('yyyy-MM-DDTHH:mm:ss')

  const currentPeriodBetweenEvents = moment(nowDate).diff(modified)
  const maxPeriodBetweenEvents = moment(timeout).diff(moment(modified))

  return currentPeriodBetweenEvents > maxPeriodBetweenEvents
}

const getDevicePath = (type) => {
  return isArc(type)
    ? DEVICE_TYPES.arc.path
    : find(DEVICE_TYPES, (el) => el.deviceSubType === type)?.path
}

const goToSummary = () => {
  window.location.href = '/#/summary'
}

const pushLoginEventToDataLayer = (
  userId,
  organizationName,
  organizationId
) => {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'login',
    user_id: userId,
    organization: organizationName,
    organization_id: organizationId,
  })
}

const getPageAndTitle = () => {
  const url = window.location.href
  const hashIndex = indexOf(url, '#')
  const path = url.substr(hashIndex + 1)

  const title = find(PAGES, ({ pattern }) => pattern.test(path))?.title || ''

  return [path, title]
}

export {
  logOut,
  isEmailValid,
  filterLocationsWithAllowedProps,
  prepareLocationFiltersParams,
  createLocationLabel,
  withMediaQuery,
  checkConnection,
  initialState,
  getOrgDevices,
  prepareBuildVersionString,
  prepareDeviceSubTypeQuery,
  processWithObue,
  getLabelWithCount,
  getLocationPath,
  checkDisconnectedStatus,
  isArc,
  getDeviceSubTypeQuery,
  getDevicePath,
  goToSummary,
  pushLoginEventToDataLayer,
  getPageAndTitle,
}
