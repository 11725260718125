import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  pageContainer: {
    display: 'flex',
    padding: '0 7%',
  },
  simplePageContainer: {
    height: 'calc(100% - 140px)',
    marginBottom: '100px',
  },
  pageContainerWithTabs: {
    height: '100%',
  },
  tabContentFixedHeader: {
    height: 'calc(100% - 60px)',
    width: '100%',
  },
  tabContent: {
    height: 'fit-content',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tabsContainerBannerOffset: {
    height: 'calc(100% - 90px)',
  },
  title: {
    padding: '0 26px 20px',
  },
  overflowAuto: {
    overflow: 'auto',
  },
  '@media (min-width: 1224px)': {
    simplePageContainer: {
      height: 'calc(100% - 100px)',
    },
    title: {
      textAlign: 'center',
      padding: '44px 26px 30px',
    },
    tabContentFixedHeader: {
      height: 'calc(100% - 180px)',
    },
    pageContainerWithTabs: {
      height: 'calc(100% - 100px)',
      marginBottom: '80px',
    },
    pageContainerWithoutPadding: {
      display: 'flex',
      height: 'fit-content',
    },
    emptyScreenContainer: {
      backgroundColor: COLORS.WHITE,
      height: '77%',
      width: '68%',
      margin: '20px auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
})

export default styles
