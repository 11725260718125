import React from 'react'
import { connect } from 'react-redux'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { TREE_LEVELS } from '../../../services/constants'
import styles from './style'
import TreeNode from './TreeNode'
import actions from './services/customTree-actions'
import { SELECT_ALL_LABEL } from './services/customTree-constants'
import { map } from 'lodash'

const TreeList = withStyles(styles)((props) => {
  const {
    nodes,
    setExpandNode,
    setCheckNode,
    classes,
    changeSelectAll,
    mainLevel,
    isAllSelected,
    setSelectAllState,
    setPropertyExpandNode,
    setPropertyCheckNode,
    userId,
  } = props

  const onCheck = (key, deep, value, propertyId) => {
    if (deep === TREE_LEVELS.PROPERTY) {
      setPropertyCheckNode(key, value, mainLevel)
    } else {
      setCheckNode(key, value, mainLevel, propertyId)
    }

    setSelectAllState()
  }

  const onExpand = (key, deep, propertyId) => {
    if (deep === TREE_LEVELS.PROPERTY) {
      setPropertyExpandNode(key, userId)
    } else {
      setExpandNode(key, propertyId)
    }
  }

  const onSelectAll = () => {
    changeSelectAll(!isAllSelected)
  }

  const renderTreeNodes = (nodes, parent = {}, deep) => {
    const renderNodes = (nodes, deep) => {
      return map(nodes, (node) => {
        const parentExpanded = parent.key ? parent.expanded : true

        if (!parentExpanded) {
          return null
        }

        const margin = deep * 15 + 'px'
        const nextDeep = deep + 1

        return (
          <div key={node.id}>
            <TreeNode
              margin={margin}
              value={node.id}
              propertyId={node.propertyId}
              onExpand={onExpand}
              onCheck={onCheck}
              checked={node.checked}
              indeterminate={node.indeterminate || false}
              name={node.name}
              expanded={node.expanded}
              isParent={node.isParent}
              deep={node.locationType}
            >
              {node.isParent && renderTreeNodes(node.children, node, nextDeep)}
            </TreeNode>
          </div>
        )
      })
    }

    return renderNodes(nodes, deep)
  }

  const renderSelectAll = () => {
    return (
      <div className={classes.labelContainer}>
        <Checkbox
          className={classes.treeCheckbox}
          checkedIcon={<CheckBoxOutlinedIcon />}
          checked={isAllSelected}
          color={isAllSelected ? 'primary' : 'default'}
          onChange={onSelectAll}
        />
        <Typography
          className={classes.selectAllLabel}
          color={isAllSelected ? 'primary' : 'inherit'}
          variant="subtitle2"
        >
          {SELECT_ALL_LABEL}
        </Typography>
      </div>
    )
  }

  return (
    <>
      {renderSelectAll()}
      <Divider className={classes.divider} />
      <div className={classes.nodesWrapper}>
        {renderTreeNodes(nodes, undefined, 0)}
      </div>
    </>
  )
})

const mapStateToProps = (state) => ({
  nodes: state.customTreeReducer.nodes,
  isAllSelected: state.customTreeReducer.isAllSelected,
  userId: state.rootReducer.userId,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setExpandNode: (key, propertyId) =>
      dispatch(actions.setExpandNode(key, propertyId)),
    setPropertyExpandNode: (key, userId) =>
      dispatch(actions.setPropertyExpandNode(key, userId)),
    setPropertyCheckNode: (key, bool, mainLevel) =>
      dispatch(actions.setPropertyCheckNode(key, bool, mainLevel)),
    setCheckNode: (key, bool, mainLevel, propertyId) =>
      dispatch(actions.setCheckNode(key, bool, mainLevel, propertyId)),
    changeSelectAll: (isSelectAll) => dispatch(actions.selectAll(isSelectAll)),
    setSelectAllState: () => dispatch(actions.setSelectAllState()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeList)
