import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'
import treeActions from '../../../../../../components/_shared/customTree/services/customTree-actions'
import styles from './style'
import actions from '../../services/uvgiDeviceList-actions'
import {
  checkConnection,
  createLocationLabel,
} from '../../../../../../services/root/root-service'
import {
  DEVICE_TYPE_MAPPER,
  LOCATION_FILTER_LABELS,
  LOCATION_LABELS,
  TREE_LEVELS,
} from '../../../../../../services/constants'
import LocationCheckboxTree from '../../../../../../components/_shared/LocationCheckboxTree/LocationCheckboxTree'
import { filterLocationsForDevice } from '../../services/uvgiDeviceList-service'
import { gaEventAction } from '../../../../../../services/googleAnalytics/ga-actions'

const LocationFilter = (props) => {
  const {
    applyLocationFilter,
    nodes,
    isAllSelected,
    loadNodesSucceeded,
    userId,
    treeCopy,
    setLocationFilterLabel,
    startPollingModification,
    stopPollingModification,
    activeTab,
    locationFilter,
    classes,
    gaEventSend,
  } = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const locationLabel = createLocationLabel(
      locationFilter[activeTab].locations,
      isAllSelected
    )

    setLocationFilterLabel(locationLabel, activeTab)
  }, [locationFilter[activeTab].locations])

  const resetLocationsFilter = () => {
    loadNodesSucceeded(
      userId,
      filterLocationsForDevice(treeCopy, locationFilter[activeTab].locations),
      TREE_LEVELS.ROOM
    )
  }

  const resetChanges = () => {
    resetLocationsFilter()
    handleClose()
  }

  const handleClickOpen = () => {
    setOpen(true)

    stopPollingModification()
    resetLocationsFilter()
  }

  const handleClose = () => {
    setOpen(false)

    startPollingModification()
  }

  const onApply = () => {
    applyLocationFilter(nodes, activeTab)
    gaEventSend('location_filter', {
      event_label: nodes.filter((node) => node.checked).length,
      device_tab: DEVICE_TYPE_MAPPER[activeTab],
    })
    handleClose()
  }

  return (
    <>
      <div className={classes.chipWrapper}>
        <Chip
          onClick={handleClickOpen}
          className={classes.chip}
          label={`${LOCATION_LABELS.location}: ${locationFilter[activeTab].label}`}
          variant="outlined"
        />
      </div>
      <LocationCheckboxTree
        onApply={onApply}
        actionLabel={LOCATION_FILTER_LABELS.actionLabel}
        modalTitle={LOCATION_FILTER_LABELS.modalTitle}
        onClose={resetChanges}
        isOpen={open}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { UVGIDeviceListReducer, sharedDevicesReducer },
    customTreeReducer,
  } = state

  return {
    nodes: customTreeReducer.nodes,
    isAllSelected: customTreeReducer.isAllSelected,
    locationFilter: UVGIDeviceListReducer.locationFilter,
    treeCopy: customTreeReducer.treeCopy,
    activeTab: sharedDevicesReducer.tab,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyLocationFilter: (nodes, tab) =>
      dispatch(checkConnection(() => actions.applyLocationFilter(nodes, tab))),
    loadNodesSucceeded: (id, nodes) =>
      dispatch(treeActions.loadNodesSucceeded(id, nodes)),
    setLocationFilterLabel: (label, tab) =>
      dispatch(actions.setLocationFilterLabel(label, tab)),
    startPollingModification: () =>
      dispatch(checkConnection(() => actions.startPollingModification())),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    gaEventSend: gaEventAction(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LocationFilter))
