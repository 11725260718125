import locationActions from './location-actions'
import { concat } from 'lodash'
import {
  getCurrentLocation,
  getCurrentNode,
  getCurrentLocPathForBulk,
  prepareLocationsTree,
  updateTree,
  getNodeTypeById,
} from './location-service'

export const locationReducer = (
  state = {
    tree: null,
    currentNode: null,
    currentRouteParams: [
      {
        pathname: '/locations',
        name: 'Root',
        id: null,
      },
    ],
    parentId: null,
    pathname: '/locations/building',
    currentLoc: '',
    error: '',
    isLoading: false,
    isLoadingSubLocations: false,
    isLoadingAssignedDevices: false,
    isLoadingAssignedUsers: false,
    subLocationsCount: null,
    assignedDevices: null,
    assignedUsers: null,
    loadLocationsError: '',
    currentSchema: [],
    defaultTimeout: 8,
  },
  action
) => {
  let updatedTree
  let currentLocationObj

  switch (action.type) {
    case locationActions.actionsTypes.ADD_NEW_CHILD_LOCATION:
    case locationActions.actionsTypes.LOAD_LOCATIONS:
    case locationActions.actionsTypes.DELETE_LOCATION:
      return {
        ...state,
        isLoading: true,
        loadLocationsError: '',
      }
    case locationActions.actionsTypes.GET_SUBLOCATIONS_COUNT:
      return {
        ...state,
        isLoadingSubLocations: true,
      }
    case locationActions.actionsTypes.GET_ASSIGNED_DEVICES:
      return {
        ...state,
        isLoadingSubLocations: false,
        isLoadingAssignedUsers: false,
        isLoadingAssignedDevices: true,
      }
    case locationActions.actionsTypes.GET_ASSIGNED_USERS:
      return {
        ...state,
        isLoadingAssignedUsers: true,
      }
    case locationActions.actionsTypes.GET_ASSIGNED_USERS_FAILED: {
      return {
        ...state,
        isLoadingAssignedUsers: false,
      }
    }
    case locationActions.actionsTypes.GET_ASSIGNED_DEVICES_FAILED: {
      return {
        ...state,
        isLoadingAssignedDevices: false,
      }
    }
    case locationActions.actionsTypes.GET_SUBLOCATIONS_COUNT_FAILED: {
      return {
        ...state,
        isLoadingSubLocations: false,
      }
    }
    case locationActions.actionsTypes.SET_CURRENT_LOCATION: {
      let { pathname } = action.payload

      if (pathname.includes('edit')) {
        pathname = pathname.substring(0, pathname.length - 5)
      }

      currentLocationObj = state.tree
        ? getCurrentNode(pathname, state.tree)
        : {
            path: state.currentRouteParams,
          }

      return {
        ...state,
        currentLoc: getCurrentLocation(action.payload.pathname),
        currentNode: currentLocationObj.list,
        currentRouteParams: concat([], currentLocationObj.path),
        pathname: action.payload.pathname,
        isLoading: false,
      }
    }
    case locationActions.actionsTypes.SET_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      }
    }
    case locationActions.actionsTypes.BULK_UPLOAD_LOCATIONS_FAILED:
    case locationActions.actionsTypes.UPDATE_LOCATION_FAILED:
    case locationActions.actionsTypes.UPDATE_TIMEOUT_FOR_LOCATION_FAILED:
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    case locationActions.actionsTypes.LOAD_LOCATIONS_FAILED:
      return {
        ...state,
        loadLocationsError: action.payload.message,
        isLoading: false,
      }
    case locationActions.actionsTypes.LOAD_LOCATIONS_SUCCEEDED:
      const preparedTree = prepareLocationsTree(action.payload.locations)

      currentLocationObj = getCurrentNode(state.pathname, preparedTree)

      return {
        ...state,
        defaultTimeout: action.payload.locations.timeout,
        tree: preparedTree || {},
        currentNode: currentLocationObj.list,
        currentRouteParams: concat([], currentLocationObj.path),
        isLoading: false,
        loadLocationsError: '',
      }
    case locationActions.actionsTypes.ADD_NEW_LOCATION_SUCCEEDED:
      const { addedLocation } = action.payload

      updatedTree = updateTree(state.tree, addedLocation, 'add')

      currentLocationObj = getCurrentNode(state.pathname, updatedTree)

      return {
        ...state,
        tree: updatedTree,
        currentNode: currentLocationObj.list,
        currentRouteParams: concat([], currentLocationObj.path),
        isLoading: false,
      }
    case locationActions.actionsTypes.BULK_UPLOAD_LOCATIONS_SUCCEEDED: {
      const { locations } = action.payload
      const { parentId } = locations[0]
      const parentType = getNodeTypeById(state.tree, parentId)
      const newPath = getCurrentLocPathForBulk(
        state.pathname,
        parentId,
        parentType
      )

      updatedTree = updateTree(state.tree, locations, 'bulk')
      currentLocationObj = getCurrentNode(newPath, updatedTree)

      return {
        ...state,
        tree: updatedTree,
        currentNode: currentLocationObj.list,
        currentRouteParams: concat([], currentLocationObj.path),
        isLoading: false,
      }
    }
    case locationActions.actionsTypes.ADD_NEW_CHILD_LOCATION_SUCCEEDED:
      const { location, url } = action.payload

      updatedTree = updateTree(state.tree, location, 'add')

      currentLocationObj = getCurrentNode(url, updatedTree)

      return {
        ...state,
        tree: updatedTree,
        currentNode: currentLocationObj.list,
        currentRouteParams: concat([], currentLocationObj.path),
        isLoading: false,
      }
    case locationActions.actionsTypes.DELETE_LOCATION_SUCCEEDED:
      updatedTree = updateTree(
        state.tree,
        {
          id: action.payload.id,
          parentId: action.payload.parentId,
        },
        'delete'
      )

      currentLocationObj = getCurrentNode(state.pathname, updatedTree)

      return {
        ...state,
        tree: updatedTree,
        currentNode: currentLocationObj.list,
        currentRouteParams: concat([], currentLocationObj.path),
        isLoading: false,
      }
    case locationActions.actionsTypes
      .DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED:
      updatedTree = updateTree(
        state.tree,
        {
          id: action.payload.id,
          parentId: action.payload.parentId,
        },
        'delete'
      )

      currentLocationObj = getCurrentNode(state.pathname, updatedTree)

      return {
        ...state,
        tree: updatedTree,
        currentNode: currentLocationObj.list,
        currentRouteParams: concat([], currentLocationObj.path),
        isLoadingSubLocations: false,
        subLocationsCount: null,
      }
    case locationActions.actionsTypes
      .DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    case locationActions.actionsTypes.EDIT_LOCATION_SUCCEEDED:
    case locationActions.actionsTypes.UPDATE_TIMEOUT_FOR_LOCATION_SUCCEEDED: {
      const { updatedLoc } = action.payload

      updatedTree = updateTree(state.tree, updatedLoc, 'edit')
      currentLocationObj = getCurrentNode(state.pathname, updatedTree)

      return {
        ...state,
        tree: updatedTree,
        currentNode: currentLocationObj.list,
        currentRouteParams: concat([], currentLocationObj.path),
        error: '',
        isLoading: false,
        subLocationsCount: null,
      }
    }
    case locationActions.actionsTypes.GET_SUBLOCATIONS_COUNT_SUCCEEDED: {
      return {
        ...state,
        subLocationsCount: action.payload.subLocationsCount,
        isLoading: false,
        isLoadingSubLocations: false,
      }
    }
    case locationActions.actionsTypes.GET_ASSIGNED_DEVICES_SUCCEEDED: {
      return {
        ...state,
        assignedDevices: action.payload.devices,
        isLoading: false,
        isLoadingAssignedDevices: false,
      }
    }
    case locationActions.actionsTypes.GET_ASSIGNED_USERS_SUCCEEDED: {
      return {
        ...state,
        assignedUsers: action.payload.users,
        isLoading: false,
        isLoadingAssignedUsers: false,
      }
    }
    case locationActions.actionsTypes.RESET_SUBLOCATIONS_COUNT: {
      return {
        ...state,
        subLocationsCount: null,
      }
    }
    case locationActions.actionsTypes.RESET_ERROR: {
      return {
        ...state,
        error: '',
      }
    }
    case locationActions.actionsTypes.RESET_USERS: {
      return {
        ...state,
        assignedUsers: null,
      }
    }
    case locationActions.actionsTypes.GET_CURRENT_LOCATIONS_SCHEMA_SUCCEEDED: {
      return {
        ...state,
        currentSchema: action.payload.schema,
      }
    }
    default:
      return state
  }
}
