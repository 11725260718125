import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import UVGILocationButton from '../../../_shared/components/addDeviceFunctionality/UVGILocationButton'
import AddUVGIDeviceButton from './AddUVGIDeviceButton'
import styles from './style'
import { connect } from 'react-redux'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../../services/root/root-service'
import actions from './../services/uvgiDeviceList-actions'

const AddDevice = (props) => {
  const {
    error,
    roomsFilter,
    devices,
    failedSerialNumber,
    addNewDevice,
    resetActionError,
    startPollingModification,
    stopPollingModification,
    setupRoomsFilter,
    classes,
  } = props

  const onAddDevice = (serialNumber, onClose) => {
    addNewDevice(serialNumber, onClose)
  }

  return (
    <div className={classes.addDeviceContainer}>
      <AddUVGIDeviceButton
        onSubmit={onAddDevice}
        resetActionError={resetActionError}
        locationFilter={roomsFilter}
        error={error}
        devices={devices}
        failedSerialNumber={failedSerialNumber}
      >
        <UVGILocationButton
          startPolling={startPollingModification}
          setupRoomsFilter={setupRoomsFilter}
          roomsFilter={roomsFilter}
          stopPolling={stopPollingModification}
        />
      </AddUVGIDeviceButton>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { UVGIDeviceListReducer },
  } = state

  return {
    error: UVGIDeviceListReducer.actionError,
    roomsFilter: UVGIDeviceListReducer.roomsFilter,
    failedSerialNumber: UVGIDeviceListReducer.failedSerialNumber,
    devices: UVGIDeviceListReducer.list,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetActionError: () => dispatch(actions.resetActionError()),
  addNewDevice: (serialNumber, onClose) =>
    dispatch(
      checkConnection(() => actions.addNewDevice(serialNumber, onClose))
    ),
  startPollingModification: () => dispatch(actions.startPollingModification()),
  stopPollingModification: () => dispatch(actions.stopPollingModification()),
  setupRoomsFilter: (nodes, tree, userId) =>
    dispatch(actions.setupRoomsFilter(nodes, tree, userId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(AddDevice)))
