import { COLORS } from '../../../../../../services/constants'

const styles = () => ({
  contentWrapper: {
    '& > .spinner': {
      display: 'none',
    },
  },
  deviceDetailsPage: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 100px)',
    paddingBottom: '15px',
    paddingLeft: '7%',
    paddingRight: '7%',
  },
  deviceDetailsPageBannerOffset: {
    height: 'calc(100% - 190px)',
  },
  maintenanceReminderContainer: {
    width: '100%',
    height: '169px',
    backgroundColor: COLORS.WARM_GRAY,
    padding: '30px',
  },
  locations: {
    minWidth: '100%',
    marginTop: '25px',
  },
  smallButton: {
    width: '163px',
    height: '27px',
    fontSize: '12px',
    marginTop: '15px',
  },
  infoWrapper: {
    marginTop: '20px',
  },
  '@media (min-width: 1224px)': {
    deviceDetailsPage: {
      margin: 'auto',
      paddingLeft: '15%',
      paddingRight: '15%',
    },
    contentWrapper: {
      backgroundColor: COLORS.WHITE,
    },
    formAndRunWrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    reminderWrapper: {
      padding: '20px',
      width: '50%',
      boxSizing: 'border-box',
    },
    infoWrapper: {
      display: 'flex',
      minHeight: '335px',
      marginTop: 0,
    },
  },
})

export default styles
