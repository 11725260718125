import { BasePdfElement } from './BasePdfElement'

const BACKGROUND_COLOR = [192, 192, 192]
const SEPARATION = 6
const SQUARE_SEPARATION = 5

export class PdfFilers extends BasePdfElement {
  div: HTMLDivElement
  internalPosX = 0

  constructor(div: HTMLDivElement) {
    super()
    this.div = div
  }

  renderFilter(itemElement: Element) {
    this.doc.setFontSize(14)

    const text = this.getPureText(itemElement)

    this.doc.setFillColor(
      BACKGROUND_COLOR[0],
      BACKGROUND_COLOR[1],
      BACKGROUND_COLOR[2]
    )

    this.doc.setDrawColor(
      BACKGROUND_COLOR[0],
      BACKGROUND_COLOR[1],
      BACKGROUND_COLOR[2]
    )

    const dim = this.doc.getTextDimensions(text)
    const totalWidth = dim.w + SQUARE_SEPARATION * 2
    const padding = 7
    this.doc.roundedRect(
      this.internalPosX,
      this.position_y + padding,
      totalWidth,
      this.height - padding * 2,
      2,
      2
    )

    this.addCenteredTextV(
      text,
      this.internalPosX + SQUARE_SEPARATION,
      this.position_y,
      this.height
    )
    this.internalPosX += totalWidth + SEPARATION
  }

  async render() {
    this.internalPosX += this.position_x
    this.div.querySelectorAll('.pdf-filters > div').forEach((element) => {
      this.renderFilter(element)
    })
  }
}
