import React, { useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import CardsContainer from '../../_shared/components/CardsContainer'
import CombinationChartContainer from '../../_shared/components/charts/CombinationChartContainer'
import EmptySummaryPageComponent from '../../_shared/components/EmptySummaryPage'
import LocationFilter from '../../_shared/components/locationFilter/LocationFilter'
import actions from '../services/uvgiSummary-actions'
import {
  CYCLE_CHART,
  TODAY_DEVICE_TABLE_COLUMNS,
  TODAY_DEVICES_LABELS,
  TODAY_ROOMS_LABELS,
  WEEK_DEVICE_TABLE_COLUMNS,
  WEEK_DEVICES_LABELS,
  WEEK_ROOMS_LABELS,
} from '../services/uvgiSummary-constants'
import {
  cardsConfig,
  createCompletedCyclesChart,
} from '../services/uvgiSummary-service'
import style from './../style'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../services/root/root-service'
import UVGIDateFilter from './UVGIDateFilter'
import UVGIDevicesSummaryInfo from './UVGIDevicesSummaryInfo'
import UVGILocationsSummaryInfo from './UVGILocationsSummaryInfo'
import { DEVICE_TYPE_MAPPER } from '../../../../services/constants'
import DevicesPageWrapper from '../../_shared/components/DevicesPageWrapper/DevicesPageWrapper'
import DownloadPDF from '../../_shared/components/downloadPDF/DownloadPDF'
import { getReportFileName } from 'containers/Summary/_shared/services/summary-helpers'
import { PdfSummaryGenerator } from 'components/_shared/pdf/PdfSummaryGenerator'

const UVGISummaryPage = (props) => {
  const {
    totalCycles,
    totalCyclesTime,
    avgCyclesTime,
    avgPerDayCycles,
    summaryIsEmpty,
    isMobile,
    classes,
    applyLocationFilter,
    locationFilter,
    completedCyclesData,
    dateFilter,
    activeTab,
    organizationName,
  } = props

  const gaContext = useMemo(
    () => ({ device_tab: DEVICE_TYPE_MAPPER[activeTab] }),
    [activeTab]
  )

  const handleGeneratePdf = useCallback(() => {
    const generator = new PdfSummaryGenerator(
      getReportFileName(organizationName, activeTab),
      DEVICE_TYPE_MAPPER[activeTab]
    )
    generator.render()
  }, [organizationName])

  const getHeader = () => (
    <div className={classes.actionsWrapper}>
      <div className={classNames('pdf-filters', classes.filters)}>
        <LocationFilter
          applyLocationFilter={applyLocationFilter}
          locationFilter={locationFilter}
          isArc={false}
          gaContext={gaContext}
        />
        <UVGIDateFilter gaContext={gaContext} />
      </div>
      <DownloadPDF downloadPDF={handleGeneratePdf} />
    </div>
  )

  const getPage = () =>
    summaryIsEmpty ? (
      <EmptySummaryPageComponent />
    ) : (
      <div className={classes.summaryWrapper}>
        <CardsContainer
          totalCycles={totalCycles}
          totalCyclesTime={totalCyclesTime}
          avgCyclesTime={avgCyclesTime}
          avgPerDayCycles={avgPerDayCycles}
          cardsConfig={cardsConfig}
          isMobile={isMobile}
        />
        {dateFilter.today ? (
          <>
            <UVGILocationsSummaryInfo
              isToday
              title={TODAY_ROOMS_LABELS.title}
              createSubtitle={TODAY_ROOMS_LABELS.totalRooms}
            />
            <UVGIDevicesSummaryInfo
              isToday
              tableSubtitle={TODAY_DEVICES_LABELS.tableSubtitle}
              title={TODAY_DEVICES_LABELS.title}
              tableColumns={TODAY_DEVICE_TABLE_COLUMNS}
              subtitle={TODAY_DEVICES_LABELS.totalDevices}
            />
          </>
        ) : (
          <>
            <UVGILocationsSummaryInfo
              title={WEEK_ROOMS_LABELS.title}
              createSubtitle={WEEK_ROOMS_LABELS.totalRooms}
            />
            <UVGIDevicesSummaryInfo
              title={WEEK_DEVICES_LABELS.title}
              tableColumns={WEEK_DEVICE_TABLE_COLUMNS}
              tableSubtitle={WEEK_DEVICES_LABELS.tableSubtitle}
              subtitle={WEEK_DEVICES_LABELS.totalDevices}
            />
            <CombinationChartContainer
              title={CYCLE_CHART.title}
              createCompletedCyclesChart={createCompletedCyclesChart}
              chartData={completedCyclesData}
              subtitle={CYCLE_CHART.subtitle}
            />
          </>
        )}
      </div>
    )

  return (
    <DevicesPageWrapper
      HeaderComponent={getHeader}
      PageComponent={getPage}
      name={DEVICE_TYPE_MAPPER[activeTab]}
      isMobile={isMobile}
    />
  )
}

const mapStateToProps = (state) => {
  const {
    summaryReducer: { UVGISummaryReducer, sharedSummaryReducer },
    rootReducer,
  } = state

  return {
    totalCycles: UVGISummaryReducer.totalCycles,
    totalCyclesTime: UVGISummaryReducer.totalCyclesTime,
    avgCyclesTime: UVGISummaryReducer.avgCyclesTime,
    avgPerDayCycles: UVGISummaryReducer.avgPerDayCycles,
    summaryIsEmpty: UVGISummaryReducer.summaryIsEmpty,
    locationFilter: UVGISummaryReducer.locationFilter,
    completedCyclesData: UVGISummaryReducer.completedCyclesData,
    dateFilter: UVGISummaryReducer.dateFilter,
    activeTab: sharedSummaryReducer.activeTab,
    organizationName: rootReducer.organization.name,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyLocationFilter: (nodes) =>
      dispatch(checkConnection(() => actions.applyLocationFilter(nodes))),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(style)(UVGISummaryPage)))
