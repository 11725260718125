import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  modalTitle: {
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '14px',
    marginBottom: '6px ',
  },
  helperTextWarning: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '10px',
    lineHeight: '13px',
    color: COLORS.GRAY,
    padding: '10px 30px',
    textAlign: 'center',
  },
  helperText: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
    lineHeight: '20px',
    color: COLORS.GRAY,
    padding: '10px 30px',
    textAlign: 'center',
  },
  modalTitleContainer: {
    paddingBottom: 0,
  },
  '@media (min-width: 1224px)': {
    submitButton: {
      width: '100%',
    },
    helperText: {
      padding: '16px 80px',
      fontSize: '11px',
    },
    dialogActions: {
      padding: '16px 80px 40px',
    },
  },
})

export default styles
