import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { CUMULATIVE_USAGE_START } from 'containers/Devices/_shared/services/devices-actions'
import BackToDeviceList from '../../../../_shared/components/backToDeviceList/BackToDeviceList'
import rootActions from '../../../../_shared/services/devices-actions'
import { ERRORS } from '../../services/uvgiDeviceDetails-constants'
import MaintenanceReminder from './maintenanceReminder/MaintenanceReminder'
import DeviceRunTime from './deviceRunTime/DeviceRunTime'
import RecentUsageData from './recentUsageData/RecentUsageData'
import DeleteDeviceButton from '../../../../_shared/components/deleteDeviceButton/DeleteDeviceButton'
import styles from './style'
import {
  checkConnection,
  withMediaQuery,
} from '../../../../../../services/root/root-service'
import actions from '../../services/uvgiDeviceDetails-actions'
import ChangeDeviceDetailsForm from './locationsAndNickname/ChangeDeviceDetailsForm'
import Loader from '../../../../../../components/_shared/loader/Loader'
import {
  DEVICE_TYPE,
  STATUS_FILTER_VALUES,
} from '../../../../../../services/constants'
import usePageVisibility from 'use-page-visibility'
import classnames from 'classnames'

const UVGIDeviceDetails = (props) => {
  const {
    deviceDetails = {},
    isEditView,
    loadAllowedLocationsForDevice,
    stopPollingModification,
    nickName,
    history,
    error,
    deviceDetailsStatus = {},
    deviceLinked,
    serialNumber,
    deleteDevice,
    timezone,
    isLoading,
    polling,
    startPollingModification,
    setMaintenanceReminder,
    changeActiveTab,
    isMobile,
    classes,
    getDeviceStatistics,
    resetDeviceDetails,
    isBannerPresent,
    cumulativeUsageInSecs,
    getCumulativeUsage,
  } = props

  const { status } = deviceDetailsStatus
  const showErrorMessage =
    status === ERRORS.error ||
    status === ERRORS.off_error ||
    deviceDetails.details.faults

  const deviceDetailsPage = classnames(
    classes.deviceDetailsPage,
    isBannerPresent ? classes.deviceDetailsPageBannerOffset : ''
  )

  const showMaintenanceReminder =
    deviceDetailsStatus.reminder && deviceLinked && !showErrorMessage

  const isInProgress =
    deviceDetails.txStatus === STATUS_FILTER_VALUES.in_progress

  const faults =
    (deviceDetails && deviceDetails.details && deviceDetails.details.faults) ||
    []

  useEffect(() => {
    const isUVGI = window.location.href.indexOf(DEVICE_TYPE.uvgi) !== -1
    const isFAR_UVC = window.location.href.indexOf(DEVICE_TYPE.uvc) !== -1

    if (isUVGI) {
      changeActiveTab(DEVICE_TYPE.uvgi)
    }

    if (isFAR_UVC) {
      changeActiveTab(DEVICE_TYPE.uvc)
    }

    loadAllowedLocationsForDevice(props.match.params.deviceId)
    getCumulativeUsage(props.match.params.deviceId)

    return () => {
      stopPollingModification()
      resetDeviceDetails()
    }
  }, [])

  useEffect(() => {
    const deviceId = parseInt(props.match.params.deviceId)

    if (deviceDetails.deviceLinked && deviceId === deviceDetails.deviceId) {
      getDeviceStatistics(deviceId)
    }
  }, [deviceDetails.deviceLinked, props.match.params.deviceId])

  const handleVisibilityChange = (visible) => {
    const deviceId = parseInt(props.match.params.deviceId)

    if (!visible) {
      stopPollingModification()
    } else if (!polling && deviceLinked) {
      startPollingModification(deviceId, true)
    }
  }

  usePageVisibility(handleVisibilityChange)

  const goBackToDevices = () => {
    history.push('/devices')
  }

  const setNewReminder = () => {
    setMaintenanceReminder(props.match.params.deviceId)
  }

  const onDeleteDevice = () => {
    deleteDevice(serialNumber, goBackToDevices)
  }

  return (
    <Loader isLoading={isLoading}>
      <div className={deviceDetailsPage}>
        <BackToDeviceList
          serialNumber={serialNumber}
          showErrorMessage={showErrorMessage}
          goBackToDevices={goBackToDevices}
        />

        <div className={classes.contentWrapper}>
          {showMaintenanceReminder && isMobile && (
            <MaintenanceReminder onClose={setNewReminder} />
          )}

          {deviceLinked && (
            <div className={classes.formAndRunWrapper}>
              <ChangeDeviceDetailsForm
                serialNumber={deviceDetails.serialNumber}
                isEditView={isEditView}
                initialNickname={nickName}
              />

              <DeviceRunTime
                status={deviceDetails.txStatus}
                device={deviceDetails}
                deviceDetailsStatus={deviceDetailsStatus}
                isInProgress={isInProgress}
                timezone={timezone}
              />
            </div>
          )}

          <div className={classes.infoWrapper}>
            <RecentUsageData
              device={deviceDetails}
              timezone={timezone}
              isError={showErrorMessage}
              faults={faults}
              isMobile={isMobile}
              cumulativeUsageInSecs={cumulativeUsageInSecs}
            />

            <div className={classes.reminderWrapper}>
              {showMaintenanceReminder && !isMobile && (
                <MaintenanceReminder
                  onClose={setNewReminder}
                  isMobile={isMobile}
                />
              )}
            </div>
          </div>
        </div>
        {isEditView && deviceLinked && (
          <DeleteDeviceButton
            onSubmit={onDeleteDevice}
            nickName={deviceDetails.deviceNickname || serialNumber}
          />
        )}
      </div>
    </Loader>
  )
}

const mapStateToProps = (state) => {
  const {
    devicesReducer: { UVGIDeviceDetailsReducer, sharedDevicesReducer },
    rootReducer,
  } = state

  return {
    deviceDetails: UVGIDeviceDetailsReducer.deviceDetails,
    isLoading: UVGIDeviceDetailsReducer.isLoading,
    deleteError: UVGIDeviceDetailsReducer.deleteError,
    updateError: UVGIDeviceDetailsReducer.updateError,
    error: UVGIDeviceDetailsReducer.error,
    nickName: UVGIDeviceDetailsReducer.nickname,
    isEditView: !rootReducer.isOperatorView,
    deviceLinked: UVGIDeviceDetailsReducer.deviceLinked,
    serialNumber: UVGIDeviceDetailsReducer.serialNumber,
    deviceDetailsStatus: UVGIDeviceDetailsReducer.deviceDetailsStatus,
    polling: UVGIDeviceDetailsReducer.polling,
    cumulativeUsageInSecs: UVGIDeviceDetailsReducer.cumulativeUsageInSecs,
    timezone: rootReducer.timezone,
    networkError: rootReducer.networkError,
    activeTab: sharedDevicesReducer.tab,
    isBannerPresent: rootReducer.isBannerPresent,
  }
}

const mapDispatchToProps = (dispatch) => ({
  startPollingModification: (id, startPoll) =>
    dispatch(actions.startPollingModification(id, startPoll)),
  stopPollingModification: () => dispatch(actions.stopPollingModification()),
  loadAllowedLocationsForDevice: (deviceId) =>
    dispatch(
      checkConnection(() => actions.loadAllowedLocationsForDevice(deviceId))
    ),
  deleteDevice: (serialNumber, onClose) =>
    dispatch(
      checkConnection(() => actions.deleteDevice(serialNumber, onClose))
    ),
  resetDeviceDetails: () => dispatch(actions.resetDeviceDetails()),
  setMaintenanceReminder: (onClose, id) =>
    dispatch(
      checkConnection(() => actions.setMaintenanceReminder(onClose, id))
    ),
  changeActiveTab: (deviceType) => dispatch(rootActions.setTab(deviceType)),
  getDeviceStatistics: (deviceId) =>
    dispatch(checkConnection(() => actions.getDeviceStatistics(deviceId))),
  getCumulativeUsage: (deviceId) =>
    dispatch(checkConnection(() => CUMULATIVE_USAGE_START({ deviceId }))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(withStyles(styles)(UVGIDeviceDetails)))
