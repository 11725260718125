import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import styles from '../../../_shared/components/addDeviceFunctionality/style'
import AddDeviceModal from '../../../_shared/components/addDeviceFunctionality/AddDeviceModal'
import { checkConnection } from '../../../../../services/root/root-service'
import { ADD_DEVICE_LABELS } from '../../../_shared/services/devices-constants'
import {
  getErrorAction,
  getErrorLabels,
} from '../../../_shared/services/devices-service'
import actions from '../services/uvgiDeviceList-actions'

const AddUVGIDeviceButton = (props) => {
  const {
    classes,
    onSubmit,
    error,
    resetActionError,
    orgName,
    devices,
    failedSerialNumber,
    loadLocationsForUVGIDevice,
    locationFilter,
    startPollingModification,
    stopPollingModification,
    resetRoomsFilter,
    children,
  } = props
  const [open, setOpen] = useState(false)
  const [errorPopup, setPopupParams] = useState({
    isPopup: !!error,
  })

  useEffect(() => {
    if (error && open) {
      const { errorText, errorActionLabel, errorModalTitle } = getErrorLabels(
        error,
        orgName
      )

      setPopupParams({
        isPopup: true,
        errorText,
        errorActionLabel,
        errorModalTitle,
        errorAction: getErrorAction(
          error,
          orgName,
          failedSerialNumber,
          devices,
          handleClose
        ),
      })
    }
  }, [error, open])

  const openModal = () => {
    setOpen(true)
    resetRoomsFilter()
  }

  const handleClickOpen = () => {
    loadLocationsForUVGIDevice(openModal)

    stopPollingModification()
  }

  const handleClose = () => {
    setOpen(false)

    resetActionError()
    startPollingModification()
  }

  const onApply = (value) => {
    onSubmit(value, handleClose)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        className={classes.addDeviceButton}
      >
        {ADD_DEVICE_LABELS.action}
      </Button>
      <AddDeviceModal
        isOpen={open}
        children={children}
        onClose={handleClose}
        onSubmit={onApply}
        resetRoomsFilter={resetRoomsFilter}
        locationFilter={locationFilter}
        modalTitle={ADD_DEVICE_LABELS.tittle}
        placeholder={ADD_DEVICE_LABELS.placeholder}
        actionLabel={ADD_DEVICE_LABELS.action}
        popupOnError={errorPopup}
        error={error}
        resetError={resetActionError}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocationsForUVGIDevice: (action) =>
      dispatch(
        checkConnection(() => actions.loadLocationsForAddDeviceModal(action))
      ),
    startPollingModification: () =>
      dispatch(checkConnection(() => actions.startPollingModification())),
    stopPollingModification: () => dispatch(actions.stopPollingModification()),
    resetRoomsFilter: () => dispatch(actions.resetRoomsFilter()),
  }
}

const mapStateToProps = (state) => ({
  orgName: state.rootReducer.organization.name,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddUVGIDeviceButton))
