import { split, takeRight } from 'lodash'
import { goToSummary } from '../../../services/root/root-service'
import authorizationActions from './authorization-actions'
import { call, fork, put, takeEvery } from 'redux-saga/effects'
import { HttpClient } from '../../../services/HttpClient'
import { ERRORS, LOGIN_STATUSES } from './authorization-constants'
import { setCookie } from './authorization-service'
import { TOKENS } from '../../../services/constants'

function* login(action) {
  try {
    const {
      body: { login, password },
      onSuccess,
    } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/login', {
        body: {
          login,
          password,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.loginFailed(ERRORS.INVALID_CREDENTIALS))
    } else {
      switch (data.status) {
        case LOGIN_STATUSES.ok: {
          localStorage.setItem('LOGIN', login)
          localStorage.setItem('LOGIN_FOR_RESET', null)

          HttpClient.login(data)
          yield put(authorizationActions.loginSucceeded(data))

          if (onSuccess) {
            onSuccess()
          }

          break
        }
        case LOGIN_STATUSES.new: {
          localStorage.setItem('LOGIN_FOR_RESET', login)
          window.location.href = '/#/resetPassword'

          break
        }
        default: {
          yield put(authorizationActions.setError(ERRORS[data.status]))

          break
        }
      }
    }
  } catch (e) {
    yield put(authorizationActions.loginFailed(e.message))
  }
}

function* loginFromResetForm(action) {
  try {
    const {
      body: { login, password, newPassword },
      onSuccess,
    } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/login', {
        body: {
          login,
          password,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.loginFailed(data.message))
    } else {
      if (data.status === LOGIN_STATUSES.ok) {
        localStorage.setItem('LOGIN', login)
        localStorage.setItem('LOGIN_FOR_RESET', null)

        HttpClient.login(data)
        yield put(authorizationActions.loginSucceeded(data))

        if (onSuccess) {
          onSuccess()
        }
      }

      if (data.status === LOGIN_STATUSES.new) {
        yield put(
          authorizationActions.setupNewPassword(
            data.session,
            login,
            password,
            newPassword,
            onSuccess
          )
        )
      }
    }
  } catch (e) {
    yield put(authorizationActions.loginFailed(e.message))
  }
}

function* loginFromFactory() {
  try {
    const currentUrlArray = split(window.location.href, '/')
    const params = takeRight(currentUrlArray, 2)

    const data = yield call(() => {
      return HttpClient.post('/api/public/user-profiles/select', {
        body: {
          oneTimeToken: params[0],
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.loginFromFactoryFailed(data.message))

      goToSummary()
    } else {
      if (data.status === LOGIN_STATUSES.ok) {
        localStorage.setItem('LOGIN', params[1])
        localStorage.setItem('LOGIN_FOR_RESET', null)
        yield put(authorizationActions.loginFromFactorySucceeded(data))

        goToSummary()
      }
    }
  } catch (e) {
    yield put(authorizationActions.loginFromFactoryFailed(e.message))

    goToSummary()
  }
}

function* logout(action) {
  try {
    const { requestData } = action.payload
    const login = requestData?.email || localStorage.getItem('LOGIN')

    if (login === 'null' || !login) {
      window.location.href = '/#/login'

      yield put(authorizationActions.logoutSucceeded(requestData))

      HttpClient.logout()
    } else {
      const data = yield call(() => {
        return HttpClient.post('/api/public/logout', {
          body: {
            login,
          },
        })
      })

      if (data.message) {
        yield put(authorizationActions.logoutFailed(data.message))
      } else {
        localStorage.setItem('LOGIN', null)
        window.location.href = '/#/login'

        yield put(authorizationActions.logoutSucceeded())

        HttpClient.logout()
      }
    }
  } catch (e) {
    yield put(authorizationActions.logoutFailed(e.message))
  }
}

function* resetPassword(action) {
  try {
    const { login, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/user-profiles/reset-password', {
        body: {
          email: login,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.resetPasswordFailed(data.message))
    } else {
      yield put(authorizationActions.resetPasswordSucceeded(data))

      if (onSuccess) {
        onSuccess(login)
      }
    }
  } catch (e) {
    yield put(authorizationActions.resetPasswordFailed(e.message))
  }
}

function* setupNewPassword(action) {
  try {
    const { login, token, newPassword, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/set-new-password', {
        body: {
          login,
          password: token,
          newPassword,
          session: token,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.setupNewPasswordFailed(data.message))
    } else {
      if (onSuccess) {
        onSuccess()
      }

      yield put(authorizationActions.setupNewPasswordSucceeded(data))
    }
  } catch (e) {
    yield put(authorizationActions.setupNewPasswordFailed(e.message))
  }
}

function* getProvidersList() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/public/okta-providers')
    })

    if (data.message) {
      yield put(authorizationActions.getProvidersListFailed(data.message))
    } else {
      yield put(authorizationActions.getProvidersListSucceeded(data))

      setCookie(TOKENS.tokenIssuer, data[0].id)
    }
  } catch (e) {
    yield put(authorizationActions.getProvidersListFailed(e.message))
  }
}

function* getOktaIsEnabledFlag() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/public/okta-login-enabled')
    })

    if (data.message) {
      yield put(authorizationActions.getOktaIsEnabledFlagFailed(data.message))
    } else {
      yield put(authorizationActions.getOktaIsEnabledFlagSucceeded(data))

      if (data) {
        yield put(authorizationActions.getProvidersList())
      }
    }
  } catch (e) {
    yield put(authorizationActions.getOktaIsEnabledFlagFailed(e.message))
  }
}

function* loginSaga() {
  yield takeEvery(authorizationActions.actionTypes.LOGIN_TO_APP, login)
}

function* logoutSaga() {
  yield takeEvery(authorizationActions.actionTypes.LOGOUT, logout)
}

function* resetPasswordSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.RESET_PASSWORD,
    resetPassword
  )
}

function* setupNewPasswordSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.SETUP_NEW_PASSWORD,
    setupNewPassword
  )
}

function* loginFromFactorySaga() {
  yield takeEvery(
    authorizationActions.actionTypes.LOGIN_FROM_FACTORY,
    loginFromFactory
  )
}

function* loginFromResetPasswordSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.CHECK_TEMPORARY_PASSWORD,
    loginFromResetForm
  )
}

function* getProvidersListSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.GET_PROVIDERS_LIST,
    getProvidersList
  )
}

function* getOktaIsEnabledFlagSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.GET_OKTA_IS_ENABLED_FLAG,
    getOktaIsEnabledFlag
  )
}

export default function* authorizationSaga() {
  yield fork(loginSaga)
  yield fork(logoutSaga)
  yield fork(resetPasswordSaga)
  yield fork(setupNewPasswordSaga)
  yield fork(loginFromFactorySaga)
  yield fork(loginFromResetPasswordSaga)
  yield fork(getProvidersListSaga)
  yield fork(getOktaIsEnabledFlagSaga)
}
