import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import Typography from '@material-ui/core/Typography'
import { BUTTONS, LOCATIONS_LABELS } from '../../service/location-constants'
import Button from '@material-ui/core/Button'
import { uniqueId, map } from 'lodash'

const AssignedDevicesAndUsers = withStyles(styles)((props) => {
  const { location, assignedDevices, assignedUsers, onModalClose, classes } =
    props

  const getDevices = () => {
    return (
      assignedDevices &&
      assignedDevices.length !== 0 &&
      map(assignedDevices, (device) => (
        <Typography variant="body2" key={uniqueId('device-')} noWrap>
          {device.serialNumber} {device.nickname}
        </Typography>
      ))
    )
  }

  const getUsers = () => {
    return (
      assignedUsers &&
      assignedUsers.length !== 0 &&
      map(assignedUsers, (user) => (
        <Typography variant="body2" key={uniqueId('user-')} noWrap>
          {user.firstName} {user.lastName}
        </Typography>
      ))
    )
  }

  const openTab = (event) => {
    onModalClose()

    if (event.target.innerText === BUTTONS.viewUsers) {
      window.location.href = '/#/users'
    } else {
      window.location.href = '/#/devices'
    }
  }

  return (
    <>
      <Typography variant="body2">
        {LOCATIONS_LABELS.assignedLocationsAndUsers(location.name)}
      </Typography>

      <div className={classes.lists}>
        {getDevices()}
        {getUsers()}
      </div>
      <div className={classes.buttonsContainer}>
        {assignedUsers && assignedUsers.length !== 0 && (
          <Button
            type="submit"
            color="primary"
            className={classes.viewButton}
            variant="contained"
            onClick={openTab}
          >
            {BUTTONS.viewUsers}
          </Button>
        )}
        {assignedDevices && assignedDevices.length !== 0 && (
          <Button
            type="submit"
            color="primary"
            className={classes.viewButton}
            variant="contained"
            onClick={openTab}
          >
            {BUTTONS.viewDevices}
          </Button>
        )}
      </div>
    </>
  )
})

export default AssignedDevicesAndUsers
