import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import styles from './style'
import { withMediaQuery } from '../../../../services/root/root-service'

const ConfirmModalComponent = withMediaQuery(
  withStyles(styles)((props) => {
    const {
      onSubmit,
      onClose,
      modalTitle,
      isOpen,
      actionLabel,
      closeLabel,
      error,
      confirmText,
      isMobile,
      classes,
    } = props

    return (
      <div>
        <Dialog open={isOpen} aria-labelledby="form-dialog-title" maxWidth="xs">
          <DialogTitle disableTypography>
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              className={classes.modalTitle}
            >
              {modalTitle}
            </Typography>
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.infoBlock}
            >
              {confirmText}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              {actionLabel}
            </Button>
            <Button
              onClick={onClose}
              variant="outlined"
              className={classes.submitButton}
            >
              {closeLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  })
)

export default ConfirmModalComponent
