import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Typography, Button } from '@material-ui/core'
import SupervisorAcccountIcon from '@material-ui/icons/SupervisorAccount'

import { EMPTY_USERS_PAGE } from '../../services/users-constants'
import styles from './style'

const EmptyUsersList = (props) => {
  const { clearSearch, classes } = props

  return (
    <div className={classes.emptyUsersListContainer}>
      <div className={classes.infoContainer}>
        <SupervisorAcccountIcon className={classes.usersIcon} />
        <Typography className={classes.description}>
          {EMPTY_USERS_PAGE.description}
        </Typography>
        <Button className={classes.clearSearch} onClick={clearSearch}>
          {EMPTY_USERS_PAGE.clearSearch}
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(EmptyUsersList)
