import { COLORS } from '../../../../../services/constants'

const style = (theme) => ({
  container: {
    backgroundColor: COLORS.WARM_GRAY,
    marginTop: '20px',
  },
  table: {
    backgroundColor: COLORS.WARM_GRAY,
    maxHeight: '230px',
  },
  headerCell: {
    padding: '15px',
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    padding: 0,
    borderTop: `2px solid ${COLORS.GRAY_LINK_WATER}`,
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  firstColumn: {
    borderRight: `1px solid ${COLORS.GRAY_MERCURY}`,
  },
  deviceCell: {
    padding: '10px 24px 10px 16px',
  },
  subtitle: {
    marginTop: '5px',
    fontSize: '12px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  active: {
    color: COLORS.GREEN,
    fontSize: '10px',
    fontFamily: 'Cerebri Sans Pro Bold',
  },
  circle: {
    fontSize: '0.8rem',
    verticalAlign: 'sub',
  },
  expandMore: {
    color: COLORS.BLACK,
  },
  '@media (min-width: 1224px)': {
    container: {
      backgroundColor: COLORS.WHITE,
    },
    table: {
      backgroundColor: COLORS.WHITE,
    },
  },
})

export default style
